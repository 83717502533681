exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3Y5Ap{border-radius:0;width:100%;display:block;color:#404040;font-size:16px;border-bottom:1px solid #dce0e0}.qOWKF{text-align:center}._1A5_T{font-weight:700}._1A5_T,._848so{font-size:14px;color:#404040}.X5xBb{margin-top:18px}._3Kzdq{margin-top:24px}._26vLG{margin-bottom:12px}._6l9mq{box-sizing:border-box;margin:0;padding:7px 21px;width:100%;outline:0;border:1px solid #fff;border-radius:4px;color:#404040;text-align:center;text-decoration:none;font-size:14px;line-height:1.3333333;cursor:pointer}._6l9mq,._6l9mq:hover{background:#0667b3}._6l9mq:focus{border-color:#0667b3;box-shadow:0 0 8px #0667b3}._1dYxM{border-color:#0667b3;background-color:#0667b3;color:#fff}._1dYxM:active,._1dYxM:active:focus,._1dYxM:active:hover,._1dYxM:focus,._1dYxM:hover{border-color:#2081cd;background-color:#2081cd;color:#fff}._3vrP_{border-color:#0667b3;color:#0667b3;background-color:#fff}._3vrP_:active,._3vrP_:active:focus,._3vrP_:active:hover,._3vrP_:focus,._3vrP_:hover{border-color:#2081cd;color:#2081cd;background-color:#fff}", ""]);

// exports
exports.locals = {
	"panelHeader": "_3Y5Ap",
	"textCenter": "qOWKF",
	"textTitle": "_1A5_T",
	"textLead": "_848so",
	"spaceTop3": "X5xBb",
	"spaceTop4": "_3Kzdq",
	"space2": "_26vLG",
	"button": "_6l9mq",
	"btnPrimary": "_1dYxM",
	"btnPrimaryBorder": "_3vrP_"
};
import React from 'react';
import PropTypes from 'prop-types';
import window from 'global'
import history from '../../core/history';
import cx from 'classnames';
function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.any,
  };

  static defaultProps = {
    onClick: null,
  };

  handleClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    history.push(this.props.to);
  };

  render() {
    const { to, children, ...props } = this.props;
    let isActive = false;

    if (window && window.location )  {
      let pathName = window.location.pathname;

      if(pathName && to) {
        if( pathName.split("/")[2]) {
          //For 2 level path
          isActive = pathName.split("/")[2].includes(to.split("/")[2]) ? true: false;
        } else if (pathName.split("/")[1]) {
          //For 1 level path
          isActive = pathName.split("/")[1].includes(to.split("/")[1]) ? true: false;
        }
      }
   
    }

    let classes = isActive ? cx(this.props.className, this.props.activeCss): this.props.className;

    return <a className={isActive} href={to} className={classes} onClick={this.handleClick}>{children}</a>;
  }
}

export default Link;

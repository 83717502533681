exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".oq9vV{color:#0667b3}.u6Wnx{color:#fff}", ""]);

// exports
exports.locals = {
	"logoColor": "oq9vV",
	"whiteColor": "u6Wnx"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._27XaJ{background-size:cover;background-position:50%;background-repeat:no-repeat;width:100%;height:100%;top:0;left:0;position:absolute;max-height:350px}._2m4cr{font-size:36px;color:#404040;text-align:center;font-weight:700}._2Ktmm{margin-top:45px;font-size:16px;color:#404040;text-align:justify;font-weight:300;font-style:normal}._1Mmeh{margin-bottom:18px}.MLcC0{margin-bottom:30px}._2y8I3{width:100%;max-width:1080px;margin:0 auto;min-height:50vh}h1{font-size:28px}h1,h2{color:#404040;font-weight:700}h2{font-size:20px}._2feoS{padding-top:48px;max-width:1080px;margin:0 auto}.Y_FMx{min-height:calc(100vh - 84px)}._4HmQ0,.Y_FMx{position:relative}@media screen and (min-width:768px){._1vWhW{position:absolute!important;top:15px;left:0;right:0;box-shadow:0 1px 3px 0 rgba(0,0,0,.08);margin:0 auto}}@media screen and (max-width:640px){._2feoS{padding-top:28px}.Y_FMx{position:relative;min-height:680px}h1,h2{text-align:left}}", ""]);

// exports
exports.locals = {
	"imageBanner": "_27XaJ",
	"titleText": "_2m4cr",
	"subText": "_2Ktmm",
	"spaceTop3": "_1Mmeh",
	"space6": "MLcC0",
	"root": "_2y8I3",
	"bannerContainer": "_2feoS",
	"bannerHeader": "Y_FMx",
	"realtivePosition": "_4HmQ0",
	"captionText": "_1vWhW"
};
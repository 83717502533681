exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1YmTC{color:#fff;padding-top:100px;padding-bottom:100px}._2Ycbn{padding-right:20px}._1zqMs{font-size:26px;margin-top:15px;margin-bottom:20px;font-weight:500;color:#fff}._1YmTC img{height:50px}._12fkW{font-size:16px}._2n1z4{text-transform:uppercase;letter-spacing:4px;font-size:15px;color:#fff}._11nzy{font-size:50px;margin-top:10px;margin-bottom:100px}@media screen and (max-width:767px){._12fkW{display:block;font-size:16px;margin-bottom:24px}._11nzy{margin-bottom:50px}}", ""]);

// exports
exports.locals = {
	"content": "_1YmTC",
	"item": "_2Ycbn",
	"itemHeadline": "_1zqMs",
	"itemText": "_12fkW",
	"subHeadline": "_2n1z4",
	"headline": "_11nzy"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".fqctA{padding-top:100px;padding-bottom:100px}._3MZi5{text-transform:uppercase;letter-spacing:4px;font-size:15px;color:#0667b3}._1MnC5{font-size:50px;margin-top:20px}._3HYFs{font-size:16px;color:#656565;margin-bottom:80px}._199dX{padding:20px;margin-right:50px;cursor:pointer}._2ydtu{padding-top:0;padding-bottom:10px;font-size:30px}._3lf8n{width:100%}._1W2b8{box-shadow:10px 10px 20px hsla(0,0%,58%,.25);border-radius:4px;border-top:10px solid #0667b3;background-color:#fff}ul{padding-left:20px}@media screen and (max-width:767px){._3lf8n{margin-top:20px}}", ""]);

// exports
exports.locals = {
	"content": "fqctA",
	"subHeadline": "_3MZi5",
	"headline": "_1MnC5",
	"text": "_3HYFs",
	"item": "_199dX",
	"itemHeadline": "_2ydtu",
	"itemImage": "_3lf8n",
	"active": "_1W2b8"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1MZzR{margin-bottom:12px}._2Ou9c{border-radius:0;width:100%;display:block;color:#404040;font-size:16px;padding-bottom:12px;border-bottom:1px solid #dce0e0}._1gRAP{font-size:14px;color:inherit}._1BXsS{font-size:24px;line-height:1;font-weight:400;font-style:normal;color:#5cb85c;display:block}._3baOS{margin-bottom:18px}._16XaH{padding-left:0;list-style:none;font-size:14px;line-height:1.43;color:#404040}._2F02V{float:none;display:table-cell;vertical-align:middle}", ""]);

// exports
exports.locals = {
	"space2": "_1MZzR",
	"panelHeader": "_2Ou9c",
	"panelBody": "_1gRAP",
	"circleIcon": "_1BXsS",
	"space3": "_3baOS",
	"listStyle": "_16XaH",
	"colMiddle": "_2F02V"
};
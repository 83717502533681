import { gql } from "react-apollo";
import { reset, change } from "redux-form";
import { hideLoading } from "react-redux-loading-bar";

import { SEARCH_LISTING_START, SEARCH_LISTING_SUCCESS, SEARCH_LISTING_ERROR } from "../constants";

import { getSearchResults } from "./getSearchResults";

const query = gql`
  query(
    $personCapacity: Int
    $flooring: String
    $floorHeight: Float
    $security: String
    $securityClass: String
    $powerConnection: String
    $lighting: String
    $buildingClimate: String
    $buildingCondition: String
    $floorCount: String
    $floorLoading: String
    $parkingCount: Int
    $truckDocks: Int
    $ramps: Int
    $gates: Int
    $doors: Int
    $roomType: [Int]
    $dates: String
    $currentPage: Int
    $geography: String
    $geoType: String
    $lat: Float
    $lng: Float
    $sw_lat: Float
    $sw_lng: Float
    $ne_lat: Float
    $ne_lng: Float
  ) {
    SearchListing(
      personCapacity: $personCapacity
      flooring: $flooring
      floorHeight: $floorHeight
      security: $security
      securityClass: $securityClass
      floorCount: $floorCount
      floorLoading: $floorLoading
      powerConnection: $powerConnection
      lighting: $lighting
      buildingClimate: $buildingClimate
      buildingCondition: $buildingCondition
      parkingCount: $parkingCount
      truckDocks: $truckDocks
      ramps: $ramps
      gates: $gates
      doors: $doors
      roomType: $roomType
      dates: $dates
      currentPage: $currentPage
      geography: $geography
      geoType: $geoType
      lat: $lat
      lng: $lng
      sw_lat: $sw_lat
      sw_lng: $sw_lng
      ne_lat: $ne_lat
      ne_lng: $ne_lng
    ) {
      count
      results {
        id
        title
        personCapacity
        flooring
        floorHeight
        security
        securityClass
        floorCount
        powerConnection
        lighting
        buildingClimate
        buildingCondition
        parkingCount
        truckDocks
        ramps
        gates
        doors
        roomType
        lat
        lng
        beds
        city
        state
        bookingType
        coverPhoto
        reviewsCount
        reviewsStarRating
        listPhotos {
          id
          name
          type
          status
        }
        listingData {
          basePrice
          currency
          qmPrice
          nkPrice
          services
          service_price_hour
          service_price_qm
          service_price_kg
          service_price_palette,
          monthlyPay
          service_description
        }
        settingsData {
          listsettings {
            id
            itemName
            itemDescription
          }
        }
        wishListStatus
        isListOwner
      }
    }
  }
`;

export function searchListing({
  personCapacity,
  flooring,
  floorHeight,
  security,
  securityClass,
  floorCount,
  floorLoading,
  powerConnection,
  lighting,
  buildingClimate,
  buildingCondition,
  parkingCount,
  truckDocks,
  ramps,
  gates,
  doors,
  roomType,
  dates,
  geography,
  currentPage,
  geoType,
  lat,
  lng,
  sw_lat,
  sw_lng,
  ne_lat,
  ne_lng,
}) {
  return async (dispatch, getState, { client }) => {
    let roomTypeArray;
    let floorHeightArray;
    if (!Array.isArray(roomType)) {
      roomTypeArray = [roomType];
    } else {
      roomTypeArray = roomType;
    }

    if (!Array.isArray(floorHeight)) {
      floorHeightArray = [floorHeight];
    } else {
      floorHeightArray = floorHeight;
    }
    dispatch({ type: SEARCH_LISTING_START });
    dispatch(reset("SearchForm"));

    try {
      const { data } = await client.query({
        query,
        variables: {
          personCapacity,
          flooring,
          floorHeight: floorHeightArray,
          security,
          securityClass,
          floorCount,
          floorLoading,
          powerConnection,
          lighting,
          buildingClimate,
          buildingCondition,
          parkingCount,
          truckDocks,
          ramps,
          gates,
          doors,
          roomType: roomTypeArray,
          dates,
          currentPage,
          geography,
          geoType,
          lat,
          lng,
          sw_lat,
          sw_lng,
          ne_lat,
          ne_lng,
        },
        fetchPolicy: "network-only",
      });
      if (data.SearchListing) {
        dispatch({ type: SEARCH_LISTING_SUCCESS });
        await dispatch(change("SearchForm", "personCapacity", personCapacity));
        await dispatch(change("SearchForm", "flooring", flooring));
        await dispatch(change("SearchForm", "floorHeight", floorHeight));
        await dispatch(change("SearchForm", "security", security));
        await dispatch(change("SearchForm", "securityClass", securityClass));
        await dispatch(change("SearchForm", "floorCount", floorCount));
        await dispatch(change("SearchForm", "floorLoading", floorLoading));
        await dispatch(change("SearchForm", "powerConnection", powerConnection));
        await dispatch(change("SearchForm", "lighting", lighting));
        await dispatch(change("SearchForm", "buildingClimate", buildingClimate));
        await dispatch(change("SearchForm", "buildingCondition", buildingCondition));
        await dispatch(change("SearchForm", "parkingCount", parkingCount));
        await dispatch(change("SearchForm", "truckDocks", truckDocks));
        await dispatch(change("SearchForm", "ramps", ramps));
        await dispatch(change("SearchForm", "gates", gates));
        await dispatch(change("SearchForm", "doors", doors));
        await dispatch(change("SearchForm", "roomType", roomTypeArray));
        await dispatch(change("SearchForm", "dates", dates));
        await dispatch(change("SearchForm", "geography", geography));
        await dispatch(change("SearchForm", "currentPage", currentPage));
        await dispatch(change("SearchForm", "geoType", geoType));
        await dispatch(change("SearchForm", "lat", lat));
        await dispatch(change("SearchForm", "lng", lng));
        await dispatch(change("SearchForm", "searchByMap", false));
        await dispatch(change("SearchForm", "sw_lat", sw_lat));
        await dispatch(change("SearchForm", "sw_lng", sw_lng));
        await dispatch(change("SearchForm", "ne_lat", ne_lat));
        await dispatch(change("SearchForm", "ne_lng", ne_lng));
        await dispatch(change("SearchForm", "initialLoad", true));
        await dispatch(change("SearchForm", "markerHighlight", {}));
        // Default Map Show
        await dispatch(change("SearchForm", "showMap", true));
        dispatch(getSearchResults(data.SearchListing));
        dispatch(hideLoading());
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: SEARCH_LISTING_ERROR,
        payload: {
          error,
        },
      });
      dispatch(hideLoading());
      return false;
    }

    return true;
  };
}

import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payout.css";
import { Button, Modal } from "react-bootstrap";
import cx from "classnames";
// Component
import PayoutList from "./PayoutList";
import EmptyList from "./PayoutList/EmptyList";
import Loader from "../Loader";
import { FormattedMessage } from "react-intl";
// Locale
import messages from "../../locale/messages";
// ForRedirect
import history from "../../core/history";
class Payout extends Component {
  static defaultProps = {
    loading: true,
    data: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      initialLoad: true,
      // modalOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      initialLoad: false,
    });
  }

  // closeModal = () => {
  //   //redirect to profile
  //   history.push("/user/editfull");
  // };

  render() {
    const { data, loading, currentAccountId, profileComplete } = this.props;
    const { initialLoad } = this.state;
   
    // const { modalOpen } = this.state;
    // if (!profileComplete && !modalOpen) {
    //   //show modal then redirect to edit profile
    //   this.setState({ modalOpen: true });
    // }

    if (loading && initialLoad) {
      return <Loader type={"text"} />;
    }
    return (
      <div>
        {data != undefined && data.length > 0 ? <PayoutList data={data} currentAccountId={currentAccountId} /> : <EmptyList />}
        {/* <Modal show={modalOpen} onHide={this.closeModal} animation={true} dialogClassName={cx(s.logInModalContainer, "loginModal")}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              <FormattedMessage {...messages.completeProfileFirst} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.closeModal} bsStyle="primary">
              <FormattedMessage {...messages.completeProfile} />
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    );
  }
}

const mapState = (state) => ({
  data: state.payout.data,
  loading: state.payout.getPayoutLoading,
  // profileComplete: state.account.data.profileComplete,
});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(Payout));

import s2 from "!isomorphic-style-loader!css-loader!react-multi-carousel/lib/styles.css";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import s from "./AsSeenOn.css";

class AsSeenOn extends React.Component {
  static propTypes = {};

  render() {
    let path = "/images/asSeenOn/";

    const items = [
      { link: "https://www.dekra.net/de/expozede1-startup-lager-miete", imagePath: path + "dekra.svg" },
      { link: "https://www.startup-region-ulm.de/expozed-gmbh/", imagePath: path + "startup_region_ulm.png" },
      {
        link: "https://www.b4bschwaben.de/b4b-nachrichten/ulm-neu-ulm_artikel,-expozed-gmbh-flexible-vermittlung-von-lagerflaechen-_arid,262580.html",
        imagePath: path + "b4b_small.png",
      },
      {
        link: "https://www.eurotransport.de/artikel/lagerflaechen-start-up-expozed1bietet-airbnb-fuer-lagerflaechen-11172433.html",
        imagePath: path + "eurotransport.svg",
      },
      {
        link: "https://www.logistik-watchblog.de/startups/2784-startup-expozed1-lagerflaeche-buchen-ferienwohnung.html",
        imagePath: path + "logistik_watchblog.png",
      },
      {
        link: "https://www.swp.de/suedwesten/staedte/ulm/lagerraeume-ulm-mieten-selfstorage-bei-diesen-startups-koennt-ihr-lagerflaeche-mieten-science-park-expozed1-spacefill-53333550.html",
        imagePath: path + "swp.svg",
      },
      { link: "https://www.verkehrsrundschau.de/magazin/airbnb-fuer-lagerflaechen-2681459.html", imagePath: path + "verkehrs_rundschau.svg" },
    ];

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 1500px)").matches : undefined;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 4,
        partialVisibilityGutter: 60, // this is needed to tell the amount of px that should be visible.
      },
      tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 3,
        partialVisibilityGutter: 60, // this is needed to tell the amount of px that should be visible.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        partialVisibilityGutter: 60, // this is needed to tell the amount of px that should be visible.
      },
    };
    return (
      <Grid fluid>
        <div className={s.container}>
          <Row>
            <Col xs={12} sm={12} md={2} lg={2}>
              <h3 className={s.headLine}>Bekannt aus:</h3>
            </Col>
            <Col xs={12} sm={12} md={10} lg={10}>
              <div className={s.carouselMain}>
                <Carousel
                  // centerMode={true}
                  partialVisible={false}
                  swipeable={smallDevice ? true : false}
                  draggable={smallDevice ? true : false}
                  showDots={smallDevice ? false : false}
                  responsive={responsive}
                  // ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={smallDevice ? true : true}
                  autoPlaySpeed={6000}
                  keyBoardControl={true}
                  arrows={false}
                  transitionDuration={500}
                  // containerClass="carousel-container"
                  // removeArrowOnDeviceType={["tablet", "mobile"]}
                  // deviceType={this.props.deviceType}
                  dotListClass="dots"
                  // itemClass="carousel-item-padding-40-px"
                >
                  {items.map((item, key) => {
                    return (
                      <div className={cx(s.table)} key={key}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                          <div className={s.carouselItem} style={{ backgroundImage: "url(" + item.imagePath + ")" }} />
                        </a>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

export default withStyles(s, s2)(AsSeenOn);

import { gql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';
import {Event} from '../../components/Tracking/GA'
import {
  SEND_FORGOT_PASSWORD_START,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_ERROR,
} from '../../constants';

import { closeForgotPasswordModal } from '../modalActions';

export function sendForgotLink(email) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SEND_FORGOT_PASSWORD_START,
    });

    dispatch(closeForgotPasswordModal());

    try {

      let mutation = gql`
        mutation sendForgotPassword($email: String!) {
          sendForgotPassword (email: $email) {
            status
          }
        }
      `;

      // Send Message
      const { data } = await client.mutate({
        mutation,
        variables: {
          email
        }
      });

      if (data && data.sendForgotPassword) {

        if (data.sendForgotPassword.status === 'notAvailable') {
          toastr.error("Passwort zurücksetzen", "Für die angegebene EMail-Adresse ist kein Konto vorhanden");
          return false;
        }
        if (data.sendForgotPassword.status === '400') {
          toastr.error("Passwort zurücksetzen", "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut");
          return false;
        }

        toastr.success("Passwort zurücksetzen", "Wir haben Ihnen eine Email gesendet mit Instruktionen um Ihr Passwort zurückzusetzen.");
        
        dispatch({
          type: SEND_FORGOT_PASSWORD_SUCCESS,
        });

        Event("account", "forget_password", email)
      }

    } catch (error) {
      dispatch({
        type: SEND_FORGOT_PASSWORD_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}
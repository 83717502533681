import {gql} from 'react-apollo';

import {
  SET_DEFAULT_PAYIN_START,
  SET_DEFAULT_PAYIN_SUCCESS,
  SET_DEFAULT_PAYIN_ERROR, 
} from '../../constants';

import { setLoaderStart, setLoaderComplete } from '../loader/loader';

import { getPayins } from './getPayins';

export function setDefaultPayin(id) {

  return async (dispatch, getState, { client }) => {
    
    dispatch({
      type: SET_DEFAULT_PAYIN_START,
    });

    dispatch(setLoaderStart('payinDefault'));

    try {

      let mutation = gql `
          mutation setDefaultPayin(
            $id: Int!, 
          ){
              setDefaultPayin(
                id: $id
              ) {
                  status
              }
          }
      `;

      const {data} = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if(data && data.setDefaultPayin) {
        await dispatch({
          type: SET_DEFAULT_PAYIN_SUCCESS,
          payload: {
            status: data.setDefaultPayin.status
          }
        });

        await dispatch(getPayins());
      }

      await dispatch(setLoaderComplete('payinDefault'));

    } catch (error) {
        dispatch({
          type: SET_DEFAULT_PAYIN_ERROR,
          payload: {
            error
          }
        });

        dispatch(setLoaderComplete('payinDefault'));
      return false;
    }

    return true;
  };
}
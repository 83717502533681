// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { createNumberMask } from "redux-form-input-masks";
import  { FaInfoCircle } from 'react-icons/fa';

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep3 from "./validateStep3";


// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, ControlLabel, Popover, OverlayTrigger, FormControl, InputGroup, InputGroupAddon } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component

import updateStep3 from "./updateStep3";
const currencyMaskEuro = createNumberMask({
  // prefix: 'US$ ',
  suffix: " €",
  decimalPlaces: 2,
  locale: "de-DE",
});


class Services extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  // onChangeServicePriceHour = (event,value) => {
  //   //Set calculated price to hidden form field
  //   // this.props.change("basePrice", calculatedBasePrice);
  // };
  // onChangeServicePriceQm = (event) => {
  //   //Set calculated price to hidden form field
  //   this.props.change("basePrice", calculatedBasePrice);
  // };
  // onChangeServicePriceKg = (event) => {
  //   //Set calculated price to hidden form field
  //   this.props.change("basePrice", calculatedBasePrice);
  // };
  // onChangeServicePricePalette = (event) => {
  //   this.setState({ calculatedBasePrice, nkPriceState: nkPrice, qmPriceState: qmPrice });
  //   //Set calculated price to hidden form field
  //   this.props.change("basePrice", calculatedBasePrice);
  // };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
  
    return (
      <div>
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          componentClass={"textarea"}
        />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage, services, valid } = this.props;
    const popoverServicePrice = (
      <Popover id="popover-trigger-hover-focus" title="Dienstleistungskonditionen">
        Wenn Sie den Wert auf 0,00€ belassen, wird diese Abrechnungsmethode nicht von Ihnen angeboten. Sie müssen mindestens eine Angabe machen.
      </Popover>
    );
    const popoverServiceDescription = (
      <Popover id="popover-trigger-hover-focus" title="Wichtige Hinweise">
        Bitte geben Sie hier wichtige Details zu den angebotenen Dienstleistungen, wie beispielsweise Maximalgewicht, ausgeschlossene Warenarten oder weitere Bedingungen an.
      </Popover>
    );
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";
    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <form onSubmit={handleSubmit}>
              <div className={s.spaceTop3}>
                <h3 className={s.landingContentTitle}>Wie soll Ihre Fläche vermietet werden? </h3>
                <span className={s.landingStep}>Legen Sie fest welche Dienstleistungen Sie anbieten wollen.</span>
              </div>

              <div className={s.spaceTop3}>
                <label className={s.displayTable}>
                  <span className={s.displayTableRow}>
                    <span className={s.displayTableCellTop}>
                      <Field name="services" component="input" type="radio" value="self" className={s.BookingradioInput} />
                    </span>
                    <span className={s.displayTableCell}>
                      <span className={s.bookText}>
                        <h5>Selbstständige Nutzung des Mieters</h5>
                        <p>
                          Der Mieter bucht nur die Fläche ohne jeglichen Service oder Dienstleistungen. Der Mieter erhält uneingeschränkten Zugang zur Fläche.
                        </p>
                      </span>
                      {/* <span className={s.subText}><FormattedMessage {...messages.whoCanBookInfo2} /></span> */}
                    </span>
                  </span>
                </label>
              </div>
              <div className={cx(s.space6, s.spaceTop3)}>
                <label className={s.displayTable}>
                  <span className={s.displayTableRow}>
                    <span className={s.displayTableCellTop}>
                      <Field name="services" component="input" type="radio" value="inclusive" className={s.BookingradioInput} />
                    </span>
                    <span className={s.displayTableCell}>
                      <span className={s.bookText}>
                        <h5>Inklusive Be- und Entladen</h5>
                        <p>Sie übernehmen die Be- und Entladung ohne zusätzliche Kosten für den Mieter.</p>
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div className={cx(s.space6, s.spaceTop3)}>
                <label className={s.displayTable}>
                  <span className={s.displayTableRow}>
                    <span className={s.displayTableCellTop}>
                      <Field name="services" component="input" type="radio" value="optional" className={s.BookingradioInput} />
                    </span>
                    <span className={s.displayTableCell}>
                      <span className={s.bookText}>
                        <h5>Optional verfügbare Dienstleistungen</h5>
                        <p>Der Mieter sorgt selbst für die Einlagerung und Nutzung der Fläche. Optional können Dienstleistungen hinzugebucht werden.</p>
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              <div className={cx(s.space6, s.spaceTop3)}>
                <label className={s.displayTable}>
                  <span className={s.displayTableRow}>
                    <span className={s.displayTableCellTop}>
                      <Field name="services" component="input" type="radio" value="full" className={s.BookingradioInput} />
                    </span>
                    <span className={s.displayTableCell}>
                      <span className={s.bookText}>
                        <h5>Full Service Vermietung</h5>
                        <p>
                          Sie übernehmen die gesamte Einlagerung, sowie das Be- und Entladen. Der Mieter bucht die entsprechende Dienstleistung automatisch mit.
                        </p>
                      </span>
                    </span>
                  </span>
                </label>
              </div>
              {(services === "optional" || services === "full") && (
                <div className={cx(s.space6, s.spaceTop3)}>
                  <FormGroup className={cx(s.formGroup, s.maxWidthInput)}>
                    <ControlLabel className={s.landingStep3}>
                      Bitte geben Sie hier Ihre Dienstleistungskonditionen an:{" "}
                      <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverServicePrice}>
                        <FaInfoCircle />
                      </OverlayTrigger>
                    </ControlLabel>
                    <InputGroup>
                      <Field
                        className={cx(s.formControlInput, s.jumboSelect)}
                        name="service_price_hour"
                        // label={formatMessage(messages.basePriceLabel)}
                        type="text"
                        {...currencyMaskEuro}
                        component="input"
                      />
                      <InputGroup.Addon>
                        pro Std.{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                      </InputGroup.Addon>
                    </InputGroup>

                    <InputGroup>
                      <Field
                        className={cx(s.formControlInput, s.jumboSelect)}
                        name="service_price_qm"
                        // label={formatMessage(messages.basePriceLabel)}
                        type="text"
                        {...currencyMaskEuro}
                        component="input"
                      />
                      <InputGroup.Addon>
                        pro m²{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                      </InputGroup.Addon>
                    </InputGroup>
                    <InputGroup>
                      <Field
                        className={cx(s.formControlInput, s.jumboSelect)}
                        name="service_price_kg"
                        // label={formatMessage(messages.basePriceLabel)}
                        type="text"
                        {...currencyMaskEuro}
                        component="input"
                      />
                      <InputGroup.Addon>
                        pro kg{"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                      </InputGroup.Addon>
                    </InputGroup>
                    <InputGroup>
                      <Field
                        className={cx(s.formControlInput, s.jumboSelect)}
                        name="service_price_palette"
                        // label={formatMessage(messages.basePriceLabel)}
                        type="text"
                        {...currencyMaskEuro}
                        component="input"
                      />
                      <InputGroup.Addon>pro Palette</InputGroup.Addon>
                    </InputGroup>

                    {/* <Field name="currency" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                      <option value="h">pro Std.</option>
                      <option value="qm">pro m²</option>
                      <option value="kg">pro kg</option>
                      <option value="palette">pro Palette</option>
                    </Field> */}
                  </FormGroup>
                </div>
              )}

{(services !== "self") && (  <div className={cx(s.space6, s.spaceTop3)}>
                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingStep3}>
                    Wichtige Hinweise:
                      <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverServiceDescription}>
                        <FaInfoCircle />
                      </OverlayTrigger>
                    </ControlLabel>
                    <Field name="service_description"
                      component={this.renderFormControlTextArea}
                      className={s.textareaInput}
                      label={"Wichtige Hinweise"}
                    />
                  </FormGroup>
                </div>)}
              <div className={s.nextPosition}>
                <div className={s.nextBackButton}>
                  <hr className={s.horizontalLineThrough} />

                  <FormGroup className={s.formGroup}>
                    <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                      <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Sofortbuchung")}>
                        <FormattedMessage {...messages.back} />
                      </Button>
                      <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} disabled={!valid}  onClick={() => nextPage("Monatliche Zahlung")}>
                        <FormattedMessage {...messages.next} />
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
const selectorStep3 = formValueSelector("ListPlaceStep3"); //
Services = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep3,
  validate: validateStep3,
})(Services);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  services: selectorStep3(state, "services"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Services)));

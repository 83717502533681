import React from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Layout.css";
import HeaderJustLogo from "../Header/HeaderJustLogo";
import Footer from "../Footer/Footer";
import CookiesDisclaimer from "../CookiesDisclaimer/CookiesDisclaimer";
import cx from "classnames";
class LayoutJustLogo extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div>
        <HeaderJustLogo />
        <div className={s.paddingTop}>
          <div className={s.minHeight}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(LayoutJustLogo);

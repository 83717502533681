import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Grid, Row, Col, Panel } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payment.css";
import { FormattedMessage } from "react-intl";

// Component
import PaymentDetails from "./PaymentDetails";
import PrePaymentForm from "./PrePaymentForm";
import PaymentForm from "./PaymentForm";
import Avatar from "../../Avatar";
import ListCoverPhoto from "../../ListCoverPhoto";

import { taxRate } from "../../../config";

// Helper
import { convert } from "../../../helpers/currencyConvertion";

// Locale
import messages from "../../../locale/messages";

//Images
import RightArrowIcon from "../../../../public/rightArrow.svg";
import { serviceFee } from "../../../config";
class Payment extends Component {
  static propTypes = {
    listId: PropTypes.number.isRequired,
    hostId: PropTypes.string.isRequired,
    guestId: PropTypes.string.isRequired,
    guestEmail: PropTypes.string.isRequired,
    hostDisplayName: PropTypes.string.isRequired,
    hostPicture: PropTypes.string,
    coverPhoto: PropTypes.string,
    listTitle: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    listType: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    houseRules: PropTypes.arrayOf(
      PropTypes.shape({
        listsettings: PropTypes.shape({
          itemName: PropTypes.string.isRequired,
        }),
      })
    ),
    checkIn: PropTypes.object.isRequired,
    checkOut: PropTypes.object.isRequired,
    guests: PropTypes.number.isRequired,
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    currency: PropTypes.string.isRequired,
    weeklyDiscount: PropTypes.number,
    monthlyDiscount: PropTypes.number,
    qmPrice: PropTypes.number,
    nkPrice: PropTypes.number,
    listPhotos: PropTypes.array,
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
      host: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    bookingType: PropTypes.string.isRequired,
    policyName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    let account = props.account;
    this.state = {
      prepayment: true,
    };
  }

  setPaymentStep2 = () => {
    this.setState({ prepayment: false });
  };
  setPaymentStep1 = () => {
    this.setState({ prepayment: true });
  };

  render() {
    const { guestEmail, hostDisplayName, hostPicture, coverPhoto, listPhotos, bookingType, policyName, mandateReturned } = this.props;
    const {
      listId,
      listTitle,
      listType,
      city,
      state,
      country,
      allowedPersonCapacity,
      security,
      securityClass,
      services,
      service_description,
      service_price_hour,
      service_price_kg,
      service_price_qm,
      service_price_palette,
      monthlyPay,
    } = this.props;
    const { houseRules, hostId, guestId, reservationId } = this.props;
    let { justPayment, preApproved } = this.props;
    const { prepayment } = this.state;
    const { guests, checkIn, checkOut, guestPicture } = this.props;
    const { basePrice, cleaningPrice, currency, weeklyDiscount, monthlyDiscount } = this.props;
    const { serviceFees, base, rates, specialPricing, bookingData } = this.props;
    let guestServiceFee = 0,
      hostServiceFee = 0,
      priceForDays = 0,
      hostServiceFeeType = "",
      hostServiceFeeValue = 0;
    let discount = 0,
      discountType,
      total = 0,
      totalWithoutFees = 0;
    let momentStartDate,
      momentEndDate,
      dayDifference,
      isAverage = 0;
    let currentDay,
      bookingSpecialPricing = [],
      isSpecialPriceAssigned = false;
    let isDayTotal = 0,
      taxRateFee = 0,
      totalWithoutServiceFee = 0,
      discountLessBasePrice = 0;

    const { locale } = this.props;
    moment.locale(locale);

    if (checkIn != null && checkOut != null) {
      momentStartDate = moment(checkIn);
      momentEndDate = moment(checkOut);
      dayDifference = momentEndDate.diff(momentStartDate, "days") + 1;
      // priceForDays = Number(basePrice) * Number(dayDifference);

      //New
      if (dayDifference > 0) {
        let stayedNights = [];
        // Find stayed nights
        for (let i = 0; i < dayDifference; i++) {
          let currentDate = moment(checkIn).add(i, "day");
          stayedNights.push(currentDate);
        }

        if (stayedNights && stayedNights.length > 0) {
          stayedNights.map((item, key) => {
            let isSpecialPricing;
            if (item) {
              let pricingRow, currentPrice;
              currentDay = moment(item).format("dddd").toLowerCase();
              // isSpecialPricing = specialPricing.find(o => moment(item).format('DD.MM.YYYY') == moment(o.blockedDates).format('DD.MM.YYYY'));
              isSpecialPricing =
                bookingData && bookingData.listBlockedPrice.find((o) => moment(item).format("DD.MM.YYYY") == moment(o.blockedDates).format("DD.MM.YYYY"));

              if (isSpecialPricing && isSpecialPricing.isSpecialPrice) {
                isSpecialPriceAssigned = true;
                currentPrice = Number(isSpecialPricing.isSpecialPrice);
              } else {
                currentPrice = Number(basePrice);
              }
              // Price object
              pricingRow = {
                blockedDates: item,
                isSpecialPrice: currentPrice,
              };
              bookingSpecialPricing.push(pricingRow);
            }
          });
        }
      }

      if (isSpecialPriceAssigned) {
        bookingSpecialPricing.map((item, index) => {
          priceForDays = priceForDays + Number(item.isSpecialPrice);
        });
      } else {
        bookingSpecialPricing.map((item, index) => {
          priceForDays = priceForDays + Number(item.isSpecialPrice);
        });
      }
    }

    isAverage = Number(priceForDays) / Number(dayDifference);
    isDayTotal = isAverage.toFixed(2) * dayDifference;
    priceForDays = isDayTotal;

    if (dayDifference >= 7) {
      if (monthlyDiscount > 0 && dayDifference >= 28) {
        discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
        discountType = monthlyDiscount + "% monthly price discount";
      } else {
        if (weeklyDiscount > 0) {
          discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
          discountType = weeklyDiscount + "% weekly price discount";
        }
      }
    }
    discountLessBasePrice = isDayTotal - discount;
    taxRateFee = taxRate && taxRate > 0 ? (discountLessBasePrice + cleaningPrice) * (Number(taxRate) / 100) : 0;
    // totalWithoutServiceFee = (isDayTotal + cleaningPrice + taxRateFee) - discount;
    totalWithoutServiceFee = isDayTotal + cleaningPrice - discount;
    const totalWithoutServiceFeeNoTax = totalWithoutServiceFee - (totalWithoutServiceFee / (100 + serviceFee)) * serviceFee;

    hostServiceFee = totalWithoutServiceFeeNoTax * (serviceFee / 100);

    let checkInStart = bookingData.listingData.checkInStart;
    let checkInEnd = bookingData.listingData.checkInEnd;

    total = priceForDays + guestServiceFee + cleaningPrice - discount;
    totalWithoutFees = priceForDays + cleaningPrice - discount;

    let checkInDate = checkIn != null ? moment(checkIn).format("DD.MM.YYYY") : "";
    let checkOutDate = checkOut != null ? moment(checkOut).format("DD.MM.YYYY") : "";
    let initialValues = {
      listId,
      listTitle,
      hostId,
      guestId,
      guests,
      checkIn,
      checkOut,
      basePrice,
      currency,
      cleaningPrice,
      discount,
      discountType,
      guestServiceFee,
      hostServiceFee,
      total: totalWithoutFees,
      bookingType,
      paymentType: "monthly",
      paymentMethod: "2",
      guestEmail,
      isSpecialPriceAssigned,
      bookingSpecialPricing: JSON.stringify(bookingSpecialPricing),
      isSpecialPriceAverage: isAverage.toFixed(2),
      dayDifference,
      taxRate: taxRateFee,
      checkInStart,
      checkInEnd,
      hostServiceFeeType,
      hostServiceFeeValue,
    };

    if (mandateReturned) {
      justPayment = true;
    }
    return (
      <Grid>
        <Row>
          <Col lg={7} md={7}>
              {prepayment && !justPayment && !preApproved ? (
                <PrePaymentForm
                  hostDisplayName={hostDisplayName}
                  houseRules={houseRules}
                  bookingType={bookingType}
                  allowedPersonCapacity={allowedPersonCapacity}
                  initialValues={initialValues}
                  listId={listId}
                  guestPicture={guestPicture}
                  isInsurable={securityClass !== "SK0"}
                  nextPage={this.setPaymentStep2}
                  monthlyPay={monthlyPay}
                  checkIn={checkIn}
                  checkOut={checkOut}
                  taxRate={taxRate}
                  isAverage={isAverage}
                />
              ) : (
                <PaymentForm
                  hostDisplayName={hostDisplayName}
                  houseRules={houseRules}
                  bookingType={bookingType}
                  allowedPersonCapacity={allowedPersonCapacity}
                  initialValues={initialValues}
                  listId={listId}
                  taxRate={taxRate}
                  guestPicture={guestPicture}
                  reservationId={reservationId}
                  prevPage={this.setPaymentStep1}
                  mandateReturned={mandateReturned}
                  //check for limit sepa payin
                  monthlyPay={monthlyPay}
                  checkIn={checkIn}
                  checkOut={checkOut}
                  isAverage={isAverage}
                  cleaningPrice={cleaningPrice}
                  total={total + taxRateFee}
                />
              )}
          </Col>
          <Col lg={5} md={5} className={s.positionSticky}>
            <div className={cx(s.summaryCard, s.colCenter)}>
              <div>
                <div className={s.postionRelative}>
                  <ListCoverPhoto
                    className={cx(s.bannerImage, s.backgroundCover)}
                    coverPhoto={coverPhoto}
                    listPhotos={listPhotos}
                    photoType={"x_medium"}
                    bgImage
                  />
                  <div className={s.secondSection}>
                    <div className={cx(s.displayInline, s.avatarWidth, s.vtrMiddle)}>
                      <div className={cx(s.profileAvatarLink, s.profileAvatarSection)}>
                        <Avatar source={hostPicture} title={hostDisplayName} className={s.profileAvatar} />
                      </div>
                      <div className={cx(s.textMuted, s.colorWhite, s.textEllipsis)}>
                        <span>{hostDisplayName}</span>
                      </div>
                    </div>
                    <div className={cx(s.displayInline, s.contentWidth, s.vtrMiddle)}>
                      <div className={cx(s.textLarge, s.colorWhite, s.propertyText)}>
                        <span>{listTitle}</span>
                      </div>
                      <div className={cx(s.textMuted, s.colorWhite, s.listItem)}>
                        <ul className={s.listStyle}>
                          <li>{listType}</li>
                        </ul>
                        <div className={s.colorWhite}>
                          {" "}
                          {city}, {state}, {country}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Panel className={s.panelHeader}>
                <div className={s.spaceTop2}>
                  <Row className={cx(s.spaceTop3, s.space3)}>
                    <Col xs={5} sm={5} className={s.textCenter}>
                      <div className={cx(s.textGray, s.space1, s.checkInText)}>
                        <span>
                          <FormattedMessage {...messages.checkIn} />
                        </span>
                      </div>
                      <div className={s.checkInDate}>{checkInDate}</div>
                    </Col>
                    <Col xs={1} sm={1}>
                      <img src={RightArrowIcon} className={s.arrrowIcon} />
                    </Col>
                    <Col xs={5} sm={5} className={cx(s.pullRight, s.textCenter)}>
                      <div className={cx(s.textGray, s.space1, s.checkInText)}>
                        <span>
                          <FormattedMessage {...messages.checkOut} />
                        </span>
                      </div>
                      <div className={s.checkInDate}>{checkOutDate}</div>
                    </Col>
                  </Row>
                  <hr className={s.horizondalLine} />
                </div>

                <PaymentDetails
                  basePrice={basePrice}
                  cleaningPrice={cleaningPrice}
                  currency={currency}
                  dayDifference={dayDifference}
                  priceForDays={priceForDays}
                  discount={discount}
                  discountType={discountType}
                  serviceFees={guestServiceFee}
                  total={total + taxRateFee}
                  bookingSpecialPricing={bookingSpecialPricing}
                  isSpecialPriceAssigned={isSpecialPriceAssigned}
                  isAverage={isAverage}
                  taxRateFee={taxRateFee}
                  taxRate={taxRate}
                  services={services}
                  service_description={service_description}
                  service_price_hour={service_price_hour}
                  service_price_kg={service_price_kg}
                  service_price_qm={service_price_qm}
                  service_price_palette={service_price_palette}
                  monthlyPay={monthlyPay}
                  checkIn={checkIn}
                  checkOut={checkOut}
                />
                {/* <div>
                  <span className={s.checkInText}><FormattedMessage {...messages.cancellationPolicy} />: </span>
                  <span className={s.policyColor}>{policyName}</span>
                </div> */}
              </Panel>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s)(Payment);

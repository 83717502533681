// General
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import  { FaMinus, FaPlus } from 'react-icons/fa';

// Translation
import { injectIntl } from 'react-intl';
import s from './IncrementButton.css';



class IncrementButtonMonth extends React.Component {
  static propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    labelSingluar: PropTypes.string,
    labelPlural: PropTypes.string,
    incrementBy: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.any
  };

  increment = (value, maxValue, incrementBy) => {
    if (value * 30 < maxValue) {
      return ((Number(value) + Number(incrementBy)) * 30);
    }
  }

  decrement = (value, minValue, incrementBy) => {
    if (value * 30 > minValue) {
      return ((Number(value) - Number(incrementBy)) * 30);
    }
  }

  render() {

    const { input: { onChange }, maxValue, minValue, labelSingluar, labelPlural, incrementBy, isFeminin } = this.props;
    const { formatMessage } = this.props.intl;
    let {input: { value}} = this.props;
    let label;
    let adjustedValue = Math.round(value / 30);
    //Defaultvalue = minValue when not set
    if(!value) {
      value = minValue;
    }
    if (adjustedValue > 1) {
      label = labelPlural;
    } else if(adjustedValue === 1) {
      label = labelSingluar;
    } else  if(adjustedValue === 0) {
      let prefix = isFeminin ? "Keine": "Kein";
      label = prefix + " " + labelSingluar
    }

    return (
      <div className={s.incrementDecrementButton}>
        <label className={s.incrementDecrementText}> {adjustedValue > 0 && adjustedValue} {label}</label>
        <Button className={s.iconButton} onClick={() => onChange(this.decrement(adjustedValue, minValue, incrementBy))}>
          <FaMinus />
        </Button>
        <Button className={s.iconButton} onClick={() => onChange(this.increment(adjustedValue, maxValue, incrementBy))}>
          <FaPlus />
        </Button>
      </div>
    )
  }
}

export default injectIntl(withStyles(s)(IncrementButtonMonth));

import React from 'react';
import LayoutJustLogo from '../../components/Layout/LayoutJustLogo';
import FunnelVermieter from './FunnelVermieter';

const title = 'Vermieter werden';

export default {

  path: '/funnel-vermieter',

  action() {
    return {
      title,
      component: <LayoutJustLogo><FunnelVermieter title={title} /></LayoutJustLogo>,
      status: 404,
    };
  },

};

import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import PayinBillingDetails from "./PayinBillingDetails";
// Components
import PayinConfirm from './PayinConfirm';

class PayinForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { page } = this.state;
    const { initialValues,reservationId } = this.props;

    return (
      <div>
       

        {page === 1 && (
          <PayinBillingDetails
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            initialValues={initialValues}
            reservationId={reservationId}

          />
        )}
        {/* {page === 2 && (
          <BankAccount
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            initialValues={initialValues}
          />
        )} */}
         {page === 2&& (
          <PayinConfirm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            initialValues={initialValues}
            reservationId={reservationId}
          />
        )}
      </div>
    );
  }
}

export default PayinForm;

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { compose, gql, graphql } from "react-apollo";
import AsSeenOn from "../../components/Home/AsSeenOn/AsSeenOn";
//Components
import BannerCaption from "../../components/Home/BannerCaption/BannerCaption";
import ContentBox1 from "../../components/static/ContentBox1/ContentBox1";
import ContentBox3 from "../../components/static/ContentBox3/ContentBox3";
import ContentBox4 from "../../components/static/ContentBox4/ContentBox4";
import ContentBox5 from "../../components/static/ContentBox5/ContentBox5";
import FaqBox from "../../components/static/FaqBox/FaqBox";
import ReviewBox from "../../components/static/ReviewBox/ReviewBox";
import HomeBanner from "../../components/Home/HomeBanner/HomeBanner";

import ContentBox1_1_Image from "./contentBox1_1.svg";
import ContentBox1_2_Image from "./contentBox1_2.svg";
import ContentBox1_3_Image from "./contentBox1_3.svg";
import contentBox3_1_Image from "./contentBox3_1.svg";
import contentBox3_2_Image from "./contentBox3_2.svg";
import contentBox3_3_Image from "./contentBox3_3.svg";
import contentBox3_4_Image from "./contentBox3_4.svg";
import contentBox3_image1 from "./contentBox3_image1.svg";
import contentBox3_image2 from "./contentBox3_image2.svg";
import contentBox3_image3 from "./contentBox3_image3.svg";
import contentBox3_image4 from "./contentBox3_image4.svg";
import keckeisenImage from "./keckeisen.png";
import bogischImage from "./bogisch.png";
import gutmannImage from "./gutmann.png";
import BvmwImage from "./bvmw.png";
import getHomeBanner from "./getHomeBanner.graphql";
import s from "./HowItWorks.css";

class HowItWorks extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    getHomeBanner: {
      loading: true,
    },
  };

  render() {
    const { getHomeBannerData } = this.props;

    const contentBox1Items = [
      {
        icon: ContentBox1_1_Image,
        headline: "Fläche finden",
        text: "Lager- und Produktionsflächen in ganz Deutschland finden. Suchen Sie genau nach Ihren Anforderungen und bleiben Sie über potentielle Objekte auf dem Laufenden.",
      },
      {
        icon: ContentBox1_2_Image,
        headline: "Fläche mieten",
        text: "Stellen Sie eine Anfrage und nehmen Sie mit dem Anbieter Kontakt auf. Profitieren Sie von einer schnellen und digitalen Abwicklung und sparen Sie Zeit und Geld.",
      },
      {
        icon: ContentBox1_3_Image,
        headline: "Fläche nutzen",
        text: "Starten Sie mit Ihrer neuen Fläche durch und überlassen Sie die Zahlung und Abwicklung unserem smarten und digitalen Mietprozess. Einfacher war mieten noch nie.",
      },
    ];

    const contentBox3Items = [
      {
        icon: contentBox3_1_Image,
        headline: "Mietvertrag",
        image: contentBox3_image1,
        textItems: ["Klare & fixe Bedingungen", "Rechtsicherer Buchungsprozess", "100% klare Vertragsbedingungen"],
      },
      {
        icon: contentBox3_2_Image,
        headline: "Zahlungswesen",
        image: contentBox3_image2,
        textItems: ["Automatische Zahlungsabwicklung", "Digitale Treuhandkonten", "Monatlich und Gesamtzahlung", "Keine Kaution notwendig"],
      },
      {
        icon: contentBox3_3_Image,
        headline: "Verwaltung",
        image: contentBox3_image3,
        textItems: ["Rechnung", "Checklisten", "Buchungsübersicht", "Benachrichtigung"],
      },
      {
        icon: contentBox3_4_Image,
        headline: "Sicherheit",
        image: contentBox3_image4,
        textItems: ["klare Mietbedingungen", "optionale Inhaltsversicherung", "Ein- & Auszugsschutz", "24/Service"],
      },
    ];
    const faqItems = [
      {
        triggerText: "Wie funktioniert die Bezahlung?",
        text: "Grundsätzlich bietet Expozed1 alle gängigen Zahlungsmethoden (Überweisung, SEPA-Lastschriftmandat, Kreditkarte, etc.) an. Durch unser digitales DSP2-Treuhandsystem können Mietzahlungen einfach und sicher abgewickelt werden und je nach Auswahl des Anbieters genutzt werden.",
      },
      {
        triggerText: "Wie sieht der Mietvertrag aus?",
        text: "Expozed1 hat es sich zum Ziel gesetzt die Vermietung einfacher und sicherer als je zuvor zu machen. Kurzfristige Mietverhältnisse bis 12 Monate können durch unsere digitale Abwicklung daher schon jetzt ohne schriftlichen Mietvertrag abgewickelt werden und stehen jederzeit digital zum Download bereit.",
      },
      {
        triggerText: "Wie kann ich sicherstellen, dass die Übergabe reibungslos abläuft?",
        text: "Nach erfolgreichem Abschluss des Mietvertrages, erhalten beide Parteien kurz vor Einzug ein Übergabeprotokoll, durch welches dies klar aufgenommen werden kann.",
      },
      {
        triggerText: "Welche Vorteile bietet Expozed1 im Vergleich zu anderen Portalen?",
        text: "Wir sind überzeugt, dass mit Hilfe digitalisierter Mietprozesse und frühzeitiger Sichtbarkeit noch mehr Lager- und Produktionsflächen mobilisiert werden können. Durch das Matching konkreter Anfragen mit den genau passenden Ressourcen können wir es schaffen noch effizienter zur passenden Ressource zu kommen.",
      }
    ];

    const reviewBoxItems = [
      {
        headline: "Eigentümer & Investoren",
        subHeadline: "Kundenmeinung",
        checkItems: ["Frühzeitig Mieter finden", "Qualifizierte Anfragen erhalten", "Digital & Smart vermieten"],
        citiation: "Als Investor bietet expozed1.de mir die Möglichkeit, meine Flächen frühzeitig am Markt zu platzieren und qualifizierte Mietanfragen zu erhalten",
        author: "Holger Gutmann, Immobilieninvestor",
        authorLink: "www.gutmanngruppe.com",
        authorImage: gutmannImage ,
      },
      {
        headline: "Logistik",
        subHeadline: "Kundenmeinung",
        checkItems: ["Leerstände optimieren", "Dauerhafte Sichtbarkeit", "Zusätzliches Vermarktungstool"],
        citiation: "Die Ansprüche an Unternehemen werden immer höher. Schnelle Entscheidungen werden möglich wenn alle Informationen vorliegen. Expozed1 bietet eine Platform die dies ermöglicht",
        author: "Helge Bogisch, Bracchi Transport & Logistik",
        authorLink: "www.bracchi.it",
        authorImage: bogischImage,
      },
      {
        headline: "Handel & E-Commerce",
        subHeadline: "Branchenbeispiel",
        checkItems: ["Mieter in ganz Deutschland erreichen", "Freie Ressourcen flexibel auslasten", "Kein Verwaltungsaufwand"],
        citiation: "Vor allem in aktuellen Zeiten, bietet expozed1.de die perfekte Möglichkeit zusätzliche Einnahmen zu generieren",
        author: "Stefan Keckeisen, Geschäftsführer Intact Batterien",
        authorLink: "www.intact-batterien.de",
        authorImage: keckeisenImage,
      },
    ];

    const contentBox4Data = {
      headline: "Ihre perfekte Lagerfläche genau nach Bedarf",
      subHeadline: "DIE PLATTFORM",
      checkItems: [
        {
          headline: "Sofort verfügbar",
          text: "Deutschlandweit. Starten Sie sofort durch.",
        },
        {
          headline: "Individuelle Laufzeit",
          text: "Sie entscheiden über die Laufzeit. Flexibel und einfach.",
        },
        {
          headline: "Einfach und unkompliziert",
          text: "Digitaler Mietprozess. Smarte Zahlungsabwicklung.",
        },
        {
          headline: "Fixer Mietpreis.",
          text: "Profitieren Sie von klaren und fixen Mietpreisen.",
        },
      ],
      text: "Lager- und Produktionsflächen in ganz Deutschland mieten. Wir bringen Mieter und Eigentümer zusammen.",
      image: BvmwImage,
    };

    return (
      <div className={s.root}>
        <HomeBanner
          cssClass={"offer-space"}
          headlineText={"Die passende Lagerfläche für jede Situation. Sofort."}
          searchboxHeadline1={"Jetzt Fläche finden"}
          searchboxHeadline2={"Welche Art von Fläche möchten Sie anbieten?"}
          searchboxHeadline3={""}
          searchboxButtonText={"Flächen anzeigen"}
          link={"/funnel-mieter"}
          showIcon={true}
        />

        <div>
          <AsSeenOn />

          <div className={s.contentBoxesBg2}>
            <div className={s.container}>
              <ContentBox4 data={contentBox4Data} />
            </div>
          </div>
          <div className={s.contentBoxesBg}>
            <div className={s.container}>
              <ContentBox1 headline={"So einfach funktioniert’s"} subHeadline={"Jetzt loslegen"} items={contentBox1Items} />
            </div>
          </div>

          <div className={s.contentBoxesBg2}>
            <div className={s.container}>
              <ContentBox3
                items={contentBox3Items}
                headline={"Die All-in-One Lösung"}
                subHeadline={"ALLES ERLEDIGT"}
                text={"Alle Prozesse digital, smart und so einfach wie möglich!"}
              />
            </div>
          </div>

          <div className={s.container}>
            <ReviewBox bg={"#F6F9FC"} items={reviewBoxItems} />
          </div>
          <div className={s.contentBoxesBg2}>
            <div className={s.container}>
              <ContentBox5 />
            </div>
          </div>
          <div className={s.contentBoxesBg}>
            <div className={s.container}>
              <FaqBox items={faqItems} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(
    gql`
      query getBanner {
        getBanner {
          id
          title
          content
        }
      }
    `,
    {
      name: "getBannerData",
      options: {
        ssr: true,
      },
    }
  ),
  graphql(getHomeBanner, {
    name: "getHomeBannerData",
    options: {
      ssr: true,
      //fetchPolicy: 'network-only'
    },
  })
)(HowItWorks);

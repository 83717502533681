// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { reduxForm, formValueSelector } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

import CustomCheckbox from "../CustomCheckbox";

// External Component
import { Checkbox } from "react-icheck";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Internal Component
import DayDragCalendar from "../../components/DayDragCalendar";
import defaultPic from "./large_no_image.png";

import updateStep3 from "./updateStep3";

class Calendar extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    disabledDates: PropTypes.array,
    blockedDates: PropTypes.array,
  };

  static defaultProps = {
    listingSteps: {
      step3: "inactive",
      listing: {
        isPublished: false,
        isApproved: false,
        isPaused: false,
      },
    },
    disabledDates: [],
    blockedDates: [],
    availableDatesPrices: [],
    availableDates: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      sources: [],
      isConfirmed: false,
    };
  }

  componentDidMount() {
    const { listBlockedPrice } = this.props;
    let sources = [];
    let sourceObject = {};

    listBlockedPrice &&
      listBlockedPrice.map((item, key) => {
        sourceObject = {};
        sourceObject["isSpecialPrice"] = item.isSpecialPrice;
        sourceObject["blockedDates"] = item.blockedDates;
        sources.push(sourceObject);
      });
    this.setState({ sources });
  }

  componentWillReceiveProps(nextProps) {
    const { listBlockedPrice } = nextProps;
    let sources = [];
    let sourceObject = {};

    listBlockedPrice &&
      listBlockedPrice.map((item, key) => {
        sourceObject = {};
        sourceObject["isSpecialPrice"] = item.isSpecialPrice;
        sourceObject["blockedDates"] = item.blockedDates;
        sources.push(sourceObject);
      });
    this.setState({ sources });
  }

  setConfirmed = (value) => {
    this.setState({ isConfirmed: value });
  };

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage } = this.props;
    const { disabledDates, blockedDates, listId, isActiveListing } = this.props;
    const { minNight, maxNight, houseRules, checkInEnd, checkInStart } = this.props;
    const { cancellationPolicy, maxDaysNotice, maxDaysNoticeStart, maxDaysNoticeEnd, bookingNoticeTime } = this.props;
    const { availableDates, availableDatesPrices, baseCurrency, currency, availableCurrencies } = this.props;
    const { basePrice, cleaningPrice, stepTwoDetails } = this.props;

    const { sources, isConfirmed } = this.state;

    let isAdminCurrency;
    isAdminCurrency = availableCurrencies && availableCurrencies.find((o) => o.isBaseCurrency == true);
    let title = stepTwoDetails && stepTwoDetails.title;
    let description = stepTwoDetails && stepTwoDetails.description;
    let coverPhoto = stepTwoDetails && stepTwoDetails.coverPhoto;
    let coverImage = stepTwoDetails && stepTwoDetails.listPhotos.find((o) => o.id == coverPhoto);
    let path = "/images/upload/x_medium_";
    let showImage;
    if (coverImage) {
      showImage = path + coverImage.name;
    } else if (!coverImage && stepTwoDetails && stepTwoDetails.listPhotos && stepTwoDetails.listPhotos.length > 0) {
      showImage = path + (stepTwoDetails && stepTwoDetails.listPhotos && stepTwoDetails.listPhotos[0].name);
    } else {
      showImage = defaultPic;
    }
    if(!maxDaysNotice) {
      return (<div></div>)
    }
    return (
      <Grid fluid>
        <Row className={cx(s.landingContainer, s.fullWidthCalendar)}>
          <Col xs={12} sm={12} md={12} lg={12} className={s.landingContent}>
            <div>
              <h3 className={s.landingContentTitle}>
                <FormattedMessage {...messages.calendar} />
              </h3>
              <p>
                Sie können Ihren Kalender ganz einfach bearbeiten - klicken Sie dazu einfach auf ein Datum, um es zu blockieren (rot) oder wieder freizugeben
                (blau) und bestätigen Sie es durch speichern.
              </p>
              <p>
                Sollten Sie Ihren Kunden Sonderpreise anbieten wollen, geben Sie für den ausgewählten Zeitraum einen Sonderpreis an und bestätigen Sie diesen
                durch speichern.
              </p>
              <p>Sie können nach dem Veröffentlichen jederzeit Änderungen vornehmen. </p>{" "}
              <div className={s.lableWeight}>
                <p className={cx(s.bookedWidth)}>
                  <span className={s.notAvailableColor}></span>
                  <FormattedMessage {...messages.booked} />
                </p>
                <p className={s.calenderColorText}>
                  <span className={s.bookedColor}></span>
                  <FormattedMessage {...messages.notAvailable} />
                </p>
                <p className={s.calenderColorText}>
                  <span className={s.availableColor}></span>
                  <FormattedMessage {...messages.availableLabel} />
                </p>
                {/* <p className={s.calenderColorText}>
                  <span className={s.specialColor}></span>
                  <FormattedMessage {...messages.sessionPrice} />
                </p> */}
              </div>
              <h3 className={s.landingStep3}>
                <FormattedMessage {...messages.unBlockInfo} />
              </h3>
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <FormGroup className={cx(s.formGroup, s.posRelative)}>
                    {/* <div className={s.imagefeed}>
                      <div className={s.caListimg} style={{ backgroundImage: `url(${showImage})` }}>
                      </div>
                      <div className={s.caListPara}>
                        <h2>{title}</h2>
                        <p>{description}</p>
                      </div>
                    </div> */}
                    <DayDragCalendar
                      formName={"ListPlaceStep3"}
                      disabledDates={disabledDates}
                      blockedDates={blockedDates}
                      listId={listId}
                      availableDates={availableDates}
                      availableDatesPrices={availableDatesPrices}
                      sources={sources}
                      minNight={minNight}
                      maxNight={maxNight}
                      houseRules={houseRules}
                      checkInEnd={checkInEnd}
                      checkInStart={checkInStart}
                      cancellationPolicy={cancellationPolicy}
                      maxDaysNotice={maxDaysNotice}
                      maxDaysNoticeStart={maxDaysNoticeStart}
                      maxDaysNoticeEnd={maxDaysNoticeEnd}
                      bookingNoticeTime={bookingNoticeTime}
                      baseCurrency={baseCurrency}
                      currency={currency}
                      isAdminCurrency={isAdminCurrency}
                      basePrice={basePrice}
                      cleaningPrice={cleaningPrice}
                    />
                  </FormGroup>
                  {!isActiveListing && (<div className={s.checkboxInfo}>
                    <p>
                      Ein guter Anbieter hält seinen Kalender aktuell. Mieter können verfügbare Daten sofort buchen. Halten Sie Ihren Kalender daher immer
                      aktuell, damit Sie nur dann Buchungen erhalten wenn Sie auch wirklich vermieten können. Stornierungen bringen Pläne von Mietern
                      durcheinander. Sollten Sie eine Buchung stornieren, weil Ihr Kalender nicht korrekt eingestellt war, werden ggf. entstehende Aufwände an
                      Sie weitergegeben und die Fläche kann für diesen Zeitpunkt nicht neu vermietet werden.
                    </p>
                    <CustomCheckbox
                      name="confirmed"
                      value={true}
                      checked={isConfirmed}
                      className={cx("icheckbox_square-green", s.checkbox)}
                      onChange={(event) => {
                        // const newValue = [...input.value];
                        this.setConfirmed(event);
                      }}
                    />
                    <h4 className={s.checkboxHeadline}>Ja, ich halte meinen Kalender auf dem neusten Stand.</h4>
                  </div>)}
                  
                </div>
                {!isActiveListing && (
                  <div className={cx(s.nextPosition, s.nextPositionCalendar)}>
                    <div className={s.nextBackButtonCalendar}>
                      <hr className={s.horizontalLineThrough} />

                      <FormGroup className={s.formGroup}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                          <Button
                            className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)}
                            // onClick={() => previousPage("min-max-nights")}
                            onClick={() => previousPage("Verfügbarkeit")}
                          >
                            <FormattedMessage {...messages.back} />
                          </Button>
                          <Button
                            disabled={!isConfirmed}
                            className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)}
                            onClick={() => nextPage("Nutzungsbedingungen")}
                          >
                            <FormattedMessage {...messages.next} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Calendar = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep3,
})(Calendar);

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep3"); // <-- same as form name

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  disabledDates: selector(state, "disabledDates"),
  blockedDates: selector(state, "blockedDates"),
  listBlockedPrice: selector(state, "listBlockedPrice"),
  minNight: selector(state, "minNight"),
  maxNight: selector(state, "maxNight"),
  houseRules: selector(state, "houseRules"),
  checkInStart: selector(state, "checkInStart"),
  checkInEnd: selector(state, "checkInEnd"),
  bookingNoticeTime: selector(state, "bookingNoticeTime"),
  maxDaysNotice: selector(state, "maxDaysNotice"),
  maxDaysNoticeStart: selector(state, "maxDaysNoticeStart"),
  maxDaysNoticeEnd: selector(state, "maxDaysNoticeEnd"),
  cancellationPolicy: selector(state, "cancellationPolicy"),
  availableDates: selector(state, "availableDates"),
  availableDatesPrices: selector(state, "availableDatesPrices"),
  currency: selector(state, "currency"),
  availableCurrencies: state.currency.availableCurrencies,
  basePrice: selector(state, "basePrice"),
  cleaningPrice: selector(state, "cleaningPrice"),
  stepTwoDetails: state.calendar.stepTwoDetails,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Calendar)));

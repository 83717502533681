import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Link from "../Link";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Logo.css";
import cx from "classnames";
import history from "../../core/history";
import Expozed1Logo from "./expozed1_logo.svg";
import Expozed1LogoWhite from "./expozed1_logo_white.svg";
class Logo extends Component {
  static propTypes = {
    siteName: PropTypes.string.isRequired,
    link: PropTypes.string,
    className: PropTypes.string,
    showMenu: PropTypes.bool,
  };

  static defaultProps = {
    siteName: null,
    logoImage: null,
    link: "/",
    logoHeight: "63",
    logoWidth: "34",
    showMenu: false,
  };

  render() {
    const { link, className, logoHeight, logoWidth, showMenu, homeLogoHeight, homeLogoWidth, layoutType } = this.props;

    let defaultHomeLogoWidth = homeLogoWidth && homeLogoWidth != null ? homeLogoWidth : "63";
    const windowLocation = typeof window !== "undefined" ?  window.location.pathname: null;
    let location = history.location ? history.location.pathname :  windowLocation;
    const locationsWhiteLogo = ["/", "/so-funktionierts", "/flaeche-anbieten"];
    const logoSrc = locationsWhiteLogo.includes(location) ? Expozed1LogoWhite : Expozed1Logo;

    return (
      <Link to={link} className={className}>
        <img src={logoSrc} className={cx(!showMenu ? "displayBlock" : "displayNone")} height={28} />
      </Link>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  showMenu: state.toggle.showMenu,
  layoutType: state.siteSettings.data.homePageType,
});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(Logo));

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox3.css";
import { Grid, Row, Col, Button } from "react-bootstrap";
import Link from "../../Link/Link";
import ArrowDown from "./arrow_down.svg";
class ContentBox3 extends React.Component {
  static propTypes = {
    items: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  changeSelectedItem = (index) => {
    this.setState({ selectedIndex: index });
  };

  render() {
    const { items, headline, subHeadline, text } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={cx(s.content)}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className={s.subHeadline}>{subHeadline}</h4>
            <h2 className={s.headline}>{headline}</h2>
            <div className={s.text}>{text}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <div className={s.itemWrapper}>
              {items.map((item, index) => {
                return (
                  <Col xs={12} sm={12} md={6} lg={6} key={index}>
                    <div className={cx(s.item, index === selectedIndex ? s.active : "")} onClick={() => this.changeSelectedItem(index)}>
                      <img src={item.icon} />
                      <h5 className={s.itemHeadline}>{item.headline}</h5>
                      <ul>
                        {item.textItems.map((textItem, index) => {
                          return <li key={index}>{textItem}</li>;
                        })}
                      </ul>
                    </div>
                  </Col>
                );
              })}
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <img className={s.itemImage} src={items[selectedIndex].image} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(ContentBox3);

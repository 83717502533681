import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url, sitename } from '../../../config';

class UboFail extends Component {
	static propTypes = {
		content: PropTypes.shape({
			kycType: PropTypes.string.isRequired,
			kycError: PropTypes.string.isRequired
		}).isRequired
	};

	render() {
		const textStyle = {
			color: '#404040',
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px',
		};

		const linkText = {
			color: '#0667B3',
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}


		//@TODO text
		const kycErrorMapping = {
			"INCOMPLETE": "Aus folgenden Gründen schlug die Überprüfung fehl: ",
			"DECLARATION_DO_NOT_MATCH_UBO_INFORMATION": "Die bereitgestellten Informationen stimmen nicht mit den Informationen überein, die uns über den Anteilseigner vorliegen.",
			"MISSING_UBO": "Es fehlt ein Dokument für die Überprüfung eines Anteilseigners.",
			"SHAREHOLDERS_DECLARATION_NEEDED": "Die Statuten sind nicht einsehbar, so dass wir nicht überprüfen können, ob die in der Verifizierung gemachten Angaben korrekt sind: Wir brauchen eine Satzung der Gesellschaft.",
			"ORGANIZATION_CHART_NEEDED": "Im Falle einer komplexen Holding-Architektur benötigen wir das Organigramm.",
			"DOCUMENT_NEEDED": "Wir warten auf die Statuten und / oder den Registrierungsnachweis, um zu überprüfen, ob alles korrekt ist.",
			"WRONG_UBO_INFORMATION": "Name, Vorname oder andere Angaben sind falsch eingegeben.",
			"SPECIFIC_CASE": "Die UBO Informationen sind noch komplett."		}


		const { content: { firstName, lastName, companyName, kycError, kycType, kycErrorReason, logo } } = this.props;
		let kycErrorText = kycErrorMapping[kycError];

		if(kycError === "SPECIFIC_CASE") {
			kycErrorText = kycErrorMapping[kycError] + kycErrorReason
		}
		if(kycError === "MISSING_UBO") {
			kycErrorText = kycErrorMapping[kycError] + kycErrorReason
		}
		if(kycError === "INCOMPLETE") {
			kycErrorText = kycErrorMapping[kycError] + kycErrorMapping[kycErrorReason]
		}

		return (
			<Layout>
				<Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
				<div>
					<Table width="100%" >
						<TBody>
							<TR>
								<TD style={textStyle}>
									<EmptySpace height={20} />
									<div>
										Hallo {firstName},
					        </div>
									<EmptySpace height={20} />
									<div>
										Leider konnten wir die eingereichten Dokumente zur Überprüfung Ihrer Anteilseigner (UBO)
										nicht erfolgreich verifizieren.{" "}
										{kycErrorText}
										<br />
										Bitte überprüfen Sie Ihre Angaben und reichen Sie die Daten erneut zur Prüfung ein.
									</div>

									<EmptySpace height={20} />
									<div>
										Vielen Dank, <br />
										Ihr {sitename} Team
					        </div>
								</TD>
							</TR>
						</TBody>
					</Table>
					<EmptySpace height={40} />
				</div>
				<Footer />
				<EmptySpace height={20} />
			</Layout>
		);
	}
}

export default UboFail;

import React from 'react';
import Layout from '../../components/Layout/Layout';

import UserLayout from '../../components/Layout/UserLayout';

import KycVerification from './KycVerification';

const title = 'Verifizierung';

export default {

  path: '/kyc-verification',

  action({ store }) {

    // From Redux Store
    let isAuthenticated = store.getState().runtime.isAuthenticated;


  
    if (!isAuthenticated) {
      return { redirect: '/login' };
    }



    return {
      title,
      component: <UserLayout><KycVerification title={title} /></UserLayout>,
    };
  },

};



exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3Mfli{background:#fff;color:#333;padding:0}._3A5EQ{margin:0 auto;border-top:1px solid #dce0e0;max-width:100%;max-width:var(--max-container-width);font-size:1.5em;display:block}._2D_fP{max-width:1080px;max-width:var(--max-content-width);margin:0 auto}._3RFOD{display:block}._3QnDr{padding:40px 30px;border-right:1px solid #dce0e0;display:table-cell;float:none}._3QnDr:last-of-type{border-right:1px solid transparent}._2jIkL{float:left;display:table;font-size:33px;height:33px;width:33px;line-height:20px;margin-right:22px}._2kiVx{display:table;text-align:left}._2m_UX{color:#404040;font-size:15px;font-weight:700;display:block;margin-bottom:10px;line-height:1}._2qZmq{color:#404040;font-size:14px;font-weight:400;line-height:1.43;padding-top:10px}._3K-AP{color:#0667b2}@media screen and (max-width:767px){._3QnDr{display:block;float:left;border-bottom:1px solid #dce0e0}._3QnDr:last-of-type{border-bottom:1px solid transparent}}._33XFx{font-size:2.4em;text-align:center;color:#707070;fill:#707070;max-height:34px;max-width:34px}", ""]);

// exports
exports.locals = {
	"root": "_3Mfli",
	"container": "_3A5EQ",
	"feedbackContainer": "_2D_fP",
	"feedbackRow": "_3RFOD",
	"feedbackBox": "_3QnDr",
	"feedbackIcon": "_2jIkL",
	"feedbackContent": "_2kiVx",
	"landingLabel": "_2m_UX",
	"landingCaption": "_2qZmq",
	"modalCaptionLink": "_3K-AP",
	"overviewIcon": "_33XFx"
};
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { compose, gql, graphql } from "react-apollo";
import AsSeenOn from "../../components/Home/AsSeenOn";
//Components
import BannerCaption from "../../components/Home/BannerCaption";
import ContentBox from "../../components/Home/ContentBox/ContentBox";
import ContentBox2 from "../../components/Home/ContentBox2/ContentBox2";
import ContentBox3 from "../../components/Home/ContentBox3/ContentBox3";
import HomeSlider from "../../components/Home/HomeSlider";
import Layout1 from "../../components/Home/Layout1";
import Layout3 from "../../components/Home/Layout3";
import Layout4 from "../../components/Home/Layout4";
import ReadyBox from "../../components/Home/ReadyBox/ReadyBox";
import ReviewBox from "../../components/Home/ReviewBox/ReviewBox";
import SearchForm from "../../components/Home/SearchForm";
import SeeAll from "../../components/Home/SeeAll";
import Loader from "../../components/Loader";
import Box1Image from "./box1.png";
import Box2Image from "./box2.svg";
import Box3Image from "./box3.svg";
import getHomeBanner from "./getHomeBanner.graphql";
import getMostViewedListingQuery from "./getMostViewedListing.graphql";
import getPopularLocationQuery from "./getPopularLocation.graphql";
// Graphql
import getRecommendQuery from "./getRecommend.graphql";
import s from "./Home.css";

class Homepage extends React.Component {
  static propTypes = {
    getRecommendData: PropTypes.shape({
      loading: PropTypes.bool,
      getRecommendData: PropTypes.array,
    }),
    getImageBannerData: PropTypes.shape({
      loading: PropTypes.bool,
      getImageBanner: PropTypes.object,
    }),
    getMostViewedListingData: PropTypes.shape({
      loading: PropTypes.bool,
      GetMostViewedListing: PropTypes.array,
    }),
    getPopularLocationData: PropTypes.shape({
      loading: PropTypes.bool,
      GetMostViewedListing: PropTypes.array,
    }),
    getHomeBannerData: PropTypes.shape({
      loading: PropTypes.bool,
      getHomeBanner: PropTypes.object,
    }),
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    getRecommendData: {
      loading: true,
    },
    getImageBannerData: {
      loading: true,
    },
    getMostViewedListingData: {
      loading: true,
    },
    getPopularLocationData: {
      loading: true,
    },
    homeBannerImages: {
      loading: true,
    },
    getHomeBanner: {
      loading: true,
    },
  };

  render() {
    const { getRecommendData, getImageBannerData, getMostViewedListingData, getBannerData } = this.props;
    const { getPopularLocationData, layoutType, getStaticBlockInfoData, homeBannerImages } = this.props;
    const { getHomeBannerData } = this.props;
    let getHomeBanner = getHomeBannerData && getHomeBannerData.getHomeBanner;

    const contentBox1_1Data = {
      title: "Sofort verfügbar, flexible Laufzeit",
      preTitle: "Mieter",
      description: "Finden Sie die passende Fläche innerhalb weniger Sekunden und steigern Sie ihre Flexibilität durch individuelle Laufzeiten.",
      buttonLabel: "Fläche finden",
      buttonLink: "/search?&guests=1",
      image: Box1Image,
      listItems: [],
      itemPlacement: "right",
      animated: true,
      fadeDelay: 2000,
    };
    const contentBox1_2Data = {
      title: "Weniger Aufwand, mehr Rendite",
      preTitle: "Vermieter",
      description: "Sorgen Sie für eine optimale Auslastung Ihrer Immobilie und profitieren Sie durch automatisierte Verwaltungsprozesse.",
      buttonLabel: "Fläche anbieten",
      buttonLink: "/become-a-host?mode=new",
      image: Box2Image,
      listItems: [],
      itemPlacement: "left",
      animated: true,
      fadeDelay: 2000,
    };
    const contentBox1_3Data = {
      title: "Rundum abgesichert",
      preTitle: "Sicherheit",
      description: "Vertrauen ist gut - Transparenz & klare Vertragsbedingungen sind besser.",
      buttonLabel: "Jetzt loslegen",
      buttonLink: "/dashboard",
      image: Box3Image,
      // listItems: ["Mietvertrag", "Zahlungsabwicklung", "Verifizierung", "Inhaltsversicherbar"],
      listItems: [],
      itemPlacement: "right",
      animated: true,
      fadeDelay: 2000,
    };

    return (
      <div className={s.root}>
        {layoutType && layoutType == 1 && <Layout1 layoutType={layoutType} data={getBannerData} homeBannerImages={getHomeBanner} />}

        {layoutType && layoutType == 3 && <Layout3 layoutType={layoutType} data={getBannerData} homeBannerImages={getHomeBanner} />}

        {layoutType && layoutType == 4 && <Layout4 layoutType={layoutType} data={getBannerData} homeBannerImages={getHomeBanner} />}

        <div>
          {layoutType && layoutType == 2 && (
            <div className={s.pageContainer}>
              <BannerCaption data={getBannerData} />
              <div className={s.pageContainer}>
                <SearchForm />
              </div>
            </div>
          )}
          {/* {
            layoutType && layoutType == 2 && <div className={s.pageContainer}>
              <SearchForm />
            </div>
          } */}

          {getRecommendData.loading && getMostViewedListingData.loading && (
            // {getRecommendData.loading && getImageBannerData.loading && getMostViewedListingData.loading && (
            <div>
              <Loader type="text" />
            </div>
          )}
          <AsSeenOn />
          {/* {!getRecommendData.loading && !getImageBannerData.loading && !getMostViewedListingData.loading && !getStaticBlockInfoData.loading && ( */}
          {!getRecommendData.loading && !getMostViewedListingData.loading && (
            <div >
              <h2 className={cx(s.findPlace, "hidden-xs")}>Finden Sie jetzt ihre geeignete Gewerbefläche</h2>
              {getRecommendData && getRecommendData.getRecommend.length > 0 && (
                <div className={s.container}>
                  <h3 className={s.containerTitle}>
                    {/* <FormattedMessage {...messages.recommended} /> */}
                    Aktuelle Flächen
                    {/* PASCAL TEMP CHANGE */}
                    {/* <SeeAll /> */}
                  </h3>
                  <HomeSlider data={getRecommendData.getRecommend} />
                </div>
              )}
              {/* {getMostViewedListingData && getMostViewedListingData.GetMostViewedListing.length > 0 && (
                <div className={cx(s.pageContainer, s.container)}>
                  <h3 className={s.containerTitle}>
                    <FormattedMessage {...messages.mostViewed} />
                    <SeeAll />
                  </h3>
                  <HomeSlider data={getMostViewedListingData.GetMostViewedListing} />
                </div>
              )} */}
              {/* 
              {
                getPopularLocationData.getPopularLocationAdmin && getPopularLocationData.getPopularLocationAdmin.length > 0 && <div className={s.pageContainer}>
                  <h3 className={cx(s.containerTitle, s.marginLeft)}>
                    <FormattedMessage {...messages.popularLocation} />
                  </h3>
                  <PopularLocationGrid
                    data={getPopularLocationData.getPopularLocationAdmin}
                  />
                </div>
              } */}
              <div className={s.contentBoxesBg}>
                <div className={s.container}>
                  <ContentBox data={contentBox1_1Data} />
                </div>
              </div>
              <div className={s.contentBoxesWhite}>
                <div className={s.container}>
                  <ContentBox data={contentBox1_2Data} />
                </div>
              </div>
              <div className={s.contentBoxesBg}>
                <div className={s.container}>
                  <ContentBox data={contentBox1_3Data} />
                </div>
              </div>

              <ReviewBox />
              <div className={s.container}>
                <ContentBox2 />
              </div>

              <div className={s.linearBg}>
                <div className={s.container}>
                  <ContentBox3 />
                </div>
              </div>

              {/* <div className={s.container}>
                <FloatingBox />
              </div> */}

              <div className={cx(s.container, s.linearBg, s.readyBox)}>
                <ReadyBox />
              </div>
              {/* {getImageBannerData.getImageBanner != null && (
                <div className={cx(s.pageContainer, s.container)}>
                  <NewsBox data={getImageBannerData.getImageBanner} />
                </div>
              )}

              {getStaticBlockInfoData &&
                !getStaticBlockInfoData.loading &&
                getStaticBlockInfoData.getStaticInfo &&
                getStaticBlockInfoData.getStaticInfo[0].isEnable == true && (
                  <div className={cx("hidden-xs", s.container)}>
                    <HomeKindofTrip data={getStaticBlockInfoData} />
                  </div>
                )} */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(
    gql`
      query getBanner {
        getBanner {
          id
          title
          content
        }
      }
    `,
    {
      name: "getBannerData",
      options: {
        ssr: true,
      },
    }
  ),
  graphql(getHomeBanner, {
    name: "getHomeBannerData",
    options: {
      ssr: true,
      //fetchPolicy: 'network-only'
    },
  }),
  graphql(getRecommendQuery, {
    name: "getRecommendData",
    options: {
      ssr: true,
      //fetchPolicy: 'network-only'
    },
  }),
  graphql(getMostViewedListingQuery, {
    name: "getMostViewedListingData",
    options: {
      ssr: true,
      //fetchPolicy: 'network-only'
    },
  }),
  // graphql(getImageBannerQuery, {
  //   name: "getImageBannerData",
  //   options: {
  //     ssr: true,
  //   },
  // }),
  graphql(getPopularLocationQuery, {
    name: "getPopularLocationData",
    options: {
      ssr: true,
    },
  })
  // graphql(getStaticBlockInfoQuery, {
  //   name: "getStaticBlockInfoData",
  //   options: {
  //     ssr: true,
  //   },
  // })
)(Homepage);

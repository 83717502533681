exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2Xr8A{padding-left:0;padding-right:0;overflow-x:hidden}._36ozF{margin:0 auto;padding-bottom:24px;max-width:1080px}._3ulqw{padding-top:48px}._2aeKU{color:#404040;line-height:24px;margin:0 0 24px;padding:0 15px;font-weight:700}._1LI_U{font-weight:600;font-size:32px;line-height:48px;margin-bottom:50px;text-align:center;color:#222529}@media screen and (max-width:1170px){._2aeKU{text-align:center}._36ozF{padding-left:20px;padding-right:20px}}@media screen and (max-width:767px){._3xmol{margin:0 0 24px 34px}}._2NWFd{background-color:#0e436c}._2t2gG{background-color:#f6f9fc}.C-uHz{background-color:#fff}._3FIX4{background:linear-gradient(90deg,#0667b3,#0c7fd8 149.65%)}._1vAyP{margin-top:100px}", ""]);

// exports
exports.locals = {
	"root": "_2Xr8A",
	"container": "_36ozF",
	"pageContainer": "_3ulqw",
	"containerTitle": "_2aeKU",
	"findPlace": "_1LI_U",
	"marginLeft": "_3xmol",
	"contentBoxesBg": "_2NWFd",
	"contentBoxesBg2": "_2t2gG",
	"contentBoxesWhite": "C-uHz",
	"linearBg": "_3FIX4",
	"readyBox": "_1vAyP"
};
import React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url, sitename } from "../../../config";

class BookingPreApproval extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      guestName: PropTypes.string.isRequired,
      hostName: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      listTitle: PropTypes.number.isRequired,
    }),
  };

  render() {
 
    const linkText = {
      color: "#0667B3",
      fontSize: "16px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const textStyle = {
      color: "#404040",
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const btnCenter = {
      textAlign: "center",
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "bold",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: "#0667B3",
      backgroundColor: "#0667B3",
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const {
      content: { guestName, hostName, threadId, listTitle, logo, paymentUrl },
    } = this.props;
    let contactURL = url + "/message/" + threadId + "/guest";

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <Body textStyle={textStyle}>
          <div>Hallo {guestName},</div>
          <EmptySpace height={20} />
          <div>
            {hostName} hat Ihre Buchungsanfrage für die Fläche {listTitle} bestätigt.
          </div>
          <EmptySpace height={20} />
          <div>Um Ihre Buchung zu durchzuführen, schließen Sie bitte den Bezahlvorang ab.</div>

          <EmptySpace height={20} />
          <div style={btnCenter}>
            <a href={paymentUrl} style={buttonStyle}>
              Bezahlvorgang abschließen
            </a>
          </div>

          <div>
            Vielen Dank, <br />
            Ihr {sitename} Team
          </div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingPreApproval;

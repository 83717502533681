import React from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./OfferSpace.css";
import { Collapse, Row, Col } from "react-bootstrap";
import cx from "classnames";
import Component4 from "./Component4.svg";
import Volatility from "./volatility.svg";
import ConnectionLines from "./connection_lines.svg";
import ConnectionLines2 from "./connection_lines2.svg";
import Euro from "./Euro.svg";
import NounBar from "./noun_bar.svg";
import Dot from "./dot.svg";
import HexBG from "./hex_bg.svg";
import ReviewImage1 from "./reviewImage1.png";
import ReviewImage2 from "./reviewImage2.png";
import Star from "./star.svg";
import Union from "./union.svg";
import Contentbox2_1 from "./contentbox2_1.svg";
import Contentbox2_2 from "./contentbox2_2.svg";
import Contentbox2_3 from "./contentbox2_3.svg";
import PascalImage from "./pascal_pohl.png";
import HubspotForm from "react-hubspot-form";
class Vermieter extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };
  constructor(props, context) {
    super(props, context);

    this.state = {
      showMore: false,
    };
  }
  render() {
    const { title } = this.props;
    return (
      <div className={s.spaceTop4}>
        <Row className={cx(s.landingContainer, s.section)}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h1>
              Jetzt einfach &<br />
              schnell vermieten
            </h1>
            <h4>Fixkosten senken. Rendite steigern.</h4>
            <a className={cx(s.button, s.btnlarge, s.spaceTop48, s.spaceBottom24)} href="/become-a-host?mode=new">
              Jetzt Fläche anbieten
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className={"hidden-xs"}>
            <img src={Component4} />
          </Col>
        </Row>
        <Row className={cx(s.blueBG)}>
          <div className={cx(s.landingContainer)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h4 className={cx(s.spaceBottomTop24, s.padding20)}>
                NEWS: Die Anzahl der Mietverträge mit einer Laufzeit unter einem Jahr sind um 20% gestiegen.
              </h4>
            </Col>
          </div>
        </Row>

        <Row className={cx(s.landingContainer, s.section)}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h2>
              Mehr Ertrag
              <br />
              für Ihr Unternehmen.
            </h2>
            <p>
              Wir befinden uns in einer Zeit ständiger Veränderungen. Produktionszyklen haben sich drastisch verkürzt und an eine langfristige Planung ist nicht
              mehr zu denken.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={s.contentBox}>
              <Col xs={8} sm={8} md={8} lg={8}>
                <h4>Das Problem</h4>
                <p>
                  Temporärer Leerstand lässt sich nicht vermeiden und ungenutzte Flächen werden je nach Auftragslage wieder benötigt. Die Fixkosten bestehen und
                  Sie verlieren Geld.
                </p>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} className={s.positionRelative}>
                <img src={Volatility} />
              </Col>
            </div>
            <img className={s.connectionLines} src={ConnectionLines} />
            <div className={s.contentBox}>
              <Col xs={8} sm={8} md={8} lg={8}>
                <h4>Ihre Chance</h4>
                <p>
                  Sichern Sie sich zusätzliche Erträge und nutzen Sie die hohe Nachfrage für sich. Verschaffen Sie sich einen Vorsprung und steigern Sie die
                  Flexibilität Ihres Unternehmens.
                </p>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} className={s.positionRelative}>
                <img src={NounBar} />
              </Col>
            </div>
            <img className={s.connectionLines} src={ConnectionLines2} />
            <div className={s.contentBox}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h2>Die einfache Lösung</h2>
                  <h3>Jetzt freie Flächen vermieten auf expozed1.de</h3>
                </Col>
              </Row>

              <Row>
                <Col xs={5} sm={5} md={5} lg={5}>
                  <img src={Euro} className={s.euroImage} />
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} className={s.dots}>
                  <div className={s.dot}>
                    <img src={Dot} />
                    Zusätzlicher Ertrag
                  </div>
                  <div className={s.dot}>
                    <img src={Dot} />
                    Keine Verwaltung
                  </div>
                  <div className={s.dot}>
                    <img src={Dot} />
                    Immer zum besten Preis
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className={cx(s.landingContainer, s.section)}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h2>Ihre Vorteile</h2>
            <h4>
              8 Gründe mehr
              <br />
              Ihre Fläche zu vermieten.
            </h4>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Row className={s.hexBox}>
              <Col xs={3} sm={3} md={2} lg={2}>
                <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                  1
                </div>
              </Col>
              <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                <h4>Zusätzliche Einnahmen </h4>
                <p>und somit Reduzierung Ihrer Kosten, damit Sie noch erfolgreicher werden.</p>
              </Col>
            </Row>
            <Row className={s.hexBox}>
              <Col xs={3} sm={3} md={2} lg={2}>
                <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                  2
                </div>
              </Col>
              <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                <h4>Individuelle Verfügbarkeit</h4>
                <p>wodurch Sie genau dann Vermieten können, wann Sie es wollen, für maximale Flexibilität in Ihrem Unternehmen</p>
              </Col>
            </Row>
            <Row className={s.hexBox}>
              <Col xs={3} sm={3} md={2} lg={2}>
                <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                  3
                </div>
              </Col>
              <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                <h4>Automatisierte Vertrags- und Zahlungsabwicklung</h4>
                <p>die Ihnen alle Vorgänge und Abrechnungen übernimmt, damit Sie sich um nichts kümmern müssen.</p>
              </Col>
            </Row>
            <Row className={s.hexBox}>
              <Col xs={3} sm={3} md={2} lg={2}>
                <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                  4
                </div>
              </Col>
              <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                <h4>Keine Nebenkostenabrechnung</h4>
                <p>und somit einfachste Verwaltung, damit Sie Zeit für die wichtigen Dinge haben.</p>
              </Col>
            </Row>

            {!this.state.showMore && (
              <span className={s.showMore} onClick={() => this.setState({ showMore: !this.state.showMore })}>
                Mehr anzeigen
              </span>
            )}
            <Collapse in={this.state.showMore}>
              <div>
                <Row className={s.hexBox}>
                  <Col xs={3} sm={3} md={2} lg={2}>
                    <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                      5
                    </div>
                  </Col>
                  <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                    <h4>Höhere Mieterträge</h4>
                    <p>die über Ihre Mietkosten hinausgehen, damit Sie immer profitieren.</p>
                  </Col>
                </Row>
                <Row className={s.hexBox}>
                  <Col xs={3} sm={3} md={2} lg={2}>
                    <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                      6
                    </div>
                  </Col>
                  <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                    <h4>Einmaliges Erstellen des Inserats</h4>
                    <p>wodurch Sie keinen weiteren Aufwand haben und Ihr Objekt vermieten können wann immer Sie wollen.</p>
                  </Col>
                </Row>
                <Row className={s.hexBox}>
                  <Col xs={3} sm={3} md={2} lg={2}>
                    <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                      7
                    </div>
                  </Col>
                  <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                    <h4>Planungssicherheit für Ihr Unternehmen</h4>
                    <p>indem Sie immer freie Flächen auf Knopfdruck vermieten können und sogar mit Leerstand profitieren.</p>
                  </Col>
                </Row>
                <Row className={s.hexBox}>
                  <Col xs={3} sm={3} md={2} lg={2}>
                    <div className={s.hex} style={{ backgroundImage: `url(${HexBG})` }}>
                      8
                    </div>
                  </Col>
                  <Col xs={9} sm={9} md={10} lg={10} className={s.hexContent}>
                    <h4>100% Kostenlos</h4>
                    <p>und ohne Risiko, wodurch Sie mit der ersten Buchung schon profitieren.</p>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>

        <Row className={cx(s.blueBG, s.section, s.reviewContainer, s.thg)}>
          <div className={cx(s.landingContainer)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h2 className={s.spaceBottomTop24}>Maximale Rendite. Minimaler Aufwand. Mehr Sicherheit.</h2>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={s.reviewBox}>
                <img src={Union} />
                <p>Vor allem in aktuellen Zeiten, bietet expozed1.de die perfekte Möglichkeit zusätzliche Einnahmen zu generieren.</p>
                <Col xs={3} sm={3} md={3} lg={3} className={s.pl0}>
                  <img className={s.reviewBoxPhoto} src={ReviewImage1} />
                </Col>
                <Col xs={9} sm={9} md={9} lg={9} className={s.pl0}>
                  <h4>Stefan Keckeisen</h4>
                  <h5>Geschäftsführer Intact Batterien </h5>
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                  <a target="_blank" rel="noopener" href="https://www.intact-batterien.de/">intact-batterien.de</a>
                </Col>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
            <div className={s.reviewBox}>
                <img src={Union} />
                <p>Als Investor bietet expozed1 mir den Vorteil, meinen temporären Leerstand mit zusätzlicher Rentabilität zu vermieten und doch flexibel für langfristige Mietverträge zu bleiben.</p>
                <Col xs={3} sm={3} md={3} lg={3} className={s.pl0}>
                  <img className={s.reviewBoxPhoto} src={ReviewImage2} />
                </Col>
                <Col xs={9} sm={9} md={9} lg={9} className={s.pl0}>
                  <h4>Holger Gutmann</h4>
                  <h5>Immobilieninvestor</h5>
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                  <a target="_blank" rel="noopener" href="https://gutmanngruppe.com /">gutmanngruppe.com</a>
                </Col>
              </div>
            </Col>
            <div style={{textAlign: "center"}}>
            <a className={cx(s.btnMega, s.spaceTop48)} href="/become-a-host?mode=new">
              Jetzt Fläche anbieten
            </a>
            </div>
          </div>
        </Row>

        <Row className={cx(s.landingContainer, s.section, s.contentBox2)}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h5>Mehr Geld</h5>
            <h2>
              Höhere Rendite,
              <br />
              maximale Effizienz.
            </h2>
            <p>Steigern Sie ihre Mieteinnahmen und sichern Sie sich zusätzliche Einkünfte - auch wenn Sie noch nie vermietet haben!</p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="hidden-xs">
            <img className={s.imageRight} src={Contentbox2_1} />
          </Col>
        </Row>
        <Row className={cx(s.blueBG, s.contentBox2)}>
          <div className={cx(s.landingContainer, s.section, s.paddingLeftRight)}>
            <Col xs={12} sm={12} md={6} lg={6} >
              <img className={s.imageLeft} src={Contentbox2_2} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} >
              <h5>Mehr Zeit</h5>
              <h2>
                Zeit für die
                <br />
                wichtigen Dinge.
              </h2>
              <p>
                Ob Mietvertrag, Rechnungserstellung oder Nebenkostenabrechnung. Profitieren Sie von automatisierter Verwaltung und erhalten Sie alle relevanten
                Dokumente auf Knopfdruck.
              </p>
            </Col>
          </div>
        </Row>
        <Row className={cx(s.landingContainer, s.section, s.contentBox2)}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h5>Mehr Flexibilität</h5>
            <h2>
              Vermieten
              <br />
              war nie flexibler
            </h2>
            <p>Sie bestimmen wann, wie lange und an wen sie vermieten wollen. Nutzen Sie freie Ressourcen und sichern Sie sich zusätzliche Einnahmen.</p>
            <p>
              <b>Das Beste:</b>
              <br />
              Sie müssen Ihr Objekt lediglich einmal einstellen, um es genau dann zu vermieten wann Sie es wollen.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img className={s.imageRight} src={Contentbox2_3} />
          </Col>
        </Row>

        <Row className={cx(s.contactSection, s.section, s.contentBox2)}>
          <div className={s.landingContainer}>
            <Col xs={12} sm={12} md={6} lg={6} style={{ height: `100%` }}>
              {/* <div className={s.contactImage} style={{ backgroundImage: `url(${PascalImage})` }}></div> */}
              <div className={s.formImage}><img src={PascalImage} alt="Pascal Pohl" /></div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={s.form}>
                <HubspotForm
                  portalId="8224909"
                  formId="b9c345fe-4148-46ca-afa7-6df509007aad"
                  onSubmit={() => console.log("Submit!")}
                  onReady={(form) => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
            </Col>
          </div>
        </Row>

        <Row className={cx(s.landingContainer, s.section, s.offerSpaceBox)}>
            <Col xs={12} sm={12} md={12} lg={12} >
              <h2>Sie suchen nach einer Fläche?</h2>
              <h4>Jetzt Fläche finden Sofort verfügbar. Flexible Laufzeit</h4>
            <a className={cx(s.button, s.btnlarge, s.spaceTop48)} href="/search?&guests=1">
              Jetzt Fläche finden
            </a>
            </Col>
            
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(Vermieter);

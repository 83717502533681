import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { compose, gql, graphql } from "react-apollo";
import { Col, Row } from "react-bootstrap";
// Redux
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { doRemoveStaticImage } from "../../../../actions/siteadmin/manageStaticBlock";
import Loader from "../../../Loader";
import s from "./BlockUploader.css";
// Component
import DropZone from "./DropZone";
// Asset
import defaultPic from "./no-image-available.png";

class BlockUploader extends React.Component {
  static propTypes = {
    staticImageLoading: PropTypes.bool,
    doRemoveStaticImage: PropTypes.any.isRequired,
    getLogoData: PropTypes.shape({
      loading: PropTypes.bool,
      getLogo: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    }),
  };

  static defaultProps = {
    profilePictureData: {
      loading: true,
    },
    staticImageLoading: false,
  };

  render() {
    const {
      getLogoData: { loading, getStaticInfo },
      image,
      doRemoveStaticImage,
      staticImageLoading,
    } = this.props;

    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlignCenter}>
          <Loader show={staticImageLoading} type={"page"}>
            <div className={s.picContainer}>
              <div className={s.profilePic}>
                {loading && <div>Laden...</div>}
                {!loading && getStaticInfo && getStaticInfo[0].image && <img src={"/images/home/" + getStaticInfo[0].image} height={200} width={200} />}
                {!loading && getStaticInfo && !getStaticInfo[0].image && <img src={defaultPic} height={200} width={200} />}
              </div>
            </div>
          </Loader>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} className={cx(s.space2, s.spaceTop2)}>
          <Col xs={12} sm={12} md={12} lg={12} className={cx(s.fullWidth, s.button, s.btnPrimaryBorder, s.btnlarge)}>
            <DropZone data={getStaticInfo} />
          </Col>
        </Col>
      </Row>
    );
  }
}
const selector = formValueSelector("StaticBlockForm");

const mapState = (state) => ({
  staticImageLoading: state.homeBannerImages.staticImageLoading,
  // image: selector(state, 'blockImage1')
});

const mapDispatch = {
  doRemoveStaticImage,
};

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(
    gql`
      query($name: String) {
        getStaticInfo(name: $name) {
          name
          image
          content
          title
        }
      }
    `,
    {
      name: "getLogoData",
      options: {
        ssr: false,
        variables: {
          name: "block1",
        },
      },
    }
  )
)(BlockUploader);

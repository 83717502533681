import { toastr } from 'react-redux-toastr';
import {
  GET_PAYIN_START,
  GET_PAYIN_SUCCESS,
  GET_PAYIN_ERROR,
} from '../../constants';

import getPayinsQuery from './getPayins.graphql';

export function getPayins(currentAccountId) {

  return async (dispatch, getState, { client }) => {

    await dispatch({
      type: GET_PAYIN_START,
      payload: {
        getPayinLoading: true
      }
    });
    try {
      const { data } = await client.query({
        query: getPayinsQuery,
        variables: {
          currentAccountId
        },
        fetchPolicy: 'network-only'
      });

      if (data && data.getPayins && data.getPayins.length >= 0) {
        await dispatch({
          type: GET_PAYIN_SUCCESS,
          payload: {
            payinData: data.getPayins,
            getPayinLoading: false
          }
        });
      } else {
        await dispatch({
          type: GET_PAYIN_ERROR,
          payload: {
            error: 'No records found.',
            getPayinLoading: false
          }
        });
      }
    } catch (error) {
      await dispatch({
        type: GET_PAYIN_ERROR,
        payload: {
          error,
          getPayinLoading: false
        }
      });
      return false;
    }

    return true;
  };
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3IBGB{padding-top:100px;padding-bottom:100px}._3Gt_Q{background-color:#f6f9fc;padding:20px;margin-bottom:20px}.tr52T{color:#0667b3;opacity:.34;font-size:70px;font-weight:500}._6LSbb{text-transform:uppercase;letter-spacing:4px;font-size:15px;color:#0667b3}.dRpRv{font-size:50px;margin-top:10px;margin-bottom:10px}._26Y28{color:#656565;font-size:16px}._3YP33{color:#000;font-size:20px;font-weight:500;margin-top:20px;margin-bottom:10px}._1M4uG{line-height:22px;font-size:13px;color:#656565}._1OlDN{border-color:#2081cd;background-color:#2081cd;color:#fff;font-size:16px;padding:10px 70px;font-weight:500}._1OlDN:hover{background:rgba(54,50,119,.8)}._1OlDN:focus{border-color:#0074c2;box-shadow:0 0 8px rgba(0,116,194,.6)}._37dg_{border-color:#0667b3;background-color:#0667b3;color:#fff}._37dg_:active,._37dg_:active:focus,._37dg_:active:hover,._37dg_:focus,._37dg_:hover{border-color:#2081cd!important;background-color:#2081cd!important;color:#fff}._1ZQh7{color:#000;opacity:.46;font-size:16px;margin-top:10px;text-align:center}.dz9i2{margin-left:50px;display:inline-block;margin-top:50px}@media screen and (max-width:767px){.dz9i2{margin-left:0;width:100%;text-align:center}._26Y28{margin-bottom:40px}}", ""]);

// exports
exports.locals = {
	"content": "_3IBGB",
	"box": "_3Gt_Q",
	"number": "tr52T",
	"headLineBlue": "_6LSbb",
	"headLine": "dRpRv",
	"headLineSub": "_26Y28",
	"headline": "_3YP33",
	"text": "_1M4uG",
	"button": "_1OlDN",
	"btnPrimary": "_37dg_",
	"buttonText": "_1ZQh7",
	"buttonWrapper": "dz9i2"
};
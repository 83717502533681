exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2Vs09{width:120px;display:table;overflow:hidden}._2FPn4{display:table-cell;vertical-align:middle}.Ywui3{word-wrap:break-word;font-size:16px;line-height:22px;letter-spacing:normal;color:#404040;color:var(--btn-color);font-weight:400;margin:0!important}.tpnvC,.tpnvC:active,.tpnvC:active:hover,.tpnvC:focus,.tpnvC:hover{display:inline-block;cursor:pointer;text-align:center;line-height:1;position:relative;-ms-touch-action:manipulation;touch-action:manipulation;width:32px;height:32px;box-shadow:none;border-radius:50%;border:1px solid #0667b2;border:1px solid var(--btn-secondary-hover-bg);background:transparent;padding:1px 6px;color:#0667b2;color:var(--btn-secondary-hover-bg);outline:0!important}.tpnvC:disabled,.tpnvC:disabled:active,.tpnvC:disabled:active:hover,.tpnvC:disabled:focus,.tpnvC:disabled:hover{border:1px solid #0667b2;border:1px solid var(--btn-secondary-hover-bg);background:transparent;padding:1px 6px;color:#0667b2;color:var(--btn-secondary-hover-bg);cursor:default;opacity:.65;filter:alpha(opacity=65)}", ""]);

// exports
exports.locals = {
	"incrementDecrementButton": "_2Vs09",
	"tableCell": "_2FPn4",
	"incrementDecrementText": "Ywui3",
	"iconButton": "tpnvC"
};
import { gql } from "react-apollo";
import { reset } from "redux-form";
import { toastr } from "react-redux-toastr";
import history from "../../core/history";
import { ADD_PAYIN_START, ADD_PAYIN_SUCCESS, ADD_PAYIN_ERROR, ADD_ERROR } from "../../constants";

// Mangopay
import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";


import { getPayins } from "../../actions/Payin/getPayins";


export function addPayin(methodId, currency, iban, bic, last4Digits, owner, isBooking, bookingId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: ADD_PAYIN_START,
      payload: {
        payinLoading: true,
      },
    });
    //create Mangopay bank account
    let bankData = {
      iban,
      bic,
    };

    try {
      var { status, errorMessage, bankAccountId } = await processMangopayPayment("create-bank-account", owner, null, null, bankData);

      let userData = {
        bankAccountId,
        country: owner.country,
        isBooking,
        bookingId,
      };


      var { status, errorMessage, mandateData } = await processMangopayPayment("create-bank-mandate", userData);

      if (!mandateData || status == 400) {
        dispatch({
          type: ADD_PAYIN_ERROR,
          payload: {
            errorMessage,
            payinLoading: false,
          },
        });

        toastr.error("Fehlgeschlagen", "Bitte überprüfen Sie Ihre Eingaben");
        dispatch({
          type: ADD_ERROR,
          payload: {
            errorMessage,
          },
        });
        return;
      }
      console.log("payin status", status);

      const mandateId = mandateData.mandateId;
      const mandateDocumentUrl = mandateData.mandateDocumentUrl;
      const mandateConfirmUrl = mandateData.mandateConfirmUrl;
      let mutation = gql`
        mutation addPayin(
          $methodId: Int!
          $last4Digits: Int
          $currency: String!
          $mangoPayBankAccountId: String!
          $mandateId: Int!
          $mandateDocumentUrl: String!
          $mandateConfirmUrl: String!
          $mandateConfirmed: Boolean
          $isVerified: Boolean
        ) {
          addPayin(
            methodId: $methodId
            last4Digits: $last4Digits
            currency: $currency
            mangoPayBankAccountId: $mangoPayBankAccountId
            mandateId: $mandateId
            mandateDocumentUrl: $mandateDocumentUrl
            mandateConfirmUrl: $mandateConfirmUrl
            mandateConfirmed: $mandateConfirmed
            isVerified: $isVerified
          ) {
            id
            methodId
            userId
            createdAt
            status
          }
        }
      `;


      const { data } = await client.mutate({
        mutation,
        variables: {
          methodId,
          last4Digits,
          currency,
          mangoPayBankAccountId: bankAccountId,
          mandateId,
          mandateDocumentUrl,
          mandateConfirmUrl,
          mandateConfirmed: false,
          isVerified: false,
        },
      });
      await dispatch(getPayins());
      if (status === 200 && data) {
        dispatch({
          type: ADD_PAYIN_SUCCESS,
          payload: {
            mandateId,
            mandateDocumentUrl,
            mandateConfirmUrl,
            status,
            payinLoading: false,
          },
        });

      } else {
        toastr.error("Fehlgeschlagen", "Bitte überprüfen Sie Ihre Eingaben");
        dispatch({
          type: ADD_PAYIN_ERROR,
          payload: {
            errorMessage,
            payinLoading: false,
          },
        });

        dispatch({
          type: ADD_ERROR,
          payload: {
            errorMessage,
          },
        });
      }
      // }
    } catch (error) {
      console.log("payin error", error);
      toastr.error("Fehlgeschlagen", "Bitte überprüfen Sie Ihre Eingaben");
      dispatch({
        type: ADD_PAYIN_ERROR,
        payload: {
          error,
          payinLoading: false,
        },
      });

      dispatch({
        type: ADD_ERROR,
        payload: {
          errorMessage,
        },
      });
      return false;
    }

    return true;
  };
}

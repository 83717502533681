import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Footer.css";

import { Row, Col, Grid } from "react-bootstrap";
import cx from "classnames";

// Component
import Link from "../Link";
import ExpozedLogoBlack from "./expozed1_logo_black.svg";
import MailIcon from "./mail.svg";
import PhoneIcon from "./phone.svg";
import FacebookIcon from "./facebook.svg";
import LinkedinIcon from "./linkedin.svg";
import InstagramIcon from "./instagram.svg";
import XingIcon from "./xing.svg";
import { sitename } from "../../config";
// Locale
import messages from "../../locale/messages";

import getEnabledBlog from "./getEnabledBlog.graphql";

class Footer extends React.Component {
  static propTypes = {
    siteName: PropTypes.string.isRequired,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    appAvailableStatus: PropTypes.bool,
    playStoreUrl: PropTypes.string,
    appStoreUrl: PropTypes.string,
    formatMessage: PropTypes.any,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getEnabledBlog: PropTypes.array,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      expozed: false,
      hosting: false,
      discover: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      data: { getEnabledBlog },
    } = nextProps;
    getEnabledBlog &&
      getEnabledBlog.length > 0 &&
      getEnabledBlog.map((item) => {
        if (item.footerCategory == sitename) {
          this.setState({ expozed: true });
        }
        if (item.footerCategory == "discover") {
          this.setState({ discover: true });
        }
        if (item.footerCategory == "hosting") {
          this.setState({ hosting: true });
        }
      });
  }

  render() {
    const { siteName, appAvailableStatus, playStoreUrl, appStoreUrl } = this.props;
    const {
      data: { getEnabledBlog },
    } = this.props;
    const { expozed, discover, hosting } = this.state;

    return (
      <div className={s.positionRelative}>
        <div className={cx(s.topImageBanner, s.root)}>
          <div className={s.container}>
            <div className={cx(s.footerSectionContainer, "hidden-print")}>
              <Grid fluid>
                <Row>
                  <div className={s.responsivePadding}>
                    <Col sm={3} md={3} lg={3} xs={12} className={cx(s.responsiveMargin, s.noPaddingMobile)}>
                      <img className={s.logo} src={ExpozedLogoBlack} />
                      <p className={s.description}>
                        Expozed1 ermöglicht die kurzzeitige und flexible Buchung von Flächen basierend auf einem rundum sicheren, transparenten und
                        automatisierten Vermietungsprozess.
                      </p>
                    </Col>
                    <Col sm={2} md={2} lg={2} xs={6} className={cx(s.responsiveMargin, s.noPaddingMobile)}>
                      <label className={s.landingLabel}>Unternehmen</label>
                      <ul className={s.listContainer}>
                        <li>
                        <a href={"https://blog.expozed1.de"}  className={cx(s.textLink)}  target="_blank" rel="noopener noreferrer">
                            Blog
                          </a>
                        </li>
                        <li>
                        <a href={"https://lp.expozed1.de/karriere"}  className={cx(s.textLink)}  target="_blank" rel="noopener noreferrer">
                        Karriere
                      </a>
                        </li>
                        <li>
                        <a href={"https://lp.expozed1.de/partner"}  className={cx(s.textLink)}  target="_blank" rel="noopener noreferrer">
                        Partner werden
                          </a>
                        </li>
                        
                      </ul>
                    </Col>
                    <Col sm={2} md={2} lg={2} xs={6} className={cx(s.responsiveMargin, s.noPaddingMobile)}>
                      <label className={s.landingLabel}>Service</label>
                      <ul className={s.listContainer}>
                        <li>
                        <a href={"https://lp.expozed1.de/mietanfrage"}  className={cx(s.textLink)}  target="_blank" rel="noopener noreferrer">
                        Mietanfrage senden 
                          </a>
                        </li>
                     
                      </ul>
                    </Col>
                    <Col sm={5} md={5} lg={5} xs={12} className={cx(s.responsiveMargin, s.noPaddingMobile)}>
                      <div className={s.contactBox}>
                        <Col xs={6} sm={6} md={6} lg={6} className={s.noPaddingMobile}>
                          <div className={s.contactLink}>
                            {" "}
                            <img src={MailIcon} />
                            <a href="mailto:info@expozed1.de">info@expozed1.de</a>
                          </div>
                          <div>
                            {" "}
                            <img src={PhoneIcon} />
                            <a href="tel:+49 731 79032090">+49 731 79032090</a>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} className={s.noPaddingMobile}>
                          <div className={s.socialIcons}>
                            <a href="https://www.facebook.com/expozed1.de" target="_blank" rel="noopener noreferrer">
                              <img src={FacebookIcon} />
                            </a>
                            <a href="https://de.linkedin.com/company/expozed1" target="_blank" rel="noopener noreferrer">
                              <img src={LinkedinIcon} />
                            </a>
                            <a href="https://www.instagram.com/expozed1.de/" target="_blank" rel="noopener noreferrer">
                              <img src={InstagramIcon} />
                            </a>
                            <a href="https://www.xing.com/companies/expozedgmbh">
                              <img src={XingIcon} />
                            </a>
                          </div>
                        </Col>
                      </div>
                      <div className={s.contactText}>
                        Wir beraten Sie gerne in einem persönlichen Gespräch am Telefon. Sie erreichen uns von Montag bis Freitag von 8:00-18:00 Uhr und in
                        Notfällen unter der +49 731 79032090.
                      </div>
                    </Col>
                  </div>
                </Row>

                <Row className={cx(s.copyrightSection, s.spaceTop4, s.copyRightTabNoMargin)}>
                  <Col xs={12} sm={12} md={4} lg={4} className={s.noPaddingMobile}>
                    <Link to={"/page/agb"} className={cx(s.footerBottomLink)}>
                      AGB
                    </Link>
                    <Link to={"/page/datenschutz"} className={cx(s.footerBottomLink)}>
                      Datenschutz
                    </Link>
                    <Link to={"/page/impressum"} className={cx(s.footerBottomLink)}>
                      Impressum
                    </Link>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8} className={s.noPaddingMobile}>
                    <span className={cx(s.text, s.copyRight)}>© {new Date().getFullYear()} Expozed1</span>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  facebook: state.siteSettings.data.facebookLink,
  twitter: state.siteSettings.data.twitterLink,
  instagram: state.siteSettings.data.instagramLink,
  appAvailableStatus: state.siteSettings.data.appAvailableStatus,
  playStoreUrl: state.siteSettings.data.playStoreUrl,
  appStoreUrl: state.siteSettings.data.appStoreUrl,
});

const mapDispatch = {};

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getEnabledBlog, {
    options: {
      fetchPolicy: "network-only",
      ssr: false,
    },
  })
)(Footer);

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".CGz6L{display:block;fill:#767676;height:36px;width:36px;font-size:36px}._3UN8Y{margin:0 auto}._1CTfz{padding:0 10px}", ""]);

// exports
exports.locals = {
	"navigationIcon": "CGz6L",
	"noMargin": "_3UN8Y",
	"padding": "_1CTfz"
};
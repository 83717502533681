exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}.qkFXg{height:402px!important;padding-left:0;padding-right:0;overflow:hidden}._2CDJN{border:1px solid #fff;border-bottom:0}._2CDJN,._3pzId{height:201px!important;padding-left:0;padding-right:0;overflow:hidden}._3pzId{border:1px solid #fff}._1cFxO{padding:0!important}._2-B8t{-ms-transform:scale(1)!important;transform:scale(1)!important;transition:-ms-transform .45s cubic-bezier(.645,.045,.355,1) 0s,transform .45s cubic-bezier(.645,.045,.355,1) 0s,opacity .45s cubic-bezier(.645,.045,.355,1) 0s!important}._2-B8t:hover{-ms-transform:scale(1.05)!important;transform:scale(1.05)!important;transition:-ms-transform .45s cubic-bezier(.645,.045,.355,1) 0s,transform .45s cubic-bezier(.645,.045,.355,1) 0s!important}@media (min-width:320px) and (max-width:767px){._3GOTa{display:none}.qkFXg{width:100%!important}}@media (min-width:768px) and (max-width:991px){.qkFXg{height:400px!important}._2CDJN,._3pzId{height:200px!important}}@media screen and (min-height:700px){.qkFXg{height:552px!important}._2CDJN,._3pzId{height:276px!important}}@media screen and (min-height:850px){.qkFXg{height:444px!important}._2CDJN{height:250px!important}._3pzId{height:196px!important}}", ""]);

// exports
exports.locals = {
	"leftBanner": "qkFXg",
	"rightTopBanner": "_2CDJN",
	"rightBottom": "_3pzId",
	"pad0": "_1cFxO",
	"bgbanner": "_2-B8t",
	"hiddenxs": "_3GOTa"
};
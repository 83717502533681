import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url, sitename } from "../../../config";

class UboSuccess extends Component {
  static propTypes = {
    content: PropTypes.shape({
      kycType: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: "#404040",
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const linkText = {
      color: "#0667B3",
      fontSize: "16px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const {
      content: { firstName, lastName, companyName, kycType, logo },
    } = this.props;

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hallo {firstName},</div>
                  <EmptySpace height={20} />
                  <div>
                    Wir haben die eingereichten Dokumente zur Überprüfung Ihrer Anteilseigner erfolgreich verifiziert. Ab jetzt können Sie Auszahlungen
                    erhalten.
                  </div>
                  <EmptySpace height={20} />
                  <div>Viel Erfolg bei der Vermietung Ihrer Fläche.</div>
                  <EmptySpace height={20} />
                  <div>
                    Vielen Dank, <br />
                    Ihr{sitename} Team
                  </div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default UboSuccess;

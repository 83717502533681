exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._26FK-{background-color:#404040;width:64px;display:block;height:1.5px;margin:0 auto;margin-bottom:27px}._1mg0B{height:2.5px;border:1px solid #404040;background-color:transparent}._1b2AR h1{font-size:60px;line-height:64px;text-align:center;font-weight:700;margin-bottom:73px}h4._1ZhEn{font-size:24px;font-weight:800;margin-bottom:20px}p._1ZhEn{font-size:17px;line-height:1.7;font-weight:400}._2Or5o{margin-bottom:103px}._12Ew- span{padding-bottom:12px;padding-top:12px;font-size:16px;color:#0667b2;display:table}._12Ew- span span{color:#0667b2;display:table-cell}._12Ew- p span,._12Ew- span span{padding-bottom:0;padding-top:0;font-size:16px}._12Ew- p span{color:#404040;display:inline-block}._2Or5o p{font-size:16px}@media screen and (max-width:991px){h4._1ZhEn{font-size:23px;font-weight:600}._1b2AR h1{font-size:36px;line-height:51px;margin-bottom:56px}}@media screen and (max-width:767px){._1b2AR h1{font-size:24px;line-height:30px;margin-bottom:20px;text-align:left;padding-right:15px;padding-left:15px}h4._1ZhEn{font-size:26px}._2Or5o{margin-bottom:10px}._12Ew- span{font-size:14px;padding-bottom:5px;padding-top:5px;vertical-align:middle;padding-left:21px}._12Ew-{padding-left:15px;padding-right:15px}}", ""]);

// exports
exports.locals = {
	"seperator": "_26FK-",
	"boxseperator": "_1mg0B",
	"mainhedding": "_1b2AR",
	"common": "_1ZhEn",
	"faqsection": "_2Or5o",
	"faqcolumn": "_12Ew-"
};
import Promise from 'bluebird';
import fetch, { Request, Headers, Response } from 'node-fetch';
import { host, url} from '../../config';

fetch.Promise = Promise;
Response.Promise = Promise;

function localUrl(route) {
  if (route.startsWith('//')) {
    return `https:${route}`;
  }

  if (route.startsWith('http')) {
    return route;
  }

  return `${url}${route}`;
}

function localFetch(route, options) {
  return fetch(localUrl(route), options);
}

export { localFetch as default, Request, Headers, Response };

import s2 from "!isomorphic-style-loader!css-loader!rc-slider/assets/index.css";
import s3 from "!isomorphic-style-loader!css-loader!react-phone-input-2/lib/style.css";
import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
// External component
import Slider from "rc-slider";
import React from "react";
import { Button, Col, Form, FormControl, Modal, Panel, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
// Redux
import { connect } from "react-redux";
// Redux form
import { change, Field, formValueSelector, reduxForm } from "redux-form";
// Locale
import messages from "../../../locale/messages";
import Loader from "../../Loader/Loader";
// Component
import DateRange from "../DateRange/DateRange";
import s from "./ManualRequest.css";
import submit from "./submit";
// Helper
import validate from "./validate";

class ManualRequest extends React.Component {
  static propTypes = {
    showContactHostModal: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    picture: PropTypes.string,
    profileId: PropTypes.number.isRequired,
    personCapacity: PropTypes.number.isRequired,
    minNight: PropTypes.number,
    maxNight: PropTypes.number,
    maxDaysNotice: PropTypes.string,
    blockedDates: PropTypes.array,
    availability: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    contactHostClose: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
    maximumStay: PropTypes.bool,
  };

  static defaultProps = {
    showContactHostModal: false,
    id: 0,
    userId: null,
    profileId: 0,
    city: null,
    displayName: null,
    picture: null,
    personCapacity: 0,
    minNight: 0,
    maxNight: 0,
    blockedDates: [],
    availability: false,
    startDate: null,
    endDate: null,
    maximumStay: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      sliderValue: 250,
      phonenumber: "",
    };

    this.renderGuests = this.renderGuests.bind(this);
    this.renderFormControlSelect = this.renderFormControlSelect.bind(this);
    this.renderFormControlTextArea = this.renderFormControlTextArea.bind(this);
    this.renderWarningBlock = this.renderWarningBlock.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
  }

  renderGuests(personCapacity) {
    const { formatMessage } = this.props.intl;

    let rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(
        <option key={i} value={i}>
          {i} {i > 1 ? formatMessage(messages.guests) : formatMessage(messages.guest)}
        </option>
      );
    }
    return rows;
  }

  renderFormControlSelect({ input, label, meta: { touched, error }, children, className }) {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        alse;
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  renderFormControlTextArea({ input, label, meta: { touched, error }, children, className, placeholder }) {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} className={className} componentClass="textarea" placeholder={placeholder}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  renderFormControlEmail = ({ input, label, type, meta: { touched, error }, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={disabled} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };
  renderWarningBlock(content, error) {
    let bgClass;
    if (error) {
      bgClass = s.alertBlockError;
    } else {
      bgClass = s.alertBlockSuccess;
    }
    return (
      <div className={cx(s.alertBlock, bgClass, s.space4)}>
        <div>
          <div className={s.messageText}>{content}</div>
        </div>
      </div>
    );
  }

  renderFormControlPhone = ({ input, label, type, meta: { touched, error }, className, country }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.margintop15}>
        <PhoneInput {...input} country={"de"} />
        {/* <InputGroup>
          <InputGroup.Addon className={s.addonStyle}>{country}</InputGroup.Addon>
          <FormControl {...input} placeholder={label} type={type} className={className} />
        </InputGroup> */}
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  handleChangePhone = (phoneNumber) => {
    const { change } = this.props;
    change("phoneNumber", phoneNumber);
    this.setState({ phoneNumber });
  };

  handleForm = (values, dispatch) => {
    const { change } = this.props;
    this.props.toggleManualRequest();
    submit(values, dispatch);
  };

  async onSliderChange(value) {
    const { change } = this.props;
    this.setState(
      {
        sliderValue: value,
      },
      () => {
        console.log(this.state.sliderValue);
      }
    );
  }

  componentDidMount() {
    const { change, initialValues } = this.props;
    change("size", this.state.sliderValue);
    change("listId", this.props.id);
    change("hostName", this.props.hostProfile.displayName);
    change("hostCompanyName", this.props.hostProfile.companyName);
    change("hostId", this.props.hostProfile.profileId);
    change("listingTitle", this.props.title);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { showManualRequestModal, toggleManualRequest } = this.props;
    const { id, personCapacity, minNight, maxNight, maxDaysNotice, blockedDates } = this.props;
    const { userId, profileId, picture, displayName, city, title, listingSize } = this.props;
    const { availability, startDate, endDate, isLoading, maximumStay } = this.props;
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { sliderValue, phoneNumber } = this.state;
    let isDateChosen = (startDate != null && endDate != null) || false;

    let daysDifference = moment(endDate).diff(moment(startDate), "days") + 1;

    let hasMinDays = daysDifference > minNight;
    let hasMaxDays = daysDifference < maxNight;

    let disabled;
    if (!isDateChosen || !availability || !hasMaxDays || !hasMinDays) {
      disabled = true;
    } else {
      disabled = false;
    }
    //let loadingStatus = loading || isLoading || false;
    let loadingStatus = isLoading || false;

    return (
      <div className={s.root}>
        <Modal show={showManualRequestModal} onHide={toggleManualRequest} animation={false} className={cx(s.modalContainer, "ContactHost")}>
          <div className={cx(s.modalTable)}>
            <div className={cx(s.modalCell)}>
              <Modal.Header className={s.modalHeading} closeButton>
                <Modal.Title>Jetzt Anfrage stellen</Modal.Title>
              </Modal.Header>
              <Modal.Body bsClass={s.logInModalBody}>
                <div className={s.modalDark}>
                  <Row className={s.noMargin}>
                    <Col md={4} className={s.noPadding}>
                      <Panel className={cx(s.hostModal)}>
                        <div className={cx(s.textCenter, s.space2)}>
                          <h3>{title}</h3>
                          <div className={cx(s.mediaPhoto, s.mediaRound)}>
                            <div className={s.mediaBadge}>
                              <img src={"/images/upload/small_" + picture} className={cx(s.imgBackground, s.profileAvatar)} height={120} width={120} />
                              {/* <Avatar
                                source={picture}
                              
                                title={displayName}
                                className={s.profileAvatar}
                                withLink
                                linkClassName={cx(s.mediaPhoto, s.mediaRound)}
                                profileId={profileId}
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <h5 className={s.textCenter}>{displayName}</h5>
                          <hr className={cx(s.spaceTop2, s.space2)} />
                          <div>
                            <p>
                              <span>Bitte geben Sie nähere Informationen zu folgenden Punkten:</span>
                            </p>
                            <ul className={s.listText}>
                              <li>
                                <span>Informationen zur Ware/Nutzung</span>
                              </li>
                              <li>
                                <span>Angaben zum Unternehmen</span>
                              </li>
                              <li>
                                <span>Besondere Anforderungen</span>
                              </li>
                            </ul>
                            <hr className={cx(s.spaceTop2, s.space2)} />
                            <div className={s.phone}>
                              Oder kontaktieren Sie uns direkt unter:
                              <a href="tel:+49 731 79032090">
                                <h4>+49 731 79032090</h4>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Col>
                    <Col md={8} className={s.noPadding}>
                      <Loader show={loadingStatus} type={"page"}>
                        <Panel className={cx(s.guestModal, "guestpanel")}>
                          {!isDateChosen && this.renderWarningBlock(<FormattedMessage {...messages.hostErrorMessage1} />)}
                          {!maximumStay &&
                            !availability &&
                            isDateChosen &&
                            this.renderWarningBlock(
                              <p>
                                <FormattedMessage {...messages.hostErrorMessage2} />
                              </p>,
                              "error"
                            )}

                          {availability && isDateChosen && this.renderWarningBlock(<FormattedMessage {...messages.hostErrorMessage3} />)}
                          <div className={s.panelBody}>
                            <h3 className={s.listTitle}>
                              <FormattedMessage {...messages.contactHostDate} />?
                            </h3>
                            <div className={s.space4}>
                              <Row>
                                <Form
                                  onSubmit={handleSubmit((values, dispatch) => {
                                    this.handleForm(values, dispatch);
                                  })}
                                >
                                  <Col lg={12} md={12} sm={7} xs={12}>
                                    <span className={"ContactHost"}>
                                      <DateRange
                                        listId={id}
                                        minimumNights={minNight}
                                        maximumNights={maxNight}
                                        blockedDates={blockedDates}
                                        formName={"ManualRequestForm"}
                                        maxDaysNotice={maxDaysNotice}
                                      />
                                    </span>
                                    {isDateChosen && !hasMaxDays && (
                                      <p className={s.dateError}>
                                        <FormattedMessage {...messages.maximumStay} /> {maxNight} <FormattedMessage {...messages.nights} />
                                      </p>
                                    )}

                                    {isDateChosen && !hasMinDays && (
                                      <p className={s.dateError}>
                                        <FormattedMessage {...messages.minimumStay} /> {minNight} <FormattedMessage {...messages.nights} />
                                      </p>
                                    )}
                                  </Col>
                                  <Col lg={12} md={12} sm={5} xs={12} className={cx(s.spaceTop2, s.smNomarginTop)}>
                                    <h3 className={s.listTitle}>Wieviel Fläche möchten Sie mieten?</h3>

                                    <Slider step={5} min={250} max={listingSize} value={sliderValue} onChange={this.onSliderChange} />
                                    <h4 className={s.sliderValue}>
                                      {sliderValue}m² von {listingSize}m²
                                    </h4>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Ihr Name</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        name="name"
                                        type="text"
                                        component={this.renderFormControlEmail}
                                        label={"Name"}
                                        className={cx(s.formControlInput, s.commonBorder)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Ihre Email-Adresse</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        name="email"
                                        type="text"
                                        component={this.renderFormControlEmail}
                                        label={formatMessage(messages.email)}
                                        className={cx(s.formControlInput, s.commonBorder)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Ihre Telefonnummer (optional)</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        input={{
                                          name: "phoneNumber",
                                          onChange: this.handleChangePhone,
                                          value: phoneNumber,
                                        }}
                                        name="phoneNumber"
                                        type="text"
                                        component={this.renderFormControlPhone}
                                        label={formatMessage(messages.phoneNumber)}
                                        className={s.formControlInput}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Teilen Sie uns Ihre Anfrage mit</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        name="content"
                                        component={this.renderFormControlTextArea}
                                        className={s.textBox}
                                        placeholder={formatMessage(messages.textBoxMessage)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <Button className={cx(s.btn, s.btnPrimary)} type="submit" disabled={submitting || disabled}>
                                      Anfrage senden
                                    </Button>
                                  </Col>
                                </Form>
                              </Row>
                            </div>
                          </div>
                        </Panel>
                      </Loader>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ManualRequest = reduxForm({
  form: "ManualRequestForm", // a unique name for this form
  validate,
})(ManualRequest);

// Decorate with connect to read form values
const selector = formValueSelector("ManualRequestForm"); // <-- same as form name

const mapState = (state) => ({
  isLoading: state.viewListing.isLoading,
  // showContactHostModal: state.viewListing.showContactHostModal,
  availability: state.viewListing.availability,
  maximumStay: state.viewListing.maximumStay,
  startDate: selector(state, "startDate"),
  endDate: selector(state, "endDate"),
  size: selector(state, "size"),
});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s, s2, s3)(connect(mapState, mapDispatch)(ManualRequest)));

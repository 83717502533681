import { toastr } from 'react-redux-toastr';
import {
  DELETE_MANDATE_DATA,
} from '../../constants';

export function deleteMandateData() {

  return async (dispatch, getState, { client }) => {

    await dispatch({
      type: DELETE_MANDATE_DATA,
      payload: {
       
      }
    });

    return true;
  };
}

import { gql } from 'react-apollo';

import history from '../../core/history';
import {
  BOOKING_PROCESS_START,
  BOOKING_PROCESS_SUCCESS,
  BOOKING_PROCESS_ERROR,
} from '../../constants';

import {Event} from '../../components/Tracking/GA'


export function bookingProcess(listId, guests, startDate, endDate, preApprove,taxRate, bookingType, reservationId, selectedLongTerm) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: BOOKING_PROCESS_START,
      payload: {
        bookingLoading: true
      }
    });
   
    try {

      let query = gql`
          query UserListing($listId:String!) {
            UserListing (listId:$listId) {
              id
              userId
              title
              coverPhoto
              country
              city
              state,
              security
              securityClass
              personCapacity
              bookingType
              listPhotos{
                id
                name
              }
              user {
                id
                email
                profile{
                  profileId
                  displayName
                  companyName
                  address1
                  address2
                  city
                  zip
                  firstName
                  picture
                }
              }
              settingsData {
                id
                settingsId
                listsettings {
                  id
                  itemName
                  settingsType {
                    typeName
                  }
                }
              }
              houseRules {
                houseRuleName
                listsettings{
                  itemName
                  isEnable
                  settingsType {
                    typeName
                  }
                }
              }
              listingData {
                checkInStart,
                checkInEnd,
                basePrice,
                cleaningPrice,
                currency,
                weeklyDiscount,
                monthlyDiscount,
                taxRate,
                qmPrice,
                nkPrice,
                services,
                service_price_hour,
                service_price_qm,
                service_price_kg,
                service_price_palette,
                monthlyPay,
                service_description,
                cancellation {
                  id
                  policyName
                }
              }
              listBlockedPrice {
                id
                listId
                isSpecialPrice
                blockedDates
              }
            }
        }
      `;

      const { data } = await client.query({
        query,
        variables: {
          listId
        },
      });

      if (data && data.UserListing) {
       
        dispatch({
          type: BOOKING_PROCESS_SUCCESS,
          payload: {
            data: data.UserListing,
            bookDetails: {
              selectedLongTerm,
              guests,
              startDate,
              endDate,
              preApprove,
              bookingType,
              reservationId,
              justPayment: bookingType === "payment" ? true : false
            },
            bookingLoading: false
          }
        });
        Event("booking", "process_success", listId)
        if(!selectedLongTerm) {
          history.push('/book/' + listId);
        } else {
          history.push('/book-longterm/' + listId + "/" + selectedLongTerm );
        }
       
      }

    } catch (error) {
      dispatch({
        type: BOOKING_PROCESS_ERROR,
        payload: {
          error,
          bookingLoading: false
        }
      });
      Event("booking", "process_error", listId)
      return false;
    }

    return true;
  };
}


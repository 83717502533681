import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
// import s from "!isomorphic-style-loader!css-loader!sass-loader!react-dates/lib/css/_datepicker.css";
// import "react-dates/initialize";

// import { DateRangePicker, isInclusivelyBeforeDay, isInclusivelyAfterDay } from "react-dates";
// import { START_DATE, END_DATE } from "react-dates/constants";

import { DateRangePicker } from "rsuite";
import s from "!isomorphic-style-loader!css-loader!sass-loader!rsuite/dist/styles/rsuite-default.css";

// Redux
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

// Redux Action
import { change } from "redux-form";
import { checkAvailability } from "../../../actions/checkAvailability";

import { getSpecialPricingData } from "../../../actions/Listing/getSpecialPricingData";

// Translation
import {  injectIntl } from "react-intl";


const { combine, allowedMaxDays, beforeToday, after, before, allowedRange } = DateRangePicker;

class DateRange extends React.Component {
  static propTypes = {
    minimumNights: PropTypes.number.isRequired,
    maximumNights: PropTypes.number.isRequired,
    checkAvailability: PropTypes.any.isRequired,
    blockedDates: PropTypes.array.isRequired,
    listId: PropTypes.number.isRequired,
    formName: PropTypes.string.isRequired,
    maxDaysNotice: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    blockedDates: [],
    maxDaysNotice: "available",
  };

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null,
      blockedDates: [],
      blockedDatesValues: [],
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
  }
  componentDidMount() {
    const { bookingStartDate, bookingEndDate, blockedDates } = this.props;
    const { formName } = this.props;
    const blockedDatesSet = new Set();

    if (formName == "BookingForm") {
      if (bookingStartDate && bookingEndDate) {
        this.setState({
          startDate: moment(bookingStartDate),
          endDate: moment(bookingEndDate),
        });
      }
    }

    blockedDates.forEach((day) => {
      if (day.calendarStatus != "available") {
        blockedDatesSet.add(moment(day.blockedDates).format("YYYY-MM-DD"));
      }
    });

    this.setState({ blockedDatesSet });
    this.setState({ blockedDatesValues: blockedDates });
  }

  componentWillReceiveProps(nextProps) {
    const { bookingStartDate, bookingEndDate, blockedDates } = nextProps;
    const { formName } = nextProps;
    const { blockedDatesSet } = this.state;

    if (formName == "BookingForm") {
      if (bookingStartDate && bookingEndDate) {
        this.setState({
          startDate: moment(bookingStartDate),
          endDate: moment(bookingEndDate),
        });
      }
    }

    blockedDates.forEach((day) => {
      if (day.calendarStatus != "available") {
        blockedDatesSet.add(moment(day.blockedDates).format("YYYY-MM-DD"));
      }
    });

    this.setState({ blockedDatesSet });
    this.setState({ blockedDatesValues: blockedDates });
  }

  async onDatesChange(value) {
    //const { focusedInput } = this.state;
    const { focusedInput, blockedDatesSet, blockedDatesValues } = this.state;
    const { listId, formName, checkAvailability, change, maximumNights, minimumNights } = this.props;
    const { getSpecialPricingData } = this.props;

    //Delete localstorage saved booking because new date new booking
    localStorage.removeItem("booking");
    
    let startDate = value[0];
    let endDate = value[1];
    this.setState({ startDate, endDate });
    await change(formName, "startDate", startDate);
    await change(formName, "endDate", endDate);
    if (startDate && endDate) {
      // if ((focusedInput === END_DATE || focusedInput === START_DATE) && endDate) {
      await getSpecialPricingData(listId, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
      await checkAvailability(
        listId,
        moment(moment(startDate)).format("YYYY-MM-DD"),
        moment(moment(endDate)).format("YYYY-MM-DD"),
        maximumNights,
        minimumNights
      );
    }
  }

  onFocusChange(focusedInput) {
    const { startDate } = this.state;
    this.setState({ focusedInput });
    if (focusedInput === START_DATE) {
      // this.setState({ endDate: null });
    }
  }

  isDayBlocked(day) {
    const { blockedDatesSet, focusedInput } = this.state;
    if (blockedDatesSet) {
      let isBlocked = blockedDatesSet.has(moment(day).format("YYYY-MM-DD"));
      // if (focusedInput) {
      //   if (this.state.focusedInput === "startDate") {
      //     if (day.isoWeekday() !== 1) {
      //       isBlocked = true;
      //     }
      //   } else if (this.state.focusedInput === "endDate") {
      //     if (day.isoWeekday() !== 7) {
      //       isBlocked = true;
      //     }
      //   }
      // }
      return isBlocked;
    } else {
      return null;
    }

    focusedInput === "startDate" ? this.isStartDayBlocked : this.isEndDayBlocked;
  }

  renderDay = (day) => {
    const { blockedDatesSet, focusedInput } = this.state;

    let bookingDayHtml = (
      <div className="datepicker-wrapper">
        <span>{day.format("D")}</span>
      </div>
    );
    let noBookingDayHtml = (
      <div className="datepicker-wrapper datepicker-non-booking">
        <span>{day.format("D")}</span>
      </div>
    );
    if (focusedInput) {
      if (this.state.focusedInput === "startDate") {
        if (day.isoWeekday() === 1) {
          return bookingDayHtml;
        } else {
          return noBookingDayHtml;
        }
      } else if (this.state.focusedInput === "endDate") {
        if (day.isoWeekday() === 7) {
          return bookingDayHtml;
        } else {
          return noBookingDayHtml;
        }
      }
    }
  };
  isDisabledDate = (date, selectDate, selectedDone, target) => {
    const { bookingNoticeTime, maxDaysNotice, maxDaysNoticeStart, maxDaysNoticeEnd } = this.props;
    if (this.isDayBlocked(date)) {
      return true;
    }

    let momentDate = moment(date);
    if (maxDaysNotice === "3months") {
      if (moment().add(3, "months") < momentDate) {
        return true;
      }
    }
    if (maxDaysNotice === "6months") {
      if (moment().add(6, "months") < momentDate) {
        return true;
      }
    }
    if (maxDaysNotice === "9months") {
      if (moment().add(9, "months") < momentDate) {
        return true;
      }
    }
    if (maxDaysNotice === "12months") {
      if (moment().add(12, "months") < momentDate) {
        return true;
      }
    }
    if (maxDaysNotice === "custom") {
      if (moment(maxDaysNoticeStart).subtract(1, "day") >= momentDate || moment(maxDaysNoticeEnd).add(1, "day") <= momentDate) {
        return true;
      }
    }
    let dayDiff = -1 * moment().diff(date, "days") + 1;
    if (bookingNoticeTime >= dayDiff) {
      return true;
    }
    if (selectDate[0]) {
      let startDate = selectDate[0];
      let dayDiffSelect = moment(date).diff(startDate, "days") + 1;

      // if (dayDiffSelect < 30 && !selectedDone) {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      if (dayDiff < 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  render() {
    const { locale } = this.props.intl;
    moment.locale(locale);
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const placement = smallDevice? "topEnd": "bottomEnd"
    return (
      <div>
     

        <DateRangePicker
          format="DD.MM.YYYY"
          locale={{
            sunday: "So",
            monday: "Mo",
            tuesday: "Di",
            wednesday: "Mi",
            thursday: "Do",
            friday: "Fr",
            saturday: "Sa",
            ok: "OK",
            today: "Heute",
            yesterday: "Gestern",
            last7Days: "letze 7 Tage",
          }}
          disabledDate={combine(beforeToday(), this.isDisabledDate)}
          size="lg"
          placeholder="Mietzeitraum"
          showWeekNumbers
          placement={placement }
          hoverRange="week"
          isoWeek
          showOneCalendar={true}
          ranges={[]}
          onChange={this.onDatesChange}
        />
      </div>
    );
  }
}

const bookingFormSelector = formValueSelector("BookingForm");

const mapState = (state) => ({
  isLoading: state.viewListing.isLoading,
  availability: state.viewListing.availability,
  bookingStartDate: bookingFormSelector(state, "startDate"),
  bookingEndDate: bookingFormSelector(state, "endDate"),
});

const mapDispatch = {
  checkAvailability,
  change,
  getSpecialPricingData,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DateRange)));

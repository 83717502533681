// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm, formValueSelector, FieldArray, change } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Internal Component
import IncrementButton from "../IncrementButton";

//Fontawesome
import  { FaInfoCircle } from 'react-icons/fa';

// Helpers
import validateStep1 from "./validateStep1";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, InputGroup, Popover, OverlayTrigger, ControlLabel, FormControl } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component
import ListPlaceTips from "../ListPlaceTips";

import update from "./update";

// Internal Component
import IncrementBtn from "./IncrementBtn";


class Page3 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    beds: PropTypes.number,
    nextPage: PropTypes.any,
    bedTypes: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showStorageText:  props.storageRack || 0,
      bedType: [],
      beds: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      bedrooms: [],
      personCapacity: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const { listingFields, beds, change, bedTypes } = this.props;
    const { bedType } = this.state;
    let obj, bedsArray = [];     if (listingFields != undefined) {
      this.setState({
        bedType: listingFields.bedType,
        beds: listingFields.beds[0],
        bedrooms: listingFields.bedrooms,
        personCapacity: listingFields.personCapacity[0],
      });
    }

    if((bedTypes && beds != bedTypes.length) || (bedTypes && bedTypes.length == 0)) {
      Array(beds).fill().map((i, index)=> {
        obj = {
          bedCount: index + 1,
          bedType: bedType.length > 0 ? bedType[0].id : null,
        };
        bedsArray.push(obj);
      })
      change('bedTypes', bedsArray);
    } 

    this.calculateBasePrice();
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        bedType: listingFields.bedType,
        beds: listingFields.beds[0],
        bedrooms: listingFields.bedrooms,
        personCapacity: listingFields.personCapacity[0],
      });
    }
    this.calculateBasePrice();
  }

  setShowStorageText = (event) => {
    this.setState({ showStorageText: event.target.value });
  };

  renderIncrementButton = (field) => <IncrementButton {...field} />;

  renderIncrementBtn = (input, dispatch) => <IncrementBtn {...input} onChange={this.handleClick} />;

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderFormControlSquareMeter = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup>
          <InputGroup>
            <FormControl {...input} placeholder={label} type={type} className={className} maxLength={5} />
            <InputGroup.Addon>m²</InputGroup.Addon>
          </InputGroup>
        </FormGroup>

        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderBedTypes = ({ fields, meta: { error, submitFailed } }) => {
    const { formatMessage } = this.props.intl;
    const { bedType } = this.state;
    return (
      <div className={s.spaceTop2}>
        {fields.map((beds, index) => (
          <div key={index}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <FormGroup className={s.formGroup}>
                  <Field name={`${beds}.bedType`} type="text" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                    {bedType.map((value, key) => {
                      return (
                        value.isEnable == 1 && (
                          <option value={value.id} key={key}>
                            {value.itemName}
                          </option>
                        )
                      );
                    })}
                  </Field>
                </FormGroup>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  };

  renderFormControlMeter = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup>
          <InputGroup>
            <FormControl {...input} placeholder={label} type={type} className={className} maxLength={5} />
            <InputGroup.Addon>m</InputGroup.Addon>
          </InputGroup>
        </FormGroup>

        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <FormControl {...input} className={className} placeholder={label} componentClass={"textarea"} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  async handleClick(e) {
    const { beds, bedTypes, change } = this.props;
    const { bedType } = this.state;

    let bedTypesValue = Object.keys(bedTypes).map(function(key) {
      return bedTypes[key];
    });

    let updatedBedTypesArray = bedTypesValue && bedTypesValue.length > 0 ? bedTypesValue.slice(0) : [];
    let updatedBedCount = beds + 1;
    let obj = {};

    if (Number(beds) < Number(e)) {
      obj = {
        bedCount: updatedBedCount,
        bedType: bedType.length > 0 ? bedType[0].id : null,
      };
      try {
        updatedBedTypesArray.push(obj);
      } catch (e) {}
    } else if (Number(beds) >= Number(e) || Number(e) > 2) {
      updatedBedTypesArray.splice(bedTypesValue.length - 1, 1);
    }

    //is the first call necessary?
    await change("bedTypes", []);
    await change("bedTypes", updatedBedTypesArray);
  }


  calculateBasePrice = () => {
    //update baseprice
    let { nkPrice, qmPrice, bookingType, propertyArea } = this.props;
    if (propertyArea && qmPrice && nkPrice && qmPrice > 0 && nkPrice > 0) {
      let calculatedBasePrice = parseFloat(((nkPrice / 30 + qmPrice / 30) * propertyArea).toFixed(2) * 1.1);
      this.props.change("basePrice", calculatedBasePrice );
      this.props.change("bookingType", bookingType );
    }
  };
 
  render() {
    const { handleSubmit, submitting, pristine, previousPage, nextPage, bedTypes, invalid } = this.props;
    const { bedrooms, bedType, beds, personCapacity, showStorageText } = this.state;
    const { formatMessage } = this.props.intl;
    const { isExistingList } = this.props;
    let path = isExistingList ? "Standort" : "Lage";

    let floorLoading = [
      {
        value: "Standard",
        label: "Standard",
      },
      {
        value: "Hoch",
        label: "Hoch",
      },
    ];

    let flooring = [
      {
        value: "Beton",
        label: "Beton",
      },
      {
        value: "Epoxidharz",
        label: "Epoxidharz",
      },
      {
        value: "Teer",
        label: "Teer",
      },
      {
        value: "Holz",
        label: "Holz",
      },
      {
        value: "Fliesen",
        label: "Fliesen",
      },
      {
        value: "Dielen",
        label: "Dielen",
      },
      {
        value: "PVC",
        label: "PVC",
      },
      {
        value: "Sonstige",
        label: "Sonstige",
      },
    ];

    const popoverFloorHeight = (
      <Popover id="popover-trigger-hover-focus" title="Hallen-/ Geschosshöhe">
        Die am niedrigsten relevanten Punkt gemessene Höhe
      </Popover>
    );
    const popoverTotalArea = (
      <Popover id="popover-trigger-hover-focus" title="Lager-/Produktionsfläche">
        Die gesamte für den Mieter innerhalb geschlossener Räume nutzbare Lager- bzw. Produktionsfläche.
      </Popover>
    );
    const popoverFloorLoading = (
      <Popover id="popover-trigger-hover-focus" title="Bodenbelastung">
        <p>
          <strong>Standard:</strong>
          <br />
          Bodenbelastung (EG) bis zu 3 Tonnen pro m², <br />
          Deckenbelastung (OG) bis zu 350kg/m²{" "}
        </p>
        <p>
          <strong>Hoch:</strong>
          <br />
          Bodenbelastung (EG) bis zu 5 Tonnen pro m², <br />
          Deckenbelastung (OG) bis zu 350kg/m²{" "}
        </p>
      </Popover>
    );

    const isBrowser = typeof window !== 'undefined';
    const smallDevice = isBrowser ? window.matchMedia('(max-width: 640px)').matches : undefined;
    const overlayPlacement = smallDevice ? 'bottom' : 'right';

    return (
      <Grid fluid>
        <Row className={cx(s.landingContainer, "arrowPosition")}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <div>
              <h3 className={s.landingContentTitle}>
                <FormattedMessage {...messages.howManyGuests} />
              </h3>
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.totalArea} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup} style={{ maxWidth: "348px" }}>
                      <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverTotalArea}>
                        <FaInfoCircle className={s.infoIcon} />
                      </OverlayTrigger>
                      <Field
                        className={cx(s.formControlInput, s.jumboSelect)}
                        name="personCapacity"
                        type="text"
                        type="number"
                        onChange={this.calculateBasePrice}
                        component={this.renderFormControlSquareMeter}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.floorHeight} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup} style={{ maxWidth: "348px" }}>
                      <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverFloorHeight}>
                        <FaInfoCircle className={s.infoIcon} />
                      </OverlayTrigger>
                      <Field
                        className={cx(s.formControlInput, s.jumboSelect)}
                        name="floorHeight"
                        type="text"
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?" step="0.01"

                        component={this.renderFormControlMeter}
                        label={formatMessage(messages.floorHeight)}
                      />
                    </InputGroup>
                  </FormGroup>

               


                  <FormGroup className={cx(s.formGroup)}>
                      <ControlLabel className={s.landingLabel}>
                        <FormattedMessage {...messages.floorLoading} />
                      </ControlLabel>
                      <InputGroup className={s.infoGroup}>
                        <Field name="floorLoading" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                          )
                          {floorLoading.map((value, key) => {
                            return (
                              <option value={value.value} key={key}>
                                {value.label}
                              </option>
                            );
                          })}
                        </Field>
                        
                        <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverFloorLoading}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                      </InputGroup>
                    </FormGroup>

                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.flooring} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup}>
                      <Field name="flooring" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                        {flooring.map((item, key) => {
                          return (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Field>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.storageRack} />
                    </ControlLabel>
                    <div>
                      <label className={cx(s.blockRadioButton, s.landingLabel)}>
                        <FormattedMessage {...messages.noText} />{" "}
                        <Field name="storageRack" component="input" type="radio" value="0" className={s.pullRight} onChange={this.setShowStorageText} />
                      </label>
                      <label className={cx(s.blockRadioButton, s.landingLabel)}>
                        <FormattedMessage {...messages.yesText} />{" "}
                        <Field name="storageRack" component="input" type="radio" value="1" className={s.pullRight} onChange={this.setShowStorageText} />
                      </label>
                    </div>
                  </FormGroup>
                  {showStorageText > 0 && (
                    <FormGroup className={s.formGroup}>
                      <Field
                        name="storageRackDescription"
                        component={this.renderFormControlTextArea}
                        className={s.textareaInput}
                        label={formatMessage(messages.storageRackDescription)}
                      />
                    </FormGroup>
                  )}
                  {/* <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.listingAdditionalInfo} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup} style={{ maxWidth: "348px" }}>
                      <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverListingAdditionalInfo}>
                        <FaInfoCircle className={s.infoIcon} />
                      </OverlayTrigger>
                      <Field
                        name="additionalInfo"
                        component={this.renderFormControlTextArea}
                        className={s.textareaInput}
                        label={formatMessage(messages.listingAdditionalInfo)}
                      />
                    </InputGroup>
                  </FormGroup> */}

                  {/* <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.howManyBedrooms} />
                    </ControlLabel>
                    <Field name="bedrooms" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)} >
                      {
                        bedrooms.map((value, key) => {
                          let rows = [];
                          for (let i = value.startValue; i <= value.endValue; i++) {
                            rows.push(<option value={i}>{i} {i > 1 ? value.otherItemName : value.itemName}</option>);
                          }
                          return rows;
                        })
                      }
                    </Field>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.howManyBeds} />
                    </ControlLabel>
                    <Field
                      name="beds"
                      type="text"
                      component={this.renderIncrementBtn}
                      labelSingluar={beds.itemName}
                      labelPlural={beds.otherItemName}
                      minValue={beds.startValue}
                      incrementBy={1}
                      maxValue={beds.endValue}
                    />
                  </FormGroup> 

                  <FieldArray name="bedTypes" component={this.renderBedTypes} className={s.spaceTop2} />
*/}
                </div>
                <div className={s.nextPosition}>
                  <div className={s.nextBackButton}>
                    <hr className={s.horizontalLineThrough} />

                    <FormGroup className={s.formGroup}>
                      <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                        <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Sicherheit")}>
                          <FormattedMessage {...messages.back} />
                        </Button>
                        <Button disabled={invalid} className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage("Ausstattung")}>
                          <FormattedMessage {...messages.next} />
                        </Button>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Page3 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update,
  validate: validateStep1,
})(Page3);

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep1"); // <-- same as form name
const selectorStep3 = formValueSelector("ListPlaceStep3"); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  beds: selector(state, "beds"),
  bedCount: selector(state, "beds"),
  bedTypes: selector(state, "bedTypes"),
  storageRack: selector(state, "storageRack"),
  propertyArea: selector(state, "personCapacity"),
  qmPrice: selectorStep3(state, "qmPrice"),
  nkPrice: selectorStep3(state, "nkPrice"),
  bookingType: selectorStep3(state, "bookingType"),

});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Page3)));

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3UxFg{display:block;height:185px;-webkit-backface-visibility:hidden;backface-visibility:hidden;position:relative;vertical-align:bottom;overflow:hidden;background-color:#bbb;text-align:center;margin-top:15px}._2U__h{position:absolute;right:0;left:0;bottom:0;top:0}._1JA-s{height:100%;width:auto;max-width:100%}", ""]);

// exports
exports.locals = {
	"listPhotoCover": "_3UxFg",
	"listPhotoMedia": "_2U__h",
	"imgResponsive": "_1JA-s"
};
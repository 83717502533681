import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import { Row, Col, Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { FaInfoCircle, FaChevronDown } from 'react-icons/fa';

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payment.css";

// Component
import CurrencyConverter from "../../CurrencyConverter";
// Locale
import messages from "../../../locale/messages";
import Faq from "./question.svg";

// Redux Form
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
// Redux
import { connect } from "react-redux";

import Collapsible from "react-collapsible";

import moment from "moment";
class PaymentDetails extends Component {
  static propTypes = {
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    currency: PropTypes.string.isRequired,
    dayDifference: PropTypes.number.isRequired,
    discount: PropTypes.number,
    discountType: PropTypes.string,
    priceForDays: PropTypes.number.isRequired,
    serviceFees: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    formatMessage: PropTypes.any,
    bookingSpecialPricing: PropTypes.array,
    isSpecialPriceAssigned: PropTypes.bool,
  };

  static defaultProps = {
    bookingSpecialPricing: [],
    isSpecialPriceAssigned: false,
  };

  renderMonthlyPayments = (checkIn, checkOut, isAverage) => {
    const { taxRate, cleaningPrice } = this.props;
    const { locale } = this.props;
    moment.locale(locale);
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    const bookingPeriods = Math.floor(bookingDays / 30);
    const bookingPeriodPrice = 30 * isAverage.toFixed(2);
    // const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";
    // const cleaningPriceWithTax = cleaningPrice + (cleaningPrice / 100) * taxRate;

    const restDays = bookingDays % 30;
    const restPrice = restDays * isAverage.toFixed(2) + cleaningPrice;
    // const restPrice = restPriceWithoutTax + (restPriceWithoutTax / 100) * taxRate;

    const popoverRestPrice = (
      <Popover id="popover-trigger-hover-focus" title="Restbetrag">
        Der Restbetrag beinhaltet die durch die Monatsgebühr noch nicht abgedeckten Buchungstage sowie die Übergabepauschale.
        <br />
        Restbetrag Miete: <b>{(restDays * isAverage).toFixed(2)}€</b>
        <br />
        Übergabepauschale: <b>{cleaningPrice.toFixed(2)}€</b>
        
      </Popover>
    );

    let bookingPeriodsContent = [];
    for (let i = 1; i <= bookingPeriods; i++) {
      if (i < bookingPeriods) {
        bookingPeriodsContent.push(
          <div key={i} className={cx(s.tableText, s.bookingPeriods)}>
            <span className={cx(s.noBorder)}>
              {i + 1}. Monatsrate (
              {moment(checkIn)
                .add(30 * (i - 1) + 1, "days")
                .format("DD.MM.YYYY")}
              ):
            </span>
            <span className={cx(s.noBorder, s.textRight)}>
              <b>{bookingPeriodPrice.toFixed(2)}€</b>
            </span>
          </div>
        );
      }
    }

    //rest
    bookingPeriodsContent.push(
      <div className={cx(s.tableText, s.bookingPeriods)}>
        <span className={cx(s.noBorder)}>
          Restbetrag (
          {moment(checkIn)
            .add(30 * (bookingPeriods - 1), "days")
            .format("DD.MM.YYYY")}
          ):
          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRestPrice}>
            <FaInfoCircle />
          </OverlayTrigger>
        </span>
        <span className={cx(s.noBorder, s.textRight)}>
          <b>{restPrice.toFixed(2)}€</b>
        </span>
      </div>
    );
    const openLink = (
      <span className={s.bookingPeriodsLink}>
        Zahlungsdetails <FaChevronDown className={s.toolTipColor} />{" "}
      </span>
    );
    return (
      <div className={s.bookingPeriod}>
        <Collapsible trigger={openLink}>
          <div className={cx(s.tableText, s.bookingPeriods)}>
            <span className={cx(s.noBorder)}>1. Monatsrate (bei Buchung): </span>
            <span className={cx(s.noBorder, s.textRight)}>
              <b>{bookingPeriodPrice.toFixed(2)}€</b>
            </span>
          </div>
          {bookingPeriodsContent}
        </Collapsible>
      </div>
    );
  };

  render() {
    const {
      basePrice,
      cleaningPrice,
      currency,
      dayDifference,
      taxRateFee,
      taxRate,
      service_description,
      services,
      service_price_hour,
      service_price_kg,
      service_price_qm,
      service_price_palette,
      monthlyPay,
      checkIn,
      checkOut,
      paymentType,
    } = this.props;
    const { priceForDays, serviceFees, discount, discountType, total } = this.props;
    const { formatMessage } = this.props.intl;
    const { bookingSpecialPricing, isSpecialPriceAssigned, isAverage } = this.props;

    const { locale } = this.props;
    moment.locale(locale);

    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    const bookingPeriods = Math.floor(bookingDays / 30);
    const bookingPeriodPrice = 30 * isAverage.toFixed(2);
    // const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
    const cleaningPriceWithTax = cleaningPrice + (cleaningPrice / 100) * taxRate;
    function LinkWithTooltip({ id, children, href, tooltip }) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip className={s.tooltip} id={id}>
              {tooltip}
            </Tooltip>
          }
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {/* <a href={href}>{children}</a> */}
          {children}
        </OverlayTrigger>
      );
    }
    const isMonthly = monthlyPay;

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";
    const popoverServiceDescription = (
      <Popover id="popover-trigger-hover-focus" title="Wichtige Informationen">
        {service_description}
      </Popover>
    );

    return (
      <div>
        <div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} style={{paddingLeft: "30px", paddingRight: "30px"}}>
              {(paymentType === "monthly" && isMonthly) && this.renderMonthlyPayments(checkIn, checkOut, isAverage)}

              <table className={cx("table")}>
                <tbody>
                  {(paymentType !== "monthly" || !isMonthly) && (
                    <tr className={s.tableText}>
                      <td className={cx(s.noBorder)}>
                        <div className={s.specialPriceIcon}>
                          {isSpecialPriceAssigned && (
                            <span className={s.iconSection}>
                              <img src={Faq} className={s.faqImage} />
                            </span>
                          )}
                          <div className={cx(s.tltip, s.relativeSection)}>
                            <FormattedMessage {...messages.averagePricePerNight} />
                          </div>
                        </div>
                        <div className={s.specialPriceText}>
                          <span>
                            <CurrencyConverter
                              //amount={basePrice}
                              amount={isAverage}
                              from={currency}
                            />
                          </span>

                          <span>
                            {" x " + dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                          </span>
                        </div>
                      </td>
                      <td className={cx(s.noBorder, "text-right")}>
                        <span>
                          <b>
                            <CurrencyConverter amount={priceForDays} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}

                  {serviceFees > 0 && (
                    <tr className={s.tableText}>
                      <td className={cx(s.noBorder)}>
                        <FormattedMessage {...messages.serviceFee} />
                      </td>
                      <td className={cx(s.noBorder, "text-right")}>
                        <span>
                          <b>
                            <CurrencyConverter amount={serviceFees} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}
                  {discount > 0 && (
                    <tr className={s.tableText}>
                      <td className={cx(s.noBorder)}>{discountType}</td>
                      <td className={cx(s.noBorder, "text-right")}>
                        -{" "}
                        <span>
                          <b>
                            <CurrencyConverter amount={discount} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}

                  {(paymentType === "monthly" && isMonthly) && (
                    <tr className={cx(s.tableText, s.bookingPeriodTotal)}>
                      <td className={cx(s.noBorder)}>Mietkosten</td>
                      <td className={cx(s.noBorder, "text-right")}>
                        <span>
                          <b>
                            <CurrencyConverter amount={total - taxRateFee} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}

                  {(cleaningPrice > 0  && paymentType !== "monthly")&& (
                    <tr className={s.tableText}>
                      <td className={cx(s.noBorder)}>
                        {paymentType === "monthly" && <span>Inkl. </span>}
                        {/* {paymentType !== "monthly" && <span>zzgl. </span>} */}
                        <FormattedMessage {...messages.cleaningFee} />
                      </td>
                      <td className={cx(s.noBorder, "text-right")}>
                        <span>
                          <b>
                            <CurrencyConverter amount={paymentType === "monthly" ? cleaningPriceWithTax : cleaningPrice} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}
                  {taxRateFee > 0 && (
                    <tr className={s.tableText}>
                      <td className={cx(s.noBorder)}>
                        {paymentType === "monthly" && <span>zzgl. </span>}MwSt. ({taxRate}%): 
                      </td>
                      <td className={cx(s.noBorder, "text-right")}>
                        <span>
                          <b>
                            <CurrencyConverter amount={taxRateFee} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}
                  {(paymentType === "monthly" && isMonthly) && (
                    <tr className={cx(s.tableText, s.bookingPeriodTotal)}>
                      <td className={cx(s.noBorder)}>Insgesamt</td>
                      <td className={cx(s.noBorder, "text-right")}>
                        <span>
                          <b>
                            <CurrencyConverter amount={total} from={currency} />
                          </b>
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>

        {services && (
          <div className={cx(s.services, s.space2)}>
            <Col xs={12} sm={12} md={12} lg={12} className={s.smPadding}>
              <table className={cx("table")}>
                <tbody>
                  <tr>
                    <td className={cx(s.noBorder)}>
                      zzgl. der jeweiligen Dienstleistungen vor Ort
                      <br />
                      <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverServiceDescription}>
                        <span className={s.overlayTrigger}>
                          Wichtige Informationen zu den Dienstleistungen <FaInfoCircle />
                        </span>
                      </OverlayTrigger>
                      <span>
                        <br />

                        {service_price_hour > 0 && (
                          <div className={s.serviceDetailsWrapper}>
                            <span className={s.ServiceCurrency}>
                              <CurrencyConverter amount={service_price_hour} from={currency} />
                            </span>
                            <span className={s.ServiceUnit}> pro Stunde</span>
                          </div>
                        )}
                        {service_price_kg > 0 && (
                          <div className={s.serviceDetailsWrapper}>
                            <span className={s.ServiceCurrency}>
                              <CurrencyConverter amount={service_price_kg} from={currency} />
                            </span>
                            <span className={s.ServiceUnit}> pro kg</span>
                          </div>
                        )}
                        {service_price_qm > 0 && (
                          <div className={s.serviceDetailsWrapper}>
                            <span className={s.ServiceCurrency}>
                              <CurrencyConverter amount={service_price_qm} from={currency} />
                            </span>
                            <span className={s.ServiceUnit}> pro m²</span>
                          </div>
                        )}
                        {service_price_palette > 0 && (
                          <div className={s.serviceDetailsWrapper}>
                            <span className={s.ServiceCurrency}>
                              <CurrencyConverter amount={service_price_palette} from={currency} />
                            </span>
                            <span className={s.ServiceUnit}> pro Palette</span>
                          </div>
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </div>
        )}
        <div className={cx(s.totalValue, s.space2)}>
          <Col xs={12} sm={12} md={12} lg={12} className={s.smPadding}>
            <table className={cx("table")}>
              <tbody>
                {(paymentType === "monthly" && isMonthly )? (
                  <tr className={s.totalText}>
                    <td className={cx(s.noBorder)}>Monatliche Rate</td>
                    <td className={cx(s.noBorder, "text-right")}>
                      <span>
                        <b>
                          <CurrencyConverter amount={bookingPeriodPrice} from={currency} />
                        </b>
                      </span>
                    </td>
                  </tr>
                ) : (
                  <tr className={s.totalText}>
                    <td className={cx(s.noBorder)}>
                      <FormattedMessage {...messages.total} />
                    </td>
                    <td className={cx(s.noBorder, "text-right")}>
                      <span>
                        <b>
                          <CurrencyConverter amount={total} from={currency} />
                        </b>
                      </span>
                    </td>
                  </tr>
                )}

                <tr>
                  <td className={cx(s.noBorder)}>
                    <span className={s.info}>({paymentType === "monthly" ? "Zzgl." : "Inkl."} MwSt.({taxRate}%), inkl. Servicegebühr)</span>
                  </td>
                  <td className={cx(s.noBorder, "text-right")}></td>
                </tr>
              </tbody>
            </table>
          </Col>
        </div>
      </div>
    );
  }
}

// PaymentDetails = reduxForm({
//   form: "PaymentForm", // a unique name for this form
//   destroyOnUnmount: false,
// })(PaymentDetails);

// Decorate with connect to read form values
const selector = formValueSelector("PaymentForm"); // <-- same as form name

const mapState = (state) => ({
  paymentType: selector(state, "paymentType"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PaymentDetails)));

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import CancelationImage from "./cancelation_info.png";
import s from "./Payment.css";

class CancelInfo extends Component {
  static propTypes = {
    hostDisplayName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalCancelationOpen: false,
    };
  }
  openCancelationModal = () => {
    this.setState({ modalCancelationOpen: true });
  };
  closeCancelationModal = () => {
    this.setState({ modalCancelationOpen: false });
  };

  render() {
    const { hostDisplayName, houseRules, checkIn, checkOut } = this.props;
    const { modalCancelationOpen } = this.state;
    const { locale } = this.props;
    moment.locale(locale);
    let moreThan30Days = moment().isBefore(moment(checkIn).subtract(30, "days"), 'day'); 
    let cancelText = moreThan30Days
      ? "Kostenlose Stornierung für 48 Stunden. Storniere danach bis " +
        moment(checkIn)
          .subtract(30, "days")
          .format("MMM Do YYYY") +
        " um eine volle Rückerstattung abzüglich einer Service-Gebühr zu erhalten."
      : "Sie buchen weniger als 4 Wochen vor Einzug. Im Falle einer Stornierung erhalten Sie den Gesamtbetrag abzüglich der ersten Monatsmiete zzgl. Servicegebühr zurück.";

    return (
      <div className={s.cancelInfo}>
        <h3 className={cx(s.h3, s.space4)}>
          <span>Stornierungsbedingungen</span>
        </h3>
        <p style={{ marginBottom: "10px" }}>{cancelText}</p>
        <span onClick={this.openCancelationModal} className={s.modalLink}>
          Weitere Einzelheiten
        </span>

        <Modal bsSize="large" show={modalCancelationOpen} animation={false} onHide={this.closeCancelationModal} dialogClassName={cx(s.cancelationModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3 className={s.photoModalHeadline}>Stornierungsrichtlinien</h3>
              <img style={{ width: "100%" }} src={CancelationImage} />
              <p className={s.modalText}>
                Es gelten die oben stehenden Bedingungen, insbesondere im Bezug auf Ihr Recht in bestimmten Fällen eine anteilige oder vollständige Erstattung
                der einbehaltenen Beträge zu verlangen.
              </p>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={this.closeCancelationModal}>
                Fertig
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withStyles(s)(CancelInfo);

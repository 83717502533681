exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1PmKJ{right:5px;top:5px;z-index:2}._1PmKJ,._3Wl6-{position:absolute}._3Wl6-{right:25px;top:25px;padding:10px 16px;display:inline-block;text-align:center;border-radius:4px;width:auto;font-size:13px;line-height:18px;color:#404040;font-weight:400;border:1px solid transparent;min-width:52px;background:#fff}._1LfUA{line-height:1;cursor:pointer;font-size:26px;color:#575757}.kyKT8{color:#e31b23;font-size:26px}._3Wl6- ._1LfUA{max-width:20px!important}._2xnOc{padding-left:6px}._2gjMv{vertical-align:bottom}._1e645{vertical-align:middle}", ""]);

// exports
exports.locals = {
	"iconContainer": "_1PmKJ",
	"buttonContainer": "_3Wl6-",
	"wishListIcon": "_1LfUA",
	"selected": "kyKT8",
	"buttonText": "_2xnOc",
	"vtrBottom": "_2gjMv",
	"vtrMiddle": "_1e645"
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Tr, Td } from 'reactable';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ShowFailedPayout.css';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';


class ShowFailedPayout extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,

    }

    constructor(props) {
        super(props);
    }

    render() {
        const { title, data } = this.props;
        const gobackcss = { padding: '10px' };

       let payoutAmount = data.total - data.hostServiceFee;
       let reason = data && data.hostFailedTransaction && data.hostFailedTransaction.reason; 
       reason = JSON.parse(reason);
       
       return (
            <div className={cx(s.pagecontentWrapper)}>
                <div className={s.contentBox}>
                    <h1 className={s.headerTitle}>{title}</h1>
                    <div className={'table-responsive'}>
                        <Link to={'/siteadmin/payout'} className={cx('pull-right')} style={gobackcss}>
                            Go back
                        </Link>
                        <Table
                            className="table"
                            noDataText="No matching records found."
                            sortable={true}
                        >
                            {data &&
                                <Tr>
                                    <Td column={"Reservation ID"}>
                                        {data.id}
                                    </Td>
                                    <Td column={"Code"}>
                                        {data.confirmationCode}
                                    </Td>
                                    {
                                        data.listData && <Td column={"List Title"}>
                                            <a href={"/spaces/" + data.listData.id} target='_blank'>
                                                {data.listData.title}
                                            </a>
                                        </Td>
                                    }
                                    {
                                        !data.listData && <Td column={"List Title"} data={"List is missing"} />
                                    }
                                    <Td column={"Host Name"}>
                                        {data.hostData && data.hostData.firstName}
                                    </Td>
                                    <Td column={"Payout Amount"}>
                                        <CurrencyConverter
                                            amount={payoutAmount}
                                            from={data.currency}
                                        />
                                    </Td>
                                    <Td column={"Reason"}>
                                        {reason && reason.raw && reason.raw.message}
                                    </Td>
                                </Tr>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }

}

export default withStyles(s)(ShowFailedPayout);
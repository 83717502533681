import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Payin.css";
import { FormattedMessage, injectIntl } from "react-intl";
import  { FaFile } from 'react-icons/fa';

// Redux
import { connect } from "react-redux";
import {
  Button,
  Panel,
} from "react-bootstrap";
import { formValueSelector } from "redux-form";
import sepaLogo from './sepa_logo.png';
// Locale
import messages from "../../../locale/messages";
import { deleteMandateData } from "../../../actions/Payin/deleteMandateData";
class MandateConfirm extends Component {
  static propTypes = {
    previousPage: PropTypes.any.isRequired,
  };

  handlePreviousPage = () => {
    const { deleteMandateData } = this.props;
      deleteMandateData()
  }

  render() {
    const { previousPage } = this.props;
    const {  mandateId, mandateDocumentUrl, mandateConfirmUrl} = this.props;
    const { formatMessage } = this.props.intl;
  
    return (
      <Panel
        className={s.panelHeader}
        // header={formatMessage(messages.addPayin)}
        header={"SEPA-Lastschrift Mandat"}
        footer={
          <div>
            <Button
              className={cx(
                s.button,
                s.btnlarge,
                s.btnPrimaryBorder,
                s.btnRight
              )}
              onClick={this.handlePreviousPage}
            >
              <FormattedMessage {...messages.back} />
            </Button>
            <a
              className={s.iconLink}
              href={mandateConfirmUrl}
            >
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)}>
                Mandat bestätigen
              </Button>
            </a>
          </div>
        }
      >
        <div className={s.panelBody}>
          <div className={s.sepaLogo}>
            <img src={sepaLogo} />
          </div>
          <div>
            Bitte prüfen Sie Ihr Mandat-Dokument und bestätigen Sie es.
          </div>
        
          {mandateDocumentUrl && (
              <div className={s.sepaLink}>
            <a
              className={s.iconLink}
              href={mandateDocumentUrl}
              target="_blank"
              rel="noopener noreferrer"
              title="Mandat-Dokument"
            >
              <FaFile /> Ihr SEPA-Lastschrift Mandat
            </a>
            </div>
          )}
        </div>
      </Panel>
    );
  }
}

const selector = formValueSelector("PayinForm");

const mapState = (state) => ({
  mandateId: state.payin.mandateId,
  mandateDocumentUrl: state.payin.mandateDocumentUrl,
  mandateConfirmUrl: state.payin.mandateConfirmUrl,
});

const mapDispatch = {deleteMandateData};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(MandateConfirm))
);

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ReadyBox.css";
import { Grid, Row, Col } from "react-bootstrap";
import Link from "../../Link/Link";

class ContentBox3 extends React.Component {
  scrollTop() {
    window.scrollTo({
      top: screen.height,
      behavior: "smooth",
    });
  }

  render() {



    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx(s.table)}>
              
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <h2 className={s.title}>Sind Sie bereit loszulegen?</h2>
                <h3 className={s.subTitle}>Jetzt kostenlos anmelden und profitieren!</h3>
                </Col>
              

              </Row>
              <Row>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <Link onClick={this.scrollTop} className={cx(s.btn, s.btnPrimary)}>
                  FLÄCHE BUCHEN
                    </Link>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <Link to={"/become-a-host?mode=new"} className={cx(s.btn, s.btnSecondary)}>
                  FLÄCHE ANBIETEN
                    </Link>
                </Col>
              

              </Row>
              {/* <div className={cx(s.tableCell, s.textColumn, s.mobileView)}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.caption}>{description}</p>
                <div className={s.listItems}>
                  {listItems.map((item, index) => {
                    return (
                      <div className={s.listItem} key={index}>
                        <img src={ArrowDown} />
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
                {buttonLabel && (
                  <Link to={buttonLink} className={cx(s.btn, s.btnPrimary)}>
                    {buttonLabel}
                  </Link>
                )}
              </div> */}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s)(ContentBox3);

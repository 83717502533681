import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
    Button,
  } from 'react-bootstrap';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListItem.css';
import cx from 'classnames';

// Redux action
import { ManagePublishStatus } from '../../../actions/Listing/ManagePublishStatus';

// Locale
import messages from '../../../locale/messages';
import Confirm from 'react-confirm-bootstrap';

class PublishOption extends Component {
    static propTypes = {
        listId: PropTypes.number.isRequired,
        isPublished: PropTypes.bool.isRequired,
        isApproved: PropTypes.bool.isRequired,
        ManagePublishStatus: PropTypes.any.isRequired,
        formatMessage: PropTypes.any,
    };

    static defaultProps = {
        isPublished: false,
        isApproved: false
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(action) {
        const { listId, ManagePublishStatus } = this.props;
        // let action = event.target.value;
        ManagePublishStatus(listId, action);
        // ManagePublishStatus(listId, action, threadId);
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { isPublished, isApproved} = this.props;
        let defaultValue = 'unPublish';
        if (isPublished) {
            defaultValue = 'publish';
        }
        return (
            // <span className="publishArrow">
            //     <select className={cx(s.formSelect, s.formSelectNew)} value={defaultValue} onChange={this.handleChange}>
            //         <option value="publish">{formatMessage(messages.listed)}</option>
            //         <option value="unPublish">{formatMessage(messages.unListed)}</option>
            //     </select>
            // </span>

            <Confirm
            onConfirm={() => this.handleChange("unPublish")}
            body="Sind Sie sicher Ihre Fläche bearbeiten zu wollen? Ihre Fläche muss dann erneut geprüft werden"
            confirmText="Inserat editieren"
            cancelText="Abbrechen"
            title="Veröffentlichung zurücknehmen"
          >
            <a href="javascript:void(0)"> <Button className={cx(s.button, s.btnPrimary, s.spaceRight2, s.spaceTop, s.smButton, s.listButton)}>
            Inserat bearbeiten

                  </Button></a>
          </Confirm>
        )
    }
}

const mapState = (state) => ({});

const mapDispatch = {
    ManagePublishStatus
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PublishOption)));
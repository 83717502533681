import { SET_USER_DATA_START, SET_USER_DATA_SUCCESS, SET_USER_DATA_ERROR } from "../constants";
import { toastr } from "react-redux-toastr";

// Redirection
import history from "../core/history";
import { decode } from "../helpers/queryEncryption";

const query = `
  query userAccount{
    userAccount {
      userId
      profileId
      companyName
      firstName
      lastName
      address1
      address2
      city
      zip
      taxId
      companyRegistrationNumber
      displayName
      gender
      dateOfBirth
      email
      userBanStatus
      phoneNumber
      preferredLanguage
      preferredCurrency
      location
      info
      createdAt
      picture
      country
      countryCode
      countryName
      nationality
      region
      companyCountry
      mangopayUserId
      mangopayUserWalletId
      mangopayDepositWalletId
      profileComplete
      profileCompleteUbo
      verification {
        id
        isEmailConfirmed
        isFacebookConnected
        isGoogleConnected
        isIdVerification
        isPhoneVerified
        isKycVerified
      }
      userData {
        type
      }
    }
  }
`;
export function loadAccount(loginScreen, refer) {
  return async (dispatch, getState, { graphqlRequest }) => {
    dispatch({
      type: SET_USER_DATA_START,
    });
    try {
      const { data } = await graphqlRequest(query);

      if (data && data.userAccount) {
        let dateOfBirth = data.userAccount.dateOfBirth;
        let updatedProfileData;
        if (dateOfBirth != null) {
          let dateOfBirthArray = dateOfBirth.split("-");
          let dateOfBirthObj = {
            month: Number(dateOfBirthArray[1] - 1),
            year: dateOfBirthArray[2],
            day: dateOfBirthArray[0],
          };
          let decodedObj = {
            email: decode(data.userAccount.email),
            phoneNumber: decode(data.userAccount.phoneNumber),
          };
          updatedProfileData = Object.assign({}, data.userAccount, dateOfBirthObj, decodedObj);
        } else {
          let decodedObj = {
            email: decode(data && data.userAccount && data.userAccount.email),
            phoneNumber: decode(data && data.userAccount && data.userAccount.phoneNumber),
          };
          updatedProfileData = { ...data.userAccount, ...decodedObj };
        }
        updatedProfileData["email"] = updatedProfileData["email"].replace(/['"]+/g, "");
        dispatch({
          type: SET_USER_DATA_SUCCESS,
          updatedProfileData,
        });

        if (loginScreen) {
          toastr.success("Login", "Sie sind jetzt angemeldet!");

          if (refer) {
            history.push(refer);
          }
          //  else {
          //   history.push('/dashboard');
          // }
        }
      }
    } catch (error) {
      dispatch({
        type: SET_USER_DATA_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }
    return true;
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url, sitename } from '../../../config';
import CurrencyView from '../modules/CurrencyView';

class BookingRequestGuest extends React.Component {

  static propTypes = {
    content: PropTypes.shape({
      confirmationCode: PropTypes.number.isRequired,
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      listTitle: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
    }).isRequired

  };

  render() {
    const textStyle = {
      color: '#404040',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center'
    }
    const bankStyle = {
      border: '1px solid #ccc',
      padding: '20px'
    }

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#0667B3',
      backgroundColor: '#0667B3',
      color: '#ffffff',
      borderTopWidth: '1px',

    }

    moment.locale("de-DE")
    const { content: { guestName, listTitle, hostName, checkIn, threadId, confirmationCode, logo, data, currency, amount } } = this.props;
    let checkInDate = checkIn != null ? moment(checkIn).format('ddd, Do MMM, YYYY') : '';
    let messageURL = url + '/message/' + threadId + '/guest';

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%" >
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>
                    Hallo {guestName},
                    </div>
                  <EmptySpace height={20} />
                  <div>
                    Ihre Buchung ({confirmationCode}) für die Fläche {listTitle} ab dem {checkInDate} ist eingegangen.
                    </div>
                  <EmptySpace height={20} />
                  <div>
                   Sie haben als Zahlungsmethode Überweisung ausgewählt. Bitte überweisen Sie den geforderten Betrag auf das unten angegebene Konto.
                   Bitte beachten Sie dabei die Referenznummer die im Überweisungszweck angegeben werden muss, damit wir Ihre Zahlung zuordnen können
                   
                    </div>
                  <EmptySpace height={40} />
                  <div style={bankStyle}>
                      Zahlungsempfänger: <b>{data.bankAccount.OwnerName}</b><br />
                      IBAN: <b>{data.bankAccount.IBAN}</b><br />
                      BIC:  <b>{data.bankAccount.BIC}</b><br />

                      Referenznummer: <b>{data.wireReference}</b><br />
                      Betrag: <b><CurrencyView amount={amount} currency={currency} /></b>

                    </div>
                  <EmptySpace height={40} />
                  <div style={btnCenter}>
                    <a href={messageURL} style={buttonStyle}>{hostName} kontaktieren</a>
                  </div>
                  <EmptySpace height={40} />
                  <div>
                    Vielen Dank, <br />
                    Ihr {sitename} Team
                    </div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }

}

export default BookingRequestGuest;

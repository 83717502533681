// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";
import  { FaCheck } from 'react-icons/fa';

// Locale
import messages from "../../locale/messages";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component
import ListPlaceTips from "../ListPlaceTips";

import updateStep3 from "./updateStep3";

class GuestRequirements extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      guestRequirements: [],
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({ guestRequirements: listingFields.guestRequirements });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({ guestRequirements: listingFields.guestRequirements });
    }
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage } = this.props;
    const { guestRequirements } = this.state;
    //TODO Translate
    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <h3 className={cx(s.landingContentTitle, s.space5)}>Voraussetzungen für Mieter</h3>
            <p className={cx(s.landingStep3, s.space3)}>
              <span>Expozed1 hat Bedingungen, die alle Mieter erfüllen müssen, bevor sie buchen.</span>
            </p>
            <form onSubmit={handleSubmit}>
              <ul className={cx("list-unstyled", s.unorderedList)}>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>Unternehmensadresse</span>
                </li>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>Ansprechpartner</span>
                </li>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>E-Mail Adresse</span>
                </li>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>Nutzerverifizierung</span>
                </li>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>Zahlungsinformationen</span>
                </li>
              </ul>
              <h4> Alle Mieter müssen folgendes tun, bevor sie eine Buchung durchführen können:</h4>
              <ul className={cx("list-unstyled", s.unorderedList)}>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>Zustimmung der Vermieterbedingungen</span>
                </li>
                <li>
                  <FaCheck className={cx(s.checkIcon)} />
                  <span className={cx(s.landingStep3, s.space3)}>Bestätigung der Nutzungs-, Zahlungs- & Stornierungsbedingungen</span>
                </li>
              </ul>
              <p>
                Wir setzen alles daran, Ihnen einen transparenten und sicheren Vermietungsprozess zu ermöglichen. Sollten Sie sich dennoch unsicher fühlen,
                wenden Sie sich jederzeit an unseren Service wenden.
              </p>
              <div className={s.nextPosition}>
                <div className={s.nextBackButton}>
                  <hr className={s.horizontalLineThrough} />

                  <FormGroup className={s.formGroup}>
                    <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                      <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("home")}>
                        <FormattedMessage {...messages.back} />
                      </Button>
                      <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage("Bedingungen hinzufügen")}>
                        <FormattedMessage {...messages.next} />
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
GuestRequirements = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep3,
})(GuestRequirements);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(GuestRequirements)));

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FaFile } from "react-icons/fa";
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
import { deleteMandateData } from "../../../../actions/Payin/deleteMandateData";
import history from "../../../../core/history";
// Locale
import messages from "../../../../locale/messages";
import s from "./Payin.css";
import sepaLogo from "./sepa_logo.png";

class MandateConfirm extends Component {
  static propTypes = {
    previousPage: PropTypes.any.isRequired,
  };
  handlePreviousPage = () => {
    const { deleteMandateData } = this.props;
    deleteMandateData();
  };

  componentDidMount() {
    //set new bookingdata
    //SEt localstorage with bookingdata
    let savedBookingData = JSON.parse(localStorage.getItem("booking"));

    if (savedBookingData) {
      localStorage.removeItem("booking");
    }

    let bookingData = this.props.book;
    bookingData["location"] = history.location ? history.location.pathname : null;
    bookingData["timestamp"] = new Date();
    bookingData["mandateReturned"] = true;
    localStorage.setItem("booking", JSON.stringify(bookingData));
  }
  render() {
    const { previousPage } = this.props;
    const { mandateDocumentUrl, mandateConfirmUrl } = this.props;

    return (
      <div className={s.panelBody}>
        <h2>Bankmandat</h2>
        <div className={s.sepaLogo}>
          <img src={sepaLogo} />
        </div>
        <div className={s.sepaWrapper}>
          Bitte prüfen Sie Ihr Mandat-Dokument und bestätigen Sie es.
          {mandateDocumentUrl && (
            <div className={s.sepaLink}>
              <a className={s.iconLink} href={mandateDocumentUrl} target="_blank" rel="noopener noreferrer" title="Mandat-Dokument">
                <FaFile /> Ihr SEPA-Lastschrift Mandat
              </a>
            </div>
          )}
        </div>
        <div className={s.displayInline}>
          <Button className={cx(s.button, s.btnlarge, s.btnPrimaryBorder, s.btnRight)} onClick={this.handlePreviousPage}>
            <FormattedMessage {...messages.back} />
          </Button>
          <a className={s.iconLink} href={mandateConfirmUrl}>
            <Button className={cx(s.button, s.btnPrimary, s.btnlarge)}>Mandat bestätigen</Button>
          </a>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  mandateId: state.payin.mandateId,
  book: state.book,
  mandateDocumentUrl: state.payin.mandateDocumentUrl,
  mandateConfirmUrl: state.payin.mandateConfirmUrl,
});

const mapDispatch = { deleteMandateData };

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MandateConfirm)));

import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './DocumentList.css';

//pdf image
import pdfIcon from './pdf_image.png';

//GraphGL
import ShowDocumentListQuery from './ShowListDocument.graphql';
import RemoveDocumentList from './RemoveDocumentList.graphql';

//toastr
import { toastr } from 'react-redux-toastr';

// Locale
import messages from '../../locale/messages';
import { FaRegTrashAlt } from 'react-icons/fa';

class DocumentList extends Component {

  static defaultProps = {
    data: {
      loading: true,
      showDocumentList: []
    },
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick(id, fileName) {
    const { mutate, isKyc,kycType } = this.props;
    let type = isKyc ? "kyc" : "document";
    const { data } = await mutate({
      RemoveDocumentList,
      variables: { id },
      refetchQueries: [{ query:  ShowDocumentListQuery, variables: { type: type, kycType: kycType }  }]
    });


    if (data && data.RemoveDocumentList && data.RemoveDocumentList.status == "success") {
      const resp = await fetch('/deleteDocuments', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
        credentials: 'include',
      });
      const { status } = await resp.json();
      toastr.success("Erfolgreich entfernt!", "Dokument wurde erfolgreich entfernt!");
    }
  }


  render() {
    const { data: { ShowDocumentList }, data } = this.props;
    const { disabled } = this.props;
    let path = "/images/document/";
   


    return (
      <div className={cx('row', s.space2, s.photoContainer)}>
        {
          ShowDocumentList && ShowDocumentList.map((item, key) => {
            let icon = item.fileType == 'application/pdf' ? pdfIcon : (path + item.fileName);
            return (
              <div key={key} className={cx('col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center')}>
                <a href={path + item.fileName} target="_blank">
                  <div className={s.listPhotoCover}>
                    <div className={s.listPhotoMedia}>
                      <img className={s.imgResponsive} src={icon} />
                    </div>
                  </div>
                </a>
                {!disabled && ( <a href="javascript:void(0);" onClick={() => this.handleClick(item.id, item.fileName)}>
                <FaRegTrashAlt />
                </a>)}
               
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(ShowDocumentListQuery, {
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        type: props.isKyc ? "kyc" : "document",
        kycType: props.kycType,
      }
    })
  
  }),
  graphql(RemoveDocumentList, {
    options: {
      fetchPolicy: 'network-only'
    }
  }),
)(DocumentList);


// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";
import { FaPlus } from "react-icons/fa";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Internal Components
import CustomCheckbox from "../CustomCheckbox";
import ListPlaceTips from "../ListPlaceTips";

import updateStep3 from "./updateStep3";
let textInput = React.createRef();

class HouseRules extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      houseRules: [],
      newHouseRules: [],
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      //if(listingFields.houseRules.length > 0) {
      this.setState({ houseRules: listingFields.houseRules });
      //}
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      //if(listingFields.houseRules.length > 0) {
      this.setState({ houseRules: listingFields.houseRules });
      //}
    }
  }

  renderRules = ({ label, name, options, input, newHouseRules, houseRules }) => {

    //input Value
    let items = [];

    const staticHouseRules = [...new Set([...input.value, "rauchen verboten", "Blablubb", "foo"])];

    const sortedHouseRules = staticHouseRules.sort(function (a, b) {
      if (a.toLowerCase()  < b.toLowerCase()) return -1;
      else if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    });
    console.log("sortedHouseRules",sortedHouseRules)
    sortedHouseRules.map((rule, index) => {
      items.push(
        <li className={s.listContent} key={index}>
          <span className={s.checkBoxSection}>
            <CustomCheckbox
              name={`${input.name}[${index}]`}
              value={rule}
              checked={input.value.indexOf(rule) !== -1}
              className={"icheckbox_square-green"}
              onChange={(event) => {
                const newValue = [...input.value];
                if (event === true) {
                  newValue.push(rule);
                } else {
                  newValue.splice(newValue.indexOf(rule), 1);
                }
                return input.onChange(newValue);
              }}
            />
          </span>
          <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
            <label className={cx(s.checkboxLabel, s.noPadding)}>{rule}</label>
          </span>
        </li>
      );
    });

    return <ul className={s.listContainer}>{items}</ul>;
  };

  addNewRule = () => {
    const newRule = textInput.current.value;
    let rules = this.props.houseRulesFormValues;
    if (newRule && newRule.length > 0) {
      const newRules = [...rules, newRule];
      this.props.change("houseRules", newRules);
      textInput.current.value = "";
    }
  };

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage } = this.props;
    const { houseRules, newHouseRules } = this.state;

    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <div>
              <h3 className={s.landingContentTitle}>
                <FormattedMessage {...messages.setHouseRules} />
              </h3>
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <FormGroup className={s.formGroup}>
                    <Field name="houseRules" component={this.renderRules} houseRules={houseRules} newHouseRules={newHouseRules} />
                  </FormGroup>

                  <div>
                    <input
                      ref={textInput}
                      className={s.newInput}
                      placeholder="Neue Bedingung hinzufügen"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.addNewRule();
                        }
                      }}
                    />
                    <Button
                      onClick={(event) => {
                        this.addNewRule();
                      }}
                      className={s.newItemButton}
                      color="primary"
                    >
                      <FaPlus className={cx(s.checkIcon)} />
                    </Button>
                  </div>
                </div>
                <div className={s.nextPosition}>
                  <div className={s.nextBackButton}>
                    <hr className={s.horizontalLineThrough} />

                    <FormGroup className={s.formGroup}>
                      <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                        <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Bedingungen")}>
                          <FormattedMessage {...messages.back} />
                        </Button>
                        <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage("Vorlaufszeit")}>
                          <FormattedMessage {...messages.next} />
                        </Button>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
const selector = formValueSelector("ListPlaceStep3");

HouseRules = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep3,
})(HouseRules);
const mapState = (state) => ({
  listingFields: state.listingFields.data,
  houseRulesFormValues: selector(state, "houseRules"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(HouseRules)));

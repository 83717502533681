exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1cfXE{background-color:#404040;width:64px;display:block;height:1.5px;margin:0 auto;margin-bottom:27px}.EOJ2W{height:2.5px;border:1px solid #404040;background-color:transparent}._3IDvh{margin-bottom:160px}._1H-zp h1{font-size:60px;line-height:46px;text-align:center;font-weight:700;margin-bottom:73px}p._1qb6e{font-size:16px;line-height:1.7;font-weight:400}._1YS4L{margin-bottom:74px}._1YS4L p{color:#404040}._1IWXl{padding-right:96px}._1YS4L a,._1YS4L ul li{font-size:16px;font-weight:400}._1YS4L ul li{line-height:3;color:#404040;list-style-type:none}._1YS4L ul li:before{content:\"\";background-image:url(" + require("./symbol.png") + ");display:inline-block;width:24px;height:18px;background-repeat:no-repeat;margin-right:12px;margin-left:-40px}._25rFL{padding-left:8%;padding-right:0}@media screen and (max-width:991px){h4._1qb6e{font-size:23px;font-weight:600}._1H-zp h1{font-size:36px;line-height:51px;margin-bottom:56px}}@media screen and (max-width:767px){._1H-zp h1{font-size:24px;line-height:30px;margin-bottom:20px;text-align:left;padding-right:15px;padding-left:15px}h4._1qb6e{font-size:26px}._1IWXl{padding-right:15px;padding-left:15px}._3J4p1{padding-left:40px;margin-top:14px}._1YS4L{margin-bottom:30px}._1YS4L ul li{font-size:14px;line-height:2;margin-bottom:10px}._1YS4L ul li:before{margin-left:-40px}p._1qb6e{font-size:14px;line-height:1.7;font-weight:400}}", ""]);

// exports
exports.locals = {
	"seperator": "_1cfXE",
	"boxseperator": "EOJ2W",
	"hostingsection": "_3IDvh",
	"mainhedding": "_1H-zp",
	"common": "_1qb6e",
	"coveredsection": "_1YS4L",
	"coveredtextarea": "_1IWXl",
	"rightsidecontent": "_25rFL",
	"coverul": "_3J4p1"
};

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button, Col,

  Modal, Row
} from 'react-bootstrap';
// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux
import { connect } from 'react-redux';
// Redux Form
import { change, formValueSelector, getFormValues, reduxForm, submit as submitForm } from 'redux-form';
// Locale
import messages from '../../../../locale/messages';
// Submit
import submit from '../../SearchForm/submit';
import CheckboxListItems from './CheckboxListItems';
import FloorHeight from './FloorHeight';
import HomeType from './HomeType';
import InstantBook from './InstantBook';
import s from './MoreFilters.css';
import Price from './Price';
// Internal Components
import RoomsBeds from './RoomsBeds';







class MoreFilters extends Component {

  static propTypes = {
    className: PropTypes.any,
    handleTabToggle: PropTypes.any,
    isExpand: PropTypes.bool
  };

  static defaultProps = {
    isExpand: false,
    fieldsSettingsData: {
      roomType: [],
      essentialsAmenities: [],
      spaces: [],
      houseRules: []
    },
    homeType: []
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setBtnWrapperRef = this.setBtnWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { isExpand } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);
    if (isExpand) {
      document.querySelector('body').setAttribute('style', 'overflow: hidden');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.querySelector('body').removeAttribute('style');
  }

  componentWillReceiveProps(nextProps) {
    const { isExpand } = nextProps;
    if (isExpand) {
      document.querySelector('body').setAttribute('style', 'overflow: hidden');
    } else {
      document.querySelector('body').removeAttribute('style');
    }
  }

  async handleSubmit() {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submitForm } = this.props;
    await change('currentPage', 1);
    submitForm('SearchForm');
    handleTabToggle('moreFilters', !isExpand)
  }

  handleReset() {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submitForm } = this.props;
    handleTabToggle('moreFilters', !isExpand)
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setBtnWrapperRef(node) {
    this.btnWrapperRef = node;
  }

  handleClickOutside(event) {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submitForm } = this.props;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      change('currentPage', 1);
      submitForm('SearchForm');
      if (this.btnWrapperRef && !this.btnWrapperRef.contains(event.target)) {
        handleTabToggle('moreFilters', !isExpand);
      }
    }
  }

  render() {
    const { className, handleTabToggle, isExpand, formValues, searchSettings } = this.props;
    const { fieldsSettingsData: { essentialsAmenities, spaces, houseRules } } = this.props;
    const { formatMessage } = this.props.intl;


    let floorLoadingOptions = [
      {
        id: "gering (bis 350kg/m²)",
        isEnable: "1",
        itemName: "gering (bis 350kg/m²)",
      },
      {
        id: "standard (bis 500kg/m²)",
        isEnable: "1",
        itemName: "standard (bis 500kg/m²)",
      },
      {
        id: "hoch (bis 750kg/m²)",
        isEnable: "1",
        itemName: "hoch (bis 750kg/m²)",
      },
      {
        id: "besonders hoch(über 750kg/m²)",
        isEnable: "1",
        itemName: "besonders hoch(über 750kg/m²)",
      },
    ];


    let powerConnectionOptions = [
      {
        id: "Standard",
        isEnable: "1",
        itemName: "Standard"
      },
      {
        id: "Produktion",
        isEnable: "1",
        itemName: "Produktion"
      },
      {
        id: "Kein Stromanschluss",
        isEnable: "1",
        itemName: "Kein Stromanschluss"
      }
    ];

    let lightingOptions = [
      {
        id: "gering",
        isEnable: "1",
        itemName: "gering"
      },
      {
        id: "standard",
        isEnable: "1",
        itemName: "standard"
      },
      {
        id: "hoch",
        isEnable: "1",
        itemName: "hoch"
      },
      {
        id: "besonders hoch",
        isEnable: "1",
        itemName: "besonders hoch"
      }
    ];

    let flooringOptions = [
      {
        id: "Beton",
        isEnable: "1",
        itemName: "Beton"
      },
      {
        id: "Epoxidharz",
        isEnable: "1",
        itemName: "Epoxidharz"
      },
      {
        id: "Teer",
        isEnable: "1",
        itemName: "Teer"
      },
      {
        id: "Sonstige",
        isEnable: "1",
        itemName: "Sonstige"
      }
    ];

    let buildingClimateOptions = [
      {
        id: "beheizt",
        isEnable: "1",
        itemName: "beheizt"
      },
      {
        id: "unbeheizt",
        isEnable: "1",
        itemName: "unbeheizt"
      },
      {
        id: "gekühlt",
        isEnable: "1",
        itemName: "gekühlt"
      },
      {
        id: "klimatisiert",
        isEnable: "1",
        itemName: "klimatisiert"
      },
      {
        id: "belüftet",
        isEnable: "1",
        itemName: "belüftet"
      }
    ];

    let buildingConditionOptions = [
      {
        id: "Erstbezug",
        isEnable: "1",
        itemName: "Erstbezug"
      },
      {
        id: "Neuwertig",
        isEnable: "1",
        itemName: "Neuwertig"
      },
      {
        id: "altersgemäß gut",
        isEnable: "1",
        itemName: "altersgemäß gut"
      },
      {
        id: "reparaturbedürftig",
        isEnable: "1",
        itemName: "reparaturbedürftig"
      }
    ]

    let floorCountOptions = [
      {
        id: "EG",
        isEnable: "1",
        itemName: "EG"
      },
      {
        id: "UG",
        isEnable: "1",
        itemName: "UG"
      },
      {
        id: "1.OG",
        isEnable: "1",
        itemName: "1.OG"
      },
      {
        id: "2.OG",
        isEnable: "1",
        itemName: "2.OG"
      },
      {
        id: "3.OG",
        isEnable: "1",
        itemName: "3.OG"
      }
    ]


    let isActive = false;

    if (formValues && (formValues.beds || formValues.bedrooms || formValues.bathrooms
      || (formValues.amenities && formValues.amenities.length) || (formValues.spaces && formValues.spaces.length)
      || (formValues.houseRules && formValues.houseRules.length))) {
      isActive = true;
    }
    return (
      <div className={className}>
        <div ref={this.setBtnWrapperRef}>
          <Button
            className={cx({ [s.btnSecondary]: (isExpand === true || isActive == true) }, s.btn, s.btnFontsize, s.responsiveFontsize, s.searchBtn)}
            onClick={() => { handleTabToggle('moreFilters', !isExpand) }}>
            <span className={cx('hidden-md hidden-lg')}>
              <FormattedMessage {...messages.filter} />
            </span>
            <span className={cx('hidden-xs hidden-sm')}>
              <FormattedMessage {...messages.moreFilters} />
            </span>
          </Button>
        </div>
        <Modal show={isExpand} animation={false} onHide={() => { handleTabToggle('moreFilters', !isExpand) }} className={'moreFilterModal'}>
          <Modal.Header closeButton>
            <FormattedMessage {...messages.moreFilters} />
          </Modal.Header>
          <Modal.Body>
            <div className={s.filterSection} ref={this.setWrapperRef}>
              <div>
            
                <HomeType
                  className={cx(s.filters, 'visible-xs', s.space4, s.showTabletSection)}
                />
                <Price
                  className={cx(s.filters, 'visible-xs', s.space4, s.showTabletSection)}
                  searchSettings={searchSettings}
                />
                <InstantBook
                  className={cx(s.filters, 'visible-xs', s.space4, s.showTabletSection)}
                />
                <RoomsBeds
                  className={s.filters}
                />
                <FloorHeight
                  className={s.filters}
                  minHeight={1}
                  maxHeight={12}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'floorLoading'}
                  options={floorLoadingOptions}
                  captionTitle={formatMessage(messages.floorLoading)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={floorLoadingOptions && floorLoadingOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'flooring'}
                  options={flooringOptions}
                  captionTitle={formatMessage(messages.flooring)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={flooringOptions && flooringOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'floorCount'}
                  options={floorCountOptions}
                  captionTitle={formatMessage(messages.floorCount)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={flooringOptions && flooringOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'powerConnection'}
                  options={powerConnectionOptions}
                  captionTitle={formatMessage(messages.powerConnection)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={powerConnectionOptions && powerConnectionOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'lighting'}
                  options={lightingOptions}
                  captionTitle={formatMessage(messages.lighting)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={lightingOptions && lightingOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'buildingClimate'}
                  options={buildingClimateOptions}
                  captionTitle={formatMessage(messages.buildingClimate)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={buildingClimateOptions && buildingClimateOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'buildingCondition'}
                  options={buildingConditionOptions}
                  captionTitle={formatMessage(messages.buildingCondition)}
                  showLabel={formatMessage(messages.showAll)}
                  hideLabel={formatMessage(messages.showLess)}
                  isActive={buildingClimateOptions && buildingClimateOptions.length > 0}
                />
                 <CheckboxListItems
                  className={s.filters}
                  fieldName={'amenities'}
                  options={essentialsAmenities}
                  captionTitle={formatMessage(messages.aminities)}
                  showLabel={formatMessage(messages.showAmenities)}
                  hideLabel={formatMessage(messages.closeAmenities)}
                  isActive={formValues && formValues.amenities && formValues.amenities.length > 0}
                />
                <CheckboxListItems
                  className={s.filters}
                  fieldName={'spaces'}
                  options={spaces}
                  captionTitle={formatMessage(messages.facilities)}
                  showLabel={formatMessage(messages.showAllFacilities)}
                  hideLabel={formatMessage(messages.closeFacilities)}
                  isActive={formValues && formValues.spaces && formValues.spaces.length > 0}
                />
                {/* <CheckboxListItems
                  // className={s.filters}
                  fieldName={'houseRules'}
                  options={houseRules}
                  captionTitle={formatMessage(messages.houseRules)}
                  showLabel={formatMessage(messages.showAllHouseRules)}
                  hideLabel={formatMessage(messages.closeHouseRules)}
                  isActive={formValues && formValues.houseRules && formValues.houseRules.length > 0}
                /> */}
              </div>
            </div>
            <div className={cx(s.footerSection)}>
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12} >
                  <Col lg={6} md={6} sm={6} xs={6} className={cx(s.spaceTop2, s.textAlignLeft)}>
                    <Button
                      bsStyle="link"
                      className={cx(s.btnLink, s.linkText)}
                      onClick={this.handleReset}>
                      <FormattedMessage {...messages.cancel} />
                    </Button>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6} className={cx(s.textAignRight)}>
                    <Button
                      className={cx(s.btn, s.btnSecondary, s.applyBtn)}
                      onClick={this.handleSubmit}>
                      <FormattedMessage {...messages.applyFilters} />
                    </Button>
                  </Col>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

MoreFilters = reduxForm({
  form: 'SearchForm', // a unique name for this form
  onSubmit: submit,
  destroyOnUnmount: false,
})(MoreFilters);

// Decorate with connect to read form values
const selector = formValueSelector('SearchForm'); // <-- same as form name

const mapState = (state) => ({
  fieldsSettingsData: state.listingFields.data,
  homeType: selector(state, 'roomType'),
  formValues: getFormValues('SearchForm')(state),
});

const mapDispatch = {
  change,
  submitForm
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MoreFilters)));
// General
import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, ControlLabel, FormControl, FormGroup, Grid, InputGroup, Row } from "react-bootstrap";
//Fontawesome
import  { FaShieldAlt } from 'react-icons/fa';
// Translation
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
// Redux Form
import { Field, formValueSelector, reduxForm } from "redux-form";
// Locale
import messages from "../../locale/messages";
// Internal Components
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import s from "./ListPlaceStep1.css";
import update from "./update";
// Helpers
import validateStep1 from "./validateStep1";

class Page2_1 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      roomType: [],
      isDisabled: true,
      securityClass: null,
      setRoomTypeSecurity: false,
      setRoomTypeInit: false,
      noRoomTypeSelected: false
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;

    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        security: listingFields.security,
      });
    }
  }

  componentDidMount() {
    const { valid } = this.props;

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,

        security: listingFields.security,
      });
    }
  }
  checkboxGroup = ({ label, name, options, input }) => (
    <ul className={s.listContainer}>
      {options.map((option, index) => {
        return (
          <li className={s.listContent} key={index}>
            <span className={s.checkBoxSection}>
              <CustomCheckbox
                name={`${input.name}[${index}]`}
                className={"icheckbox_square-green"}
                value={option.id}
                disabled={option.id !== "no_security" && input.value.includes("no_security")}
                checked={input.value.indexOf(option.id) !== -1}
                onChange={(event) => {
                  let newValue = [...input.value];
                  if (event === true) {
                    newValue.push(option.id);
                  } else {
                    newValue.splice(newValue.indexOf(option.id), 1);
                  }
                  if (newValue.includes("no_security")) {
                    newValue = ["no_security"];
                  }

                  return input.onChange(newValue);
                }}
              />
            </span>
            <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
              <label className={cx(s.checkboxLabel, s.noPadding)}>{option.itemName}</label>
            </span>
          </li>
        );
      })}
    </ul>
  );

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  onChangeRoomtype = (event, newValue, previousValue, name) => {
    if(newValue === "0"){
      this.setState({noRoomTypeSelected: true})
    }else {
      this.setState({noRoomTypeSelected: false})
    }
  }

  render() {
    const { roomTypeListing, change, security, handleSubmit, submitting, pristine, valid, previousPage, nextPage, existingList } = this.props;
    const { houseType, roomType, buildingSize, setRoomTypeSecurity, setRoomTypeInit,noRoomTypeSelected } = this.state;
    const { formatMessage } = this.props.intl;
    let { isDisabled } = this.state;
    let roomTypeOptions = [...roomType];
    roomTypeOptions.unshift({ id: 0, itemName: "Keine Angabe", isEnable: 1 });
    

    if (noRoomTypeSelected) {
      isDisabled = true;
    }
    let securityOptions = [
      {
        id: "cylinder_lock",
        value: "cylinder_lock",
        itemName: "Bündiges Zylinderschloss",
      },
      {
        id: "security_window",
        value: "security_window",
        itemName: "Fenster mit Standardsicherung",
      },
      {
        id: "alarm_system",
        value: "alarm_system",
        itemName: "Alarmanlage (VdS geprüft)",
      },
      {
        id: "no_security",
        value: "no_security",
        itemName: "Keine Sicherheitsstandards",
      },
    ];

    let buildingTypeOptions = [
      {
        value: "Thermofassade/Sandwich",
        label: "Thermofassade/Sandwich",
      },
      {
        value: "Ziegel",
        label: "Ziegel",
      },
      {
        value: "Beton",
        label: "Beton",
      },
      {
        value: "Holz",
        label: "Holz",
      },
      {
        value: "Sonstiges",
        label: "Sonstiges",
      },
    ];

    let buildingTypeOptions2 = [
      {
        value: "Thermoelement/Sandwich",
        label: "Thermoelement/Sandwich",
      },
      {
        value: "Blechdach",
        label: "Blechdach",
      },
      {
        value: "Holzdach",
        label: "Holzdach",
      },
      {
        value: "Ziegeldach",
        label: "Ziegeldach",
      },
      {
        value: "Sonstiges",
        label: "Sonstiges",
      },
    ];
    let path = "index";
    if (existingList) {
      path = "home";
    }

    let securityClass = "";
    if (security) {
      let q0 = security.includes("no_security");
      let q1 = security.includes("cylinder_lock");
      let q2 = security.includes("security_window");
      let q3 = security.includes("alarm_system");


      if (q1 && !q2 && !q3) {
        securityClass = "SK1";
      }
      if (q1 && q2 && !q3) {
        securityClass = "SK2";
      }
      if (q1 && q2 && q3) {
        securityClass = "SK3";
      }
      if (!q1 && (q2 || q3)) {
        securityClass = "SK0";
      }
      if (q1 && !q2 && q3) {
        securityClass = "SK0";
      }
      if (!q0 && q1 && q3 && !q2) {
        securityClass = "SK1";
      }
      if (q0) {
        securityClass = "SK0";
      }
      change("securityClass", securityClass);
    }

    const hasNoSecurity = security && securityClass === "SK0";
    let selectedRoomType = roomTypeOptions.filter(function(room) {
      return room.id == roomTypeListing;
    })[0];
    let selectedRoomTypeListing = selectedRoomType ? selectedRoomType.itemName : null;
    const roomTypeHasNoSecurity =
      selectedRoomTypeListing === "Außenfläche" || selectedRoomTypeListing === "Offene Lager- & Logistikfläche" ;
    //Set security class for specific roomType
    if (!setRoomTypeSecurity && hasNoSecurity) {

      this.setState({ setRoomTypeSecurity: true,noRoomTypeSelected: true });
    }

    if(roomTypeHasNoSecurity ) {
      change("securityClass", "SK0");

    }


    if ((securityClass === "" && !roomTypeHasNoSecurity ) || hasNoSecurity && noRoomTypeSelected) {
      isDisabled = true;
    }

    if(valid && !hasNoSecurity && securityClass !== "") {
      isDisabled = false;
    }


    return (
      <div>
        <Grid fluid>
          <Row className={cx(s.landingContainer, "arrowPosition")}>
            <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
              {roomTypeHasNoSecurity ? (
                <div className={s.securityError}>
                  <h3>Hinweis zu Offenen Lager- und Logistikfläche</h3>
                  <p>
                    Sie buche eine Offene Lager- und Logistikfläche bzw. eine Außenfläche. Kunden nutzen diese Fläche hauptsächlich als Stellplatz oder
                    Lagerfläche für große oder nicht schutzbedürftige Güter.
                  </p>
                  <p>
                    Bitte beachten Sie, dass Offene Lager- und Logistikflächen, sowie Außenflächen sind nicht versicherbar sind. Sollten Sie einen Inhaltsschutz
                    für Ihre Güter benötigen buchen Sie bitte eine Fläche aus den Kategorien Lager- oder Produktionsfläche.
                  </p>
                </div>
              ) : (
                <div>
                  {/* TODO translate */}
                  <h3 className={s.landingContentTitle}>Sicherheit</h3>
                  <form onSubmit={handleSubmit}>
                    <div className={s.landingMainContent}>
                      <FormGroup className={cx(s.formGroup)}>
                        <ControlLabel className={s.landingLabel}>
                          <FormattedMessage {...messages.buildingType} />
                        </ControlLabel>
                        <InputGroup className={s.infoGroup}>
                          <Field name="buildingType" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                            {buildingTypeOptions.map((item, key) => {
                              return (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Field>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={cx(s.formGroup)}>
                        <ControlLabel className={s.landingLabel}>
                          <FormattedMessage {...messages.buildingType2} />
                        </ControlLabel>
                        <InputGroup className={s.infoGroup}>
                          <Field name="buildingType2" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                            {buildingTypeOptions2.map((item, key) => {
                              return (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Field>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={s.formGroup}>
                        <ControlLabel className={s.landingLabel}>Versicherung</ControlLabel>
                        <InputGroup className={s.infoGroup} style={{ maxWidth: "348px" }}>
                          <Field name="security" component={this.checkboxGroup} options={securityOptions} />
                        </InputGroup>
                      </FormGroup>
                      {hasNoSecurity && (
                        <div className={s.securityError}>
                          <h3>Hinweis zu Offenen Lager- und Logistikfläche</h3>
                          <p>
                            Ihr Objekt verfügt nicht über die erforderlichen Sicherheitstands Um Ihr Objekt anbieten zu können müssen Sie die Objektart ändern.
                            Bitte wählen Sie die passende Objektart:
                          </p>

                          <Field
                            name="roomType"
                            component={this.renderFormControlSelect}
                            className={cx(s.backgroundPosition, s.formControlSelect, s.jumboSelect, s.noFontWeight)}
                            onChange={this.onChangeRoomtype}
                          >
                            {roomTypeOptions.map((value, key) => {
                              return (
                                value.isEnable == 1 &&
                                (value.itemName === "Außenfläche" ||
                                  value.itemName === "Offene Lager- & Logistikfläche" ||
                                  value.itemName === "Keine Angabe") && (
                                  <option value={value.id} key={key}>
                                    {value.itemName}
                                  </option>
                                )
                              );
                            })}
                          </Field>
                        </div>
                      )}

                      {security && securityClass && !hasNoSecurity && (
                        <div className={s.securitySuccess}>
                          Ihr Objekt ist versicherbar und besitzt die Sicherheitsklasse{" "}
                          <div className={s.securityClass}>
                            {securityClass}
                            <span title={"Versicherbar"}>
                              <FaShieldAlt className={s.instantIcon} />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              )}
              <div className={s.nextPosition}>
                <div className={s.nextBackButton}>
                  <hr className={s.horizontalLineThrough} />

                  <FormGroup className={s.formGroup}>
                    <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                      <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Allgemeine Informationen")}>
                        <FormattedMessage {...messages.back} />
                      </Button>
                      <Button
                        className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)}
                        disabled={isDisabled}
                        onClick={() => nextPage("Technische Angaben")}
                      >
                        <FormattedMessage {...messages.next} />
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

Page2_1 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep1,
  onSubmit: update,
})(Page2_1);

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep1"); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  security: selector(state, "security"),
  roomTypeListing: selector(state, "roomType"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Page2_1)));

import React from 'react';
import Layout from '../../components/Layout';
import Contact from './Contact';

const title = 'Kontakt & Support';

export default {

  path: '/kontakt',

  action() {
    return {
      title,
      component: <Layout><Contact title={title} /></Layout>,
    };
  },

};

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1VM7H{background-color:#fff!important;border-radius:4px;box-shadow:0 1px 10px 0 #404040}._2LaAI{padding:10px}._2ygYJ{color:#404040!important;font-size:16px;font-weight:400;line-height:normal}._3gXcl{padding-right:16px;padding-left:16px}._1WFZB{color:#404040!important;font-style:italic;font-weight:700;text-decoration:underline}._1fw_q{border-color:#0667b3!important;background-color:#0667b3!important;color:#fff;font-size:16px;font-weight:700;margin-top:0;float:right;z-index:9}._1fw_q:active,._1fw_q:active:focus,._1fw_q:active:hover,._1fw_q:focus,._1fw_q:hover{border-color:#2081cd!important;background-color:#2081cd!important;color:#fff!important}._22tGS{position:fixed!important;right:0;bottom:0;left:0;z-index:1005}@media screen and (min-width:768px){._1gyyT{padding:9px 27px}}@media screen and (max-width:480px){._1VM7H{background-color:#fff!important;height:auto}._1fw_q{float:right;margin-top:0;margin-bottom:10px;font-weight:300}._1fw_q,._2ygYJ{font-size:15px}}._1yPtV{width:100%;display:table}._39-bN{width:100%;display:table-row}._1Bezf{width:90%}._1Bezf,._2Z3rk{display:table-cell;vertical-align:middle}._2Z3rk{width:10%}.f11sQ{padding:15px}@media screen and (max-width:767px){._1Bezf,._2Z3rk{display:block;width:100%;vertical-align:middle}._1fw_q{border-color:#0667b3!important;background-color:#0667b3!important;color:#fff;font-size:16px;font-weight:700;margin-top:8px;float:right;z-index:9}.f11sQ{padding:15px}}@media screen and (max-width:1030px){._2ygYJ{color:#404040!important;font-size:14px;font-weight:400;line-height:normal}}", ""]);

// exports
exports.locals = {
	"cookiesBackground": "_1VM7H",
	"root": "_2LaAI",
	"labelText": "_2ygYJ",
	"paddingSpace": "_3gXcl",
	"linkStyle": "_1WFZB",
	"button": "_1fw_q",
	"fixedPosition": "_22tGS",
	"btnlarge": "_1gyyT",
	"displayTable": "_1yPtV",
	"displayRow": "_39-bN",
	"displayText": "_1Bezf",
	"displayBtn": "_2Z3rk",
	"displayTableSection": "f11sQ"
};
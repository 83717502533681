import { reset } from 'redux-form';

// Redux Action
import { contactAgent } from '../../../../actions/message/contactAgent';


async function submit(values, dispatch) {
  // dispatch(contactHostClose());
  let listId = values.listId;
  let content = values.content;
  let email = values.email;
  let name = values.name;
  let phoneNumber = values.phoneNumber;
  let hostName = values.hostName;
  let hostCompanyName = values.hostCompanyName;
  let hostId = values.hostId;
  let listingTitle = values.listingTitle;
  dispatch(contactAgent(
    listId,
    listingTitle,
    content,
    email,
    name,
    phoneNumber,
    hostName,
    hostCompanyName,
    hostId
  ));
  dispatch(reset('ContactAgentForm'));
}

export default submit;

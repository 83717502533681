exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".lgyoy{border:1px solid #ddd;margin-top:20px;padding:10px;border-radius:6px}.lgyoy,.lgyoy div{margin-bottom:10px}.lgyoy img{width:20px;float:left;margin-right:10px}", ""]);

// exports
exports.locals = {
	"CheckItemBox": "lgyoy"
};
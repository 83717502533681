exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2Yzis{position:relative!important;text-align:center;transition-duration:.2s;transition-timing-function:ease-out;width:auto;font-size:14px;line-height:18px;font-weight:400;box-shadow:0 3px 10px 4px rgba(0,0,0,.08);text-decoration:none;border:1px solid transparent;float:right;z-index:9;color:#484848!important;background:#fff!important}._2Yzis:active,._2Yzis:focus,._2Yzis:focus:hover{outline:0;border-color:transparent;box-shadow:0 3px 10px 4px rgba(0,0,0,.08)}._2Yzis:hover{color:#484848!important;background:#fff!important;border-color:transparent!important;outline:0}._1YO7D{position:fixed!important;left:13px;bottom:13px}._1V90z{color:#404040;padding-right:5px;padding-bottom:3px;font-size:20px}", ""]);

// exports
exports.locals = {
	"buttonStyle": "_2Yzis",
	"buttonPosition": "_1YO7D",
	"iconStyle": "_1V90z"
};
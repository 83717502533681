exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2c9op{margin:0 auto;padding-top:30px;max-width:1080px;max-width:var(--max-content-width)}.x2pz7{max-width:1080px;width:100%;display:block;margin:0 auto}._2O3T1{padding-right:20px}._3OLl_{margin:0;padding:15px 0;position:relative;border-bottom:1px solid #dce0e0}.ve9cV{cursor:pointer;position:absolute;right:20px;font-size:20px;color:#bbb;display:none}._3OLl_:hover .ve9cV{display:block}._2wpmt{font-weight:400;line-height:1}.Buwmi{margin-bottom:12px}@media screen and (max-width:640px){._2PEUE{padding:0}}@media screen and (max-width:768px){._3AUrI{padding:0}}@media screen and (max-width:1024px){.ve9cV{position:relative;display:block;right:unset}}", ""]);

// exports
exports.locals = {
	"container": "_2c9op",
	"landingContainer": "x2pz7",
	"panelSpace": "_2O3T1",
	"panelBody": "_3OLl_",
	"iconRemove": "ve9cV",
	"icon": "_2wpmt",
	"space2": "Buwmi",
	"listDetailContent": "_2PEUE",
	"smPadding": "_3AUrI"
};
import { reset } from 'redux-form';

// Redux Action
import { manualRequest } from '../../../actions/message/manualRequest';


async function submit(values, dispatch) {
  // dispatch(contactHostClose());
  let listId = values.listId;
  let content = values.content;
  let startDate = values.startDate;
  let endDate = values.endDate;
  let size = values.size;
  let email = values.email;
  let name = values.name;
  let phoneNumber = values.phoneNumber;
  let hostName = values.hostName;
  let hostCompanyName = values.hostCompanyName;
  let hostId = values.hostId;
  let listingTitle = values.listingTitle;

  dispatch(manualRequest(
    listId,
    listingTitle,
    content,
    startDate,
    endDate,
    size,
    email,
    name,
    phoneNumber,
    hostName,
    hostCompanyName,
    hostId
  ));
  dispatch(reset('ManualRequestForm'));
}

export default submit;

import {gql} from 'react-apollo';

import {
  REMOVE_PAYIN_START,
  REMOVE_PAYIN_SUCCESS,
  REMOVE_PAYIN_ERROR, 
} from '../../constants';


// Mangopay
import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";


import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { getPayins } from './getPayins';

import history from '../../core/history';

export function removePayin(id,mandateId) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: REMOVE_PAYIN_START,
    });

    dispatch(setLoaderStart('payinRemove'));

    let userData = {
      mandateId: mandateId,
    }

    var {
      status,
      errorMessage,
    } = await processMangopayPayment("cancel-bank-mandate", userData);

    try {

      let mutation = gql `
          mutation removePayin(
            $id: Int!, 
          ){
              removePayin(
                id: $id
              ) {
                  status
              }
          }
      `;

      const {data} = await client.mutate({
        mutation,
        variables: {
          id
        }
      });

      if(data && data.removePayin && status === 200) {
        await dispatch({
          type: REMOVE_PAYIN_SUCCESS,
          payload: {
            status: data.removePayin.status
          }
        });

        await dispatch(setLoaderComplete('payinRemove'));

        await dispatch(getPayins());

        history.push('/user/payin');
      }

      await dispatch(setLoaderComplete('payinRemove'));

    } catch (error) {
        dispatch({
          type: REMOVE_PAYIN_ERROR,
          payload: {
            error
          }
        });
      dispatch(setLoaderComplete('payinRemove'));
      return false;
    }

    return true;
  };
}

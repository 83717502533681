// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { createNumberMask } from "redux-form-input-masks";
import { serviceFee } from "../../config";
// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

//Fontawesome
import  { FaInfoCircle, FaRegFilePdf } from 'react-icons/fa';

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep3 from "./validateStep3";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, ControlLabel, InputGroup, Popover, OverlayTrigger, FormControl } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component
import ListPlaceTips from "../ListPlaceTips";
import updateStep3 from "./updateStep3";
import IncrementButton from "../IncrementButton";
const currencyMask = createNumberMask({
  // prefix: 'US$ ',
  suffix: " €/m² monatlich",
  decimalPlaces: 2,
  locale: "de-DE",
});
const currencyMaskEuro = createNumberMask({
  // prefix: 'US$ ',
  suffix: " €",
  decimalPlaces: 2,
  locale: "de-DE",
});
class Pricing extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      qmPriceState: 0,
      nkPriceState: 0,
      cleaningPriceState: 0,
      calculatedBasePrice: 0,
      minNight: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      maxNight: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0,
      },
      lightboxMietpreisOpen: false,
    };
  }

  componentWillMount() {
    const { valid, listingFields, basePrice, cleaningPrice, change } = this.props;
    if (listingFields != undefined) {
      this.setState({
        minNight: listingFields.minNight[0],
        maxNight: listingFields.maxNight[0],
      });
    }
    if (basePrice > 0) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    if (cleaningPrice > 0) {
      change("cleaningPrice2", cleaningPrice - (cleaningPrice / (100 + serviceFee)) * serviceFee);
    }

    this.calculateBasePrice();
  }

  componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    const { minNightData, maxNightData, basePrice, cleaningPrice, change } = nextProps;

    if (listingFields != undefined) {
      this.setState({
        minNight: listingFields.minNight[0],
        maxNight: listingFields.maxNight[0],
      });
    }

    if (basePrice > 0) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    this.calculateBasePrice();
  }
  renderIncrementButton = (field) => <IncrementButton {...field} />;
  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} maxLength={5} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  calculateBasePrice = () => {
    let { nkPrice, qmPrice } = this.props;
    let { propertyArea } = this.props;

    if (propertyArea && qmPrice && nkPrice && qmPrice > 0 && nkPrice > 0) {
      let calculatedBasePrice = parseFloat(((nkPrice / 30 + qmPrice / 30) * propertyArea).toFixed(2) * 1.1);
      this.setState({ calculatedBasePrice, nkPriceState: nkPrice, qmPriceState: qmPrice });
      //Set calculated price to hidden form field
      this.props.change("basePrice", calculatedBasePrice);
    }
  };

  onChangeQmPrice = (event) => {
    this.calculateBasePrice();
  };
  onChangeNkPrice = (event) => {
    this.calculateBasePrice();
  };
  onChangeCleaningPrice = () => {
    let { cleaningPrice, cleaningPrice2, change } = this.props;
    const cleaninPriceWithFee = cleaningPrice2 * ((100 + serviceFee) / 100);
    change("cleaningPrice", cleaninPriceWithFee);
    this.setState({ cleaningPriceState: cleaningPrice2 });
  };

  roundCurrency = (num) => {
    return (Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100).toFixed(2);
  };

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage, cleaningPrice2 } = this.props;
    const { isDisabled, calculatedBasePrice, cleaningPriceState } = this.state;
    let { nkPriceState, qmPriceState } = this.state;
    let { nkPrice, qmPrice } = this.props;
    const { formatMessage } = this.props.intl;
    const { propertyArea, cleaningPrice } = this.props;
    const { minNight, maxNight, lightboxMietpreisOpen } = this.state;
    const { minNightData, maxNightData } = this.props;
    const popoverNkPrice = (
      <Popover id="popover-trigger-hover-focus" title="Nebenkosten pro Monat">
        Bitte tragen Sie hier die Nebenkosten pro m² ein. Mehr Informationen in der Infobox rechts.
      </Popover>
    );
    const popoverQmPrice = (
      <Popover id="popover-trigger-hover-focus" title="Quadratmeterpreis pro Monat">
        Bitte tragen Sie hier den Preis pro m² ein. Mehr Informationen in der Infobox rechts.
      </Popover>
    );

    const infoboxContent = (
      <div className={s.pricingInfoContent}>
        <h3>Jetzt Ihren Mietpreis ermitteln</h3>
        <h4>Mietpreis</h4>
        <p>Realisieren Sie den bestmöglichen Mietpreis für Ihre Fläche und nutzen Sie unsere Mietpreis Karte.</p>
        <a target="_blank" rel="noopener" href={"/assets/mietpreise_2020.pdf"}>
          {" "}
          <FaRegFilePdf className={s.pdfIcon} /> MIETPREIS-KARTE
        </a>

        <h4>Nebenkosten</h4>
        <p>Ermitteln Sie die passenden Nebenkosten mit Hilfe unseres Nebenkosten Charts </p>
        <a target="_blank" rel="noopener" href={"/assets/nebenkostenuebersicht.pdf"}>
          {" "}
          <FaRegFilePdf className={s.pdfIcon} /> NEBENKOSTEN CHART
        </a>
        <h4>Übergabeprotokoll</h4>
        <p>Bitte dokumentieren Sie bei jeder Übergabe den Zählerstand und nutzen Sie am Besten unser Übergabeprotokoll.</p>
        <a target="_blank" rel="noopener" href={"/assets/checkliste.pdf"}>
          {" "}
          <FaRegFilePdf className={s.pdfIcon} /> ÜBERGABEPROTOKOLL
        </a>
      </div>
    );
    qmPrice = parseFloat(qmPrice);
    nkPrice = parseFloat(nkPrice);

    if (nkPrice !== nkPriceState || qmPrice !== qmPriceState) {
      this.calculateBasePrice();
    }
    if (cleaningPrice2 !== cleaningPriceState) {
      this.onChangeCleaningPrice();
    }

    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <div>
              <h3 className={s.landingContentTitle}>
                <FormattedMessage {...messages.pricing} />
              </h3>
              {/* <p className={cx(s.landingStep3, s.space4)}><span><FormattedMessage {...messages.pricingDescription} /></span></p> */}
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <FormGroup className={cx(s.formGroup, s.maxWidthInput)}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.qmPrice} />
                    </ControlLabel>
                    <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverQmPrice}>
                      <FaInfoCircle className={s.infoIconPricing} />
                    </OverlayTrigger>
                    <Field
                      className={cx(s.formControlInput, s.jumboSelect)}
                      name="qmPrice"
                      onChange={this.onChangeQmPrice}
                      // label={formatMessage(messages.basePriceLabel)}
                      type="text"
                      {...currencyMask}
                      component="input"
                    />
                  </FormGroup>
                  <FormGroup className={cx(s.formGroup, s.maxWidthInput)}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.nkPrice} />
                    </ControlLabel>
                    <OverlayTrigger trigger={["hover", "focus"]} placement="right" overlay={popoverNkPrice}>
                      <FaInfoCircle className={s.infoIconPricing} />
                    </OverlayTrigger>
                    <Field
                      className={cx(s.formControlInput, s.jumboSelect)}
                      name="nkPrice"
                      onChange={this.onChangeNkPrice}
                      type="text"
                      {...currencyMask}
                      component="input"
                    />
                  </FormGroup>
                  {calculatedBasePrice > 0 && (
                    <div className={s.pricePreview}>
                      {/* <div>
                        Fläche: <span>{propertyArea}m²</span>
                      </div> */}
                      <div>
                        Mietpreis: <span>{this.roundCurrency(qmPrice)}€/m²</span>
                      </div>
                      <div>
                        Nebenkosten: <span>{this.roundCurrency(nkPrice)}€/m²</span>
                      </div>
                      <div className={cx(s.priceSummary, s.lh28)}>
                        <h4>Ihr Mietertrag beträgt:</h4>
                        <span>
                          <strong>{this.roundCurrency(qmPrice + nkPrice)}€/m² monatlich</strong>
                          <br />
                          {this.roundCurrency((qmPrice / 30 + nkPrice / 30) * propertyArea)}€ / Tag
                          <br />
                          {this.roundCurrency((qmPrice / 30 + nkPrice / 30) * 7 * propertyArea)}€ / Woche
                          <br />
                          <strong>{this.roundCurrency((qmPrice + nkPrice) * propertyArea)}€ / Monat</strong>
                        </span>

                        {/* <span>{this.roundCurrency((qmPrice + nkPrice) * propertyArea)}€/Monat</span> */}
                      </div>
                      <div className={s.priceSummary}>
                        ServiceGebühr 10% für den Mieter: <span>{this.roundCurrency((qmPrice + nkPrice) / 10)}€/m²</span>
                      </div>
                      <div className={s.priceSummary}>
                        <strong>
                          Der monatliche Mietpreis für den Mieter beträgt:{" "}
                          <span>{this.roundCurrency(qmPrice + nkPrice + (qmPrice + nkPrice) / 10)}€/m² (zzgl. MwSt.)</span>
                        </strong>
                      </div>
                      {/* <div>
                        Endpreis qm/Tag: <span>{this.roundCurrency(calculatedBasePrice  /propertyArea)}€/m²</span>
                      </div>
                      <div>
                      Endpreis/Tag: <span>{this.roundCurrency(calculatedBasePrice)}€</span>
                      </div> */}

                      {/* <div>
                        <span>{this.roundCurrency((qmPrice / 30 + nkPrice / 30) * 7 * propertyArea)}€/Woche</span>
                      </div>
                      <div>
                        <span>{this.roundCurrency((qmPrice/30 + nkPrice/30)  * propertyArea)}€/Tag</span>
                      </div> */}
                    </div>
                  )}
                  <FormGroup className={cx(s.formGroup, s.space4, s.hidden)}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.basePrice} />
                    </ControlLabel>
                    <Field
                      name="basePrice"
                      type="text"
                      component={this.renderFormControl}
                      label={formatMessage(messages.basePriceLabel)}
                      className={cx(s.formControlInput, s.jumboSelect)}
                    />
                  </FormGroup>

                  <FormGroup className={cx(s.formGroup, s.space4, s.maxWidthInput)}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.cleaningPrice} />
                    </ControlLabel>
                    <Field name="cleaningPrice2" type="text" {...currencyMaskEuro} component="input" className={cx(s.formControlInput, s.jumboSelect)} />
                  </FormGroup>

                  {/* hide tax rate feature */}
                  {/* <FormGroup className={cx(s.formGroup, s.space4)}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.taxRate} />
                    </ControlLabel>
                    <Field
                      name="taxRate"
                      type="text"
                      component={this.renderFormControl}
                      label={formatMessage(messages.taxRate)}
                      className={cx(s.formControlInput, s.jumboSelect)}
                    />
                 </FormGroup> */}
                  {/* Only ask for min maxnights because we skipped this step- fields are hidden */}
                  <FormGroup className={cx(s.formGroup, s.space4, s.hidden)}>
                    <Field
                      name="minNight"
                      type="text"
                      component={this.renderIncrementButton}
                      labelSingluar={minNight.itemName}
                      labelPlural={minNight.otherItemName}
                      maxValue={minNight.endValue}
                      minValue={minNight.startValue}
                      incrementBy={1}
                    />
                    {isDisabled && (
                      <div className={s.errorMessage}>
                        {" "}
                        <FormattedMessage {...messages.tripLengthError1} />{" "}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className={(s.formGroup, s.hidden)}>
                    <Field
                      name="maxNight"
                      type="text"
                      component={this.renderIncrementButton}
                      labelSingluar={maxNight.itemName}
                      labelPlural={maxNight.otherItemName}
                      maxValue={maxNight.endValue}
                      minValue={maxNight.startValue}
                      incrementBy={1}
                    />
                  </FormGroup>
                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.currency} />
                    </ControlLabel>

                    <Field name="currency" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                      <option value="EUR">EUR</option>
                      {/* {
                        availableCurrencies.map((currency, key) => {
                          if (currency.isEnable === true) {
                            return <option key={key} value={currency.symbol}>{currency.symbol}</option>
                          }
                        })
                      } */}
                    </Field>
                  </FormGroup>
                </div>
                <div className={s.nextPosition}>
                  <div className={s.nextBackButton}>
                    <hr className={s.horizontalLineThrough} />

                    <FormGroup className={s.formGroup}>
                      <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                        <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Vorlaufszeit")}>
                          <FormattedMessage {...messages.back} />
                        </Button>
                        <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} disabled={isDisabled} onClick={() => nextPage("Buchungsdauer")}>
                          <FormattedMessage {...messages.next} />
                        </Button>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <ListPlaceTips content={infoboxContent} />
        </Row>
      </Grid>
    );
  }
}

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep3"); //
const selectorStep1 = formValueSelector("ListPlaceStep1"); //
Pricing = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3,
  // onSubmit: updateStep4
})(Pricing);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  basePrice: selector(state, "basePrice"),
  qmPrice: selector(state, "qmPrice"),
  nkPrice: selector(state, "nkPrice"),
  cleaningPrice: selector(state, "cleaningPrice"),
  cleaningPrice2: selector(state, "cleaningPrice2"),
  minNightData: selector(state, "minNight"),
  maxNightData: selector(state, "maxNight"),
  propertyArea: selectorStep1(state, "personCapacity"),
  mapUpdateLoading: state.location.mapUpdateLoading,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Pricing)));

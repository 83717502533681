exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}.Pc0mh{background-color:#404040;width:64px;display:block;height:1.5px;margin:0 auto;margin-bottom:27px}._3jJq_{height:2.5px;border:1px solid #404040;background-color:transparent}._2ZMGX{padding-left:8%;padding-right:8%}._2PK-d{background-image:url(" + require("./fullimage2.jpg") + ");width:100%;height:440px;background-position:50%;background-size:cover;margin-bottom:68px}._2cLIt{padding-top:77px;padding-left:119px}._2cLIt h2{font-size:60px;color:#fff}._2x2tr{background-color:#fff;color:#333;font-size:17px;font-weight:700;padding:10px 27px;margin-top:37px}._2x2tr:hover{color:#333!important}@media screen and (max-width:1200px){._2ZMGX{padding-left:1%;padding-right:1%}}@media screen and (max-width:991px){._2TwuN{margin-bottom:64px}h4._3XyWx{font-size:23px;font-weight:600}.-KLqi h1{font-size:36px;line-height:51px;margin-bottom:13px}}@media screen and (max-width:767px){._2TwuN{margin-bottom:15px}.-KLqi h1{font-size:28px;line-height:51px;margin-bottom:5px}h4._3XyWx{font-size:26px}._2ZMGX{padding-left:15px;padding-right:15px}._2cLIt{padding-top:77px;padding-left:40px}._2cLIt h2{font-size:32px}._2PK-d{height:337px;margin-bottom:0}.yecCw{padding-top:0}}", ""]);

// exports
exports.locals = {
	"seperator": "Pc0mh",
	"boxseperator": "_3jJq_",
	"overlayImageSection": "_2ZMGX",
	"overlayBg": "_2PK-d",
	"overlayContent": "_2cLIt",
	"brnStarted": "_2x2tr",
	"centerimgsection": "_2TwuN",
	"common": "_3XyWx",
	"mainhedding": "-KLqi",
	"overmobile": "yecCw"
};
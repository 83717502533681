exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3jOaB{margin:5px auto;padding:0 0 40px;margin-top:0 auto;max-width:1080px;max-width:var(--max-content-width)}._31idF{padding-left:0;padding-right:0;min-height:100vh;height:100%;width:100%;overflow:hidden;display:block}", ""]);

// exports
exports.locals = {
	"container": "_3jOaB",
	"root": "_31idF"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1S4Qp{border-radius:0;width:100%;display:block;color:#404040;font-size:16px;padding-bottom:0;border-bottom:1px solid #dce0e0;background:#f5f5f5}._3liXR{padding-left:0;margin:0;list-style-type:none}._3liXR .AEyTN:first-child{padding-top:0}._3liXR .AEyTN:last-child{border-bottom:0;padding-bottom:0}", ""]);

// exports
exports.locals = {
	"panelHeader": "_1S4Qp",
	"listContainer": "_3liXR",
	"panelBody": "AEyTN"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._36GIz{min-height:700px;position:relative}.Fd_ht{max-width:1080px;margin-left:auto;margin-right:auto;padding-left:12px;padding-right:12px}.TLBY4{border-radius:0}._1dojs{width:400px}.TjeR7{font-weight:700;color:#404040}.yUAKm{display:table;table-layout:fixed;width:100%}._2P2xz{padding:0}._1wAwy{margin-bottom:6px}._1BtC2{margin-bottom:12px}.L7Zlp{margin-bottom:18px}._3iYI1{margin-bottom:24px}._2ETZG{margin-bottom:48px}._3iEMc{margin-top:6px}._9R0vq{margin-top:12px}._3Igci{margin-top:18px}.TpaYi{margin-top:24px}._387gG{margin-top:48px}._3LDRS{float:right}._3cTAA{border-color:#c4c4c4;background:#fff;color:#404040;margin-bottom:0;border-radius:4px;border:1px solid;text-align:center;vertical-align:middle;font-weight:700;line-height:1.43;white-space:normal;cursor:pointer;width:100%;padding:5px 21px}._2Onot{font-size:16px;color:#707070;font-weight:400}._36uAm{font-size:18px;color:#707070}._3cTAA:focus,._3cTAA:hover{border-color:#aaa;color:#404040;text-decoration:none}.kh4cC{border-color:#0667b3;color:#0667b3;background-color:#fff}.kh4cC:active,.kh4cC:active:focus,.kh4cC:active:hover,.kh4cC:focus,.kh4cC:hover{border-color:#2081cd;color:#2081cd;background-color:#fff}._2oO-4{position:relative;height:1.25em;width:1.25em;margin-right:10px!important;vertical-align:text-bottom}", ""]);

// exports
exports.locals = {
	"Container": "_36GIz",
	"containerResponsive": "Fd_ht",
	"receiptPanel": "TLBY4",
	"rowWidth": "_1dojs",
	"textBold": "TjeR7",
	"rowTable": "yUAKm",
	"noPadding": "_2P2xz",
	"space1": "_1wAwy",
	"space2": "_1BtC2",
	"space3": "L7Zlp",
	"space4": "_3iYI1",
	"space5": "_2ETZG",
	"spaceTop1": "_3iEMc",
	"spaceTop2": "_9R0vq",
	"spaceTop3": "_3Igci",
	"spaceTop4": "TpaYi",
	"spaceTop5": "_387gG",
	"pullRight": "_3LDRS",
	"button": "_3cTAA",
	"landingLabel": "_2Onot",
	"titleText": "_36uAm",
	"btnPrimaryBorder": "kh4cC",
	"blockRadioButton": "_2oO-4"
};
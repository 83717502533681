import React from 'react';
import Layout from '../../components/Layout';
import Book from './Book';
import NotFound from '../notFound/NotFound';
import { getPayins } from '../../actions/Payin/getPayins';

const title = 'Langfristige Buchung';

export default {

  path: '/book-longterm/:hostingId/:selectedLongTerm',

  async action({ store, params, query }) {

    // From Redux Store
    let isAuthenticated = store.getState().runtime.isAuthenticated;
    let hostingId = params.hostingId;
    let selectedLongTerm = params.selectedLongTerm;
    let bookingData = store.getState().book.data;

 

    // Check listId is provided
    if (!hostingId) {
      return {
        title,
        component: <Layout><NotFound title={title} /></Layout>,
        status: 404
      };
    }

  
    return {
      title,
      component: <Layout><Book selectedLongTerm={selectedLongTerm} title={title} /></Layout>,
    };
  },

};
import messages from './messages';

const validate = values => {

  const errors = {}

  if (!values.firstName) {
    errors.firstName = messages.required;
  }
  if (!values.companyName) {
    errors.companyName = messages.required;
  }
  if (!values.region) {
    errors.region = messages.required;
  }

  if (!values.lastName) {
    errors.lastName = messages.required;
  }
  if (!values.address1) {
    errors.address1 = messages.required;
  }
  if (!values.address2) {
    errors.address2 = messages.required;
  }
  if (!values.city) {
    errors.city = messages.required;
  }
  if (!values.zip) {
    errors.zip = messages.required;
  }
  if (!values.taxId) {
    errors.taxId = messages.required;
  }
  if(values.taxId && !values.taxId.replace(/\s/g,'').match(/^(HRB|HRA)\d+$/g)) {
    errors.taxId = messages.taxIdInvalid;
  }
  if (!values.gender) {
    errors.gender = messages.required;
  }

  if (!values.companyRegistrationNumber) {
    errors.companyRegistrationNumber = messages.required;
  }
  if(values.companyRegistrationNumber && !values.companyRegistrationNumber.replace(/\s/g,'').match(/^(HRB|HRA)\d+$/g)) {
    errors.companyRegistrationNumber = messages.companyRegistrationNumberInvalid;
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = messages.required;
  }

  if (!values.preferredLanguage) {
    errors.preferredLanguage = messages.required;
  }

  if (!values.preferredCurrency) {
    errors.preferredCurrency = messages.required;
  }

  if (!values.location) {
    errors.location = messages.required;
  }

  if (!values.info) {
    errors.info = messages.required;
  }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = messages.required;
  }

  return errors
}

export default validate

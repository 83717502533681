import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Link from "../../Link";
import ListCoverPhoto from "../../ListCoverPhoto";
import s from "./WishListGroupItem.css";
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

class WishListGroupItem extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    data: PropTypes.object,
  };

  render() {
    const {
      data,
      data: { id, name, userId, isPublic, updatedAt, createdAt, wishListCount, wishListCover },
    } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <Link className={s.linkContainer} to={"/wishlists/" + id}>
          <div className={cx(s.imgContainer)}>
            <div className={cx(s.parent)}>
              <div className={cx(s.children)}>
                <div className={cx(s.content)}>
                  {wishListCover && wishListCover.listData && wishListCover.listData.listPhotos && (
                    <div>
                      <ListCoverPhoto
                        className={cx(s.imageContent)}
                        coverPhoto={wishListCover.listData.coverPhoto != null ? wishListCover.listData.coverPhoto : wishListCover.listData.listPhotos[0].id}
                        listPhotos={wishListCover.listData.listPhotos}
                        photoType={"x_medium"}
                        bgImage
                      />
                    </div>
                  )}

                  <div className={s.infoContainer}>
                    <div className={cx(s.infoTitle, s.textEllipsis)}>{name}</div>
                    {wishListCount > 0 && (
                      <div className={s.infoText}>
                        {wishListCount} {wishListCount > 1 ? "Flächen" : "Fläche"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(WishListGroupItem));

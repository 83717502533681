exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1edxi{color:#28a745;padding-top:5px;font-size:14px;clear:both}._3xrlc{padding-right:5px;padding-bottom:3px;font-size:20px}", ""]);

// exports
exports.locals = {
	"trustIconWrapper": "_1edxi",
	"iconStyle": "_3xrlc"
};
import React from 'react';
import PropTypes from 'prop-types';

// External Component
import moment from 'moment';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';


// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Button,
  Row,
  Col,
  Label,
  ProgressBar,
} from 'react-bootstrap';

import s from './ListItem.css';
import  { IoMdClose } from 'react-icons/io';

// For Redirect
import history from '../../../core/history';
// Component
import ListCoverPhoto from '../../ListCoverPhoto';
import PublishOption from './PublishOption';
import ExportCalendar from '../../ListPlaceStep1/SyncCalendar/ExportCalendar'
import { ManagePublishStatus } from "../../../actions/Listing/ManagePublishStatus";


// Redux
import { connect } from 'react-redux';

// Redux action
import { removeListing } from '../../../actions/removeListing';

// Locale
import messages from '../../../locale/messages';
class ListItem extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = {
      isRemove: false,
      showExport: false,
    };
    this.openRemoveList = this.openRemoveList.bind(this);
    this.closeRemoveList = this.closeRemoveList.bind(this);
    this.openExportModal = this.openExportModal.bind(this);
    this.closeExportModal = this.closeExportModal.bind(this);
  }

  openExportModal() {
    this.setState({ showExport: true });
  }

  closeExportModal() {
      this.setState({ showExport: false });
  }

  percentage(data) {
    let totalPercentage = 100;
    let percentage = 0;
    let step1Percentage = 0, step2Percentage = 0, step2PhotosPercentage = 0, step3Percentage = 0;
    let step1 = data.listingSteps.step1;
    let step2 = data.listingSteps.step2;
    let step3 = data.listingSteps.step3;
    let step4 = data.listingSteps.step4;
    let listPhotos = data.listPhotos;
    if (data) {
      if (step1 === "active") {
        step1Percentage = 0.20;
      }
      if (step1 === "completed") {
        step1Percentage = 0.30;
      }
      if (step2 === "completed") {
        step2Percentage = 0.20;
      }
      if (listPhotos.length > 0) {
        step2PhotosPercentage = 0.10;
      }
      // if (step3 === "completed") {
      //   step3Percentage = 0.40;
      // }
      if (step4 === "completed") {
        step3Percentage = 0.40;
      }
    }
    percentage = step1Percentage + step2Percentage + step2PhotosPercentage + step3Percentage;
    return Number(totalPercentage * percentage);
  }
  title(data) {
    if (data) {
      if (data.title != null) {
        return data.title
      } else if(data.settingsData[0] && data.settingsData[0].listsettings) {
        return data.settingsData[0].listsettings.itemName + " in " + data.city;
      } else {
        return "Fläche"+ " in " + data.city;
      }
    }
  }
  handlePreview(listId) {
    if (listId) {
      history.push('/spaces/' + listId + '/preview');
    }
  }
  handleEditListing(listId) {
    if (listId) {
      history.push('/become-a-host/' + listId + '/home');
    }
  }
  handleEditCalendar(listId) {
    if (listId) {
      history.push("/become-a-host/" + listId + "/editCalendar");
    }
  }
  handleRemoveListing(listId) {
    const { removeListing } = this.props;
    removeListing(listId);
    this.setState({ isRemove: false });
  }

  handlePauseListing(listId) {
    const { ManagePublishStatus } = this.props;
    // const { getAllApprovedListings: { refetch } } = this.props;

    ManagePublishStatus( listId, "isPaused");
    // await refetch(variables);
  }
  openRemoveList() {
    this.setState({ isRemove: true });
  }
  closeRemoveList() {
    this.setState({ isRemove: false });
  }
  removeItem(listId) {
    return (
      <li className={s.panelBodyAlert}>
        <div className={cx(s.alertBlock)}>

          <Row>
            <Col xs={12} xsOffset={0} smOffset={1} sm={10} mdOffset={1} md={10} lgOffset={1} lg={10}>
              <p className={cx(s.h5, s.spaceTop5)}>
                <span><FormattedMessage {...messages.deleteListing} /></span>
              </p>
              <p className={cx(s.spaceTop2, s.alertText)}>
                <span><FormattedMessage {...messages.deleteListingInfo} /></span>
              </p>
              <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                <Button
                  className={cx(s.button, s.btnPrimary, s.btnlarge, s.spaceTop4, s.spaceRight2, s.smButton)}
                  onClick={() => this.handleRemoveListing(listId)}>
                  <FormattedMessage {...messages.delete} />
                </Button>
                <Button
                  className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.spaceTop4, s.smButton)}
                  onClick={this.closeRemoveList}>
                  <FormattedMessage {...messages.goBack} />
                </Button>
                
              </Col>
            </Col>
          </Row>
        </div>
      </li>
    )
  }
  render() {
    const { isRemove, showExport } = this.state;
    const { data } = this.props;
    const { formatMessage, locale } = this.props.intl;
    let step3 = data.listingSteps.step3;
    //set locale
    moment.locale(locale)
    let updatedDate = moment(data.updatedAt).format('Do MMMM YYYY');
    let listId = data.id;
    let coverPhoto = data.coverPhoto;
    let listPhotos = data.listPhotos;
    let progressClass = this.percentage(data) == 100 ? cx(s.leanProgressBar,s.success) : cx(s.leanProgressBar)
    if (isRemove) {
      return this.removeItem(listId);
    } else {
      return (
        <li className={s.panelBody} >
          <Row>


            <Col xs={12} sm={12} className={cx('hidden-md hidden-lg', s.space2)}>
              <a href="javascript:void(0);" onClick={this.openRemoveList}>
                <IoMdClose className={cx(s.iconRemove, s.icon, 'pull-right')} />
              </a>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
          
              <div className={s.listPhotoCover}>
                <div className={s.listPhotoMedia}>
                  <a target="_blank" href={"/spaces/" + listId + "/preview"}>
                    <ListCoverPhoto
                      className={s.imgResponsive}
                      coverPhoto={coverPhoto}
                      listPhotos={listPhotos}
                      photoType={"x_medium"}
                    />
                  </a>
                </div>
              </div>
              {(data && data.isPublished && !data.isApproved)&& (<div className={s.badge}><Label bsStyle={"info"}><FormattedMessage {...messages.inReview} /></Label></div>)}
		{(data && data.isPublished && data.isApproved)&& (<div className={s.badge}><Label bsStyle={"success"}>LIVE</Label></div>)}
		{(data && data.isPublished && data.isPaused && data.isApproved)&& (<div className={s.badge}><Label bsStyle={"warning"}>PAUSIERT</Label></div>)}
            </Col>
            <Col xs={12} sm={12} md={7} lg={7} className={s.listDetailContent}>
              <a href="javascript:void(0);" onClick={this.openRemoveList}>
                <IoMdClose className={cx(s.iconRemove, s.icon, "hidden-sm hidden-xs", 'pull-right')} />
              </a>
              <Col xs={12} sm={12} md={10} lg={10} className={cx(s.noPadding, s.spaceTop2)}>
                <ProgressBar
                  bsClass={progressClass}
                  className={s.leanProgressContainer}
                  now={this.percentage(data)}
                />
                <span className={s.labelText}><FormattedMessage {...messages.progressBarText1} /> {this.percentage(data)}% <FormattedMessage {...messages.progressBarText2} /></span>
              </Col>
              <Col xs={12} sm={12} md={10} lg={10} className={cx(s.noPadding, s.spaceTop2, s.textOverFlow)}>
                <a target="_blank" href={"/spaces/" + listId + "/preview"}>
                  <span className={s.listContent}> {this.title(data)} </span>
                </a>
              </Col>
              <Col xs={12} sm={12} md={10} lg={10} className={cx(s.noPadding, s.spaceTop2)}>
                <span className={s.landingStep}><FormattedMessage {...messages.listingUpdateInfo} /> {updatedDate}</span>
              </Col>
              {data && !data.isApproved && (
                <Button className={cx(s.button, s.btnPrimary, s.spaceRight2, s.spaceTop, s.smButton, s.listButton)} onClick={() => this.handleEditListing(listId)}>
                  {
                    data.listingSteps.step4 === "completed" && listPhotos.length > 0 && <span><FormattedMessage {...messages.editListing} /></span>
                  }
                  {
                    data.listingSteps.step4 === "completed" && listPhotos.length <= 0 && <span><FormattedMessage {...messages.finishListing} /></span>
                  }
                  {
                    data.listingSteps.step4 !== "completed" && <span><FormattedMessage {...messages.finishListing} /></span>
                  }
                </Button>
              )}
              
                  
              {
                data && data.isReady && data.isApproved && data.user.userBanStatus != 1 && <PublishOption
                  listId={listId}
                  isPublished={data.isPublished}
                  isApproved={data.isApproved}
                />
              }

              {
                data && data.listingSteps && data.isReady && <a
                  href={"/spaces/" + listId + "/preview"}
                  target="_blank"
                  className={cx('btn btn-default', s.button, s.btnPrimaryBorder, s.spaceTop, s.smButton, s.spaceRight2, s.listButton)}
                >
                  <FormattedMessage {...messages.preview} />
                </a>
              }
              {
                data && data.listingSteps && data.isReady && <Button
                onClick={() => this.handleEditCalendar(listId)}
                 
                  className={cx(s.button, s.btnPrimary, s.spaceRight2, s.spaceTop, s.smButton, s.listButton)}
                >
                  Verfügbarkeit bearbeiten
                </Button>
              }

               

          
            
            {data && data.isApproved  && (
               <span>
               <Button className={cx('btn btn-default', s.button, s.btnPrimaryBorder, s.spaceTop, s.smButton, s.spaceRight2, s.listButton)} onClick={() => this.openExportModal()}>
               <FormattedMessage {...messages.calendarButton} />

               </Button>

               <Button className={cx('btn btn-default', s.button, s.btnPrimaryBorder, s.spaceTop, s.smButton, s.spaceRight2, s.listButton)} onClick={() => this.handlePauseListing(listId)}>
               {data.isPaused  ? "Angebot fortsetzen" : "Angebot pausieren"}

               </Button>
               </span>
             ) }

            </Col>
          </Row>
          <ExportCalendar
                        listId={listId}
                        showModal={showExport}
                        close={this.closeExportModal}
                    />
        </li>
      );
    }
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  removeListing,
  ManagePublishStatus
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ListItem)));
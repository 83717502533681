exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._6qiu6{margin-left:250px;margin-top:50px;position:fixed;bottom:0;padding:15px;background:#e7e7e7;width:calc(100% - 250px);text-align:center}", ""]);

// exports
exports.locals = {
	"footerContainer": "_6qiu6"
};
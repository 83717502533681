import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConfirmEmail from './ConfirmEmail';
import BookingRequestHost from './BookingRequestHost';
import BookingConfirmationHost from './BookingConfirmationHost';
import BookingConfirmationGuest from './BookingConfirmationGuest';
import BookingDeclinedGuest from './BookingDeclinedGuest';
import BookingRequestGuest from './BookingRequestGuest';
import BookingExpiredGuest from './BookingExpiredGuest';
import BookingExpiredHost from './BookingExpiredHost';
import CancelledByHost from './CancelledByHost';
import CancelledByHostConfirm from './CancelledByHostConfirm';
import CancelledByGuest from './CancelledByGuest';
import CancelledByGuestConfirm from './CancelledByGuestConfirm';
import CompletedReservationGuest from './CompletedReservationGuest';
import CompletedReservationHost from './CompletedReservationHost';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import NewMessage from './NewMessage';
import NewInquiry from './NewInquiry';
import ConfirmDocumentVerification from './ConfirmDocumentVerification';
import ContactEmail from './ContactEmail';
import ReportUserMail from './ReportUserMail';
import BookingPreApproval from './BookingPreApproval';
import BanStatusServiceStatusBanned from './BanStatusServiceStatusBanned';
import BanStatusServiceStatusUnBanned from './BanStatusServiceStatusUnBanned';
import ContactSupport from './ContactSupport';
import FeedbackMail from './FeedbackMail';
import ListingApprovedHost from './ListingApprovedHost';
import ListingApproveRequest from './ListingApproveRequest';
import KycSuccess from './KycSuccess';
import KycFail from './KycFail';

import UboSuccess from './UboSuccess';
import UboFail from './UboFail';
import RefundSuccess from './RefundSuccess';
import PayoutSuccess from './PayoutSuccess';
import PayoutFail from './PayoutFail';

import ManualRequest from './ManualRequest';
import ContactAgent from './ContactAgent';

import BankTransferRequestGuest from './BankTransferRequestGuest';
import BankTransferRequestHost from './BankTransferRequestHost';
import CancelAdminNotification from './CancelAdminNotification';

import BookingMonthlyReceiptHost from './BookingMonthlyReceiptHost';
import BookingMonthlyReceiptGuest from './BookingMonthlyReceiptGuest';
import BookingMonthlyFail from './BookingMonthlyFail';



class EmailTemplate extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        content: PropTypes.object.isRequired
    };

    render() {
        const { type, content } = this.props;

        return (
            <div>
                {
                    type === 'welcomeEmail' && <ConfirmEmail content={content} />
                }
                {
                    type === 'confirmEmail' && <ConfirmEmail content={content} />
                }
                {
                    type === 'bookingRequest' && <BookingRequestHost content={content} />
                }
                {
                    type === 'bookingRequestGuest' && <BookingRequestGuest content={content} />
                }
                {
                    type === 'bookingConfirmedToHost' && <BookingConfirmationHost content={content} />
                }
                {
                    type === 'bookingConfirmedToGuest' && <BookingConfirmationGuest content={content} />
                }
                {
                    type === 'bookingDeclinedToGuest' && <BookingDeclinedGuest content={content} />
                }
                {
                    type === 'bookingExpiredGuest' && <BookingExpiredGuest content={content} />
                }
                {
                    type === 'bookingExpiredHost' && <BookingExpiredHost content={content} />
                }
                {
                    type === 'cancelledByHost' && <CancelledByHost content={content} />
                }
                {
                    type === 'cancelledByHostConfirm' && <CancelledByHostConfirm content={content} />
                }
                {
                    type === 'cancelledByGuest' && <CancelledByGuest content={content} />
                }
                {
                    type === 'cancelledByGuestConfirm' && <CancelledByGuestConfirm content={content} />
                }
                {
                    type === 'completedGuest' && <CompletedReservationGuest content={content} />
                }
                {
                    type === 'completedHost' && <CompletedReservationHost content={content} />
                }
                {
                    type === 'forgotPasswordLink' && <ForgotPasswordEmail content={content} />
                }
                {
                    type === 'message' && <NewMessage content={content} />
                }
                {
                    type === 'inquiry' && <NewInquiry content={content} />
                }
                {
                    type === 'documentVerification' && <ConfirmDocumentVerification content={content} />
                }
                {
                    type === 'contact' && <ContactEmail content={content} />
                }
                {
                    type === 'reportUser' && <ReportUserMail content={content} />
                }
                {
                    type === 'bookingPreApproval' && <BookingPreApproval content={content} />
                }
                {
                    type === 'banStatusServiceStatusBanned' && <BanStatusServiceStatusBanned content={content} />
                }
                {
                    type === 'banStatusServiceStatusUnBanned' && <BanStatusServiceStatusUnBanned content={content} />
                }
                {
                    type === 'contactSupport' && <ContactSupport content={content} />
                }
                {
                    type === 'userFeedback' && <FeedbackMail content={content} />
                }
                {
                    type === 'listingApprovedHost' && <ListingApprovedHost content={content} />
                }
                {
                    type === 'listingApproveRequest' && <ListingApproveRequest content={content} />
                }
                {
                    type === 'kycSuccess' && <KycSuccess content={content} />
                }
                {
                    type === 'kycFail' && <KycFail content={content} />
                }
                {
                    type === 'uboSuccess' && <UboSuccess content={content} />
                }
                {
                    type === 'uboFail' && <UboFail content={content} />
                }
                {
                    type === 'payoutSuccess' && <PayoutSuccess content={content} />
                }
                {
                    type === 'payoutFail' && <PayoutFail content={content} />
                }
                {
                    type === 'refundSuccess' && <RefundSuccess content={content} />
                }
                {
                    type === 'manualRequest' && <ManualRequest content={content} />
                }
                {
                    type === 'contactAgent' && <ContactAgent content={content} />
                }
                {
                    type === 'bankTransferRequestGuest' && <BankTransferRequestGuest content={content} />
                }
                {
                    type === 'bankTransferRequestHost' && <BankTransferRequestHost content={content} />
                }
                {
                    type === 'CancelAdminNotification' && <CancelAdminNotification content={content} />
                }
                {
                    type === 'BookingMonthlyReceiptHost' && <BookingMonthlyReceiptHost content={content} />
                }
                {
                    type === 'BookingMonthlyReceiptGuest' && <BookingMonthlyReceiptGuest content={content} />
                }
                {
                    type === 'BookingMonthlyFail' && <BookingMonthlyFail content={content} />
                }
            </div>
        );
    }
}

export default EmailTemplate;

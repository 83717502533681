import React from 'react';
import LayoutJustLogo from '../../components/Layout/LayoutJustLogo';
import FunnelMieter from './FunnelMieter';

const title = 'Mieter werden';

export default {

  path: '/funnel-mieter',

  action() {
    return {
      title,
      component: <LayoutJustLogo><FunnelMieter title={title} /></LayoutJustLogo>,
      status: 404,
    };
  },

};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// External component
import {Range} from 'rc-slider';


// Redux form
import {change} from 'redux-form';

// Helper
import {convert} from '../../../helpers/currencyConvertion';

// Styles
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!rc-slider/assets/index.css';

class PersonCapacityRange extends Component {
    static propTypes = {
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        maxPersonCapacity: PropTypes.number.isRequired,
        maxPersonCapacity: PropTypes.number.isRequired,
        change: PropTypes.any.isRequired,
        handleSubmit: PropTypes.any
    };

    static defaultProps = {
        min: 0,
        max: 0
    };
    
    constructor (props) {
        super(props);
        this.state = {
            minPersonCapacity: 0,
            maxPersonCapacity: 0
        };
        this.updateValue = this.updateValue.bind(this);
        this.onAfterChange = this.onAfterChange.bind(this);
    }

    componentDidMount() {
        const { min, max, minPersonCapacity, maxPersonCapacity } = this.props;
        if (minPersonCapacity && maxPersonCapacity) {
            this.setState({
                minPersonCapacity,
                maxPersonCapacity
            });
        } else if(min && max) {
            this.setState({
                minPersonCapacity: min,
                maxPersonCapacity: max
            });
        }

    }

    async updateValue(sliderState) {
        const { change, min, max } = this.props;
        
        if(sliderState && sliderState.length) {
            this.setState({
                minPersonCapacity: sliderState[0],
                maxPersonCapacity: sliderState[1]
            });
            if (sliderState[0] == min && sliderState[1] == max) {
                await change('SearchForm', 'personCapacityRangeLabel', [min, max]);
            } else {
                await change('SearchForm', 'personCapacityRangeLabel', sliderState);
            }
        }
    }

    async onAfterChange(sliderState){
        const { change, from, base, rates, handleSubmit, min, max } = this.props;
        let minPersonCapacity, maxPersonCapacity;
        let values = sliderState;
        
        if(sliderState) {
            this.setState({
                minPersonCapacity: sliderState[0],
                maxPersonCapacity: sliderState[1]
            });
        }

        if (sliderState[0] == min && sliderState[1] == max) {
            await change('SearchForm', 'personCapacityRange', null);
        } else {
            await change('SearchForm', 'personCapacityRange', values);
        }
        await change('SearchForm', 'personCapacityRangeLabel', sliderState);
        await change('SearchForm', 'currentPage', 1);
       // await handleSubmit();
    }
    
    render() {
        const { min, max } = this.props;
        const { minPersonCapacity, maxPersonCapacity } = this.state;
        return (
            <div>
                <Range
                    min={min}
                    max={max}
                    defaultValue={[min, max]}
                    value={[minPersonCapacity, maxPersonCapacity]}
                    onChange={this.updateValue}
                    onAfterChange={this.onAfterChange}
                    allowCross={true}
                    step={1}
                />
            </div>
        );
    }
}

const mapState = (state) => ({
});

const mapDispatch = {
    change
};

export default withStyles(s) (connect(mapState, mapDispatch)(PersonCapacityRange));
import { gql } from 'react-apollo';
import { reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import history from '../../core/history';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../constants';

// Mangopay
import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";

import { getPayouts } from '../../actions/Payout/getPayouts';

export function addPayout(
  methodId,
  currency,
  iban,
  bic,
  last4Digits,
  owner
) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: ADD_PAYOUT_START,
      payload: {
        payoutLoading: true
      }
    });
    //create Mangopay bank account
    let bankData = {
      iban,
      bic
    }
 
    try {

  


      const {
        status,
        errorMessage,
        bankAccountId,
      } = await processMangopayPayment("create-bank-account", owner, null, null, bankData);

      if(status == 400) {
        toastr.error('Failed!', errorMessage);
        dispatch({
          type: ADD_PAYOUT_ERROR,
          payload: {
            errorMessage,
            payoutLoading: false
          }
        });
        return;
      }
    
      let mutation = gql`
          mutation addPayout(
            $methodId: Int!, 
            $last4Digits: Int,
            $currency: String!,
            $mangoPayBankAccountId: String!,
            $isVerified: Boolean
          ){ 
              addPayout(
                methodId: $methodId,
                last4Digits: $last4Digits,
                currency: $currency,
                mangoPayBankAccountId: $mangoPayBankAccountId,
                isVerified: $isVerified
              ) {
                  id
                  methodId
                  userId
                  createdAt
                  status
              }
          }
      `;

      if (methodId == 1) { // PayPal
        const { data } = await client.mutate({
          mutation,
          variables: {
            methodId,
            currency,
            isVerified: true
          }
        });

        await dispatch(getPayouts());

        if (data && data.addPayout) {
          dispatch({
            type: ADD_PAYOUT_SUCCESS,
            payload: {
              status: data.addPayout.status,
              payoutLoading: false
            }
          });
          dispatch(reset('PayoutForm'));
          history.push('/user/payout');
        }
      } else { 
        //Bank account

        
        const { data } = await client.mutate({
          mutation,
          variables: {
            methodId,
            last4Digits,
            currency,
            mangoPayBankAccountId: bankAccountId,
            isVerified: true
          }
        });

        await dispatch(getPayouts());
        if (status === 200 && data) {
          dispatch({
            type: ADD_PAYOUT_SUCCESS,
            payload: {
              status,
              payoutLoading: false
            }
          });

          dispatch(reset('PayoutForm'));
          history.push('/user/payout');
        } else {
          toastr.error('Failed!', errorMessage);
          dispatch({
            type: ADD_PAYOUT_ERROR,
            payload: {
              errorMessage,
              payoutLoading: false
            }
          });
        }
      }
    } catch (error) {
      console.log("payout error", error)
      dispatch({
        type: ADD_PAYOUT_ERROR,
        payload: {
          error,
          payoutLoading: false
        }
      });
      return false;
    }

    return true;
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Button,
	Grid,
	Row,
	Col,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Payin.css';
import { graphql, gql, compose } from 'react-apollo';

// Redux
import { connect } from 'react-redux';

// Component
import PayinForm from '../../Payin/PayinForm/PayinForm';
import Loader from '../../Loader/Loader';

// Locale
import messages from '../../../locale/messages';

class Payin extends Component {
	static propTypes = {
		profilePhotoLoading: PropTypes.bool,
	
	};

	static defaultProps = {
	
	};

	constructor(props) {
		super(props);
	}
	handleClick() {
		const { nextPage, emailVerified, payinData } = this.props;
		if (!emailVerified) {
			nextPage('verification');
		} else if(!payinData) {
			nextPage('payin');
		}
		else {
			nextPage('payment');
		}
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { payinData, accountData } = this.props;
		let initialData = accountData;

		initialData.methodId = 2;
		initialData.paymentType = 2;
		initialData.currency = "EUR";
		return (
			<Grid>
				<Row>
					<div className={s.pageContainer}>
						<div className={s.activationStepPanel}>
							<div className={s.panelBody}>
								<h3 className={s.space1}>
									{/* <span>dsfsdfds</span> */}
								</h3>
								<div className={cx(s.textLead, s.space4)}>
									<div>
										{/* <span>
											dsadsadsa
										</span> */}
									</div>
								</div>
							
									<div className={cx(s.alignLeft, s.space4, s.spaceTop4)}>
										<div className={s.space2}>
											 <PayinForm initialValues={initialData}/>
										</div>
									</div>
								
								<Col xs={12} sm={12} md={12} lg={12} className={cx(s.noPadding, s.spaceTop2)}>
									{
										payinData.length > 0 && <Button
											className={cx(s.button, s.btnPrimary)}
											onClick={this.handleClick}
										>
											<FormattedMessage {...messages.continue} />
										</Button>
									}

								</Col>
							</div>
						</div>
					</div>
				</Row>
			</Grid>
		);
	}
}
const mapState = (state) => ({
	profilePhotoLoading: state.account.profilePhotoLoading,
	accountData: state.account.data

});

const mapDispatch = {};

export default compose(
	injectIntl,
	withStyles(s),
	connect(mapState, mapDispatch),
	graphql(gql`
		query {
			userAccount {
				picture
			}
		}
	  `, {
			name: 'profilePictureData',
			options: {
				ssr: false
			}
		}),
)(Payin);
// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep1 from "./validateStep1";
//Fontawesome
import  { FaInfoCircle, FaAmazon } from 'react-icons/fa';

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, InputGroup, Popover, OverlayTrigger, ControlLabel, FormControl } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

import update from "./update";

// Component
import ListPlaceTips from "../ListPlaceTips";

class Page1 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    nextPage: PropTypes.any,
    userData: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    userData: {
      firstName: "",
      companyName: "",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      roomType: [],
      personCapacity: [],
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderFormControlSquareMeter = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    const popoverRoomType = (
      <Popover id="popover-trigger-hover-focus" title="Lagerfläche">
        <p>Wir unterscheiden zwischen versicherbaren und nicht versicherbaren Flächen.</p>
        <p>
          <strong>Versicherbare Flächen:</strong>
          <br />
          Räumlich abgetrennte und zugangsbeschränkte Flächen sind versicherbar und werden je nach Ausstattung in Sicherheitsklassen eingeteilt.
        </p>
        <ul>
          <li>Lager- & Logistikfläche</li>
          <li>Produktionsfläche</li>
        </ul>

        <p>
        <strong>Nichtversicherbare Flächen:</strong>
          <br />
          Flächen die nicht den oben genannten Anforderungen entsprechen sind nicht versicherbar und werden als Außenflächen behandelt.        </p>

        <ul>
          <li>Außenfläche</li>
          <li>Offene Lager- & Logistikfläche </li>
        </ul>
      </Popover>
    );

    const isBrowser = typeof window !== 'undefined';
    const smallDevice = isBrowser ? window.matchMedia('(max-width: 640px)').matches : undefined;
    const overlayPlacement = smallDevice ? 'bottom' : 'right';
    
    return (
      <div>
        <FormGroup>
          <InputGroup>
            <FormControl {...input} placeholder={label} type={type} className={className} maxLength={5} type="number" />
            <InputGroup.Addon>m²</InputGroup.Addon>
            <InputGroup.Addon className={s.infoButton}>
              <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRoomType}>
                <FaInfoCircle />
              </OverlayTrigger>
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>

        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };


 

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, invalid } = this.props;
    const { userData } = this.props;
    const { roomType, personCapacity } = this.state;
    // TODO translate
    const infoBoxContent = (
      <div>
        <p>Jetzt Fläche anbieten und exklusive Vorteile sichern</p>
        <div className={s.infoAdvantage}>
          <FaAmazon /> Rundum abgesichert
        </div>
        <div className={s.infoAdvantage}>
          <FaAmazon /> Höhere Mieterträge
        </div>
        <div className={s.infoAdvantage}>
          <FaAmazon /> Kein Verwaltungsaufwand
        </div>
        <div className={s.infoAdvantage}>
          <FaAmazon /> Digitale & automatisierte Verwaltung
        </div>
        <div className={s.infoAdvantage}>
          <FaAmazon /> Deutschlandweite Sichtbarkeit
        </div>
      </div>
    );

    return (
      <Grid>
        <Row>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <Col xs={12} smOffset={1} sm={8} mdOffset={1} md={8} lgOffset={1} lg={8}>
            <h2 className={s.landingTitle}>
                <FormattedMessage {...messages.hi} />, {userData.firstName}!<br />
                <br /> <FormattedMessage {...messages.letYouGetReady} />
              </h2>

              <strong className={s.landingStep}>
                <span>
                  {" "}
                  <FormattedMessage {...messages.step1HeadingNew} />
                </span>
              </strong>
              <h3 className={s.landingContentTitle}>
                {" "}
                <FormattedMessage {...messages.whatKindOfPlace} />{" "}
              </h3>

              <form onSubmit={handleSubmit}>
                <FormGroup className={s.formGroup}>
                  <Row>
                    <Col componentClass={ControlLabel} xs={12} sm={12} md={12} lg={12}>
                      <Field
                        name="roomType"
                        component={this.renderFormControlSelect}
                        className={cx(s.backgroundPosition, s.formControlSelect, s.jumboSelect, s.noFontWeight)}
                      >
                        {roomType.map((value, key) => {
                          return (
                            value.isEnable == 1 && (
                              <option value={value.id} key={key}>
                                {value.itemName}
                              </option>
                            )
                          );
                        })}
                      </Field>
                    </Col>

                    <Col componentClass={ControlLabel} xs={12} sm={12} md={12} lg={12}>
                      <Field
                        name="personCapacity"
                        component={this.renderFormControlSquareMeter}
                        className={cx(s.backgroundPosition, s.formControlGroup, s.jumboSelect, s.noFontWeight)}
                      ></Field>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className={s.formGroup}>
                  <Button disabled={invalid} className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={() => nextPage("Allgemeine Informationen")}>
                    <FormattedMessage {...messages.continue} />
                  </Button>
                </FormGroup>
              </form>
            </Col>
          </Col>
          {/* <ListPlaceTips content={infoBoxContent} /> */}
        </Row>
      </Grid>
    );
  }
}
const selectorStep1 = formValueSelector("ListPlaceStep1"); //

Page1 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update,
  validate: validateStep1,
})(Page1);

const mapState = (state) => ({
  userData: state.account.data,
  listingFields: state.listingFields.data,
  propertyArea: selectorStep1(state, "personCapacity"),

});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Page1)));

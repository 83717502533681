
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './FloorHeight.css';

import cx from 'classnames';

// Redux Form
import { Field, reduxForm, formValueSelector, change, submit as submitForm } from 'redux-form';

// Redux
import { connect } from 'react-redux';

// Locale
import messages from '../../../../../locale/messages';

// Submit
import submit from '../../../SearchForm/submit';

import FloorHeightRange from './FloorHeightRange';

class FloorHeight extends Component {

  static propTypes = {
    className: PropTypes.any,
    minHeight: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
  };

  static defaultProps = {
    isExpand: false,
    searchSettings: {
    }
  };

  constructor(props) {
    super(props);
  }

  renderFloorHeightRange = ({ input, label, meta: { touched, error }, className, min, max, rangeCurrency, minHeight, maxHeight }) => {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, change } = this.props;
    return (
      <div className={cx(s.floorHeightRangeContainer, s.space4)}>
        <FloorHeightRange
          {...input}
          min={min}
          max={max}
          minHeight={minHeight}
          maxHeight={maxHeight}
        />
      </div>
    )
  }

  render() {
    const { className, handleTabToggle, isExpand, searchSettings } = this.props;
    const { floorHeightRangeLabel } = this.props;
    const { formatMessage } = this.props.intl;

    let minHeight = this.props.minHeight;
    let maxHeight = this.props.maxHeight;

    let minFloorHeightRange = floorHeightRangeLabel != undefined ? floorHeightRangeLabel[0] : minHeight;
    let maxFloorHeightRange = floorHeightRangeLabel != undefined ? floorHeightRangeLabel[1] : maxHeight;

    return (
      <div className={className}>
        <p className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold)}>
          <FormattedMessage {...messages.floorHeight} />
        </p>
         <p className={s.captionTitle}>
         {minFloorHeightRange}m
          <span>{' - '}</span>
          {maxFloorHeightRange}m
        </p>
        <Field
          name="floorHeightRange"
          component={this.renderFloorHeightRange}
          min={minHeight}
          max={maxHeight}
          minHeight={minFloorHeightRange}
          maxHeight={maxFloorHeightRange}
        />
      </div>
    );
  }
}

FloorHeight = reduxForm({
  form: 'SearchForm', // a unique name for this form
  onSubmit: submit,
  destroyOnUnmount: false,
})(FloorHeight);

// Decorate with connect to read form values
const selector = formValueSelector('SearchForm'); // <-- same as form name

const mapState = (state) => ({
  fieldsSettingsData: state.listingFields.data,
  floorHeight: selector(state, 'floorHeight'),
  floorHeightRangeLabel: selector(state, 'floorHeightRangeLabel'),
  floorHeightRange: selector(state, 'floorHeightRange'),
});

const mapDispatch = {
  change,
  submitForm
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(FloorHeight)));
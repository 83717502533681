import { gql } from "react-apollo";
import {
  MANGOPAY_CREATE_UBO_DOCUMENT_START,
  MANGOPAY_CREATE_UBO_DOCUMENT_SUCCESS,
  MANGOPAY_CREATE_UBO_DOCUMENT_ERROR,
} from "../../constants";

// Mangopay
import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";

import { toastr } from "react-redux-toastr";

export function uploadUboDocuments(
  data,isIncomplete
) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: MANGOPAY_CREATE_UBO_DOCUMENT_START,
      payload: {
        paymentLoading: true,
      },
    });
    try {
      const {
        status,
        errorMessage,
      } = await processMangopayPayment(
        "create-ubo-document",
        data,
        "UBO",
        isIncomplete
      );

      // dispatch({
      //   type: MANGOPAY_CREATE_UBO_DOCUMENT_SUCCESS,
      //   // payload: { paymentLoading: false }
      //   payload: { paymentLoading: true },
      // });

      if (status === 200) {
        await dispatch({
          type: MANGOPAY_CREATE_UBO_DOCUMENT_SUCCESS,
          payload: { paymentLoading: true },
        });

        return {
          status
        };
      } else {
        errorMessage ? toastr.error("Failed!", errorMessage) : "";
        await dispatch({
          type: MANGOPAY_CREATE_UBO_DOCUMENT_ERROR,
          payload: { paymentLoading: false },
        });
        return {
          status
    
        };
      }
    } catch (error) {
      console.log("error",error)
      dispatch({
        type: MANGOPAY_CREATE_UBO_DOCUMENT_ERROR,
        payload: {
          error,
          paymentLoading: false,
        },
      });
      return false;
    }

  };
}


import messages from '../../locale/messages';



const validatePage1 = values => {

	const errors = {};
	
	if (isNaN(values.personCapacity)) {
		errors.personCapacity = messages.notANumber;
		// errors.personCapacity = "ERRORs";
	}

	if(values.personCapacity < 250 || values.personCapacity > 20000) {
		errors.personCapacity = messages.validationPersonCapacity;
	}

	if (values.personCapacity % 1 != 0) {
		errors.personCapacity = messages.notAFullNumber;
	}


	if (isNaN(values.floorHeight)) {
		errors.floorHeight = messages.notANumber;
		// errors.floorHeight = "ERRORs";
	}

	if(values.floorHeight < 2 || values.floorHeight > 25) {
		errors.floorHeight = messages.validationfloorHeight;
	}

	// errors.floorHeight = messages.only2decimals;
	if(!values.storageRack) {
		errors.storageRack = messages.required;
	}
	if(!values.residenceType) {
		errors.residenceType = messages.required;
	}
	if(!values.securityClass) {
		errors.securityClass = messages.required;
	}
	if(values.security && values.security.length == 0) {
		errors.security = messages.required;
	}

	if (!values.houseType) {
		errors.houseType = messages.required;
	  }
	
	  if (!values.country) {
		errors.country = messages.required;
	  }
	
	  if (!values.state) {
		errors.state = messages.required;
	  }
	
	  if (!values.city) {
		errors.city = messages.required;
	  }
	
	  if (!values.street) {
		errors.street = messages.required;
	  }
	
	  if (!values.zipcode) {
		errors.zipcode = messages.required;
	  }
	
	
	return errors;
}

export default validatePage1;

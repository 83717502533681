import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox2.css";
import { Grid, Row, Col } from "react-bootstrap";
import ProblemImage from "./problem.svg";
import SolutionImage from "./solution.svg";
import AdvantageImage from "./advantage.svg";

class ContentBox2 extends React.Component {
  

  render() {



    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx(s.table)}>
      
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className={cx(s.tableCell)}>
                    <img className={s.image} src={ProblemImage} />
                    <h3 className={s.title}>Das Problem</h3>
                    <p className={s.caption}>
                      Tausende, nicht zugängliche leerstehende Flächen, zu hoher Verwaltungsaufwand und intransparente Preis- und Vertragsgestaltung.
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className={cx(s.tableCell)}>
                    <img className={s.image} src={SolutionImage} />
                    <h3 className={s.title}>Unsere Lösung</h3>
                    <p className={s.caption} style={{paddingLeft: "20px", paddingRight: "20px"}}>Automatisierung aller Verwaltungs- & Zahlungsprozesse, sowie eine Übersicht aller sofort verfügbaren Flächen.</p>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className={cx(s.tableCell)}>
                    <img className={s.image} src={AdvantageImage} />
                    <h3 className={s.title}>Ihr Vorteil</h3>
                    <p className={s.caption}>
                      Mit Expozed1 finden Sie Flächen innerhalb von Sekunden, automatisieren die Verwaltung, sparen Zeit, Geld und steigern die Effizienz Ihres
                      Unternehmens.
                    </p>
                  </div>
                </Col>
              </Row>
              {/* <div className={cx(s.tableCell, s.textColumn, s.mobileView)}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.caption}>{description}</p>
                <div className={s.listItems}>
                  {listItems.map((item, index) => {
                    return (
                      <div className={s.listItem} key={index}>
                        <img src={ArrowDown} />
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
                {buttonLabel && (
                  <Link to={buttonLink} className={cx(s.btn, s.btnPrimary)}>
                    {buttonLabel}
                  </Link>
                )}
              </div> */}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s)(ContentBox2);

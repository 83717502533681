import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url, sitename } from "../../../config";
import CurrencyView from "../modules/CurrencyView";

class CancelAdminNotification extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      payoutToHost: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: "#404040",
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };
    moment.locale("de-DE");
    const {
      content: { guestName, hostName, confirmationCode, checkIn, listTitle, payoutToHost, currency, logo },
    } = this.props;
    let checkInDate = checkIn != null ? moment(checkIn).format("ddd, Do MMM, YYYY") : "";
    let momentStartDate = moment(checkIn).startOf("day");
    let today = moment();
    let interval = momentStartDate.diff(today, "days") + 1;
    let isPastDay = false;
    if (interval < 0) {
      isPastDay = true;
    }

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                 Es wurde eine Stornierung eingereicht:

                 Fläche:


                 Guest:


                 Host:
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CancelAdminNotification;

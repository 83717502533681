exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1m34d{padding-top:100px;padding-bottom:100px;color:#fff}._3eEJK{font-size:16px;margin-bottom:10px}._3HBuz{font-size:50px;margin-bottom:50px}.ARbmv{background-image:url(" + require("./+.svg") + ")}._2ex3h,.ARbmv{font-size:20px;padding:20px;border-bottom:1px solid #fff;display:block;margin-bottom:20px;padding-left:0;cursor:pointer;background-repeat:no-repeat;background-position:99% 30px}._2ex3h{background-image:url(" + require("./-.svg") + ")}", ""]);

// exports
exports.locals = {
	"content": "_1m34d",
	"faqText": "_3eEJK",
	"headLine": "_3HBuz",
	"trigger": "ARbmv",
	"triggerOpen": "_2ex3h"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2-CaQ{background-color:#404040;width:64px;display:block;height:1.5px;margin:0 auto;margin-bottom:27px}._3kUmY{height:2.5px;border:1px solid #404040;background-color:transparent}._3xOVw{margin-bottom:160px}@media screen and (max-width:767px){._3xOVw{margin-bottom:15px}._1e6Lp h1{font-size:28px;line-height:51px;text-align:left;margin-bottom:5px}h4._30wC6{font-size:26px}}@media screen and (max-width:992px){._3xOVw{margin-bottom:64px}h4._30wC6{font-size:23px;font-weight:600}._1e6Lp h1{font-size:36px;line-height:51px;margin-bottom:13px}}", ""]);

// exports
exports.locals = {
	"seperator": "_2-CaQ",
	"boxseperator": "_3kUmY",
	"centerimgsection": "_3xOVw",
	"mainhedding": "_1e6Lp",
	"common": "_30wC6"
};
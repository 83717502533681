import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
// Redux Form
import { Field, reduxForm } from "redux-form";

import { Grid, Row, FormGroup, Col, FormControl } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Cancellation.css";

// Components
import Summary from "./Summary";
import DetailsForHost from "./DetailsForHost";
import DetailsForGuest from "./DetailsForGuest";
import NotFound from "../../routes/notFound/NotFound";

// Helpers
import validate from "./validate";
import submit from "./submit";
import Link from '../Link';
// Locale
import messages from "../../locale/messages";

class CancellationPolicy extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    userType: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      guests: PropTypes.number.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      hostServiceFee: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      reservationState: PropTypes.string.isRequired,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        listingData: PropTypes.shape({
          cancellation: PropTypes.shape({
            id: PropTypes.number.isRequired,
            policyName: PropTypes.string.isRequired,
            priorDays: PropTypes.number.isRequired,
            accommodationPriorCheckIn: PropTypes.number.isRequired,
            accommodationBeforeCheckIn: PropTypes.number.isRequired,
            accommodationDuringCheckIn: PropTypes.number.isRequired,
            guestFeePriorCheckIn: PropTypes.number.isRequired,
            guestFeeBeforeCheckIn: PropTypes.number.isRequired,
            guestFeeDuringCheckIn: PropTypes.number.isRequired,
          }),
        }),
      }),
      messageData: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      hostData: PropTypes.shape({
        profileId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        companyName: PropTypes.string.isRequired,
        picture: PropTypes.string,
      }),
      guestData: PropTypes.shape({
        profileId: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        companyName: PropTypes.string.isRequired,
        picture: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    data: {
      checkIn: null,
      checkOut: null,
    },
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup>
        <FormControl {...input} className={className} componentClass="textarea" placeholder={label}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  };

  calculateCancellation(interval, nights, hoursSinceBooking) {
    const {
      data,
      data: {
        listData: { listingData },
      },
    } = this.props;

    let accomodation,
      guestFees,
      remainingNights,
      policyName,
      priorDays,
      policyId,
      nonRefundableNights,
      type = "priorCheckIn";
    // let = cancellation = data && data.cancellation ? data && data.cancellation : listingData && listingData.cancellation;

    //Cancellation Settings
    let cancellation = {
      accommodation48: 100,
      accommodationBeforeCheckIn: 100,
      accommodationDuringCheckIn: 0,
      accommodationPriorCheckIn: 100,
      guestFee48: 0,
      guestFeeBeforeCheckIn: 0,
      guestFeeDuringCheckIn: 0,
      guestFeePriorCheckIn: 2,
      hostFee48: 0,
      hostFeeBeforeCheckIn: 100,
      hostFeeDuringCheckIn: 100,
      hostFeePriorCheckIn: 100,
      id: 1,
      isEnable: true,
      nonRefundable48: 0,
      nonRefundableNightsBeforeCheckIn: 30,
      nonRefundableNightsDuringCheckIn: 0,
      nonRefundableNightsPriorCheckIn: 0,
      policyName: "Flexible",
      priorDays: 28,
      shortCancelHours: 48,
    };

    let cancellationRuleObj = {
      accomodation,
      guestFees,
      nonRefundableNights,
      priorDays,
      policyName,
      remainingNights,
      interval,
      nights,
    };

    if (hoursSinceBooking < cancellation.shortCancelHours && interval >= cancellation.priorDays) {
      type = "shortCancel";
    } else if (interval >= cancellation.priorDays) {
      type = "priorCheckIn";
    } else if (interval < cancellation.priorDays && interval > 0) {
      type = "beforeCheckIn";
    } else if (interval < cancellation.priorDays && interval <= 0) {
      type = "duringCheckIn";
    }

    if (type == "shortCancel") {
      cancellationRuleObj = {
        accomodation: cancellation.accommodation48,
        guestFees: cancellation.guestFee48,
        nonRefundableNights: cancellation.nonRefundable48,
        priorDays: cancellation.shortCancel,
        policyName: cancellation.policyName,
        interval,
        nights,
        type,
      };
    } else if (type == "priorCheckIn") {
      cancellationRuleObj = {
        accomodation: cancellation.accommodationPriorCheckIn,
        guestFees: cancellation.guestFeePriorCheckIn,
        nonRefundableNights: cancellation.nonRefundableNightsPriorCheckIn,
        priorDays: cancellation.priorDays,
        policyName: cancellation.policyName,
        interval,
        nights,
        type,
      };
    } else if (type == "beforeCheckIn") {
      cancellationRuleObj = {
        accomodation: cancellation.accommodationBeforeCheckIn,
        guestFees: cancellation.guestFeeBeforeCheckIn,
        nonRefundableNights: cancellation.nonRefundableNightsBeforeCheckIn,
        priorDays: cancellation.priorDays,
        policyName: cancellation.policyName,
        remainingNights: interval === 0 ? nights : null,
        interval,
        nights,
        type,
      };
    } else {
      cancellationRuleObj = {
        accomodation: cancellation.accommodationDuringCheckIn,
        guestFees: cancellation.guestFeeDuringCheckIn,
        nonRefundableNights: cancellation.nonRefundableNightsDuringCheckIn,
        priorDays: cancellation.priorDays,
        policyName: cancellation.policyName,
        remainingNights: interval === 0 ? nights - cancellation.nonRefundableNightsBeforeCheckIn : nights + interval,
        interval,
        nights,
        type,
      };
    }
    return { cancellationRuleObj };
  }

  render() {
    const { formatMessage } = this.props.intl;
    const {
      userType,
      data,
      data: { guestData, hostData, messageData, listData },
    } = this.props;
    let momentStartDate,
      momentEndDate,
      nights,
      interval,
      cancelData = {},
      bookingCreateDate,
      duration,
      hoursSinceBooking,
      placeholder;
    let today = moment().startOf("day");
    if (data.checkIn != null && data.checkOut != null && data.createdAt != null) {
      momentStartDate = moment(data.checkIn).startOf("day");
      momentEndDate = moment(data.checkOut).startOf("day");
      nights = momentEndDate.diff(momentStartDate, "days");
      interval = momentStartDate.diff(today, "days") + 1;
      bookingCreateDate = moment(data.createdAt);
      duration = moment.duration(bookingCreateDate.diff(moment()));
      hoursSinceBooking = duration.asHours() * -1;
    }
    //Dont allow cancel for monthly or vorkasse payment
    const notCancelable = data.isMonthly || data.transaction.paymentMethodId === 5;
    if (guestData && hostData && messageData && listData) {
      cancelData = this.calculateCancellation(interval - 1, nights + 1, hoursSinceBooking);
      const { handleSubmit, submitting, error, pristine } = this.props;
      if (userType === "host") {
        placeholder = formatMessage(messages.cancellationNote) + formatMessage(messages.cancellationNoteForHost);
      } else {
        placeholder = formatMessage(messages.cancellationNote) + formatMessage(messages.cancellationNoteForGuest);
      }

 
     if(notCancelable) {
       return (      <div className={s.noCancelPossible}>
        
        <p className={cx(s.landingStep, s.space3)}>
          <span>
            Leider ist eine Stornierung mit von Ihnen genutzten Zahlungsart (Monatliche Zahlung oder Vorkasse) nicht möglich.
            <br/>Falls Sie Fragen hierzu haben, wenden Sie sich bitte an unseren Kundenservice Rufen Sie uns an unter{" "}
          <a href="tel:49 731 79032090" className={s.linkText} target="_blank">
            +49 731 79032090
          </a>
          </span>
          
        </p>
        <Link
              className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft, s.btnWidth)}
              to={"/bookings/current"}
            >
              Zurück zu Ihren Buchungen
            </Link>
      </div>);
     }
  
      
      return (
        <div>
          <Grid>
            <Row className={s.landingContaiconfirmationCodener}>
              <form onSubmit={handleSubmit(submit)}>
                <Col xs={12} sm={7} md={7} lg={7}>
                <div className={s.cancelLeftBg}>
          <Summary
            userType={userType}
            firstName={userType === "host" ? guestData.firstName : hostData.firstName}
            companyName={userType === "host" ? guestData.companyName : hostData.companyName}
            guests={data.guests}
            nights={nights + 1}
            interval={interval - 1}
            cancelData={cancelData}
          />
          <Field className={s.textareaInput} name="message" component={this.renderFormControlTextArea} label={placeholder} />
          <p className={cx(s.landingStep, s.space3)}>
            <span>
              <FormattedMessage {...messages.reservationCancel} />
            </span>
          </p>
        </div>
                </Col>
                {userType === "host" && (
                  <DetailsForHost
                    userType={userType}
                    firstName={guestData.firstName}
                    companyName={guestData.companyName}
                    guestEmail={guestData.userData.id}
                    hostName={hostData.firstName}
                    hostCompanyName={hostData.companyName}
                    profileId={guestData.profileId}
                    picture={guestData.picture}
                    createdAt={data.createdAt}
                    checkIn={data.checkIn}
                    checkOut={data.checkOut}
                    guests={data.guests}
                    title={listData.title}
                    listId={data.listId}
                    basePrice={data.basePrice}
                    cleaningPrice={data.cleaningPrice}
                    guestServiceFee={data.guestServiceFee}
                    hostServiceFee={data.hostServiceFee}
                    total={data.total}
                    currency={data.currency}
                    cancelData={cancelData}
                    reservationId={data.id}
                    threadId={data.messageData.id}
                    confirmationCode={data.confirmationCode}
                    holdeData={data}
                    taxRate={data.taxRate}
                    interval={interval}
                    hostServiceFeeType={data.hostServiceFeeType}
                    hostServiceFeeValue={data.hostServiceFeeValue}
                    isSpecialPriceAverage={data.isSpecialPriceAverage}
                  />
                )}
                {userType === "guest" && (
                  <DetailsForGuest
                    userType={userType}
                    firstName={hostData.firstName}
                    companyName={hostData.companyName}
                    hostEmail={hostData.userData.id}
                    guestName={guestData.firstName}
                    guestCompanyName={guestData.companyName}
                    profileId={hostData.profileId}
                    picture={hostData.picture}
                    createdAt={data.createdAt}
                    checkIn={data.checkIn}
                    checkOut={data.checkOut}
                    guests={data.guests}
                    title={listData.title}
                    listId={data.listId}
                    basePrice={data.basePrice}
                    cleaningPrice={data.cleaningPrice}
                    guestServiceFee={data.guestServiceFee}
                    hostServiceFee={data.hostServiceFee}
                    total={data.total}
                    currency={data.currency}
                    cancelData={cancelData}
                    reservationId={data.id}
                    threadId={data.messageData.id}
                    confirmationCode={data.confirmationCode}
                    discount={data.discount ? data.discount : 0}
                    holdeData={data}
                    taxRate={data.taxRate}
                    interval={interval}
                    hostServiceFeeType={data.hostServiceFeeType}
                    hostServiceFeeValue={data.hostServiceFeeValue}
                    isSpecialPriceAverage={data.isSpecialPriceAverage}
                  />
                )}
              </form>
            </Row>
          </Grid>
        </div>
      );
    } else {
      return <NotFound />;
    }
  }
}

CancellationPolicy = reduxForm({
  form: "CancellationForm", // a unique name for this form
  validate,
  onSubmit: submit,
})(CancellationPolicy);

export default injectIntl(withStyles(s)(CancellationPolicy));

import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Form, FormControl, FormGroup, Row,Tabs,Tab, Button } from "react-bootstrap";
// Translation
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
// Redux Form
import { formValueSelector, reduxForm } from "redux-form";
// Redux action
import { bookingProcess } from "../../../actions/booking/bookingProcess";

import { FaEnvelope, FaPhone } from "react-icons/fa";
import ContactAgent from "../Agentbox/ContactAgent/ContactAgent";

// Locale
import messages from "../../../locale/messages";
// Component
import DateRange from "../DateRange";
import BillDetails from "./BillDetails";
import BookingButton from "./BookingButton";
import CheckItemBox from "./CheckItemBox";
import s from "./Calendar.css";

class BookingForm extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    personCapacity: PropTypes.number.isRequired,
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    currency: PropTypes.string,
    monthlyDiscount: PropTypes.number,
    weeklyDiscount: PropTypes.number,
    minNight: PropTypes.number,
    maxNight: PropTypes.number,
    maxDaysNotice: PropTypes.string,
    maxDaysNoticeStart: PropTypes.string,
    maxDaysNoticeEnd: PropTypes.string,
    loading: PropTypes.bool,
    availability: PropTypes.bool,
    maximumStay: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    blockedDates: PropTypes.array,
    isHost: PropTypes.bool.isRequired,
    guests: PropTypes.number,
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    bookingType: PropTypes.string.isRequired,
    bookingLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    account: PropTypes.shape({
      userBanStatus: PropTypes.number,
    }),
  };
  static defaultProps = {
    blockedDates: [],
    availability: true,
    maximumStay: false,
    minimumStay: false,
    startDate: null,
    endDate: null,
    guests: 1,
    personCapacity: 0,
  };
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "shortTermBooking",
      selectedLongTerm: null,
      showContactModal: false,
    };
  }
  renderFormControlSelect({ input, label, meta: { touched, error }, children, className }) {
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  }

  toggleContactModal = () => {
    this.setState({ showContactModal: !this.state.showContactModal });
  };

  renderGuests(personCapacity) {
    const { formatMessage } = this.props.intl;
    const rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(
        <option key={i} value={i}>
          {i} {i > 1 ? formatMessage(messages.guests) : formatMessage(messages.guest)}
        </option>
      );
    }
    return rows;
  }

  setTab = (key) => {
    this.setState({selectedTab: key})
  }
  setSelectedLongTerm = (value) => {
    this.setState({selectedLongTerm: value})
  }
  render() {
    const { formatMessage } = this.props.intl;
    const { id, personCapacity, basePrice, cleaningPrice, currency, isHost, bookingType, taxRate, qmPrice, nkPrice, isMonthly } = this.props;
    const { monthlyDiscount, weeklyDiscount, minNight, maxNight, maxDaysNotice, maxDaysNoticeStart, maxDaysNoticeEnd, checkIn, checkOut } = this.props;
    const { isLoading, availability, maximumStay, guests, startDate, endDate, account, blockedDates, minimumStay, bookingNoticeTime } = this.props;
    const { bookingProcess, serviceFees, base, rates, bookingLoading, initialValues, insurable, services, houseType } = this.props;
    const isDateChosen = (startDate != null && endDate != null) || false;
    const {selectedLongTerm, selectedTab, showContactModal} = this.state;
    let userBanStatusValue;
    if (account) {
      const {
        account: { userBanStatus },
      } = this.props;
      userBanStatusValue = userBanStatus;
    }

   let checkboxItems = [];
   const servicesMapping = {
    "self": "Selbstständige Nutzung",
    "inclusive": "Inklusive Be- und Entladen",
    "optional": "Optional Dienstleistungen",
    "full": "Full Service Vermietung"
  }
  
   if(services) {
     checkboxItems.push(servicesMapping[services])
   }
   if(houseType && houseType === "133") {
     checkboxItems.push("Gesamtes Gebäude")
   }
   if(insurable) {
     checkboxItems.push("Inhaltsversicherbar")
   }
    return (
      <Form>
        <FormGroup className={s.formGroup}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="booking-box">
          
              <Tabs
      className={s.tabs}
      activeKey={selectedTab}
      onSelect={(k) => this.setTab(k)}
    >
      <Tab className={s.TaContent} eventKey="shortTermBooking" title="Kurze Laufzeit">
      <span className={cx("viewListingDate")}>
                <DateRange
                  listId={id}
                  minimumNights={minNight}
                  maximumNights={maxNight}
                  blockedDates={blockedDates}
                  bookingNoticeTime={bookingNoticeTime}
                  formName={"BookingForm"}
                  maxDaysNotice={maxDaysNotice}
                  maxDaysNoticeStart={maxDaysNoticeStart}
                  maxDaysNoticeEnd={maxDaysNoticeEnd}
                />
              </span>
              <FormGroup>
          {!isLoading && maximumStay && isDateChosen && !userBanStatusValue && (
            <div className={cx(s.bookItMessage, s.spaceTop3)}>
              <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                <FormattedMessage {...messages.maximumStay} /> {maxNight} {maxNight > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
              </p>
            </div>
          )}
          {!isLoading && minimumStay && isDateChosen && !userBanStatusValue && (
            <div className={cx(s.bookItMessage, s.spaceTop3)}>
              <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                <FormattedMessage {...messages.minimumNightStay} /> {minNight} {minNight > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
              </p>
            </div>
          )}

          {!isLoading && !availability && isDateChosen && !userBanStatusValue && (
            <div className={cx(s.bookItMessage, s.spaceTop3)}>
              <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                <FormattedMessage {...messages.hostErrorMessage2} />
              </p>
            </div>
          )}
        </FormGroup>
        {!maximumStay && !minimumStay && availability && isDateChosen && !userBanStatusValue && (
          <BillDetails
            basePrice={basePrice}
            cleaningPrice={cleaningPrice}
            currency={currency}
            monthlyDiscount={monthlyDiscount}
            weeklyDiscount={weeklyDiscount}
            startDate={startDate}
            endDate={endDate}
            serviceFees={serviceFees}
            base={base}
            rates={rates}
            taxRate={taxRate}
            qmPrice={qmPrice}
            nkPrice={nkPrice}
            personCapacity={personCapacity}
            isMonthly={isMonthly}
            checkIn={startDate}
            checkOut={endDate}
          />
        )}
        <CheckItemBox  items={checkboxItems}/>
        {/* <BookingButton
          listId={id}
          startDate={startDate}
          endDate={endDate}
          guests={!isNaN(guests) ? guests : 1}
          bookingProcess={bookingProcess}
          availability={availability}
          isDateChosen={isDateChosen}
          userBanStatus={userBanStatusValue}
          basePrice={basePrice}
          isHost={isHost}
          bookingType={bookingType}
          bookingLoading={bookingLoading}
          maximumStay={maximumStay}
          taxRate={taxRate}
          minimumStay={minimumStay}
        /> */}
         <div>
            <Button onClick={this.toggleContactModal} className={cx(s.btn, s.btnPrimary, s.fullWidth, s.btnContact)}>
              Fläche besichtigen 
            </Button>
            
          </div>
      </Tab>
      <Tab className={s.TaContent} eventKey="longTermBooking" title="Langfristige Miete">
      <select  className={s.selectedLongTerm} value={selectedLongTerm} onChange={(e) => this.setSelectedLongTerm( e.target.value)}>
            <option disabled selected hidden value={null}>Mietlaufzeit wählen</option>
            <option value={"1-3"}>1-3 Jahre</option>
            <option value={"3-5"}>3-5 Jahre</option>
            <option value={"5+"}>über 5 Jahre</option>
        </select>
        <CheckItemBox  items={checkboxItems}/>
{/* 
        <BookingButton
          bookingProcess={bookingProcess}
          listId={id}
          userBanStatus={userBanStatusValue}
          isHost={isHost}
          selectedLongTerm={selectedLongTerm}
          isLongTermBooking={selectedTab ==="longTermBooking"}
        /> */}

          <div>
            <Button onClick={this.toggleContactModal} className={cx(s.btn, s.btnPrimary, s.fullWidth, s.btnContact)}>
              Fläche besichtigen
            </Button>
          
          </div>
      </Tab>
   
    </Tabs>
             
            </Col>
          </Row>
        </FormGroup>
        {/* <FormGroup>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <ControlLabel className={s.text}><FormattedMessage {...messages.guest} /></ControlLabel>
              <Field
                name="guests"
                component={this.renderFormControlSelect}
                className={s.formControlSelect}
              >
                {this.renderGuests(personCapacity)}
              </Field>
            </Col>
          </Row>
        </FormGroup> */}
          {showContactModal && (
          <ContactAgent
            hostProfile={this.props.hostProfile}
            showManualRequestModal={showContactModal}
            id={this.props.id}
            listingTitle={this.props.listingTitle}
            toggleManualRequest={this.toggleContactModal}
          />
        )}
      </Form>
    );
  }
}
BookingForm = reduxForm({
  form: "BookingForm", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(BookingForm);
// Decorate with connect to read form values
const selector = formValueSelector("BookingForm"); // <-- same as form name
const mapState = (state) => ({
  isLoading: state.viewListing.isLoading,
  availability: state.viewListing.availability,
  maximumStay: state.viewListing.maximumStay,
  minimumStay: state.viewListing.minimumStay,
  startDate: selector(state, "startDate"),
  endDate: selector(state, "endDate"),
  guests: Number(selector(state, "guests")),
  account: state.account.data,
  serviceFees: state.book.serviceFees,
  base: state.currency.base,
  rates: state.currency.rates,
  bookingLoading: state.book.bookingLoading,
});
const mapDispatch = {
  bookingProcess,
};
export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BookingForm)));

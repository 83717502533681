import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { Button, Col } from "react-bootstrap";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
import s from "./Agentbox.css";
import ContactAgent from "./ContactAgent/ContactAgent";
// Components
import PhotoPohl from "./pascal_pohl.jpg";

class Reviews extends React.Component {
  static propTypes = {};

  static defaultProps = {
    loading: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showContactModal: false,
    };
  }

  toggleContactModal = () => {
    this.setState({ showContactModal: !this.state.showContactModal });
  };

  render() {
    const { formatMessage, locale } = this.props.intl;
    const { showContactModal } = this.state;
    const { mobile } = this.props;
    moment.locale(locale);

    return (
      <div className={s.root}>
        {!mobile ? (
          <div>
            {" "}
            <Col xs={3} sm={3} md={3} lg={3} className={cx(s.column, s.noPadding)}>
              <img className={s.photo} src={PhotoPohl} />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className={cx(s.column)}>
              <p>Ihr Ansprechpartner </p>
              <p className={s.agentName}>Pascal Pohl</p>
              <p className={s.phoneNumber}>
                <a href="tel:+49 731 79032090">+49 731 79032090</a>
              </p>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3} className={cx(s.column)}>
              <Button onClick={this.toggleContactModal} className={cx(s.btn, s.btnPrimary, s.fullWidth)}>
                <FaEnvelope />
              </Button>
              <a href="tel:+49 731 79032090">
                <Button className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnManualRequest, s.fullWidth, s.manualRequestBtn)}>
                  <FaPhone />
                </Button>
              </a>
            </Col>
          </div>
        ) : (
          <div>
            <Button onClick={this.toggleContactModal} className={cx(s.btn, s.btnPrimary, s.fullWidth)}>
              <FaEnvelope />
            </Button>
            <a href="tel:+49 731 79032090">
              <Button className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnManualRequest, s.fullWidth, s.manualRequestBtn)}>
                <FaPhone />
              </Button>
            </a>
          </div>
        )}

        {showContactModal && (
          <ContactAgent
            hostProfile={this.props.hostProfile}
            showManualRequestModal={showContactModal}
            id={this.props.id}
            coverPhoto={this.props.coverPhoto}
            listingTitle={this.props.listingTitle}
            toggleManualRequest={this.toggleContactModal}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Reviews)));

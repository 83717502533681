exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._27b_7{padding:0}.h1m4u{display:table;margin-bottom:100px;background:#fff;box-shadow:0 12px 32px rgba(13,58,160,.16);border-radius:4px;margin-top:50px;margin-right:30px;height:400px}._9JDV1{background-color:#cfe6f8}._2eJCm{display:table-cell;vertical-align:middle;padding-right:10px}._1IbNA{width:100%;height:400px;object-fit:contain;object-position:top}._1CsAI{width:55%}._3JLKG{width:100%;float:right}._2vHsL{font-weight:600;font-size:32px;line-height:48px;color:#fff;margin:0;word-wrap:break-word;padding-top:2px;padding-bottom:2px;margin-top:10px;margin-bottom:10px}.fJvTM{font-size:16px;line-height:26px;color:#70758b}._3umXt,.fJvTM{margin-bottom:20px}._3umXt,.u2QwW{background:#fff}.u2QwW{border:1px solid rgba(6,103,179,.5);box-sizing:border-box;border-radius:3px;font-weight:500;font-size:15px;line-height:22px;display:inline-block;padding:5px 10px;cursor:pointer;color:#0667b3}._2wMcF{padding:50px;padding-top:50px;padding-left:10px;padding-right:10px}._2N1QM{font-weight:500;color:#000;margin-bottom:5px;margin-top:10px}._2N1QM,.FdiN6{font-size:16px;line-height:16px}.FdiN6{color:#70758b}._32fI5{display:none}@media (max-width:1023px) and (min-width:768px){._3JLKG{width:100%}._1hdbm{padding-right:10px}}@media screen and (max-width:767px){._1IbNA{object-fit:contain}._2eJCm{display:block;width:100%}.fJvTM{min-height:0}.h1m4u{display:block;margin-right:0;box-shadow:none}.ak-jJ{padding-right:0}.WBGvH{display:none}._32fI5{display:block}._1hdbm{padding:24px 0 0}._2vHsL{font-size:25px;line-height:30px}.fJvTM{color:#404040;margin-top:8px;margin-bottom:16px;word-wrap:break-word;font-size:16px;line-height:24px;padding-top:0;padding-bottom:0}._3JLKG{width:100%}}@media (max-width:1199px) and (min-width:768px){.h1m4u{padding-top:35px;padding-left:24px;padding-right:24px}._2vHsL{font-size:26px}}", ""]);

// exports
exports.locals = {
	"noPadding": "_27b_7",
	"table": "h1m4u",
	"dots": "_9JDV1",
	"tableCell": "_2eJCm",
	"image": "_1IbNA",
	"imageColumn": "_1CsAI",
	"imgShadow": "_3JLKG",
	"title": "_2vHsL",
	"caption": "fJvTM",
	"logo": "_3umXt",
	"button": "u2QwW",
	"contentRight": "_2wMcF",
	"quoteName": "_2N1QM",
	"quoteCompany": "FdiN6",
	"mobileView": "_32fI5",
	"textColumn": "_1hdbm",
	"paddingRight": "ak-jJ",
	"desktopView": "WBGvH"
};
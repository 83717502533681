import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import moment from 'moment';
import { url, sitename } from '../../../config';

class ContactAgent extends Component {
	static propTypes = {
		content: PropTypes.shape({
			hostName: PropTypes.string.isRequired,
			listTitle: PropTypes.string.isRequired,
		}).isRequired
	};

	render() {
		const textStyle = {
			color: '#404040',
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px',
		};

		const linkText = {
			color: '#0667B3',
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}


		const { content: {	listId,
			listingTitle,
			startDate,
			endDate,
			size,
			email,
			name,
			phoneNumber,
			hostName,
			hostCompanyName,
			hostId, logo } } = this.props;
		let listingUrl = url + "/spaces/"+ listId;
	
		return (
			<Layout>
				<Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
				<div>
					<Table width="100%" >
						<TBody>
							<TR>
								<TD style={textStyle}>
									<EmptySpace height={20} />
									<div>
										Hallo Admin,
					        </div>
									<EmptySpace height={20} />
									<div>
									{name}hat eine Nachricht zu folgendem Objekt gesendet.<br />
									<a href={listingUrl} >{listingTitle}</a>


									</div>
									<EmptySpace height={20} />
									<h4>AnfrageDaten:</h4><br/>
									Name: {name}<br/>
									Email: {email}<br/>
									Telefon: +{phoneNumber}<br/>

									<EmptySpace height={20} />
									<h4>Anbieter der Fläche:</h4><br/>
									Name: {hostName}<br/>
									Firmenname: {hostCompanyName}<br/>
									<EmptySpace height={20} />
									<div>
										Ihr {sitename} Team
					        </div>
								</TD>
							</TR>
						</TBody>
					</Table>
					<EmptySpace height={40} />
				</div>
				<Footer />
				<EmptySpace height={20} />
			</Layout>
		);
	}
}

export default ContactAgent;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loader from "../../Loader";

import { graphql, compose } from 'react-apollo';

// Redux Form
import {  reduxForm, change, formValueSelector } from 'redux-form';
// Redux
import { connect } from 'react-redux';

import {
  Button,
  Panel,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Payin.css';

// Graphql
import getPaymentMethodsQuery from './getPaymentMethods.graphql';

// Locale
import messages from '../../../locale/messages';

class PayinMethods extends Component {
  static propTypes = {
    handleSubmit: PropTypes.any.isRequired,
    previousPage: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
   
  };

  static defaultProps = {
    PaymentMethodsData: {
      loading: true,
      getPaymentMethods: []
    }
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { PaymentMethodsData: { loading, getPaymentMethods } } = nextProps;
    const { change, paymentMethodId } = this.props;
    // if (getPaymentMethods != null && getPaymentMethods.length > 0) {
    //   if (paymentMethodId === undefined || paymentMethodId === null) {
    //     change('methodId', getPaymentMethods[0].id);
    //     change('paymentType', getPaymentMethods[0].paymentType);
    //     change('currency', getPaymentMethods[0].currency);
    //   }
    // }
    change('methodId', 1);
    change('paymentType', 1);
  }

  handleChange(methodId, paymentType, currency) {
    const { change } = this.props;
    change('methodId', methodId);
    change('paymentType', paymentType);
    change('currency', currency);
  }

  render() {
    const { error, handleSubmit, previousPage } = this.props;
    // const { PaymentMethodsData: { loading, getPaymentMethods } } = this.props;

    //Get PaymentMethods for payin not from DB
    const getPaymentMethods = [{ 
      currency: "EUR",
      details: "Lastschriftmandat benötigt",
      fees: "Keine Gebühren",
      id:1,
      isEnable: true,
      name: "SEPA Lastschrift",
      paymentType: 1,
      processedIn: "Direkt",
      status: null,
    } ]
   const loading = false

    const { paymentMethodId } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <form onSubmit={handleSubmit}>
        <Panel
          className={s.panelHeader}
          header={formatMessage(messages.addPayin)}
          footer={
            <div>
              {/* <Button className={cx(s.button, s.btnlarge, s.btnPrimaryBorder, s.btnRight)} onClick={previousPage}>
                <FormattedMessage {...messages.back} />
              </Button> */}
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} type="submit">
                <FormattedMessage {...messages.next} />
              </Button>
            </div>
          }
        >
          <div className={s.panelBody}>
            <p className={s.payinIntro}>
            Um monatliche Zahlungen tätigen zu können muss eine Zahlungsmethode hinterlegt werden. Hier sehen Sie ihre hinterlegten Zahlungsmethoden, können diese bearbeiten oder neue hinzufügen. 
            </p>
         
            {
              loading && <div> <Loader type={"text"} /></div>
            }
            {
              !loading && getPaymentMethods != undefined && getPaymentMethods.length > 0 && <table className={cx('table', s.noBorder)}>
                <thead>
                  <tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
                    <th className={s.noBorder} />
                    <th className={s.noBorder}>Zahlungsart</th>
                    <th className={s.noBorder}>Ausführungsdauer</th>
                    <th className={s.noBorder}>Gebühren</th>
                    <th className={s.noBorder}>Währung</th>
                    <th className={s.noBorder}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    getPaymentMethods.map((item, index) => {
                      let checked = false;
                      if (item.id === paymentMethodId) {
                        checked = true;
                      }
                      return (
                        <tr className={cx(s.sectionTitleLight)} key={index}>
                          <td>
                            <input name="methodId" type="radio" checked={checked} value={item.id} onChange={() => this.handleChange(item.id, item.paymentType, item.currency)} />
                          </td>
                          <td><label className={s.radioText}>{item.name}</label></td>
                          <td>{item.processedIn}</td>
                          <td>{item.fees}</td>
                          <td>{item.currency}</td>
                          <td>{item.details}</td>
                        </tr>
                      );
                    })
                  }

                </tbody>
              </table>
            }
            {
              !loading && getPaymentMethods != undefined && getPaymentMethods.length === 0 && <div> <FormattedMessage {...messages.noPaymentFound} /> </div>
            }
          </div>
        </Panel>
      </form>
    );
  }
}

PayinMethods = reduxForm({
  form: 'PayinForm', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PayinMethods);

const selector = formValueSelector('PayinForm');

const mapState = (state) => ({
  paymentMethodId: selector(state, 'methodId')
});

const mapDispatch = {
  change
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getPaymentMethodsQuery, {
    name: 'PaymentMethodsData',
    options: {
      ssr: false,
    }
  }),
)(PayinMethods);
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Grid, Row, Col } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./CompleteProfile.css";
import { graphql, gql, compose } from "react-apollo";

// Redux
import { connect } from "react-redux";

// Component
import CompleteProfileForm from "../../CompleteProfile/CompleteProfile";

// Locale
import messages from "../../../locale/messages";

class CompleteProfile extends Component {
  static propTypes = {
    profilePhotoLoading: PropTypes.bool,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    const { nextPage, emailVerified, payinData, guestPicture } = this.props;

    if (!emailVerified) {
      nextPage("verification");
    } else if (guestPicture === null) {
      nextPage("avatar");
    }
    //TODO Temp restore
    // else if(!payinData) {
    // 	nextPage('payin');
    // }
    else {
      nextPage("payment");
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { payinData, accountData } = this.props;
    let initialData = accountData;

    initialData.methodId = 2;
    initialData.paymentType = 2;
    initialData.currency = "EUR";

    if (accountData.profileComplete) {
      this.handleClick();
    }
    return (
      <Grid>
        <Row>
          <div className={s.pageContainer}>
            <div className={s.activationStepPanel}>
              <div className={s.panelBody}>
                <div className={cx(s.space4, s.spaceTop4)}>
                  <div className={s.space2}>
                    <CompleteProfileForm isBooking={true} />
                  </div>
                </div>

                {/* <Col xs={12} sm={12} md={12} lg={12} className={cx(s.noPadding, s.spaceTop2)}>
									{
										accountData.profileComplete && <Button
											className={cx(s.button, s.btnPrimary)}
											onClick={this.handleClick}
										>
											<FormattedMessage {...messages.continue} />
										</Button>
									}

								</Col> */}
              </div>
            </div>
          </div>
        </Row>
      </Grid>
    );
  }
}
const mapState = (state) => ({
  profilePhotoLoading: state.account.profilePhotoLoading,
  accountData: state.account.data,
});

const mapDispatch = {};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(
    gql`
      query {
        userAccount {
          picture
        }
      }
    `,
    {
      name: "profilePictureData",
      options: {
        ssr: false,
      },
    }
  )
)(CompleteProfile);

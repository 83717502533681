import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

import { Button, FormGroup, Col, FormControl} from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payin.css";

// Helpers
import validateBankAccount from "./validateBankAccount";
import submit from "./submit";

// Locale
import messages from "../../../../locale/messages";

// Components
import Loader from "../../../Loader/Loader";
import TrustInfo from "../../../TrustInfo";

const normalizeIban = (value, previousValue) => {
  //   //DE41 2004 1144 0166 9118 00
  if (!value) {
    return value;
  }

  // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
  if (previousValue && previousValue.length >= value.length) {
    return value;
  }

  if (value.length <= 2) {
    value = value.replace(/[^A-Za-z]/g, "").toUpperCase();
  } else if (value.length > 2) {
    value = value.slice(0, 2) + value.slice(2).replace(/[^0-9\s]/g, "");
  }

  // Add / at appropriate sections of the input
  if (value.length === 4 || value.length === 9 || value.length === 14 || value.length === 19 || value.length === 24) {
    value += " ";
  }

  // Prevent characters being entered after Dob is full
  if (value.length >= 27) {
    return value.slice(0, 27);
  }
  return value;
};
class BankAccount extends Component {
  static propTypes = {
    handleSubmit: PropTypes.any.isRequired,
    previousPage: PropTypes.any.isRequired,
    siteName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    businessType: "individual",
  };

  componentDidMount() {
    const { bookingId, change } = this.props;

    change("bookingId", bookingId);
  }

  componentWillReceiveProps() {
    const { bookingId, change } = this.props;
    change("bookingId", bookingId);
  }

  renderFieldIban = ({ input, label, type, meta: { touched, error, dirty }, placeHolder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.space1}>
        <Col lg="3" md="3" sm="12" xs="12" className={cx(s.responsiveTextAlign, s.responsivePadding)}>
          <label className={s.labelText}>{label}</label>
        </Col>
        <Col lg="9" md="9" sm="12" xs="12" className={s.responsivePadding}>
          <FormGroup className={s.formGroup}>
            <FormControl
              {...input}
              componentClass="input"
              pattern="^DE\d{2}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{2}|DE\d{20}$"
              className={cx(s.formControlInput, s.commonBorder, s.inputFormControl)}
              placeholder={placeHolder}
            />
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
          </FormGroup>
        </Col>
      </div>
    );
  };

  renderFieldBic = ({ input, label, type, meta: { touched, error, dirty }, placeHolder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.space1}>
        <Col lg="3" md="3" sm="12" xs="12" className={cx(s.responsiveTextAlign, s.responsivePadding)}>
          <label className={s.labelText}>{label}</label>
        </Col>
        <Col lg="9" md="9" sm="12" xs="12" className={s.responsivePadding}>
          <FormGroup className={s.formGroup}>
            <FormControl
              {...input}
              componentClass="input"
              pattern="^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$"
              className={cx(s.formControlInput, s.commonBorder, s.inputFormControl)}
              placeholder={placeHolder}
            />
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
          </FormGroup>
        </Col>
      </div>
    );
  };

  renderSelectField = ({ input, label, type, meta: { touched, error, dirty }, children, placeHolder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.space1}>
        <Col lg="3" md="3" sm="12" xs="12" className={cx(s.responsiveTextAlign, s.responsivePadding)}>
          <label className={s.labelText}>{label}</label>
        </Col>
        <Col lg="9" md="9" sm="12" xs="12" className={s.responsivePadding}>
          <FormGroup className={s.formGroup}>
            <FormControl {...input} componentClass="select" className={cx(s.formControlInput, s.commonBorder, s.inputFormControl)} placeholder={placeHolder}>
              {children}
            </FormControl>
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
          </FormGroup>
        </Col>
      </div>
    );
  };

  render() {
    const { handleSubmit, pristine, previousPage, submitting, error } = this.props;
    const { base, availableCurrencies, siteName, payinLoading, businessType, payoutCountry } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={"inputFocusColor"}>
        <form onSubmit={handleSubmit(submit)}>
          <div className={s.panelBody}>
          <h2>Bankdaten</h2>
            <Field
              name="iban"
              component={this.renderFieldIban}
              normalize={normalizeIban}
              label={formatMessage(messages.accountNumber)}
              placeHolder={"Bsp: DE07 1234 1234 1234 1234 12"}
            />
            <Field name="bic" component={this.renderFieldBic} label={formatMessage(messages.bic)} placeHolder={"Bsp: ERFBDE8E759"} />

            <div className={cx(s.infoBox)}>{/* Info Bank account blabla */}</div>

            <div className={s.displayInline}>
              <Button className={cx(s.button, s.btnlarge, s.btnPrimaryBorder, s.btnRight)} onClick={previousPage}>
                <FormattedMessage {...messages.back} />
              </Button>
              <div className={s.displayInline}>
                <Loader
                  type={"button"}
                  buttonType={"submit"}
                  className={cx(s.button, s.btnPrimary, s.btnlarge, s.displayInline)}
                  disabled={pristine || submitting || error || payinLoading}
                  show={payinLoading}
                  label={formatMessage(messages.finish)}
                />
              </div>
              <TrustInfo />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

BankAccount = reduxForm({
  form: "PayinForm", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateBankAccount,
})(BankAccount);

const selector = formValueSelector("PayinForm");

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  payinLoading: state.reservation.payinLoading,
  bookingId: state.book.data.id,
  // businessType: selector(state, 'businessType'),
  // payoutCountry: selector(state, 'country')
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BankAccount)));

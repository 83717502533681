import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Body from '../modules/Body';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { sitename } from '../../../config';

class BanStatusServiceStatusUnBanned extends React.Component {

	static propTypes = {
		content: PropTypes.shape({
			userMail: PropTypes.string.isRequired,
		}).isRequired
	};

	render() {
		const buttonStyle = {
			margin: 0,
			fontFamily: 'Arial',
			padding: '10px 16px',
			textDecoration: 'none',
			borderRadius: '2px',
			border: '1px solid',
			textAlign: 'center',
			verticalAlign: 'middle',
			fontWeight: 'normal',
			fontSize: '18px',
			whiteSpace: 'nowrap',
			background: '#ffffff',
			borderColor: '#0667B3',
			backgroundColor: '#0667B3',
			color: '#ffffff',
			borderTopWidth: '1px',
		};

		const linkText = {
			color: '#0667B3',
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}

		const textStyle = {
			color: '#404040',
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px'
		};
		const { content: { userName, userMail, adminMail, logo } } = this.props;
		let mailTo = 'mailto:' + adminMail;

		return (
			<Layout>
				<Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
				<Body textStyle={textStyle}>
					<div>
						Hallo {userName},
                    </div>
					<EmptySpace height={20} />
					<div>
						Wir haben Ihr Benutzerkonto wieder aktiviert. Bitte melden Sie sich mit Ihren Login Daten an. Ihr Benutzerkonto wurde zuvor deaktiviert, da ein Verstoß gegen unsere Vertragsbedingungen vorlag.
                    </div>
					<EmptySpace height={20} />
					<div>
						Falls Sie Fragen haben sollten, wenden Sie sich gern an unser <a href={mailTo}>Support Team </a>
					</div>
					<EmptySpace height={40} />
					<div>
						Vielen Dank, <br />
						Ihr {sitename} Team
					</div>
				</Body>
				<Footer />
				<EmptySpace height={20} />
			</Layout>
		);
	}

}

export default BanStatusServiceStatusUnBanned;

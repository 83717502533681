
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Guests.css';
import {
  Button,
  Col
} from 'react-bootstrap';
import cx from 'classnames';
import { MdClear } from "react-icons/md";
// Redux Form
import { Field, reduxForm, formValueSelector, change, submit as submitForm } from 'redux-form';

// Redux
import { connect } from 'react-redux';

// Locale
import messages from '../../../../locale/messages';

// Submit
import submit from '../../SearchForm/submit';

import PersonCapacityRange from '../../PersonCapacityRange/PersonCapacityRange';
import CurrencyConverter from '../../../CurrencyConverter/CurrencyConverter';

class Guests extends Component {

  static propTypes = {
    className: PropTypes.any,
    handleTabToggle: PropTypes.any,
    isExpand: PropTypes.bool,
   
  };

  static defaultProps = {
    isExpand: false,
    smallDevice: false
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setBtnWrapperRef = this.setBtnWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async handleSubmit() {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submitForm } = this.props;
    await change('currentPage', 1);
    submitForm('SearchForm');
    handleTabToggle('guests', !isExpand)
  }

  handleReset() {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submitForm } = this.props;
    change('personCapacity', null);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setBtnWrapperRef(node) {
    this.btnWrapperRef = node;
  }

  handleClickOutside(event) {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submitForm } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      change('currentPage', 1);
      submitForm('SearchForm');
      if (this.btnWrapperRef && !this.btnWrapperRef.contains(event.target)) {
        handleTabToggle('guests', !isExpand)
      }
    }
  }

  renderPriceRange = ({ input, label, meta: { touched, error }, className, min, max, rangeCurrency, minPersonCapacity, maxPersonCapacity }) => {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, change, smallDevice } = this.props;
    return (
      <div className={cx(s.priceRangeContainer, s.space4)}>
        <PersonCapacityRange
          {...input}
          min={min}
          max={max}
          minPersonCapacity={minPersonCapacity}
          maxPersonCapacity={maxPersonCapacity}
        />
      </div>
    )
  }

  render() {
    const { className, handleTabToggle, isExpand, searchSettings } = this.props;
    const { personCapacityRangeLabel, personCapacity, personCapacityRange, smallDevice } = this.props;
    const { formatMessage } = this.props.intl;

    // let minPrice = searchSettings.minPrice;
    // let maxPrice = searchSettings.maxPrice;
    // let rangeCurrency = searchSettings.personCapacityCurrency;
   
    let minPersonCapacity = 250;
    let maxPersonCapacity = 5000;
    let minPersonCapacityRange = personCapacityRangeLabel != undefined ? personCapacityRangeLabel[0] : minPersonCapacity;
    let maxPersonCapacityRange = personCapacityRangeLabel != undefined ? personCapacityRangeLabel[1] : maxPersonCapacity;
    return (
      <div className={className}>
        <div ref={this.setBtnWrapperRef}>
          <Button
            className={cx({ [s.btnSecondary]: (isExpand === true || (personCapacity && personCapacity.length > 1)) }, s.btn, s.responsiveFontsize, s.searchBtn)}
            onClick={() => handleTabToggle('guests', !isExpand)}>
            {
              !personCapacityRange && "Flächenbedarf"
            }
            {
              personCapacityRange && personCapacityRange.length > 1 && <span>
                {minPersonCapacityRange}m²
                {' - '}
                {maxPersonCapacityRange}m²
              </span>
            }
          </Button>
        </div>
        {
          isExpand && <div className={cx(s.searchFilterPopover, { [s.searchFilterPopoverFull]: smallDevice == true })} ref={this.setWrapperRef}>
          <div className={s.searchFilterPopoverContent}>
            <div className={cx('visible-xs visible-sm', s.searchFilterPopoverHeader)}>
              <div className={cx(s.displayTable)}>
                <div className={cx('text-left', s.displayTableCell, s.searchFilterCloseIcon)}>
                  <span onClick={this.handleSubmit}>
                    <MdClear />
                  </span>
                </div>
              </div>
            </div>
            <div
                className={cx(s.displayTable, s.space4, { [s.spaceTop7]: smallDevice == true }, { [s.paddingTop2]: smallDevice == true })}>
              <div className={cx(s.captionTitle, s.fullWidth, s.capitalizeText)}>
                {minPersonCapacityRange}m²
                <span>{' - '}</span>
                {maxPersonCapacityRange}m²
              </div>
              <div className={cx(s.fullWidth)}>
              <Field
                name="priceRange"
                component={this.renderPriceRange}
                min={minPersonCapacity}
                max={maxPersonCapacity}
                minPersonCapacity={minPersonCapacityRange}
                maxPersonCapacity={maxPersonCapacityRange}
              />
            </div>
            </div>

            <div className={cx(s.searchFilterPopoverFooter, s.displayTable, s.applyBtnDesktopNoPaddingRight)}>
              {/* <div className={cx('hidden-xs hidden-sm', s.displayTableCell)}>
                {
                  (minPersonCapacityRange > minPersonCapacity || maxPersonCapacityRange < maxPersonCapacity) && <Button
                    bsStyle="link"
                    className={cx(s.btnLink)}
                    onClick={this.handleReset}>
                    <FormattedMessage {...messages.clear} />
                  </Button>
                }
              </div> */}
              <div className={cx( s.displayTableCell, s.applyBtnDesktop, s.applyBtnDesktopRight)}>
                <Button
                  bsStyle="link"
                  className={cx(s.btnLink, s.applyBtn, 'hidden-xs')}
                  onClick={this.handleSubmit}>
                  <FormattedMessage {...messages.apply} />
                </Button>

                <Col xs={12} className={cx(s.noPadding, 'visible-xs')}>
                  <Button
                    className={cx(s.btn, s.applyBtn)}
                    onClick={this.handleSubmit}>
                    <FormattedMessage {...messages.apply} />
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </div>
          
          
          
          
          
          
          // <div className={s.searchFilterPopover, { [s.searchFilterPopoverFull]: smallDevice == true }} ref={this.setWrapperRef}>
            // <div className={s.searchFilterPopoverContent}>
              // {/* <p className={cx(s.captionTitle, s.space4)}>
              //   {minPersonCapacityRange}m²
              //   <span>{' - '}</span>
              //   {maxPersonCapacityRange}m²
              // </p>
              // <Field
              //   name="priceRange"
              //   component={this.renderPriceRange}
              //   min={minPersonCapacity}
              //   max={maxPersonCapacity}
              //   minPersonCapacity={minPersonCapacityRange}
              //   maxPersonCapacity={maxPersonCapacityRange}
              // /> */}
          //     <div className={cx(s.searchFilterPopoverFooter, s.displayTable)}>
          //       <div className={cx('text-left', s.displayTableCell)}>
          //         {
          //           /*priceRange && <Button
          //             bsStyle="link"
          //             className={cx(s.btnLink)}
          //             onClick={this.handleReset}>
          //             <FormattedMessage {...messages.clear} />
          //           </Button>*/
          //         }
          //       </div>
          //       <div className={cx('text-right', s.displayTableCell)}>
          //         <Button
          //           bsStyle="link"
          //           className={cx(s.btnLink, s.applyBtn)}
          //           onClick={this.handleSubmit}>
          //           <FormattedMessage {...messages.apply} />
          //         </Button>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        }
      </div>
    );
  }
}

Guests = reduxForm({
  form: 'SearchForm', // a unique name for this form
  onSubmit: submit,
  destroyOnUnmount: false,
})(Guests);

// Decorate with connect to read form values
const selector = formValueSelector('SearchForm'); // <-- same as form name

const mapState = (state) => ({
  fieldsSettingsData: state.listingFields.data,
  guests: selector(state, 'personCapacity'),
  personCapacityRangeLabel: selector(state, 'personCapacityRangeLabel'),
  personCapacityRange: selector(state, 'personCapacityRange'),
});

const mapDispatch = {
  change,
  submitForm
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Guests)));
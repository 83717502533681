import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Collapse, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp, FaInfoCircle } from "react-icons/fa";
// Translation
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
// Redux Action
import { getSpecificSettings } from "../../../actions/getSpecificSettings";
import { contactHostOpen } from "../../../actions/message/contactHostModal";
// Locale
import messages from "../../../locale/messages";
import IconDoor from "../../ListPlaceStep1/icon_door.png";
import IconGate from "../../ListPlaceStep1/icon_gate.png";
import IconLoadingBrige from "../../ListPlaceStep1/icon_loadingbridge.png";
import IconRamp from "../../ListPlaceStep1/icon_ramp.png";
import CancelInfo from "./CancelInfo/CancelInfo";
// Helper functions
import { checkValue } from "./helper";
import ListBedTypes from "./ListBedTypes";
import s from "./ListingDetails.css";
// Internal Component
import ListItem from "./ListItem";

class ListingDetails extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      listingData: PropTypes.shape({
        cancellation: PropTypes.shape({
          policyName: PropTypes.string.isRequired,
          policyContent: PropTypes.string.isRequired,
        }),
      }),
    }),
    getSpecificSettings: PropTypes.any,
    settingsData: PropTypes.object,
    isHost: PropTypes.bool.isRequired,
    formatMessage: PropTypes.any,
    userBanStatus: PropTypes.number,
  };
  static defaultProps = {
    isHost: false,
    description: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { data, contactHostOpen, isHost, userBanStatus } = this.props;
    const { open } = this.state;
    const { formatMessage } = this.props.intl;
    let minNight, maxNight, checkInStart, checkInEnd, propertyType, roomType;
    let userAmenities = [],
      userSafetyAmenities = [],
      amenities = [];
    let sharedSpaces = [],
      houseRules = [],
      bedTypes = [],
      listBedTypes = [];
    let description,
      personCapacity,
      buildingType,
      buildingType2,
      bathrooms,
      bedrooms,
      beds,
      flooring,
      floorLoading,
      floorCount,
      parkingCount,
      floorHeight,
      buildingCondition,
      storageRack,
      storageRackDescription,
      powerConnection,
      lighting,
      buildingClimate,
      doors,
      gates,
      truckDocks,
      ramps,
      security,
      securityClass;
    if (data.listingData != undefined) {
      minNight = checkValue(data.listingData.minNight, 0);
      maxNight = checkValue(data.listingData.maxNight, 0);
      checkInStart = checkValue(data.listingData.checkInStart, "");
      checkInEnd = checkValue(data.listingData.checkInEnd, "");
    }
    if (data.settingsData != undefined && data.settingsData.length > 0) {
      propertyType = checkValue(data.settingsData && data.settingsData[1].listsettings.itemName, "");
      roomType = checkValue(data.settingsData && data.settingsData[0].listsettings.itemName, "");
    }
    sharedSpaces = checkValue(data.userSpaces, []);
    houseRules = checkValue(data.houseRules, []);
    userAmenities = checkValue(data.userAmenities, []);
    userSafetyAmenities = checkValue(data.userSafetyAmenities, []);
    description = checkValue(data.description, "");
    buildingType = checkValue(data.buildingType, "");
    buildingType2 = checkValue(data.buildingType2, "");
    personCapacity = checkValue(data.personCapacity, 0);
    bathrooms = checkValue(data.bathrooms, 0);
    bedrooms = checkValue(data.bedrooms, 0);
    beds = checkValue(data.beds, 0);
    bedTypes = checkValue(data.userBedsTypes, []);

    parkingCount = checkValue(data.parkingCount, 0);
    floorCount = checkValue(data.floorCount, "");
    floorHeight = checkValue(data.floorHeight, 0);
    security = checkValue(data.security, "");
    securityClass = checkValue(data.securityClass, "");
    floorLoading = checkValue(data.floorLoading, "");
    flooring = checkValue(data.flooring, "");
    buildingCondition = checkValue(data.buildingCondition, "");
    storageRack = checkValue(data.storageRack, false);
    storageRackDescription = checkValue(data.storageRackDescription, "");

    powerConnection = checkValue(data.powerConnection, "");
    lighting = checkValue(data.lighting, "");
    buildingClimate = checkValue(data.buildingClimate, "");
    truckDocks = checkValue(data.truckDocks, 0);
    ramps = checkValue(data.ramps, 0);
    gates = checkValue(data.gates, 0);
    doors = checkValue(data.doors, 0);

    let count = 150,
      firstArray,
      restArray,
      dotString = false;
    if (description) {
      firstArray = description.slice(0, count);
      restArray = description.slice(count, description.length);
    }
    if (restArray && restArray.length > 0) {
      dotString = true;
    }

    let isAmenities;
    userAmenities.map((item) => {
      if (item.listsettings.isEnable == "1") {
        isAmenities = true;
      }
    });

    let isSharedSpaces;
    sharedSpaces.map((item) => {
      if (item.listsettings.isEnable == "1") {
        isSharedSpaces = true;
      }
    });

    const insurable = securityClass && securityClass !== "SK0";

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";

    const popoverBuildingCondition = (
      <Popover id="popover-trigger-hover-focus" title="Gebäudezustand">
        <p>
          <b>Neuwertig</b>
          <br />
          Substanz der Immobilie ist in sehr gutem Zustand. Wände- Bodenbeläge, sowie weitere Ausstattungen sind neuwertig.
        </p>
        <p>
          <b>Altersgemäß gut</b>
          <br />
          Substanz der Immobilie ist in Ordnung. Wände- und Bodenbeläge, sowie weitere Ausstattungen sind in zu erwartendem Zustand.
        </p>
        <p>
          <b>Renovierungsbedüftig</b>
          <br />
          Substanz der Immobilie ist in Ordnung, Wände- und Bodenbeläge, sowie weitere Ausstattungen sind nicht mehr zeitgemäß.
        </p>
      </Popover>
    );
    const popoverFloorHeight = (
      <Popover id="popover-trigger-hover-focus" title="Hallen-/ Geschosshöhe">
        Die am niedrigsten relevanten Punkt gemessene Höhe
      </Popover>
    );

    const popoverFloorLoading = (
      <Popover id="popover-trigger-hover-focus" title="Bodenbelastung">
        <p>
          <strong>Standard:</strong>
          <br />
          Bodenbelastung (EG) bis zu 3 Tonnen pro m², <br />
          Deckenbelastung (OG) bis zu 350kg/m²{" "}
        </p>
        <p>
          <strong>Hoch:</strong>
          <br />
          Bodenbelastung (EG) bis zu 5 Tonnen pro m², <br />
          Deckenbelastung (OG) bis zu 350kg/m²{" "}
        </p>
      </Popover>
    );

    const popoverPowerConnection = (
      <Popover id="popover-trigger-hover-focus" title="Stromanschluss">
        <p>
          <strong>Standard</strong>
          <br />
          Für Lager ausreichende Versorgung mit 230V Steckdosen.
        </p>

        <p>
          <strong>Produktion</strong>
          <br />
          Lagerfläche mit 230V Steckdosen und mindestens einem 16A Anschluss.
        </p>

        <p>Hinweis: Genaue Angaben können Sie in Ihre Flächenbeschreibung hinzufügen.</p>
      </Popover>
    );
    const popoverLighting = (
      <Popover id="popover-trigger-hover-focus" title="Beleuchtung">
        <p>
          <strong>Einfach Beleuchtet</strong>
          <br />
          Ausreichende Beleuchtung für einfache Lagerarbeiten (unter 150 Lux)
        </p>
        <p>
          <strong>Ausreichend für Lager</strong>
          <br />
          Standardanforderungen für Lagerflächen (mindestens 150 Lux)
        </p>
        <p>
          <strong>Ausreichend für Produktion </strong>
          <br />
          Erfüllt mindestens die Anforderungen für einfache Produktionsarbeiten (mindestens 300 Lux)
        </p>
        <p>
          <strong>Besonders hohe Beleuchtung </strong>
          <br />
          Geeignet für Produktion, Lager und Büroarbeiten (über 500 Lux)
        </p>
      </Popover>
    );
    const popoverTruckDocks = (
      <Popover id="popover-trigger-hover-focus" title="Ladebrücken">
        <img className={s.popoverImage} src={IconLoadingBrige} />
      </Popover>
    );
    const popoverRamps = (
      <Popover id="popover-trigger-hover-focus" title="Rampen">
        <img className={s.popoverImage} src={IconRamp} />
      </Popover>
    );
    const popoverGates = (
      <Popover id="popover-trigger-hover-focus" title="Tore">
        <img className={s.popoverImage} src={IconGate} />
      </Popover>
    );
    const popoverDoors = (
      <Popover id="popover-trigger-hover-focus" title="Türen">
        <img className={s.popoverImage} src={IconDoor} />
      </Popover>
    );

    return (
      <Row className={cx(s.pageContent)}>
        <div className={cx(s.horizontalLineThrough)}>
          <h2 className={cx(s.sectionTitleText)}>
            {" "}
            <FormattedMessage {...messages.aboutListing} />
          </h2>
          <div>
            <p className={cx(s.listingFontSize)}>
              {!this.state.open && count >= 150 && dotString === true && <span className={cx(s.subText, s.lineBreak)}> {firstArray} ...</span>}
              {!this.state.open && count >= 150 && dotString === false && <span className={cx(s.subText, s.lineBreak)}> {firstArray}</span>}
              {restArray && restArray.length > 0 && (
                <span>
                  <Collapse in={open}>
                    <div>
                      {" "}
                      <span className={cx(s.subText, s.lineBreak)}>
                        {firstArray} {restArray}
                      </span>
                    </div>
                  </Collapse>
                  {dotString && (
                    <div className={s.btnContainer}>
                      <div className={s.showHidePadding}>
                        <Button bsStyle="link" className={cx(s.button, s.noPadding, s.btnLInk, s.showHideBtn)} onClick={() => this.handleClick()}>
                          {this.state.open ? <FormattedMessage {...messages.hideDescription} /> : <FormattedMessage {...messages.showDescription} />}

                          {this.state.open && <FaAngleUp className={s.navigationIcon} />}

                          {!this.state.open && <FaAngleDown className={s.navigationIcon} />}
                        </Button>
                      </div>
                    </div>
                  )}
                </span>
              )}
            </p>
          </div>
          {/* {
            !isHost && !userBanStatus && <div className={cx(s.spaceTop3, s.space4)}><p>
              <a href="javascript:void(0)" className={cx(s.sectionCaptionLink, s.sectionLink)} onClick={() => contactHostOpen(data.id)} >
                <FormattedMessage {...messages.contactHost} />
              </a>
            </p>
            </div>
          } */}
          <hr />
        </div>
        <div className={cx(s.horizontalLineThrough)}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className={cx(s.space1)}>
              <p className={s.textMutedNew}>Flächeneigenschaften</p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Row>
                <Col md={12} lg={12}>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.storageSpace} />: <strong>{personCapacity}m²</strong>
                    </span>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.flooring} />: <strong>{flooring}</strong>
                    </span>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.buildingType} />: <strong>{buildingType}</strong>
                    </span>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.buildingType2} />: <strong>{buildingType2}</strong>
                    </span>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.buildingCondition} />: <strong>{buildingCondition}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverBuildingCondition}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  {/* <p>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.beds} />: <strong>{beds}</strong>
                    </span>
                  </p> */}

                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.floorCount} />: <strong>{floorCount}</strong>
                    </span>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.floorLoading} />: <strong>{floorLoading}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverFloorLoading}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.floorHeight} />: <strong>{floorHeight}m</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverFloorHeight}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>

                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.powerConnection} />: <strong>{powerConnection}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverPowerConnection}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.lighting} />: <strong>{lighting}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverLighting}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.buildingClimate} />: <strong>{buildingClimate}</strong>
                    </span>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.truckDocks} />: <strong>{truckDocks}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverTruckDocks}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.ramps} />: <strong>{ramps}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRamps}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.gates} />: <strong>{gates}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverGates}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.doors} />: <strong>{doors}</strong>
                    </span>
                    <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverDoors}>
                      <FaInfoCircle />
                    </OverlayTrigger>
                  </p>
                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.storageRack2} />: <strong>{storageRack ? "vorhanden" : "nicht vorhanden"}</strong>
                    </span>
                  </p>
                  {storageRackDescription && (
                    <p className={s.splitList}>
                      <span className={cx(s.text)}>
                        <FormattedMessage {...messages.storageRackDescription} />: <strong>{storageRackDescription}</strong>
                      </span>
                    </p>
                  )}

                  <p className={s.splitList}>
                    <span className={cx(s.text)}>
                      Versicherbar: <strong>{insurable ? "Ja" : "Nein"}</strong>
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </div>
        {listBedTypes && listBedTypes.length > 0 && listBedTypes[0].bedType && (
          <div>
            {" "}
            <ListBedTypes itemList={listBedTypes} label={formatMessage(messages.beds)} />
            <div xs={12} sm={12} md={12} lg={12}>
              <hr />
            </div>
          </div>
        )}

        {sharedSpaces && sharedSpaces.length > 0 && isSharedSpaces && (
          <div>
            {" "}
            <ListItem
              itemList={sharedSpaces}
              label={formatMessage(messages.sharedSpaces)}
              showLabel={formatMessage(messages.showAllSharedSpaces)}
              hideLabel={formatMessage(messages.closeAllSharedSpaces)}
              icon={false}
            />
            <div>
              <hr />
            </div>
          </div>
        )}

        {userAmenities && userAmenities.length > 0 && isAmenities && (
          <div>
            {" "}
            <ListItem
              itemList={userAmenities}
              label={formatMessage(messages.aminities)}
              showLabel={formatMessage(messages.showAmenities)}
              hideLabel={formatMessage(messages.closeAmenities)}
              icon={true}
            />
            <div>
              <hr />
            </div>
          </div>
        )}
        {/* <Col xs={12} sm={12} md={8} lg={8} className={cx(s.space2, s.horizontalLineThrough)}>
          <Row>
            <Col xs={12} sm={3} md={3} lg={3} className={cx(s.space1, s.spaceTop1)}>
              <p className={s.textMuted}> <FormattedMessage {...messages.prices} /> </p>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} className={cx(s.space1,s.spaceTop1)}>
              <Row>
                <Col md={6} lg={6}>
                  <p>
                    <span className={cx(s.text)}>
                      <FormattedMessage {...messages.extraPeople} />: <strong><FormattedMessage {...messages.noCharge} /></strong>
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col> */}

        {houseRules.length > 0 && (
          <div>
            {" "}
            <ListItem
              itemList={houseRules}
              label={formatMessage(messages.houseRules)}
              showLabel={formatMessage(messages.showAllHouseRules)}
              hideLabel={formatMessage(messages.closeHouseRules)}
              icon={false}
            />
            <div>
              <hr />
            </div>
          </div>
        )}

        {userSafetyAmenities.length > 0 && (
          <div>
            <ListItem
              itemList={userSafetyAmenities}
              label={formatMessage(messages.safetyFeatures)}
              showLabel={formatMessage(messages.showAllSafetyFeatures)}
              hideLabel={formatMessage(messages.closeSafetyFeatures)}
              icon={true}
            />
            <div>
              <hr />
            </div>
          </div>
        )}
 {/*
        {data && data.listingData && data.listingData.cancellation && (
          <div className={cx(s.horizontalLineThrough)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(s.spaceTop1)}>
                <Row>
                  <Col md={12} lg={12}>
                    <p className={s.listingFontSize}>
                      <span className={cx(s.text)}><strong>{data.listingData.cancellation.policyName}</strong></span>
                    </p>
                    <p className={s.listingFontSize}>
                      <span className={cx(s.text)}>{data.listingData.cancellation.policyContent}</span>
                    </p>
                    <div className={cx(s.listingFontSize, s.showHideMargin)}>
                      <span onClick={this.openCancelationModal}
                        className={cx(s.sectionCaptionLink)}
                      >
                        <FormattedMessage {...messages.viewDetails} />
                      </span>
                    </div> 
                    <CancelInfo />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
          </div>
       
        )}   */}
        {/* {
          ((minNight != null && minNight) || (maxNight != null && maxNight > 0)) && <div>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(s.space1)}>
                <p className={s.textMutedNew}> <FormattedMessage {...messages.availability} /> </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(s.spaceTop1)}>
                <Row>
                  <Col md={12} lg={12}>
                    {
                      minNight != null && minNight > 0 &&
                      <p className={s.listingFontSize}><span className={cx(s.text)}> <strong>{minNight} {minNight > 1 ? 'nights' : 'night'}{' '}</strong>
                        <FormattedMessage {...messages.minimumStay} />
                      </span>
                      </p>
                    }
                    {
                      maxNight != null && maxNight > 0 &&
                      <p className={s.listingFontSize}><span className={cx(s.text)}> <strong>{maxNight} {maxNight > 1 ? 'nights' : 'night'}{' '}</strong>
                        <FormattedMessage {...messages.maximumNightStay} />
                      </span>
                      </p>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
          </div>
        } */}
      </Row>
    );
  }
}
const mapState = (state) => ({
  settingsData: state.viewListing.settingsData,
});
const mapDispatch = {
  getSpecificSettings,
  contactHostOpen,
};
export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ListingDetails)));

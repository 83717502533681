import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox3.css";
import { Grid, Row, Col } from "react-bootstrap";
import Link from "../../Link/Link";
import MapImage from "./map.svg";

class ContentBox3 extends React.Component {
  

  render() {



    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx(s.table)}>
              <Row>
                <Col lg={7} md={7} sm={12} xs={12}>
                <span className={s.textTop}>NACHHALTIGKEIT</span>

                  <div className={cx(s.tableCell, s.paddingRight)}>
                    <h3 className={s.title}>Bestehende Flächen nutzen</h3>
                    <p className={s.caption}>
                    Wir machen freie Flächen sichtbar, schaffen schnelle & transparente Abwicklung und steigern Effizienz und Rendite von Unternehmen in ganz Deutschland.
                    </p>
                  </div>
                </Col>
                <Col lg={5} md={5} sm={12} xs={12}>
                  <div className={cx(s.tableCell)}>
                    <img className={s.image} src={MapImage} />
                   
                  </div>
                </Col>

              </Row>
              {/* <div className={cx(s.tableCell, s.textColumn, s.mobileView)}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.caption}>{description}</p>
                <div className={s.listItems}>
                  {listItems.map((item, index) => {
                    return (
                      <div className={s.listItem} key={index}>
                        <img src={ArrowDown} />
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
                {buttonLabel && (
                  <Link to={buttonLink} className={cx(s.btn, s.btnPrimary)}>
                    {buttonLabel}
                  </Link>
                )}
              </div> */}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s)(ContentBox3);

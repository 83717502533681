import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url, sitename } from "../../../config";
import CurrencyView from "../modules/CurrencyView";

class CancelledByGuestConfirm extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      payoutToHost: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: "#404040",
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    moment.locale("de-DE");

    const {
      content: { guestName, hostName, confirmationCode, checkIn, listTitle, payoutToHost, currency, logo },
    } = this.props;
    let checkInDate = checkIn != null ? moment(checkIn).format("ddd, Do MMM, YYYY") : "";
    let momentStartDate = moment(checkIn).startOf("day");
    let today = moment();
    let interval = momentStartDate.diff(today, "days") + 1;
    let isPastDay = false;
    if (interval < 0) {
      isPastDay = true;
    }

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hallo {guestName},</div>
                  <EmptySpace height={20} />
                  <div>
                    Sie haben die Buchung {confirmationCode} für die Fläche {listTitle} vom {checkInDate} erfolgreich storniert.
                    {refundToGuest > 0 ? (
                      <div>
                        <span>
                          Entsprechend der expozed1 Stornierungsbedingungen erhalten Sie ein Rückerstattung von{" "}
                          <CurrencyView amount={refundToGuest} currency={currency} />.
                        </span>
                        <EmptySpace height={10} />
                        <p>Wir werden den Betrag auf die von Ihnen verwendete Zahlungsmethode bald möglichst rückerstatten.</p>
                      </div>
                    ) : (
                      <div> Entsprechend der expozed1 Stornierungsbedingungen erhalten Sie keine Rückerstattung.</div>
                    )}
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    Genaue Informationen können Sie gern dem beigefügten Stornierungsbeleg entnehmen.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    Vielen Dank, <br />
                    Ihr {sitename} Team
                  </div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CancelledByGuestConfirm;

export function getSubject(type) {
	let subject, previewText;

	if (type === 'welcomeEmail' || type === 'confirmEmail') {
		subject = 'Bestätigen Sie Ihre E-Mail Adresse';
		previewText = 'Bitte bestätigen Sie im nächsten Schritt Ihre E-Mail Adresse';
	}
	if (type === 'bankTransferRequestGuest') {
		subject = 'Vielen Dank! Ihre Buchung war erfolgreich.';
		previewText = 'Gute Neuigkeiten, Ihre Buchung war erfolgreich.';
	}
	if (type === 'bankTransferRequestHost') {
		subject = 'Sie haben eine neue Buchung vorliegen.';
		previewText = 'Gute Neuigkeiten, eine neue Buchung liegt vor';
	}
	if (type === 'bookingRequest') {
		subject = 'Neue Buchungsanfrage';
		previewText = 'Gute Neuigkeiten, Sie haben eine neue Buchungsanfrage vorliegen';
	}
	if (type === 'bookingRequestGuest') {
		subject = 'Neue Buchungsanfrage';
		previewText = 'Ihre Buchungsanfrage wurde versendet';
	}
	if (type === 'bookingConfirmedToHost') {
		subject = 'Sie haben eine bestätigte Buchung vorliegen.';
		previewText = 'Gute Neuigkeiten, eine neue Buchungsbestätigung liegt vor';
	}
	if (type === 'bookingConfirmedToGuest') {
		subject = 'Vielen Dank! Ihre Buchung war erfolgreich.';
		previewText = 'Gute Neuigkeiten, Ihre Anfrage wurde vom Anbieter bestätigt.';
	}
	if (type === 'bookingDeclinedToGuest') {
		subject = 'Ihre Anfrage wurde abgelehnt';
		previewText = 'Leider wurde Ihre Buchungsanfrage abgelehnt';
	}
	if (type === 'bookingExpiredGuest') {
		subject = 'Ihre Buchungsanfrage ist abgelaufen';
		previewText = 'Leider ist Ihre Buchungsanfrage abgelaufen';
	}
	if (type === 'bookingExpiredHost') {
		subject = 'Ihre Buchungsanfrage ist abgelaufen';
		previewText = 'Leider ist Ihre Buchungsanfrage abgelaufen';
	}
	if (type === 'cancelledByHost') {
		subject = 'Ihre Buchung wurde storniert';
		previewText = 'Leider hat der Anbieter die Buchung storniert';
	}
	if (type === 'cancelledByHostConfirm') {
		subject = 'Ihre Buchung wurde storniert';
		previewText = 'Ihre Buchung wurde erfolgreich storniert';
	}
	if (type === 'cancelledByGuest') {
		subject = 'Ihre Buchung wurde storniert';
		previewText = 'Leider hat der Mieter die Buchung storniert';
	}
	if (type === 'cancelledByGuestConfirm') {
		subject = 'Ihre Buchung wurde storniert';
		previewText = 'Ihre Buchung wurde erfolgreich storniert';
	}
	if (type === 'completedGuest') {
		subject = 'Ihre Buchung wurde erfolgreich beendet';
		previewText = 'Schreiben Sie ein Feedback für den Anbieter der Fläche';
	}
	if (type === 'completedHost') {
		subject = 'Ihre Buchung wurde erfolgreich beendet';
		previewText = 'Schreiben Sie ein Feedback für Ihren Mieter';
	}
	if (type === 'forgotPasswordLink') {
		subject = 'Passwort zurücksetzen';
		previewText = 'Setzen Sie Ihr Passwort zurück';
	}

	if (type === 'message') {
		subject = 'Neue Nachricht im Postfach';
		previewText = 'Sie haben eine neue Nachricht erhalten';
	}

	if (type === 'inquiry') {
		subject = 'Eine neue Anfrage liegt vor';
		previewText = 'Es liegt eine neue Anfrage vor';
	}

	if (type === 'documentVerification') {
		subject = 'Verifizierungsstatus Ihrer Dokumente';
		previewText = 'Ihre hochgeladenen Verifizierungsdokumente wurden bearbeitet';
	}
	if (type === 'contact') {
		subject = 'Customer Support Benachrichtigung';
		previewText = 'Es liegt eine neue Nachricht des Customer Supports vor';
	}
	if (type === 'reportUser') {
		subject = 'Ein Verstoß liegt gegen Sie vor';
		previewText = 'Sie haben eine Benachrichtigung zu einem Verstoß vorliegen';
	}
	if (type === 'bookingPreApproval') {
		subject = 'Ihre Buchungsanfrage wurde bestätigt';
		previewText = 'Gute Neuigkeiten, der Anbieter hat Ihre Buchungsanfrage bestätigt';
	}

	if (type === 'banStatusServiceStatusBanned') {
		subject = 'Ihr Benutzerkonto wurde deaktiviert';
		previewText = 'Ein Verstoß wurde gemeldet';
	}
	if (type === 'banStatusServiceStatusUnBanned') {
		subject = 'Ihr Benutzerkonto wurde aktiviert';
		previewText = 'Gute Neuigkeiten, wir haben Ihr Benutzerkonto aktiviert';
	}
	if (type === 'contactSupport') {
		subject = 'Es liegt eine neue Kundenanfrage vor';
		previewText = 'Es liegt eine neue Kundenanfrage vor';
	}
	if (type === 'userFeedback') {
		subject = 'Kundenbeurteilung';
		previewText = 'Ein neues Feedback liegt vor';
	}
	if (type === 'listingApprovedHost') {
		subject = 'Ihr Inserat wurde veröffentlicht';
		previewText = 'Ihr Inserat wurde geprüft und genehmigt';
	}
	if (type === 'listingApproveRequest') {
		subject = 'Prüfung Inserat';
		previewText = 'Es wurde ein Inserat zur Überprüfung eingereicht';
	}
	if (type === 'kycSuccess') {
		subject = 'Erfolgreiche KYC Verifizierung';
		previewText = 'Wir haben Ihre KYC-Dokumente erfolgreich verifiziert';
	}
	if (type === 'kycFail') {
		subject = 'Verifizierung fehlgeschlagen';
		previewText = 'Leider konnten wir Ihre KYC-Dokumente nicht verifizieren.';
	}
	if (type === 'uboSuccess') {
		subject = 'Erfolgreiche Verifizierung der Anteilseigner';
		previewText = 'Ihre eingereichten Dokumente wurden erfolgreich verifiziert';
	}
	if (type === 'uboFail') {
		subject = 'Verifizierung fehlgeschlagen';
		previewText = 'Leider konnten wir Ihre Dokumente nicht verifizieren'
	}
	if (type === 'payoutSuccess') {
		subject = 'Sie haben eine Auszahlung erhalten';
		previewText = 'Expozed1 hat eine Auszahlung auf Ihr Bankkonto veranlasst'
	}
	if (type === 'payoutFail') {
		subject = 'Es gab einen Fehler bei Ihrer Auszahlung';
		previewText = 'Es gab einen Fehler bei Ihrer Auszahlung'
	}
	if (type === 'refundSuccess') {
		subject = 'Sie haben eine Rückerstattung erhalten';
		previewText = 'Expozed1 hat eine Rückerstattung veranlasst'
	}
	if (type === 'manualRequest') {
		subject = 'Eine neue manuelle Anfrage ist eingegangen';
		previewText = 'Eine neue manuelle Anfrage ist eingegangen'
	}
	if (type === 'contactAgent') {
		subject = 'Eine neue Nachricht von einem Nutzer ist eingegangen';
		previewText = 'Eine neue Nachricht von einem Nutzer ist eingegangen'
	}
	if (type === 'CancelAdminNotification') {
		subject = 'NEUE STORNIERUNG';
		previewText = 'Eine Stornierung ist eingegangen'
	}

	if (type === 'BookingMonthlyReceiptHost') {
		subject = 'Erfolgreicher Zahlungseingang ';
		previewText = 'Erfolgreicher Zahlungseingang '
	}
	if (type === 'BookingMonthlyReceiptGuest') {
		subject = 'Ihr Zahlung wurde erfolgreich ausgeführt';
		previewText = 'Ihr Zahlung wurde erfolgreich ausgeführt'
	}
	if (type === 'BookingMonthlyFail') {
		subject = 'Eine Monatliche Zahlung konnte nicht ausgeführt werden';
		previewText = 'Eine Monatliche Zahlung konnte nicht ausgeführt werden'
	}

	return {
		subject,
		previewText
	};
}

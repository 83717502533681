import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import CancelationImage from "./cancelation_info.png";
import s from "./CancelInfo.css";

class CancelInfo extends Component {
  static propTypes = {
    hostDisplayName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalCancelationOpen: false,
    };
  }
  openCancelationModal = () => {
    this.setState({ modalCancelationOpen: true });
  };
  closeCancelationModal = () => {
    this.setState({ modalCancelationOpen: false });
  };

  render() {
    const { hostDisplayName, houseRules } = this.props;
    const { modalCancelationOpen } = this.state;
    const { locale } = this.props;
    moment.locale(locale);

    return (
      <div className={s.cancelInfo}>
        <h3 className={cx(s.h3, s.space4)}>
          <span>Stornierungsbedingungen</span>
        </h3>
        <p style={{ marginBottom: "10px" }}>Kostenlose Stornierung innerhalb 48 Stunden.</p>
        <p style={{ marginBottom: "10px" }}>
          Stornieren Sie mehr als 30 Tage vor Mietbeginn um eine volle Rückerstattung abzüglich der Servicegebühr zu erhalten.{" "}
        </p>
        <p style={{ marginBottom: "10px" }}>
          Im Falle einer Stornierung später als 30 Tagen vor Mietbeginn erhalten sie eine volle Rückerstattung abzüglich der Servicegebühr und dem Mietzins in
          Höhe von 30 Tagen.
        </p>

        <span onClick={this.openCancelationModal} className={s.modalLink}>
          Weitere Einzelheiten
        </span>

        <Modal bsSize="large" show={modalCancelationOpen} animation={false} onHide={this.closeCancelationModal} dialogClassName={cx(s.cancelationModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3 className={s.photoModalHeadline}>Stornierungsrichtlinien</h3>
              <img style={{ width: "100%" }} src={CancelationImage} />
              <p className={s.modalText}>
                Es gelten die oben stehenden Bedingungen, insbesondere im Bezug auf Ihr Recht in bestimmten Fällen eine anteilige oder vollständige Erstattung
                der einbehaltenen Beträge zu verlangen.
              </p>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={this.closeCancelationModal}>
                Fertig
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withStyles(s)(CancelInfo);

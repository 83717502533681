exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._9jYct{margin:0 20px}._1s7ov{margin:64px 40px 96px}.ojFDy{font-weight:700;font-size:36px;line-height:44px;letter-spacing:-.6px;padding-top:6px;padding-bottom:6px}._2Oexm,.ojFDy{color:#404040;margin:0;word-wrap:break-word}._2Oexm{font-weight:200;font-size:16px;line-height:24px;padding-top:0;padding-bottom:0}", ""]);

// exports
exports.locals = {
	"root": "_9jYct",
	"container": "_1s7ov",
	"headingText": "ojFDy",
	"subHeading": "_2Oexm"
};
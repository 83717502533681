import s2 from "!isomorphic-style-loader!css-loader!sass-loader!react-dates/lib/css/_datepicker.css";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Grid, Panel, Row } from "react-bootstrap";
import { SingleDatePicker } from "react-dates";
import history from "../../core/history";
// Component
import CustomCheckbox from "../CustomCheckbox";

import Avatar from "../Avatar";
import ListCoverPhoto from "../ListCoverPhoto";
import s from "./Payment/Payment.css";
import { toastr } from "react-redux-toastr";
import CheckItemBox from "../ViewListing/Calendar/CheckItemBox";
import Expozed1LogoWhite from "../Logo/expozed1_logo_white.svg";
class BookingLongTerm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prepayment: true,
      submitted: false,
      validationErrors: [],
      name: "",
      phone: "",
      email: "",
      message: "",
      flachenbedarf: 250,
      ab_wann_benotigen_sie_die_flache_2: "",
      mietlaufzeit: props.selectedLongTerm,
      focusedInput: null,
      termsAccepted: false,
    };
  }

  handleHSFormSubmit = () => {
    // const { listId } = this.props;
    // toastr.success("Anfrage", "Wir haben Ihre Anfrage erhalten und melden uns schnellst möglich bei Ihnen.");
    // history.push("/spaces/" + listId);
    this.setState({ submitted: true });
  };
  handleGoBack = () => {
    const { listId } = this.props;
    history.push("/spaces/" + listId);
  };

  componentDidMount() {
    let isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.addEventListener("message", (event) => {
        if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormSubmit") {
          this.handleHSFormSubmit();
        }
      });
    }
  }

  handleClick = (values, dispatch) => {
    const allValid = this.validateForm(values);
    if (allValid) {
      this.sendFormToHubspot(values);
    }

    this.setState({
      contactLoading: false,
    });

    // if (data && data.sendContactEmail && data.sendContactEmail.status == 200) {
    //   Event("expozed", "send_contact_form", values.email);

    //   toastr.success("Vielen Dank!", "Wir haben Ihre Nachricht erhalten.");
    // } else {
    //   toastr.error("Error!", "Sorry, something went wrong. Please try again!");
    // }
  };

  sendFormToHubspot = (values) => {
    const { message, ab_wann_benotigen_sie_die_flache_2, flachenbedarf, mietlaufzeit, name, phone, email, focusedInput } = this.state;
    // Replace portalId and formId with information from your FORM
    var portalId = "8224909";
    var formId = "bf11ceb2-984b-4e59-8d16-7f2c77d2db22";
    var url = "https://api.hsforms.com/submissions/v3/integration/submit/" + portalId + "/" + formId;
    // Build request JSON:

    const flachenbedarfMapping = {
      250: "ab 250m²",
      500: "ab 500m²",
      1000: "ab 1000m²",
    };
    var hubspotData = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "name",
          value: name,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "ab_wann_benotigen_sie_die_flache_2",
          value: ab_wann_benotigen_sie_die_flache_2,
        },
        {
          name: "flachenbedarf",
          value: flachenbedarfMapping[flachenbedarf],
        },
        {
          name: "mietlaufzeit",
          value: mietlaufzeit,
        },
        {
          name: "nachricht",
          value: message,
        },
      ],
      context: {
        hutk: this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Expozed1 to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999, // replace with your subscription ID
              text: "I agree to receive marketing communications from Expozed1.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(hubspotData),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Anfrage", "Wir haben Ihre Anfrage erhalten und melden uns schnellst möglich bei Ihnen.");

          this.setState({ submitted: true });
        } else {
          toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
      });
  };

  getCookie = (name) => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };

  returnYears = () => {
    let years = [];
    for (let i = moment().year() - 100; i <= moment().year(); i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    </div>
  );
  validateForm = () => {
    const { ab_wann_benotigen_sie_die_flache_2, flachenbedarf, mietlaufzeit, name, phone, email, focusedInput } = this.state;

    let validationErrors = [];

    let allValid = true;

    if (!ab_wann_benotigen_sie_die_flache_2) {
      validationErrors.push("ab_wann_benotigen_sie_die_flache_2");
      allValid = false;
    }
    if (!flachenbedarf) {
      validationErrors.push("flachenbedarf");
      allValid = false;
    }
    if (!mietlaufzeit) {
      validationErrors.push("mietlaufzeit");
      allValid = false;
    }
    if (!name) {
      validationErrors.push("name");
      allValid = false;
    }

    if (phone && phone.length < 7) {
      validationErrors.push("phone");
      allValid = false;
    }
    if (!email) {
      validationErrors.push("email");
      allValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email)) {
      validationErrors.push("email");
      allValid = false;
    }

    this.setState({ validationErrors });
    return allValid;
  };

  // handle input change
  handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    let newValue = {};
    newValue[name] = value;
    this.setState(newValue, () => {
      this.validateForm();
    });
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput: focusedInput.focused }, () => {
      this.validateForm();
    });
  };
  onDateChange(date) {
    this.setState({ ab_wann_benotigen_sie_die_flache_2: date }, () => {
      this.validateForm();
    });
  }

  setTermsAccepted = (value) => {
    this.setState({ termsAccepted: value });
  };

  render() {
    const { guestEmail, hostDisplayName, hostPicture, coverPhoto, listPhotos } = this.props;
    const { listId, listTitle, listType, city, state, country } = this.props;
    const { submitted, validationErrors } = this.state;
    const { message, ab_wann_benotigen_sie_die_flache_2, flachenbedarf, mietlaufzeit, name, phone, email, focusedInput, termsAccepted } = this.state;
    const { locale } = this.props;

    const checkboxItems =  ["persönliche Beratung", "Individuelle Laufzeit", "24/7 Service" ]
    moment.locale(locale);
    return (
      <Grid>
        <Row>
          <Col lg={7} md={7}>
              <div>
                {submitted ? (
                  <div>
                    <h1>Vielen Dank für Ihre Anfrage</h1>
                    <h3>Wir werden uns umgehend bei Ihnen melden</h3>
                  </div>
                ) : (
                  <div>
                    <h1>Ihr passendes Objekt langfristig mieten</h1>
                    <h3>Jetzt Anfrage ausfüllen und vom exklusiven Service für langfristige Anmietung profitieren.</h3>
                    <div className={s.landingMainContent,s.longTermForm}>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>Ab wann benötigen Sie die Fläche?</label>

                          <SingleDatePicker
                            id="date_input"
                            isOutsideRange={(day) => moment().diff(day) > 0}
                            name="ab_wann_benotigen_sie_die_flache_2"
                            date={ab_wann_benotigen_sie_die_flache_2}
                            onDateChange={(date) => this.onDateChange(date)}
                            numberOfMonths={1}
                            placeholder={"Datum"}
                            hideKeyboardShortcutsPanel
                            onFocusChange={(focusedInput) => this.onFocusChange(focusedInput)}
                            daySize={48}
                            focused={focusedInput}
                            keepOpenOnDateSelect={false}
                            renderMonthElement={this.renderMonthElement}
                            className={s.dateInput}
                          />
                          {validationErrors.includes("ab_wann_benotigen_sie_die_flache_2") && (
                            <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>
                          )}
                        </Col>
                      </Row>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>Flächenbedarf</label>

                          <select
                            name="flachenbedarf"
                            type="text"
                            value={flachenbedarf}
                            label={"Flächenbedarf"}
                            className={cx(s.formControlSelect, s.commonBorder)}
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="250">Ab 250m²</option>
                            <option value="500">Ab 500m²</option>
                            <option value="1000">Ab 1000m²</option>
                          </select>
                          {validationErrors.includes("flachenbedarf") && <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>}
                        </Col>
                      </Row>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>Mietlaufzeit</label>

                          <select
                            name="mietlaufzeit"
                            type="text"
                            value={mietlaufzeit}
                            label={"Mietlaufzeit"}
                            className={cx(s.formControlSelect, s.commonBorder)}
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="-1">Unter 1 Jahr</option>
                            <option value="1-3">1-3 Jahre</option>
                            <option value="3-5">3-5 Jahre</option>
                            <option value="5+">Über 5 Jahre</option>
                          </select>
                          {validationErrors.includes("mietlaufzeit") && <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>}
                        </Col>
                      </Row>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>Name</label>
                          <input
                            name="name"
                            type="text"
                            value={name}
                            label={"Name"}
                            className={cx(s.formControlInput, s.commonBorder)}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          {validationErrors.includes("name") && <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>}
                        </Col>
                      </Row>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>Telefonnummer</label>

                          <input
                            name="phone"
                            type="text"
                            value={phone}
                            label={"Telefonnummer"}
                            className={cx(s.formControlInput, s.commonBorder)}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          {validationErrors.includes("phone") && <span className={s.errorMessage}>* ungültige Eingabe</span>}
                        </Col>
                      </Row>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>E-Mail</label>

                          <input
                            name="email"
                            type="text"
                            value={email}
                            label={"E-Mail"}
                            className={cx(s.formControlInput, s.commonBorder)}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          {validationErrors.includes("email") && <span className={s.errorMessage}>* ungültige Eingabe</span>}
                        </Col>
                      </Row>
                      <Row className={s.spaceTop}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                          <label className={s.labelText}>Nachricht</label>

                          <textarea
                            rows="5"
                            name="message"
                            value={message}
                            className={cx(s.formControlInput, s.commonBorder)}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          {validationErrors.includes("message") && <span className={s.errorMessage}>* ungültige Eingabe</span>}
                        </Col>
                      </Row>

                      <Row className={s.space4}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className={s.confirmBox}>
                            <span className={(s.checkBoxSection, s.checkBox)}>
                              <CustomCheckbox
                                name="termsAccepted"
                                value={true}
                                checked={termsAccepted}
                                className={"icheckbox_square-green"}
                                onChange={(event) => {
                                  // const newValue = [...input.value];
                                  this.setTermsAccepted(event);
                                }}
                              />
                            </span>
                            <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
                              <label className={cx(s.checkboxLabel, s.noPadding)}>
                                Mit Absenden dieses Formulars bestätigen Sie, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden dürfen. (Weitere
                                Informationen und Widerrufshinweise finden Sie in der{" "}
                                <a href="/page/datenschutz" target="blank" rel="noopener noreferrer">
                                  Datenschutzerklärung
                                </a>{" "}
                                und den{" "}
                                <a href="/page/agb" target="blank" rel="noopener noreferrer">
                                  AGB
                                </a>
                                )
                              </label>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <Button className={cx(s.btnSecondary, s.btn, s.backButton)} onClick={() => this.handleGoBack()}>
                  zurück
                </Button>
                {!submitted && (
                  <Button
                    disabled={!termsAccepted}
                    className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight, s.noMargin, s.smPosition)}
                    onClick={this.handleClick}
                  >
                    Absenden
                  </Button>
                )}
              </div>
          </Col>
          <Col lg={5} md={5} className={s.positionSticky}>
            <div className={cx(s.summaryCard, s.colCenter)}>
              <div>
                <div className={s.postionRelative}>
                  <ListCoverPhoto
                    className={cx(s.bannerImage, s.backgroundCover)}
                    coverPhoto={coverPhoto}
                    listPhotos={listPhotos}
                    photoType={"x_medium"}
                    bgImage
                  />
                  <div className={s.secondSection}>
                    <div className={cx(s.displayInline, s.avatarWidth, s.vtrMiddle)}>
                      <div className={cx(s.profileAvatarLink, s.profileAvatarSection)}>
                        <Avatar source={hostPicture} title={hostDisplayName} className={s.profileAvatar} />
                      </div>
                      <div className={cx(s.textMuted, s.colorWhite, s.textEllipsis)}>
                        <span>{hostDisplayName}</span>
                      </div>
                    </div>
                    <div className={cx(s.displayInline, s.contentWidth, s.vtrMiddle)}>
                      <div className={cx(s.textLarge, s.colorWhite, s.propertyText)}>
                        <span>{listTitle}</span>
                      </div>
                      <div className={cx(s.textMuted, s.colorWhite, s.listItem)}>
                        <ul className={s.listStyle}>
                          <li>{listType}</li>
                        </ul>
                        <div className={s.colorWhite}>
                          {" "}
                          {city}, {state}, {country}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Panel className={s.panelHeader}>
                <div className={s.spaceTop2}>
                  <Row className={cx(s.spaceTop3, s.space3)}>
                    <Col xs={12} sm={12}>
                      <h3>Jetzt Fläche anfragen</h3>
                      <CheckItemBox  items={checkboxItems}/>
                    </Col>
                  </Row>
                </div>
                <div className={s.footerLogo}>
<img src={Expozed1LogoWhite} />
                </div>
              </Panel>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s, s2)(BookingLongTerm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

// Redux
import { connect } from 'react-redux';

import {
  Button,
  Panel,
  Label,
  OverlayTrigger,
  Tooltip,
  Modal,
  Alert
} from 'react-bootstrap';
import cx from 'classnames';
import  { FaInfoCircle } from 'react-icons/fa';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Payout.css';

// Redirection
import history from '../../../core/history';

// Locale
import messages from '../../../locale/messages';

// Redux actions
import { removePayout } from '../../../actions/Payout/removePayoutAction';
import { setDefaultPayout } from '../../../actions/Payout/setDefaultPayout';

class PayoutList extends Component {

  static defaultProps = {
    payoutRemoveLoader: false,
    payoutDefaultLoader: false,
    payoutVerifyLoader: false,
    data: []
  };


  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  handleClick() {
    history.push('/user/addpayout');
  }

  openModal = () => {
    this.setState({modalOpen: true})
  }

  closeModal = () => {
    this.setState({modalOpen: false})
  }


  render() {
    const { data, removePayout, setDefaultPayout, currentAccountId } = this.props;
    const { formatMessage } = this.props.intl;
    const { payoutRemoveLoader, payoutDefaultLoader, payoutVerifyLoader, isKycVerified } = this.props;
    const { modalOpen } = this.state;

    return (
      <Panel className={s.panelHeader} header={formatMessage(messages.payoutMethod)} >
        <div className={s.panelBody}>
          <p className={s.payoutIntro}>
          {!isKycVerified && (
               <Alert bsStyle="warning">
               Achtung! Um zukünftig Auszahlungen erhalten zu können, müssen Sie zuerst eine einmalige Überprüfung durchlaufen. Bei der KYC Verifizierung handelt es sich um die Überprüfung der Identität und Firmendaten von Neukunden. Diese dient der Prävention von Betrug, bietet Ihnen Sicherheit und muss zur Einhaltung von lokalen Gesetzen durchgeführt werden. Ein grundlegender Bestandteil hierbei ist die Verifizierung der Anteilseigner und dient Ihrem eigenen Schutz.
               <br/><a href="/kyc-verification">Hierfür benötigte Dokumente jetzt hochladen</a>
             </Alert>
            )}
            <FormattedMessage {...messages.payoutTitleBlock1} />
          </p>
          <table className={cx('table', s.noBorder)}>
            <thead>
              <tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
                <th className={s.noBorder}><FormattedMessage {...messages.payoutTitle} /></th>
                <th className={s.noBorder}><FormattedMessage {...messages.payoutTitle4} /></th>
                <th className={s.noBorder}><FormattedMessage {...messages.status} /></th>
                <th className={s.noBorder}></th>
              </tr>
            </thead>
            <tbody>
              {
                data.length > 0 && data.map((item, index) => {
                  return (
                    <tr className={cx(s.rowBorder, s.sectionTitleLight)} key={index}>
                      <td>{item.paymentMethod.name ==="Bank Account" ? "Bankkonto" : item.paymentMethod.name} {item.default && <Label bsStyle="success"><FormattedMessage {...messages.default} /></Label>}</td>
                      <td>
                        {
                          item.methodId == 1 && <span>
                            {item.payEmail}
                          </span>
                        }
                        {
                          item.methodId == 2 && <span>
                          ******{item.last4Digits && item.last4Digits.toString().length === 3 ? "0" + item.last4Digits : item.last4Digits} ({item.currency})
                          </span>
                        }
                          </td>
                      <td>
                        {
                          item.isVerified === true && <FormattedMessage {...messages.ready} />
                        }
                        {
                          item.isVerified !== true && <FormattedMessage {...messages.notReady} />
                        }
                      </td>
                      <td className={s.textTruncate}>
                        {
                          !item.default && item.isVerified === true && <a
                            href="javascript:void(0)"
                            className={cx(s.marginRight,{ [s.transparentText]: payoutDefaultLoader })}
                            onClick={() => {
                              if (!payoutDefaultLoader) {
                                setDefaultPayout(item.id)
                              }
                            }}
                          >
                            <FormattedMessage {...messages.setDefault} />
                          </a>
                        }
                        {
                          !item.default && item.isVerified !== true && <a
                            href="javascript:void(0)"
                            onClick={() => {
                              if (!payoutVerifyLoader) {
                              }
                            }}
                          >
                            <FormattedMessage {...messages.payoutVerify} /> 
                            <OverlayTrigger
                              overlay={<Tooltip id={'tooltip' + index}><FormattedMessage {...messages.payoutVerifyStripeInfo} /></Tooltip>}
                              placement="top"
                            >
                              <span>&nbsp;<FaInfoCircle style={{color: '#404040'}} /></span>
                            </OverlayTrigger>
                          </a>
                        }
                         <a
                            className={cx( { [s.transparentText]: payoutRemoveLoader })}
                            href="javascript:void(0)"
                            onClick={() => {
                              if (!payoutRemoveLoader) {
                                this.openModal();
                              }
                            }}
                          >
                            <FormattedMessage {...messages.remove} />
                          </a>

                      </td>
                      <Modal
          show={modalOpen}
          onHide={this.closeModal}
          animation={true}
          dialogClassName={cx(s.logInModalContainer, "loginModal")}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              {/* TODO Translate */}
              Sind Sie sicher dass Sie diese Auszahlungs-Methode entfernen möchten?
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={() => {
              removePayout(item.id);
              this.closeModal();
            }} bsStyle="primary">
              entfernen
            </Button>
          </Modal.Footer>
        </Modal>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          <div className={cx(s.sectionTitleLight)}>
            <Button className={cx(s.button, s.btnlarge, s.btnPrimary)} onClick={this.handleClick}>
              <FormattedMessage {...messages.addPayout} />
            </Button>
            <span className={s.textMuted}></span>
          </div>
        </div>

        
      </Panel>

    );
  }
}

const mapState = (state) => ({
  payoutRemoveLoader: state.loader.payoutRemove,
  payoutDefaultLoader: state.loader.payoutDefault,
  payoutVerifyLoader: state.loader.payoutVerify,
  isKycVerified: state.account.data.verification.isKycVerified
});

const mapDispatch = {
  removePayout,
  setDefaultPayout,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PayoutList)));
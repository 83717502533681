import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { Row, Col, Panel, Button } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Itinerary.css";
import { connect } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";
// Components
import Avatar from "../Avatar";
import CurrencyConverter from "../CurrencyConverter";
import ListCoverPhoto from "../ListCoverPhoto";
import Link from "../Link";
import StarRating from "../StarRating";
import  { FaCheckCircle } from 'react-icons/fa';

import history from "../../core/history";
import { url } from "../../config";

// Helper
import { generateTime } from "../Receipt/helper";

// Helper functions
import { checkIn, checkValue } from "../ViewListing/ListingDetails/helper";
// Locale
import messages from "../../locale/messages";
import ListNotFound from "../../routes/listNotFound/ListNotFound";
class Itinerary extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      guestServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      reservationState: PropTypes.string.isRequired,
      listData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
        reviewsCount: PropTypes.number.isRequired,
        reviewsStarRating: PropTypes.number.isRequired,
        listingData: PropTypes.shape({
          checkInStart: PropTypes.string.isRequired,
          checkInEnd: PropTypes.string.isRequired,
        }),
        coverPhoto: PropTypes.number,
        listPhotos: PropTypes.arrayOf({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      }),
      messageData: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      hostData: PropTypes.shape({
        profileId: PropTypes.number.isRequired,
        displayName: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired,
      }),
    }),
  };

  static defaultProps = {
    data: null,
  };

  render() {
    const { data } = this.props;
    const { formatMessage } = this.props.intl;
    const { userId } = this.props;
    let description,
      personCapacity,
      buildingType,
      buildingType2,
      bathrooms,
      bedrooms,
      beds,
      flooring,
      floorLoading,
      floorCount,
      parkingCount,
      floorHeight,
      buildingCondition,
      storageRack,
      storageRackDescription,
      powerConnection,
      lighting,
      buildingClimate,
      doors,
      gates,
      truckDocks,
      monthlyPay,
      ramps,
      reservationState,
      security,
      services,
      service_price_hour,
      service_price_qm,
      service_price_kg,
      service_price_palette,
      service_description,
      securityClass;
    let sharedSpaces = [],
      houseRules = [],
      bedTypes = [],
      listBedTypes = [];
    let userAmenities = [],
      userSafetyAmenities = [],
      amenities = [];

    if (data === null) {
      return (
        <div>
          {" "}
          <FormattedMessage {...messages.errorMessage} />{" "}
        </div>
      );
    } else if (data.listData === null) {
      return (
        <div>
          <ListNotFound />
        </div>
      );
    } else {
      const {
        data,
        data: { documents, id, listId, checkIn, reservationState, checkOut, total, guestServiceFee, currency, confirmationCode, hostId, guestId },
      } = this.props;
      const {
        data: {
          hostData: { profileId, displayName, picture },
        },
      } = this.props;

      const {
        data: {
          listData: { title, street, city, state, country, zipcode },
        },
      } = this.props;
      const {
        data: {
          listData: { coverPhoto, listPhotos, reviewsCount, reviewsStarRating },
        },
      } = this.props;
      // const { data: { listData: { listingData: { checkInStart, checkInEnd } } } } = this.props;
      const {
        data: { messageData },
      } = this.props;
      let checkInTimeFormat;
      let {
        data: { checkInStart, checkInEnd },
      } = this.props;
      const { locale } = this.props.intl;
      moment.locale(locale);
      const mangopayBankInfo = JSON.parse(data.mangopayBankInfo);

      let checkInDate = checkIn ? moment(checkIn).format("ddd, Do MMM") : "";
      let checkOutDate = checkOut ? moment(checkOut).format("ddd, Do MMM") : "";
      let momentStartDate, momentEndDate, dayDifference, checkInTime, checkOutTime;
      if (checkIn != null && checkOut != null) {
        momentStartDate = moment(checkIn);
        momentEndDate = moment(checkOut);
        dayDifference = momentEndDate.diff(momentStartDate, "days") + 1;
      }
      if (checkInStart !== "Flexible") {
        checkInTime = generateTime(checkInStart);
      }

      if (checkInEnd !== "Flexible") {
        checkOutTime = generateTime(checkInEnd);
      }

      if (checkInStart === "Flexible" && checkInEnd === "Flexible") {
        checkInTimeFormat = formatMessage(messages.flexibleCheckIn);
      } else if (checkInStart !== "Flexible" && checkInEnd === "Flexible") {
        checkInTimeFormat = "Ab " + checkInTime + " Uhr";
      } else if (checkInStart === "Flexible" && checkInEnd !== "Flexible") {
        checkInTimeFormat = "Bis " + checkOutTime + " Uhr";
      } else if (checkInStart !== "Flexible" && checkInEnd !== "Flexible") {
        checkInTimeFormat = checkInTime + " - " + checkOutTime + " Uhr";
      }

      let subTotal = total + guestServiceFee;
      let starRatingValue = 0;
      if (reviewsCount > 0 && reviewsStarRating > 0) {
        starRatingValue = Number(reviewsStarRating / reviewsCount);
      }

      let isHost = false;
      if (userId === hostId) {
        isHost = true;
      }
      sharedSpaces = checkValue(data.listData.userSpaces, []);
      houseRules = checkValue(data.listData.houseRules, []);
      userAmenities = checkValue(data.listData.userAmenities, []);
      userSafetyAmenities = checkValue(data.listData.userSafetyAmenities, []);
      description = checkValue(data.listData.description, "");
      buildingType = checkValue(data.listData.buildingType, "");
      buildingType2 = checkValue(data.listData.buildingType2, "");
      personCapacity = checkValue(data.listData.personCapacity, 0);
      bathrooms = checkValue(data.listData.bathrooms, 0);
      bedrooms = checkValue(data.listData.bedrooms, 0);
      beds = checkValue(data.listData.beds, 0);
      bedTypes = checkValue(data.listData.userBedsTypes, []);

      parkingCount = checkValue(data.listData.parkingCount, 0);
      floorCount = checkValue(data.listData.floorCount, "");
      floorHeight = checkValue(data.listData.floorHeight, 0);
      security = checkValue(data.listData.security, "");
      securityClass = checkValue(data.listData.securityClass, "");
      floorLoading = checkValue(data.listData.floorLoading, "");
      flooring = checkValue(data.listData.flooring, "");
      buildingCondition = checkValue(data.listData.buildingCondition, "");
      storageRack = checkValue(data.listData.storageRack, false);
      monthlyPay = checkValue(data.listData.listingData.monthlyPay, true);
      storageRackDescription = checkValue(data.listData.storageRackDescription, "");

      powerConnection = checkValue(data.listData.powerConnection, "");
      lighting = checkValue(data.listData.lighting, "");
      buildingClimate = checkValue(data.listData.buildingClimate, "");
      services = checkValue(data.listData.listingData.services, "");
      service_description = checkValue(data.listData.listingData.service_description, "");
      service_price_hour = checkValue(data.listData.listingData.service_price_hour, 0);
      service_price_qm = checkValue(data.listData.listingData.service_price_qm, 0);
      service_price_kg = checkValue(data.listData.listingData.service_price_kg, 0);
      service_price_palette = checkValue(data.listData.listingData.service_price_palette, 0);
      truckDocks = checkValue(data.listData.truckDocks, 0);
      ramps = checkValue(data.listData.ramps, 0);
      gates = checkValue(data.listData.gates, 0);
      doors = checkValue(data.listData.doors, 0);
      const insurable = securityClass && securityClass !== "SK0";

      const servicesMapping = {
        full: "Komplett-Service",
        optional: "Dienstleistung optional",
        self: "selbständiges Be- & Abladen",
        inclusive: "Inkl. Be- & Abladen",
      };

      return (
        <div className={cx(s.Container, s.spaceTop4, "ViewProfile")}>
          <div className={s.containerResponsive}>
            <section className={cx(s.spaceTop5, s.space4)}>
              <Row className={s.landingContainer}>
                {reservationState === "approved" && (
                  <Col xs={12} sm={12} md={9} lg={9}>
                    <h2 className={s.textCenter}>
                      {/* <FormattedMessage {...messages.itinerayTitle} /> {city}! */}
                      Ihre Buchung wurde bestätigt.
                    </h2>
                    <div className={s.textCenter}>
                      <strong>
                      <span>
                        <FormattedMessage {...messages.reservationCode} />
                      </span>
                      <span>&nbsp;</span>
                      <span>{confirmationCode}</span>
                      <span>&nbsp;</span>
                     
                      
                      </strong>
                      {/* <Link to={"/users/bookings/receipt/" + id}>
                        <FormattedMessage {...messages.viewReceipt} />
                      </Link> */}
                    </div>
                    {documents && documents.map((document,index)=> {
                          if(document.type==="receipt" || document.type==="receipt_monthly") {
                            return ( <a style={{display: "block"}} className={s.documentLink} target="blank" href={url + "/downloadPdf?filename=receipt" + "-" + document.transactionId}>
                            <FaRegFilePdf />
                           Rechnung-{document.transactionId}
                          </a>)
                          }
                          if(document.type==="bookingDetails") {
                            return ( <a style={{display: "block"}} className={s.documentLink} target="blank" href={url + "/downloadPdf?filename=bookingDetails" + "-" + document.transactionId}>
                            <FaRegFilePdf />
                           Buchungsdetails-{document.transactionId}
                          </a>)
                          }
                      })}
                  </Col>
                )}
                {reservationState === "pending" && (
                  <div>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Col xs={12} sm={12} md={7} lg={7}>
                        <div className={s.textCenter}>
                          <h2>Vielen Dank! Ihre Buchungsanfrage wurde an den Vermieter versandt. </h2>
                          <span className={s.reservationCode}>
                            <FormattedMessage {...messages.reservationCode} />:
                      &nbsp;
                          {confirmationCode}</span>
                          <FaCheckCircle className={s.circleIcon} />
                          <h4>Wir informieren Sie per Email über den weiteren Ablauf sobald der Vermieter Ihre Anfrage bearbeitet hat. </h4>
                          <Button className={cx(s.dashboardButton, s.button, s.btnPrimary, s.btnlarge)} onClick={() => history.push("/dashboard")}>
                            Zum Dashboard
                          </Button>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5}>
                        <Row>
                          <div className={cx(s.imgContainer)}>
                            <div className={cx(s.parent)}>
                              <div className={cx(s.children)}>
                                <div className={cx(s.content)}>
                                  <Link to={"/spaces/" + listId}>
                                    <ListCoverPhoto
                                      className={cx(s.imageContent)}
                                      coverPhoto={coverPhoto}
                                      listPhotos={listPhotos}
                                      photoType={"x_medium"}
                                      bgImage
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <h1 className={cx(s.spaceTop2, s.space1, s.textWidth)}>
                            <Link to={"/spaces/" + listId} className={s.titleText}>
                              {title}
                            </Link>
                          </h1>
                          <div className={cx(s.space1)}>
                            <a className={s.textMuted}>
                              {city}, {state}, {country}
                            </a>
                          </div>
                          <div className={cx(s.space5)}>
                            <span>
                              <StarRating value={starRatingValue} name={"Itinerary"} className={s.starReview} />
                            </span>
                            <span className={s.textMuted}>
                              &nbsp;{reviewsCount} {reviewsCount > 1  || reviewsCount === 0 ? formatMessage(messages.reviews) : formatMessage(messages.review)}
                            </span>
                          </div>
                        </Row>
                      </Col>
                    </Col>
                  </div>
                )}

                {reservationState === "notpayed" && (
                  <div>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Col xs={12} sm={12} md={7} lg={7}>
                        <div className={s.textCenter}>
                          <h2>Vielen Dank! Wir warten auf den Eingang Ihrer Zahlung. </h2>
                          <span>
                            <FormattedMessage {...messages.reservationCode} />
                          </span>
                          <span>&nbsp;</span>
                          <span>{confirmationCode}</span>
                          <FaCheckCircle className={s.circleIcon} />
                          <h4>Bitte nehmen Sie eine manuelle Banküberweisung vor, mit folgenden Zahlungsdaten:</h4>
                          <div className="banktransferInfo"></div>
                          <Button className={cx(s.dashboardButton, s.button, s.btnPrimary, s.btnlarge)} onClick={() => history.push("/dashboard")}>
                            Zum Dashboard
                          </Button>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5}>
                        <Row>
                          <div className={cx(s.imgContainer)}>
                            <div className={cx(s.parent)}>
                              <div className={cx(s.children)}>
                                <div className={cx(s.content)}>
                                  <Link to={"/spaces/" + listId}>
                                    <ListCoverPhoto
                                      className={cx(s.imageContent)}
                                      coverPhoto={coverPhoto}
                                      listPhotos={listPhotos}
                                      photoType={"x_medium"}
                                      bgImage
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <h1 className={cx(s.spaceTop2, s.space1, s.textWidth)}>
                            <Link to={"/spaces/" + listId} className={s.titleText}>
                              {title}
                            </Link>
                          </h1>
                          <div className={cx(s.space1)}>
                            <a className={s.textMuted}>
                              {city}, {state}, {country}
                            </a>
                          </div>
                          <div className={cx(s.space5)}>
                            <span>
                              <StarRating value={starRatingValue} name={"Itinerary"} className={s.starReview} />
                            </span>
                            <span className={s.textMuted}>
                              &nbsp;{reviewsCount} {reviewsCount > 1  || reviewsCount === 0 ? formatMessage(messages.reviews) : formatMessage(messages.review)}
                            </span>
                          </div>
                        </Row>
                      </Col>
                    </Col>
                  </div>
                )}

                {reservationState === "waiting" && (
                  <div>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Col xs={12} sm={12} md={7} lg={7}>
                        <div className={s.textCenter}>
                          <h2>Vielen Dank! Wir warten auf den Eingang Ihrer Zahlung. </h2>
                          <p>Bitte führe eine manuelle SEPA-Überweisung aus um die Buchung abzuschließen</p>
                          <span>
                            Zahlungsempfänger: <b>{mangopayBankInfo.BankAccount.OwnerName}</b>
                            <br />
                            IBAN: <b>{mangopayBankInfo.BankAccount.IBAN}</b>
                            <br />
                            BIC: <b>{mangopayBankInfo.BankAccount.BIC}</b>
                            <br />
                            Referenznummer: <b>{mangopayBankInfo.WireReference}</b>
                            <br />
                            Betrag: <b>{mangopayBankInfo.DeclaredDebitedFunds.Amount / 100} €</b>
                            <br />
                          </span>
                          <span>
                            <FormattedMessage {...messages.reservationCode} />:
                          </span>
                          <span>&nbsp;</span>
                          <span>
                            {" "}
                            <b>{confirmationCode} </b>
                          </span>
                          <FaCheckCircle className={s.circleIcon} />
                          <Button className={cx(s.dashboardButton, s.button, s.btnPrimary, s.btnlarge)} onClick={() => history.push("/dashboard")}>
                            Zum Dashboard
                          </Button>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={5} lg={5}>
                        <Row>
                          <div className={cx(s.imgContainer)}>
                            <div className={cx(s.parent)}>
                              <div className={cx(s.children)}>
                                <div className={cx(s.content)}>
                                  <Link to={"/spaces/" + listId}>
                                    <ListCoverPhoto
                                      className={cx(s.imageContent)}
                                      coverPhoto={coverPhoto}
                                      listPhotos={listPhotos}
                                      photoType={"x_medium"}
                                      bgImage
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <h1 className={cx(s.spaceTop2, s.space1, s.textWidth)}>
                            <Link to={"/spaces/" + listId} className={s.titleText}>
                              {title}
                            </Link>
                          </h1>
                          <div className={cx(s.space1)}>
                            <a className={s.textMuted}>
                              {city}, {state}, {country}
                            </a>
                          </div>
                          <div className={cx(s.space5)}>
                            <span>
                              <StarRating value={starRatingValue} name={"Itinerary"} className={s.starReview} />
                            </span>
                            <span className={s.textMuted}>
                              &nbsp;{reviewsCount} {reviewsCount > 1  || reviewsCount === 0 ? formatMessage(messages.reviews) : formatMessage(messages.review)}
                            </span>
                          </div>
                        </Row>
                      </Col>
                    </Col>
                  </div>
                )}
              </Row>
            </section>
            {reservationState === "approved" && (
              <Row>
                <Col md={7} lg={7}>
                  <Panel className={s.cardPanel}>
                    <div className={s.paneBody}>
                      <Row className={cx(s.rowTable, s.dateRange)}>
                        <div className={s.hideSm}>
                          <Col sm={3} md={3} lg={3}>
                            <span className={s.textBold}>
                              <FormattedMessage {...messages.checkIn} />
                            </span>
                          </Col>
                          <Col sm={3} md={3} lg={3}>
                            <span>{checkInDate}</span>
                          </Col>
                          <Col sm={3} md={3} lg={3}>
                            <span className={s.textBold}>
                              <FormattedMessage {...messages.checkOut} />
                            </span>
                          </Col>
                          <Col sm={3} md={3} lg={3}>
                            <span>{checkOutDate}</span>
                            <br />
                          </Col>
                        </div>
                        <div className={cx(s.hideSm, s.mt20)}>
                          <Col sm={3} md={3} lg={3}>
                            <span className={s.textBold}>Übergabezeit</span>
                          </Col>
                          <Col sm={9} md={9} lg={9}>
                            <span>{checkInTimeFormat}</span>
                          </Col>
                        </div>
                        <div className={cx(s.textCenter, s.showSm)}>
                          <Col sm={6} xs={6}>
                            <span className={s.textBold}>
                              <FormattedMessage {...messages.checkIn} />
                            </span>
                            <br />
                            <span>{checkInDate}</span>
                          </Col>
                          <Col sm={6} xs={6}>
                            <span className={s.textBold}>
                              <FormattedMessage {...messages.checkOut} />
                            </span>
                            <br />
                            <span>{checkOutDate}</span>
                            <br />
                          </Col>
                        </div>
                        <div className={cx(s.textCenter, s.showSm)}>
                          <Col sm={6} xs={6} className={s.mt20}>
                            <span className={s.textBold}>Übergabezeit</span>
                            <br />
                            <span>{checkInTimeFormat}</span>
                          </Col>
                        </div>
                      </Row>
                      <hr />
                    </div>
                    <div className={cx(s.textCenter, s.panelBody)}>
                      <Row className={cx(s.rowTable)}>
                        <Col md={3} lg={3} className={s.space1}>
                          <span className={s.textBold}>
                            <FormattedMessage {...messages.address} />
                          </span>
                        </Col>
                        <Col md={9} lg={9}>
                          <div>
                            <span>{street}</span> <br />
                            <span>
                              {city}, {state} {zipcode}
                            </span>{" "}
                            <br />
                            <span>{country}</span> <br />
                          </div>
                          <div className={s.spaceTop2}>
                            <a href={"/spaces/" + listId} target={"_blank"}>
                              {/* <Link to={"/spaces/" + listId}>  */}
                              <FormattedMessage {...messages.viewListing} />
                              {/* </Link> */}
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                    <div className={cx(s.textCenter, s.panelBody)}>
                      <Row className={cx(s.rowTable)}>
                        <Col md={3} lg={3} className={s.space1}>
                          <span className={s.textBold}>Anbieter</span>
                        </Col>
                        <Col md={3} lg={3} className={s.showSm}>
                          <div className={cx(s.profileAvatarSection, s.profileAvatarLink)}>
                            <Avatar source={picture} height={115} width={115} className={s.profileAvatar} withLink profileId={profileId} />
                          </div>
                        </Col>
                        {!isHost && (
                          <Col md={6} lg={6}>
                            <span>{displayName}</span> <br />
                            <div className={s.spaceTop1}>
                              <Link to={"/message/" + messageData.id + "/guest"}>
                                <FormattedMessage {...messages.messageHost} />
                              </Link>
                            </div>
                          </Col>
                        )}
                        <Col md={3} lg={3} className={s.hideSm}>
                          <div className={cx(s.profileAvatarSection, s.profileAvatarLink)}>
                            <Avatar source={picture} height={115} width={115} className={s.profileAvatar} withLink profileId={profileId} />
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                    <div className={cx(s.textCenter, s.panelBody)}>
                      <Row className={cx(s.rowTable)}>
                        <Col md={3} lg={3}>
                          <span className={s.textBold}>
                            <FormattedMessage {...messages.billing} />
                          </span>
                        </Col>
                        <Col md={9} lg={9}>
                          {dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                          {"   "} <CurrencyConverter className={s.ml20} amount={subTotal} from={currency} />
                        </Col>
                      </Row>
                      <hr />
                    </div>
                    <div className={cx(s.textCenter, s.panelBody)}>
                      <Row className={cx(s.rowTable)}>
                        <Col md={3} lg={3}>
                          <span className={s.textBold}>Beschreibung:</span>
                        </Col>
                        <Col md={9} lg={9}>
                          <table className={cx("table")}>
                            <tbody>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>{description} </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                    {services && (
                      <div className={cx(s.textCenter, s.panelBody)}>
                        <Row className={cx(s.rowTable)}>
                          <Col md={3} lg={3}>
                            <span className={s.textBold}>Dienstleistungen:</span>
                          </Col>
                          <Col md={9} lg={9}>
                            <table className={cx("table")}>
                              <tbody>
                                <tr className={s.tableText}>
                                  <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                    {servicesMapping[services]}
                                    <br />{" "}
                                    {(services === "optional" || services === "full") && (
                                      <div className={s.serviceDetails}>
                                        Zzgl. Dienstleistung vor Ort
                                        <br />
                                        {service_price_hour > 0 && (
                                          <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                                            <div className={s.serviceDetailsWrapper}>
                                              <span className={s.ServiceCurrency}>
                                                <CurrencyConverter amount={service_price_hour} from={currency} />
                                              </span>
                                              <span className={s.ServiceUnit}> pro Stunde</span>
                                            </div>
                                          </Col>
                                        )}
                                        {service_price_kg > 0 && (
                                          <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                                            <div className={s.serviceDetailsWrapper}>
                                              <span className={s.ServiceCurrency}>
                                                <CurrencyConverter amount={service_price_kg} from={currency} />
                                              </span>
                                              <span className={s.ServiceUnit}> pro kg</span>
                                            </div>
                                          </Col>
                                        )}
                                        {service_price_qm > 0 && (
                                          <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                                            <div className={s.serviceDetailsWrapper}>
                                              <span className={s.ServiceCurrency}>
                                                <CurrencyConverter amount={service_price_qm} from={currency} />
                                              </span>
                                              <span className={s.ServiceUnit}> pro m²</span>
                                            </div>
                                          </Col>
                                        )}
                                        {service_price_palette > 0 && (
                                          <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                                            <div className={s.serviceDetailsWrapper}>
                                              <span className={s.ServiceCurrency}>
                                                <CurrencyConverter amount={service_price_palette} from={currency} />
                                              </span>
                                              <span className={s.ServiceUnit}> pro Palette</span>
                                            </div>
                                          </Col>
                                        )}
                                        {service_description && (
                                          <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                                            <div>{service_description}</div>
                                          </Col>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    )}

                    <div className={cx(s.textCenter, s.panelBody)}>
                      <Row className={cx(s.rowTable)}>
                        <Col md={3} lg={3} sm={12} xs={12}>
                          <span className={s.textBold}>
                            <FormattedMessage {...messages.theListing} />
                          </span>
                        </Col>
                        <Col md={9} lg={9} sm={12} xs={12}>
                          <table className={cx("table", "hidden-xs", s.tableListing)}>
                            <tbody>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.storageSpace} />: <strong>{personCapacity}m²</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.flooring} />: <strong>{flooring}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.buildingType} />: <strong>{buildingType}</strong>{" "}
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.buildingType2} />: <strong>{buildingType2}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.buildingCondition} />: <strong>{buildingCondition}</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.floorCount} />: <strong>{floorCount}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.floorLoading} />: <strong>{floorLoading}</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.floorHeight} />: <strong>{floorHeight}m</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.powerConnection} />: <strong>{powerConnection}</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.lighting} />: <strong>{lighting}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.buildingClimate} />: <strong>{buildingClimate}</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.truckDocks} />: <strong>{truckDocks}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.ramps} />: <strong>{ramps}</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.gates} />: <strong>{gates}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.doors} />: <strong>{doors}</strong>
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.storageRack2} />: <strong>{storageRack ? "vorhanden" : "nicht vorhanden"}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  {storageRackDescription && (
                                    <div>
                                      <FormattedMessage {...messages.storageRackDescription} />: <strong>{storageRackDescription}</strong>
                                    </div>
                                  )}
                                </td>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  Versicherbar: <strong>{insurable ? "Ja" : "Nein"}</strong>{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className={cx("table", "hidden-lg", s.tableListing)}>
                            <tbody>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.storageSpace} />: <strong>{personCapacity}m²</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.flooring} />: <strong>{flooring}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.buildingType} />: <strong>{buildingType}</strong>{" "}
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.buildingType2} />: <strong>{buildingType2}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.buildingCondition} />: <strong>{buildingCondition}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.floorCount} />: <strong>{floorCount}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.floorLoading} />: <strong>{floorLoading}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.floorHeight} />: <strong>{floorHeight}m</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.powerConnection} />: <strong>{powerConnection}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.lighting} />: <strong>{lighting}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.buildingClimate} />: <strong>{buildingClimate}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.truckDocks} />: <strong>{truckDocks}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.ramps} />: <strong>{ramps}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.gates} />: <strong>{gates}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  <FormattedMessage {...messages.doors} />: <strong>{doors}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  <FormattedMessage {...messages.storageRack2} />: <strong>{storageRack ? "vorhanden" : "nicht vorhanden"}</strong>
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding, s.tableWidth)}>
                                  {storageRackDescription && (
                                    <div>
                                      <FormattedMessage {...messages.storageRackDescription} />:{" "}
                                      <strong style={{ display: "block" }}>{storageRackDescription}</strong>
                                    </div>
                                  )}
                                </td>
                              </tr>
                              <tr className={s.tableText}>
                                <td className={cx(s.noBorder, s.noPadding)}>
                                  Versicherbar: <strong>{insurable ? "Ja" : "Nein"}</strong>{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                </Col>
                <Col lg={5} md={5}>
                  <div className={cx(s.imgContainer)}>
                    <div className={cx(s.parent)}>
                      <div className={cx(s.children)}>
                        <div className={cx(s.content)}>
                          <Link to={"/spaces/" + listId}>
                            <ListCoverPhoto className={cx(s.imageContent)} coverPhoto={coverPhoto} listPhotos={listPhotos} photoType={"x_medium"} bgImage />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={5}>
                  <h1 className={cx(s.spaceTop2, s.space1, s.textWidth)}>
                    <Link to={"/spaces/" + listId} className={s.titleText}>
                      {title}
                    </Link>
                  </h1>
                  <div className={cx(s.space1)}>
                    <a className={s.textMuted}>
                      {city}, {state}, {country}
                    </a>
                  </div>
                  <div className={cx(s.space5)}>
                    <span>
                      <StarRating value={starRatingValue} name={"Itinerary"} className={s.starReview} />
                    </span>
                    <span className={s.textMuted}>
                      &nbsp;{reviewsCount} {reviewsCount > 1  || reviewsCount === 0 ? formatMessage(messages.reviews) : formatMessage(messages.review)}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => ({
  userId: state.account.data.userId,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Itinerary)));

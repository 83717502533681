import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url, sitename } from '../../../config';

class ListingApprovedHost extends Component {
	static propTypes = {
		content: PropTypes.shape({
			hostName: PropTypes.string.isRequired,
			listTitle: PropTypes.string.isRequired,
		}).isRequired
	};

	render() {
		const textStyle = {
			color: '#404040',
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px',
		};

		const linkText = {
			color: '#0667B3',
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}


		const { content: { guestName, hostName, listTitle, listCity, threadId, logo } } = this.props;
		let contactURL = url + '/message/' + threadId + '/guest';

		return (
			<Layout>
				<Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
				<div>
					<Table width="100%" >
						<TBody>
							<TR>
								<TD style={textStyle}>
									<EmptySpace height={20} />
									<div>
										Hallo {hostName},
					        </div>
									<EmptySpace height={20} />
									<div>
										Ihr Inserat {listTitle} wurde erfolgreich geprüft und genehmigt.
										Ihre Fläche ist jetzt für alle Nutzer unserer Plattform sichtbar und kann gebucht werden.
									</div>

									<EmptySpace height={20} />
									<div>
										Vielen Dank, <br />
										Ihr {sitename} Team
					        </div>
								</TD>
							</TR>
						</TBody>
					</Table>
					<EmptySpace height={40} />
				</div>
				<Footer />
				<EmptySpace height={20} />
			</Layout>
		);
	}
}

export default ListingApprovedHost;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1QzGE{padding-top:100px;padding-bottom:100px;color:#000}._1RcdK button:before{background:#c5cad1!important}._1RcdK li._3ss26 button:before{background:#000!important}._1RcdK ._2-6pT li,._1RcdK button,._1RcdK li._3ss26 button:before,._2-6pT li button{width:20px!important;height:20px!important;line-height:20px!important}._1EkXM{position:absolute;top:40px;background-color:#0667b3;left:40px;width:40px;height:5px}._3pHXf{box-shadow:10px 10px 20px hsla(0,0%,58%,.25);border-radius:4px;padding:50px;width:98%;background:#f6f9fc;margin-bottom:10px}._1FTuI{text-transform:uppercase;letter-spacing:4px;font-size:15px;color:#0667b3}._2ZeOC{background-color:#d4ebfd;padding:40px;position:relative;min-height:363px}.I3DVp{padding:20px;font-weight:300;font-size:22px;padding-left:0}._3NVNH,.I3DVp{line-height:32px}._3NVNH{font-weight:700;font-size:14px}.Dw999{color:#000;font-size:50px;margin-bottom:100px}._2_nw7{color:#000;font-size:28px;margin-top:10px;margin-bottom:50px}.bEMyb{color:#0667b3;font-size:12px}._1bWRr{display:block}.QPHs9{color:#656565;font-size:14px;margin-bottom:20px}.QPHs9 img{display:inline-block;vertical-align:-5px;margin-right:20px}._1vRBA{position:absolute;bottom:-20px;right:-20px}._1vRBA img{width:100px}@media screen and (max-width:767px){._2ZeOC{padding-left:20px;padding-right:20px}}", ""]);

// exports
exports.locals = {
	"content": "_1QzGE",
	"dot": "_1RcdK",
	"slick-active": "_3ss26",
	"slick-dots": "_2-6pT",
	"blueLine": "_1EkXM",
	"itemWrapper": "_3pHXf",
	"subHeadline": "_1FTuI",
	"citiationWrapper": "_2ZeOC",
	"citiationText": "I3DVp",
	"citiationAuthor": "_3NVNH",
	"citiationLink": "_2U23y",
	"headline": "Dw999",
	"itemHeadline": "_2_nw7",
	"itemSubHeadline": "bEMyb",
	"checkboxItems": "_1bWRr",
	"checkboxItem": "QPHs9",
	"authorImage": "_1vRBA"
};
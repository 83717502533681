// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Style
import { ProgressBar } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./TabBarStep.css";

// Locale
import messages from "../../locale/messages";

class TabBarStep extends Component {
  static propTypes = {
    nextPage: PropTypes.any,
    formatMessage: PropTypes.any,
  };

  render() {
    const { nextPage, completed, progress, pages, currentPage } = this.props;
    const { formatMessage } = this.props.intl;
    const progressLength = 100 / pages.length;
    const progressWidth = progressLength + "%";
    let actualProgress = (100 / pages.length) * (pages.indexOf(currentPage) + 1);
    return (
      <div>
        {completed ? (
          <div className={cx(s.progressContainer, "hidden-xs")}>
            {pages.map((page, key) => (
              <a className={s.linkReset} onClick={() => nextPage(page)} href="javascript:void(0);">
                <div style={{ width: progressWidth }} className={cx(s.textTrunck, s.progressStep, s.progressSection, s.progressStyle)} title={page}>
                  {page}
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div className={cx(s.progressContainer, "hidden-xs")}>
            {pages.map((page, key) => (
              <div style={{ width: progressWidth }} className={cx(s.textTrunck, s.progressStep, s.progressSection, s.progressStyle, s.noPointer)} title={page}>
                {page}
              </div>
            ))}
          </div>
        )}
        <div>
          <ProgressBar now={actualProgress} className={s.leanProgress} />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(TabBarStep));

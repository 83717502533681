import s2 from "!isomorphic-style-loader!css-loader!react-phone-input-2/lib/style.css";
import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Form, FormControl, Modal, Panel, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
// External component
import PhoneInput from "react-phone-input-2";
// Redux
import { connect } from "react-redux";
// Redux form
import { change, Field, formValueSelector, reduxForm } from "redux-form";
// Locale
import messages from "../../../../locale/messages";
// Component
import Loader from "../../../Loader/Loader";
import s from "./ContactAgent.css";
import submit from "./submit";
// Helper
import validate from "./validate";
import { Event } from "../../../Tracking/GA"

class ContactAgent extends React.Component {
  static propTypes = {
    showContactHostModal: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    picture: PropTypes.string,
    profileId: PropTypes.number.isRequired,
    personCapacity: PropTypes.number.isRequired,
    minNight: PropTypes.number,
    maxNight: PropTypes.number,
    maxDaysNotice: PropTypes.string,
    blockedDates: PropTypes.array,
    availability: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    contactHostClose: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
    maximumStay: PropTypes.bool,
  };

  static defaultProps = {
    showContactHostModal: false,
    id: 0,
    userId: null,
    profileId: 0,
    city: null,
    displayName: null,
    picture: null,
    personCapacity: 0,
    minNight: 0,
    maxNight: 0,
    blockedDates: [],
    availability: false,
    startDate: null,
    endDate: null,
    maximumStay: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      phonenumber: "",
    };

    this.renderFormControlTextArea = this.renderFormControlTextArea.bind(this);
    this.renderWarningBlock = this.renderWarningBlock.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
  }

  renderFormControlTextArea({ input, label, meta: { touched, error }, children, className, placeholder }) {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} className={className} componentClass="textarea" placeholder={placeholder}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  renderFormControlEmail = ({ input, label, type, meta: { touched, error }, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={disabled} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };
  renderWarningBlock(content, error) {
    let bgClass;
    if (error) {
      bgClass = s.alertBlockError;
    } else {
      bgClass = s.alertBlockSuccess;
    }
    return (
      <div className={cx(s.alertBlock, bgClass, s.space4)}>
        <div>
          <div className={s.messageText}>{content}</div>
        </div>
      </div>
    );
  }

  renderFormControlPhone = ({ input, label, type, meta: { touched, error }, className, country }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.margintop15}>
        <PhoneInput {...input} country={"de"} />
        {/* <InputGroup>
          <InputGroup.Addon className={s.addonStyle}>{country}</InputGroup.Addon>
          <FormControl {...input} placeholder={label} type={type} className={className} />
        </InputGroup> */}
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  handleChangePhone = (phoneNumber) => {
    const { change } = this.props;
    change("phoneNumber", phoneNumber);
    this.setState({ phoneNumber });
  };

  handleForm = (values, dispatch) => {
    const { change } = this.props;

    Event("lead", "fläche_besichtigen", values.email);

    this.props.toggleManualRequest();
    submit(values, dispatch);
  };

  async onSliderChange(value) {
    const { change } = this.props;
    this.setState(
      {
        sliderValue: value,
      },
      () => {
        console.log(this.state.sliderValue);
      }
    );
  }

  componentDidMount() {
    const { change, initialValues } = this.props;
    change("listId", this.props.id);
    change("hostName", this.props.hostProfile.displayName);
    change("hostCompanyName", this.props.hostProfile.companyName);
    change("hostId", this.props.hostProfile.profileId);
    change("listingTitle", this.props.listingTitle);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { showManualRequestModal, toggleManualRequest } = this.props;
    const { id, personCapacity, minNight, maxNight, maxDaysNotice, blockedDates } = this.props;
    const { userId, profileId, picture, displayName, city, title, listingSize } = this.props;
    const { availability, startDate, endDate, isLoading, maximumStay } = this.props;
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { phoneNumber } = this.state;

    //let loadingStatus = loading || isLoading || false;
    let loadingStatus = isLoading || false;

    return (
      <div className={s.root}>
        <Modal show={showManualRequestModal} onHide={toggleManualRequest} animation={false} className={cx(s.modalContainer, "ContactHost")}>
          <div className={cx(s.modalTable)}>
            <div className={cx(s.modalCell)}>
              <Modal.Header className={s.modalHeading} closeButton>
                <Modal.Title>Sie haben Fragen? - wir helfen gerne.</Modal.Title>
              </Modal.Header>
              <Modal.Body bsClass={s.logInModalBody}>
                <div className={s.modalDark}>
                  <Row className={s.noMargin}>
                    <Col md={12} className={s.noPadding}>
                      <Loader show={loadingStatus} type={"page"}>
                        <Panel className={cx(s.guestModal, "guestpanel")}>
                          <div className={s.panelBody}>
                            {/* <div className={cx(s.textCenter, s.space2)}>
                              <h3>{title}</h3>
                              <div className={cx(s.mediaPhoto, s.mediaRound)}>
                                <div className={s.mediaBadge}>
                                  <img src={"/images/upload/small_" + picture} className={cx(s.imgBackground, s.profileAvatar)} height={120} width={120} />
                                  {/* <Avatar
                                source={picture}
                              
                                title={displayName}
                                className={s.profileAvatar}
                                withLink
                                linkClassName={cx(s.mediaPhoto, s.mediaRound)}
                                profileId={profileId}
                              /> 
                                </div>
                              </div>
                            </div> */}
                            <h3 className={s.listTitle}>Schreiben Sie uns eine Nachricht und wir melden uns bei Ihnen.</h3>
                            <div className={s.space4}>
                              <Row>
                                <Form
                                  onSubmit={handleSubmit((values, dispatch) => {
                                    this.handleForm(values, dispatch);
                                  })}
                                >
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Ihr Name</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        name="name"
                                        type="text"
                                        component={this.renderFormControlEmail}
                                        label={"Name"}
                                        className={cx(s.formControlInput, s.commonBorder)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Ihre Email-Adresse</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        name="email"
                                        type="text"
                                        component={this.renderFormControlEmail}
                                        label={formatMessage(messages.email)}
                                        className={cx(s.formControlInput, s.commonBorder)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Ihre Telefonnummer (optional)</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        input={{
                                          name: "phoneNumber",
                                          onChange: this.handleChangePhone,
                                          value: phoneNumber,
                                        }}
                                        name="phoneNumber"
                                        type="text"
                                        component={this.renderFormControlPhone}
                                        label={formatMessage(messages.phoneNumber)}
                                        className={s.formControlInput}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <h3 className={s.listTitle}>Teilen Sie uns Ihre Anfrage mit</h3>

                                    <div className={s.messagePanel}>
                                      <Field
                                        name="content"
                                        component={this.renderFormControlTextArea}
                                        className={s.textBox}
                                        placeholder={formatMessage(messages.textBoxMessage)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} sm={12} xs={12} className={s.spaceTop2}>
                                    <Button className={cx(s.btn, s.btnPrimary)} type="submit" disabled={submitting}>
                                      <FormattedMessage {...messages.sendMessage} />
                                    </Button>
                                  </Col>
                                </Form>
                              </Row>
                            </div>
                          </div>
                        </Panel>
                      </Loader>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ContactAgent = reduxForm({
  form: "ContactAgentForm", // a unique name for this form
  validate,
})(ContactAgent);

// Decorate with connect to read form values
const selector = formValueSelector("ContactAgentForm"); // <-- same as form name

const mapState = (state) => ({
  isLoading: state.viewListing.isLoading,
  // showContactHostModal: state.viewListing.showContactHostModal,
});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s, s2)(connect(mapState, mapDispatch)(ContactAgent)));

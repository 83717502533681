import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payment.css";
// Locale
import messages from "../../../locale/messages";
class RequestInfo extends Component {
  static propTypes = {
    hostDisplayName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };
  //TODO Translate
  render() {
    const { hostDisplayName, houseRules, isInsurable } = this.props;

    return (
      <div className={s.houseRulesPanel}>
        <div className={s.requestInfo}>
          <h3>Ihre Buchung wird vom Vermieter zuerst geprüft und manuell freigegeben</h3>
          <p>Die Anfrage ist kostenfrei und unverbindlich</p>
          <p>
            Bei Bestätigung der Anfrage durch den Vermieter, bekommen Sie eine Email mit Anweisungen um den Zahlungsprozess und somit die Buchung abzuschließen
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(RequestInfo);

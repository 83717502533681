exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._19s79{background-color:#404040;width:64px;display:block;height:1.5px;margin:0 auto;margin-bottom:27px}.hxLM1{height:2.5px;border:1px solid #404040;background-color:transparent}._12Kb_{margin-bottom:137px}._3WtG_ h1{font-size:60px;line-height:40px;text-align:center;font-weight:700;margin-bottom:75px}._1qQNk ._2dT4R{background-color:#0667b2;display:-ms-flexbox;display:flex;border-radius:29px;width:50px;height:50px;text-align:center;margin-bottom:20px}._1qQNk ._2dT4R span{display:block;margin:auto;color:#fff;font-weight:700;font-size:17px}._1qQNk a{font-size:17px}h4._3jdRP{font-size:24px;font-weight:800;margin-bottom:20px}p._3jdRP{font-size:16px;line-height:1.7;font-weight:400}@media screen and (max-width:991px){h4._3jdRP{font-size:23px;font-weight:600}._3Pv5-{margin-bottom:35px;padding-right:0;padding-left:0}._3WtG_ h1{font-size:36px;line-height:51px;margin-bottom:13px}._1qQNk{margin-top:44px}._12Kb_{margin-bottom:85px}}@media screen and (max-width:767px){._3WtG_ h1{font-size:24px;line-height:30px;margin-bottom:20px;text-align:left;padding-left:15px;padding-right:15px}._1qQNk{margin-top:24px}h4._3jdRP{font-size:20px}._12Kb_{margin-bottom:45px}p._3jdRP{font-size:14px;line-height:1.7;font-weight:400}}", ""]);

// exports
exports.locals = {
	"seperator": "_19s79",
	"boxseperator": "hxLM1",
	"hostingsection": "_12Kb_",
	"mainhedding": "_3WtG_",
	"steps": "_1qQNk",
	"circle": "_2dT4R",
	"common": "_3jdRP",
	"whyblock": "_3Pv5-"
};
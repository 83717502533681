import { gql } from 'react-apollo';
// Send Email
import { sendEmail } from '../../core/email/sendEmail';
import {
  CONTACT_AGENT_START,
  CONTACT_AGENT_SUCCESS,
  CONTACT_AGENT_ERROR,
} from '../../constants';
import { toastr } from 'react-redux-toastr';
import {Event} from '../../components/Tracking/GA'

import { adminEmail } from '../../config';
export function contactAgent(
  listId,
  listingTitle,
  content,
  email,
  name,
  phoneNumber,
  hostName,
  hostCompanyName,
  hostId
) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: CONTACT_AGENT_START,
    });

    try {

      let emailContent = {
        listId,
        listingTitle,
        content,
        email,
        name,
        phoneNumber,
        hostName,
        hostCompanyName,
        hostId
      }

      let response = await sendEmail(adminEmail, 'contactAgent', emailContent);
      if (response && response.status === 200) {
        toastr.success("Anfrage versendet", "Wir haben Ihre Nachricht erhalten");
          
        dispatch({
          type: CONTACT_AGENT_SUCCESS,
        });

        Event("expozed", "contact_agent", email)
      }

    } catch (error) {
      console.log(error)
      toastr.error("Error", "Es ist ein Fehler beim übermitteln Ihrer Nachricht aufgetreten");
      dispatch({
        type: CONTACT_AGENT_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}
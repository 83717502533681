import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url, sitename } from "../../../config";
import moment from "moment";
import { checkInData, checkInFormat } from "../../../helpers/timeHelper";

class BookingConfirmationGuest extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      listTitle: PropTypes.string.isRequired,
      listCity: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: "#404040",
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "10px",
      textAlign: "center",
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "bold",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: "#0667B3",
      backgroundColor: "#0667B3",
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const bookingTitle = {
      paddingBottom: "25px",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "36px",
      margin: "0",
      padding: "0",
      textAlign: "center",
    };

    const profilePic = {
      borderRadius: "999px",
      margin: "0",
      padding: "0",
      lineHeight: "150%",
      borderSpacing: "0",
      width: "125px",
    };

    const userName = {
      color: "#565a5c",
      fontSize: "26px",
      fontWeight: "bold",
      paddingBottom: "5px",
    };

    const subTitle = {
      color: "#565a5c",
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: "5px",
    };

    const linkText = {
      color: "#0667B3",
      fontSize: "18px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const space = {
      paddingBottom: "20px",
    };

    moment.locale("de-DE");
    const {
      content: { guestName, hostName, listTitle, listCity, threadId, logo, reservationId, isManualTransfer },
    } = this.props;
    let contactURL = url + "/message/" + threadId + "/guest";
    const {
      content: { checkIn, checkOut, guests, allowedCheckInTime, allowedCheckOutTime, confirmationCode },
    } = this.props;
    let itineraryURL = url + "/users/bookings/itinerary/" + reservationId;
    let checkInDate = checkIn != null ? moment(checkIn).format("ddd, Do MMM, YYYY") : "";
    let checkOutDate = checkOut != null ? moment(checkOut).format("ddd, Do MMM, YYYY") : "";
    let checkInDateShort = checkIn != null ? moment(checkIn).format("Do MMMM") : "";
    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <h1 style={bookingTitle}>
                    Ihre Buchung war erfolgreich
                    <br />
                    <span>Die von Ihnen gewählte Fläche ist ab dem {checkInDateShort} gebucht.</span> <br />
                  </h1>
                  {isManualTransfer && (
                    <div>
                      <EmptySpace height={20} />
                      <div>
                      Ihre ausstehende Zahlung ist bei uns eingegangen und wir haben Ihre Buchung erfolgreich abgeschlossen.
                      </div>
                    </div>
                  )}

                  <EmptySpace height={20} />
                  <div>
                    Herzlichen Glückwunsch! Der Anbieter {hostName} hat Ihre Anfrage für {listTitle} bestätigt. Bitte informieren Sie sich genauer zu den
                    Nutzungsbedingungen des Anbieters und treten Sie mit Ihrem Anbieter in Kontakt, um Details zum Bezug und der Nutzung der Fläche zu
                    besprechen.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    <a style={buttonStyle} href={contactURL}>
                      Anbieter kontaktieren
                    </a>
                  </div>
                  <EmptySpace height={20} />
                </TD>
              </TR>
            </TBody>
          </Table>
          <Table width="100%">
            <TBody>
              <TR style={textStyle}>
                <TD style={space}>
                  <span style={subTitle}>Mietbeginn</span>
                  <br />
                  <EmptySpace height={10} />
                  <span>{checkInDate}</span>
                  <br />
                  <EmptySpace height={1} />
                  <span>Übergabezeit: {checkInFormat(allowedCheckInTime, allowedCheckOutTime)}</span>
                </TD>
                <TD style={space}>
                  <EmptySpace height={10} />
                </TD>
                <TD style={space}>
                  <span style={subTitle}>Mietende</span>
                  <br />
                  <EmptySpace height={10} />
                  <span>{checkOutDate}</span>
                  <br />
                </TD>
              </TR>
              <TR style={textStyle}>
                <TD>
                  <div>
                    <span style={subTitle}>Buchungsnummer</span>
                    <br />
                    <EmptySpace height={10} />
                    <span>{confirmationCode}</span>
                    <EmptySpace height={10} />
                  </div>
                </TD>
              </TR>

              <TR style={textStyle}>
                <TD>
                  <EmptySpace height={10} />
                </TD>
                <TD>
                  <EmptySpace height={10} />
                </TD>
                <TD>
                  <div>
                    <a href={itineraryURL} style={linkText}>
                      Buchungsdetails
                    </a>
                  </div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={50} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingConfirmationGuest;

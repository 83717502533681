exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1gwgu{color:#000;padding-top:100px;padding-bottom:70px}._1n234{padding-right:20px}._3lvVH{font-size:24px;margin-top:0;margin-bottom:5px;color:#000}._3lvVH,.keGKf{font-weight:500}.keGKf{color:#656565;margin-bottom:30px}._3DBKi{padding-right:120px}.CuV9U{display:-ms-flexbox;display:flex;margin-bottom:30px}._2EGUg{padding-top:40px}._2k-Zh{width:150px;margin-top:30px;margin-bottom:30px}.OIAX2{width:50px}._2EGUg img{height:25px;margin-top:15px;margin-left:0}._2imzP{font-size:16px;color:#656565;-ms-flex:1 1;flex:1 1}._1heQK{text-transform:uppercase;letter-spacing:4px;font-size:15px;color:#0667b3}._3bTN6{font-size:50px;margin-top:10px;margin-bottom:30px}", ""]);

// exports
exports.locals = {
	"content": "_1gwgu",
	"item": "_1n234",
	"itemHeadline": "_3lvVH",
	"text": "keGKf",
	"textWrapper": "_3DBKi",
	"itemWrapper": "CuV9U",
	"itemsWrapper": "_2EGUg",
	"image": "_2k-Zh",
	"itemImageWrapper": "OIAX2",
	"itemText": "_2imzP",
	"subHeadline": "_1heQK",
	"headline": "_3bTN6"
};
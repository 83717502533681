import fetch from "../../fetch/fetch.server";
import { url } from "../../../config";
export async function processMangopayPayment(type, data, kycType, files, bankData) {
  let URL;
  let variables = {
    data,
    kycType,
    files,
    bankData,
  };
  if (type === "create-user-with-wallet") {
    URL = url + "/mangopay-create-user-with-wallet";
  } else if (type === "create-kyc-document") {
    URL = url + "/mangopay-create-kyc-document";
  } else if (type === "create-ubo-document") {
    URL = url + "/mangopay-create-ubo-document";
  } else if (type === "create-payment") {
    URL = url + "/mangopay-create-payment";
  } else if (type === "create-payment-monthly") {
    URL = url + "/mangopay-create-payment-monthly";
  } else if (type === "create-payout") {
    URL = url + "/mangopay-create-payout";
  } else if (type === "create-bank-account") {
    URL = url + "/mangopay-create-bank-account";
  } else if (type === "create-bank-mandate") {
    URL = url + "/mangopay-create-bank-mandate";
  } else if (type === "cancel-bank-mandate") {
    URL = url + "/mangopay-cancel-bank-mandate";
  } else if (type === "create-refund") {
    URL = url + "/mangopay-refund";
  } else if (type === "create-reservation") {
    URL = url + "/create-reservation";
  }
  try {
    const resp = await fetch(URL, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(variables),
      credentials: "include",
    });
    const { status, errorMessage, redirect, accountId, paymentIntentSecret, bankAccountId, mandateData } = await resp.json();
    if (status === 200 && redirect) {
      window.location = redirect;
    }
     
  return {
    status,
    errorMessage,
    accountId,
    paymentIntentSecret,
    bankAccountId,
    mandateData,
  };
  } catch(error){
    console.error(error)
  }
 
}

// Fetch request
import fetch from "../../core/fetch";
import history from "../../core/history";
// Redux
import { setRuntimeVariable } from "../../actions/runtime";

import { loadAccount } from "../../actions/account";

import { createUserWithWallet } from "../../actions/mangopay/createUserWithWallet";

// Toaster,,,,
import { toastr } from "react-redux-toastr";

async function submit(values, dispatch, props) {
  const { closeModal } = props;
  const query = `
  query (
    $companyName:String,
    $firstName:String,
    $lastName:String,
    $address1:String,
    $address2:String,
    $city:String,
    $zip:String,
    $taxId:String,
    $companyRegistrationNumber:String,
  	$gender: String,
    $dateOfBirth: String,
    $email: String!,
  	$phoneNumber: String,
  	$preferredLanguage: String,
  	$preferredCurrency: String,
  	$location: String,
    $info: String,
    $loggedinEmail: String,
    $countryCode: String,
    $countryName: String,
    $region: String,
    $companyCountry: String
    $nationality: String
    $profileComplete: Boolean
    $profileCompleteUbo: Boolean
  ) {
      userEditProfile (
        companyName:$companyName,
        firstName:$firstName,
        lastName:$lastName,
        address1:$address1,
        address2:$address2,
        city:$city,
        zip:$zip,
        taxId:$taxId,
        companyRegistrationNumber:$companyRegistrationNumber,
        gender: $gender,
        dateOfBirth: $dateOfBirth,
        email: $email,
        phoneNumber: $phoneNumber,
        preferredLanguage: $preferredLanguage,
        preferredCurrency: $preferredCurrency,
        location: $location,
        info: $info,
        loggedinEmail: $loggedinEmail,
        countryCode: $countryCode,
        countryName: $countryName,
        region: $region,
        companyCountry: $companyCountry
        nationality: $nationality
        profileComplete: $profileComplete
        profileCompleteUbo: $profileCompleteUbo
      ) {
        status
      }
    }
    `;
  const { year, month, day } = values;
  let dateOfBirth = day + "-" + (Number(month) + 1) + "-" + year;
  let companyNameValue = values.companyName ? values.companyName.trim() : values.companyName;
  let firstNameValue = values.firstName ? values.firstName.trim() : values.firstName;
  let lastNameValue = values.lastName ? values.lastName.trim() : values.lastName;
  let address1Value = values.address1 ? values.address1.trim() : values.address1;
  let address2Value = values.address2 ? values.address2.trim() : values.address2;
  let zipValue = values.zip ? values.zip.trim() : values.zip;
  let cityValue = values.city ? values.city.trim() : values.city;
  const ubo = values.ubo;
  let taxIdValue = values.taxId ? values.taxId.trim().toUpperCase().replace(/\s/g, "") : values.taxId.toUpperCase().replace(/\s/g, "");
  let companyRegistrationNumberValue = values.companyRegistrationNumber
    ? values.companyRegistrationNumber.trim().replace(/\s/g, "")
    : values.companyRegistrationNumber.replace(/\s/g, "");
  let phoneNumberValue = values.phoneNumber ? "+" + values.phoneNumber.trim().toUpperCase().replace(/\b0+/g, "") : values.phoneNumber.toUpperCase();
  if (phoneNumberValue.charAt(1) === "+") {
    phoneNumberValue = phoneNumberValue.substring(1);
  }
  let location = values.location ? values.location.trim() : values.location;
  let infoValue = values.info ? values.info.trim() : values.info;
  let loggedinEmailValue = values.loggedinEmail ? values.loggedinEmail.trim().replace(/['"]+/g, "") : values.loggedinEmail.replace(/['"]+/g, "");
  let countryCode = values.phoneDialCode ? values.phoneDialCode : values.dialCodec;
  let region = values.region ? values.region.trim() : values.region;
  let countryName = values.countryName ? values.countryName.trim() : values.countryName;
  let nationality = values.nationality ? values.nationality.trim() : values.nationality;
  let companyCountry = values.companyCountry ? values.companyCountry.trim() : values.companyCountry;

  const userData = {
    companyName: companyNameValue,
    firstName: firstNameValue,
    lastName: lastNameValue,
    address1: address1Value,
    address2: address2Value,
    zip: zipValue,
    city: cityValue,
    taxId: taxIdValue,
    companyRegistrationNumber: companyRegistrationNumberValue,
    gender: values.gender,
    dateOfBirth: dateOfBirth,
    email: values.email,
    preferredLanguage: values.preferredLanguage,
    preferredCurrency: values.preferredCurrency,
    location: location,
    info: infoValue,
    phoneNumber: phoneNumberValue,
    loggedinEmail: loggedinEmailValue,
    countryCode: countryCode,
    countryName: countryName,
    region: region,
    companyCountry: companyCountry,
    nationality: nationality,
    profileComplete: true,
    profileCompleteUbo: ubo,
  };
  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables: userData,
    }),
    credentials: "include",
  });

  const { data } = await resp.json();

  const completeProfile = values.completeProfile;

  // Mangopay create user and wallet
  const { status } = await dispatch(createUserWithWallet(userData));
  //TODO Check status
  if (data.userEditProfile.status == "success" && status == 200) {
    await dispatch(loadAccount());
    toastr.success("", "Ihre Änderungen wurden erfolgreich aktualisiert!");
    if (closeModal) {
      closeModal();
    } else {
      //   //redirect to dashboard
      // history.push("/dashboard");
    }
  } else if (data.userEditProfile.status == "email") {
    //throw new SubmissionError({ _error: messages.emailAlreadyExists });
    toastr.error("Update Profile Failed", "Email already exist, please try another email address!");
  } else if (data.userEditProfile.status == "notLoggedIn") {
    dispatch(
      setRuntimeVariable({
        name: "isAuthenticated",
        value: false,
      })
    );
    toastr.error("Update Profile Failed", "You are not logged in, please login and try again!");
    //throw new SubmissionError({ _error: messages.notLoggedIn });
  } else {
    // throw new SubmissionError({ _error: messages.somethingWentWrong });
    toastr.error("Update Profile Failed", "Sorry, something went wrong! Reload this page and try again!");
  }
}

export default submit;

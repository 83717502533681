exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3IlKR{margin-bottom:10px;margin-top:0;font-weight:600;font-size:70px;line-height:65px}._2Quvc,._3IlKR{text-shadow:#000 1px 1px 1px;color:#fff}._2Quvc{line-height:28px;font-size:30px;opacity:.95}._25Hpy{width:100%;box-shadow:0 4px 4px rgba(0,0,0,.05);padding-top:20px;padding-bottom:20px}._2M_ui,.YkgZb{margin:0 auto;padding-bottom:24px;max-width:1080px;padding:0 15px}.YkgZb{padding-top:150px}._10yrT{text-align:center}._10yrT,._10yrT span{font-size:16px;line-height:24px;color:rgba(64,64,64,.85)}._10yrT span{vertical-align:-4px;margin-left:10px}._2Drxg{width:100%}.r_VBb{display:block}._3staf{display:none}._21WOT{background:linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(" + require("../../../../public/bg_photo_jumbo_home.jpg") + ");background-size:cover;padding-bottom:100px;background-position:50%}._3kQQz{-ms-transform:none;transform:none;top:200px;color:#707070;background:#fff;padding:20px 25px;border-radius:4px;width:100%;box-shadow:1px 5px 10px rgba(0,0,0,.2);z-index:1;margin-bottom:20px}._3eSHw{padding-top:70px}@media screen and (max-width:767px){._10yrT{margin-bottom:10px}._3IlKR{font-size:46px;line-height:55px;color:#fff}._25Hpy{position:static;width:100%}._2M_ui{padding-top:20px}._2Drxg{margin-left:0}._3eSHw{padding-top:70px}._3eSHw,.owB-O{-ms-transform:none;transform:none}.owB-O{padding-top:100px}._3IlKR{margin-top:20px}.r_VBb{display:none}._3staf{display:block}._14QvB{margin-left:5px!important;font-size:14px!important}._10yrT img{height:24px}._10yrT span{font-size:14px}}", ""]);

// exports
exports.locals = {
	"textJumbo": "_3eSHw",
	"jumboHeadline": "_3IlKR",
	"jumboSubHeadline": "_2Quvc",
	"containerWrapper": "_25Hpy",
	"container": "_2M_ui",
	"container2": "YkgZb",
	"imageItem": "_10yrT",
	"fullWidth": "_2Drxg",
	"desktopView": "r_VBb",
	"mobileView": "_3staf",
	"homeBannerSlider": "_21WOT",
	"BookWrap": "_3kQQz",
	"textJumboMobile": "owB-O",
	"FormBookWrap": "_1WvLu",
	"itemText": "_14QvB"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1GAPD{padding-top:56px;margin-bottom:84px}h4._3JEtI{font-size:24px;font-weight:800;margin-bottom:20px}p._3JEtI{font-size:16px;line-height:1.7;font-weight:400}._1GAPD ._1BSK7{padding-right:45px}@media screen and (max-width:991px){._1GAPD{padding-top:45px;margin-bottom:0}h4._3JEtI{font-size:23px;font-weight:600}._1BSK7{margin-bottom:85px;padding-right:0!important;padding-left:0}.lqTJZ h1{font-size:36px;line-height:51px;margin-bottom:13px}}@media (min-width:768px) and (max-width:991px){._1BSK7{padding-right:15px!important}}@media screen and (max-width:767px){._1GAPD{margin-top:0;margin-bottom:0}._3JEtI{font-size:23px;font-weight:600}._1BSK7{margin-bottom:35px;padding-right:0;padding-left:0}.lqTJZ h1{font-size:32px;line-height:51px;margin-bottom:5px;text-align:left;padding-left:15px;padding-right:15px}._234ua{margin-top:24px}h4._3JEtI{font-size:20px}p._3JEtI{font-size:14px;line-height:1.7;font-weight:400}}", ""]);

// exports
exports.locals = {
	"whybnb": "_1GAPD",
	"common": "_3JEtI",
	"whyblock": "_1BSK7",
	"mainhedding": "lqTJZ",
	"steps": "_234ua"
};
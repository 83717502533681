import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox1.css";
import { Grid, Row, Col } from "react-bootstrap";
import Link from "../../Link/Link";
import ArrowDown from "./arrow_down.svg";
class ContentBox1 extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderContent = () => {};
  onChange = (isVisible) => {
    this.setState({ isVisible });
  };

  render() {
    const { items, headline, subHeadline } = this.props;
    const colWidth = 12 / items.length;

    return (
      <Grid className={s.content} fluid>
        <h4 className={s.subHeadline}>{subHeadline}</h4>
        <h2 className={s.headline}>{headline}</h2>
        <Row>
          {items.map((item,index) => {
            return (
              <Col xs={12} sm={12} md={colWidth} lg={colWidth} key={index}>
                <div className={s.item}>
                  <img src={item.icon} />
                  <h5 className={s.itemHeadline}>{item.headline}</h5>
                  <span className={s.itemText}>{item.text}</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s)(ContentBox1);

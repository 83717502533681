exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._38DgY{padding-left:0;padding-right:0}._2vSlo{margin:0 auto;padding:0 0 40px;max-width:100%;max-width:var(--max-container-width);margin-top:1px}._2yIml{max-width:912px;width:100%;display:block;margin:0 auto}.nAppY{font-size:24px;color:#404040;margin:0 0 18px}._1UulS{margin-top:60px;margin-bottom:100px}._1QCgJ{font-size:32px;color:#404040;margin:0 0 30px}._2o-Td{font-size:14px;color:#bbb;font-weight:700;text-transform:uppercase;margin:0 0 6px;display:block}", ""]);

// exports
exports.locals = {
	"root": "_38DgY",
	"container": "_2vSlo",
	"landingContainer": "_2yIml",
	"landingContentTitle": "nAppY",
	"landingContent": "_1UulS",
	"landingTitle": "_1QCgJ",
	"landingStep": "_2o-Td"
};
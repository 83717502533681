// General
import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy, FaEnvelope, FaFacebook, FaTwitter } from "react-icons/fa";
// Translation
import { FormattedMessage } from "react-intl";
// Redux
import { connect } from "react-redux";
import { ShareButtons } from "react-share";
import { closeSocialShareModal, openSocialShareModal } from "../../../actions/modalActions";
import { url } from "../../../config";
import history from "../../../core/history";
import messages from "../../../locale/messages";
import s from "./SocialShareModal.css";

const { FacebookShareButton, TwitterShareButton, EmailShareButton } = ShareButtons;

class SocialShareModal extends Component {
  static propTypes = {
    closeSocialShareModal: PropTypes.func,
    socialshareModal: PropTypes.bool,
    openSocialShareModal: PropTypes.func,
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      socialshareModalStatus: false,
      isFormOpen: false,
      value: "Link kopieren",
      copied: false,
    };
    this.openForm = this.openForm.bind(this);
    this.copyText = this.copyText.bind(this);
  }

  openForm() {
    this.setState({ isFormOpen: true });
  }

  componentDidMount() {
    const { socialshareModal } = this.props;
    if (socialshareModal === true) {
      this.setState({ socialshareModalStatus: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { socialshareModal } = nextProps;
    if (socialshareModal === true) {
      this.setState({ socialshareModalStatus: true });
    } else {
      this.setState({ socialshareModalStatus: false });
    }
  }

  async copyText() {
    this.setState({
      value: "Link kopiert",
      copied: true,
    });

    setTimeout(() => {
      this.setState({
        value: "Link kopieren",
        copied: false,
      });
    }, 2000);
  }

  render() {
    const { closeSocialShareModal, openSocialShareModal, listId, title, city, state, country } = this.props;
    const { socialshareModalStatus, isFormOpen } = this.state;
    let location = history.location ? history.location.pathname : null;
    var locationPath = location ? location.replace("/preview", "") : null;

    const shareUrl = url + locationPath;
    let previewText = "Check out this listing on Expozed!";
    let bodyText = "Check out this listing on Expozed!" + " " + shareUrl;

    return (
      <div>
        <Modal
          show={socialshareModalStatus}
          animation={false}
          onHide={closeSocialShareModal}
          dialogClassName={cx(s.signupModalContainer, "signupModal", "sharesocialModal")}
        >
          <Modal.Header closeButton className={cx(s.marginBottom24, "customClosebutton", s.marginleftM5)}>
            {/* <Modal.Title><FormattedMessage {...messages.signup} /></Modal.Title> */}
          </Modal.Header>
          <Modal.Body bsClass={s.signupModalBody}>
            <div className={s.paddingbottom24}>
              <div className={s.share}>
                <FormattedMessage {...messages.shareLabel} />
              </div>
              <div className={s.content}>
                <FormattedMessage {...messages.socialShareDesc} />: {title} <FormattedMessage {...messages.inLabel} /> {city}, {state}, {country}
                {/* 'Silo Studio' Cottage - Guesthouses for Rent in Tyringham */}
              </div>
            </div>
            <ListGroup className={"sharingsocial"}>
              <ListGroupItem tag="a" href={shareUrl} className={s.borderradiusNone}>
                {/* <FaFacebook className={s.socialIcons} /> */}
                <FacebookShareButton
                  url={shareUrl}
                  // className={s.displayIcon}
                >
                  <FaFacebook className={s.socialIcons} />

                  <FormattedMessage {...messages.facebook} />
                </FacebookShareButton>
                {/* Facebook */}
              </ListGroupItem>
              <ListGroupItem tag="a" href={shareUrl}>
                {/* <FaTwitter className={s.socialIcons} /> */}
                <TwitterShareButton url={shareUrl} className={s.displayIcon}>
                  <FaTwitter className={s.socialIcons} />
                  <FormattedMessage {...messages.twitter} />
                </TwitterShareButton>
                {/* Twitter */}
              </ListGroupItem>
              <ListGroupItem tag="a" href={shareUrl}>
                {/* <FaEnvelope className={s.socialIcons} /> */}
                <EmailShareButton url={shareUrl} subject={previewText} body={bodyText} className={s.displayIcon}>
                  <FaEnvelope className={s.socialIcons} />
                  <FormattedMessage {...messages.emailLabel} />
                </EmailShareButton>
                {/* Email */}
              </ListGroupItem>
              <ListGroupItem tag="a" href="#">
                {/* <FaCopy className={s.socialIcons} /> */}
                <CopyToClipboard text={shareUrl} onCopy={() => this.copyText()}>
                  <span>
                    <FaCopy className={s.socialIcons} />
                    {this.state.value}
                  </span>
                </CopyToClipboard>
              </ListGroupItem>
            </ListGroup>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  socialshareModal: state.modalStatus.isSocialShareModal,
});

const mapDispatch = {
  closeSocialShareModal,
  openSocialShareModal,
};

export default withStyles(s)(connect(mapState, mapDispatch)(SocialShareModal));

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListingsApprove.css';

// Query
import listingsApproveQuery from './listingsApproveQuery.graphql';

// Component
import ListingsApproveComponent from '../../../components/siteadmin/ListingsApprove/ListingsApprove';
import Loader from '../../../components/Loader/Loader';

class ListingsApprove extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    getAllApprovedListings: PropTypes.shape({
      loading: PropTypes.bool,
      getAllApprovedListings: PropTypes.array,
    })
  };

  static defaultProps = {
    getAllApprovedListings: {
      loading: true
    }
  };

  render() {
    const { getAllApprovedListings: { loading, getAllApprovedListings }, title } = this.props;

    if (loading) {
      return <Loader type={"text"} />;
    } else {
      return <ListingsApproveComponent getAllListings={getAllApprovedListings} title={title} />;
    }
  }

}

export default compose(
  withStyles(s),
  graphql(listingsApproveQuery, {
    name: 'getAllApprovedListings',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only'
    }
  }),
)(ListingsApprove);

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2RITo{margin-bottom:6px;position:relative}._27Tnc{display:block;box-sizing:border-box;margin:0;padding:20px 16px;width:100%;outline:0;border:1px solid #373277;border-radius:4px;background:#373277;color:#fff;text-align:center;text-decoration:none;font-size:16px;line-height:1.3333333;cursor:pointer}._27Tnc:hover{background:rgba(54,50,119,.8)}._27Tnc:focus{border-color:#0074c2;box-shadow:0 0 8px rgba(0,116,194,.6)}._1BNN8{border-color:#3b5998;background:#3b5998;border-radius:4px;text-decoration:none}._1BNN8:focus,._1BNN8:hover{background:#2d4373;color:#fff;text-decoration:none}._2x95A{border-color:#555;border-radius:4px;color:#000}._2x95A,._2x95A:focus,._2x95A:hover{background:transparent;text-decoration:none}._2x95A:focus,._2x95A:hover{color:#fff;color:#000}._1TkJA,._3mUxt{display:inline-block;margin:1px 0 0;width:20px;height:20px;vertical-align:middle;float:left;fill:currentColor}", ""]);

// exports
exports.locals = {
	"formGroup": "_2RITo",
	"button": "_27Tnc",
	"facebook": "_1BNN8 _27Tnc",
	"google": "_2x95A _27Tnc",
	"icon": "_3mUxt",
	"googleIcon": "_1TkJA"
};
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropzoneComponent from "react-dropzone-component";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { uploadKycDocuments } from "../../actions/mangopay/uploadKycDocuments";
import { toastr } from "react-redux-toastr";
// Style
import { Button, Label } from "react-bootstrap";
// Style
import s from "./DocumentUpload.css";

import s2 from "!isomorphic-style-loader!css-loader!./filepicker.css";
// Locale
import messages from '../../locale/messages';
import { FormattedMessage } from 'react-intl';
// Component
import DocumentList from "../DocumentList";

//compose
import { graphql, gql, compose } from "react-apollo";
import ShowDocumentListQuery from "../DocumentList/ShowListDocument.graphql";
const query = gql`
  query ShowDocumentList($type: String, $kycType: String) {
    ShowDocumentList(type: $type, kycType: $kycType) {
      id
      userId
      fileName
      fileType
      type
      kycType
      kycStatus
    }
  }
`;

class DocumentUpload extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.success = this.success.bind(this);
    this.complete = this.complete.bind(this);
    this.dropzone = null;
  }

  componentDidMount() {
    const isBrowser = typeof window !== "undefined";
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument && document.querySelector(".dz-hidden-input")) {
      document.querySelector(".dz-hidden-input").style.visibility = "visible";
      document.querySelector(".dz-hidden-input").style.opacity = "0";
      // document.querySelector(".dz-hidden-input").style.height = "100%";
      document.querySelector(".dz-hidden-input").style.width = "100%";
      document.querySelector(".dz-hidden-input").style.cursor = "pointer";
    }
  }

  success(file, fromServer) {
    /*const { listId, createListPhotos } = this.props;
    const { files } = fromServer;
    let fileName = files[0].filename;
    let fileType = files[0].mimetype;
    // Calling Redux action to create a record for uploaded file
    if(listId != undefined) {
      createListPhotos(listId, fileName, fileType);
    }*/
  }

  async complete(file, type) {
    const { mutate, isKyc, kycType } = this.props;

    let variables = {};
    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      let fileName = files[0].filename;
      let fileType = files[0].mimetype;
      let type = isKyc ? "kyc" : "document";
      variables = {
        fileName,
        fileType,
        type,
        kycType,
        kycStatus: "uploaded",
      };
      const { data } = await mutate({
        variables,
        refetchQueries: [
          { query, variables: { type: type, kycType: kycType } },
        ],
      });

      if (data && data.uploadDocument) {
        if (data.uploadDocument.status === "success") {
          toastr.success(
            "Upload erfolgreich!",
            "Ihr Dokument wurde erfolgreich hochgeladen!"
          );
        } else {
          toastr.error("Fehler!", "Etwas ist schief gelaufen!");
        }
      }
    }
    this.dropzone.removeFile(file);
  }

  refetchQuery = () => {
    const {
      data: { refetch },
      data,
    } = this.props;
    let variables = {
      type: this.props.isKyc ? "kyc" : "document",
      kycType: this.props.kycType,
    };
    refetch(variables);
  };
  async uploadDocsToMangoPay(kycType, files) {
    // Mangopay create user and wallet
    const { status } = await this.props.uploadKycDocuments(kycType, files);
    if (status == 200) {
      toastr.success("Erfolgreich eingereicht!", "Ihre Daten wurden zur Überprüfung eingereicht.");
    } else {
      toastr.error("Fehler", "Ihre Daten konnten nicht übermittelt werden");
    }

    this.refetchQuery();
  }

  render() {
    const { placeholder, listId, isKyc, kycType } = this.props;
    const {
      data: { ShowDocumentList },
      data,
    } = this.props;
    const djsConfig = {
      dictDefaultMessage: placeholder,
      addRemoveLinks: false,
      maxFilesize: 10,
      maxFiles: 10,
      acceptedFiles: "image/*,application/pdf",
      hiddenInputContainer: ".dzInputContainer" + kycType,
    };
    const componentConfig = {
      iconFiletypes: [".jpg", ".png", ".pdf"],
      postUrl: "/documents",
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      success: this.success,
      complete: this.complete,
    };

    let kycStatus = null;
    let kycDocStatus = null;
    if (ShowDocumentList && ShowDocumentList.length > 0) {
      kycStatus = ShowDocumentList.map((document) => {
        return document.kycStatus;
      });
    }
    if(this.props.returnKycStatus) {
      this.props.returnKycStatus(kycStatus)
    }
    let uniqueKycStatus = [...new Set(kycStatus)];

    if (uniqueKycStatus.length === 1) {
      //make sure all docs of type have same status
      kycDocStatus = uniqueKycStatus[0];
    }
    return (
      <div>
       
        {kycDocStatus === "submitted" && <div className={s.badge}><Label bsStyle={"info"}> <FormattedMessage {...messages.kycSubmitted} /></Label></div>}
        {kycDocStatus === "success" && <div className={s.badge}><Label bsStyle={"success"}> <FormattedMessage {...messages.kycValidated} /></Label></div>}
        {kycDocStatus !== "success" && (
          <div className={cx("listPhotoContainer")}>
            <div className={cx("dzInputContainer" + kycType)}>
              {kycDocStatus !== "submitted" && (
                <DropzoneComponent
                className="dropzone"
                  config={componentConfig}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                  key={kycType}
                />
              )}
            </div>
            <DocumentList
              disabled={kycDocStatus === "submitted"}
              isKyc={isKyc}
              kycType={kycType}
            />

{ShowDocumentList &&
          ShowDocumentList.length > 0 &&
          kycDocStatus === "uploaded" && (
            <Button
              className={cx(
                s.button,
                s.btnPrimary,
                s.btnlarge,
                s.pullRight,
                s.noMargin,
                s.smPosition
              )}
              onClick={() =>
                this.uploadDocsToMangoPay(kycType, ShowDocumentList)
              }
            >
              <FormattedMessage {...messages.kycSubmitButton} />
            </Button>
            
          )}
          </div>
        )}
        
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = { uploadKycDocuments };

export default compose(
  withStyles(s),
  graphql(ShowDocumentListQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        type: props.isKyc ? "kyc" : "document",
        kycType: props.kycType,
      },
    }),
  }),
  graphql(gql`
    mutation uploadDocument(
      $fileName: String
      $fileType: String
      $type: String
      $kycType: String
      $kycStatus: String
    ) {
      uploadDocument(
        fileName: $fileName
        fileType: $fileType
        type: $type
        kycType: $kycType
        kycStatus: $kycStatus
      ) {
        fileName
        fileType
        type
        kycType
        status
        kycStatus
      }
    }
  `),
  connect(mapState, mapDispatch)
)(DocumentUpload);

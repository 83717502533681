import React, { Component } from "react";

import { useContext } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import s from "./Booking.css";
import StepWizard from "./StepWizard";

import { Row, Col, Grid } from "react-bootstrap";

// Redux Form
import { formValueSelector } from "redux-form";

// Redux actions
import { resendEmailVerification } from "../../actions/manageUserVerification";
import { restoreBooking } from "../../actions/booking/restoreBooking";
import history from "../../core/history";
// Component
import Meetup from "./Meetup";
import Verification from "./Verification";
import Payment from "./Payment";
import AvatarUpload from "./AvatarUpload";
import Payin from "./Payin";
import CompleteProfile from "./CompleteProfile";
import BookingLongTerm from "./BookingLongTerm";
import Loader from "../Loader";
import withStyles from "isomorphic-style-loader/lib/withStyles";

class Booking extends Component {
  static propTypes = {
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      picture: PropTypes.string,
      displayName: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      verification: PropTypes.shape({
        isEmailConfirmed: PropTypes.bool.isRequired,
      }),
    }),
    bookingData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      coverPhoto: PropTypes.number,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      personCapacity: PropTypes.number.isRequired,
      bookingType: PropTypes.string.isRequired,
      user: PropTypes.shape({
        email: PropTypes.string.isRequired,
        profile: PropTypes.shape({
          profileId: PropTypes.number.isRequired,
          displayName: PropTypes.string.isRequired,
          companyName: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          picture: PropTypes.string,
        }),
      }),
      settingsData: PropTypes.arrayOf(
        PropTypes.shape({
          listsettings: PropTypes.shape({
            itemName: PropTypes.string.isRequired,
          }),
        })
      ),
      houseRules: PropTypes.arrayOf(
        PropTypes.shape({
          listsettings: PropTypes.shape({
            itemName: PropTypes.string.isRequired,
          }),
        })
      ),
      listingData: PropTypes.shape({
        basePrice: PropTypes.number.isRequired,
        nkPrice: PropTypes.number.isRequired,
        qmPrice: PropTypes.number.isRequired,
        cleaningPrice: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        weeklyDiscount: PropTypes.number,
        monthlyDiscount: PropTypes.number,
        cancellation: PropTypes.shape({
          policyName: PropTypes.string.isRequired,
        }),
      }),
      listPhotos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }),
    resendEmailVerification: PropTypes.any.isRequired,
    bookDetails: PropTypes.shape({
      startDate: PropTypes.object.isRequired,
      endDate: PropTypes.object.isRequired,
      guests: PropTypes.number.isRequired,
    }),
    serviceFees: PropTypes.object.isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    specialPricing: PropTypes.array,
  };

  static defaultProps = {
    account: {
      email: null,
      displayName: null,
      firstName: null,
      companyName: null,
      picture: null,
      verification: {
        isEmailConfirmed: false,
      },
    },
    bookingData: {
      title: null,
      personCapacity: 0,
      coverPhoto: null,
      city: null,
      state: null,
      country: null,
      user: {
        profile: {
          displayName: null,
          firstName: null,
          companyName: null,
          picture: null,
        },
      },
      settingsData: [
        {
          listsettings: {
            itemName: null,
          },
        },
      ],
      houseRules: [],
      listingData: {
        basePrice: 0,
        nkPrice: 0,
        qmPrice: 0,
        cleaningPrice: 0,
        currency: null,
        weeklyDiscount: 0,
        monthlyDiscount: 0,
      },
      listPhotos: [],
    },
    bookDetails: {
      checkIn: null,
      checkOut: null,
      guests: 1,
    },
    specialPricing: [],
  };

  constructor(props) {
    super(props);
    let account = props.account;
    //Evaluate first page
    let startPage = account.picture && account.verification.isEmailConfirmed && account.profileComplete ? "payment" : "meetup";
    this.state = {
      page: startPage,
      restored: false,
      activeStep: 0,
      isMobile: false,
      mandateReturned: false,
    };
    this.nextPage = this.nextPage.bind(this);
  }

  nextPage(page) {
    this.setState({ page });
  }

  componentDidMount() {
    //SEt localstorage with bookingdata
    const { restoreBooking } = this.props;
    let savedBookingData = JSON.parse(localStorage.getItem("booking"));

    if (savedBookingData && new Date() - savedBookingData.timestamp > 1000 * 60 * 5) {
      localStorage.removeItem("booking");
    }
    if (savedBookingData && savedBookingData.data && !this.state.restored) {
      restoreBooking(savedBookingData);

      if (savedBookingData && savedBookingData.mandateReturned) {
        localStorage.removeItem("booking");
        this.setState({ mandateReturned: savedBookingData.mandateReturned });
      }
    }

    if (!savedBookingData) {
      try {
        let bookingData = this.props.book;
        bookingData["location"] = history.location ? history.location.pathname : null;
        bookingData["timestamp"] = new Date();
        localStorage.setItem("booking", JSON.stringify(bookingData));
      } catch (error) {
        console.error(error);
      }
    }

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  setWizardStep = (step) => {
    this.setState((state) => ({
      activeStep: step,
    }));
  };

  render() {
    const { mandateReturned, restored, isMobile } = this.state;
    let { page } = this.state;
    let { account, bookingData, bookDetails, resendEmailVerification, payinData, locale, isLongTermBooking, selectedLongTerm } = this.props;
    let {
      bookDetails: { guests, startDate, endDate, taxRate, bookingType, justPayment, reservationId },
    } = this.props;
    let { serviceFees, base, rates, specialPricing } = this.props;
    let {} = this.state;
    const profileComplete = account.profileComplete;

    //wait till restore is ready
    if (!bookingData.title) {
      return <Loader type={"text"} />;
    }
    const stepIds = {
      completeProfile: 0,
      verification: 1,
      avatar: 2,
      // payin: 3,
      payment: 3,
    };
    let selectedWizardstep = stepIds[page];

    let steps = ["Profil vervollständigen", "E-Mail Adresse bestätigen", "Foto hochladen"];
    let stepOrientation = isMobile ? "vertical" : "horizontal";

    if (mandateReturned) {
      page = "payment";
    }


    mandateReturned;
    if (isLongTermBooking) {
      return (
        <BookingLongTerm
          listId={bookingData.id}
          hostId={bookingData.userId}
          guestId={account.userId}
          guestEmail={account.email}
          hostDisplayName={bookingData.user.profile.firstName}
          hostDisplayCompanyName={bookingData.user.profile.companyName}
          hostPicture={bookingData.user.profile.picture}
          listTitle={bookingData.title}
          listType={bookingData.settingsData[0].listsettings.itemName}
          coverPhoto={bookingData.coverPhoto}
          city={bookingData.city}
          state={bookingData.state}
          country={bookingData.country}
          houseRules={bookingData.houseRules}
          allowedPersonCapacity={bookingData.personCapacity}
          guests={guests}
          services={bookingData.listingData.services}
          listPhotos={bookingData.listPhotos}
          bookingType={bookingData.bookingType}
          bookingData={bookingData}
          guestPicture={account && account.picture}
          locale={locale}
          selectedLongTerm={selectedLongTerm}
        />
      );
    }
    return (
      <div className={s.completeContainer}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {page !== "payment" && page !== "meetup" && <StepWizard orientation={stepOrientation} steps={steps} activeStep={selectedWizardstep} />}
            {page === "meetup" && (
              <Meetup
                hostDisplayName={bookingData.user.profile.firstName}
                hostDisplayCompanyName={bookingData.user.profile.companyName}
                hostPicture={bookingData.user.profile.picture}
                guestDisplayName={account.firstName}
                guestDisplayCompanyName={account.companyName}
                nextPage={this.nextPage}
                guestPicture={account.picture}
                emailVerified={account.verification.isEmailConfirmed}
                payinData={payinData}
                profileComplete={profileComplete}
                restored={restored}
              />
            )}

            {page === "completeProfile" && (
              <CompleteProfile
                nextPage={this.nextPage}
                emailVerified={account.verification.isEmailConfirmed}
                payinData={payinData}
                guestPicture={account.picture}
              />
            )}

            {page === "payin" && <Payin payinData={payinData} />}

            {page === "verification" && <Verification guestEmail={account.email} resendEmailVerification={resendEmailVerification} />}

            {page === "avatar" && (
              <AvatarUpload
                payinData={payinData}
                nextPage={this.nextPage}
                guestPicture={account.picture}
                guestDisplayName={account.firstName}
                emailVerified={account.verification.isEmailConfirmed}
              />
            )}
          </Col>
        </Row>
        {page === "payment" && (
          <Payment
            preApproved={bookDetails.preApprove}
            listId={bookingData.id}
            hostId={bookingData.userId}
            guestId={account.userId}
            guestEmail={account.email}
            hostDisplayName={bookingData.user.profile.firstName}
            hostDisplayCompanyName={bookingData.user.profile.companyName}
            hostPicture={bookingData.user.profile.picture}
            listTitle={bookingData.title}
            listType={bookingData.settingsData[0].listsettings.itemName}
            coverPhoto={bookingData.coverPhoto}
            city={bookingData.city}
            state={bookingData.state}
            country={bookingData.country}
            houseRules={bookingData.houseRules}
            checkIn={startDate}
            checkOut={endDate}
            allowedPersonCapacity={bookingData.personCapacity}
            guests={guests}
            basePrice={bookingData.listingData.basePrice}
            nkPrice={bookingData.listingData.nkPrice}
            qmPrice={bookingData.listingData.qmPrice}
            cleaningPrice={bookingData.listingData.cleaningPrice}
            services={bookingData.listingData.services}
            service_price_hour={bookingData.listingData.service_price_hour}
            service_price_kg={bookingData.listingData.service_price_kg}
            service_price_qm={bookingData.listingData.service_price_qm}
            service_price_palette={bookingData.listingData.service_price_palette}
            monthlyPay={bookingData.listingData.monthlyPay}
            service_description={bookingData.listingData.service_description}
            currency={bookingData.listingData.currency}
            security={bookingData.security}
            securityClass={bookingData.securityClass}
            weeklyDiscount={bookingData.listingData.weeklyDiscount}
            monthlyDiscount={bookingData.listingData.monthlyDiscount}
            listPhotos={bookingData.listPhotos}
            serviceFees={serviceFees}
            base={base}
            rates={rates}
            bookingType={bookingData.bookingType}
            justPayment={justPayment}
            reservationId={reservationId}
            policyName={bookingData.listingData.cancellation.policyName}
            specialPricing={specialPricing}
            bookingData={bookingData}
            guestPicture={account && account.picture}
            taxRate={bookingData.listingData.taxRate}
            locale={locale}
            mandateReturned={mandateReturned}
          />
        )}
      </div>
    );
  }
}

// Decorate with connect to read form values
const selector = formValueSelector("BookingForm"); // <-- same as form name

const mapState = (state) => ({
  account: state.account.data,
  bookingData: state.book.data,
  bookDetails: state.book.bookDetails,
  serviceFees: state.book.serviceFees,
  book: state.book,
  locale: state.intl.locale,
  base: state.currency.base,
  rates: state.currency.rates,
  specialPricing: state.viewListing.specialPricing,
  payinData: state.payin.data,
});

const mapDispatch = {
  resendEmailVerification,
  restoreBooking,
};

export default withStyles(s)(connect(mapState, mapDispatch)(Booking));

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._4fPNQ{padding:0}._3VTya{margin:0}._3Hnjg{margin:0 auto;max-width:1080px;padding-top:20px}._3KJjj ._1d3NS{border-radius:4px;height:110px}._1jPqx{height:65px;background-size:contain;background-repeat:no-repeat;background-position:50%;margin:20px;cursor:pointer;filter:gray;-moz-filter:grayscale(100%);-ms-filter:grayscale(100%);-o-filter:grayscale(100%);filter:url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"filter\"><feColorMatrix type=\"matrix\" color-interpolation-filters=\"sRGB\" values=\"0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0\" /></filter></svg>#filter');filter:grayscale(1)}._1jPqx:hover{-moz-filter:grayscale(0);-ms-filter:grayscale(0);-o-filter:grayscale(0);filter:none}._2RGIj{margin-top:40px;font-weight:600}@media screen and (max-width:767px){._2RGIj{text-align:center}}", ""]);

// exports
exports.locals = {
	"noPadding": "_4fPNQ",
	"noMargin": "_3VTya",
	"container": "_3Hnjg",
	"carouselMain": "_3KJjj",
	"carousel__main": "_1d3NS",
	"carouselItem": "_1jPqx",
	"headLine": "_2RGIj"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._365SQ{padding-left:20px;padding-right:20px}._28fXj{margin:0 auto;padding:0 0 40px;max-width:1080px;max-width:var(--max-content-width)}._2EurY{font-size:145px}._2EurY,._2IG5g{font-weight:700;margin:0}._2IG5g{font-size:85px}.mnzhi{text-align:center}._35gdl{padding-left:0;list-style:none}._2eEX6{font-size:15px;color:#404040}a,a:hover{color:#0667b2}._36YY7{margin-top:12px}._2dvJf{margin-bottom:12px}._22iyP{margin-bottom:48px}._1VRfV{margin-top:48px}", ""]);

// exports
exports.locals = {
	"root": "_365SQ",
	"container": "_28fXj",
	"textJumbo": "_2EurY",
	"textMedium": "_2IG5g",
	"textCenter": "mnzhi",
	"listStyled": "_35gdl",
	"subTitle": "_2eEX6",
	"spaceTop2": "_36YY7",
	"space2": "_2dvJf",
	"space6": "_22iyP",
	"spaceTop6": "_1VRfV"
};
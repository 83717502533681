// General
import React, { Component } from "react";
import PropTypes from "prop-types";

//Fontawesome
import { FaInfoCircle } from "react-icons/fa";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep1 from "./validateStep1";

// Redux
import { connect } from "react-redux";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, ControlLabel, FormControl, InputGroup, Popover, OverlayTrigger } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component
import ListPlaceTips from "../ListPlaceTips";
import CustomCheckbox from "../CustomCheckbox";

import update from "./update";

// Internal Component
import IncrementButton from "../IncrementButton";
class Page2 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      houseType: [],
      roomType: [],
      buildingSize: [],
      buildingCondition: [],
      floorCount: "",
      parkingCount: 0,
      mandateExists: false,
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;

    if (listingFields != undefined) {
      this.setState({
        houseType: listingFields.houseType,
        roomType: listingFields.roomType,
        buildingSize: listingFields.buildingSize,
        buildingCondition: listingFields.buildingCondition,
        floorCount: listingFields.floorCount,
        parkingCount: listingFields.parkingCount,
      });
    }
  }

  componentDidMount() {
    const { valid } = this.props;

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        houseType: listingFields.houseType,
        roomType: listingFields.roomType,
        buildingSize: listingFields.buildingSize,
        buildingCondition: listingFields.buildingCondition,
        floorCount: listingFields.floorCount,
        parkingCount: listingFields.parkingCount,
      });
    }
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} maxLength={5} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderIncrementButton = (field) => <IncrementButton {...field} />;

  setMandateExists = (value) => {
    this.setState({ mandateExists: value });
  };

  render() {
    const { residenceType, handleSubmit, submitting, pristine, valid, previousPage, nextPage, existingList } = this.props;
    const { houseType, roomType, buildingSize, mandateExists } = this.state;
    const { formatMessage } = this.props.intl;

    let isDisabled = this.state.isDisabled;

    let path = "index";
    if (existingList) {
      path = "home";
    }

    if (residenceType === "0" && !mandateExists) {
      isDisabled = true;
    }

    const popoverBuildingCondition = (
      <Popover id="popover-trigger-hover-focus" title="Gebäudezustand">
        <p>
          <b>Neuwertig</b>
          <br />
          Substanz der Immobilie ist in sehr gutem Zustand. Wände- Bodenbeläge, sowie weitere Ausstattungen sind neuwertig.
        </p>
        <p>
          <b>Altersgemäß gut</b>
          <br />
          Substanz der Immobilie ist in Ordnung. Wände- und Bodenbeläge, sowie weitere Ausstattungen sind in zu erwartendem Zustand.
        </p>
        <p>
          <b>Renovierungsbedüftig</b>
          <br />
          Substanz der Immobilie ist in Ordnung, Wände- und Bodenbeläge, sowie weitere Ausstattungen sind nicht mehr zeitgemäß.
        </p>
      </Popover>
    );

    let buildingCondition = [
      {
        value: "Neuwertig",
        label: "Neuwertig",
      },
      {
        value: "altersgemäß gut",
        label: "altersgemäß gut",
      },
      {
        value: "renovierungsbedüftig",
        label: "renovierungsbedüftig",
      },
    ];

    let floorCount = [
      {
        value: "EG",
        label: "EG",
      },
      {
        value: "UG",
        label: "UG",
      },
      {
        value: "1.OG",
        label: "1.OG",
      },
      {
        value: "2.OG",
        label: "2.OG",
      },
      {
        value: "3.OG",
        label: "3.OG",
      },
      {
        value: "mehrere Etagen",
        label: "mehrere Etagen",
      },
    ];

    const popoverRoomType = (
      <Popover id="popover-trigger-hover-focus" title="Lagerfläche">
        <p>Wir unterscheiden zwischen versicherbaren und nicht versicherbaren Flächen.</p>
        <p>
          <strong>Versicherbare Flächen:</strong>
          <br />
          Räumlich abgetrennte und zugangsbeschränkte Flächen sind versicherbar und werden je nach Ausstattung in Sicherheitsklassen eingeteilt.
        </p>
        <ul>
          <li>Lager- & Logistikfläche</li>
          <li>Produktionsfläche</li>
        </ul>

        <p>
          <strong>Nichtversicherbare Flächen:</strong>
          <br />
          Flächen die nicht den oben genannten Anforderungen entsprechen sind nicht versicherbar und werden als Außenflächen behandelt.{" "}
        </p>

        <ul>
          <li>Außenfläche</li>
          <li>Offene Lager- & Logistikfläche </li>
        </ul>
      </Popover>
    );

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";

    return (
      <div>
        <Grid fluid>
          <Row className={cx(s.landingContainer, "arrowPosition")}>
            <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
              <div>
                <h3 className={s.landingContentTitle}>
                  <FormattedMessage {...messages.whatKindOfPlaceListing} />
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className={s.landingMainContent}>
                    <FormGroup className={s.formGroup}>
                      <ControlLabel className={s.landingLabel}>
                        <FormattedMessage {...messages.whatGuestHave} />
                      </ControlLabel>
                      <InputGroup className={s.infoGroup}>
                        <Field name="roomType" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                          {roomType.map((value, key) => {
                            return (
                              value.isEnable == 1 && (
                                <option value={value.id} key={key}>
                                  {value.itemName}
                                </option>
                              )
                            );
                          })}
                        </Field>
                        <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRoomType}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className={s.formGroup}>
                      <ControlLabel className={s.landingLabel}>
                        <FormattedMessage {...messages.whatTypeOfProperty} />
                      </ControlLabel>
                      <Field name="houseType" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                        {houseType.map((value, key) => {
                          return (
                            value.isEnable == 1 && (
                              <option value={value.id} key={key}>
                                {value.itemName}
                              </option>
                            )
                          );
                        })}
                           {/* <option value={"full"} >
                                Gesamtes Gebäude
                              </option>
                         <option value={"part"} >
                               Teil des Gebäudes
                              </option> */}
                      </Field>
                    </FormGroup>

                    <FormGroup className={s.formGroup}>
                      <ControlLabel className={s.landingLabel}>
                        <FormattedMessage {...messages.isPersonalHome} />
                      </ControlLabel>
                      <div>
                        <label className={cx(s.blockRadioButton, s.landingLabel)}>
                          <FormattedMessage {...messages.yesText} />{" "}
                          <Field name="residenceType" component="input" type="radio" value="1" className={s.pullRight} />
                        </label>
                        <label className={cx(s.blockRadioButton, s.landingLabel)}>
                          <FormattedMessage {...messages.noText} />{" "}
                          <Field name="residenceType" component="input" type="radio" value="0" className={s.pullRight} />
                        </label>
                      </div>
                    </FormGroup>

                    {residenceType === "0" && (
                      <div className={s.mandateCheckbox}>
                        <CustomCheckbox
                          name="mandateExists"
                          value={true}
                          checked={mandateExists}
                          className={cx("icheckbox_square-green", s.mandateCheckboxInput)}
                          onChange={(event) => {
                            // const newValue = [...input.value];
                            this.setMandateExists(event);
                          }}
                        />
                        <label className={cx(s.checkboxLabel, s.noPadding)}>Hiermit bestätige ich, der Vermietung des Objekts bevollmächtigt zu sein</label>
                      </div>
                    )}
                    {/* <FormGroup className={s.formGroup}>
                      <label className={s.landingCaption}>
                        <FormattedMessage {...messages.isPersonalHomeInfo} />
                      </label>
                    </FormGroup> */}

                    <FormGroup className={cx(s.formGroup)}>
                      <ControlLabel className={s.landingLabel}>
                        <FormattedMessage {...messages.buildingCondition} />
                      </ControlLabel>
                      <InputGroup className={s.infoGroup}>
                        <Field name="buildingCondition" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                          )
                          {buildingCondition.map((value, key) => {
                            return (
                              <option value={value.value} key={key}>
                                {value.label}
                              </option>
                            );
                          })}
                        </Field>

                        <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverBuildingCondition}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className={cx(s.formGroup)}>
                      <ControlLabel className={s.landingStep3}>
                        <FormattedMessage {...messages.floorCount} />
                      </ControlLabel>

                      <Field name="floorCount" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                        {floorCount.map((value, key) => {
                          return (
                            <option value={value.value} key={key}>
                              {value.label}
                            </option>
                          );
                        })}
                      </Field>
                    </FormGroup>

                    <FormGroup className={cx(s.formGroup)}>
                      <ControlLabel className={s.landingStep3}>
                        <FormattedMessage {...messages.parkingCount} />
                      </ControlLabel>
                      <Field
                        name="parkingCount"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={"Parkplatz"}
                        labelPlural={"Parkplätze"}
                        maxValue={500}
                        minValue={0}
                        incrementBy={1}
                      />
                    </FormGroup>
                    <div className={s.nextPosition}>
                      <div className={s.nextBackButton}>
                        <hr className={s.horizontalLineThrough} />
                        <FormGroup className={s.formGroup}>
                          <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                            <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage(path)}>
                              <FormattedMessage {...messages.back} />
                            </Button>
                            <Button
                              className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)}
                              disabled={isDisabled}
                              onClick={() => nextPage("Sicherheit")}
                            >
                              <FormattedMessage {...messages.next} />
                            </Button>
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

Page2 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep1,
  onSubmit: update,
})(Page2);

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep1"); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  residenceType: selector(state, "residenceType"),
  // inital: state.form.ListPlaceStep1.initial
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Page2)));

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Button, Modal, Panel } from "react-bootstrap";
import { FaBolt, FaShieldAlt, FaChevronRight } from "react-icons/fa";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { change } from "redux-form";
import { formatURL } from "../../../helpers/formatURL";
import CustomCheckbox from "../../CustomCheckbox";

// Locale
import messages from "../../../locale/messages";
import CurrencyConverter from "../../CurrencyConverter";
import StarRating from "../../StarRating";
import WishListIcon from "../../WishListIcon";
import ListingPhotos from "../ListingPhotos";
import s from "./ListingItem.css";
import history from "../../../core/history";
import { SingleDatePicker } from "react-dates";
import { toastr } from "react-redux-toastr";
import s2 from "!isomorphic-style-loader!css-loader!sass-loader!react-dates/lib/css/_datepicker.css";
import moment from "moment";

class ListingItem extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    id: PropTypes.number,
    basePrice: PropTypes.number,
    currency: PropTypes.string,
    title: PropTypes.string,
    beds: PropTypes.number,
    personCapacity: PropTypes.number,
    roomType: PropTypes.string,
    listPhotos: PropTypes.array,
    coverPhoto: PropTypes.number,
    bookingType: PropTypes.string.isRequired,
    reviewsCount: PropTypes.number,
    reviewsStarRating: PropTypes.number,
    wishListStatus: PropTypes.bool,
    isListOwner: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      validationErrors: [],
      name: "",
      phone: "",
      email: "",
      message: "",
      flachenbedarf: "0",
      ab_wann_benotigen_sie_die_flache_2: "",
      mietlaufzeit: "0",
      focusedInput: null,
      showModal: false,
      termsAccepted: false,
    };
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver(value) {
    const { change } = this.props;
    change("SearchForm", "markerHighlight", { id: value, hover: "true" });
  }

  handleMouseOut(value) {
    const { change } = this.props;
    change("SearchForm", "markerHighlight", {});
  }

  openNoResultsFormModal = () => {
    this.setState({ showModal: true });
  };
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleClick = (values, dispatch) => {
    const allValid = this.validateForm();
    if (allValid) {
      this.sendFormToHubspot(values);
    }

    this.setState({
      contactLoading: false,
    });
  };

  sendFormToHubspot = (values) => {
    const { ab_wann_benotigen_sie_die_flache_2, flachenbedarf, mietlaufzeit, name, phone, email, message, focusedInput } = this.state;
    // Replace portalId and formId with information from your FORM
    var portalId = "8224909";
    var formId = "13c66f03-8758-4654-a44b-f25d3654bd7b";
    var url = "https://api.hsforms.com/submissions/v3/integration/submit/" + portalId + "/" + formId;
    // Build request JSON:

    const flachenbedarfMapping = {
      250: "ab 250m²",
      500: "ab 500m²",
      1000: "ab 1000m²",
    };
    var hubspotData = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "name",
          value: name,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "ab_wann_benotigen_sie_die_flache_2",
          value: ab_wann_benotigen_sie_die_flache_2,
        },
        {
          name: "flachenbedarf",
          value: flachenbedarfMapping[flachenbedarf],
        },
        {
          name: "mietlaufzeit",
          value: mietlaufzeit,
        },
        {
          name: "nachricht",
          value: message,
        },
      ],
      context: {
        hutk: this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Expozed1 to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999, // replace with your subscription ID
              text: "I agree to receive marketing communications from Expozed1.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(hubspotData),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("fetch response", response.json());
        if (response.status === 200) {
          toastr.success("Anfrage", "Wir haben Ihre Anfrage erhalten und melden uns schnellst möglich bei Ihnen.");

          this.setState({ submitted: true });
        } else {
          toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
        }
        this.toggleModal();
      })
      .catch((error) => {
        toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
        this.toggleModal();
      });
  };
  getCookie = (name) => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };

  returnYears = () => {
    let years = [];
    for (let i = moment().year() - 100; i <= moment().year(); i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    </div>
  );
  validateForm = () => {
    const { ab_wann_benotigen_sie_die_flache_2, flachenbedarf, mietlaufzeit, name, phone, email, focusedInput } = this.state;

    let validationErrors = [];

    let allValid = true;

    if (!ab_wann_benotigen_sie_die_flache_2) {
      validationErrors.push("ab_wann_benotigen_sie_die_flache_2");
      allValid = false;
    }
    if (!flachenbedarf || flachenbedarf === "0") {
      validationErrors.push("flachenbedarf");
      allValid = false;
    }
    if (!mietlaufzeit || mietlaufzeit === "0") {
      validationErrors.push("mietlaufzeit");
      allValid = false;
    }
    if (!name) {
      validationErrors.push("name");
      allValid = false;
    }
    if (!email) {
      validationErrors.push("email");
      allValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email)) {
      validationErrors.push("email");
      allValid = false;
    }

    if (phone && phone.length < 7) {
      validationErrors.push("phone");
      allValid = false;
    }

    this.setState({ validationErrors });
    return allValid;
  };

  // handle input change
  handleInputChange = (e) => {
    const { name, value } = e.target;

    let newValue = {};
    newValue[name] = value;
    this.setState(newValue, () => {
      this.validateForm();
    });
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput: focusedInput.focused }, () => {
      this.validateForm();
    });
  };
  onDateChange(date) {
    this.setState({ ab_wann_benotigen_sie_die_flache_2: date }, () => {
      this.validateForm();
    });
  }

  setTermsAccepted = (value) => {
    this.setState({ termsAccepted: value });
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { showModal } = this.state;
    const {
      id,
      basePrice,
      currency,
      title,
      beds,
      personCapacity,
      city,
      state,
      roomType,
      coverPhoto,
      listPhotos,
      bookingType,
      reviewsCount,
      reviewsStarRating,
      wishListStatus,
      isInsurable,
      isListOwner,
      isMapShow,
      personalized,
      personCount,
      qmPrice,
      nkPrice,
      isListView,
      isAdditionalItem,
      services,
    } = this.props;

    const {
      termsAccepted,
      ab_wann_benotigen_sie_die_flache_2,
      flachenbedarf,
      mietlaufzeit,
      name,
      phone,
      email,
      message,
      focusedInput,
      validationErrors,
    } = this.state;
    console.log("validationErrors", validationErrors);
    console.log("validationErrors state", this.state);
    const { locale } = this.props;
    moment.locale(locale);
    if (isAdditionalItem) {
      return (
        <div
          className={cx(s.listItemContainer, s.listView, s.additionalItem)}
          onMouseOver={() => this.handleMouseOver(id)}
          onMouseOut={() => this.handleMouseOut(id)}
        >
          <div className={cx(s.listView, s.listInfo)}>
            <Row>
              <Col xs={8} sm={8} md={8} className={cx(s.textEllipsis, s.infoDesc, s.infoText, s.infoSpace)}>
                <div className={s.listingInfo}>
                  <span>Jetzt kostenlose Anfrage stellen</span>
                </div>
                <div className={cx(s.textEllipsis, s.listingTitle)}>Keine passende Fläche gefunden?</div>

                <div className={cx(s.textEllipsis, s.infoSpaceTop1, s.additionalItemText)}>Wir haben weitere exklusive Flächen in Ihrer Region verfügbar!</div>
              </Col>
              <Col xs={4} sm={4} md={4} className={cx(s.textEllipsis, s.additionalButtons)}>
                <Button className={cx(s.button, s.btnPrimary)} onClick={this.toggleModal}>
                  Zur Anfrage
                </Button>
                <a href="/kontakt">Service kontaktieren</a>
              </Col>
            </Row>
          </div>

          <Modal show={showModal} onHide={this.toggleModal} animation={false} className={cx(s.modalContainer, "requestForm")}>
            <div className={cx(s.modalTable)}>
              <div className={cx(s.modalCell)}>
                <Modal.Header className={s.modalHeading} closeButton>
                  <Modal.Title>Jetzt Mietanfrage stellen</Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass={s.modalBody} className={s.modalBody}>
                  <div className={s.modalDark}>
                    <Row className={s.noMargin}>
                      <Col md={12} className={s.noPadding}>
                        <Panel className={cx(s.panel)}>
                          <div className={s.panelBody}>
                            <div>
                              <h3>Sie haben besondere Anforderungen?</h3>
                              <h3>Wir finden die passende Fläche für Sie.</h3>
                              <div className={s.landingMainContent}>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>Ab wann benötigen Sie die Fläche?</label>

                                    <SingleDatePicker
                                      id="date_input"
                                      isOutsideRange={(day) => moment().diff(day) > 0}
                                      name="ab_wann_benotigen_sie_die_flache_2"
                                      date={ab_wann_benotigen_sie_die_flache_2}
                                      onDateChange={(date) => this.onDateChange(date)}
                                      numberOfMonths={1}
                                      placeholder={"Datum"}
                                      hideKeyboardShortcutsPanel
                                      onFocusChange={(focusedInput) => this.onFocusChange(focusedInput)}
                                      daySize={48}
                                      focused={focusedInput}
                                      keepOpenOnDateSelect={false}
                                      renderMonthElement={this.renderMonthElement}
                                      className={s.dateInput}
                                    />
                                    {validationErrors.includes("ab_wann_benotigen_sie_die_flache_2") && (
                                      <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>
                                    )}
                                  </Col>
                                </Row>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>Flächenbedarf</label>

                                    <select
                                      name="flachenbedarf"
                                      type="text"
                                      value={flachenbedarf}
                                      label={"Flächenbedarf"}
                                      className={cx(s.formControlSelect, s.commonBorder)}
                                      onChange={(e) => this.handleInputChange(e)}
                                    >
                                      <option value="0">Bitte auswählen</option>
                                      <option value="250">Ab 250m²</option>
                                      <option value="500">Ab 500m²</option>
                                      <option value="1000">Ab 1000m²</option>
                                    </select>
                                    {validationErrors.includes("flachenbedarf") && <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>}
                                  </Col>
                                </Row>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>Mietlaufzeit</label>

                                    <select
                                      name="mietlaufzeit"
                                      type="text"
                                      value={mietlaufzeit}
                                      label={"Mietlaufzeit"}
                                      className={cx(s.formControlSelect, s.commonBorder)}
                                      onChange={(e) => this.handleInputChange(e)}
                                    >
                                      <option value="0">Bitte auswählen</option>
                                      <option value="-1">Unter 1 Jahr</option>
                                      <option value="1-3">1-3 Jahre</option>
                                      <option value="3-5">3-5 Jahre</option>
                                      <option value="5+">Über 5 Jahre</option>
                                    </select>
                                    {validationErrors.includes("mietlaufzeit") && <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>}
                                  </Col>
                                </Row>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>Name</label>
                                    <input
                                      name="name"
                                      type="text"
                                      value={name}
                                      label={"Name"}
                                      className={cx(s.formControlInput, s.commonBorder)}
                                      onChange={(e) => this.handleInputChange(e)}
                                    />
                                    {validationErrors.includes("name") && <span className={s.errorMessage}>* Bitte machen Sie eine Angabe</span>}
                                  </Col>
                                </Row>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>Telefonnummer</label>

                                    <input
                                      name="phone"
                                      type="text"
                                      value={phone}
                                      label={"Telefonnummer"}
                                      className={cx(s.formControlInput, s.commonBorder)}
                                      onChange={(e) => this.handleInputChange(e)}
                                    />
                                    {validationErrors.includes("phone") && <span className={s.errorMessage}>* ungültige Eingabe</span>}
                                  </Col>
                                </Row>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>E-Mail</label>

                                    <input
                                      name="email"
                                      type="text"
                                      value={email}
                                      label={"E-Mail"}
                                      className={cx(s.formControlInput, s.commonBorder)}
                                      onChange={(e) => this.handleInputChange(e)}
                                    />
                                    {validationErrors.includes("email") && <span className={s.errorMessage}>* ungültige Eingabe</span>}
                                  </Col>
                                </Row>
                                <Row className={s.spaceTop}>
                                  <Col xs={12} sm={12} md={12} lg={12} className={s.textAlign}>
                                    <label className={s.labelText}>Nachricht</label>

                                    <textarea
                                      rows="5"
                                      name="message"
                                      value={message}
                                      className={cx(s.formControlInput, s.commonBorder)}
                                      onChange={(e) => this.handleInputChange(e)}
                                    />
                                    {validationErrors.includes("message") && <span className={s.errorMessage}>* ungültige Eingabe</span>}
                                  </Col>
                                </Row>

                                <Row className={s.space4}>
                                  <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className={s.confirmBox}>
                                      <span className={(s.checkBoxSection, s.checkBox)}>
                                        <CustomCheckbox
                                          name="termsAccepted"
                                          value={true}
                                          checked={termsAccepted}
                                          className={"icheckbox_square-green"}
                                          onChange={(event) => {
                                            // const newValue = [...input.value];
                                            this.setTermsAccepted(event);
                                          }}
                                        />
                                      </span>
                                      <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
                                        <label className={cx(s.checkboxLabel, s.noPadding)}>
                                          Mit Absenden dieses Formulars bestätigen Sie, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden dürfen.
                                          (Weitere Informationen und Widerrufshinweise finden Sie in der{" "}
                                          <a href="/page/datenschutz" target="blank" rel="noopener noreferrer">
                                          Datenschutzerklärung
                                          </a>{" "}
                                          und den{" "}
                                          <a href="/page/agb" target="blank" rel="noopener noreferrer">
                                            AGB
                                          </a>
                                          )
                                        </label>
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Panel>
                        <Button
                          disabled={!termsAccepted}
                          className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight, s.noMargin, s.smPosition)}
                          onClick={this.handleClick}
                        >
                          Absenden
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </div>
            </div>
          </Modal>
        </div>
      );
    }

    let bedsLabel = "bed";
    let guestsLabel = "guest";
    let heartIcon = "heartIcon";
    let personalizedURL = "",
      startDate,
      endDate,
      guestCount;

    if (beds > 1) {
      bedsLabel = "beds";
    }

    if (personCapacity > 1) {
      guestsLabel = "guests";
    }
    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Number(reviewsStarRating / reviewsCount);
    }
    let activeItem = 0,
      photoTemp,
      photosList = listPhotos.slice();
    if (listPhotos && listPhotos.length > 1) {
      listPhotos.map((x, y) => {
        if (x.id === coverPhoto) activeItem = y;
      });
      if (activeItem > 0) {
        photoTemp = photosList[0];
        photosList[0] = photosList[activeItem];
        photosList[activeItem] = photoTemp;
      }
    }

    startDate = personalized && personalized.startDate ? "?&startdate=" + personalized.startDate : "";
    endDate = personalized && personalized.endDate ? "&enddate=" + personalized.endDate : "";
    guestCount = personCapacity && personalized.startDate && personalized.endDate ? "&guests=" + personCount : "";
    personalizedURL = startDate + endDate + guestCount;
    const servicesMapping = {
      self: "Selbstständige Nutzung",
      inclusive: "Inklusive Be- und Entladen",
      optional: "Optional Dienstleistungen",
      full: "Full Service Vermietung",
    };

    return (
      <div
        className={cx(s.listItemContainer, isListView ? s.listView : "")}
        onMouseOver={() => this.handleMouseOver(id)}
        onMouseOut={() => this.handleMouseOut(id)}
      >
        {!isListOwner && <WishListIcon listId={id} key={id} isChecked={wishListStatus} heartIcon={heartIcon} />}

        <ListingPhotos
          id={id}
          coverPhoto={coverPhoto}
          listPhotos={photosList}
          formatURL={formatURL}
          title={title}
          isMapShow={isMapShow}
          personalizedURL={personalizedURL}
          isListView={isListView}
        />
        <div className={cx(isListView ? s.listView : "", s.listInfo)}>
          <a className={s.listInfoLink} href={"/spaces/" + formatURL(title) + "-" + id + personalizedURL} target={"_blank"}>
            {isListView ? (
              <Row>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoDesc, s.infoText, s.infoSpace)}>
                  <div className={s.listingInfo}>
                    <span>{roomType}</span>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.listingTitle)}>
                  {title}
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.listingLocation)}>
                  {city}, {state}
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoPrice, s.infoText, s.infoSpaceTop1)}></Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoPrice, s.infoText, s.infoSpaceTop1)}></Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoSpaceTop1, s.icons)}>
                  {bookingType === "instant" && (
                    <span title={formatMessage(messages.instantBookTooltip)}>
                      <FaBolt className={s.instantIcon} /> Sofort mieten
                    </span>
                  )}
                  {isInsurable && (
                    <span title={"Versicherbar"}>
                      <FaShieldAlt className={s.insurableIcon} /> versicherbar
                    </span>
                  )}
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoSpaceTop1)}>
                  <div>
                    <div className={s.box}>{personCapacity}m²</div>
                    {services && <div className={s.box}>{servicesMapping[services]}</div>}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoReview, s.infoSpaceTop1)}>
                  <div className={cx(s.reviewStar, "small-star-rating")}>
                    <StarRating value={starRatingValue} name={"review"} className={s.displayInline} starColor={"#404040"} emptyStarColor={"#cccccc"} />
                    <span className={s.textInline}>
                      &nbsp; {reviewsCount + " "}
                      {reviewsCount > 1 || reviewsCount === 0 ? formatMessage(messages.reviews) : formatMessage(messages.review)}
                    </span>{" "}
                    <div className={s.asterix}>
                      <div className={s.listPrice}>
                        <div>
                          {" "}
                          {
                            <span className={s.qmPrice}>
                              {" "}
                              <CurrencyConverter amount={parseFloat((basePrice / personCapacity) * 30)} from={currency} />
                            </span>
                          }{" "}
                          / m²
                        </div>
                        <div>
                          {" "}
                          {
                            <span className={s.monthPrice}>
                              {" "}
                              <CurrencyConverter amount={basePrice.toFixed(2) * 30} from={currency} /> pro Monat
                            </span>
                          }
                        </div>
                      </div>
                      <div className={s.arrowRight}>
                        <FaChevronRight />
                      </div>
                      {/* <div>* zzgl. MwSt.</div> */}
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoDesc, s.infoText, s.infoSpace)}>
                  <div className={s.listingInfo}>
                    <span>{roomType}</span>
                    <span>&nbsp;&#183;&nbsp;</span>
                    <span className={s.areaLabel}>{personCapacity}m²</span>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.listingTitle)}>
                  {title}
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoPrice, s.infoText, s.infoSpaceTop1)}>
                  {
                    <span className={s.qmPrice}>
                      {" "}
                      <CurrencyConverter amount={parseFloat((basePrice / personCapacity) * 30)} from={currency} />
                    </span>
                  }{" "}
                  / m² *
                  {bookingType === "instant" && (
                    <span title={formatMessage(messages.instantBookTooltip)}>
                      <FaBolt className={s.instantIcon} />
                    </span>
                  )}
                  {isInsurable && (
                    <span title={"Versicherbar"}>
                      <FaShieldAlt className={s.insurableIcon} />
                    </span>
                  )}
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoPrice, s.infoText, s.infoSpaceTop1)}>
                  {
                    <span className={s.monthPrice}>
                      {" "}
                      <CurrencyConverter amount={basePrice.toFixed(2) * 30} from={currency} />
                    </span>
                  }{" "}
                  pro Monat *
                </Col>
                <Col xs={12} sm={12} md={12} className={cx(s.textEllipsis, s.infoReview, s.infoSpaceTop1)}>
                  <div className={cx(s.reviewStar, "small-star-rating")}>
                    <StarRating value={starRatingValue} name={"review"} className={s.displayInline} starColor={"#404040"} emptyStarColor={"#cccccc"} />
                    <span className={s.textInline}>
                      &nbsp; {reviewsCount + " "}
                      {reviewsCount > 1 || reviewsCount === 0 ? formatMessage(messages.reviews) : formatMessage(messages.review)}
                    </span>{" "}
                    <span className={s.asterix}>* zzgl. MwSt.</span>
                  </div>
                </Col>
              </Row>
            )}
          </a>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  isMapShow: state.personalized.showMap,
  personalized: state.personalized,
  locale: state.intl.locale,
});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s, s2)(connect(mapState, mapDispatch)(ListingItem)));

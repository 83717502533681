import { gql } from 'react-apollo';
// Toaster
import { toastr } from 'react-redux-toastr';
import {Event} from '../../components/Tracking/GA'


import {
    MANANGE_LISTING_PUBLISH_STATUS_START,
    MANANGE_LISTING_PUBLISH_STATUS_SUCCESS,
    MANANGE_LISTING_PUBLISH_STATUS_ERROR,
} from '../../constants';

// To Refresh the Manage Listing Status
const ManageListingQuery = gql` 
    query ManageListings{
        ManageListings {
            id
            title
            city
            updatedAt
            coverPhoto
            isPublished
            isApproved
            isPaused
            isReady
            listPhotos{
                id
                name
            }
            settingsData {
                listsettings {
                    id
                    itemName
                }
            }
            listingSteps {
                id
                step1
                step2
                step3
                step4
            }
        }
  }`;

// To Refresh Listing Steps Query
const ListingStepsQuery = gql`
    query ($listId:String!) {
        showListingSteps (listId:$listId) {
            id
            listId
            step1
            step2
            step3
            step4
            listing {
                id
                isReady
                isPublished
                isApproved
                isPaused
            }
        }
    }`;

const getUpcomingBookingQuery = gql`
query getUpcomingBookings ($listId: Int!){
    getUpcomingBookings(listId: $listId){
      count
    }
  }`;

export function ManagePublishStatus(listId, action) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: MANANGE_LISTING_PUBLISH_STATUS_START,
            payload: {
                publishListLoading: true
            }
        });

        let mutation = gql`
            mutation ManagePublish($listId: Int!, $action: String!) {
                managePublish(listId: $listId, action: $action) {
                    status
                }
            }
        `;

        // Update List Status
        let WishListStatus = gql`
            mutation updateListStatus($listId:Int!, $action: String!) {
                 updateListStatus (listId:$listId, action: $action) {
                    status
                 }
             }
        `;

        let upcomingBookingCount;
        const bookedData = await client.query({
            query: getUpcomingBookingQuery,
            variables: {
                listId
            },
            fetchPolicy: 'network-only'
        });

        if (bookedData && bookedData.data && bookedData.data.getUpcomingBookings) {
            upcomingBookingCount = bookedData.data.getUpcomingBookings.count;
        }

        try {

            let type = 'Publish Listing';
            if (action === 'unPublish') {
                type = 'Unublish Listing'
            }

            if (upcomingBookingCount > 0 && action === 'unPublish') {
                toastr.error("Veröffentlichung zurücknehmen", 'Du kannst deine Veröffentlichung nicht zurücknehmen, da noch anstehende Buchungen für dieses Objekt vorhanden sind');
                dispatch({
                    type: MANANGE_LISTING_PUBLISH_STATUS_ERROR,
                    payload: {
                        publishListLoading: false
                    }
                });
            } else {

                const { data } = await client.mutate({
                    mutation,
                    variables: {
                        listId,
                        action
                    },
                    refetchQueries: [
                        { query: ManageListingQuery },
                    ]
                });

                if (data.managePublish.status === '200') {

                    const { dataList } = await client.mutate({
                        mutation: WishListStatus,
                        variables: {
                            listId,
                            action
                        },
                    });

                    // Reload Existing Steps Page
                    const { data } = await client.query({
                        query: ListingStepsQuery,
                        variables: { listId },
                        fetchPolicy: 'network-only',
                    });
                    if(action === 'unPublish') {
                        toastr.success("Veröffentlichung zurücknehmen", "Dein Objekt ist jetzt nicht mehr veröffentlicht!");

                    } else if(action === 'publish') {
                        toastr.success("Veröffentlichung eingereicht","Ihr Angebot wird nun von uns überprüft. Sie erhalten eine Benachrichtigung sobald der Vorgang abgeschlossen ist.");
                        Event("listing", "request_approve", listId)
                    }
                
                    else if(action === 'publish') {
                        toastr.success("Veröffentlichung erfolgreich","Die Fläche ist nun geprüft und LIVE");
                        Event("listing", "approve", listId)
                    }
                    dispatch({
                        type: MANANGE_LISTING_PUBLISH_STATUS_SUCCESS,
                        payload: {
                            listingSteps: data.showListingSteps,
                            publishListLoading: false
                        }
                    });
                } else {

                    toastr.error("Fehler", "Es ist ein Fehler aufgetreten. Bitte probieren Sie es nochmal oder wenden Sie sich an den Support");
                    dispatch({
                        type: MANANGE_LISTING_PUBLISH_STATUS_ERROR,
                        payload: {
                            status: data.managePublish.status,
                            publishListLoading: false
                        }
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: MANANGE_LISTING_PUBLISH_STATUS_ERROR,
                payload: {
                    error,
                    publishListLoading: false
                }
            });
        }
    };
}

// General
import React from 'react';
import PropTypes from 'prop-types';

// Translation
import { injectIntl } from 'react-intl';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Button } from 'react-bootstrap';
import s from './IncrementButton.css';
import  { FaMinus, FaPlus } from 'react-icons/fa';


class IncrementButton extends React.Component {
  static propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    labelSingluar: PropTypes.string,
    labelPlural: PropTypes.string,
    incrementBy: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.any
  };

  increment = (value, maxValue, incrementBy) => {
    if (value < maxValue) {
      return (Number(value) + Number(incrementBy));
    }
  }

  decrement = (value, minValue, incrementBy) => {
    if (value > minValue) {
      return (Number(value) - Number(incrementBy));
    }
  }

  render() {

    const { input: { onChange }, maxValue, minValue, labelSingluar, labelPlural, incrementBy, isFeminin } = this.props;
    const { formatMessage } = this.props.intl;
    let {input: { value}} = this.props;
    let label;
    
    //Defaultvalue = minValue when not set
    if(!value) {
      value = minValue;
    }
    if (value > 1) {
      label = labelPlural;
    } else if(value === 1) {
      label = labelSingluar;
    } else  if(value === 0) {
      let prefix = isFeminin ? "Keine": "Kein";
      label = prefix + " " + labelSingluar
    }

    return (
      <div className={s.incrementDecrementButton}>
        <label className={s.incrementDecrementText}> {value > 0 && value} {label}</label>
        <Button className={s.iconButton} onClick={() => onChange(this.decrement(value, minValue, incrementBy))}>
          <FaMinus />
        </Button>
        <Button className={s.iconButton} onClick={() => onChange(this.increment(value, maxValue, incrementBy))}>
          <FaPlus />
        </Button>
      </div>
    )
  }
}

export default injectIntl(withStyles(s)(IncrementButton));

import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ReviewBox.css";
import { Row, Col } from "react-bootstrap";
import cx from "classnames";
import { connect } from "react-redux";
import CheckboxImage from "./checkbox.svg";

class SliderAnimation extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.scrollTop = this.scrollTop.bind(this);
  }

  scrollTop() {
    window.scrollTo({
      top: screen.height,
      behavior: "smooth",
    });
  }

  render() {
    const { items, bg } = this.props;
    const settings = {
      vertical: false,
      dots: true,
      appendDots: (dots) => (
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
            display: "inline-block",
            textAlign: "left",
            position: "absolute",
            bottom: "-50px !important",
          }}
        >
          <ul className={s.dot} style={{ margin: "0px" }}>
            {" "}
            {dots}{" "}
          </ul>
        </div>
      ),
      fade: false,
      infinite: true,
      speed: 3000,
      arrows: false,
      slidesToShow: 1,
      // className: "center",
      // centerPadding: "60px",
      // centerMode: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      draggable: false,
      touchMove: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            pauseOnHover: false,
            draggable: true,
            touchMove: true,
          },
        },
      ],
    };

    return (
      <div className={cx(s.content, "reviewbox")}>
        <h4 className={s.subHeadline}>ZUFRIEDENE & ÜBERZEUGTE KUNDEN </h4>
        <h2 className={s.headline}>Expozed1 - die digitale Lösung für flexible Vermietung in jeder Branche</h2>
        <div className={cx()}>
          <Slider {...settings}>
            {items.map((item, index) => {
              return (
                <div key={index}>
                  <div className={s.itemWrapper}>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <div className={s.infoWrapper}>
                          <h5 className={s.itemSubHeadline}>{item.subHeadline}</h5>
                          <h3 className={s.itemHeadline}>{item.headline}</h3>
                          <div className={s.checkboxItems}>
                            {item.checkItems.map((item, index) => {
                              return (
                                <div key={index} className={s.checkboxItem}>
                                  <img src={CheckboxImage} />
                                  {item}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <div className={s.citiationWrapper}>
                          <div className={s.blueLine}></div>
                          <div className={s.citiationText}>"{item.citiation}"</div>
                          <div className={s.citiationAuthor}>{item.author}</div>
                          <div className={s.citiationLink}>
                            <a target="_blank" rel="noopener noreferrer" href={"https://" + item.authorLink}>
                              {item.authorLink}
                            </a>
                          </div>
                          <div className={s.authorImage}>
                            <img src={item.authorImage} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  //homeBannerImages: state.homeBannerImages
});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(SliderAnimation));

// export default withStyles(s)(SliderAnimation);

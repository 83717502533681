import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { compose, gql, graphql } from "react-apollo";
import AsSeenOn from "../../components/Home/AsSeenOn/AsSeenOn";
//Components
import BannerCaption from "../../components/Home/BannerCaption/BannerCaption";
import ContentBox1 from "../../components/static/ContentBox1/ContentBox1";
import ContentBox2 from "../../components/static/ContentBox2/ContentBox2";
import ContentBox3 from "../../components/static/ContentBox3/ContentBox3";
import FaqBox from "../../components/static/FaqBox/FaqBox";
import ReviewBox from "../../components/static/ReviewBox/ReviewBox";
import HomeBanner from "../../components/Home/HomeBanner/HomeBanner";
import ReadyBox from "../../components/Home/ReadyBox/ReadyBox";

import ContentBox1_1_Image from "./contentBox1_1.svg";
import ContentBox1_2_Image from "./contentBox1_2.svg";
import ContentBox1_3_Image from "./contentBox1_3.svg";
import ContentBox1_4_Image from "./contentBox1_4.svg";
import ContentBox1_5_Image from "./contentBox1_5.svg";
import ContentBox1_6_Image from "./contentBox1_6.svg";
import ContentBox1_7_Image from "./contentBox1_7.svg";
import contentBox3_1_Image from "./contentBox3_1.svg";
import contentBox3_2_Image from "./contentBox3_2.svg";
import contentBox3_3_Image from "./contentBox3_3.svg";
import contentBox3_4_Image from "./contentBox3_4.svg";
import contentBox3_image1 from "./contentBox3_image1.svg";
import contentBox3_image2 from "./contentBox3_image2.svg";
import contentBox3_image3 from "./contentBox3_image3.svg";
import contentBox3_image4 from "./contentBox3_image4.svg";
import keckeisenImage from "./keckeisen.png";
import bogischImage from "./bogisch.png";
import gutmannImage from "./gutmann.png";
import getHomeBanner from "./getHomeBanner.graphql";
import s from "./OfferSpace.css";

class OfferSpace extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    getHomeBanner: {
      loading: true,
    },
  };

  render() {
    const { getBannerData } = this.props;
    const { layoutType } = this.props;
    const { getHomeBannerData } = this.props;
    let getHomeBanner = getHomeBannerData && getHomeBannerData.getHomeBanner;

    const contentBox1Items = [
      {
        icon: ContentBox1_1_Image,
        headline: "Gewinn erhöhen",
        text: "Bieten sie Ihre Fläche auf expozed1.de an und generieren Sie mehr Mieteinnahmen. ",
      },
      {
        icon: ContentBox1_2_Image,
        headline: "Bedingungen festlegen",
        text: "Sie bestimmen den genauen Preis, die Verfügbarkeit und wichtige Vertragsinhalte. ",
      },
      {
        icon: ContentBox1_3_Image,
        headline: "Kostenlos Inserat erstellen",
        text: "Vermieten Sie völlig kostenlos und profitieren Sie von unverbindlichen Anfragen. ",
      },
    ];
    const contentBox2Items = [
      {
        headline: "Kostenlos registrieren und Inserat einstellen",
        text: "",
      },
      {
        headline: "Wir prüfen und veröffentlichen Ihr Angebot",
        text: "",
      },
      {
        headline: "Anfragen erhalten & Besichtigung vereinbaren",
        text: "Erhalten Sie qualifizierte Anfragen und treten Sie in persönlichen Kontakt mit potentiellen Mietern.",
      },
      {
        headline: "Fläche übergeben & Miete erhalten",
        text: "Nutzen Sie die digitale Vertragsabwicklung und profitieren Sie von einem smarten und einfachen Vermarktungsprozess.",
      },
    ];

    const contentBox1Items2 = [
      {
        icon: ContentBox1_4_Image,
        headline: "Individuelle Verfügbarkeit",
        text: "Sie bestimmen, wann ihr Objekt verfügbar ist und können es  jederzeit mit einem Klick pausieren. ",
      },
      {
        icon: ContentBox1_5_Image,
        headline: "Erhalten Sie die Miete im Vorraus",
        text: "Sie erhalten die Miete immer direkt zu Beginn des Monats.",
      },
      {
        icon: ContentBox1_6_Image,
        headline: "Einfachste Bedienung",
        text: "Durch Ihr eigenes Dashboard haben Sie alles zu jederzeit im Blick.",
      },
      {
        icon: ContentBox1_7_Image,
        headline: "Dienstleistungen anbieten",
        text: "Verdienen Sie zusätzliches Geld mit Dienstleistungen direkt vor Ort.",
      },
    ];
    const contentBox3Items = [
      {
        icon: contentBox3_1_Image,
        headline: "Mietvertrag",
        image: contentBox3_image1,
        textItems: ["Klare & fixe Bedingungen", "Rechtsicherer Buchungsprozess", "100% klare Vertragsbedingungen"],
      },
      {
        icon: contentBox3_2_Image,
        headline: "Zahlungswesen",
        image: contentBox3_image2,
        textItems: ["Automatische Zahlungsabwicklung", "Digitale Treuhandkonten", "Monatlich und Gesamtzahlung", "Keine Kaution notwendig"],
      },
      {
        icon: contentBox3_3_Image,
        headline: "Verwaltung",
        image: contentBox3_image3,
        textItems: ["Rechnung", "Checklisten", "Buchungsübersicht", "Benachrichtigung"],
      },
      {
        icon: contentBox3_4_Image,
        headline: "Sicherheit",
        image: contentBox3_image4,
        textItems: ["klare Mietbedingungen", "optionale Inhaltsversicherung", "Ein- & Auszugsschutz", "24/Service"],
      },
    ];

    const faqItems = [
      {
        triggerText: "Welche Kosten entstehen für mich als Anbieter?",
        text: "Das Inserieren auf unserer Platform ist für Sie völlig kostenlos. Unser Anspruch ist es für Sie frühzeitig einen qualifizierten Mieter zu finden. Im Falle einer erfolgreichen Vermittlung erlauben wir es uns eine Servicegebühr zu erheben. Diese ist je nach Laufzeit gestaffelt: 0-12Monate = 0,5 Monatsmieten, 12-24Monate.",
      },
      {
        triggerText: "Wie sieht der Mietvertrag aus?",
        text: "Expozed1 hat es sich zum Ziel gesetzt die Vermietung einfacher und sicherer als je zuvor zu machen. Kurzfristige Mietverhältnisse bis 12 Monate können durch unsere digitale Abwicklung daher schon jetzt ohne schriftlichen Mietvertrag abgewickelt werden und stehen jederzeit digital zum Download bereit.",
      },
      {
        triggerText: "Wie funktioniert die Bezahlung?",
        text: "Grundsätzlich bietet Expozed1 alle gängigen Zahlungsmethoden (Überweisung, SEPA-Lastschriftmandat, Kreditkarte, etc.) an. Durch unser digitales DSP2-Treuhandsystem können Mietzahlungen einfach und sicher abgewickelt, und je nach Auswahl des Anbieters genutzt werden.",
      },
      {
        triggerText: "Welche Vorteile bietet Expozed1 im Vergleich zu anderen Anbietern?",
        text: "Expozed1 hilt Eigentümern dabei Leerstände sichtbar zu machen und frühzeitig mit qualifizierten Mietern in Kontakt zu treten, darüberhinaus bietet die Plattform die Möglichkeit die gesamte Vermarktung smart und digital abzuwickeln.",
      },
      {
        triggerText: "Kann ich meinen Mieter auswählen?",
        text: "Ja, ein zentrales Thema ist die Qualifizierung der Anfragen. Mit Hilfe individueller Anpassungen können Sie genau eingrenzen, wer als Eigentümer in Frage kommt und jede Anfrage vor Terminvereinbarung bzw. Vertragsabschluss einsehen.",
      },
    ];
  

    const reviewBoxItems = [
      {
        headline: "Eigentümer & Investoren",
        subHeadline: "Kundenmeinung",
        checkItems: ["Frühzeitig Mieter finden", "Qualifizierte Anfragen erhalten", "Digital & Smart vermieten"],
        citiation: "Als Investor bietet expozed1.de mir die Möglichkeit, meine Flächen frühzeitig am Markt zu platzieren und qualifizierte Mietanfragen zu erhalten",
        author: "Holger Gutmann, Immobilieninvestor",
        authorLink: "www.gutmanngruppe.com",
        authorImage: gutmannImage ,
      },
      {
        headline: "Logistik",
        subHeadline: "Kundenmeinung",
        checkItems: ["Leerstände optimieren", "Dauerhafte Sichtbarkeit", "Zusätzliches Vermarktungstool"],
        citiation: "Die Ansprüche an Unternehemen werden immer höher. Schnelle Entscheidungen werden möglich wenn alle Informationen vorliegen. Expozed1 bietet eine Platform die dies ermöglicht",
        author: "Helge Bogisch, Bracchi Transport & Logistik",
        authorLink: "www.bracchi.it",
        authorImage: bogischImage,
      },
      {
        headline: "Handel & E-Commerce",
        subHeadline: "Branchenbeispiel",
        checkItems: ["Mieter in ganz Deutschland erreichen", "Freie Ressourcen flexibel auslasten", "Kein Verwaltungsaufwand"],
        citiation: "Vor allem in aktuellen Zeiten, bietet expozed1.de die perfekte Möglichkeit zusätzliche Einnahmen zu generieren",
        author: "Stefan Keckeisen, Geschäftsführer Intact Batterien",
        authorLink: "www.intact-batterien.de",
        authorImage: keckeisenImage,
      },
    ];

    return (
      <div className={s.root}>
        <HomeBanner
          cssClass={"offer-space"}
          headlineText={"Es gibt keinen Grund, freie Fläche nicht auf Expozed1 anzubieten."}
          searchboxHeadline1={"Jetzt zusätzliche Einnahmen generieren"}
          searchboxHeadline2={"Welche Art von Fläche möchten Sie anbieten?"}
          searchboxHeadline3={"In unter 2 Minuten - kostenlos & unverbindlich"}
          searchboxButtonText={"Jetzt Flächen anbieten"}
          link={"/funnel-vermieter"}        />

        <div>
          <AsSeenOn />

          <div className={s.contentBoxesBg}>
            <div className={s.container}>
              <ContentBox1 headline={"Mit freien Flächen Geld verdienen"} subHeadline={"Jetzt loslegen"} items={contentBox1Items} />
            </div>
          </div>

          <div className={s.container}>
            <ContentBox2 items={contentBox2Items} />
          </div>

          <div className={s.contentBoxesBg2}>
            <div className={s.container}>
              <ContentBox3 items={contentBox3Items} headline={"Wir haben schon alles für Sie erledigt"} subHeadline={"ALL IN ONE"} />
            </div>
          </div>
          <div className={s.contentBoxesBg}>
            <div className={s.container}>
              <ContentBox1 headline={"Noch mehr Vorteile für Sie"} subHeadline={"Expozed1"} items={contentBox1Items2} />
            </div>
          </div>
          <div className={s.contentBoxesBg2}>
            <div className={s.container}>
              <ReviewBox bg={"#FFF"} items={reviewBoxItems} />
            </div>
          </div>
          <div className={s.contentBoxesBg}>
            <div className={s.container}>
              <FaqBox items={faqItems} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(
    gql`
      query getBanner {
        getBanner {
          id
          title
          content
        }
      }
    `,
    {
      name: "getBannerData",
      options: {
        ssr: true,
      },
    }
  ),
  graphql(getHomeBanner, {
    name: "getHomeBannerData",
    options: {
      ssr: true,
      //fetchPolicy: 'network-only'
    },
  })
)(OfferSpace);

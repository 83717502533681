exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2ZvVt{background:#fff;color:#333;padding:0}.V9bKS{margin:0 auto;padding:0;max-width:100%;max-width:var(--max-container-width)}._1_zv1{padding:15px;position:relative;border-radius:2px;text-align:left;padding-left:50px;font-size:18px;color:#404040}.UEpL0{background:#ffd1c4}._2NWoZ{color:#e4a593;position:absolute;left:18px;top:15px;font-size:20px;-ms-transform:none;transform:none}._2wQO2{background:#bfeeb0}._20Uf2{color:#8bd275;position:absolute;left:18px;top:15px;font-size:20px;-ms-transform:none;transform:none}", ""]);

// exports
exports.locals = {
	"root": "_2ZvVt",
	"container": "V9bKS",
	"alertContainer": "_1_zv1",
	"alertDanger": "UEpL0",
	"dangerIcon": "_2NWoZ",
	"alertSuccess": "_2wQO2",
	"successIcon": "_20Uf2"
};
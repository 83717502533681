// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { reduxForm } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, Modal } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Internal Component
import PhotosUpload from "../PhotosUpload";

// Validate
import updateStep2 from "./updateStep2";

//Fontawesome
import  { FaInfoCircle, FaCameraRetro } from 'react-icons/fa';

// Helpers
import validateStep2 from "./validateStep2";

class Photos extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    listId: PropTypes.number.isRequired,
    photosCount: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAvailable: false,
      showModal: false,
    };
  }

  componentWillMount() {
    const { photosCount } = this.props;

    if (photosCount > 0) {
      this.setState({ isAvailable: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { photosCount } = nextProps;

    if (photosCount > 0) {
      this.setState({ isAvailable: true });
    } else {
      this.setState({ isAvailable: false });
    }
  }

  openModal = () => {
    this.setState({ showModal: true });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage, listId } = this.props;
    const { isAvailable, showModal } = this.state;
    const { formatMessage } = this.props.intl;
    //TODO translate
    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={12} md={12} lg={12} className={s.landingContent}>
            <div>
              <div onClick={this.openModal} className={s.photoHintModal}>
                <FaInfoCircle /> Tipps für Ihre Fotos
              </div>
              <h3 className={s.landingContentTitle}>Fügen Sie Ihrem Inserat Fotos hinzu</h3>
              <h4>
                Helfen Sie potentiellen Mietern einen bestmöglichen Eindruck von Ihrer Fläche zu bekommen. Sie können weitere Fotos auch nach dem
                Veröffentlichen hinzufügen.
              </h4>
              <form>
                <div className={s.landingMainContent}>
                  <FormGroup className={s.formGroup}>
                    <PhotosUpload listId={listId} placeholder={formatMessage(messages.photosPlaceholder)} />
                  </FormGroup>

                  <p className={s.spaceTop3}>Bitte Beachten Sie, dass Bilder mit Logos, Werbung oder anderen textlichen Darstellungen unkenntlich zu machen sind und ggf. nicht freigegeben werden können.</p>
                </div>
                <div className={cx(s.nextPosition, s.nextPositionMap)}>
                  <div className={s.nextBackButtonMap}>
                    <hr className={s.horizontalLineThrough} />
                    <FormGroup className={s.formGroup}>
                      <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                        <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("home")}>
                          <FormattedMessage {...messages.back} />
                        </Button>
                        {!isAvailable && (
                          <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage("Beschreibung")}>
                            <FormattedMessage {...messages.skip} />
                          </Button>
                        )}
                        {isAvailable && (
                          <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage("Titelbild")}>
                            <FormattedMessage {...messages.next} />
                          </Button>
                        )}
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>

        <Modal show={showModal} animation={false} onHide={this.closeModal} dialogClassName={cx(s.photoModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3 className={s.photoModalHeadline}>Tipps, wie Sie perfekte Bilder Ihrer Fläche machen</h3>
              <div className={s.photoModalItem}>
                <FaCameraRetro />{" "}
                <span>
                Nehmen Sie die Fotos im Querformat auf, um so viel wie möglich von der Fläche zu erfassen. Machen Sie die Fotos aus den Ecken des Raumes heraus, um Perspektive zu erzeugen.
                </span>
              </div>
              <div className={s.photoModalItem}>
                <FaCameraRetro />{" "}
                <span>
                Räume sehen in natürlichem Licht am besten aus. Sollten Sie die Fotos abends oder nachts aufnehmen, nutzen Sie am besten das Licht und vermeiden den Blitz.
                </span>
              </div>
              <div className={s.photoModalItem}>
                <FaCameraRetro />{" "}
                <span>
                Machen Sie Fotos von allen für den Mieter relevanten Räumen, der Außenansicht des Gebäudes, den Zugängen und relevanten Ausstattungsmerkmalen.
                </span>
              </div>

              <h4>Wir empfehlen zusätzlich einen Grundrissplan sowie einen Schnitt der Fläche hinzuzufügen.</h4>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={this.closeModal}>
                            Fertig
                          </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Grid>
    );
  }
}

Photos = reduxForm({
  form: "ListPlaceStep2", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep2,
  validate: validateStep2,
})(Photos);

const mapState = (state) => ({
  photosCount: state.location.photosCount,
});
const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Photos)));

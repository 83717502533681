exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3PWuO{margin-bottom:20px}._1xMQc{font-size:24px;font-weight:400;font-weight:700;color:#575757}._2kZ3n{margin-bottom:24px}._35GxB ._2Uu-F{width:900px}._2XaHE{margin-bottom:20px}.GIhJq{color:#0667b3;cursor:pointer}._37VBX{border-color:#0667b3;background-color:#0667b3;color:#fff;font-size:16px}._7BAiR{padding:7px 30px}._37VBX:active,._37VBX:active:focus,._37VBX:active:hover,._37VBX:focus,._37VBX:hover{border-color:#2081cd!important;background-color:#2081cd!important;color:#fff}", ""]);

// exports
exports.locals = {
	"cancelInfo": "_3PWuO",
	"cancelationModal": "_35GxB",
	"modal-dialog": "_2Uu-F",
	"h3": "_1xMQc",
	"space4": "_2kZ3n",
	"modalText": "_2XaHE",
	"modalLink": "GIhJq",
	"btnPrimary": "_37VBX",
	"btnlarge": "_7BAiR"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3zXqj{font-size:18px;color:#0667b2}._3uXBW{font-size:48px}._3zXqj span{animation-name:_1PLCb;animation-duration:1.4s;animation-iteration-count:infinite;animation-fill-mode:both;color:#0667b2}._3zXqj span:nth-child(2){animation-delay:.2s}._3zXqj span:nth-child(3){animation-delay:.4s}@keyframes _1PLCb{0%{opacity:.2}20%{opacity:1}to{opacity:.2}}._2rZAW{text-align:center}._9UNL8{color:#0667b3;font-size:48px}", ""]);

// exports
exports.locals = {
	"saving": "_3zXqj",
	"savingDots": "_3uXBW",
	"blink": "_1PLCb",
	"textCenter": "_2rZAW",
	"iconColor": "_9UNL8"
};
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./CheckItemBox.css";
import Checkmarkcircle from "./checkmarkcircle.svg";
import React from "react";

const CheckItemBox = (props) => {
  const { items } = props;
  if (items && items.length === 0) {
    return <div></div>
  } 
  return (
    <div className={s.CheckItemBox}>
      {items.map((item, index) => (
        <div key={index}>
          <img src={Checkmarkcircle} />
          {item}
        </div>
      ))}
    </div>
  );
};

export default withStyles(s)(CheckItemBox);

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1b6aK{padding-left:0;padding-right:0;overflow-x:hidden}._1iX-5{margin:0 auto;padding-bottom:24px;max-width:1080px}._30iiB{padding-top:48px}._2xdwX{color:#404040;line-height:24px;margin:0 0 24px;padding:0 15px;font-weight:700}._32wSS{font-weight:600;font-size:32px;line-height:48px;margin-bottom:50px;text-align:center;color:#222529}@media screen and (max-width:1170px){._2xdwX{text-align:center}}@media screen and (max-width:767px){.IbvS_{margin:0 0 24px 34px}}.fUR7t{background-color:#f6f9fc}._2q61k{background-color:#fff}._207qk{background:linear-gradient(90deg,#0667b3,#0c7fd8 149.65%)}.VFY04{margin-top:100px}", ""]);

// exports
exports.locals = {
	"root": "_1b6aK",
	"container": "_1iX-5",
	"pageContainer": "_30iiB",
	"containerTitle": "_2xdwX",
	"findPlace": "_32wSS",
	"marginLeft": "IbvS_",
	"contentBoxesBg": "fUR7t",
	"contentBoxesWhite": "_2q61k",
	"linearBg": "_207qk",
	"readyBox": "VFY04"
};
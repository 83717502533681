import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { formValueSelector } from 'redux-form';

// Components
import MandateConfirm from './MandateConfirm';
import BankAccount from './BankAccount';

class PayinConfirm extends Component {
  static propTypes = {
    previousPage: PropTypes.any.isRequired,
    paymentType: PropTypes.string.isRequired
  };



  render() {
    const { mandateId, previousPage, reservationId } = this.props;
    //TODO route another way this works by accident
    if (!mandateId) {
      return <BankAccount
        previousPage={previousPage}
        reservationId={reservationId}

      />
    } else  {
      return <MandateConfirm
        previousPage={previousPage}
        reservationId={reservationId}

      />
    }
  }
}

const selector = formValueSelector('PayinForm');

const mapState = (state) => ({
  mandateId: state.payin.mandateId,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayinConfirm);
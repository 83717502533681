import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import  { FaInfoCircle } from 'react-icons/fa';

import moment from "moment";
import { Row, Col, Panel, Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
// Component
import CurrencyConverter from "../CurrencyConverter";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Receipt.css";
import ExpozedLogo from "../../../public/expozed1_logo.svg";
//Images
import Faq from "./question.svg";

// Components
import Link from "../Link";

// Helper
import { generateTime } from "./helper";
import { serviceFee } from "../../config";
// Locale
import messages from "../../locale/messages";
import ListNotFound from "../../routes/listNotFound/ListNotFound";

class PaymentReceipt extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    siteName: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      listId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      discountType: PropTypes.string,
      guestServiceFee: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      listData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
        listingData: PropTypes.shape({
          checkInStart: PropTypes.string.isRequired,
          checkInEnd: PropTypes.string.isRequired,
        }),
        settingsData: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            listsettings: PropTypes.shape({
              itemName: PropTypes.string.isRequired,
            }),
          })
        ),
      }),
      hostData: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }),
      guestData: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }),
      bookingSpecialPricing: PropTypes.array,
    }),
  };

  static defaultProps = {
    data: null,
  };

  print() {
    window.print();
  }

  renderMonthlyPayments = (checkIn, checkOut, isAverage) => {
    const {
      data: { cleaningPrice, taxRate },
    } = this.props;
    const { locale } = this.props;
    moment.locale(locale);
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    const bookingPeriods = Math.floor(bookingDays / 30);
    const bookingPeriodPrice = 30 * isAverage.toFixed(2);
    // const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";

    const restDays = bookingDays % 30;
    const restPrice = restDays * isAverage.toFixed(2) + cleaningPrice;
    // const restPrice = restPriceWithoutTax + (restPriceWithoutTax / 100) * taxRate;

    const popoverRestPrice = (
      <Popover id="popover-trigger-hover-focus" title="Restbetrag">
        Der Restbetrag beinhaltet die durch die Monatsgebühr noch nicht abgedeckten Buchungstage sowie die Übergabepauschale.
        <br />
        Restbetrag Miete: <b>{(restDays * isAverage).toFixed(2)}€</b>
        <br />
        Übergabepauschale: <b>{cleaningPrice.toFixed(2)}€</b>
      </Popover>
    );

    let bookingPeriodsContent = [];
    for (let i = 1; i <= bookingPeriods; i++) {
      if (i < bookingPeriods) {
        bookingPeriodsContent.push(
          <tr key={i}>
            <th className={s.rowWidth}>
              {i + 1}. Monatsrate (
              {moment(checkIn)
                .add(30 * (i - 1) + 1, "days")
                .format("DD.MM.YYYY")}
              ):
            </th>
            <td>{bookingPeriodPrice.toFixed(2)}€</td>
          </tr>
        );
      }
    }

    //rest
    bookingPeriodsContent.push(
      <tr>
        <th className={s.rowWidth}>
          Restbetrag (
          {moment(checkIn)
            .add(30 * (bookingPeriods - 1), "days")
            .format("DD.MM.YYYY")}
          ):
          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRestPrice}>
            <FaInfoCircle />
          </OverlayTrigger>
        </th>
        <td>{restPrice.toFixed(2)}€</td>
      </tr>
    );

    return (
      <React.Fragment>
        <tr>
          <th className={s.rowWidth}>Abbuchungen (zzgl. MwSt.)</th>
          <td></td>
        </tr>
        <tr>
          <th className={s.rowWidth}>1. Monatsrate (bei Buchung):</th>
          <td>{bookingPeriodPrice.toFixed(2)}€</td>
        </tr>
        {bookingPeriodsContent}
        <tr>
          <th className={s.rowWidth}></th>
          <td></td>
        </tr>
      </React.Fragment>
    );
  };
  addPercentage(total, per) {
    return total + (per / 100) * total;
  }
  render() {
    const { data, siteName, userId } = this.props;
    const { formatMessage } = this.props.intl;
    const { locale } = this.props.intl;
    moment.locale(locale);
    function LinkWithTooltip({ id, children, href, tooltip }) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip className={s.tooltip} id={id}>
              {tooltip}
            </Tooltip>
          }
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {/* <a href={href}>{children}</a> */}
          {children}
        </OverlayTrigger>
      );
    }

    if (data === null) {
      return (
        <div>
          {" "}
          <FormattedMessage {...messages.errorMessage} />{" "}
        </div>
      );
    } else if (data.listData === null) {
      return <ListNotFound />;
    } else {
      const {
        data,
        data: { id, checkIn, checkOut, confirmationCode, createdAt, updatedAt, hostId, guestId, isMonthly },
      } = this.props;
      const {
        data: { basePrice, cleaningPrice, total, discount, discountType, guestServiceFee, currency, hostServiceFee, taxRate },
      } = this.props;
      const {
        data: { hostData, guestData },
      } = this.props;
      const {
        data: {
          transaction: { transactionId, paymentMethodId, last4Digits },
        },
      } = this.props;
      const receiptId = data.transaction.id;
      const {
        data: {
          listData: { title, street, city, state, country, zipcode },
        },
      } = this.props;
      // const { data: { listData: { listingData: { checkInStart, checkInEnd } } } } = this.props;
      const {
        data: {
          listData: { settingsData, listingData },
        },
      } = this.props;
      const {
        data: { bookingSpecialPricing },
      } = this.props;
      let {
        data: { checkInStart, checkInEnd },
      } = this.props;

      moment.locale(locale);

      let roomType = settingsData[0].listsettings.itemName;
      let createdDate = createdAt ? moment(createdAt).format("ddd, MMM Do, YYYY ") : "";
      let updatedDate = updatedAt ? moment(updatedAt).format("ddd, MMM Do, YYYY ") : "";
      let checkInDate = checkIn ? moment(checkIn).format("ddd, Do MMM") : "";
      let checkOutDate = checkOut ? moment(checkOut).format("ddd, Do MMM") : "";
      let momentStartDate,
        momentEndDate,
        dayDifference,
        dayPrice = 0,
        checkInTime,
        checkOutTime;
      let isSpecialPricingAssinged = bookingSpecialPricing && bookingSpecialPricing.length > 0 ? true : false;
      //@TODO disable special pricing to avoid errors
      isSpecialPricingAssinged = false;
      let isAverage = 0,
        subTotal,
        userType;
      let isDayTotal = 0;
      let checkInTimeFormat;
      checkInStart = checkInStart ? checkInStart : listingData.checkInStart;
      checkInEnd = checkInEnd ? checkInEnd : listingData.checkInEnd;
      if (checkIn != null && checkOut != null) {
        momentStartDate = moment(checkIn);
        momentEndDate = moment(checkOut);
        dayDifference = momentEndDate.diff(momentStartDate, "days") + 1;
        // dayPrice = basePrice * dayDifference;
        if (isSpecialPricingAssinged) {
          bookingSpecialPricing &&
            bookingSpecialPricing.map((item, index) => {
              dayPrice = dayPrice + Number(item.isSpecialPrice);
            });
        } else {
          dayPrice = basePrice * dayDifference;
        }
      }

      if (checkInStart !== "Flexible") {
        checkInTime = generateTime(checkInStart);
      }

      if (checkInEnd !== "Flexible") {
        checkOutTime = generateTime(checkInEnd);
      }

      if (checkInStart === "Flexible" && checkInEnd === "Flexible") {
        checkInTimeFormat = formatMessage(messages.flexibleCheckIn);
      } else if (checkInStart !== "Flexible" && checkInEnd === "Flexible") {
        checkInTimeFormat = "From " + checkInTime;
      } else if (checkInStart === "Flexible" && checkInEnd !== "Flexible") {
        checkInTimeFormat = "Upto " + checkOutTime;
      } else if (checkInStart !== "Flexible" && checkInEnd !== "Flexible") {
        checkInTimeFormat = checkInTime + " - " + checkOutTime;
      }
      const dayPriceNoFee = dayPrice - (dayPrice / (100 + serviceFee)) * serviceFee;
      const cleaningPriceNoFee = cleaningPrice - (cleaningPrice / (100 + serviceFee)) * serviceFee;
      // const hostServiceFee = (dayPriceNoFee + cleaningPriceNoFee) / serviceFee
      const hostServiceFeeWithTax = this.addPercentage(hostServiceFee, taxRate);

      if (userId === hostId) {
        userType = "host";
        subTotal = total - hostServiceFeeWithTax;
      } else {
        userType = "guest";
        subTotal = total + guestServiceFee;
      }

      isAverage = Number(dayPrice) / Number(dayDifference);
      isDayTotal = isAverage.toFixed(2) * dayDifference;
      dayPrice = isDayTotal;
      let paymentMethod = {
        2: "Sofort (Klarna)",
        3: "Giropay",
        4: "Kreditkarte",
      };

      let discountLessBasePrice = isDayTotal;
      let taxRateFee = taxRate && taxRate > 0 ? (discountLessBasePrice + cleaningPrice) * (Number(taxRate) / 100) : 0;
      const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
      const bookingPeriods = Math.floor(bookingDays / 30);
      const bookingPeriodPriceWithOutTax = 30 * isAverage.toFixed(2);
      const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
      return (
        <div className={s.container}>
          <div className={cx(s.spaceTop1)}>
            <a className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.printButton, "hidden-print")} onClick={this.print}>
              Drucken
            </a>
          </div>
          <div className={cx(s.containerResponsive, s.spaceTop4)}>
            <div>
              <Panel className={s.receiptPanel}>
                <h2>
                  <FormattedMessage {...messages.customerReceipt} />
                </h2>
                <div className={s.logo}>
                  <img src={ExpozedLogo} alt="expozed1" />
                </div>

                <div className={s.space1}>
                  <h6>Buchungsnummer</h6>
                </div>
                <div className={s.space1}>
                  <h4>{confirmationCode}</h4>
                </div>
                <hr />
                <Row className={s.space4}>
                  <Col sm={4} md={4} lg={4}>
                    <span className={s.textBold}>Mieter</span>
                    <p>
                      {guestData.displayName}
                      <br />
                      {guestData.address1}
                      <br />
                      {guestData.address2}
                      <br />
                      {guestData.zip} {guestData.city}
                      <br />
                      {guestData.countryName}
                      <br />
                      Steuer ID: {guestData.taxId}
                      <br />
                    </p>
                  </Col>
                  <Col sm={4} md={4} lg={4}>
                    <span className={s.textBold}>Vermieter</span>
                    <p>
                      {hostData.displayName}
                      <br />
                      {hostData.address1}
                      <br />
                      {hostData.address2} <br />
                      {hostData.zip} {hostData.city}
                      <br />
                      {hostData.countryName}
                      <br />
                      Steuer ID: {hostData.taxId}
                      <br />
                    </p>
                  </Col>

                  <Col sm={4} md={4} lg={4}>
                    <span style={{ float: "right", paddingRight: "20px" }} className={s.textBold}>
                      {createdDate}
                    </span>
                    <br />
                    <span style={{ float: "right", paddingRight: "20px" }}>Rechnungsnummer: {receiptId}</span>
                  </Col>
                </Row>
                <hr />

                <Row className={s.space4}>
                  <Col sm={3} md={3} lg={3}>
                    <span className={s.textBold}>Objektadresse</span>
                    <h4>{title}</h4>
                    <p>
                      <span>{street}</span> <br />
                      <span>
                        {city}, {state} {zipcode}
                      </span>{" "}
                      <br />
                      <span>{country}</span> <br />
                    </p>
                  </Col>
                  <Col sm={3} md={3} lg={3}>
                    <span className={s.textBold}>Flächenart</span>
                    <p>{roomType}</p>
                  </Col>
                  <Col sm={3} md={3} lg={3}>
                    <span className={s.textBold}>
                      <FormattedMessage {...messages.checkIn} />
                    </span>
                    <p>
                      <span>{checkInDate}</span>
                      {/* <br />
                    <span>{checkInTimeFormat}</span> */}
                    </p>
                  </Col>
                  <Col sm={3} md={3} lg={3}>
                    <span className={s.textBold}>
                      <FormattedMessage {...messages.checkOut} />
                    </span>
                    <p>
                      <span>{checkOutDate}</span>
                      <br />
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h2>Buchungsgebühren</h2>

                    <table className={cx("table", "table-bordered")}>
                      <tbody>
                        {isMonthly && this.renderMonthlyPayments(checkIn, checkOut, isAverage)}
                        <tr>
                          <th className={s.rowWidth}>Abrechnung</th>
                          <td></td>
                        </tr>
                        <tr className={cx(s.positionR)}>
                          <th className={s.rowWidth}>
                            <div className={cx(s.specialPriceIcon, "hidden-print")}>
                              {isSpecialPricingAssinged && (
                                <span className={s.iconSection}>
                                  <img src={Faq} className={s.faqImage} />
                                </span>
                              )}
                              <div className={cx(s.tltip, s.relativeSection)}>
                                <FormattedMessage {...messages.recepitInfoDesc} />
                              </div>
                            </div>
                            <CurrencyConverter
                              // amount={basePrice}
                              amount={isAverage}
                              from={currency}
                            />
                            &nbsp;x {dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                            {/* {
                                                        isSpecialPricingAssinged && <LinkWithTooltip
                                                            tooltip="Average rate per night for your trip."
                                                            // href="#"
                                                            id="tooltip-1"
                                                        >
                                                            <span className={s.iconSection}>
                                                                <FaQuestion className={s.instantIcon} />
                                                            </span>
                                                        </LinkWithTooltip>
                                                    } */}
                          </th>

                          <td>
                            <CurrencyConverter amount={dayPrice} from={currency} />
                          </td>
                        </tr>

                        {/* {
                                                taxRate > 0 && <tr>
                                                    <th className={s.rowWidth}>
                                                        <FormattedMessage {...messages.taxRate} />
                                                    </th>
                                                    <td>
                                                        <CurrencyConverter
                                                            amount={taxRate}
                                                            from={currency}
                                                        />
                                                    </td>
                                                </tr>
                                            } */}
                        {discount > 0 && (
                          <tr>
                            <th className={s.rowWidth}>{discountType}</th>
                            <td>
                              - <CurrencyConverter amount={discount} from={currency} />
                            </td>
                          </tr>
                        )}
                        {userType === "guest" && guestServiceFee > 0 && (
                          <tr>
                            <th className={s.rowWidth}>
                              <FormattedMessage {...messages.serviceFee} />
                            </th>
                            <td>
                              <CurrencyConverter amount={guestServiceFee} from={currency} />
                            </td>
                          </tr>
                        )}

                        {cleaningPrice > 0 && !isMonthly && (
                          <tr>
                            <th className={s.rowWidth}>
                              {isMonthly && <span>Inkl. </span>}
                              <FormattedMessage {...messages.cleaningFee} />
                            </th>
                            <td>
                              <span>
                                <CurrencyConverter amount={isMonthly ? cleaningPriceWithTax : cleaningPrice} from={currency} />
                              </span>
                            </td>
                          </tr>
                        )}
                        {taxRateFee > 0 && (
                          <tr>
                            <th className={s.rowWidth}>zzgl. MwSt. {taxRate}%:</th>
                            <td>
                              <CurrencyConverter amount={taxRateFee} from={currency} />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th className={s.rowWidth}>
                            <FormattedMessage {...messages.total} />
                          </th>
                          <td>
                            <CurrencyConverter amount={total} from={currency} />
                          </td>
                        </tr>

                        {userType === "host" && hostServiceFee > 0 && (
                          <React.Fragment>
                            <tr>
                              <th className={s.rowWidth}>
                                <FormattedMessage {...messages.serviceFee} />
                              </th>
                              <td>
                                {" "}
                                - &nbsp;
                                <CurrencyConverter amount={hostServiceFeeWithTax} from={currency} />
                              </td>
                            </tr>
                            <tr>
                              <th className={s.rowWidth}>Mietertrag</th>
                              <td>
                                <CurrencyConverter amount={subTotal} from={currency} />
                              </td>
                            </tr>
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                    {userType === "guest" && (
                      <table className={cx("table", "table-bordered")}>
                        <tbody>
                          <tr>
                            <th className={s.paid}>bezahlt</th>
                            <td className={s.paid2}>
                              <FormattedMessage {...messages.paymentReceived} /> {updatedDate}
                            </td>
                          </tr>
                          <tr>
                            <th className={s.rowWidth}>Betrag:</th>
                            <td>
                              {isMonthly ? (
                                <CurrencyConverter amount={bookingPeriodPrice} from={currency} />
                              ) : (
                                <CurrencyConverter amount={subTotal} from={currency} />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className={s.rowWidth}>Transaktionsnummer:</th>
                            <td>{transactionId}</td>
                          </tr>
                          <tr>
                            <th className={s.rowWidth}>Zahlungsmethode:</th>
                            <td>{isMonthly ? "SEPA-Lastschrift (**********" + last4Digits + ")" : paymentMethod[paymentMethodId]}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </Col>
                </Row>
              </Panel>
            </div>
            <div className={s.spaceTop4}>
              <p>
                {siteName} <FormattedMessage {...messages.receiptInfo1} /> <FormattedMessage {...messages.receiptInfo2} /> {siteName}{" "}
                <FormattedMessage {...messages.receiptInfo3} /> {siteName} geregelt.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  userId: state.account.data.userId,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PaymentReceipt)));

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DayPickerSingleDateController } from "react-dates";
import { Grid, Row, Col, Button } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./FunnelVermieter.css";
import OneImage from "./1.svg";
import BiggerOneImage from "./bigger1.svg";
import VermietetImage from "./vermietet.svg";
import LeerstehendImage from "./leerstehend.svg";
import {  Checkbox } from "antd"; // Translation
import s4 from "!isomorphic-style-loader!css-loader!antd/dist/antd.css";

import s2 from "!isomorphic-style-loader!css-loader!rc-slider/assets/index.css";
import s3 from "!isomorphic-style-loader!css-loader!sass-loader!react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { Event } from "../../components/Tracking/GA";
import history from "../../core/history";

import ListingSize1Image from "./listing_size_1.svg";
import ListingSize2Image from "./listing_size_2.svg";
import ListingSize3Image from "./listing_size_3.svg";
import ListingSize4Image from "./listing_size_4.svg";
import ListingType1Image from "./listing_type_1.svg";
import ListingType2Image from "./listing_type_2.svg";
import ListingType3Image from "./listing_type_3.svg";
class FunnelMieter extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      actualStep: 1,
      selectedState: null,
      selectedListingType: null,
      selectedListingCount: null,
      selectedListingSize: null,
      selectedStartDate: null,
      selectedDuration: null,
      selectedListingStatus: null,
      focusedInput: null,
      showNumberListings: false,
      listingCount: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      showCalendar: false,
    };
  }

  nextStep = () => {
    console.log("NEXT");
    this.setState({ actualStep: this.state.actualStep + 1 });
    Event("funnel-vermieter", "step", this.state.actualStep + 1);
  };
  previousStep = () => {
    this.setState({ actualStep: this.state.actualStep - 1 });
  };
  setSelectedState = (state) => {
    this.setState({ selectedState: state });
  };
  setSelectedListingType = (type) => {
    this.setState({ selectedListingType: type });
    this.nextStep();
  };
  setSelectedListingCount = (count) => {
    this.setState({ selectedListingCount: count });
    this.nextStep();
  };
  setSelectedListingStatus = (status) => {
    this.setState({ selectedListingStatus: status });
    this.nextStep();
  };
  setSelectedListingSize = (size) => {
    this.setState({ selectedListingSize: size });
    this.nextStep();
  };
  setSelectedStartDate = (date) => {
    this.setState({ selectedStartDate: date.format("DD.MM.YYYY") });
    this.nextStep();
  };
  setSelectedDuration = (duration) => {
    this.setState({ selectedDuration: duration });
    this.nextStep();
  };
  showListingCount = () => {
    this.setState({ showNumberListings: true });
  };

  onCheckboxChange = (e) => {
    this.setState({checked: e.target.checked})
  }


  async onSliderChange(value) {
    const { change } = this.props;
    this.setState(
      {
        selectedDuration: value,
      },
      () => {
        console.log(this.state.selectedDuration);
      }
    );
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    let tempState = this.state;
    tempState[name] = value;
    // this.setState(newValue, () => {
    //   this.validateForm();
    // });
    this.setState(tempState);
  };

  getCookie = (name) => {
    let re = new RegExp(name + "=([^;]+)");
    let value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };

  sendFormToHubspot = () => {
    const { firstname, lastname, phone, email, selectedListingCount, selectedListingSize, selectedListingType, selectedListingStatus, selectedStartDate } = this.state;
    // Replace portalId and formId with information from your FORM
    let portalId = "8224909";
    let formId = "f5740dbc-9147-4b69-b751-b7e9111f8eae";
    let url = "https://api.hsforms.com/submissions/v3/integration/submit/" + portalId + "/" + formId;
    // Build request JSON:

    const listingSizeMapping = {
      "250_500": "250qm - 500qm",
      "500_1500": "500qm - 1.500qm",
      "1500_5000": "500qm - 1.500qm",
      "5000+": "> 5.000qm",
    };
    let hubspotData = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: firstname,
        },
        {
          name: "lastname",
          value: lastname,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "anzahl_flachen",
          value: String(selectedListingCount),
        },
        {
          name: "flachengro_e",
          value: listingSizeMapping[selectedListingSize],
        },
        {
          name: "flachenart",
          value: selectedListingType,
        },
        {
          name: "flachenstatus",
          value: selectedListingStatus,
        },
        {
          name: "vermietet_bis",
          value: moment(selectedStartDate).format("DD.MM.YYYY"),
        },
      ],
      context: {
        hutk: this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Expozed1 to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999, // replace with your subscription ID
              text: "I agree to receive marketing communications from Expozed1.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(hubspotData),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Anfrage", "Wir haben Ihre Anfrage erhalten und melden uns schnellst möglich bei Ihnen.");

          this.setState({ submitted: true });
        } else {
          toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
      });
  };

  sendForm = () => {
    this.sendFormToHubspot();
    history.push("/become-a-host?mode=new");
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput: focusedInput.focused });
  };
  onDateChange = (date) => {
    this.setState({ selectedStartDate: date });
    this.nextStep();
  };
  changeListingCount(e) {
    this.setState({ selectedListingCount: e.target.value });
  }
  showCalendar = () => {
    this.setState({ showCalendar: true, selectedListingStatus: "Vermietet" });
  };

  returnYears = () => {
    let years = [];
    for (let i = moment().year() - 100; i <= moment().year(); i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    </div>
  );

  renderStep = (stepNumber) => {
    const { firstname, lastname, email, phone, checked } = this.state;
    moment.locale("de");
    if (stepNumber === 1) {
      return (
        <div>
          <h3 className={s.headline}>Wie viele Flächen wollen Sie vermieten?</h3>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingCount(1)}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={OneImage} />
                </div>
              </div>
              <div className={s.clickLabel}>Eine Fläche</div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              {!this.state.showNumberListings ? (
                <div className={s.clickItem} onClick={() => this.showListingCount()}>
                  <div className={s.itemImageWrapper}>
                    <img className={s.itemImage} src={BiggerOneImage} />
                  </div>
                </div>
              ) : (
                <div className={cx(s.formItem, s.clickItem, s.listingCount)}>
                  <span className={s.label}>Anzahl der Flächen</span>

                  <input
                    name="listingcount"
                    type="text"
                    value={this.state.listingCount}
                    label={"Anzahl der Flächen"}
                    className={cx(s.formControlInput, s.commonBorder)}
                    onChange={(e) => this.changeListingCount(e)}
                  />
                  <Button disabled={!this.state.selectedListingCount} onClick={this.nextStep} className={s.nextButton}>
                    Weiter
                  </Button>
                </div>
              )}

              <div className={s.clickLabel}>Mehrere Flächen</div>
            </Col>
          </Row>
        </div>
      );
    }

    if (stepNumber === 2) {
      return (
        <div>
          <h3 className={s.headline}>Wie groß ist Ihre Fläche?</h3>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={cx(s.clickItem, s.clickItemSmall)} onClick={() => this.setSelectedListingSize("50_500")}>
                <div className={s.itemImafxdfgdvgeWrapper}>
                  <img src={ListingSize1Image} />
                </div>
              </div>
              <div className={s.clickLabel}>50qm - 500qm</div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={cx(s.clickItem, s.clickItemSmall)} onClick={() => this.setSelectedListingSize("500_1500")}>
                <div className={s.itemImageWrapper}>
                  <img src={ListingSize2Image} />
                </div>
              </div>
              <div className={s.clickLabel}>500qm - 1.500qm</div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={cx(s.clickItem, s.clickItemSmall)} onClick={() => this.setSelectedListingSize("1500_5000")}>
                <div className={s.itemImageWrapper}>
                  <img src={ListingSize3Image} />
                </div>
              </div>
              <div className={s.clickLabel}>1.500qm - 5.000qm</div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={cx(s.clickItem, s.clickItemSmall)} onClick={() => this.setSelectedListingSize("5000+")}>
                <div className={s.itemImageWrapper}>
                  <img src={ListingSize4Image} />
                </div>
              </div>
              <div className={s.clickLabel}>{">"} 5.000qm</div>
            </Col>
          </Row>
        </div>
      );
    }

    if (stepNumber === 3) {
      return (
        <div>
          <h3 className={s.headline}>Um was für eine Fläche handelt es sich?</h3>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingType("Lager oder Logistik")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={ListingType1Image} />
                </div>
              </div>
              <div className={s.clickLabel}>Lager oder Logistik</div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingType("Produktion")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={ListingType2Image} />
                </div>
              </div>
              <div className={s.clickLabel}>Produktion</div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingType("Außenfläche")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={ListingType3Image} />
                </div>
              </div>
              <div className={s.clickLabel}>Außenfläche</div>
            </Col>
          </Row>
        </div>
      );
    }

    if (stepNumber === 4) {
      return (
        <div>
          <h3 className={s.headline}>Sind Ihre Flächen derzeit vermietet? </h3>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={6} lg={6}>
              {!this.state.showCalendar ? (
                <div className={s.clickItem} onClick={() => this.showCalendar()}>
                  <div className={s.itemImageWrapper}>
                    <img className={s.itemImage} src={VermietetImage} />
                  </div>
                </div>
              ) : (
                <div className={s.clickItem}>
                  <div className={s.rentHeadline}>
                    Bis wann läuft die derzeitige Miete?
                    <div className={s.calendar}>
                      <DayPickerSingleDateController onDateChange={this.onDateChange} hideKeyboardShortcutsPanel numberOfMonths={1} />
                    </div>
                  </div>
                </div>
              )}

              <div className={s.clickLabel}>Derzeit vermietet</div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingStatus("Leerstehend")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={LeerstehendImage} />
                </div>
              </div>
              <div className={s.clickLabel}>Leerstehend</div>
            </Col>
          </Row>
        </div>
      );
    }
    if (stepNumber === 5) {
      return (
        <div>
          <h3 className={s.headline}>Vielen Dank für Ihre Angaben zu Ihrem Objekt</h3>
          <h5 className={s.subHeadline}>
            Um Ihre bisherigen Angaben zu speichern, legen wir Ihnen einen persönlichen Account an. Dazu benötigen wir zunächst Ihre Kontaktdaten.
          </h5>

          <div className={s.formItem}>
            <span className={s.label}>Vorname</span>

            <input
              name="firstname"
              type="text"
              value={this.state.firstname}
              label={"Vorname"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={s.formItem}>
            <span className={s.label}>Nachname</span>

            <input
              name="lastname"
              type="text"
              value={this.state.lastname}
              label={"Nachname"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={s.formItem}>
            <span className={s.label}>E-Mail-Adresse</span>

            <input
              name="email"
              type="text"
              value={this.state.email}
              label={"E-Mail-Adresse"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={s.formItem}>
            <span className={s.label}>Telefonnummer</span>

            <input
              name="phone"
              type="text"
              value={this.state.phone}
              label={"Telefonnummer"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={cx(s.formItem,s.checkBox)}>
          <Checkbox onChange={this.onCheckboxChange}>Ich erkläre mich mit der Verarbeitung der gemachten Angaben zur Kontaktaufnahme durch die Expozed GmbH einverstanden.</Checkbox>
          </div>
          <Button disabled={!checked || !firstname || !lastname || !email || !phone} onClick={this.sendForm} className={s.nextButton}>
            Jetzt starten
          </Button>
          <div className={s.subButton}>Kostenlos & unverbindlich</div>
        </div>
      );
    }
  };

  render() {
    console.log("state", this.state);
    const { siteName } = this.props;
    const { actualStep } = this.state;

    return (
      <div className={s.container}>
        <Grid fluid>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={s.stepper}>
                <div className={cx(s.item, actualStep === 1 ? s.activeItem : "")}>1</div>
                <div className={cx(s.item, actualStep === 2 || actualStep === 3 ? s.activeItem : "")}>2</div>
                <div className={cx(s.item, actualStep === 4 ? s.activeItem : "")}>3</div>
                <div className={cx(s.item, actualStep === 5 ? s.activeItem : "")}>4</div>
              </div>
            </Col>
          </Row>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              {this.renderStep(actualStep)}

              {actualStep > 1 && (
                <Button onClick={this.previousStep} className={s.prevButton}>
                  zurück
                </Button>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {};

export default withStyles(s, s2, s3, s4)(connect(mapState, mapDispatch)(FunnelMieter));

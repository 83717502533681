// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { reduxForm, formValueSelector } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component
import ListPlaceTips from "../ListPlaceTips";
import CustomCheckbox from "../CustomCheckbox";

// Helpers
import validateStep3 from "./validateStep3";

class LocalLaws extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    minNightData: PropTypes.number,
    maxNightData: PropTypes.number,
    siteName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      termsAccepted: false,
    };
  }

  static defaultProps = {
    minNightData: 0,
    maxNightData: 0,
  };

  setTermsAccepted = (value) => {
    this.setState({ termsAccepted: value });
  };

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage, formErrors, minNightData, maxNightData } = this.props;
    const { siteName } = this.props;
    const { termsAccepted } = this.state;
    let isDisabled = false;

    if (!termsAccepted) {
      isDisabled = true;
    }
    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <h3>Nutzungsbedingungen und Sicherheitsrichtlinien</h3>
            <p className={cx(s.textHigh, s.space3)}>
              <span>Nehmen Sie sich einen Moment Zeit um sich über unsere Nutzungsbedingungen und gültige Gesetzmäßigkeiten in Kenntnis zu setzen.</span>
            </p>
            <form onSubmit={handleSubmit}>
              <div className={s.landingMainContent}>
                <div className={cx(s.textLow, s.space5)}>
                  <h4>Sie sind rundum geschützt</h4>
                  <p>
                    <span>
                      Für den seltenen Fall, dass Probleme auftreten, unterstützt Sie Expozed1 mit dem täglich rund um die Uhr verfügbaren Kundenservice und
                      bietet eine rechtssichere Verwaltung und Dokumentation jeder Buchung.
                    </span>
                  </p>
                  <h4>Weniger Verwaltung und mehr Rendite</h4>
                  <p>
                    <span>Steigern Sie Ihre Rendite und nutzen Sie die gewonnene Zeit für wichtige Dinge.</span>
                  </p>

                  <div className={s.checkboxInfo}>
                    <span className={s.checkBoxSection}>
                      <CustomCheckbox
                        name="termsAccepted"
                        value={true}
                        checked={termsAccepted}
                        className={"icheckbox_square-green"}
                        onChange={(event) => {
                          // const newValue = [...input.value];
                          this.setTermsAccepted(event);
                        }}
                      />
                    </span>
                    <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
                      <label className={cx(s.checkboxLabel, s.noPadding)}>
                        Mit Erstellung ihres Inserates bestätigen Sie mit den <a href="/page/agb" target="_blank" ref="noopener"> Nutzungsbedingungen</a>,{" "}
                        <a href="/page/datenschutz" target="_blank" ref="noopener">Datenschutzrichtlinien</a> und den{" "}
                        <a href="/page/stornierungsbedingungen" target="_blank" ref="noopener">Zahlungs- und Stornierungsbedingungen</a> einverstanden zu sein. Alle Buchungsvorgänge
                        unterliegen den geltenden Gesetzen und Bestimmungen.
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div className={s.nextPosition}>
                <div className={s.nextBackButton}>
                  <hr className={s.horizontalLineThrough} />

                  <FormGroup className={s.formGroup}>
                    <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                      <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Kalender")}>
                        <FormattedMessage {...messages.back} />
                      </Button>
                      <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} type="submit" disabled={isDisabled}>
                        <FormattedMessage {...messages.next} />
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </form>
          </Col>
        </Row>

       
      </Grid>
    );
  }
}

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep3"); // <-- same as form name

LocalLaws = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
})(LocalLaws);

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  listingFields: state.listingFields.data,
  formErrors: state.form.ListPlaceStep3,
  minNightData: selector(state, "minNight"),
  maxNightData: selector(state, "maxNight"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(LocalLaws)));

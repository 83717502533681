import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./SideMenu.css";
import { Button, Col, FormGroup } from "react-bootstrap";
import cx from "classnames";

// Locale
import messages from "../../../locale/messages";

// Component
import Link from "../../Link";
import history from "../../../core/history";

class SideMenu extends React.Component {
  static propTypes = {};

  handleClick() {
    history.push("/become-a-host");
  }

  render() {
    return (
      <Col xs={12} sm={3} md={3} lg={3} className={s.smPadding}>
        <ul className={cx(s.listContainer, "sidemenu-managelistings")}>
          <li>
            <Link to={"/spaces"} className={s.sideNavitem} activeCss={s.active}>
              <FormattedMessage {...messages.yourListings} />
            </Link>
          </li>
          <li>
            <Link to={"/rentals/current"} className={s.sideNavitem} activeCss={s.active}>
              <FormattedMessage {...messages.upcomingReservations} />
            </Link>
          </li>
          <li>
            <Link to={"/rentals/previous"} className={s.sideNavitem} activeCss={s.active}>
              <FormattedMessage {...messages.previousReservations} />
            </Link>
          </li>

          <li>
            <Link to={"/user/transaction"} className={s.sideNavitem} activeCss={s.active}>
              <FormattedMessage {...messages.transactionHistory} />
            </Link>
          </li>
        </ul>
        <Col xs={12} sm={11} md={12} lg={7} className={cx(s.noPadding, s.space2, s.spaceTop2)}>
          <FormGroup className={s.formGroup}>
            <Button className={cx(s.button, s.btnPrimary, s.sideMenuBtn)} onClick={this.handleClick}>
              <FormattedMessage {...messages.addListing} />
            </Button>
          </FormGroup>
        </Col>
      </Col>
    );
  }
}

export default withStyles(s)(SideMenu);

import { gql } from "react-apollo";
import {
  MANGOPAY_CREATE_USER_WALLET_START,
  MANGOPAY_CREATE_USER_WALLET_SUCCESS,
  MANGOPAY_CREATE_USER_WALLET_ERROR,
} from "../../constants";

// Helper
import { convert } from "../../helpers/currencyConvertion";

import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";

import { toastr } from "react-redux-toastr";

export function createUserWithWallet(
  userData
) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: MANGOPAY_CREATE_USER_WALLET_START,
      payload: {
        paymentLoading: true,
      },
    });
    try {
      const {
        status,
        errorMessage,
      } = await processMangopayPayment(
        "create-user-with-wallet",
        userData, null, null
      );


      if (status === 200) {
        await dispatch({
          type: MANGOPAY_CREATE_USER_WALLET_SUCCESS,
          payload: { paymentLoading: true },
        });

        return {
          status,
        };
      } else {
        errorMessage ? toastr.error("Failed!", errorMessage) : "";
        await dispatch({
          type: MANGOPAY_CREATE_USER_WALLET_ERROR,
          payload: { paymentLoading: false },
        });
        return {
          status,
        };
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: MANGOPAY_CREATE_USER_WALLET_ERROR,
        payload: {
          error,
          paymentLoading: false,
        },
      });
      return false;
    }

  };
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url, sitename } from "../../../config";

class KycFail extends Component {
  static propTypes = {
    content: PropTypes.shape({
      kycType: PropTypes.string.isRequired,
      kycError: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: "#404040",
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const linkText = {
      color: "#0667B3",
      fontSize: "16px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const kycTypeMapping = {
      IDENTITY_PROOF: "Personalausweis/Reisepass",
      ARTICLES_OF_ASSOCIATION: "Gesellschaftsvertrag",
      REGISTRATION_PROOF: "Handelsregisterauszug",
    };

    const kycErrorMapping = {
      DOCUMENT_UNREADABLE: "Das vorliegende Dokument kann leider nicht gelesen werden.",
      DOCUMENT_NOT_ACCEPTED: "Das vorliegende Dokument wurde abgelehnt.",
      DOCUMENT_MISSING: "Ein notwendiges Dokument liegt nicht vor. ",
      DOCUMENT_HAS_EXPIRED: "Das vorliegende Dokument ist leider abgelaufen.",
      DOCUMENT_INCOMPLETE: "Das vorliegende Dokument ist leider unvollständig.",
      DOCUMENT_DO_NOT_MATCH_USER_DATA: "Das vorliegende Dokument stimmt nicht mit den vorliegenden Daten des Nutzerkontos überein.",
      DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA: "Das vorliegende Dokument stimmt nicht mit den vorliegenden Daten des Nutzerkontos überein.",
      DOCUMENT_FALSIFIED: "Das vorliegende Dokument ist leider fehlerhaft.",
      "UNDERAGE PERSON": "Die zu prüfende Person hat die Volljährigkeit noch nicht erreicht.",
      SPECIFIC_CASE: "Sonderfall ",
    };

    const {
      content: { firstName, lastName, companyName, kycError, kycType, kycErrorReason, logo },
    } = this.props;
    let kycTypeText = kycTypeMapping[kycType];
    let kycErrorText = kycErrorMapping[kycError];

    if (kycError === "SPECIFIC_CASE") {
      kycErrorText = kycErrorMapping[kycError] + kycErrorReason;
    }

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hallo {firstName},</div>
                  <EmptySpace height={20} />
                  <div>
                    Leider konnten wir eines Ihrer Dokumente({kycTypeText}) nicht erfolgreich verifizieren.{kycErrorText}
                    <br />
                    <br />
                    Das ungültige Dokument wurde entfernt.<br />
                    Bitte laden Sie neue Dateien hoch. Unser Support Team steht Ihnen gern zur Verfügung, falls Sie
                    Fragen haben sollten oder Unterstützung benötigen.
                  </div>

                  <EmptySpace height={20} />
                  <div>
                    Vielen Dank, <br />
                    Ihr {sitename} Team
                  </div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default KycFail;

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1h6nj{padding-left:20px;padding-right:20px}._3jcDk{margin:0 auto;padding:0 0 40px;max-width:1080px;max-width:var(--max-content-width)}._3FTGC{font-size:145px}._3FTGC,.Yffl4{font-weight:700;margin:0}.Yffl4{font-size:85px}.qyw-B{text-align:center}.XqSa_{padding-left:0;list-style:none}._82mhy{font-size:15px;color:#404040}a,a:hover{color:#0667b2}.sPqdm{margin-top:12px}._3pj_N{margin-bottom:12px}._2_FwB{margin-bottom:48px}._12Rc5{margin-top:48px}", ""]);

// exports
exports.locals = {
	"root": "_1h6nj",
	"container": "_3jcDk",
	"textJumbo": "_3FTGC",
	"textMedium": "Yffl4",
	"textCenter": "qyw-B",
	"listStyled": "XqSa_",
	"subTitle": "_82mhy",
	"spaceTop2": "sPqdm",
	"space2": "_3pj_N",
	"space6": "_2_FwB",
	"spaceTop6": "_12Rc5"
};
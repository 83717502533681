exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}.vq0B5{margin:0 auto;padding-top:30px;max-width:1080px;max-width:var(--max-content-width)}._2a40o{max-width:1080px;width:100%;display:block;margin:0 auto}._3X0sW{font-size:16px;border-bottom:1px solid #dce0e0;background:#f5f5f5}._1huYh,._3X0sW{border-radius:0;width:100%;display:block;color:#404040}._1huYh{font-size:14px;line-height:1.43}@media screen and (max-width:768px){._2gbhB{padding:0}}", ""]);

// exports
exports.locals = {
	"container": "vq0B5",
	"landingContainer": "_2a40o",
	"panelHeader": "_3X0sW",
	"panelEmpty": "_1huYh",
	"smPadding": "_2gbhB"
};
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Panel, Col, Row } from "react-bootstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payment.css";
import cx from "classnames";
// Locale
import messages from "../../../locale/messages";
class InsuranceInfo extends Component {
  static propTypes = {
    hostDisplayName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };
  //TODO Translate
  render() {
    const { hostDisplayName, houseRules, isInsurable } = this.props;

    return (
      <div className={s.houseRulesPanel}>
       
        {isInsurable ? (
          <div className={s.insurance}>
                        <div className={s.insureItemSuccess}>

            <h3>Ihre Fläche ist versicherbar</h3>
            <p>Wir empfehlen bei allen versicherbaren Flächen eine Inhaltsversicherung abzuschließen.</p>
            <p>Sie können hierfür die von uns bereitgestellten Formulare verwenden</p>
            <div className={s.insureItem}>
            <h4>Jetzt Inhaltsversicherung abschließen</h4>

            <a target="_blank" rel="noopener"  href={"/assets/inhaltsversicherung.pdf"} >Zum Dokument</a>
            </div>
            </div>
          </div>
        ) : (
          <div className={s.insurance}>
            <div className={s.insureItemError}>
            <h3>Hinweis zu Offenen Lager- und Logistikfläche</h3>
                        <p>
                          Sie buche eine Offene Lager- und Logistikfläche bzw. eine Außenfläche. Kunden nutzen diese Fläche hauptsächlich als Stellplatz oder
                          Lagerfläche für große oder nicht schutzbedürftige Güter.
                        </p>
                        <p>
                          Bitte beachten Sie, dass Offene Lager- und Logistikflächen, sowie Außenflächen sind nicht versicherbar sind. Sollten Sie einen
                          Inhaltsschutz für Ihre Güter benötigen buchen Sie bitte eine Fläche aus den Kategorien Lager- oder Produktionsfläche.
                        </p>

          </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(InsuranceInfo);

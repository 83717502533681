exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2IGTG{padding:0}.dbQeT{width:100%;display:table;margin-bottom:85px;padding-top:85px}._20g-X{display:table-cell;vertical-align:middle;padding-left:10px;padding-right:10px}._2KGlj{width:55%}._2dPuS{width:100%;float:right}._3MI0q{font-weight:700;font-size:16px;line-height:32px;color:#fff;text-transform:uppercase;border-bottom:4px solid #fff;display:inline-block;margin-bottom:50px;margin-left:15px;margin-top:50px}._2mQ9P{font-weight:600;font-size:55px;line-height:48px;margin:0;padding-top:2px;padding-bottom:2px;margin-top:10px;margin-bottom:30px}._1Q-u5,._2mQ9P{color:#fff;word-wrap:break-word}._1Q-u5{font-size:25px;line-height:26px;opacity:.75;margin-bottom:24px;padding-top:0;padding-bottom:0}._38fIi{padding-right:100px}._4K8T5{display:none}@media (max-width:1023px) and (min-width:768px){._2dPuS{width:100%}._16NC7{padding-right:10px}}@media screen and (max-width:767px){.dbQeT{display:block;padding-top:20px}._3MI0q{margin-bottom:20px;margin-top:20px}._2mQ9P{padding-top:20px}._20g-X{display:block}._7Oq0j,._20g-X{width:100%}._38fIi{padding-right:0}.bxgk8{display:none}._4K8T5{display:block}._16NC7{padding:24px 0 0}._2mQ9P{font-size:25px;line-height:30px}._1Q-u5{margin-top:8px;margin-bottom:16px;word-wrap:break-word;font-size:16px;line-height:24px;padding-top:0;padding-bottom:0}._2dPuS{width:100%}}@media (max-width:1199px) and (min-width:768px){.dbQeT{padding-top:35px;padding-left:24px;padding-right:24px}._2mQ9P{font-size:26px}}", ""]);

// exports
exports.locals = {
	"noPadding": "_2IGTG",
	"table": "dbQeT",
	"tableCell": "_20g-X",
	"imageColumn": "_2KGlj",
	"imgShadow": "_2dPuS",
	"textTop": "_3MI0q",
	"title": "_2mQ9P",
	"caption": "_1Q-u5",
	"image": "_7Oq0j",
	"paddingRight": "_38fIi",
	"mobileView": "_4K8T5",
	"textColumn": "_16NC7",
	"desktopView": "bxgk8"
};
// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep3 from "./validateStep3";

// Redux
import { connect } from "react-redux";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, ControlLabel, FormControl } from "react-bootstrap";
import s from "./ListPlaceStep1.css";
import DateRange from "./DateRange";
// Component
import ListPlaceTips from "../ListPlaceTips";
import SyncCalendar from "./SyncCalendar";

import updateStep3 from "./updateStep3";
import Loader from "../Loader";
class MaxDaysNotice extends Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    listId: PropTypes.number.isRequired,
    listingSteps: PropTypes.shape({
      step3: PropTypes.string.isRequired,
      listing: PropTypes.shape({
        isPublished: PropTypes.bool.isRequired,
        isApproved: PropTypes.bool.isRequired,
      }),
    }),
  };

  static defaultProps = {
    listingSteps: {
      step3: "inactive",
      listing: {
        isPublished: false,
        isApproved: false,
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      maxDaysNotice: [],
      maxDaysNoticeStart: [],
      maxDaysNoticeEnd: [],
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({
        maxDaysNotice: listingFields.maxDaysNotice,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;

    if (listingFields != undefined) {
      this.setState({
        maxDaysNotice: listingFields.maxDaysNotice,
      });
    }
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && <span>{formatMessage(error)}</span>}
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderDateRange = ({
    input,
    label,
    meta: { touched, error },
    formName,
    numberOfMonths,
    startDateName,
    endDateName,
    index,
    defaultStartDate,
    defaultEndDate,
    isCurrentStatus,
    resetCalendar,
  }) => {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, change } = this.props;
    let startDate = defaultStartDate ? defaultStartDate : null;
    let endDate = defaultEndDate ? defaultEndDate : null;
    return (
      <div className={"saveCalenderDate"}>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}

        <DateRange
          {...input}
          formName={formName}
          numberOfMonths={1}
          index={index}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          // isCurrentStatus={isCurrentStatus}
          // resetCalendar={resetCalendar}
          displayFormat={"DD.MM.YYYY"}
        />
      </div>
    );
  };
  render() {
    const { handleSubmit, submitting, pristine, valid, previousPage, nextPage, existingList, listId, invalid } = this.props;
    const { maxDaysNotice } = this.props;
    const { listingSteps, isActiveListing } = this.props;
    const { maxDaysNoticeStart, maxDaysNoticeEnd } = this.props;
    const { formatMessage } = this.props.intl;
    let containerClass = isActiveListing ? cx(s.landingContainerActive, s.fullWidthCalendar) : cx(s.landingContainer);
    let landingClass = isActiveListing ? s.landingContentActive : s.landingContent;

    if (isActiveListing && !maxDaysNotice) {
      return (
        <Grid fluid>
          <Row className={containerClass}>
          <Loader type={"text"} />
          </Row>
        </Grid>
      );
    }
    return (
      <Grid fluid>
        <Row className={containerClass}>
          <Col xs={12} sm={7} md={7} lg={7} className={landingClass} >
            <div className="maxDaysNotice">
              <h3 className={s.landingContentTitle}>
                <FormattedMessage {...messages.maxDaysTitle} />
              </h3>
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <FormGroup className={s.formGroup}>
                    <Field name="maxDaysNotice" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                      <option value={"available"}>{formatMessage(messages.datesOption5)}</option>
                      <option value={"3months"}>{formatMessage(messages.datesOption1)}</option>
                      <option value={"6months"}>{formatMessage(messages.datesOption2)}</option>
                      <option value={"9months"}>{formatMessage(messages.datesOption3)}</option>
                      <option value={"12months"}>{formatMessage(messages.datesOption4)}</option>
                      <option value={"custom"}>eigenen Zeitraum wählen</option>
                    </Field>
                  </FormGroup>
                  {maxDaysNotice === "available" && (
                    <div className={s.infoText}>
                     Ihr Objekt ist für den Mieter dauerhaft Verfügbar. Im nächsten Schritt können Sie einzelne Tage blockieren und Sonderpreise hinzufügen.
                    </div>
                  )}
                  {maxDaysNotice === "3months" && (
                    <div className={s.infoText}>
                     Ihr Objekt ist für den Mieter immer für die nächsten 3 Monate verfügbar.
                    </div>
                  )}
                  {maxDaysNotice === "6months" && (
                    <div className={s.infoText}>
                     Ihr Objekt ist für den Mieter immer für die nächsten 6 Monate verfügbar.
                    </div>
                  )}
                  {maxDaysNotice === "9months" && (
                    <div className={s.infoText}>
                     Ihr Objekt ist für den Mieter immer für die nächsten 9 Monate verfügbar.
                    </div>
                  )}
                  {maxDaysNotice === "12months" && (
                    <div className={s.infoText}>
                     Ihr Objekt ist für den Mieter immer für die nächsten 12 Monate verfügbar.
                    </div>
                  )}
                  {maxDaysNotice === "custom" && (
                    <div className={s.infoText}>
                     Ihr Objekt ist für den von Ihnen ausgewählten Zeitraum verfügbar.
                    </div>
                  )}
                  {maxDaysNotice === "custom" && (
                    <div className={s.customDate}>
                      <FormGroup className={cx(s.formGroup, s.sDateWrap)}>
                        <label>
                          <FormattedMessage {...messages.selectedDates} />
                        </label>
                      </FormGroup>
                      <FormGroup>
                        <Field
                          name="maxDaysNoticeCustom"
                          component={this.renderDateRange}
                          defaultStartDate={maxDaysNoticeStart}
                          defaultEndDate={maxDaysNoticeEnd}
                          formName={"ListPlaceStep3"}
                          // isCurrentStatus={isCurrentStatus}
                          // resetCalendar={resetDatePickerChange}
                        />
                      </FormGroup>
                    </div>
                  )}
                  {/* <FormGroup className={cx(s.formGroup, s.spaceTop4)}>
                    <ControlLabel className={s.landingStep3}>
                      <FormattedMessage {...messages.chooseCancellationPolicy} />
                    </ControlLabel>
                    <Field name="cancellationPolicy" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)} >
                      <option value={"1"}>{formatMessage(messages.flexible)}</option>
                      <option value={"2"}>{formatMessage(messages.moderate)}</option>
                      <option value={"3"}>{formatMessage(messages.strict)}</option>
                    </Field>
                  </FormGroup> */}
                  {listingSteps && listingSteps.step3 === "completed" && listingSteps.listing && listingSteps.listing.isPublished && (
                    <div className={s.spaceTop4}>
                      <h3 className={cx(s.landingContentTitle)}>
                        <FormattedMessage {...messages.syncCalendars} />
                      </h3>
                      <SyncCalendar isActiveListing={true} listId={listId} />
                    </div>
                  )}
                  {/* <div className={s.spaceTop4}>
                      <h3 className={cx(s.landingContentTitle)}><FormattedMessage {...messages.syncCalendars} /></h3>
                      <SyncCalendar listId={listId} />
                    </div> */}
                </div>
                {!isActiveListing && (
                  <div className={s.nextPosition}>
                    <div className={s.nextBackButton}>
                      <hr className={s.horizontalLineThrough} />

                      <FormGroup className={s.formGroup}>
                        <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                          <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Monatliche Zahlung")}>
                            <FormattedMessage {...messages.back} />
                          </Button>
                          <Button disabled={invalid} className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage("Kalender")}>
                            <FormattedMessage {...messages.next} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
const selector = formValueSelector("ListPlaceStep3");
MaxDaysNotice = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3,
})(MaxDaysNotice);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  listingSteps: state.location.listingSteps,
  maxDaysNotice: selector(state, "maxDaysNotice"),
  maxDaysNoticeStart: selector(state, "maxDaysNoticeStart"),
  maxDaysNoticeEnd: selector(state, "maxDaysNoticeEnd"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MaxDaysNotice)));

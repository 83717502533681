import { convert } from "./currencyConvertion";
function addTax(amount, taxRate) {
  return parseFloat(Number(amount + (amount / 100) * taxRate).toFixed(2));
}
function getNetto(amount, taxRate) {
  return parseFloat(Number((amount - (taxRate + 100)) * 100).toFixed(2));
}
function getFee(amount, fee) {
  return parseFloat(Number((amount / (100 + fee)) * 100).toFixed(2));
}
export function cancellationGuestData(
  remainingNights,
  nights,
  priceForDays,
  accomodation,
  isCleaningPrice,
  taxRate,
  guestServiceFee,
  guestFees,
  discount,
  hostServiceFee,
  basePrice,
  total,
  policyName,
  interval,
  priorDays,
  nonRefunableNights,
  hostServiceFeeType,
  hostServiceFeeValue,
  currency,
  base,
  rates,
  serviceFees,
  cancelData
) {
  let refundableAmount = 0,
    nonRefundableAmount = 0,
    refundWithoutGuestFee = 0;
  let updatedGuestFee = 0,
    updatedHostFee = 0,
    payoutToHost = 0,
    subtotal = 0,
    hostRefund = 0,
    refundHostServiceFee = 0;
  let cancellationData = {};
  let refundFee = 0;
  let checkInNights = remainingNights == 0 || remainingNights > 0 ? remainingNights : nights;
  // let totalNights = checkInNights - nonRefunableNights;

  let totalNights = cancelData.cancellationRuleObj.nonRefundableNights > checkInNights ? 0 : checkInNights - cancelData.cancellationRuleObj.nonRefundableNights;
  // let totalNights = checkInNights - nonRefunableNights;
  accomodation = cancelData.cancellationRuleObj.accomodation;
  if (accomodation == 0) {
    isCleaningPrice = 0;
    guestServiceFee = 0;
  }
  let cancelType = cancelData.cancellationRuleObj.type;
  let accomodationAmount = cancelData.cancellationRuleObj.accomodation > 0 ? cancelData.cancellationRuleObj.accomodation / 100 : 0;
  let guestFeeAmount = cancelData.cancellationRuleObj.guestFees > 0 ? cancelData.cancellationRuleObj.guestFees / 100 : 0;
  let hostServiceFeeAmount = Number(hostServiceFeeValue) > 0 ? Number(hostServiceFeeValue) / 100 : 0;
  // guestServiceFee = hostServiceFee;
  // console.log("baz", guestServiceFee);

  refundableAmount = addTax(totalNights * basePrice * accomodationAmount + isCleaningPrice - guestServiceFee * guestFeeAmount - discount, taxRate);
  payoutToHost = hostRefund - refundHostServiceFee;

  // console.log("total", total);
  // console.log("isCleaningPrice", isCleaningPrice);
  // console.log("hostServiceFeeAmount", hostServiceFeeAmount);
  // console.log("guestFeeAmount", guestFeeAmount);
  //Custom config
  if (cancelType === "duringCheckIn") {
    let nettoPrice = (total / (100 + taxRate)) * 100 - isCleaningPrice;
    refundableAmount = 0;
    payoutToHost = (nettoPrice / 110) * 100;
    refundFee = (nettoPrice / 110) * 10;
  } else if (cancelType === "priorCheckIn") {
    let nettoPrice = (total / (100 + taxRate)) * 100 - isCleaningPrice;
    //custom calculation - 98% from netto
    let hostServiceFeeAmountFull = hostServiceFeeAmount * 100;
    let guestFeeAmountFull = guestFeeAmount * 100;
    refundableAmount = addTax(
      (nettoPrice / (100 + hostServiceFeeAmountFull)) * (100 + hostServiceFeeAmountFull - guestFeeAmountFull) + isCleaningPrice - discount,
      taxRate
    );

    payoutToHost = 0;
    refundFee = (nettoPrice / 110) * 2 ;

  } else if (cancelType === "beforeCheckIn") {
    //Innherhalb 30 Tage
    let hostServiceFeeAmountFull = hostServiceFeeAmount * 100;
    let guestFeeAmountFull = guestFeeAmount * 100;
    let nettoPrice = (total / (100 + taxRate)) * 100 - isCleaningPrice;
    let fee = (nettoPrice / (100 + hostServiceFeeAmountFull)) * hostServiceFeeAmountFull;
    let thirtydays = (nettoPrice / (100 + hostServiceFeeAmountFull) / checkInNights) * 30 * 100;

    refundableAmount = addTax(nettoPrice - fee - thirtydays + isCleaningPrice, taxRate);
    payoutToHost = thirtydays;
    refundFee = fee;
  } else if (cancelType === "shortCancel") {
    //48h nach Buchung - komplette ERstattung
    payoutToHost = 0;
    refundFee = 0;
    refundableAmount = total;
  }

  refundWithoutGuestFee = addTax(totalNights * basePrice * accomodationAmount + isCleaningPrice, taxRate);
  // nonRefundableAmount = total + guestServiceFee - refundableAmount;
  nonRefundableAmount = total - refundableAmount;
  updatedGuestFee = guestServiceFee;
  hostRefund = nonRefundableAmount;

  if (serviceFees) {
    refundHostServiceFee = hostRefund * hostServiceFeeAmount;
  }
  updatedHostFee = hostServiceFee;
  cancellationData = {
    refundableNightPrice: refundableAmount,
    refundWithoutGuestFee,
    nonRefundableNightPrice: nonRefundableAmount,
    payoutToHost,
    updatedHostFee,
    updatedGuestFee,
    refundFee,
  };

  return cancellationData;
}

export function cancellationHostData(
  remainingNights,
  nights,
  priceForDays,
  accomodation,
  guestServiceFee,
  guestFees,
  hostServiceFee,
  basePrice,
  total,
  interval,
  serviceFees,
  priorDays,
  isCleaningPrice,
  taxRate,
  hostServiceFeeType,
  hostServiceFeeValue,
  currency,
  base,
  rates,
  nonRefunableNights
) {
  let refundAmount = 0,
    refundAmountNoGuestFee = 0,
    refundDays = 0,
    earnedAmount = 0,
    earnedDays = 0,
    subtotal = 0,
    hostRefund = 0,
    refundHostServiceFee = 0;
  let guestRefund = 0,
    guestRefundWithOutServiceFee = 0,
    refundableAmount = 0;
  let cancellationData = {};
  let checkInNights = remainingNights ? remainingNights : nights;
  let totalNights = checkInNights - nonRefunableNights;
  if (accomodation == 0) {
    isCleaningPrice = 0;
    guestServiceFee = 0;
  }

  if (remainingNights && remainingNights >= 0) {
    if (remainingNights == nights) {
      refundableAmount = totalNights * basePrice * (accomodation / 100) + isCleaningPrice + guestServiceFee * (guestFees / 100);
      refundAmount = totalNights * basePrice * (accomodation / 100) + isCleaningPrice + guestServiceFee * (guestFees / 100);
      refundDays = totalNights;
      hostRefund = total + guestServiceFee - refundableAmount;

      if (serviceFees) {
        if (hostServiceFeeType === "percentage") {
          refundHostServiceFee = hostRefund * (Number(hostServiceFeeValue) / 100);
        } else {
          refundHostServiceFee = convert(base, rates, hostServiceFeeValue, serviceFees.host.currency, currency);
        }
      }

      earnedAmount = hostRefund - refundHostServiceFee;
      refundAmountNoGuestFee = total - hostServiceFee - earnedAmount;

      earnedDays = totalNights;
    } else {
      refundableAmount = totalNights * basePrice * (accomodation / 100);
      refundAmount = totalNights * basePrice * (accomodation / 100);
      refundDays = totalNights;
      hostRefund = total - refundableAmount;

      if (serviceFees) {
        if (hostServiceFeeType === "percentage") {
          refundHostServiceFee = hostRefund * (Number(hostServiceFeeValue) / 100);
        } else {
          refundHostServiceFee = convert(base, rates, hostServiceFeeValue, serviceFees.host.currency, currency);
        }
      }

      earnedAmount = hostRefund - refundHostServiceFee;
      refundAmountNoGuestFee = total - hostServiceFee - earnedAmount;
      earnedDays = totalNights;
    }
  } else {
    refundableAmount = totalNights * basePrice * (accomodation / 100) + isCleaningPrice + guestServiceFee * (guestFees / 100);
    refundAmount = totalNights * basePrice * (accomodation / 100) + guestServiceFee * (guestFees / 100);

    refundDays = totalNights;
    hostRefund = total + guestServiceFee - refundableAmount;
    if (serviceFees) {
      if (hostServiceFeeType === "percentage") {
        refundHostServiceFee = hostRefund * (Number(hostServiceFeeValue) / 100);
      } else {
        refundHostServiceFee = convert(base, rates, hostServiceFeeValue, serviceFees.host.currency, currency);
      }
    }
    earnedAmount = hostRefund - refundHostServiceFee;
    refundAmountNoGuestFee = total - hostServiceFee - earnedAmount;
    earnedDays = totalNights;
  }

  cancellationData = {
    refundAmount,
    refundAmountNoGuestFee,
    earnedAmount,
    earnedDays,
    refundDays,
  };
  return cancellationData;
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1enPr{padding:0}._3LgN0{width:100%;display:table;margin-bottom:48px;text-align:center;box-shadow:1px 12px 32px 1px hsla(240,1%,57%,.16);border-radius:4px;padding:50px 30px 30px}.E1r5X{display:table-cell;vertical-align:middle;padding-left:10px;padding-right:10px}._3TWP2{width:55%}._16RkQ{width:100%;float:right}.eiyMI{font-weight:700;color:#404040;margin:0;font-size:20px;line-height:36px;padding-top:2px;padding-bottom:2px;margin-top:10px;margin-bottom:10px}.eiyMI,.pbwk7{word-wrap:break-word}.pbwk7{font-weight:200;color:#70758b;margin-bottom:24px;font-size:16px;line-height:24px;padding-top:0;padding-bottom:0}.dRpRv{font-size:32px;font-weight:600;margin-bottom:80px}._2jplY{height:140px}._1RPjO{display:none}@media (max-width:1023px) and (min-width:768px){._16RkQ{width:100%}._39OID{padding-right:10px}}@media screen and (max-width:767px){._3LgN0{padding-top:20px}.dRpRv{margin-bottom:20px}.E1r5X{display:block;width:100%;margin-bottom:50px}._34ICX{display:none}._1RPjO{display:block}._39OID{padding:24px 0 0}.eiyMI{font-size:25px;line-height:30px}.pbwk7{color:#404040;margin-top:8px;margin-bottom:16px;word-wrap:break-word;font-size:16px;line-height:24px;padding-top:0;padding-bottom:0}._16RkQ{width:100%}}@media (max-width:1199px) and (min-width:768px){._3LgN0{padding-top:35px;padding-left:24px;padding-right:24px}.eiyMI{font-size:26px}}", ""]);

// exports
exports.locals = {
	"noPadding": "_1enPr",
	"table": "_3LgN0",
	"tableCell": "E1r5X",
	"imageColumn": "_3TWP2",
	"imgShadow": "_16RkQ",
	"title": "eiyMI",
	"caption": "pbwk7",
	"headLine": "dRpRv",
	"image": "_2jplY",
	"mobileView": "_1RPjO",
	"textColumn": "_39OID",
	"desktopView": "_34ICX"
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Panel,
  Alert
} from 'react-bootstrap';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './EmptyList.css';

// Redirection
import Link from '../../Link';

// Locale
import messages from '../../../locale/messages';

class EmptyList extends Component {
  static propTypes = {
    siteName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  handleClick() {
    history.push('/user/addpayout');
  }

  render() {
    const { siteName,isKycVerified } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Panel className={cx(s.panelHeader)} header={formatMessage(messages.payoutMethod)} >
            {!isKycVerified && (
              //TODO Translate file
              <Alert bsStyle="warning">
                Achtung! Um zukünftig Auszahlungen erhalten zu können, müssen Sie zuerst eine einmalige Überprüfung durchlaufen. Bei der KYC Verifizierung handelt es sich um die Überprüfung der Identität und Firmendaten von Neukunden. Diese dient der Prävention von Betrug, bietet Ihnen Sicherheit und muss zur Einhaltung von lokalen Gesetzen durchgeführt werden. Ein grundlegender Bestandteil hierbei ist die Verifizierung der Anteilseigner und dient Ihrem eigenen Schutz.
                <br/><a href="/kyc-verification">Hierfür benötigte Dokumente jetzt hochladen</a>
              </Alert>
            )}
        <div className={cx(s.spaceTop3, s.textCenter)}>
          <span className={s.textTitle}><FormattedMessage {...messages.addPayoutMethod} /></span>
        </div>
        <div className={s.textCenter}>
          <span className={s.textLead}><FormattedMessage {...messages.paymentReleaseInfo1} /></span><br />
          <span className={s.textLead}><FormattedMessage {...messages.paymentReleaseInfo2} /></span>
        </div>
        <div className={cx(s.spaceTop4, s.space2, s.textCenter)}>
          <Link to={"/user/addpayout"} className={cx(s.button, s.btnPrimary)}><FormattedMessage {...messages.addPayout} /></Link>
        </div>
      </Panel>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  isKycVerified: state.account.data.verification.isKycVerified

});
const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(EmptyList)));
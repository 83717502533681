import React from 'react';
import PropTypes from 'prop-types';
class DisableGa extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const {disableString} = this.props;
    window[disableString] = true;
  }


  render() {
    return (
      <div>
       Google Analytics opt-out for GA-ID: {this.props.gaId}
      </div>
    );
  }
}

export default DisableGa;

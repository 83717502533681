import React from 'react';
import Layout from '../../../components/Layout/Layout';
import OfferSpace from './OfferSpace';

const title = 'Fläche anbieten';

export default {

  path: '/offer-space-old',

  action() {
    return {
      title,
      component: <Layout><OfferSpace title={title} /></Layout>,
    };
  },

};

import {
  GET_PAYIN_START,
  GET_PAYIN_SUCCESS,
  GET_PAYIN_ERROR,
  ADD_PAYIN_START,
  ADD_PAYIN_ERROR,
  ADD_PAYIN_SUCCESS,
  DELETE_MANDATE_DATA
} from '../constants';

export default function payin(state = {}, action) {
  switch (action.type) {

    case GET_PAYIN_START:
      return {
        ...state,
        getPayinLoading: action.payload.getPayinLoading
      };
      
    case GET_PAYIN_SUCCESS:
      return {
        ...state,
        data: action.payload.payinData,
        getPayinLoading: action.payload.getPayinLoading
      };

    case GET_PAYIN_ERROR:
      return {
        ...state,
        getPayinLoading: action.payload.getPayinLoading
      };


    case ADD_PAYIN_START:
      return {
        ...state,
        getPayinLoading: action.payload.getPayinLoading
      };
      
    case ADD_PAYIN_ERROR:
      return {
        ...state,
        getPayinLoading: action.payload.getPayinLoading
      };
      
    case ADD_PAYIN_SUCCESS:
      return {
        ...state,
        getPayinLoading: action.payload.getPayinLoading,
        mandateId: action.payload.mandateId,
        mandateDocumentUrl: action.payload.mandateDocumentUrl,
        mandateConfirmUrl: action.payload.mandateConfirmUrl,
      };
    case DELETE_MANDATE_DATA:
      return {
        ...state,
        getPayinLoading: false,
        mandateId: null,
        mandateDocumentUrl: null,
        mandateConfirmUrl: null,
      };

    default:
      return state;
  }
}
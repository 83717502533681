exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._4qRkb{padding:0}._1UVnS{margin:0}.mqeLd{position:relative}._3ceoz{font-family:inherit;font-size:48px;line-height:56px;padding-top:8px;padding-bottom:8px;color:#404040;margin:0;font-weight:200}.tuF15{font-weight:700;color:#0667b3}@media screen and (max-width:767px){._3ceoz{font-size:25px;line-height:30px}}", ""]);

// exports
exports.locals = {
	"noPadding": "_4qRkb",
	"noMargin": "_1UVnS",
	"bannerCaptionContainer": "mqeLd",
	"bannerCaptionText": "_3ceoz",
	"bannerCaptionHighlight": "tuF15"
};
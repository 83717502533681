import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
// Locale
import messages from "../../locale/messages";
// Component
import Avatar from "../Avatar";
import s from "./ViewProfile.css";

class ResponseItem extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    data: PropTypes.shape({
      authorData: PropTypes.shape({
        picture: PropTypes.string,
        companyName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        profileId: PropTypes.number.isRequired,
      }),
    }),
  };

  render() {
    const {
      data: {
        authorData: { firstName, companyName, lastName, profileId, picture },
      },
    } = this.props;
    const {
      data: { reviewContent },
      otherUserResponse,
    } = this.props;
    return (
      <div>
        <div className={cx(s.reviewBody, s.pullLeft)}>
          <Avatar
            source={picture}
            height={34}
            width={34}
            title={firstName}
            companyName={companyName}
            className={s.profileAvatar}
            withLink
            linkClassName={cx(s.avatarDisplay, s.profileAvatarLink)}
            profileId={profileId}
          />
        </div>
        <div className={s.reviewBody}>
          <span className={s.textBold}>
            <FormattedMessage {...messages.responseFrom} /> {firstName}:
          </span>
          <p>{reviewContent}</p>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ResponseItem);

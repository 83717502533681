import withStyles from "isomorphic-style-loader/lib/withStyles";
import React, { Component } from "react";
import s from "./Book.css";
import PaymentFailedIcon from './payment-failure.svg'

class Book extends Component {
  static defaultProps = {
    reservationData: {
      loading: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      restored: false,
    };
  }
  componentDidMount() {}

  render() {
    const { store, listId } = this.props;

    let offerUrl = "/spaces/" + listId;

    return (
      <div className={s.container}>
        <div className={s.icon}><img src={PaymentFailedIcon} /></div>
        <h1>Ihre Zahlung ist leider fehlgeschlagen</h1>
        <h2>
          Sollte es weiterhin zu Problemen kommen, unterstützen wir sie gerne. <br />
          Rufen Sie uns an unter{" "}
          <a href="tel:49 731 79032090" className={s.linkText} target="_blank">
            +49 731 79032090
          </a>
        </h2>
        <a href={offerUrl}>Zurück zur angebotenen Fläche</a>
      </div>
    );
  }
}

export default withStyles(s)(Book);
// withStyles(s)
// export default compose(
// 	injectIntl,
// 	withStyles(s),
// 	connect(mapState, mapDispatch),
// 	graphql(gql`
// 		query {
// 			userAccount {
// 				picture
// 			}
// 		}
// 	  `, {
// 			name: 'profilePictureData',
// 			options: {
// 				ssr: false
// 			}
// 		}),
// )(Payin);

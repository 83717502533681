exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._1E2Q6{padding-top:100px;padding-bottom:100px}.I8xBx{text-transform:uppercase;letter-spacing:4px;font-size:15px;color:#0667b3}._3ZT35{font-size:50px;margin-top:20px;margin-bottom:20px}._2VvIW{font-size:20px;color:#656565;margin-bottom:70px}._2XE35{border-color:#2081cd;background-color:#2081cd;color:#fff;font-size:16px;padding:15px 70px;font-weight:500;margin-right:20px;margin-left:20px}._2XE35:hover{background:rgba(54,50,119,.8)}._2XE35:focus{border-color:#0074c2;box-shadow:0 0 8px rgba(0,116,194,.6)}.E2smC{border-color:#0667b3;background-color:#0667b3;color:#fff}.E2smC:active,.E2smC:active:focus,.E2smC:active:hover,.E2smC:focus,.E2smC:hover{border-color:#2081cd!important;background-color:#2081cd!important;color:#fff;text-decoration:none}._1Ituu{border-color:#0667b3;background-color:#fff;color:#0667b3;border:1px solid #0667b3}._1Ituu:active,._1Ituu:active:focus,._1Ituu:active:hover,._1Ituu:focus,._1Ituu:hover{border-color:#2081cd!important;background-color:#fff!important;color:#2081cd;border:1px solid #2081cd;text-decoration:none}._3Y-4O{margin-bottom:100px;display:-ms-flexbox;display:flex;background:#fff;box-shadow:0 12px 32px rgba(13,58,160,.16);border-radius:4px;padding:30px 50px 80px;text-align:center}@media screen and (max-width:767px){._2XE35{display:block;margin-bottom:10px}._3Y-4O{margin-bottom:0}}", ""]);

// exports
exports.locals = {
	"content": "_1E2Q6",
	"subHeadline": "I8xBx",
	"headline": "_3ZT35",
	"text": "_2VvIW",
	"button": "_2XE35",
	"btnPrimary": "E2smC",
	"btnSecondary": "_1Ituu",
	"box": "_3Y-4O"
};
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3nC9c{font-size:19px;line-height:1;font-weight:400;font-style:normal;color:#dc3545;display:block;float:right}._23iT-{margin:0}._1d4it{border:0}._1SnE_ span{background:#0667b3;color:#fff;padding:10px;border-radius:4px}._1SnE_ span:hover{background:#2081cd}._23gA7{height:30px;width:30px;border-radius:50%;border:2px solid #c4c4c4}._10Z0P{display:none!important}.dDjg5{display:block!important}@media screen and (max-width:1200px){._10Z0P{display:block!important}.dDjg5{display:none!important}}@media(max-width:1200px){._1mIuK{height:100vh;background:#fff!important;overflow-y:scroll}._2SxAQ{margin-top:20px;border-top:1px solid #dce0e0;margin-top:60px}}@media(min-width:768px) and (max-width:1200px){._1mIuK{left:0}._2SxAQ{margin-top:60px!important;border-top:1px solid #dce0e0;margin-right:0!important}}@media(max-width:767px){._2SxAQ{margin-top:60px}}", ""]);

// exports
exports.locals = {
	"circleIcon": "_3nC9c",
	"root": "_23iT-",
	"navDropDown": "_1d4it",
	"highlight": "_1SnE_",
	"userAvatar": "_23gA7",
	"breakPointScreen": "_10Z0P",
	"nonBreakPointScreen": "dDjg5",
	"newMenu": "_1mIuK",
	"newMenuDesign": "_2SxAQ"
};
// Redux Form
import { SubmissionError } from "redux-form";

// Fetch request
import fetch from "../../core/fetch";

// Language
import messages from "../../locale/messages";

// Redux
import { setRuntimeVariable } from "../../actions/runtime";
import { loadAccount } from "../../actions/account";
import { closeSignupModal } from "../../actions/modalActions";
import { Event } from "../../components/Tracking/GA";

// Helper
import PopulateData from "../../helpers/populateData";

async function submit(values, dispatch) {
  const query = `query (
    $email: String!,
    $password: String!,
  ) {
      userRegister (
        email: $email,
        password: $password,
      ) {
        emailToken
        status
      }
    }`;

  const params = {
    email: values.email,
    password: values.password,
  };

  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables: params,
    }),
    credentials: "include",
  });

  const { data } = await resp.json();
  if (data && data.userRegister) {
    if (data.userRegister.status == "success") {
      Event("account", "register", values.email);

      dispatch(closeSignupModal());
      let registerScreen = true;
      let refer = values.refer;
      dispatch(loadAccount(registerScreen, refer));
      dispatch(
        setRuntimeVariable({
          name: "isAuthenticated",
          value: true,
        })
      );
    } else if (data.userRegister.status == "email") {
      throw new SubmissionError({ _error: messages.emailAlreadyExists });
    } else if (data.userRegister.status == "loggedIn") {
      dispatch(loadAccount());
      dispatch(
        setRuntimeVariable({
          name: "isAuthenticated",
          value: true,
        })
      );
      throw new SubmissionError({ _error: messages.loggedIn });
    } else if (data.userRegister.status == "adminLoggedIn") {
      throw new SubmissionError({ _error: messages.adminLoggedIn });
    } else {
      throw new SubmissionError({ _error: messages.somethingWentWrong });
    }
  } else {
    throw new SubmissionError({ _error: messages.somethingWentWrong });
  }
}

export default submit;

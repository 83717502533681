import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox.css";
import { Grid, Row, Col } from "react-bootstrap";
import Link from "../../Link/Link";
import ArrowDown from "./arrow_down.svg";
import Fade from "react-reveal/Fade";
import Typewriter from "typewriter-effect";
import VizSensor from "react-visibility-sensor";
class ContentBox extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonLabel: PropTypes.string,
      buttonLink: PropTypes.string,
      image: PropTypes.string,
      listItems: PropTypes.array,
      itemPlacement: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  renderContent = () => {};
  onChange = (isVisible) => {
    this.setState({ isVisible });
  };

  render() {
    const { isVisible } = this.state;
    const {
      data: { fadeDelay, animated, title, preTitle, description, buttonLabel, image, buttonLink, listItems, itemPlacement },
    } = this.props;
    const imageCol = (
      <Col lg={6} md={6} sm={6} xs={12}>
        <div className={cx(s.tableCell, s.imageColumn)}>
          <img src={image} className={cx("img-responsive", s.imgShadow)} />
        </div>
      </Col>
    );
    const textColumnClass = itemPlacement === "left" ? s.textColumnLeft : s.textColumnRight;

    const content = (
      <div>
        <p className={s.caption}>{description}</p>
        <div className={s.listItems}>
          {listItems.map((item, index) => {
            return (
              <div className={s.listItem} key={index}>
                <img src={ArrowDown} />
                <span>{item}</span>
              </div>
            );
          })}
        </div>
        {buttonLabel && (
          <Link to={buttonLink} className={cx(s.btn, s.btnPrimary)}>
            {buttonLabel}
          </Link>
        )}
      </div>
    );

    return (
      <Grid fluid>
        <VizSensor key={title} scrollCheck={true} delayedCall={true} partialVisibility={true} onChange={this.onChange}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={cx(s.table)}>
                <Row>
                  {itemPlacement === "left" && imageCol}

                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className={cx(s.tableCell, textColumnClass, s.desktopView)}>
                      {animated && isVisible && (
                        <h3 className={s.title}>
                          <Typewriter
                            options={{
                              autoStart: true,
                              loop: false,
                            }}
                            onInit={(typewriter) => {
                              typewriter
                                .typeString(preTitle)
                                .pauseFor(2500)
                                .deleteAll()
                                .typeString(title)
                                .callFunction(function(state) {
                                  state.elements.cursor.style.display = "none";
                                })
                                .start();
                            }}
                          />
                        </h3>
                      )}
                      {fadeDelay > 0 ? (
                        <Fade delay={fadeDelay} bottom>
                          {content}
                        </Fade>
                      ) : (
                        <div>{content}</div>
                      )}
                    </div>
                  </Col>
                  {itemPlacement === "right" && imageCol}
                </Row>
                <div className={cx(s.tableCell, s.textColumn, s.mobileView)}>
                  {animated && isVisible && (
                    <h3 className={s.title}>
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: false,
                        }}
                        onInit={(typewriter) => {
                          typewriter
                            .typeString(preTitle)
                            .pauseFor(2500)
                            .deleteAll()
                            .typeString(title)
                            .callFunction(function(state) {
                              state.elements.cursor.style.display = "none";
                            })
                            .start();
                        }}
                      />
                    </h3>
                  )}
                  {fadeDelay > 0 ? (
                    <Fade delay={fadeDelay} bottom>
                      {content}
                    </Fade>
                  ) : (
                    <div>{content}</div>
                  )}

                  {/* <h3 className={s.title}>{title}</h3>
                <p className={s.caption}>{description}</p>
                <div className={s.listItems}>
                  {listItems.map((item, index) => {
                    return (
                      <div className={s.listItem} key={index}>
                        <img src={ArrowDown} />
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
                {buttonLabel && (
                  <Link to={buttonLink} className={cx(s.btn, s.btnPrimary)}>
                    {buttonLabel}
                  </Link>
                )} */}
                </div>
              </div>
            </Col>
          </Row>
        </VizSensor>
      </Grid>
    );
  }
}

export default withStyles(s)(ContentBox);

import { gql } from "react-apollo";
import { BOOKING_PAYMENT_START, BOOKING_PAYMENT_SUCCESS, BOOKING_PAYMENT_ERROR } from "../../constants";

import { sendPayment } from "../../core/payment/sendPayment";
// Helper
import { convert } from "../../helpers/currencyConvertion";

import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";
import { toastr } from "react-redux-toastr";
import reservation from "../../reducers/reservation";

import { Event } from "../../components/Tracking/GA";


export function makePayment(
  listId,
  title,
  hostId,
  guestId,
  checkIn,
  checkOut,
  guests,
  message,
  basePrice,
  cleaningPrice,
  currency,
  discount,
  discountType,
  guestServiceFee,
  hostServiceFee,
  total,
  bookingType,
  paymentCurrency,
  paymentType,
  // name,
  // cardNumber,
  // cvv,
  // expiryDate,
  // expiryYear,
  guestEmail,
  specialPricing,
  isSpecialPriceAssigned,
  isSpecialPriceAverage,
  dayDifference,
  paymentMethodId,
  selectedPaymentMethodId,
  taxRate,
  checkInStart,
  checkInEnd,
  hostServiceFeeType,
  hostServiceFeeValue,
  reservationId,
  isMonthly,
  bookingPeriodPrice,
  last4Digits,
  monthlyBookingContent
) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: BOOKING_PAYMENT_START,
      payload: {
        paymentLoading: true,
      },
    });

    try {
      const mutation = gql`
        mutation createReservation(
          $listId: Int!
          $hostId: String!
          $guestId: String!
          $checkIn: String!
          $checkOut: String!
          $guests: Int!
          $message: String!
          $basePrice: Float!
          $cleaningPrice: Float
          $currency: String!
          $discount: Float
          $discountType: String
          $guestServiceFee: Float
          $hostServiceFee: Float
          $total: Float!
          $bookingType: String
          $paymentType: Int!
          $cancellationPolicy: Int!
          $specialPricing: String
          $isSpecialPriceAssigned: Boolean
          $isSpecialPriceAverage: Float
          $dayDifference: Float
          $taxRate: Float
          $checkInStart: String
          $checkInEnd: String
          $hostServiceFeeType: String
          $hostServiceFeeValue: Float
          $reservationId: Int
          $isMonthly: Boolean
        ) {
          createReservation(
            listId: $listId
            hostId: $hostId
            guestId: $guestId
            checkIn: $checkIn
            checkOut: $checkOut
            guests: $guests
            message: $message
            basePrice: $basePrice
            cleaningPrice: $cleaningPrice
            currency: $currency
            discount: $discount
            discountType: $discountType
            guestServiceFee: $guestServiceFee
            hostServiceFee: $hostServiceFee
            total: $total
            bookingType: $bookingType
            paymentType: $paymentType
            cancellationPolicy: $cancellationPolicy
            specialPricing: $specialPricing
            isSpecialPriceAssigned: $isSpecialPriceAssigned
            isSpecialPriceAverage: $isSpecialPriceAverage
            dayDifference: $dayDifference
            taxRate: $taxRate
            checkInStart: $checkInStart
            checkInEnd: $checkInEnd
            hostServiceFeeType: $hostServiceFeeType
            hostServiceFeeValue: $hostServiceFeeValue
            reservationId: $reservationId
            isMonthly: $isMonthly
          ) {
            id
            listId
            hostId
            guestId
            checkIn
            checkOut
            guests
            message
            basePrice
            cleaningPrice
            currency
            discount
            discountType
            guestServiceFee
            hostServiceFee
            total
            confirmationCode
            createdAt
            status
            paymentMethodId
            cancellationPolicy
            isSpecialPriceAverage
            dayDifference
            taxRate
            checkInStart
            checkInEnd
            hostServiceFeeType
            hostServiceFeeValue
            # reservationId,
            isMonthly
          }
        }
      `;

      let preApprove = getState().book.bookDetails.preApprove;
      let bookingTypeData;
      if (preApprove === true) {
        bookingTypeData = "instant";
      } else {
        bookingTypeData = bookingType;
      }

      let cancellationPolicy = getState().book.data.listingData.cancellation.id;

      const { data } = await client.mutate({
        mutation,
        variables: {
          listId,
          hostId,
          guestId,
          checkIn,
          checkOut,
          guests,
          message,
          basePrice,
          cleaningPrice,
          currency,
          discount,
          discountType,
          guestServiceFee,
          hostServiceFee,
          total,
          bookingType: bookingTypeData,
          paymentType,
          cancellationPolicy,
          specialPricing,
          isSpecialPriceAssigned,
          isSpecialPriceAverage,
          dayDifference,
          taxRate,
          checkInStart,
          checkInEnd,
          hostServiceFeeType,
          hostServiceFeeValue,
          reservationId,
          isMonthly,
          
        },
      });
      if (data && data.createReservation) {
        let reservationId = data.createReservation.id;
        let amount = total + guestServiceFee;
        let rates = getState().currency.rates;
        let currentCurrency = getState().currency.to ? getState().currency.to : getState().currency.base;
        let baseCurrency = getState().currency.base;
        let convertedAmount = 0;

        let overAllAmount = amount && amount.toString().split(".");
        let isAmount = 0;
        if (overAllAmount && overAllAmount[1] == "00") {
          isAmount = overAllAmount && overAllAmount[0];
          // isAmount = Math.round(amount);
        } else {
          isAmount = amount;
        }
        if (paymentType == 1) {
          //Paypal
          // convertedAmount = convert(baseCurrency, rates, amount, currency, paymentCurrency);
          convertedAmount = convert(baseCurrency, rates, isAmount, currency, paymentCurrency);
          sendPayment(reservationId, convertedAmount.toFixed(2), paymentCurrency, title);
          dispatch({
            type: BOOKING_PAYMENT_SUCCESS,
            // payload: { paymentLoading: false }
            payload: { paymentLoading: true },
          });

         
        } else if (bookingType === "instant") {
          convertedAmount = convert(baseCurrency, rates, amount, currency, currentCurrency);

     
          let reservationDetails = {
            reservationId,
            listId,
            hostId,
            guestId,
            guestEmail,
            title,
            amount: convertedAmount.toFixed(2),
            currency: currentCurrency,
            isMonthly,
          
          };
   

          let paymentData = {
            reservationDetails,
            selectedPaymentMethodId,
            bookingPeriodPrice,
            last4Digits,
            monthlyBookingContent
          };
          const { status, errorMessage, paymentIntentSecret } = await processMangopayPayment("create-payment", paymentData);

          if (status === 200) {
            await dispatch({
              type: BOOKING_PAYMENT_SUCCESS,
              payload: { paymentLoading: true },
            });

            Event("booking", "payment_success", reservationId);

            return {
              status,
            };
          } else {
            Event("booking", "payment_error", reservationId);
            errorMessage ? toastr.error("Transaktion fehlgeschlagen", errorMessage) : "";
            await dispatch({
              type: BOOKING_PAYMENT_ERROR,
              payload: { paymentLoading: false },
            });
            return {
              status,
              paymentIntentSecret,
              reservationId,
            };
          }
        } else if (bookingType === "reservation") {
          //booking request no payment#
          convertedAmount = convert(baseCurrency, rates, amount, currency, currentCurrency);
          let reservationDetails = {
            reservationId,
            listId,
            hostId,
            guestId,
            guestEmail,
            title,
            amount: convertedAmount.toFixed(2),
            currency: currentCurrency,
          };
  

          let paymentData = {
            reservationDetails,
            // selectedPaymentMethodId: 99,
            selectedPaymentMethodId,
          };

        

          //not payed yet - create reservation
          const { status, errorMessage, paymentIntentSecret } = await processMangopayPayment("create-reservation", paymentData);

          if (status === 200) {
            await dispatch({
              type: BOOKING_PAYMENT_SUCCESS,
              payload: { paymentLoading: true },
            });
          } else {
            Event("booking", "payment_error", reservationId);
            errorMessage ? toastr.error("Transaktion fehlgeschlagen", errorMessage) : "";
            await dispatch({
              type: BOOKING_PAYMENT_ERROR,
              payload: { paymentLoading: false },
            });
            return {
              status,
              paymentIntentSecret,
              reservationId,
            };
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      Event("booking", "payment_error", "");
      dispatch({
        type: BOOKING_PAYMENT_ERROR,
        payload: {
          error,
          paymentLoading: false,
        },
      });
      return false;
    }

    return true;
  };
}

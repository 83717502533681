import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

// Redux
import { connect } from "react-redux";

import { Button, Panel, Label, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Payin.css";
import  { FaChevronRight, FaFile } from 'react-icons/fa';

// Redirection
import history from "../../../core/history";

// Locale
import messages from "../../../locale/messages";

// Redux actions
import { removePayin } from "../../../actions/Payin/removePayinAction";
import { setDefaultPayin } from "../../../actions/Payin/setDefaultPayin";

class PayinList extends Component {
  static defaultProps = {
    payinRemoveLoader: false,
    payinDefaultLoader: false,
    payinVerifyLoader: false,
    data: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  handleClick() {
    history.push("/user/addpayin");
  }

  openModal = () => {
    this.setState({modalOpen: true})
  }

  closeModal = () => {
    this.setState({modalOpen: false})
  }

 
  render() {
    const {
      data,
      removePayin,
      setDefaultPayin,
      currentAccountId,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      payinRemoveLoader,
      payinDefaultLoader,
      payinVerifyLoader,
    } = this.props;

    const { modalOpen } = this.state;
    return (
      <Panel
        className={s.panelHeader}
        header={formatMessage(messages.payinMethod)}
      >
        <div className={s.panelBody}>
          <p className={s.payinIntro}>
          Unser sicheres Zahlungssystem unterstützt verschiedene Zahlungsmethoden. Diese können hier hinzugefügt und editiert werden.
          </p>
          <table className={cx("table", s.noBorder)}>
            <thead>
              <tr
                className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}
              >
                <th className={s.noBorder}>
                  {/* <FormattedMessage {...messages.payinTitle} /> */}
                  Zahlungsmethode
                </th>
                <th className={s.noBorder}>
                  <FormattedMessage {...messages.payoutTitle4} />                </th>
                <th className={s.noBorder}>
                  <FormattedMessage {...messages.status} />
                </th>
                <th className={s.noBorder}>
                  {/* <FormattedMessage {...messages.options} /> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <tr
                      className={cx(s.rowBorder, s.sectionTitleLight)}
                      key={index}
                    >
                      <td>
                        SEPA-Lastschrift{" "}
                        {item.default && (
                          <Label bsStyle="success">
                            <FormattedMessage {...messages.default} />
                          </Label>
                        )}
                      </td>
                      <td>
                        <span>
                          {/* Fix leading 0 for integer */}
                          ******{item.last4Digits && item.last4Digits.toString().length === 3 ? "0" + item.last4Digits : item.last4Digits} ({item.currency})
                        </span>
                      </td>
                      <td>
                        {item.isVerified === true && item.mandateConfirmed && (
                          // <FormattedMessage {...messages.ready} />
                          <span>Mandat erteilt</span>
                        )}
                        {item.isVerified !== true && (
                          <FormattedMessage {...messages.notReady} />
                        )}
                      </td>
                      <td className={s.textTruncate}>
                        {item.mandateDocumentUrl && (
                          <a
                            className={s.iconLink}
                            href={item.mandateDocumentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Mandat-Dokument"
                          >
                            <FaFile />
                          </a>
                        )}

                        {item.mandateConfirmUrl && !item.mandateConfirmed && (
                                     

                          <a
                            href={item.mandateConfirmUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >   {/* TODO Translate */}
                            Mandat bestätigen
                            <OverlayTrigger
                              overlay={
                                <Tooltip id={"tooltip" + index}>Hier können Sie das SEPA-Lastschriftmandat online erteilen.</Tooltip>
                              }
                              placement="top"
                            >
                              <span>
                                &nbsp;
                                <FaInfoCircle style={{ color: "#404040" }} />
                              </span>
                            </OverlayTrigger>
                          </a>
                        )}
                        {!item.default && item.isVerified === true && (
                          <a
                            href="javascript:void(0)"
                            className={cx(s.marginRight, {
                              [s.transparentText]: payinDefaultLoader,
                            })}
                            onClick={() => {
                              if (!payinDefaultLoader) {
                                setDefaultPayin(item.id);
                              }
                            }}
                          >
                            <FormattedMessage {...messages.setDefault} />
                          </a>
                        )}

                          <a
                            className={cx({
                              [s.transparentText]: payinRemoveLoader,
                            })}
                            href="javascript:void(0)"
                            onClick={() => {
                              if (!payinRemoveLoader) {
                                this.openModal();
                              }
                            }}
                          >
                            <FormattedMessage {...messages.remove} />
                          </a>
                      </td>

                      <Modal
          show={modalOpen}
          onHide={this.closeModal}
          animation={true}
          dialogClassName={cx(s.logInModalContainer, "loginModal")}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
                 {/* TODO Translate */}
            Sind Sie sicher dass Sie diese Einzahlungs-Methode entfernen möchten? Ihr SEPA-Lastschrift Mandat wird dann für dieses Konto zurückgezogen.
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={() => {
              removePayin(item.id, item.mandateId);
              this.closeModal();
            }}  bsStyle="primary">
              entfernen
            </Button>
          </Modal.Footer>
        </Modal>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className={cx(s.sectionTitleLight)}>
            <Button
              className={cx(s.button, s.btnlarge, s.btnPrimary)}
              onClick={this.handleClick}
            >
              <FormattedMessage {...messages.addPayin} />
            </Button>
            <span className={s.textMuted}>
              {/* &nbsp;
              <FormattedMessage {...messages.directDeposit} /> */}
            </span>
          </div>
        </div>
       
      </Panel>
    );
  }
}

const mapState = (state) => ({
  payinRemoveLoader: state.loader.payinRemove,
  payinDefaultLoader: state.loader.payinDefault,
  payinVerifyLoader: state.loader.payinVerify,
});

const mapDispatch = {
  removePayin,
  setDefaultPayin,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(PayinList))
);

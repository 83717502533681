exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}.kbPT2{padding-left:20px;padding-right:20px}._2yfE9{margin:0 auto;padding-top:30px;max-width:1080px;max-width:var(--max-content-width)}._2uDiI{font-size:95px}._1C4M9,._2uDiI{font-weight:700;margin:0}._1C4M9{font-size:85px}._2CU_T{text-align:center}._3uW9k{padding-left:0;list-style:none}._3ICGE{font-size:15px;color:#404040}a,a:hover{color:#0667b2}._2TDFj{margin-top:12px}._3PqFb{margin-bottom:12px}._2HgYR{margin-bottom:48px}._21iuF{margin-top:48px}._3wPmE{width:100%;background:#f5f5f5}@media (max-width:1023px) and (min-width:768px){._3wPmE{padding:0 10px}}@media (max-width:767px){._3wPmE{padding:0 10px}}", ""]);

// exports
exports.locals = {
	"root": "kbPT2",
	"container": "_2yfE9",
	"textJumbo": "_2uDiI",
	"textMedium": "_1C4M9",
	"textCenter": "_2CU_T",
	"listStyled": "_3uW9k",
	"subTitle": "_3ICGE",
	"spaceTop2": "_2TDFj",
	"space2": "_3PqFb",
	"space6": "_2HgYR",
	"spaceTop6": "_21iuF",
	"bgColor": "_3wPmE"
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Table, TBody, TR, TD} from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { sitename} from '../../../config';

class BookingExpiredGuest extends Component {
    static propTypes = {
		content: PropTypes.shape({
			listTitle: PropTypes.string.isRequired,
			guestName: PropTypes.string.isRequired,
			checkIn: PropTypes.string.isRequired,
			confirmationCode: PropTypes.number.isRequired,
		}).isRequired
    };

    render() {
    	const textStyle = {
	      color: '#404040',
	      backgroundColor: '#F7F7F7',
	      fontFamily: 'Arial',
	      fontSize: '16px',
	      padding: '35px',
	    };

		moment.locale("de-DE")
	    const { content: {guestName, listTitle, confirmationCode, checkIn, logo } } = this.props;
	    let checkInDate = checkIn != null ? moment(checkIn).format('ddd, Do MMM, YYYY') : '';
        return (
					<Layout>
						<Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
						<div>
							<Table width="100%" >
								<TBody>
									<TR>
										<TD style={textStyle}>
											<EmptySpace height={20} />
											<div>
												Hallo {guestName},
					        		</div>
											<EmptySpace height={20} />
											<div>
												Wir bedauern es sehr Ihnen mitzuteilen, dass Ihre Buchungsanfrage {' '}{confirmationCode} für die Fläche {listTitle} ab dem {checkInDate} leider innerhalb der Frist nicht beantwortet wurde.
					        		</div>
											<EmptySpace height={40} />
											<div>
											  Vielen Dank, <br />
												Ihr {sitename} Team
					        		</div>
										</TD>
									</TR>
								</TBody>
							</Table>
							<EmptySpace height={40} />
						</div>
						<Footer />
						<EmptySpace height={20} />
					</Layout>
        );
    }
}

export default BookingExpiredGuest;

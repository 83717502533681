// General
import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { FaBolt, FaShieldAlt } from "react-icons/fa";
// Translation
import { FormattedMessage } from "react-intl";
import AutoAffix from "react-overlays/lib/AutoAffix";
// Redux
import { connect } from "react-redux";
// ES6 Imports
import Scroll from "react-scroll"; // Imports all Mixins
import { openBookingModal } from "../../actions/BookingModal/modalActions";
import CurrencyConverter from "../../components/CurrencyConverter";
import Loader from "../../components/Loader";
import StarRating from "../../components/StarRating";
// Components
import Agentbox from "../../components/ViewListing/Agentbox";
import AvailabilityCalendar from "../../components/ViewListing/AvailabilityCalendar";
import BookingModal from "../../components/ViewListing/BookingModal";
import Calendar from "../../components/ViewListing/Calendar";
import HostDetail from "../../components/ViewListing/HostDetail";
import ListingDetails from "../../components/ViewListing/ListingDetails";
import ListingIntro from "../../components/ViewListing/ListingIntro";
import LocationMap from "../../components/ViewListing/LocationMap";
import ManualRequest from "../../components/ViewListing/ManualRequest/ManualRequest";
import Photos from "../../components/ViewListing/Photos";
import Reviews from "../../components/ViewListing/Reviews";
import SimilarListings from "../../components/ViewListing/SimilarListings";
// Locale
import messages from "../../locale/messages";
import NotFound from "../notFound/NotFound";
// Graphql
import BlockedDatesQuery from "./BlockedDates.graphql";
import ListingDataQuery from "./getListingData.graphql";
import SimilarListsQuery from "./getSimilarListing.graphql";
import MoreReviewsQuery from "./MoreReviews.graphql";
import s from "./ViewListing.css";

// Or Access Link,Element,etc as follows
let Link = Scroll.Link;
let Element = Scroll.Element;
let Events = Scroll.Events;
let scroll = Scroll.animateScroll;
let scrollSpy = Scroll.scrollSpy;
var durationFn = function (deltaTop) {
  return deltaTop;
};
class ViewListing extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    listId: PropTypes.number.isRequired,
    preview: PropTypes.bool,
    isAdmin: PropTypes.bool,
    account: PropTypes.shape({
      userId: PropTypes.string,
      userBanStatus: PropTypes.number,
    }),
    ListingBlockedDates: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.shape({
        blockedDates: PropTypes.array,
      }),
    }),
    getListingData: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.object,
    }),
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    similarListsData: PropTypes.shape({
      loading: PropTypes.bool,
      getSimilarListing: PropTypes.array,
    }),
    openBookingModal: PropTypes.func,
  };
  static defaultProps = {
    getListingData: {
      loading: false,
      UserListing: {
        userId: null,
      },
    },
    ListingBlockedDates: {
      loading: true,
      UserListing: {
        blockedDates: [],
      },
    },
    account: {
      userId: null,
      userBanStatus: 0,
    },
    isAdmin: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showManualRequestModal: false,
    };
  }

  toggleManualRequestModal = () => {
    this.setState({ showManualRequestModal: !this.state.showManualRequestModal });
  };

  render() {
    const {
      listId,
      title,
      getListingData: { loading, UserListing },
      preview,
    } = this.props;
    const { ListingBlockedDates } = this.props;
    const { listReviewsData } = this.props;
    const { showManualRequestModal } = this.state;
    const { openBookingModal, baseCurrency } = this.props;
    const {
      account: { userId, userBanStatus },
      isAdmin,
    } = this.props;
    const {
      lat,
      lng,
      startDate,
      endDate,
      guests,
      similarListsData,
      similarListsData: { getSimilarListing },
    } = this.props;
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;

    let basePrice = UserListing && UserListing.listingData && UserListing.listingData.basePrice ? UserListing.listingData.basePrice : 0;
    let currencyValue = UserListing && UserListing.listingData && UserListing.listingData.currency ? UserListing.listingData.currency : "USD";

    if (isBrowser && loading && !UserListing) {
      return <Loader type="text" />;
    }
    let isHost = false;
    if (UserListing) {
      if (userId && userId === UserListing.userId) {
        isHost = true;
      } else if (isAdmin) {
        isHost = true;
      }
    }
    if (preview && !isHost) {
      return <NotFound title={title} />;
    }
    if (!loading && !UserListing) {
      return <NotFound title={title} />;
    }
    let reviewsCount = UserListing.reviewsCount;
    let reviewsStarRating = UserListing.reviewsStarRating;
    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Number(reviewsStarRating / reviewsCount);
    }
    const insurable = UserListing.securityClass !== "SK0";
    return (
      <div className={s.root}>
        <div className={s.container}>
          {UserListing && (
            <div className={s.pageContainer}>
              <Photos data={UserListing} loading={loading} />
              <Element name="test1" className="element">
                <Grid fluid className={cx(s.horizontalLineThrough)}>
                  <Row className={cx(s.pageContent)}>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Row>
                        <div className={s.stickyContainer}>
                          <AutoAffix viewportOffsetTop={0} container={this} affixClassName={s.showAffix}>
                            <div className={cx(s.zPanel, s.panelDefault)}>
                              <div className={cx("stickHeader", s.viewHeader)}>
                                <div className={s.headerNav}>
                                  <div className={s.textColor}>
                                    <ul className={cx("list-inline", s.stickyMenu)}>
                                      <li>
                                        <Link
                                          className={cx(s.textList)}
                                          activeClass={s.active}
                                          to="test1"
                                          spy={true}
                                          smooth={true}
                                          offset={-50}
                                          duration={800}
                                          onSetActive={this.handleSetActive}
                                        >
                                          <FormattedMessage {...messages.overview} />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          className={cx(s.textList)}
                                          activeClass={s.active}
                                          to="test2"
                                          spy={true}
                                          smooth={true}
                                          offset={-80}
                                          duration={800}
                                          onSetActive={this.handleSetActive}
                                        >
                                          <FormattedMessage {...messages.reviews} />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          className={cx(s.textList)}
                                          activeClass={s.active}
                                          to="test3"
                                          spy={true}
                                          smooth={true}
                                          offset={-70}
                                          duration={800}
                                          onSetActive={this.handleSetActive}
                                        >
                                          <FormattedMessage {...messages.theHost} />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          className={cx(s.textList)}
                                          activeClass={s.active}
                                          to="test4"
                                          spy={true}
                                          smooth={true}
                                          offset={-70}
                                          duration={800}
                                          onSetActive={this.handleSetActive}
                                        >
                                          <FormattedMessage {...messages.location} />
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AutoAffix>
                        </div>
                        <Grid fluid>
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className={cx(s.paddingIntro, s.listingIntroSection)}>
                              <ListingIntro data={UserListing} reviewsCount={UserListing.reviewsCount} reviewsStarRating={UserListing.reviewsStarRating} />
                            </Col>
                          </Row>
                        </Grid>
                        <Grid fluid className={cx(s.horizontalLineThrough, s.noBorder)}>
                          <ListingDetails data={UserListing} isHost={isHost} userBanStatus={userBanStatus} />
                        </Grid>
                        <Grid fluid className={"availabilityMobile"}>
                          <AvailabilityCalendar
                            listId={listId}
                            smallDevice={smallDevice}
                            loading={ListingBlockedDates.loading}
                            blockedDates={ListingBlockedDates.UserListing != null ? ListingBlockedDates.UserListing.blockedDates : undefined}
                            listingData={UserListing.listingData || undefined}
                          />
                        </Grid>
                        <Element name="test2" className="element">
                          <Grid fluid>
                            <Reviews
                              reviewsCount={UserListing.reviewsCount}
                              reviewsStarRating={UserListing.reviewsStarRating}
                              data={listReviewsData}
                              listId={listId}
                            />
                          </Grid>
                        </Element>
                        {/* <Element name="test3" className="element">
                          <Grid fluid>
                            <HostDetail
                              id={UserListing.id}
                              userId={UserListing.userId}
                              hostEmail={UserListing.user.email}
                              personCapacity={UserListing.personCapacity}
                              city={UserListing.city}
                              listingData={UserListing.listingData || undefined}
                              profile={UserListing.user.profile || undefined}
                              blockedDates={ListingBlockedDates.UserListing != null ? ListingBlockedDates.UserListing.blockedDates : undefined}
                              isHost={isHost}
                              userBanStatus={userBanStatus}
                            />
                          </Grid>
                        </Element> */}
                        <Element name="test4" className="element">
                          <Grid fluid className={cx(s.paddingTop2)}>
                            <LocationMap data={UserListing} />
                          </Grid>
                        </Element>
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className={s.positionSticky}>
                      {!smallDevice && !loading && (
                        <div className={cx(s.responsiveNopadding, "hidden-xs hidden-sm")}>
                          <Calendar
                            id={UserListing.id}
                            loading={ListingBlockedDates.loading}
                            blockedDates={ListingBlockedDates.UserListing != null ? ListingBlockedDates.UserListing.blockedDates : undefined}
                            coverPhoto={UserListing.listPhotos[0].name}
                            personCapacity={UserListing.personCapacity}
                            listingData={UserListing.listingData || undefined}
                            isHost={isHost}
                            bookingType={UserListing.bookingType}
                            userBanStatus={userBanStatus}
                            startDate={startDate}
                            endDate={endDate}
                            reviewsCount={UserListing.reviewsCount}
                            reviewsStarRating={UserListing.reviewsStarRating}
                            guests={guests}
                            insurable={insurable}
                            listingTitle={UserListing.title}
                            listingSize={UserListing.personCapacity}
                            hostProfile={UserListing.user.profile}
                            houseType={UserListing.houseType}

                          />
                        </div>
                      )}
                      {!smallDevice && loading && (
                        <div className={cx(s.webDevice, s.responsiveNopadding, "hidden-xs hidden-sm")}>
                          <Calendar
                            id={UserListing.id}
                            loading={ListingBlockedDates.loading}
                            blockedDates={ListingBlockedDates.UserListing != null ? ListingBlockedDates.UserListing.blockedDates : undefined}
                            personCapacity={UserListing.personCapacity}
                            listingData={UserListing.listingData || undefined}
                            coverPhoto={UserListing.listPhotos[0].name}
                            isHost={isHost}
                            bookingType={UserListing.bookingType}
                            userBanStatus={userBanStatus}
                            startDate={startDate}
                            endDate={endDate}
                            reviewsCount={UserListing.reviewsCount}
                            reviewsStarRating={UserListing.reviewsStarRating}
                            guests={guests}
                            insurable={insurable}
                            listingTitle={UserListing.title}
                            listingSize={UserListing.personCapacity}
                            hostProfile={UserListing.user.profile}
                            houseType={UserListing.houseType}

                          />
                        </div>
                      )}

                      {/* <Agentbox
                        hostProfile={UserListing.user.profile}
                        id={UserListing.id}
                        coverPhoto={UserListing.listPhotos[0].name}
                        personCapacity={UserListing.personCapacity}
                        listingTitle={UserListing.title}
                        hostProfile={UserListing.user.profile}
                        mobile={false}
                      /> */}
                    </Col>
                  </Row>
                  {similarListsData && similarListsData.getSimilarListing && similarListsData.getSimilarListing.length > 0 && (
                    <Grid fluid className={cx(s.space2, s.noPaddingMobile, s.pageContent, s.similarPadding)}>
                      <Row className={cx(s.space3, s.sliderMargin, "similarListing", s.similarPadding)}>
                        <Col xs={12} sm={12} md={12} lg={12} className={cx(s.spaceTop2, s.space2, s.similarPaddingText)}>
                          <h2 className={cx(s.sectionTitleText, s.space2)}>
                            <FormattedMessage {...messages.similarListings} />
                          </h2>
                        </Col>
                        <SimilarListings data={similarListsData.getSimilarListing} />
                      </Row>
                    </Grid>
                  )}
                </Grid>
              </Element>
            </div>
          )}
          <div className={cx(s.stickyBookButton, "visible-sm visible-xs")}>
            <div className={cx(s.filtersFooter)}>
              <div className={s.filtersContainer}>
                <Row className={s.footerContainer}>
                  <div className={cx(s.displayTable, s.displayTableMobile)}>
                    <div className={cx(s.smSpace, s.displayTableCell, s.displayBlockMobile)}>
                      <div className={s.bookItPriceAmount}>
                        <div className={s.bookItPrice}>
                          <CurrencyConverter
                            from={currencyValue || baseCurrency}
                            amount={parseFloat((basePrice / UserListing.personCapacity) * 30)}
                            className={s.bookItPrice}
                          />
                        </div>
                        <span className={s.bookItNight}> / m²</span>
                        {UserListing && UserListing.bookingType === "instant" && (
                          <span title="sofortbuchen">
                            <FaBolt className={s.instantIcon} />
                          </span>
                        )}
                        {insurable && (
                          <span title="versicherbar">
                            <FaShieldAlt className={s.insurableIcon} />
                          </span>
                        )}
                      </div>
                      <div>
                        <div className={s.reviewSection}>
                          <StarRating name={"review"} value={starRatingValue} />
                        </div>
                        <div>
                          <span>
                            {reviewsCount > 0 && (
                              <span>
                                {reviewsCount} {reviewsCount > 1  || reviewsCount === 0 ? <FormattedMessage {...messages.reviews} /> : <FormattedMessage {...messages.review} />}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={cx(s.displayTableCell, s.displayBlockMobile)}>
                      <BookingModal
                        id={UserListing.id}
                        loading={ListingBlockedDates.loading}
                        personCapacity={UserListing.personCapacity}
                        listingData={UserListing.listingData || undefined}
                        isHost={isHost}
                        bookingType={UserListing.bookingType}
                        reviewsCount={UserListing.reviewsCount}
                        reviewsStarRating={UserListing.reviewsStarRating}
                        insurable={insurable}
                        houseType={UserListing.houseType}
                        hostProfile={UserListing.user.profile}
                        listingTitle={UserListing.title}

                      />
                      <Button className={cx(s.btn, s.btnPrimary, s.fullWidth)} onClick={openBookingModal}>
                        <FormattedMessage {...messages.bookNow} />
                      </Button>
                      {/* <Button
                        className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnManualRequest, s.fullWidth, s.manualRequestBtn)}
                        onClick={this.toggleManualRequestModal}
                      >
                        Teilfläche anfragen
                      </Button> */}
                    </div>
                    {/* <Agentbox
                      hostProfile={UserListing.user.profile}
                      id={UserListing.id}
                      coverPhoto={UserListing.listPhotos[0].name}
                      personCapacity={UserListing.personCapacity}
                      listingTitle={UserListing.title}
                      hostProfile={UserListing.user.profile}
                      mobile={true}
                    /> */}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {showManualRequestModal && (
          <ManualRequest
            hostProfile={UserListing.user.profile}
            showManualRequestModal={showManualRequestModal}
            id={UserListing.id}
            picture={UserListing.listPhotos[0].name}
            personCapacity={UserListing.personCapacity}
            minNight={UserListing.listingData.minNight}
            maxNight={UserListing.listingData.maxNight}
            blockedDates={ListingBlockedDates.UserListing != null ? ListingBlockedDates.UserListing.blockedDates : undefined}
            maxDaysNotice={UserListing.listingData.maxDaysNotice}
            listingTitle={UserListing.title}
            listingSize={UserListing.personCapacity}
            toggleManualRequest={this.toggleManualRequestModal}
          />
        )}
      </div>
    );
  }
}
const mapState = (state) => ({
  account: state.account.data,
  isAdmin: state.runtime.isAdminAuthenticated,
});
const mapDispatch = {
  openBookingModal,
};
export default compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(ListingDataQuery, {
    name: "getListingData",
    options: (props) => ({
      variables: {
        listId: props.listId,
        preview: props.preview,
      },
      fetchPolicy: "network-only",
      ssr: true,
    }),
  }),
  graphql(BlockedDatesQuery, {
    name: "ListingBlockedDates",
    options: (props) => ({
      variables: {
        listId: props.listId,
        preview: props.preview,
      },
      fetchPolicy: "network-only",
      ssr: false,
    }),
  }),
  graphql(MoreReviewsQuery, {
    name: "listReviewsData",
    options: (props) => ({
      variables: {
        listId: props.listId,
      },
      fetchPolicy: "network-only",
      ssr: false,
    }),
  }),
  graphql(SimilarListsQuery, {
    name: "similarListsData",
    options: (props) => ({
      variables: {
        listId: props.listId,
        lat: props.lat,
        lng: props.lng,
      },
      fetchPolicy: "network-only",
      ssr: false,
    }),
  })
)(ViewListing);

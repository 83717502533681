import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Payin.css';
import { Button, Modal } from "react-bootstrap";
import cx from "classnames";
// Component
import PayinList from './PayinList/PayinList';
import EmptyList from './PayinList/EmptyList';
import Loader from '../Loader/Loader';
import { FormattedMessage } from "react-intl";
// Locale
import messages from "../../locale/messages";
// Redirection
import history from "../../core/history";
class Payin extends Component {
  static defaultProps = {
    loading: true,
    data: []
  }

  constructor(props) {
    super(props);

    this.state = {
      initialLoad: true,
      modalOpen: false,
    };
  }
  componentWillMount() { }
  componentDidMount() {
    let savedBookingData = JSON.parse(localStorage.getItem('booking'));
    if (savedBookingData && ((new Date().getTime()) - new Date(savedBookingData.timestamp).getTime()) > 1000 * 60 * 5) {
      localStorage.removeItem("booking");
             
    } else if (savedBookingData) {
      history.push( savedBookingData.location)
    }
    this.setState({
      initialLoad: false
    });
   
  }
  
  closeModal = () => {
    //redirect to profile
    history.push("/user/editfull");
  };
 
  render() {
    const { data, loading, currentAccountId,  accountData } = this.props;
    const { initialLoad } = this.state;
    const profileComplete = accountData ? accountData.profileComplete : null;
    const { modalOpen } = this.state;
    if (!profileComplete && !modalOpen && profileComplete !== null) {
      //show modal then redirect to edit profile
      this.setState({ modalOpen: true });
    }

    if (loading && initialLoad) {
      return <Loader type={"text"} />;
    }
    return (
      <div>
        {data != undefined && data.length > 0 ? <PayinList data={data} currentAccountId={currentAccountId} /> : <EmptyList />}
        <Modal show={modalOpen} onHide={this.closeModal} animation={true} dialogClassName={cx(s.logInModalContainer, "loginModal")}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              <FormattedMessage {...messages.completeProfileFirst} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.closeModal} bsStyle="primary">
              <FormattedMessage {...messages.completeProfile} />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  data: state.payin.data,
  loading: state.payin.getPayinLoading,
  profileComplete: state.account.data.profileComplete,
  accountData: state.account.data,
});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(Payin));
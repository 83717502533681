exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._31Bfn{padding-left:20px;padding-right:20px}._1ek3c{margin:0 auto;padding:0 0 40px;max-width:700px}._11Iu7{font-size:95px}._11Iu7,.VSqsx{font-weight:700;margin:0}.VSqsx{font-size:50px}._3WWfA{text-align:center}._31GE8{padding-left:0;list-style:none}.HV1_G{font-size:22px;color:#404040;margin-left:10px}a,a:hover{color:#0667b2}._5ODzV{margin-top:12px}.jAeNx{margin-bottom:12px}.FhVzt{margin-bottom:48px}._1qzXv{margin-top:48px}._1ZHVr{font-size:22px!important;line-height:normal;margin-top:36px}", ""]);

// exports
exports.locals = {
	"root": "_31Bfn",
	"container": "_1ek3c",
	"textJumbo": "_11Iu7",
	"textMedium": "VSqsx",
	"textCenter": "_3WWfA",
	"listStyled": "_31GE8",
	"subTitle": "HV1_G",
	"spaceTop2": "_5ODzV",
	"space2": "jAeNx",
	"space6": "FhVzt",
	"spaceTop6": "_1qzXv",
	"subText": "_1ZHVr"
};
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox5.css";
import { Grid, Row, Col, Button } from "react-bootstrap";
import Link from "../../Link/Link";
class ContentBox5 extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={cx(s.content)}>
        <Row className={cx(s.box)}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h4 className={s.subHeadline}>VERMIETEN WAR NIE EINFACHER</h4>
            <h2 className={s.headline}>Sie haben Lagerflächen? </h2>
            <div className={s.text}>Finden Sie jetzt Ihren nächsten Mieter - für heute oder erst in einem Jahr</div>
            <Link className={cx(s.button, s.btnPrimary, s.spaceTop48)} to="/become-a-host?mode=new">
              Jetzt kostenlos Inserat erstellen
            </Link>
            <Link className={cx(s.button, s.btnSecondary, s.spaceTop48)} to="/flaeche-anbieten">
              mehr erfahren
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(ContentBox5);

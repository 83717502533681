import messages from "../../../locale/messages";
import IBAN from "iban";

function isBic(value) {
  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
    value.toUpperCase()
  );
}
const validate = (values) => {
  const errors = {};

  if (!values.iban) {
    errors.iban = messages.required;
  } else if (!IBAN.isValid(values.iban.replace(/ /g, ""))) {
    errors.iban = messages.ibanInvalid;
  }

  if (!values.bic) {
    errors.bic = messages.required;
  } else if(!isBic(values.bic)) {
    errors.bic = messages.bicInvalid;
  }

  return errors;
};

export default validate;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Redux
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

// Translation
import { FormattedMessage, injectIntl } from "react-intl";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./DetailSearchForm.css";

import { Button, Grid, Row, Col, FormControl } from "react-bootstrap";
import cx from "classnames";
import { FaSearch } from "react-icons/fa";

// History
import history from "../../../core/history";

// Components
import DateRange from "../DateRange/DateRange";
import PlaceGeoSuggest from "../PlaceGeoSuggest/PlaceGeoSuggest";
import MobileDateRange from "../MobileDateRange/MobileDateRange";

// Redux Action
import { setPersonalizedValues } from "../../../actions/personalized";

// Helper
import detectMobileBrowsers from "../../../helpers/detectMobileBrowsers";

import { environment } from "../../../config";

// Locale
import messages from "../../../locale/messages";
import { TSThisType } from "babel-types";

class DetailSearchForm extends React.Component {
  static propTypes = {
    setPersonalizedValues: PropTypes.any,
    personalized: PropTypes.shape({
      location: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      chosen: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      personCapacity: PropTypes.number,
      formatMessage: PropTypes.any,
    }),
    settingsData: PropTypes.shape({
      listSettings: PropTypes.array,
    }),
  };

  static defaultProps = {
    listingFields: [],
  };

  static defaultProps = {
    personalized: {
      location: null,
      lat: null,
      lng: null,
      startDate: null,
      endDate: null,
      personCapacity: null,
      roomType: null,
      chosen: null,
    },
    settingsData: {
      listSettings: [],
    },
  };

  constructor(props) {
    super(props);
    (this.state = {
      mobileDevice: false,
      personCapacity: [],
      roomType: [],
      isLoad: false,
      focus: false,
      showContactForm: false,
      showFormErrors: false,
    }),
      (this.handleClick = this.handleClick.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  componentWillMount() {
    const { listingFields } = this.props;

    this.setState({ isLoad: true });
    if (detectMobileBrowsers.isMobile() === true) {
      this.setState({ mobileDevice: true });
    }
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  componentDidMount() {
    this.setState({ isLoad: false });
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, isDisabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={isDisabled} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  handleClick = () => {
    const { personalized, user, account } = this.props;
    let savedContactData = JSON.parse(localStorage.getItem("contactData"));

    let updatedURI,
      uri = "/search?";

    if (!personalized.location) {
      this.setState({ focus: true });
      return;
    }
    if (!this.state.showContactForm && !user && !savedContactData) {
      this.setState({ showContactForm: true });
      return;
    }
    //use first option if not selected
    let roomType = personalized.roomType || 0;

    if (personalized.chosen != null) {
      uri = uri + "&address=" + personalized.location + "&chosen=" + personalized.chosen;
    } else {
      if (personalized.location != null) {
        uri = uri + "&address=" + personalized.location;
      }
    }

    if (personalized.startDate != null && personalized.endDate != null) {
      uri = uri + "&startdate=" + personalized.startDate + "&enddate=" + personalized.endDate;
    }

    if (personalized.personCapacity != null && !isNaN(personalized.personCapacity)) {
      uri = uri + "&guests=" + personalized.personCapacity;
    }
    if (roomType != null && !isNaN(roomType) && roomType !== 0) {
      uri = uri + "&roomType=" + roomType;
    }


    let name, company, email, phone;
    if (user) {
      const firstName = account && account.data && account.data.firstName ? account.data.firstName : "keine Angabe";
      const lastName = account && account.data && account.data.lastName ? account.data.lastName : "";

      email = user && user.email ? user.email : "keine Angabe";
      phone = account && account.data && account.data.phoneNumber ? account.data.phoneNumber : "keine Angabe";
      name = firstName + " " + lastName;
      company = account && account.data && account.data.companyName ? account.data.companyName : "keine Angabe";
      const data = {
        name,
        company,
        email,
        phone,
      };
      this.sendFormToHubspot(data);
      updatedURI = encodeURI(uri);
      history.push(updatedURI);
    } else if (savedContactData) {
      //use data from localstorage
      email = savedContactData.email;
      phone = savedContactData.phone;
      name = savedContactData.name;
      company = savedContactData.company;
      const data = {
        name,
        company,
        email,
        phone,
      };
      this.sendFormToHubspot(data);
      updatedURI = encodeURI(uri);
      history.push(updatedURI);
    } else {
      //Send to hubspot lead form
      const name = personalized.name;
      const company = personalized.company;
      const phone = personalized.phone;
      const email = personalized.email;
      //save data to localstorage
      const contactData = {
        name,
        company,
        phone,
        email,
      };
      localStorage.setItem("contactData", JSON.stringify(contactData));
      if (name && company && phone && email) {
        this.sendFormToHubspot(null);
        updatedURI = encodeURI(uri);
        history.push(updatedURI);
      } else {
        this.setState({ showFormErrors: true });
      }
    }
  };

  sendFormToHubspot = (data) => {
    const { personalized } = this.props;
    let hsData = data ? data : personalized;
    const name = hsData.name;
    const company = hsData.company;
    const phone = hsData.phone;
    const email = hsData.email;

    const portalId = "8224909";
    const formId = environment === "production" ? "ab674ec9-40df-4af0-972a-6bc2f6c6a9c0" : "25b17eb0-cfc7-405d-a285-8712e1f7f915";
    const url = "https://api.hsforms.com/submissions/v3/integration/submit/" + portalId + "/" + formId;
    // Build request JSON:
    const areaTypeMapping = {
      129: "Lager- & Logistikfläche",
      130: "Produktionsfläche",
      131: "Außenfläche",
      132: "Offene Lager- & Logistikfläche",
    };
    const flaechenBedarf = personalized.personCapacity === 1 ? "ab 250m²" : personalized.personCapacity;
    const flaechenArt = areaTypeMapping[personalized.roomType] ? areaTypeMapping[personalized.roomType] : "beliebig";
    const mietZeitraum = personalized.startDate
      ? moment(personalized.startDate).format("DD.MM.YYYY") + " - " + moment(personalized.endDate).format("DD.MM.YYYY")
      : "nicht angegeben";
    var hubspotData = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "name",
          value: name,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "company",
          value: company,
        },
        {
          name: "flache_bedarf",
          value: flaechenBedarf,
        },
        {
          name: "flachenart",
          value: flaechenArt,
        },
        {
          name: "gew_nschter_lagerort_plz_ort_",
          value: personalized.location,
        },
        {
          name: "mietzeitraum",
          value: mietZeitraum,
        },
      ],
      context: {
        hutk: this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Expozed1 to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999, // replace with your subscription ID
              text: "I agree to receive marketing communications from Expozed1.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(hubspotData),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {})
      .catch((error) => {
        console.log("error", error);
      });
  };

  getCookie = (name) => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        <FormControl {...input} placeholder={label} type={type} className={className} required />
      </div>
    );
  };

  render() {
    const { setPersonalizedValues, personalized, user } = this.props;
    const { isLoad } = this.state;
    const { formatMessage } = this.props.intl;
    const { roomType, focus, showContactForm, showFormErrors } = this.state;
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;

    let disabled = false;

    const validateRequired = (value) => (value ? undefined : "Benötigt");
    const validateEmail = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "ungültige Email-Adresse" : undefined);
    let rowsPersonCapacity = [];
    let roomTypeData = [...roomType];
    roomTypeData.unshift({
      endValue: null,
      id: 0,
      isEnable: "1",
      itemDescription: null,
      itemName: "beliebig",
      maximum: null,
      minimum: null,
      otherItemName: null,
      startValue: null,
      typeId: 1,
    });
    let startValue, endValue;
    rowsPersonCapacity.push(
      <option value={250} key={250}>
        ab 250m²
      </option>
    );
    rowsPersonCapacity.push(
      <option value={500} key={500}>
        ab 500m²
      </option>
    );
    rowsPersonCapacity.push(
      <option value={1000} key={1000}>
        ab 1000m²
      </option>
    );

    if (!personalized.chosen) {
      disabled = true;
    }

  
    const savedContactData = typeof window !== 'undefined' && localStorage.getItem("contactData");
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className={s.pad0}>
            <div className={s.header}>
              <h4>Jetzt Fläche finden</h4>
              <span>Lager- und Produktionsflächen in ganz Deutschland buchen</span>
            </div>

            <form>
              {showContactForm ? (
                <div className={cx(s.searchFormInputs, "homeSearchForm vidSearchForm")}>
                  <div className={s.searchForm}>
                    <div className={cx(s.table)}>
                      <div className={cx(s.tableRow)}>
                        {/* This is only to preserve the same layout display */}
                        <div className={cx(s.tableCell, s.guests, s.mobilePadding)}>
                          <label className={cx(s.selectPadding, s.label)}>Name</label>
                          <Field
                            name="name"
                            type="text"
                            component={this.renderFormControl}
                            label="Name"
                            onChange={(e) => setPersonalizedValues({ name: "name", value: e.target.value })}
                            className={cx(s.inputField, showFormErrors && !personalized.name && s.errorField)}
                          />
                        </div>
                        <div className={cx(s.tableCell, s.guests, s.mobilePadding)}>
                          <label className={cx(s.selectPadding, s.label)}>Firma</label>

                          <Field
                            name="company"
                            type="text"
                            component={this.renderFormControl}
                            label="Firma"
                            onChange={(e) => setPersonalizedValues({ name: "company", value: e.target.value })}
                            className={cx(s.inputField, showFormErrors && !personalized.company && s.errorField)}
                          />
                        </div>
                      </div>
                      <div className={cx(s.tableRow)}>
                        <div className={cx("col-lg-6 col-md-6 col-sm-6 col-xs-12", s.boxLeft)}>
                          <div className={cx(s.tableCell, s.guests, s.mobilePadding)}>
                            <label className={cx(s.selectPadding, s.label)}>Email</label>
                            <Field
                              name="email"
                              type="text"
                              component={this.renderFormControl}
                              label="Email"
                              onChange={(e) => setPersonalizedValues({ name: "email", value: e.target.value })}
                              className={cx(s.inputField, showFormErrors && !personalized.email && s.errorField)}
                            />
                          </div>
                        </div>
                        <div className={cx("col-lg-6 col-md-6 col-sm-6 col-xs-12", s.boxRight)}>
                          <div className={cx(s.tableCell, s.guests, s.mobilePadding)}>
                            <label className={cx(s.selectPadding, s.label)}>Telefon</label>
                            <Field
                              name="phone"
                              type="text"
                              component={this.renderFormControl}
                              label="Telefon"
                              onChange={(e) => setPersonalizedValues({ name: "phone", value: e.target.value })}
                              className={cx(s.inputField, showFormErrors && !personalized.phone && s.errorField)}
                            />
                          </div>
                        </div>

                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className={cx(s.search)}>
                              <Button className={cx(s.btn, s.btnPrimary, s.btnBlock)} onClick={this.handleClick}>
                                <span className={cx("hidden-lg hidden-xs")}>
                                  <FaSearch />
                                </span>
                                <span className={cx("hidden-md hidden-sm")}>Anfragen & Suchen</span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={cx(s.searchFormInputs, "homeSearchForm vidSearchForm")}>
                  <div className={s.searchForm}>
                    <div className={cx(s.table)}>
                      <div className={cx(s.tableRow)}>
                        <div className={cx(s.tableCell, s.location)}>
                          <label className={s.label}>
                            <span>
                              {" "}
                              <FormattedMessage {...messages.where} />
                            </span>
                          </label>
                          <label className={s.searchElement} aria-label="Location input">
                            {!isLoad && (
                              <PlaceGeoSuggest
                                label={formatMessage(messages.homeWhere)}
                                className={cx(s.formControlInput, s.input)}
                                containerClassName={s.geoSuggestContainer}
                                focus={focus}
                              />
                            )}
                            {isLoad && (
                              <Field
                                component={this.renderFormControl}
                                label={formatMessage(messages.homeWhere)}
                                className={cx(s.formControlInput, s.input, s.loadfield)}
                                name="location"
                              />
                            )}
                          </label>
                        </div>
                        <div className={cx(s.tableCell, s.dates)}>
                          <label className={s.label}>
                            <span>
                              {" "}
                              <FormattedMessage {...messages.when} />
                            </span>
                          </label>
                          <span className={cx("homeDate vidFormsearch", s.input)}>
                            {!smallDevice && <DateRange formName={"SearchForm"} numberOfMonths={2} />}
                            {smallDevice && <MobileDateRange formName={"SearchForm"} numberOfMonths={1} />}
                          </span>
                        </div>
                        <div className={cx("col-lg-6 col-md-6 col-sm-6 col-xs-12", s.boxLeft)}>
                          <div className={cx(s.tableCell, s.guests, s.mobilePadding)}>
                            <label className={cx(s.selectPadding, s.label)}>
                              {/* <FormattedMessage {...messages.guest} /> */}
                              Flächenbedarf{" "}
                            </label>
                            <FormControl
                              componentClass="select"
                              className={cx(s.formControlSelect, s.input, s.inputPadding, s.formControlSelect, s.input, s.inputPadding, "selectNew")}
                              onChange={(e) => setPersonalizedValues({ name: "personCapacity", value: Number(e.target.value) })}
                              defaultValue={personalized.personCapacity}
                            >
                              {rowsPersonCapacity}
                            </FormControl>
                          </div>
                        </div>
                        <div className={cx("col-lg-6 col-md-6 col-sm-6 col-xs-12", s.boxRight)}>
                          <div className={cx(s.tableCell, s.guests, s.mobilePadding)}>
                            <label className={cx(s.selectPadding, s.label)}>
                              {/* <FormattedMessage {...messages.guest} /> */}
                              Flächenart{" "}
                            </label>
                            <FormControl
                              componentClass="select"
                              className={cx(s.formControlSelect, s.input, s.inputPadding, s.selectBorder, "selectNew")}
                              onChange={(e) => setPersonalizedValues({ name: "roomType", value: Number(e.target.value) })}
                              defaultValue={personalized.roomType}
                            >
                              {roomTypeData.map((value, key) => {
                                return (
                                  value.isEnable == 1 && (
                                    <option value={value.id} key={key}>
                                      {value.itemName}
                                    </option>
                                  )
                                );
                              })}
                            </FormControl>
                          </div>
                        </div>

                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className={cx(s.search)}>
                              <Button className={cx(s.btn, s.btnPrimary, s.btnBlock)} onClick={this.handleClick}>
                                <span className={cx("hidden-lg hidden-xs")}>
                                  <FaSearch />
                                </span>
                                <span className={cx("hidden-md hidden-sm")}>{user || savedContactData ? "Suchen" : "Weiter"}</span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
DetailSearchForm = reduxForm({
  form: "DetailSearchForm", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(DetailSearchForm);

const selector = formValueSelector("DetailSearchForm"); // <-- same as form name

const mapState = (state) => ({
  personalized: state.personalized,
  user: state.user,
  account: state.account,
  settingsData: state.viewListing.settingsData,
  listingFields: state.listingFields.data,
  location: selector(state, "location"),
});

const mapDispatch = {
  setPersonalizedValues,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DetailSearchForm)));

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { checkInData, checkInFormat } from '../../../helpers/timeHelper';
import { url, sitename } from '../../../config';

class BookingConfirmationHost extends React.Component {

  static propTypes = {
    content: PropTypes.shape({
      reservationId: PropTypes.number.isRequired,
      threadId: PropTypes.number.isRequired,
      confirmationCode: PropTypes.number.isRequired,
      guestName: PropTypes.string.isRequired,
      guestLastName: PropTypes.string.isRequired,
      guestLocation: PropTypes.string.isRequired,
      guestProfilePic: PropTypes.string.isRequired,
      guestJoinedDate: PropTypes.string.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkOut: PropTypes.string.isRequired,
      guests: PropTypes.number.isRequired,
      allowedCheckInTime: PropTypes.string.isRequired,
      allowedCheckOutTime: PropTypes.string.isRequired,
    }).isRequired
  };

  render() {
    const textStyle = {
      color: '#404040',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '10px',
      textAlign: 'center'
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#0667B3',
      backgroundColor: '#0667B3',
      color: '#ffffff',
      borderTopWidth: '1px',

    }

    const bookingTitle = {
      paddingBottom: '25px',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '36px',
      margin: '0',
      padding: '0',
      textAlign: 'center'

    }

    const profilePic = {
      borderRadius: '999px',
      margin: '0',
      padding: '0',
      lineHeight: '150%',
      borderSpacing: '0',
      width: '125px'
    }

    const userName = {
      color: '#565a5c',
      fontSize: '26px',
      fontWeight: 'bold',
      paddingBottom: '5px',
    }

    const subTitle = {
      color: '#565a5c',
      fontSize: '18px',
      fontWeight: 'bold',
      paddingBottom: '5px',
    }

    const linkText = {
      color: '#0667B3',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    }

    const space = {
      paddingBottom: '20px',
    }
    
    moment.locale("de-DE")

    const { content: { reservationId, threadId, logo } } = this.props;
    const { content: { guestName, guestLastName, guestLocation, guestProfilePic, guestJoinedDate, isManualTransfer } } = this.props;
    const { content: { checkIn, checkOut, guests, allowedCheckInTime, allowedCheckOutTime, confirmationCode } } = this.props;
    let checkInDate = checkIn != null ? moment(checkIn).format('ddd, Do MMM, YYYY') : '';
    let checkOutDate = checkOut != null ? moment(checkOut).format('ddd, Do MMM, YYYY') : '';
    let checkInDateShort = checkIn != null ? moment(checkIn).format('Do MMMM') : '';
    let guestJoinedYear = guestJoinedDate != null ? moment(guestJoinedDate).format('YYYY') : '';
    let itineraryURL = url + '/users/bookings/itinerary/' + reservationId;
    let messageURL = url + '/message/' + threadId + '/host';
    let imageURL;
    if (guestProfilePic) {
      imageURL = url + '/images/avatar/medium_' + guestProfilePic;
    }

    return (
      <Layout>
        <Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
        <div>
          <Table width="100%" >
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <h1 style={bookingTitle}>
                    Eine neue Buchungsbestätigung liegt vor! <br />
                    <span>{guestName} hat Ihre Fläche ab dem {checkInDateShort} gebucht.</span> <br />
                  </h1>

                  {isManualTransfer && (
                    <div>
                      <EmptySpace height={20} />
                      <div>
                      Die ausstehende Zahlung Ihres Mieters ist bei uns eingegangen und wir haben Ihre Buchung erfolgreich abgeschlossen.
                      </div>
                    </div>
                  )}  
                  <EmptySpace height={20} />
                  <div>
                    Senden Sie eine Nachricht an {guestName}, um die Buchung zu bestätigen und Details zur Übergabe und Nutzung zu besprechen.
                    </div>
                  <EmptySpace height={20} />
                  <div>
                    {
                      guestProfilePic && <img style={profilePic} src={imageURL} height={125} />
                    }
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    <span style={userName}>{guestName} {guestLastName}</span><br />
                    <EmptySpace height={5} />
                    <span>{guestLocation}</span><br />
                    <EmptySpace height={5} />
                    <span>{sitename} Nutzer seit {guestJoinedYear}</span>
                  </div>
                  <EmptySpace height={30} />
                  <div>
                    <a href={messageURL} style={buttonStyle}>Mieter kontaktieren</a>
                  </div>
                  <EmptySpace height={40} />
                </TD>
              </TR>
            </TBody>
          </Table>
          <Table width="100%">
            <TBody>
              <TR style={textStyle}>
                <TD style={space}>
                  <span style={subTitle}>Mietbeginn</span><br />
                  <EmptySpace height={10} />
                  <span>{checkInDate}</span><br />
                  <EmptySpace height={1} />
                  <span>Übergabezeit: {
                    checkInFormat(allowedCheckInTime,allowedCheckOutTime)
                  }</span>
                </TD>
                <TD style={space}><EmptySpace height={10} /></TD>
                <TD style={space}>
                  <span style={subTitle}>Mietende</span><br />
                  <EmptySpace height={10} />
                  <span>{checkOutDate}</span><br />
                  
                </TD>
              </TR>
              <TR style={textStyle}>
                <TD>
                  <div>
                    <span style={subTitle}>Buchungsnummer</span><br />
                    <EmptySpace height={10} />
                    <span>{confirmationCode}</span>
                    <EmptySpace height={10} />
                  </div>
                </TD>
              </TR>

              <TR style={textStyle}>
                <TD><EmptySpace height={10} /></TD>
                <TD><EmptySpace height={10} /></TD>
                <TD>
                  <div>
                    <a href={itineraryURL} style={linkText}>Buchungsdetails</a>
                  </div>
                </TD>
              </TR>

            </TBody>
          </Table>
          <EmptySpace height={50} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }

}

export default BookingConfirmationHost;

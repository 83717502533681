exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._1SppS{max-width:320px}._14-NY{margin:60px 30px 0;border:1px solid #dce0e0;border-radius:4px;width:100%;padding:26px;background:#fff}._2yOdC{font-size:16px;line-height:150%;color:#707070}._3ZtjL{color:#2d2d2d;display:block;width:100%}@media (max-width:1023px) and (min-width:768px){._14-NY{margin:60px 13px 0}}@media screen and (max-width:768px){._14-NY{margin:60px 0 0}}", ""]);

// exports
exports.locals = {
	"helpPanelContainer": "_1SppS",
	"helpPanel": "_14-NY",
	"helpPanelText": "_2yOdC",
	"helpPanelTextTitle": "_3ZtjL"
};
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./FaqBox.css";
import { Grid, Row, Col, Button } from "react-bootstrap";
import Link from "../../Link/Link";
import ArrowDown from "./arrow_down.svg";
import Collapsible from "react-collapsible";
class FaqBox extends React.Component {
  static propTypes = {
    items: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { items } = this.props;

    return (
      <div className={cx(s.content)}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
        <h2 className={s.headLine}>FAQ</h2>

            {items.map((item, index) => {
              return (
                <Collapsible key={index} trigger={item.triggerText} triggerClassName={s.trigger} triggerOpenedClassName={s.triggerOpen}>
                  <div className={cx(s.faqText)}>{item.text}</div>
                </Collapsible>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(FaqBox);

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CompleteProfile.css';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import cx from 'classnames';

// Components
import EditProfileForm from '../EditProfileForm/EditProfileForm';
import Expozed1Logo from "../Logo/expozed1_logo.svg";
class CompleteProfile extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { title,isBooking, closeModal } = this.props;
    return (
      <div>
        <div className={s.container}>
        
   

          <Row className={cx(s.landingContainer)}>
            <Col xs={12} sm={12} md={12} lg={12} className={cx(s.smBottom, s.smPadding)}>
              {/* //TODO Translate */}
              <h4>Um den Service von Expozed1 nutzen zu können vervollständigen Sie bitte Ihr Profil.</h4>
              
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className={s.smPadding}>
              <EditProfileForm ubo={false} completeProfile={true} isBooking={isBooking} closeModal={closeModal}/>
            </Col>
          </Row>
      </div>
      </div>
 
    );
  }

}

export default withStyles(s)(CompleteProfile);

import React from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./KycVerification.css";


import UboUpload from "../../components/DocumentUpload/UboUpload";

import moment from "moment";
import { graphql, gql, compose } from "react-apollo";
import { connect } from "react-redux";



import { Grid, Row, Col } from "react-bootstrap";
import c from "!isomorphic-style-loader!css-loader!react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

import cx from "classnames";

import EditProfileSideMenu from "../../components/EditProfileSideMenu/EditProfileSideMenu";
// Translation
import { injectIntl, FormattedMessage } from "react-intl";
// Locale
import messages from "../../locale/messages";

class KycVerification extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  render() {
    const { formatMessage, locale } = this.props.intl;

    moment.locale(locale);

    return (
      <div className={s.container}>
        <Grid fluid>
          <Row className={cx(s.landingContainer, "kycverification")}>
            <Col xs={12} sm={3} md={3} lg={3} className={s.smPadding}>
              <EditProfileSideMenu />
            </Col>
           <UboUpload />
          </Row>
        </Grid>
      </div>
    );
  }
}
const mapState = (state) => ({});

const mapDispatch = {};

export default compose(injectIntl, withStyles(s, c), connect(mapState, mapDispatch))(KycVerification);

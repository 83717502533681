import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./HomeBanner.css";
import { Button, Row, Col } from "react-bootstrap";
import cx from "classnames";
import { connect } from "react-redux";
import { FaSearch } from "react-icons/fa";
import Link from "../../Link/Link";

// Components
import DetailSearchForm from "../DetailSearchForm/DetailSearchForm";
import SliderAnimation from "../SliderAnimation/SliderAnimation";

class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.scrollTop = this.scrollTop.bind(this);
  }

  scrollTop() {
    window.scrollTo({
      top: screen.height,
      behavior: "smooth",
    });
  }

  render() {
    const { link, headlineText, searchboxHeadline1, searchboxHeadline2, searchboxHeadline3, searchboxButtonText, isSearchAction, showIcon } = this.props;

    return (
      <div>
        <div className={cx(s.homeBannerSlider)}>
          <div className={cx(s.homePosition, "homePosition")}>
            <div className={cx(s.container2)}>
              <div className={s.FormBookWrap}>
                <Row className={s.fullWidth}>
                  <Col xs={12} sm={12} md={7} lg={7}>
                    <div className={s.textJumbo}>
                      <h1 className={s.jumboHeadline}>{headlineText}</h1>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={5}>
                    <div className={s.BookWrap}>
                      <div className={s.searchBox}>
                       
                          <div>
                            <h3>{searchboxHeadline1}</h3>
                            <Link
              to={link}
            >
                            <Button className={cx(s.btn, s.btnPrimary, s.button)} onClick={this.handleClick}>
                              <span className={cx("hidden-lg hidden-xs")}>
                                <FaSearch />
                              </span>
                              <span className={cx("hidden-md hidden-sm")}>{showIcon &&(<FaSearch />)}{searchboxButtonText}</span>
                            </Button></Link>

                            <span className={cx(s.subText)}>{searchboxHeadline3}</span>
                          </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    );
  }
}

const mapState = (state) => ({
  //homeBannerImages: state.homeBannerImages
});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(HomeBanner));

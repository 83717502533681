import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, FormGroup, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { FaBolt, FaShieldAlt, FaPhone } from "react-icons/fa";
// Translation
import { FormattedMessage } from "react-intl";
// Redux
import { connect } from "react-redux";
import { taxRate } from "../../../config";
// Locale
import messages from "../../../locale/messages";
// Component
import CurrencyConverter from "../../CurrencyConverter";
import Loader from "../../Loader";
import StarRating from "../../StarRating";
import ManualRequest from "../ManualRequest/ManualRequest";
import ViewCount from "../ViewCount";
import BookingForm from "./BookingForm";
import s from "./Calendar.css";
import { Event } from "../../Tracking/GA";

class Calendar extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    personCapacity: PropTypes.number.isRequired,
    listingData: PropTypes.shape({
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number,
      currency: PropTypes.string,
      monthlyDiscount: PropTypes.number,
      weeklyDiscount: PropTypes.number,
      minNight: PropTypes.number,
      maxNight: PropTypes.number,
      maxDaysNotice: PropTypes.string,
      maxDaysNoticeStart: PropTypes.string,
      maxDaysNoticeEnd: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    loading: PropTypes.bool,
    blockedDates: PropTypes.array,
    isHost: PropTypes.bool.isRequired,
    bookingType: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
    userBanStatus: PropTypes.number,
    reviewsCount: PropTypes.number.isRequired,
    reviewsStarRating: PropTypes.number.isRequired,
  };
  static defaultProps = {
    isLoading: false,
    loading: false,
    blockedDates: [],
    isHost: false,
    listingData: {
      basePrice: 0,
      cleaningPrice: 0,
      monthlyDiscount: 0,
      weeklyDiscount: 0,
      minNight: 0,
      maxNight: 0,
    },
  };
  constructor(props) {
    super(props);

    this.state = {
      showManualRequestModal: false,
    };
  }

  toggleManualRequest = () => {
    this.setState({ showManualRequestModal: !this.state.showManualRequestModal });
  };
  render() {
    const { id, personCapacity, isLoading, isHost, userBanStatus, bookingType, coverPhoto, listingTitle, listingSize, hostProfile } = this.props;
    const {
      listingData: {
        basePrice,
        cleaningPrice,
        currency,
        monthlyDiscount,
        weeklyDiscount,
        minNight,
        maxNight,
        maxDaysNotice,
        maxDaysNoticeStart,
        maxDaysNoticeEnd,
        bookingNoticeTime,
        qmPrice,
        nkPrice,
        services,
        service_description,
        service_price_hour,
        service_price_qm,
        service_price_kg,
        service_price_palette,
        monthlyPay,
      },
    } = this.props;
    const { loading, blockedDates, startDate, endDate, guests, insurable, houseType } = this.props;
    const { reviewsCount, reviewsStarRating } = this.props;
    const { showManualRequestModal } = this.state;
    let loadingStatus = loading || isLoading || false;
    const isMonthly = monthlyPay;
    let initialValues = {
      startDate,
      endDate,
      guests,
    };

    const servicesMapping = {
      full: "Komplett-Service",
      optional: "Dienstleistung optional",
      self: "selbständiges Be- & Abladen",
      inclusive: "Inkl. Be- & Abladen",
    };

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";
    const popoverServiceDescription = (
      <Popover id="popover-trigger-hover-focus" title="Dienstleistungshinweise">
        <span className={s.services}>{servicesMapping[services]}</span>
        <Row>
          {(services === "optional" || services === "full") && (
            <div className={s.serviceDetails}>
              Zzgl. Dienstleistung vor Ort
              {service_price_hour > 0 && (
                <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                  <div className={s.serviceDetailsWrapper}>
                    <span className={s.ServiceCurrency}>
                      <CurrencyConverter amount={service_price_hour} from={currency} />
                    </span>
                    <span className={s.ServiceUnit}> pro Stunde</span>
                  </div>
                </Col>
              )}
              {service_price_kg > 0 && (
                <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                  <div className={s.serviceDetailsWrapper}>
                    <span className={s.ServiceCurrency}>
                      <CurrencyConverter amount={service_price_kg} from={currency} />
                    </span>
                    <span className={s.ServiceUnit}> pro kg</span>
                  </div>
                </Col>
              )}
              {service_price_qm > 0 && (
                <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                  <div className={s.serviceDetailsWrapper}>
                    <span className={s.ServiceCurrency}>
                      <CurrencyConverter amount={service_price_qm} from={currency} />
                    </span>
                    <span className={s.ServiceUnit}> pro m²</span>
                  </div>
                </Col>
              )}
              {service_price_palette > 0 && (
                <Col className={cx(s.noPadding)} xs={12} sm={12} md={6} lg={6}>
                  <div className={s.serviceDetailsWrapper}>
                    <span className={s.ServiceCurrency}>
                      <CurrencyConverter amount={service_price_palette} from={currency} />
                    </span>
                    <span className={s.ServiceUnit}> pro Palette</span>
                  </div>
                </Col>
              )}
            </div>
          )}
        </Row>
        {service_description && (
          <div>
            <span className={s.serviceDescriptionTitle}>Wichtige Informationen</span>
            <span className={s.serviceDescription}>{service_description}</span>
          </div>
        )}
      </Popover>
    );

    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Number(reviewsStarRating / reviewsCount);
    }
    return (
      <div className={cx(s.bookItContainer, "bookItContentCommon", "modalMarginTop")}>
        <div className={cx(s.bookItContentBox)} data-sticky-section>
          <div className={cx(s.bootItPriceSection, "borderRadiusNone")}>
            <div className={cx(s.noPadding, s.mobileBg, s.calendarTableCell)}>
              <div className={cx(s.bookItPriceAmount, s.currenyMarginR)}>
                <Row>
                  <Col xs={12} sm={12} md={7} lg={7}>
                    <Row>
                      <CurrencyConverter amount={parseFloat((basePrice / personCapacity) * 30)} className={s.bookItPrice} from={currency} />
                      €/m² *
                    </Row>
                    <Row>
                      <CurrencyConverter amount={basePrice.toFixed(2) * 30} className={s.bookItPriceMonthly} from={currency} />
                      <span className={s.monthly}>pro Monat *</span>
                    </Row>
                    <Row>
                      {" "}
                      <span className={s.asterix}>* zzgl. MwSt.</span>
                    </Row>
                    {services && (
                      <Row>
                        <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverServiceDescription}>
                          <span className={s.overlayTrigger}>Dienstleistungshinweise</span>
                        </OverlayTrigger>
                      </Row>
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={5}>
                    <div className={s.icons}>
                      <Row>
                        <Col>
                          {insurable && (
                            <span title={"versicherbar"} className={s.InsurableIcon}>
                              <FaShieldAlt style={{ marginRight: "1px" }} />
                            </span>
                          )}
                          {bookingType === "instant" && (
                            <span title={"sofortbuchen"} className={s.instantIcon}>
                              <FaBolt />
                            </span>
                          )}
                          <div className={s.reviewSection}>
                            <StarRating name={"review"} value={starRatingValue} />
                          </div>
                          <div class={s.reviewCount}>
                            {reviewsCount > 0 && (
                              <span>
                                {reviewsCount}{" "}
                                {reviewsCount > 1 || reviewsCount === 0 ? (
                                  <FormattedMessage {...messages.reviews} />
                                ) : (
                                  <FormattedMessage {...messages.review} />
                                )}
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className={cx("bookItFormSection")}>
            <Loader show={loadingStatus} type={"page"}>
              <div className={cx(s.bookItPanel, "borderRadiusNone")}>
                <BookingForm
                  initialValues={initialValues}
                  id={id}
                  personCapacity={personCapacity}
                  bookingNoticeTime={bookingNoticeTime}
                  basePrice={basePrice}
                  nkPrice={nkPrice}
                  qmPrice={qmPrice}
                  cleaningPrice={cleaningPrice}
                  currency={currency}
                  monthlyDiscount={monthlyDiscount}
                  weeklyDiscount={weeklyDiscount}
                  minNight={minNight}
                  maxNight={maxNight}
                  blockedDates={blockedDates}
                  isHost={isHost}
                  userBanStatus={userBanStatus}
                  bookingType={bookingType}
                  maxDaysNotice={maxDaysNotice}
                  maxDaysNoticeStart={maxDaysNoticeStart}
                  maxDaysNoticeEnd={maxDaysNoticeEnd}
                  startDate={startDate}
                  checkIn={startDate}
                  endDate={endDate}
                  checkOut={endDate}
                  taxRate={taxRate}
                  guests={guests}
                  isMonthly={isMonthly}
                  insurable={insurable}
                  services={services}
                  houseType={houseType}
                  hostProfile={hostProfile}
                  listingTitle={listingTitle}
                />
                <div>
                  <FormGroup className={cx(s.formGroup, s.textMuted, "text-center")}>
                    <small>
                      <FormattedMessage {...messages.bookingInfo} />
                    </small>
                  </FormGroup>
                </div>
                <div>
                  {/* <Button className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnPrimaryBorder)} onClick={this.toggleManualRequest}>
                    Teilfläche anfragen
                  </Button> */}
                  <a href="tel:+49 731 79032090">
                    <Button
                      onClick={() => {
                        Event("lead", "jetzt_kontaktieren", "phone-link");
                      }}
                      className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnManualRequest, s.fullWidth, s.manualRequestBtn)}
                    >
                      Jetzt kontaktieren <FaPhone />
                    </Button>
                  </a>
                </div>
                <ViewCount listId={id} isHost={isHost} />
              </div>
            </Loader>
          </div>
        </div>
        {showManualRequestModal && (
          <ManualRequest
            hostProfile={hostProfile}
            showManualRequestModal={showManualRequestModal}
            id={id}
            picture={coverPhoto}
            personCapacity={personCapacity}
            minNight={minNight}
            maxNight={maxNight}
            blockedDates={blockedDates}
            maxDaysNotice={maxDaysNotice}
            title={listingTitle}
            listingSize={listingSize}
            toggleManualRequest={this.toggleManualRequest}
          />
        )}
      </div>
    );
  }
}
const mapState = (state) => ({
  isLoading: state.viewListing.isLoading,
});
const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(Calendar));

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './completeProfile.css';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import cx from 'classnames';

// Components
import CompleteProfile from '../../components/CompleteProfile/CompleteProfile';

class CompleteProfileRoute extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { title } = this.props;
    return (

      <div className={s.container}>
        <Grid>
          <Row className={cx(s.landingContainer)}>
            
            <Col xs={12} sm={12} md={12} lg={12} className={s.smPadding}>
              <CompleteProfile />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

}

export default withStyles(s)(CompleteProfileRoute);

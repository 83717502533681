import { gql } from "react-apollo";
import { BOOKING_RESTORE_START, BOOKING_RESTORE_SUCCESS, BOOKING_RESTORE_ERROR } from "../../constants";
import history from '../../core/history';

export function restoreBooking(bookingData) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: BOOKING_RESTORE_START,
    });
    if (bookingData) {
      await dispatch({
        type: BOOKING_RESTORE_SUCCESS,
        payload: {
          bookingData: bookingData,
        },
      });
      history.push(bookingData.location);

    } else {
      dispatch({
        type: BOOKING_RESTORE_ERROR,
        payload: {},
      });
    }

    return true;
  };
}

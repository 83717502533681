import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import Loader from "../../components/Loader";
// Redux
import { connect } from "react-redux";
import getReservationQuery from "./getReservationQuery.graphql";
import { bookingProcess } from "../../actions/booking/bookingProcess";
import history from "../../core/history";

class Book extends Component {
  static defaultProps = {
    reservationData: {
      loading: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      restored: false,
    };
  }
  componentDidMount() {}

  render() {
    const { store, reservationId } = this.props;
    const { userId } = this.props;
    const { restored } = this.state;
    const {
      reservationData: { loading, getReservation },
    } = this.props;
    if (getReservation && !loading) {
      let listId = getReservation.listId;
      let guests = 99;
      let startDate = getReservation.checkIn;
      let endDate = getReservation.checkOut;
      let taxRate = getReservation.taxRate;
      let reservationState = getReservation.reservationState;
      let paymentState = getReservation.paymentState;
      let bookingType = "payment";
      let reservationGuestId = getReservation.guestId;
      if (reservationGuestId !== userId || paymentState !== "pending" || reservationState !== "notpayed") {
        //loggedin user is not owner of reservation
        //reservation not payed
        history.push("/");
        return;
      }
      if (store) {
        if (!restored) {
          console.log("restore");
          store.dispatch(bookingProcess(listId, guests, startDate, endDate, true, taxRate, bookingType, reservationId, null));
          this.setState({ restored: true });
        }
      }
    }

   
    if (loading) {
      return (
        <div>
          <Loader type={"text"} show={loading} />
        </div>
      );
    }

    return <div> </div>;
  }
}
const mapState = (state) => ({
  userId: state.user.id,
});

const mapDispatch = {};
export default compose(
  connect(mapState, mapDispatch),
  graphql(getReservationQuery, {
    name: "reservationData",
    options: (props) => ({
      variables: {
        reservationId: props.reservationId,
      },
      fetchPolicy: "network-only",
    }),
  })
)(Book);

// export default compose(
// 	injectIntl,
// 	withStyles(s),
// 	connect(mapState, mapDispatch),
// 	graphql(gql`
// 		query {
// 			userAccount {
// 				picture
// 			}
// 		}
// 	  `, {
// 			name: 'profilePictureData',
// 			options: {
// 				ssr: false
// 			}
// 		}),
// )(Payin);

import React from 'react';
import WishLists from './WishLists';
import Layout from '../../components/Layout';

const title = 'gespeicherte Flächen';

export default {

  path: '/wishlists/:id?',

  action({ store, params }) {

    // From Redux Store
    const isAuthenticated = store.getState().runtime.isAuthenticated;
    let profileId, wishListId;

 

    if (!isAuthenticated) {
      return { redirect: '/login' };
    }

    if (isAuthenticated) {
      profileId = store.getState().account ? store.getState().account.data.profileId : null;
    }

    if (params && params.id) {
      wishListId = params.id;
    }

    return {
      title,
      component: <Layout><WishLists
        profileId={profileId}
        wishListId={wishListId}
      />
      </Layout>,
    };
  },

};

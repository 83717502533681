// Toaster
import { toastr } from "react-redux-toastr";

import { ADMIN_PAYOUT_HOST_START, ADMIN_PAYOUT_HOST_SUCCESS, ADMIN_PAYOUT_HOST_ERROR } from "../../constants";

// Helper
import { convert } from "../../helpers/currencyConvertion";
import { processMangopayPayment } from "../../core/payment/mangopay/processMangopayPayment";

export function payoutHost(reservationId, destination, payoutId, amount, currency, paymentCurrency, userId, paymentMethodId, hostEmail, isMonthly, transactionId,listId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: ADMIN_PAYOUT_HOST_START,
      payload: {
        loading: true,
        reservationId,
      },
    });

    try {
      let rates = getState().currency.rates;
      let baseCurrency = getState().currency.base;
      let convertedAmount = convert(baseCurrency, rates, amount, currency, paymentCurrency);
      let cardDetails = {};
      let reservationDetails = {
        reservationId,
        amount: convertedAmount.toFixed(2),
        currency: paymentCurrency,
        hostEmail,
        payoutId,
        userId,
        destination,
        transfer_group: "Payout to Host",
        isMonthly: isMonthly,
        transactionId,
        listId
      };

      const data = { reservationDetails, selectedPayoutMethodId: payoutId, cardDetails };
      const { status, errorMessage } = await processMangopayPayment("create-payout", data);
  

      if (status && status === 200) {
        dispatch({
          type: ADMIN_PAYOUT_HOST_SUCCESS,
          payload: {
            loading: false,
            completed: true,
          },
        });
        toastr.success("Payment to Host", "Payment transferred to host successfully!");
      } else {
        if (errorMessage) {
          toastr.error("Auszahlung an Vermieter", errorMessage);
        } else {
          toastr.error("Auszahlung an Vermieter", "Auszahlung fehlgeschlagen.");
        }
        dispatch({
          type: ADMIN_PAYOUT_HOST_ERROR,
          payload: {
            loading: false,
          },
        });
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: ADMIN_PAYOUT_HOST_ERROR,
        payload: {
          error,
          loading: false,
        },
      });
      return false;
    }

    return true;
  };
}

// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Internal Helpers
import submit from './submit';
import update from './update';
import updateStep2 from './updateStep2';
import updateStep3 from './updateStep3';

// Translation
import { injectIntl } from 'react-intl';

// Step #1
import Page1 from './Page1';
import ExistingPage1 from './ExistingPage1';
import Page2 from './Page2';
import Page2_1 from './Page2_1';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import Page7 from './Page7';
import Page8 from './Page8';

// Step #2
import Photos from './Photos';
import PhotoCover from './PhotoCover';
import Title from './Title';
import Description from './Description';

// Step #3
import GuestRequirements from './GuestRequirements';
import HouseRules from './HouseRules';
import ReviewGuestBook from './ReviewGuestBook';
import AdvanceNotice from './AdvanceNotice';
import MaxDaysNotice from './MaxDaysNotice';
import MinMaxNights from './MinMaxNights';
import Calendar from './Calendar';
import EditCalendar from './EditCalendar';
import Pricing from './Pricing';
import Discount from './Discount';
import Booking from './Booking';
import LocalLaws from './LocalLaws';
import Services from './Services';
import MonthlyPayment from './MonthlyPayment';

// Tab Bar
import TabBarStep from './TabBarStep';

import history from '../../core/history';

class ListPlaceStep1 extends Component {

  static propTypes = {
    listData: PropTypes.object,
    existingList: PropTypes.bool,
    listingSteps: PropTypes.object,
    listId: PropTypes.number,
    formBaseURI: PropTypes.string,
    mode: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      progress: 0,
      page: 'index',
      step1: null,
      step2: null,
      step3: null,
      step4: null,
      formValues: {},
    };
  }

  componentWillMount() {
    const { existingList, listingSteps } = this.props;
    if (existingList && listingSteps != undefined) {
      this.setState({
        step1: listingSteps.step1,
        step2: listingSteps.step2,
        step3: listingSteps.step3,
        step4: listingSteps.step4,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { existingList, listingSteps } = nextProps;
    if (existingList && listingSteps != undefined) {
      this.setState({
        step1: listingSteps.step1,
        step2: listingSteps.step2,
        step3: listingSteps.step3,
        step4: listingSteps.step4,
      });
    } else {
      this.setState({
        step1: null,
        step2: null,
        step3: null,
        step4: null,
      });

    }
  }

  nextPage(formPage) {
    const { listId, existingList, formBaseURI } = this.props;
    let pathName = formBaseURI + formPage;
    if (existingList === true) {
      pathName = formBaseURI + listId + "/" + formPage;
    }
    history.push(pathName);
    this.setState({ page: formPage })
  }

  previousPage(formPage) {
    const { listId, existingList, formBaseURI } = this.props;
    let pathName = formBaseURI + formPage;
    if (existingList === true) {
      pathName = formBaseURI + listId + "/" + formPage;
    }
    history.push(pathName);
    this.setState({ page: formPage })
  }

  renderTabBar(currentPage) {
    const { step1, step2, step3, step4, progress} = this.state;
    const { photosCount, formPage} = this.props;

    const step1Pages = [
      "Allgemeine Informationen", "Sicherheit", "Technische Angaben", "Ausstattung", "Lage", "Standort", "Zusatzausstattungen", "Weiteres"
    ];
    const step2Pages = [
      "Fotos", "Titelbild", "Beschreibung", "Titel"
    ];
    const step3Pages = [
      "Bedingungen", 
      "Bedingungen hinzufügen",
       "Vorlaufszeit", "Preis", "Buchungsdauer", 
       "Sofortbuchung", "Dienstleistungen", "Monatliche Zahlung",
       "Verfügbarkeit", "Kalender",
         "Nutzungsbedingungen"
    ];
    let actualPages = [];
    let stepCompleted = false;
    if (step1Pages.indexOf(currentPage) > -1) {
      stepCompleted = step1 === "completed";
      actualPages = step1Pages
    } 

    if (step2Pages.indexOf(currentPage) > -1 ) {
      stepCompleted = step2 === "completed";
      actualPages = step2Pages
       }

    if (step3Pages.indexOf(currentPage) > -1 ) {
      stepCompleted = step4 === "completed";
      actualPages = step3Pages
        }
      return <TabBarStep pages={actualPages} completed={stepCompleted}  currentPage={currentPage} nextPage={this.nextPage} /> 

  }

  render() {
    const { page, formValues, step1 } = this.state;
    const { formPage, listingSteps, photosCount, mode, existingList, listId, baseCurrency } = this.props;
    let currentPage = page;
   if (formPage != undefined) {
      currentPage = formPage;
    }

    return (
      <div className={'inputFocusColor'}>
        {this.renderTabBar(currentPage)}
        {currentPage === "index" && <Page1 nextPage={this.nextPage} />}
        {currentPage === "home" && <ExistingPage1
          nextPage={this.nextPage}
          photosCount={photosCount}
        />}
        {currentPage === "Allgemeine Informationen" && <Page2
          nextPage={this.nextPage}
          previousPage={this.previousPage}
        />}
        {currentPage === "Sicherheit" && <Page2_1
          nextPage={this.nextPage}
          previousPage={this.previousPage}
        />}
        {currentPage === "Technische Angaben" && <Page3
          nextPage={this.nextPage}
          previousPage={this.previousPage}
        />}
        {currentPage === "Ausstattung" && <Page4
          nextPage={this.nextPage}
          previousPage={this.previousPage}
        />}
        {currentPage === "Lage" && <Page5
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          onSubmit={existingList ? update : submit}
        />}
        {currentPage === "Standort" && <Page6
          nextPage={this.nextPage}
          previousPage={this.previousPage}
        />}
        {currentPage === "Zusatzausstattungen" && <Page7
          nextPage={this.nextPage}
          previousPage={this.previousPage}
        />}
        {currentPage === "Weiteres" && <Page8
          previousPage={this.previousPage}
          onSubmit={update}
        />}

        {currentPage === "Fotos" && <Photos
          previousPage={this.previousPage}
          listId={listId}
          nextPage={this.nextPage}
        />}
        {currentPage === "Titelbild" && <PhotoCover
          previousPage={this.previousPage}
          listId={listId}
          nextPage={this.nextPage}
        />}
        {currentPage === "Beschreibung" && <Description
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Titel" && <Title
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          onSubmit={updateStep2}
        />}

        {currentPage === "Bedingungen" && <GuestRequirements
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Bedingungen hinzufügen" && <HouseRules
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "review-how-guests-book" && <ReviewGuestBook
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Vorlaufszeit" && <AdvanceNotice
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Verfügbarkeit" && <MaxDaysNotice
          listId={listId}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Buchungsdauer" && <MinMaxNights
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Preis" && <Pricing
          previousPage={this.previousPage}
          nextPage={this.nextPage} />}
        {currentPage === "Kalender" && <Calendar
          listId={listId}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          baseCurrency={baseCurrency}
        />}
        {currentPage === "discount" && <Discount
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Sofortbuchung" && <Booking
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Dienstleistungen" && <Services
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Monatliche Zahlung" && <MonthlyPayment
          previousPage={this.previousPage}
          nextPage={this.nextPage}
        />}
        {currentPage === "Nutzungsbedingungen" && <LocalLaws
          previousPage={this.previousPage}
          onSubmit={updateStep3}
        />}
        {currentPage === "editCalendar" && <EditCalendar
          onSubmit={updateStep3} listId={listId}
        />}
      </div>
    );
  }

}


const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingSteps: state.location.listingSteps,
  photosCount: state.location.photosCount
});

const mapDispatch = {};

export default injectIntl(connect(mapState, mapDispatch)(ListPlaceStep1));

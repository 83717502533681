import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../SliderAnimation/SliderAnimation.css";
import s2 from "./Layout3.css";
import { Row, Col } from "react-bootstrap";
import cx from "classnames";
import { connect } from "react-redux";
import arrow from "./arrow-down.png";
import PriceImage from "./price.svg";
import SupportImage from "./support.svg";
import NoRiskImage from "./no_risk.svg";
import TimeImage from "./time.svg";


// Components
import DetailSearchForm from "../DetailSearchForm/DetailSearchForm";
import SliderAnimation from "../SliderAnimation/SliderAnimation";

class Layout3 extends React.Component {
  constructor(props) {
    super(props);
    this.scrollTop = this.scrollTop.bind(this);
  }

  scrollTop() {
    window.scrollTo({
      top: screen.height,
      behavior: "smooth",
    });
  }

  render() {
    const {
      data,
      data: { loading, getBanner },
      layoutType,
      homeBannerImages,
    } = this.props;



    return (
      <div>
        <div className={cx(s2.homeBannerSlider, s2.desktopView)} >
          <div className={cx(s2.homePosition, "homePosition")}>
    
            <div className={cx(s2.container2)}>
              <div className={s2.FormBookWrap}>
                <Row className={s2.fullWidth}>
            
                  <Col xs={12} sm={12} md={5} lg={5}>
                    {" "}
                    {!loading && getBanner && (
                      <div className={s2.BookWrap}>
                        <DetailSearchForm />
                      </div>
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} mdOffset={1} lgOffset={1}>
                    <div className={s2.textJumbo}>
                      <h1 className={s2.jumboHeadline}>Flexibel Fläche mieten</h1>
                      <h3 className={s2.jumboSubHeadline}>kurzfristig mieten, statt langfristig binden</h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            
          </div>
        
        </div>
        <div className={cx(s2.homeBannerSlider, s2.mobileView)}>
          <div className={cx(s2.homePosition)}>
            {/* <div className={s2.homeCarsoual}>
              <SliderAnimation data={data} homeBannerImages={homeBannerImages} />
            </div> */}
            <div className={cx(s.container)}>
              <div className={s2.FormBookWrap}>
                <Row className={s2.fullWidth}>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <div className={s2.textJumboMobile}>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    {" "}
                    {!loading && getBanner && (
                      <div className={s2.BookWrap}>
                        <DetailSearchForm />
                      </div>
                    )}
                  </Col>
                  
                </Row>
              </div>
            </div>

            
          </div>
        </div>
        <div className={s2.containerWrapper}>
              <div className={s2.container}>
                <Row>
                  <Col xs={6} sm={6} md={3} lg={3}>
                    <div className={s2.imageItem}>
                      <img src={TimeImage} /> <span>Sofort verfügbar</span>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3} lg={3}>
                    <div className={s2.imageItem}>
                      <img src={NoRiskImage} /> <span>Kein Risiko</span>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3} lg={3}>
                    <div className={s2.imageItem}>
                      <img src={PriceImage} /> <span>Fixer Mietpreis</span>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={3} lg={3}>
                    <div className={s2.imageItem}>
                      <img src={SupportImage} /> <span>24/7 Support</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  //homeBannerImages: state.homeBannerImages
});

const mapDispatch = {};

export default withStyles(s, s2)(connect(mapState, mapDispatch)(Layout3));

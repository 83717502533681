exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3owrS{position:absolute;top:10px;left:0;z-index:1;margin-left:18px}._3owrS,._9iVui{box-shadow:0 0 8px 0 #dedede;border-radius:8px}._9iVui{padding:9px 12px;background-color:#fff}._2KrMy{font-weight:700;margin:0 0 0 10px;word-wrap:break-word;font-size:14px;line-height:16px;letter-spacing:.2px;padding-top:0;padding-bottom:0;color:#404040;vertical-align:middle}@media screen and (max-width:1199px){._2KrMy{font-size:12px}}", ""]);

// exports
exports.locals = {
	"redoContainer": "_3owrS",
	"redoContent": "_9iVui",
	"redoText": "_2KrMy"
};
// Redux Form
import { reset } from 'redux-form';

// Fetch Request
import fetch from '../../core/fetch';

// Toaster
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {

  if (values.newPassword != values.confirmPassword) {
    toastr.error("Password change failed", "Password mismatch.Please try again.");
    return;
  }

  const query = `
    mutation (
        $oldPassword: String,
        $newPassword: String,
        $confirmPassword: String,
        $registeredType: String,
    ) {
        ChangePassword (
            oldPassword: $oldPassword,
            newPassword: $newPassword,
            confirmPassword: $confirmPassword,
            registeredType: $registeredType
        ) {
            status
        }
    }
  `;

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: values
    }),
    credentials: 'include',
  });

  const { data } = await resp.json();

  if (data.ChangePassword.status === 'success') {
    toastr.success("Passwort geändert", "Ihr Passwort wurde erfolgreich geändert.");
    // Clear form data
    dispatch(reset('ChangePasswordForm'));
  } else if (data.ChangePassword.status === 'WrongPassword') {
    toastr.error("Change Password", "Passwort ist nicht korrekt. Bitte probieren Sie es erneut!");
  } else if (data.ChangePassword.status === 'notLoggedIn') {
    toastr.error("Change Password", "Sie sind nicht eingeloggt");
  } else if (data.ChangePassword.status === 'WrongConfirmPassword') {
    toastr.error("Change Password", "Ihr Passwort stimmt nicht mit der Bestätigung überein.");
  } else {
    toastr.error("Change Password", "Ups, es ist etwas schiefgelaufen. Bitte laden Sie die Seite erneut.");
  }

}

export default submit;

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}.mlIF9{padding-left:20px;padding-right:20px}._2IhlO{margin:0 auto;padding:0 0 40px;max-width:1080px;max-width:var(--max-content-width)}._14uVQ{font-size:95px}._14uVQ,.Oz9XG{font-weight:700;margin:0}.Oz9XG{font-size:85px}._3tmPE{text-align:center}._3KBUF{padding-left:0;list-style:none}._251EZ{font-size:15px;color:#404040}a,a:hover{color:#0667b2}.i2NFT{margin-top:12px}._1WEb2{margin-bottom:12px}._2Ywg2{margin-bottom:48px}._3bBdQ{margin-top:48px}", ""]);

// exports
exports.locals = {
	"root": "mlIF9",
	"container": "_2IhlO",
	"textJumbo": "_14uVQ",
	"textMedium": "Oz9XG",
	"textCenter": "_3tmPE",
	"listStyled": "_3KBUF",
	"subTitle": "_251EZ",
	"spaceTop2": "i2NFT",
	"space2": "_1WEb2",
	"space6": "_2Ywg2",
	"spaceTop6": "_3bBdQ"
};
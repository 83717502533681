/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  if (typeof window !== "undefined" && window.ga) {
    window.ga("send", "event", category, action, label);
  }
};

import React from "react";
import { analytics } from "../../config";
import Cookies from "universal-cookie";
import DisableGa from './DisableGa'

const title = "Google Analytics opt out";
export default {
  path: "/disable-ga",

  action({ store }) {
    let gaId = analytics.google.trackingId;
    var disableStr = "ga-disable-" + gaId;
    let expireDate = new Date(Date.now());
    expireDate.setFullYear(expireDate.getFullYear() + 50);
    const cookies = new Cookies();
    cookies.set(disableStr, true, { path: "/", expires: expireDate });
    return {
      title,
      component: <DisableGa disableString={disableStr} gaId={gaId} />,
    };
  },
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { uploadUboDocuments } from "../../actions/mangopay/uploadUboDocuments";
import { toastr } from "react-redux-toastr";
// Style
import { Button, Label, Row, Col, Image } from "react-bootstrap";
// Style
import s from "./DocumentUpload.css";
import moment from "moment";
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import IdentityProofImage from "./identity_proof.svg";
import RegistrationImage from "./registration.svg";
import ArticlesImage from "./articles.png";
// Locale
import messages from "../../locale/messages";
// Component
import { injectIntl, FormattedMessage } from "react-intl";
import CountryList from "../CountryList";
import { SingleDatePicker } from "react-dates";
import CustomCheckbox from "../CustomCheckbox";

import DocumentUpload from "../DocumentUpload/DocumentUpload";
import EditProfileForm from "../../components/EditProfileForm/EditProfileForm";
//compose
import { graphql, gql, compose } from "react-apollo";
import ShowDocumentListQuery from "../DocumentList/ShowListDocument.graphql";
const query = gql`
  query ShowDocumentList($type: String, $kycType: String) {
    ShowDocumentList(type: $type, kycType: $kycType) {
      id
      userId
      fileName
      fileType
      type
      kycType
      kycStatus
    }
  }
`;

class UboUpload extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    const account = this.props.account;
    this.state = {
      termsAccepted: false,
      companyIsKg: false,
      companyHasShareholders: false,
      numberUbos: 1,
      focusedInputLegal: false,
      validationErrorsLegal: [],
      legalUser: {
        FirstName: account.firstName,
        LastName: account.lastName,
        AddressLine1: account.address1,
        AddressLine2: account.address2,
        City: account.city,
        Region: account.region,
        PostalCode: account.zip,
        Country: account.country,
        Birthday: account.dateOfBirth,
        Nationality: account.nationality,
        BirthCity: "",
        BirthCountry: "DE",
        CompanyName: account.companyName,
        CompanyRegistrationNumber: account.companyRegistrationNumber,
        articlesKycStatus: null,
      },
      inputList: [
        {
          validationErrors: [],
          focusedInput: false,
          FirstName: "",
          LastName: "",
          CompanyName: "",
          CompanyRegistrationNumber: "",
          LastName: "",
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          Region: "",
          PostalCode: "",
          Country: "DE",
          Birthday: null,
          Nationality: "DE",
          BirthCity: "",
          BirthCountry: "DE",
        },
      ],
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onDateChangeLegal = this.onDateChangeLegal.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.sendUbo = this.sendUbo.bind(this);
  }

  // handle input change
  handleInputChange = (e, index) => {
    const { inputList } = this.state;
    const { name, value } = e.target;

    const list = [...inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
    this.validateUbo();
  };
  // handle input change
  handleInputChangeLegal = (e) => {
    const { legalUser } = this.state;
    const { name, value } = e.target;
    legalUser[name] = value;
    this.setState({ legalUser });
    this.validateUbo();
  };

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const { inputList } = this.state;

    const list = [...inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    const { inputList } = this.state;
    //Add new
    this.setState({
      inputList: [
        ...inputList,
        {
          validationErrors: [],
          focusedInput: false,

          FirstName: "",
          LastName: "",
          CompanyName: "",
          CompanyRegistrationNumber: "",
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          Region: "",
          PostalCode: "",
          Country: "DE",
          Birthday: null,
          Nationality: "DE",
          BirthCity: "",
          BirthCountry: "DE",
        },
      ],
    });
  };

  validateUbo = () => {
    const { inputList, legalUser } = this.state;
    const { companyIsKg, companyHasShareholders } = this.state;
    const {
      data: { ShowDocumentList },
      data,
    } = this.props;
    const kycDocStatus = ShowDocumentList && ShowDocumentList.length > 0 ? ShowDocumentList[0].kycStatus : null;
    const list = [...inputList];
    let validationErrorsLegal;

    let allValid = true;

    // if ((!companyHasShareholders || !companyIsKg) && kycDocStatus !== "incomplete") {
    //   validationErrorsLegal = [];
    // console.log("allValid",legalUser)

    //   //Dont need form data because we create UBO with profile data
    //   for (const [key, value] of Object.entries(legalUser)) {
    //     if (key !== "AddressLine2" && (!value || (typeof value === "string" && value.length === 0))) {
    //       validationErrorsLegal.push(key);
    //       allValid = false;
    //     }
    //   }
    //   this.setState({ validationErrorsLegal });
    //   console.log("allValid",validationErrorsLegal)
    //   return allValid;
    // }
    inputList.forEach((itemList, index) => {
      let validationErrors = [];
      for (const [key, value] of Object.entries(itemList)) {
        if (
          key !== "AddressLine2" &&
          key !== "focusedInput" &&
          ((key === "CompanyRegistrationNumber" &&
            !value
              .replace(/\s/g, "")
              .toUpperCase()
              .match(/^(HRB|HRA)\d+$/g)) ||
            !value ||
            (typeof value === "string" && value.length === 0))
        ) {
          validationErrors.push(key);
          allValid = false;
        }
      }
      list[index]["validationErrors"] = validationErrors;
    });
    this.setState({ inputList: list });
    return allValid;
  };

  refetchQuery = () => {
    const {
      data: { refetch },
      data,
    } = this.props;
    let variables = {
      type: "ubo",
      kycType: "UBO",
    };
    refetch(variables);
  };
  async sendUbo() {
    const { companyIsKg, companyHasShareholders, inputList, legalUser } = this.state;
    const { mutate } = this.props;
    
    try {
      const {
        data: { ShowDocumentList },
        data,
      } = this.props;
      const kycDocStatus = ShowDocumentList && ShowDocumentList.length > 0 ? ShowDocumentList[0].kycStatus : null;
      let valid = this.validateUbo();

      if (valid) {
        let variables = {};
        //create DB entry
        let type = "ubo";
        variables = {
          fileName: "ubo",
          fileType: "ubo",
          type,
          kycType: "UBO",
          kycStatus: "uploaded",
        };
        const { data } = await mutate({
          variables,
          refetchQueries: [{ query, variables: { type: type, kycType: "UBO" } }],
        });

        let UboData = inputList;

        UboData.forEach((item, i) => {
          item["Birthday"] = moment(item["Birthday"]).format("DD-MM-YYYY");

          //delete obsolete key values
          delete item.validationErrors;
          delete item.focusedInput;
        });
        const { status } = await this.props.uploadUboDocuments(UboData, kycDocStatus === "incomplete");
        if (status == 200) {
          toastr.success("Erfolgreich eingereicht!", "Ihre Daten wurden zur Überprüfung eingereicht.");
        } else {
          toastr.error("Fehler", "Ihre Daten konnten nicht übermittelt werden");
        }

        this.refetchQuery();
      }
    } catch (error) {
      console.error(error);
    }
  }

  onDateChange(date, index) {
    const { inputList } = this.state;
    const list = [...inputList];
    list[index]["Birthday"] = moment(date);
    this.setState({ inputList: list });

    this.validateUbo();
  }
  onDateChangeLegal(date) {
    const { legalUser } = this.state;
    legalUser["Birthday"] = date;
    this.setState({ legalUser });
    this.validateUbo();
  }

  onFocusChange = (focusedInput, index) => {
    const { inputList } = this.state;
    const list = [...inputList];
    list[index]["focusedInput"] = focusedInput.focused;
    this.setState({ inputList: list });
  };
  onFocusChangeLegal = (focusedInput) => {
    this.setState({ focusedInputLegal: focusedInput.focused });
  };

  handleBirthCountryChange = (e, selectedData) => {
    const { inputList } = this.state;
    const list = [...inputList];
    list[index]["BirthCountry"] = selectedData.countryCode;
    this.setState({ inputList: list });
    this.validateUbo();
  };
  handleBirthCountryChangeLegal = (e, selectedData) => {
    const { legalUser } = this.state;
    legalUser["BirthCountry"] = selectedData.countryCode;
    this.setState({ legalUser });
    this.validateUbo();
  };
  handleCountryChange = (e, selectedData) => {
    const { inputList } = this.state;
    const list = [...inputList];
    (list[index]["Country"] = selectedData.countryCode), this.setState({ inputList: list });
    this.validateUbo();
  };
  handleNationalityChange = (e, selectedData) => {
    const { inputList } = this.state;
    const list = [...inputList];
    (list[index]["Nationality"] = selectedData.countryCode), this.setState({ inputList: list });
    this.validateUbo();
  };

  handleChange = (event, index) => {
    const { inputList } = this.state;
    const list = [...inputList];
    list[index][event.target.name] = event.target.value;
    this.setState({ inputList: list });
    this.validateUbo();
  };

  returnYears = () => {
    let years = [];
    for (let i = moment().year() - 100; i <= moment().year(); i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    </div>
  );

  setTermsAccepted = (value) => {
    this.setState({ termsAccepted: value });
  };

  returnArticlesKycStatus = (value) => {
    if (value && value.length > 0) {
      this.setState({ articlesKycStatus: value[0] });
    }
  };

  render() {
    const { formatMessage, locale } = this.props.intl;
    const { account } = this.props;
    const { inputList, termsAccepted, articlesKycStatus } = this.state;
    moment.locale(locale);
    const {
      data: { ShowDocumentList },
      data,
    } = this.props;
    let kycDocStatus = null;
    if (ShowDocumentList && ShowDocumentList.length > 0) {
      kycDocStatus = ShowDocumentList[0].kycStatus;
    }

    if (inputList && inputList[0] && !inputList[0].validationErrors) {
      inputList[0]["validationErrors"] = [];
    }

    if (kycDocStatus === "uploaded") {
      //Temporary hack fix value
      kycDocStatus = "submitted";
    }

    return (
      <div className={s.container}>
        <Col xs={12} sm={9} md={9} lg={9} className={s.smPadding}>
          <div className={s.header}>
            <h1 className={s.infoTitle}>Einmalige Auszahlungsverifizierung </h1>
            <h4>Für Vermietungen im Europäischen Zahlungsraum</h4>
            <p className={s.infoText}>
              Um Auszahlungen im europäischen Zahlungsraum zu erhalten muss eine einmalige Verifizierung des Vertreters und der Gesellschafter des Unternehmens
              erfolgen. Diese dient der Prävention von Betrug, gewährleistet Ihre Zahlungssicherheit und muss zur Einhaltung der europäischen
              Zahlungsvorschriften durchgeführt werden.
              <br />
              <br />
              Bitte geben Sie hierzu die geforderten Daten an und laden die entsprechenden Dokumente hoch. Die Dokumente können Sie jederzeit unter{" "}
              <a target="_blank" rel="noopener" href="https://www.online-handelsregister.de">
                https://www.online-handelsregister.de
              </a>{" "}
              herunterladen.
            </p>
          </div>
          <div className={s.body}>
            <div>
              <h2 className={s.infoTitle}>Schritt 1: Auszahlungskonto anlegen</h2>
              <p className={s.infoText}>
                Bitte überprüfen Sie die von Ihnen angegebenen Daten und ergänzen Sie die Kontaktdaten der vertretenden Person (i.d.R. der Geschäftsführer) des
                Unternehmens.
                <br />
                Wichtig! Die angegebene Person muss im beigefügten Handelsregister eingetragen sein.
              </p>
              {/* @TODO Translate */}
              {!account.profileCompleteUbo && <EditProfileForm ubo={true} />}

              <ul className={s.list}>
                <li className={cx(s.space4, "clearfix", s.background, s.listItem)}>
                  <Row>
                    <Col xs={12} sm={2} md={2} lg={2} className={s.inlineFlex}>
                      <Image src={IdentityProofImage} className={s.iconImages} />
                    </Col>
                    <Col xs={12} sm={5} md={5} lg={5} className={s.responsiveCenter}>
                      <h4>Personalausweis/Reisepass</h4>
                      <p className={s.description}>Bitte laden Sie eine Kopie ihres aktuellen Personalausweis/Reisepass hoch.</p>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} className={s.responsiveFlex}>
                      <DocumentUpload isKyc={true} kycType={"IDENTITY_PROOF"} key={1} placeholder={formatMessage(messages.kycUploadPlaceholder)} />
                    </Col>
                  </Row>
                </li>
                <li className={cx(s.space4, "clearfix", s.background, s.listItem)}>
                  <Row>
                    <Col xs={12} sm={2} md={2} lg={2} className={s.inlineFlex}>
                      <Image src={RegistrationImage} className={s.iconImages} />
                    </Col>
                    <Col xs={12} sm={5} md={5} lg={5} className={s.responsiveCenter}>
                      <h4>Handelsregister</h4>
                      <p className={s.description}>
                        Bitte laden Sie eine Kopie Ihres Handelsregisterauszuges hoch. Bitte beachten Sie, dass der Auszug maximal 3 Monate alt sein darf.
                      </p>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} className={s.responsiveFlex}>
                      <DocumentUpload isKyc={true} kycType={"REGISTRATION_PROOF"} key={2} placeholder={formatMessage(messages.kycUploadPlaceholder)} />
                    </Col>
                  </Row>
                </li>
              </ul>
            </div>
          </div>
          {account.profileCompleteUbo && (
            <div>
              <div className={s.body}>
                <h2 className={s.infoTitle}>Schritt 2: Verifizierung der Gesellschafter</h2>
                <p className={s.infoText}>
                  <FormattedMessage {...messages.kycverificationText} />
                </p>
                <p className={s.infoText}>
                  Bitte machen Sie Angaben zu allen Gesellschaftern Ihres Unternehmens, mit einem Anteil von über 25% und fügen Sie zur Verifizierung einen
                  Auszug des aktuellen Gesellschaftervertrags hinzu.
                </p>
              </div>
              <div className={s.body}>
                <ul className={s.list}>
                  <li className={cx(s.space4, "clearfix", s.background, s.listItem)}>
                    <Row>
                      <Col xs={12} sm={2} md={2} lg={2} className={s.inlineFlex}>
                        <Image src={ArticlesImage} className={s.iconImages} />
                      </Col>
                      <Col xs={12} sm={5} md={5} lg={5} className={s.responsiveCenter}>
                        <h4>Gesellschaftsvertrag</h4>
                        <p className={s.description}>Bitte laden Sie eine Kopie der aktuellsten Version des Gesellschaftsvertrags hoch. </p>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4} className={s.responsiveFlex}>
                        <DocumentUpload
                          returnKycStatus={this.returnArticlesKycStatus}
                          isKyc={true}
                          kycType={"ARTICLES_OF_ASSOCIATION"}
                          key={3}
                          placeholder={formatMessage(messages.kycUploadPlaceholder)}
                        />{" "}
                      </Col>
                    </Row>
                  </li>
                </ul>
                {(articlesKycStatus === "submitted" || articlesKycStatus === "success") && (
                  <div className={s.verificationBlock}>
                    <p className={s.infoText}>{/* <FormattedMessage {...messages.kycIdentityProofText} /> */}</p>
                    {kycDocStatus && (kycDocStatus === "submitted" || kycDocStatus === "success") && <h2>Angaben zu den Gesellschaftern</h2>}
                    {kycDocStatus && kycDocStatus === "submitted" && (
                      <div className={cx(s.badgeUbo, s.badge)}>
                        <Label bsStyle={"info"}>
                          {" "}
                          <FormattedMessage {...messages.kycSubmitted} />
                        </Label>
                      </div>
                    )}
                    {kycDocStatus === "success" && (
                      <div className={cx(s.badgeUbo, s.badge)}>
                        <Label bsStyle={"success"}>
                          {" "}
                          <FormattedMessage {...messages.kycValidated} />
                        </Label>
                      </div>
                    )}

                    {(!kycDocStatus || kycDocStatus === "incomplete") && (
                      <div>
                        {kycDocStatus === "incomplete" && (
                          <div className={cx(s.badgeUbo, s.badge)}>
                            <Label bsStyle={"warning"}>INCOMPLETE</Label>
                          </div>
                        )}

                        {(kycDocStatus === "incomplete" || !kycDocStatus) && inputList && (
                          <div>
                            {inputList.map((x, i) => {
                              return (
                                <div className="box" key={i}>
                                  <h4>Kontaktdaten des Gesellschafters: </h4>
                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>Firmenname</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="CompanyName"
                                          type="text"
                                          value={x.CompanyName}
                                          label={"Firmenname"}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />

                                        {x.validationErrors.includes("CompanyName") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>Handelregisternr.</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="CompanyRegistrationNumber"
                                          type="text"
                                          value={x.CompanyRegistrationNumber}
                                          label={"Handelregisternummer"}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />

                                        {x.validationErrors.includes("CompanyRegistrationNumber") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                  </Row>
                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.firstName)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="FirstName"
                                          type="text"
                                          value={x.FirstName}
                                          label={formatMessage(messages.firstName)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />

                                        {x.validationErrors.includes("FirstName") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.lastName)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="LastName"
                                          type="text"
                                          value={x.LastName}
                                          label={formatMessage(messages.lastName)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("LastName") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                  </Row>

                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.address1)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="AddressLine1"
                                          type="text"
                                          value={x.AddressLine1}
                                          label={formatMessage(messages.address1)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("AddressLine1") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.address2)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="AddressLine2"
                                          type="text"
                                          value={x.AddressLine2}
                                          label={formatMessage(messages.address2)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("AddressLine2") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                  </Row>

                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.zipCode)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="PostalCode"
                                          type="text"
                                          value={x.PostalCode}
                                          label={formatMessage(messages.zipCode)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("PostalCode") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.city)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="City"
                                          type="text"
                                          value={x.City}
                                          label={formatMessage(messages.city)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("City") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                  </Row>

                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.region)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="Region"
                                          type="text"
                                          value={x.Region}
                                          label={formatMessage(messages.region)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("Region") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.country)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <div className={s.inputField}>
                                          <CountryList
                                            input={{
                                              name: "Country",
                                              onChange: (e) => this.handleChange(e, i),
                                              value: x.Country,
                                            }}
                                            className={cx(s.formControlSelect)}
                                            dialCode={false}
                                            isCompanyCountry={false}
                                            getSelected={this.handleCountryChange}
                                          />
                                          {x.validationErrors.includes("Country") && <span className={s.errorMessage}>* ungültig</span>}
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row>

                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.nationality)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <div className={s.inputField}>
                                          <CountryList
                                            input={{
                                              name: "Nationality",
                                              onChange: (e) => this.handleChange(e, i),
                                              value: x.Nationality,
                                            }}
                                            className={cx(s.formControlSelect)}
                                            dialCode={false}
                                            isCompanyCountry={false}
                                            getSelected={this.handleNationalityChange}
                                          />
                                          {x.validationErrors.includes("Nationality") && <span className={s.errorMessage}>* ungültig</span>}
                                        </div>
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.birthDay)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <div className={s.inputField}>
                                          <SingleDatePicker
                                            id="date_input"
                                            isOutsideRange={(day) => moment().diff(day) < 0}
                                            name="Birthday"
                                            date={x.Birthday}
                                            onDateChange={(date) => this.onDateChange(date, i)}
                                            onFocusChange={(focusedInput) => this.onFocusChange(focusedInput, i)}
                                            numberOfMonths={1}
                                            startDatePlaceholderText={formatMessage(messages.birthDay)}
                                            hideKeyboardShortcutsPanel
                                            daySize={48}
                                            keepOpenOnDateSelect={false}
                                            focused={x.focusedInput}
                                            renderMonthElement={this.renderMonthElement}

                                            // renderDayContents={this.renderDay}
                                          />
                                          {x.validationErrors.includes("Birthday") && <span className={s.errorMessage}>* ungültig</span>}
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row>

                                  <Row className={s.spaceTop}>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.birthcity)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <input
                                          name="BirthCity"
                                          type="text"
                                          value={x.BirthCity}
                                          label={formatMessage(messages.birthcity)}
                                          className={cx(s.formControlInput, s.commonBorder)}
                                          onChange={(e) => this.handleInputChange(e, i)}
                                        />
                                        {x.validationErrors.includes("BirthCity") && <span className={s.errorMessage}>* ungültig</span>}
                                      </Col>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} className={s.textAlign}>
                                      <Col xs={12} sm={12} md={5} lg={5} className={s.textAlign}>
                                        <label className={s.labelText}>{formatMessage(messages.birthcountry)}</label>
                                      </Col>
                                      <Col xs={12} sm={7} md={7} lg={7}>
                                        <div className={s.inputField}>
                                          <CountryList
                                            input={{
                                              name: "BirthCountry",
                                              onChange: (e) => this.handleChange(e, i),
                                              value: x.BirthCountry,
                                            }}
                                            className={cx(s.formControlSelect)}
                                            dialCode={false}
                                            isCompanyCountry={false}
                                            getSelected={this.handleBirthCountryChange}
                                          />
                                          {x.validationErrors.includes("BirthCountry") && <span className={s.errorMessage}>* ungültig</span>}
                                        </div>
                                      </Col>
                                    </Col>
                                  </Row>

                                  <Row className={cx(s.spaceTop, s.actionButtons)}>
                                    {inputList.length !== 1 && (
                                      <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlign, s.actionButton)}>
                                        <div onClick={() => this.handleRemoveClick(i)} className={s.pullRight}>
                                          Gesellschafter entfernen
                                          <FaMinusCircle className={s.circleIcon} />
                                        </div>
                                      </Col>
                                    )}
                                    {inputList.length - 1 === i && inputList.length < 3 && (
                                      <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlign, s.actionButton)}>
                                        <div onClick={this.handleAddClick} className={s.pullRight}>
                                          Weiteren Gesellschafter hinzufügen
                                          <FaPlusCircle className={s.circleIcon} />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <Row className={cx(s.spaceTop4)}>
                          <Col xs={12} sm={12} md={8} lg={8}>
                            <CustomCheckbox
                              name="termsAccepted"
                              value={true}
                              checked={termsAccepted}
                              className={cx("icheckbox_square-green", s.checkbox)}
                              onChange={(event) => {
                                // const newValue = [...input.value];
                                this.setTermsAccepted(event);
                              }}
                            />
                            <label className={cx(s.checkboxLabel, s.noPadding)}>Hiermit bestätige ich, die Vollständigkeit und Richtigkeit der Angaben </label>
                          </Col>
                          <Col xs={12} sm={12} md={4} lg={4}>
                            <div>
                              <Button
                                disabled={!termsAccepted}
                                className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight, s.noMargin, s.smPosition)}
                                onClick={this.sendUbo}
                              >
                                <FormattedMessage {...messages.kycSubmitButton} />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
      </div>
    );
  }
}

const mapState = (state) => ({ account: state.account.data });

const mapDispatch = { uploadUboDocuments };

export default compose(
  injectIntl,
  withStyles(s),
  graphql(ShowDocumentListQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        type: "ubo",
        kycType: "UBO",
      },
    }),
  }),
  graphql(gql`
    mutation uploadDocument($fileName: String, $fileType: String, $type: String, $kycType: String, $kycStatus: String) {
      uploadDocument(fileName: $fileName, fileType: $fileType, type: $type, kycType: $kycType, kycStatus: $kycStatus) {
        fileName
        fileType
        type
        kycType
        status
        kycStatus
      }
    }
  `),
  connect(mapState, mapDispatch)
)(UboUpload);

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ListPlaceTips.css";
import { Col } from "react-bootstrap";

// Local
import messages from "../../locale/messages";
class ListPlaceTips extends React.Component {
  static propTypes = {};
  render() {
    const { headline1, headline2, headline3, text1, text2, text3, content } = this.props;
    if (content) {
      return (
        <Col xs={12} sm={5} md={5} lg={5} xsHidden>
          <div className={s.helpPanelContainer}>
            <div className={s.helpPanel}>
              <div className={s.helpPanelText}>
                {content}
              </div>
            </div>
          </div>
        </Col>
      );
    }
    return (
      <Col xs={12} sm={5} md={5} lg={5} xsHidden>
        <div className={s.helpPanelContainer}>
          <div className={s.helpPanel}>
            <div className={s.helpPanelText}>
              <p>
                <span className={s.helpPanelTextTitle}>{headline1}</span>
                <span>{text1}</span>
              </p>
              <p>
                <span className={s.helpPanelTextTitle}>{headline2}</span>
                <span>{text2}</span>
              </p>
              <p>
                <span className={s.helpPanelTextTitle}>{headline3}</span>
                <span>{text3}</span>
              </p>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

export default withStyles(s)(ListPlaceTips);

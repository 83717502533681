import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import PayinContainer from './PayinContainer';
import { getPayins } from '../../actions/Payin/getPayins';

const title = 'Zahlungsmethoden';

export default {

  path: '/user/payin',

  async action({ store, query }) {

    // From Redux Store
    let isAuthenticated = store.getState().runtime.isAuthenticated;
    let currentAccountId = query && query.account;

 

    if (!isAuthenticated) {
      return { redirect: '/login' };
    }

    await store.dispatch(getPayins(currentAccountId));

    return {
      title,
      component: <UserLayout><PayinContainer title={title} currentAccountId={currentAccountId} /></UserLayout>,
    };
  },

};

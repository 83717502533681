import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Modal, Panel } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
// Redux
import { connect } from "react-redux";
// Redux action
import { sendMessageAction } from "../../../actions/message/sendMessageAction";
import history from "../../../core/history";
// Locale
import messages from "../../../locale/messages";
// Component
import CountDown from "../../CountDown";
import Link from "../../Link";
import s from "../ViewMessage.css";

class HostActions extends Component {
  static propTypes = {
    actionType: PropTypes.string.isRequired,
    sendMessageAction: PropTypes.any.isRequired,
    threadId: PropTypes.number.isRequired,
    reservationId: PropTypes.number,
    threadType: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    personCapacity: PropTypes.number.isRequired,
    guestDisplayName: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
    hostDisplayName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      approveModalOpen: false,
      declineModalOpen: false,
    };
  }

  openDeclineModal = () => {
    this.setState({ declineModalOpen: true });
  };
  closeDeclineModal = () => {
    this.setState({ declineModalOpen: false });
  };

  openApproveModal = () => {
    this.setState({ approveModalOpen: true });
  };

  closeApproveModal = () => {
    this.setState({ approveModalOpen: false });
    // history.push("/rentals/current");
  };

  async sendMessage(type) {
    const { sendMessageAction, threadId, threadType, startDate, endDate, personCapacity, reservationId } = this.props;
    this.closeApproveModal();
    this.closeDeclineModal();

    sendMessageAction(threadId, threadType, null, type, startDate, endDate, personCapacity, reservationId, null, null, null, null);
    history.push("/rentals/current");
  
  }

  // Inquiry
  inquiry(guestDisplayName) {
    const { createdAt } = this.props;
    let startDate = moment();
    let next72Hours = moment(createdAt).add(71, "hours").add(59, "minutes");
    let distance = next72Hours - startDate;
    let options = { endDate: next72Hours };
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            <FormattedMessage {...messages.hostAction1} /> {guestDisplayName} <FormattedMessage {...messages.hostAction2} />
          </strong>
        </h4>
        <p className={s.spaceTop2}>
          <FormattedMessage {...messages.hostAction3} /> {guestDisplayName} <FormattedMessage {...messages.hostAction4} />
        </p>
        {distance > 0 && (
          <p className={s.spaceTop2}>
            <FaRegClock className={cx(s.textGray, s.timerIcon)} />
            <FormattedMessage {...messages.hostResponseTime1} /> <CountDown options={options} /> <FormattedMessage {...messages.hostResponseTime2} />
          </p>
        )}
        <Col md={12} className={cx(s.spaceTop2, s.noPadding)}>
          <Button className={s.btnPrimary} onClick={() => this.sendMessage("preApproved")}>
            <FormattedMessage {...messages.preApprove} />
          </Button>
          {/* <Button className={cx(s.btnPrimaryBorder, s.btnRight)} onClick={() => this.sendMessage('declined')}>
						<FormattedMessage {...messages.decline} />
					</Button> */}
        </Col>
      </Panel>
    );
  }

  // Request to book
  requestToBook(guestDisplayName) {
    const { createdAt, listPublishStatus } = this.props;
    const { approveModalOpen, declineModalOpen } = this.state;
    let startDate = moment();
    let next72Hours = moment(createdAt).add(71, "hours").add(59, "minutes");
    let distance = next72Hours - startDate;
    let options = { endDate: next72Hours };

    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            {guestDisplayName} <FormattedMessage {...messages.guestRequest} />
          </strong>
        </h4>
        {distance > 0 && (
          <p className={s.spaceTop2}>
            <FormattedMessage {...messages.hostResponseTime1} /> <CountDown options={options} /> <FormattedMessage {...messages.hostResponseTime2} />
          </p>
        )}

        {listPublishStatus && (
          <Col md={12} className={cx(s.spaceTop2, s.noPadding)}>
            <Button className={s.btnPrimary} onClick={() => this.openApproveModal()}>
              <FormattedMessage {...messages.approve} />
            </Button>
            <Button className={cx(s.btnPrimaryBorder, s.btnRight)} onClick={() => this.openDeclineModal()}>
              <FormattedMessage {...messages.decline} />
            </Button>
          </Col>
        )}

        <Modal bsSize="large" show={approveModalOpen} animation={false} onHide={this.closeApproveModal} dialogClassName={cx(s.cancelationModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3>Sind Sie sicher?</h3>
              <p className={s.modalText}>Sind Sie sicher dass Sie die Anfrage annehmen möchten?</p>
              <Button className={cx(s.button, s.btn, s.btnlarge)} onClick={this.closeApproveModal}>
                Abbrechen
              </Button>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={() => this.sendMessage("preapproved")}>
                Annehmen
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal bsSize="large" show={declineModalOpen} animation={false} onHide={this.closeDeclineModal} dialogClassName={cx(s.cancelationModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3>Sind Sie sicher?</h3>
              <p className={s.modalText}>Sind Sie sicher dass Sie die Anfrage ablehnen möchten?</p>
              <Button className={cx(s.button, s.btn, s.btnlarge)} onClick={this.closeDeclineModal}>
                Abbrechen
              </Button>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={() => this.sendMessage("declined")}>
                Ablehnen
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Panel>
    );
  }

  // Inquiry pre-approved
  approved() {
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            <FormattedMessage {...messages.requestApproved} />
          </strong>
        </h4>
        <p className={s.spaceTop2}>
          <FormattedMessage {...messages.timeToExpire} />
        </p>
        {/* show Link for payment */}
      </Panel>
    );
  }

  // Request to book/ Inquiry declined
  declined() {
    const { approveModalOpen, declineModalOpen } = this.state;

    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>Sie haben die Buchung abgelehnt.</strong>
        </h4>
        <p className={s.spaceTop2}>Der Mieter wurde entsprechend benachrichtigt.</p>
        <p className={s.spaceTop2}>Sie wollen die Buchung doch noch annehmen?</p>
        <Button className={s.btnPrimary} onClick={() => this.openApproveModal()}>
          <FormattedMessage {...messages.approve} />
        </Button>
        <Modal bsSize="large" show={approveModalOpen} animation={false} onHide={this.closeApproveModal} dialogClassName={cx(s.cancelationModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3>Sind Sie sicher?</h3>
              <p className={s.modalText}>Sind Sie sicher dass Sie die Anfrage annehmen möchten?</p>
              <Button className={cx(s.button, s.btn, s.btnlarge)} onClick={this.closeApproveModal}>
                Abbrechen
              </Button>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={() => this.sendMessage("preapproved")}>
                Annehmen
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal bsSize="large" show={declineModalOpen} animation={false} onHide={this.closeDeclineModal} dialogClassName={cx(s.cancelationModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3>Sind Sie sicher?</h3>
              <p className={s.modalText}>Sind Sie sicher dass Sie die Anfrage ablehnen möchten?</p>
              <Button className={cx(s.button, s.btn, s.btnlarge)} onClick={this.closeDeclineModal}>
                Abbrechen
              </Button>
              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={() => this.sendMessage("declined")}>
                Ablehnen
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Panel>
    );
  }

  // Booking confirmed by host/ instant booking
  bookingConfirmed() {
    const { reservationId } = this.props;
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            <FormattedMessage {...messages.bookingIsConfirmed} />
          </strong>
        </h4>
        <p className={s.spaceTop2}>
          <FormattedMessage {...messages.contactGuest} />
        </p>
        <Col md={12} className={cx(s.spaceTop2, s.noPadding)}>
          <Link to={"/cancel/" + reservationId + "/host"} className={cx(s.linkBtn, s.btnPrimary)}>
            <FormattedMessage {...messages.cancelReservation} />
          </Link>
        </Col>
      </Panel>
    );
  }
  // Booking confirmed by host/ instant booking
  notpayed() {
    const { reservationId } = this.props;
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>Warten auf Zahlung von Mieter</strong>
        </h4>
        <p className={s.spaceTop2}>
          Es ist noch keine Zahlung vom Mieter eingegangen. Nach Zahlungseingang wird die Buchung bestätigt und Sie werden per Email informiert.
        </p>
      </Panel>
    );
  }

  waiting() {
    const { reservationId } = this.props;
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>Warten auf Zahlung von Mieter</strong>
        </h4>
        <p className={s.spaceTop2}>
          Der Mieter hat Überweisung als Zahlungsmethode gewählt. Es ist noch keine Zahlung vom Mieter eingegangen. Nach Zahlungseingang wird die Buchung
          bestätigt und Sie werden per Email informiert.
        </p>
      </Panel>
    );
  }

  // Pre-approved or approved by host is expired
  expired(guestDisplayName) {
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            <FormattedMessage {...messages.bookingIsExpired} />
          </strong>
        </h4>
        <p className={s.spaceTop2}>
          {guestDisplayName}'s <FormattedMessage {...messages.bookingIsExpired1} />
        </p>
      </Panel>
    );
  }

  // Booking is cancelled by host
  cancelled(guestDisplayName) {
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            <FormattedMessage {...messages.bookingRequestCancel1} />
          </strong>
        </h4>
        <p className={s.spaceTop2}>
          {guestDisplayName}'s <FormattedMessage {...messages.bookingRequestCancel3} />
        </p>
      </Panel>
    );
  }

  completed() {
    return (
      <Panel className={cx(s.space5, s.contextPadding)}>
        <h4>
          <strong>
            <FormattedMessage {...messages.reservationIsCompleted} />
          </strong>
        </h4>
        <p className={s.spaceTop2}>
          <FormattedMessage {...messages.reservationIsCompletedDescription} />
        </p>
      </Panel>
    );
  }

  render() {
    const { actionType, guestDisplayName } = this.props;

    if (actionType === "inquiry") {
      return this.inquiry(guestDisplayName);
    } else if (actionType === "preApproved") {
      return this.approved();
    } else if (actionType === "declined") {
      return this.declined();
    } else if (actionType === "intantBooking" || actionType === "approved" || actionType === "monthly_payed") {
      return this.bookingConfirmed();
    } else if (actionType === "notpayed") {
      return this.notpayed();
    } else if (actionType === "waiting") {
      return this.waiting();
    } else if (actionType === "requestToBook") {
      return this.requestToBook(guestDisplayName);
    } else if (actionType === "expired") {
      return this.expired(guestDisplayName);
    } else if (actionType === "cancelledByHost" || actionType === "cancelledByGuest") {
      return this.cancelled(guestDisplayName);
    } else if (actionType === "completed") {
      return this.completed();
    } else if (actionType === "notpayed") {
      return this.not_payed();
    }
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  sendMessageAction,
};

export default withStyles(s)(connect(mapState, mapDispatch)(HostActions));

// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep3 from "./validateStep3";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

// Component
import CustomCheckbox from "../CustomCheckbox";
import updateStep3 from "./updateStep3";

class MonthlyPayment extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  setMonthlyPay = (event) => {
    this.props.change("monthlyPay", !event);
  } 

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage, services, valid, monthlyPay } = this.props;

    console.log("monthlypay", monthlyPay);
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";
    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <form onSubmit={handleSubmit}>
              <div className={s.spaceTop3}>
                <h3 className={s.landingContentTitle}>Hinweise zur Zahlungsabwicklung</h3>
                <span className={s.landingStep}>
                  Profitieren Sie von unserem automatisierten Zahlungssystem. Mieter haben bei Buchung folgende Möglichkeiten:
                </span>
              </div>

              <div className={cx(s.space6, s.spaceTop3)}>
                <label className={s.displayTable}>
                  <span className={s.displayTableRow}>
                    <span className={s.displayTableCell}>
                      <span className={s.bookText}>
                        <h5>Monatliche Zahlung (Standard)</h5>
                        <p>
                          Mieter haben die Möglichkeit klassisch in Form einer monatlichen Miete zu bezahlen, wobei die 1. Mietzahlung direkt bei Buchung fällig
                          wird.
                        </p>
                      </span>
                    </span>
                  </span>
                </label>
              </div>

              <div className={cx(s.space6, s.spaceTop3)}>
                <label className={s.displayTable}>
                  <span className={s.displayTableRow}>
                    <span className={s.displayTableCell}>
                      <span className={s.bookText}>
                        <h5>Vorauszahlung der gesamten Mietkosten</h5>
                        <p>Zusätzlich haben Mieter die Möglichkeit die gesamte Mietzahlung direkt bei Buchung im Voraus zu bezahlen.</p>
                      </span>
                    </span>
                  </span>
                </label>
              </div>

              <div className={s.checkboxInfo}>
                <h4>
                  <b>Hinweis</b>
                </h4>
                <p>
                  Für Zahlungen mit einem Wert über 5.000€ sowie der Monatlichen Bezahlung, stehen aus Sicherheitsgründen automatisch die Zahlungsmethoden
                  Überweisung und SEPA Lastschriftmandat zur Verfügung.  
                </p>

                <CustomCheckbox
                      name="confirmed"
                      value={true}
                      checked={!monthlyPay}
                      className={cx("icheckbox_square-green", s.checkbox)}
                      onChange={(event) => {
                        // const newValue = [...input.value];
                        this.setMonthlyPay(event);
                      }}
                    />
                {/* <Field name="monthlyPay" component="input" type="checkbox" checked={}  onChange={(event) => {
                        this.setMonthlyPay(event);
                      }} className={cx(s.BookingradioInput, s.floatLeft)} /> */}
                <h4 className={s.checkboxHeadlineMonthly}>Monatliche Zahlung deaktivieren</h4>
                <h4 className={s.checkboxHeadline}>
                  Achtung: Wenn Sie Ihre Fläche lediglich unter der Bedingung der Vorauszahlung der gesamten Mietkosten anbieten, wird dadurch im Schnitt 50%
                  weniger gebucht!
                </h4>
                <p></p>
              </div>

              <div className={s.nextPosition}>
                <div className={s.nextBackButton}>
                  <hr className={s.horizontalLineThrough} />

                  <FormGroup className={s.formGroup}>
                    <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                      <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Dienstleistungen")}>
                        <FormattedMessage {...messages.back} />
                      </Button>
                      <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} disabled={!valid} onClick={() => nextPage("Verfügbarkeit")}>
                        <FormattedMessage {...messages.next} />
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
const selectorStep3 = formValueSelector("ListPlaceStep3"); //
MonthlyPayment = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep3,
  validate: validateStep3,
})(MonthlyPayment);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  monthlyPay: selectorStep3(state, "monthlyPay"),
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MonthlyPayment)));

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { renderToString } from "react-dom/server";
import { FormattedMessage, injectIntl } from "react-intl";
import { serviceFee } from "../../../config";
// Redux
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
// Redux Form
import { Field, formValueSelector, reduxForm, reset } from "redux-form";
import { makePayment } from "../../../actions/booking/makePayment";
import fetch from "../../../core/fetch";
// Locale
import messages from "../../../locale/messages";
// Component
import CustomCheckbox from "../../CustomCheckbox";
import Loader from "../../Loader";
import TrustInfo from "../../TrustInfo";
import BankTransferLogo from "./banktransfer.png";
import CancelInfo from "./CancelInfo";
import CreditcardLogo from "./creditcard.png";
import GiropayLogo from "./giropay.svg";
//Images
import KlarnaLogo from "./klarna.svg";
import PayinForm from "./PayinForm/PayinForm";
import s from "./Payment.css";
import SepaLogo from "./sepa_logo.png";
// Helpers
import validate from "./validate";
const limitSepa = 5000;
const paymentOptionsData = [
  {
    label: "SOFORT (Klarna)",
    value: "2",
    limit: 2500,
  },
  {
    label: "Giropay",
    value: "3",
    limit: 2500,
  },
  {
    label: "Kreditkarte",
    value: "4",
    limit: 5000,
  },
  {
    label: "Überweisung (Vorkasse)",
    value: "5",
    limit: 999999,
  },
];

const createOptions = () => {
  return {
    style: {
      base: {
        color: "#404040",
        fontWeight: 400,
        //fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
        fontFamily: "inherit",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        ":focus": {
          color: "#404040",
        },

        "::placeholder": {
          color: "#aaa",
        },

        ":focus::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        color: "#303238",
        ":focus": {
          color: "#404040",
        },
        "::placeholder": {
          color: "#aaa",
        },
      },
    },
  };
};
class PaymentForm extends Component {
  static propTypes = {
    houseRules: PropTypes.arrayOf(
      PropTypes.shape({
        listsettings: PropTypes.shape({
          itemName: PropTypes.string.isRequired,
        }),
      })
    ),
    hostDisplayName: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    initialValues: PropTypes.shape({
      listId: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      checkIn: PropTypes.object.isRequired,
      checkOut: PropTypes.object.isRequired,
      guests: PropTypes.number.isRequired,
      basePrice: PropTypes.number.isRequired,
      qmPrice: PropTypes.number.isRequired,
      nkPrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      weeklyDiscount: PropTypes.number,
      monthlyDiscount: PropTypes.number,
      paymentType: PropTypes.number,
    }).isRequired,
    paymentCurrencyList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        symbol: PropTypes.string.isRequired,
        isEnable: PropTypes.bool.isRequired,
        isPayment: PropTypes.bool.isRequired,
      })
    ),
    paymentLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    paymentCurrencyList: [],
    paymentLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: null,
      selectedPaymentMethodId: 2,
      termsAccepted: false,
      modalTermsOfUseOpen: false,
      modalPrivacyOpen: false,
      modalPaymentTermsOpen: false,
      selectedBankAccount: null,
    };
    this.renderpaymentCurrencies = this.renderpaymentCurrencies.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl disabled={disabled} componentClass="select" {...input} className={className}>
          {children}
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup>
        <FormControl {...input} className={className} componentClass="textarea" placeholder={label}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  };

  renderGuests(personCapacity) {
    const { formatMessage } = this.props.intl;

    let rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(
        <option key={i} value={i}>
          {i} {i > 1 ? formatMessage(messages.guests) : formatMessage(messages.guest)}
        </option>
      );
    }
    return rows;
  }

  renderpaymentCurrencies() {
    const { paymentCurrencyList } = this.props;
    let rows = [];

    if (paymentCurrencyList != null && paymentCurrencyList.length > 0) {
      paymentCurrencyList.map((item, index) => {
        if (item.isEnable && item.isPayment) {
          rows.push(
            <option key={index} value={item.symbol}>
              {item.symbol}
            </option>
          );
        }
      });
    }
    return rows;
  }

  renderFormControl = ({ input, label, type, placeholder, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={placeholder} type={type} className={className} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  handleClick() {
    const { dispatch } = this.props;
    dispatch(reset("BookingForm"));
  }

  renderMonthlyPayments = (checkIn, checkOut, isAverage) => {
    const { taxRate, cleaningPrice } = this.props;
    const { locale } = this.props;
    moment.locale(locale);
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    const bookingPeriods = Math.floor(bookingDays / 30);
    const bookingPeriodPrice = 30 * isAverage.toFixed(2);

    const restDays = bookingDays % 30;
    const restPrice = restDays * isAverage.toFixed(2) + cleaningPrice;

    let bookingPeriodsContent = [];
    for (let i = 1; i <= bookingPeriods; i++) {
      if (i < bookingPeriods) {
        bookingPeriodsContent.push(
          <div>
            {i + 1}. Monatsrate (
            {moment(checkIn)
              .add(30 * (i - 1) + 1, "days")
              .format("DD.MM.YYYY")}
            ):
            {bookingPeriodPrice.toFixed(2)}€
            <br />
          </div>
        );
      }
    }

    //rest
    bookingPeriodsContent.push(
      <div>
        Restbetrag (
        {moment(checkIn)
          .add(30 * (bookingPeriods - 1), "days")
          .format("DD.MM.YYYY")}
        ):
        {restPrice.toFixed(2)}€
      </div>
    );

    return renderToString(
      <React.Fragment>
       
        <tr>
          <td className={s.rowWidth}>1. Monatsrate (bei Buchung):</td>
          <td>{bookingPeriodPrice.toFixed(2)}€</td>
        </tr>
        {bookingPeriodsContent}
        <tr>
          <td className={s.rowWidth}></td>
          <td></td>
        </tr>
      </React.Fragment>
    );
  };

  subtractPercentage(total, per) {
    return total - (per / 100) * total;
  }
  addPercentage(total, per) {
    return total + (per / 100) * total;
  }
  reversePercentage(finalNum, per) {
    return (finalNum * 100) / (per + 100);
  }
  getPercentage(total, percent) {
    return (percent / 100) * total;
  }

  async handleSubmit(values, dispatch) {
    const { paymentType, taxRate, reservationId, payinData, paymentMethod, selectedBankAccount, checkIn, checkOut, isAverage } = this.props;
    const { selectedPaymentMethodId } = this.state;
    // const selectedPaymentMethodId = parseInt(paymentType);

    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    const bookingPeriods = Math.floor(bookingDays / 30);
    const bookingPeriodPriceWithOutTax = 30 * isAverage.toFixed(2);
    const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;

    let paymentCurrency = selectedPaymentMethodId == 1 ? values.paymentCurrency : null;

    let query = `query checkReservation ($checkIn: String,$checkOut: String,$listId: Int ){
      checkReservation(checkIn: $checkIn, checkOut:$checkOut, listId:$listId ){
        id
        listId
        hostId
        guestId
        checkIn
        checkOut
        status
      }
    }`;

    const params = {
      listId: values.listId,
      checkIn: values.checkIn,
      checkOut: values.checkOut,
    };

    const resp = await fetch("/graphql", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: params,
      }),
      credentials: "include",
    });

    const { data } = await resp.json();

    if (data && data.checkReservation && data.checkReservation.status == "200") {
      let msg = "",
        paymentMethodId,
        createPaymentMethod;
      let total = values.total;

      const totalAmountNoServiceFee = this.reversePercentage(total, serviceFee);

      const hostServiceFeeNoTax = this.getPercentage(totalAmountNoServiceFee, serviceFee);
      const hostServiceFee = this.addPercentage(hostServiceFeeNoTax, taxRate);
      if (taxRate > 0) {
        total = total + (total / 100) * taxRate;
      }
      const selectedPayin = payinData.find((data) => data.id == selectedBankAccount);
      const last4Numbers = selectedPayin ? selectedPayin.last4Digits : "0000";
      const last4Digits = last4Numbers.toString().length === 3 ? "0" + last4Numbers : last4Numbers;

      const paymentResponse = await dispatch(
        makePayment(
          values.listId,
          values.listTitle,
          values.hostId,
          values.guestId,
          values.checkIn,
          values.checkOut,
          values.guests,
          values.message || "",
          values.basePrice,
          values.cleaningPrice,
          values.currency,
          values.discount,
          values.discountType,
          values.guestServiceFee,
          hostServiceFeeNoTax.toFixed(2),
          total,
          // values.bookingType,
          paymentMethod != 5 ? "instant" : "reservation",
          paymentCurrency,
          paymentType === "full" ? paymentMethod : selectedBankAccount,
          values.guestEmail,
          values.bookingSpecialPricing,
          values.isSpecialPriceAssigned,
          values.isSpecialPriceAverage,
          values.dayDifference,
          paymentMethodId,
          // selectedPaymentMethodId,
          paymentType === "full" ? paymentMethod : selectedBankAccount,
          taxRate,
          values.checkInStart,
          values.checkInEnd,
          values.hostServiceFeeType,
          values.hostServiceFeeValue,
          reservationId,
          paymentType === "full" ? false : true,
          bookingPeriodPrice,
          last4Digits,
          paymentType === "full" ? "" : this.renderMonthlyPayments(values.checkIn, values.checkOut, isAverage)
        )
      );
    } else {
      toastr.error("Oops!", "Those dates are not available.");
    }
  }

  handleSelectPaymentMethod(e) {
    let selectedPaymentMethodId = e.target.value;

    this.setState({ selectedPaymentMethodId: selectedPaymentMethodId });
  }

  handleSelectPaymentType = (e) => {
    const { change } = this.props;

    let selectedPaymentType = e.target.value;
    change("paymentType", selectedPaymentType);
  };
  handleSelectBankAccount = (e) => {
    let selectedBankAccount = e.target.value;

    this.setState({ selectedBankAccount: selectedBankAccount });
  };

  componentDidMount() {
    const { mandateReturned, change, payinData, monthlyPay, checkOut, checkIn, total, isAverage, taxRate } = this.props;
    let latestPayin = null;

    const bookingPeriodPriceWithOutTax = 30 * isAverage.toFixed(2);
    const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;

    if (!monthlyPay || bookingDays <= 60 || bookingPeriodPrice > limitSepa) {
      change("paymentType", "full");
    } else if (mandateReturned) {
      //select monthly and last added bankaccount
      latestPayin = payinData.length > 0 ? payinData[payinData.length - 1] : null;

      change("paymentType", "monthly");
      if (latestPayin) {
        this.setState({ selectedBankAccount: latestPayin.id });
        change("selectedBankAccount", latestPayin.id);
      }
    } else {
      if (payinData.length > 0) {
        //initial select first bank account from list
        change("selectedBankAccount", payinData[0].id);
      } else {
        change("selectedBankAccount", "new");
      }
    }

    //Remove temp booking from localstorage
    localStorage.removeItem("booking");
  }

  setTermsAccepted = (value) => {
    this.setState({ termsAccepted: value });
  };

  openPrivacyModal = () => {
    this.setState({ modalPrivacyOpen: true });
  };
  closePrivacyModal = () => {
    this.setState({ modalPrivacyOpen: false });
  };

  openTermsOfUseModal = () => {
    this.setState({ modalTermsOfUseOpen: true });
  };
  closeTermsOfUseModal = () => {
    this.setState({ modalTermsOfUseOpen: false });
  };

  openPaymentTermsModal = () => {
    this.setState({ modalPaymentTermsOpen: true });
  };
  closePaymentTermsModal = () => {
    this.setState({ modalPaymentTermsOpen: false });
  };

  render() {
    const {
      change,
      payinData,
      listId,
      guestPicture,
      prevPage,
      handleSubmit,
      submitting,
      error,
      reservationId,
      pristine,
      paymentType,
      hostDisplayName,
      houseRules,
      allowedPersonCapacity,
      paymentLoading,
      valid,
      checkIn,
      checkOut,
      paymentMethod,
      selectedBankAccount,
      monthlyPay,
      taxRate,
      total,
      isAverage,
    } = this.props;
    const {
      modalCancelationOpen,
      termsAccepted,
      modalTermsOfUseOpen,
      modalPrivacyOpen,
      modalPaymentTermsOpen,
      paymentStatus,
      selectedPaymentMethodId,
    } = this.state;

    const { formatMessage } = this.props.intl;

    let paymentOptions = [];

    paymentOptionsData.forEach((paymentOption, index) => {
      if (total < paymentOption.limit) {
        paymentOptions.push(
          <option value={paymentOption.value} key={paymentOption.value}>
            {paymentOption.label}
          </option>
        );
      }
    });

    const initialValuesPayinForm = {
      country: "DE",
    };

    const { locale } = this.props;
    moment.locale(locale);

    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;

    //Payin options for monthly payment
    let payinOptions = payinData.map((data) => {
      //TODO other payment options
      //TODO Translate
      let last4Numbers = data.last4Digits.toString().length === 3 ? "0" + data.last4Digits : data.last4Digits;
      return <option value={data.id}>{data.methodId === 1 && "Bankkonto" + " ******" + last4Numbers}</option>;
    });

    payinOptions.push(<option value={"new"}>Bankkonto hinzufügen</option>);

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";

    const bookingPeriodPriceWithOutTax = 30 * isAverage.toFixed(2);
    const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;


    const popoverOnlyPrepayment = (
      <Popover id="popover-trigger-hover-focus" title="Nur Vorkasse verfügbar">
        Der Gesamtbetrag Ihrer Buchung, weshalb bei gewählter Einmalzahlung nur Vorkasse zu Verfügung steht.
      </Popover>
    );


    return (
      <div className={cx(s.bookItPanel, s.spaceTop2, s.aboutNoMargin)}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Row>
            <div className={cx(s.textLeft)}></div>
            <Col lg={8} md={8} sm={12} xs={12} className={cx(s.textLeft)}>
              <section>
                <header className={s.paymentHeader}>
                  <Row>
                    <Col md={10} className={cx(s.textLeft, s.paymentPadding)}>
                      <h3 className={cx(s.pullLeft, s.h3, s.space2)}>
                        <FormattedMessage {...messages.payment} />
                      </h3>
                    </Col>
                  </Row>
                </header>
              </section>
              <FormGroup className={s.formGroup}>
                <ControlLabel className={s.landingLabel}>Wie möchten Sie bezahlen?</ControlLabel>
                <div>
                  <div className={s.paymentType}>
                    {monthlyPay && bookingDays > 60 && bookingPeriodPrice < limitSepa && (
                      <label className={cx(s.blockRadioButton, s.landingLabel)}>
                        Monatliche Zahlung
                        <Field
                          onChange={(e) => this.handleSelectPaymentType(e)}
                          name="paymentType"
                          component="input"
                          type="radio"
                          paymentType
                          value="monthly"
                          className={s.pullRight}
                        />
                      </label>
                    )}
                    <label className={cx(s.blockRadioButton, s.landingLabel)}>
                      Einmalzahlung
                      <Field
                        onChange={(e) => this.handleSelectPaymentType(e)}
                        name="paymentType"
                        component="input"
                        type="radio"
                        value="full"
                        className={s.pullRight}
                      />
                    </label>
                  </div>
                  {paymentType === "full" && (
                    <div>
                      <ControlLabel className={cx(s.landingLabel, s.inlineBlock)}>Einzahlungsmethode wählen:</ControlLabel>

                      <Field
                        name="paymentMethod"
                        component={this.renderFormControlSelect}
                        onChange={(e) => this.handleSelectPaymentMethod(e)}
                        className={cx(s.backgroundPosition, s.formControlSelect, s.jumboSelect, s.noFontWeight)}
                      >
                        {paymentOptions}) );
                      </Field>
                    </div>
                  )}
                  {paymentType === "monthly" && (
                    <div>
                      <ControlLabel className={s.landingLabel}>Bankkonto auswählen:</ControlLabel>
                      <Field
                        name="selectedBankAccount"
                        type="text"
                        className={cx(s.backgroundPosition, s.formControlSelect, s.jumboSelect, s.noFontWeight)}
                        component={this.renderFormControlSelect}
                        onChange={(e) => this.handleSelectBankAccount(e)}
                      >
                        {payinOptions}
                      </Field>
                    </div>
                  )}
                </div>
              </FormGroup>
            </Col>

            {paymentMethod && paymentType !== "monthly" && (
              <Col lg={4} md={4} sm={12} xs={12} className={cx(s.noPadding, s.spaceTop2, s.cardSection)}>
                <div className={"placeHolderFont"}>
                  {paymentMethod === "2" && (
                    <div>
                      <img className={s.paymentLogo} src={KlarnaLogo} />
                      {/* <label className={s.labelText}>Klarna Text blabla</label> */}
                    </div>
                  )}
                  {paymentMethod === "3" && (
                    <div>
                      <img className={s.paymentLogo} src={GiropayLogo} />
                      {/* <label className={s.labelText}>GIROPAY Text blabla</label> */}
                    </div>
                  )}
                  {paymentMethod === "4" && (
                    <div>
                      <img className={s.paymentLogo} src={CreditcardLogo} />
                      {/* <label className={s.labelText}>KREDITKARTE Text blabla</label> */}
                    </div>
                  )}
                  {paymentMethod === "5" && (
                    <div>
                      <img className={s.paymentLogo} src={BankTransferLogo} />
                      {/* <label className={s.labelText}>KREDITKARTE Text blabla</label> */}
                    </div>
                  )}
                </div>
              </Col>
            )}
            {paymentType && paymentType === "monthly" && (
              <Col lg={4} md={4} sm={12} xs={12} className={cx(s.noPadding, s.spaceTop2, s.cardSection)}>
                <div className={"placeHolderFont"}>
                  {" "}
                  <div>
                    <img className={s.paymentLogo} src={SepaLogo} />
                    {/* <label className={s.labelText}>KREDITKARTE Text blabla</label> */}
                  </div>
                </div>
              </Col>
            )}

            {(paymentType === "full" || selectedBankAccount !== "new") && (
              <div>
                {" "}
                <Row className={s.space4}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <CancelInfo checkIn={checkIn} checkOut={checkOut} />
                  </Col>
                </Row>
                <Row className={s.space4}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className={s.confirmBox}>
                      <span className={s.checkBoxSection}>
                        <CustomCheckbox
                          name="termsAccepted"
                          value={true}
                          checked={termsAccepted}
                          className={"icheckbox_square-green"}
                          onChange={(event) => {
                            // const newValue = [...input.value];
                            this.setTermsAccepted(event);
                          }}
                        />
                      </span>
                      <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
                        <label className={cx(s.checkboxLabel, s.noPadding)}>
                          Mit Abschluss der Buchung bestätigen Sie, mit den{" "}
                          <a href="/page/agb" target="blank" rel="noopener noreferrer">
                            {" "}
                            Nutzungsbedingungen
                          </a>
                          ,{" "}
                          <a href="/page/datenschutz" target="blank" rel="noopener noreferrer">
                            Datenschutzrichtlinien
                          </a>{" "}
                          und den{" "}
                          <a href="/page/zahlungsbedingungen" target="blank" rel="noopener noreferrer">
                            Zahlungs- und Stornierungsbedingungen
                          </a>{" "}
                          einverstanden zu sein. Alle Buchungsvorgänge unterliegen den geltenden Gesetzen und Bestimmungen.
                        </label>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className={s.space4}>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Button className={cx(s.cancelLinkText)} onClick={prevPage}>
                      zurück
                    </Button>
                    {/* {!paymentLoading && (
                  <Link to={"/spaces/" + listId} className={cx(s.cancelLinkText)} onClick={this.handleClick}>
                    <FormattedMessage {...messages.cancel} />
                  </Link>
                )}
                {paymentLoading && (
                  <a href="javascript:void(0)" className={cx(s.cancelLinkText)}>
                    <FormattedMessage {...messages.cancel} />
                  </a>
                )} */}
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div className={s.nextBtn}>
                      <Loader
                        type={"button"}
                        buttonType={"submit"}
                        className={cx(s.button, s.btnPrimary, s.btnlarge)}
                        disabled={submitting || error || !valid || !termsAccepted || (!selectedBankAccount && paymentType === "monthly")}
                        show={paymentLoading}
                        label={formatMessage(messages.payNow)}
                      />
                      <TrustInfo />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </form>
        {selectedBankAccount === "new" && paymentType === "monthly" && (
          <Row className={s.space4}>
            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.noPadding, s.spaceTop2)}>
              <PayinForm reservationId={reservationId} initialValues={initialValuesPayinForm} />
            </Col>
          </Row>
        )}
        <Modal show={modalTermsOfUseOpen} animation={false} onHide={this.closeTermsOfUseModal} dialogClassName={cx(s.photoModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3 className={s.photoModalHeadline}>Terms Of Use</h3>

              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={this.closeTermsOfUseModal}>
                Fertig
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={modalPrivacyOpen} animation={false} onHide={this.closePrivacyModal} dialogClassName={cx(s.photoModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3 className={s.photoModalHeadline}>PRIVACY</h3>

              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={this.closePrivacyModal}>
                Fertig
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={modalPaymentTermsOpen} animation={false} onHide={this.closePaymentTermsModal} dialogClassName={cx(s.photoModal)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body bsClass={s.photoModalBody}>
            <div className={s.root}>
              <h3 className={s.photoModalHeadline}>Payment Terms</h3>

              <Button className={cx(s.button, s.btnPrimary, s.btnlarge)} onClick={this.closePaymentTermsModal}>
                Fertig
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

PaymentForm = reduxForm({
  form: "PaymentForm", // a unique name for this form
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PaymentForm);

// Decorate with connect to read form values
const selector = formValueSelector("PaymentForm"); // <-- same as form name

const mapState = (state) => ({
  paymentCurrencyList: state.currency.availableCurrencies,
  paymentLoading: state.book.paymentLoading,
  payinData: state.payin.data,
  paymentType: selector(state, "paymentType"),
  paymentMethod: selector(state, "paymentMethod"),
  selectedBankAccount: selector(state, "selectedBankAccount"),
  loading: state.payin.getPayinLoading,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PaymentForm)));

// General
import cx from 'classnames';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,


  Col, FormGroup, Grid,

  Row
} from 'react-bootstrap';
// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux
import { connect } from 'react-redux';
// Redux Form
import { Field, formValueSelector, reduxForm } from 'redux-form';
// Locale
import messages from '../../locale/messages';
// Internal Component
import IncrementButton from '../IncrementButton';
import IncrementButtonMonth from '../IncrementButton/IncrementButtonMonth';
import IncrementButtonWeek from '../IncrementButton/IncrementButtonWeek';
import s from './ListPlaceStep1.css';
import updateStep3 from './updateStep3';
// Helpers
import validateStep3 from './validateStep3';










class MinMaxNights extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  static defaultProps = {
    minNightData: 30,
    maxNightData: 330
  }

  constructor(props) {
    super(props);
    this.state = {
      minNight: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
      maxNight: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
      isDisabled: false,
    }
  }

  componentWillMount() {
    const { listingFields } = this.props;

    if (listingFields != undefined) {
      this.setState({
        minNight: listingFields.minNight[0],
        maxNight: listingFields.maxNight[0],
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields, minNightData, maxNightData } = nextProps;

    if (listingFields != undefined) {
      this.setState({
        minNight: listingFields.minNight[0],
        maxNight: listingFields.maxNight[0],
      });
    }
  }

  renderIncrementButton = (field) => (
    <IncrementButton
      {...field}
    />
  );
  IncrementButtonWeek = (field) => (
    <IncrementButtonWeek
      {...field}
    />
  );
  IncrementButtonMonth = (field) => (
    <IncrementButtonMonth
      {...field}
    />
  );

  render() {
    const { handleSubmit, submitting, pristine, valid, previousPage, nextPage, existingList } = this.props;
    const { minNight, maxNight } = this.state;
    const { minNightData, maxNightData } = this.props;
    let isDisabled = false;
    if (maxNightData > 0) {
      if (minNightData > maxNightData) {
        isDisabled = true;
      }
    }

    return (
      <Grid fluid>
        <Row className={s.landingContainer}>
          <Col xs={12} sm={7} md={7} lg={7} className={cx(s.landingContent, s.minMaxNights) }>
            <div>
              <h3 className={s.landingContentTitle}><FormattedMessage {...messages.tripLength} /></h3>
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <h4>Wie lange soll ein Mieter mindestens buchen? </h4>
                  <FormGroup className={cx(s.formGroup, s.space4)}>
                    <Field
                      name="minNight"
                      type="text"
                      component={this.IncrementButtonWeek}
                      labelSingluar={"Tag min."}
                      labelPlural={"Tage min."}
                      maxValue={minNight.endValue}
                      minValue={minNight.startValue}
                      incrementBy={1}
                    />
                    {isDisabled && <div className={s.errorMessage}> <FormattedMessage {...messages.tripLengthError1} /> </div>}
                  </FormGroup>
                  <h4>Wie lange soll ein Mieter maximal buchen können?</h4>
                  <FormGroup className={s.formGroup}>
                    <Field
                      name="maxNight"
                      type="text"
                      component={this.IncrementButtonMonth}
                      labelSingluar={"Monat"}
                      labelPlural={"Monate"}
                      maxValue={maxNight.endValue}
                      minValue={30}
                      incrementBy={1}
                    />
                  </FormGroup>
                  <h5>Bitte beachten Sie: Je flexibler Ihr Objekt gebucht werden kann, desto höher die Wahrscheinlichkeit einer Buchung.</h5>
                </div>
                <div className={s.nextPosition}>
               <div className={s.nextBackButton}>
                <hr className={s.horizontalLineThrough} />

                <FormGroup className={s.formGroup}>
                  <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                    <Button 
                      className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} 
                      onClick={() => previousPage("Preis")}
                    >
                      <FormattedMessage {...messages.back} />
                    </Button>
                    <Button 
                      className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} 
                      disabled={isDisabled} 
                      onClick={() => nextPage("Sofortbuchung")}
                    >
                      <FormattedMessage {...messages.next} />
                    </Button>
                  </Col>
                </FormGroup>
                </div>
                </div>
              </form>
            </div>
          </Col>
         
        </Row>
      </Grid>
    );
  }
}

// Decorate with connect to read form values
const selector = formValueSelector('ListPlaceStep3'); // <-- same as form name

MinMaxNights = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
})(MinMaxNights);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  minNightData: selector(state, 'minNight'),
  maxNightData: selector(state, 'maxNight')
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MinMaxNights)));

import { gql } from 'react-apollo';
// Send Email
import { sendEmail } from '../../core/email/sendEmail';
import {
  MANUAL_REQUEST_START,
  MANUAL_REQUEST_SUCCESS,
  MANUAL_REQUEST_ERROR,
} from '../../constants';
import { toastr } from 'react-redux-toastr';
import {Event} from '../../components/Tracking/GA'

import { adminEmail } from '../../config';
export function manualRequest(
  listId,
  listingTitle,
  content,
  startDate,
  endDate,
  size,
  email,
  name,
  phoneNumber,
  hostName,
  hostCompanyName,
  hostId
) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: MANUAL_REQUEST_START,
    });

    try {

      let emailContent = {
        listId,
        listingTitle,
        content,
        startDate,
        endDate,
        size,
        email,
        name,
        phoneNumber,
        hostName,
        hostCompanyName,
        hostId
      }

      let response = await sendEmail(adminEmail, 'manualRequest', emailContent);
      if (response && response.status === 200) {
        toastr.success("Anfrage versendet", "Wir haben Ihre Nachricht erhalten");
          
        Event("expozed", "manual_request", email)
        dispatch({
          type: MANUAL_REQUEST_SUCCESS,
        });
      }

    } catch (error) {
      console.log(error)
      toastr.error("Error", "Es ist ein Fehler beim übermitteln Ihrer Nachricht aufgetreten");
      dispatch({
        type: MANUAL_REQUEST_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3mGmi{padding-left:20px;padding-right:20px}._1ryiY{margin:0 auto;padding-top:30px;max-width:1080px;max-width:var(--max-content-width)}._3Xckn{color:#404040;font-size:20px;margin-bottom:20px;margin-top:0}", ""]);

// exports
exports.locals = {
	"root": "_3mGmi",
	"container": "_1ryiY",
	"infoTitle": "_3Xckn"
};
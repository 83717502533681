exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3u4su{background-color:#404040;width:64px;display:block;height:1.5px;margin:0 auto;margin-bottom:27px}.q7wA1{height:2.5px;border:1px solid #404040;background-color:transparent}._2aLN2 h1{font-size:60px;line-height:46px;text-align:center;font-weight:700;margin-bottom:64px}h4._1DfqK{font-size:24px;font-weight:800;margin-bottom:20px}p._1DfqK{font-size:16px;line-height:1.7;font-weight:400}._2pB9k{margin-bottom:116px}._2pB9k a{font-size:16px;font-weight:400}._2pB9k{margin-bottom:159px}@media screen and (max-width:991px){h4._1DfqK{font-size:23px;font-weight:600}._2aLN2 h1{font-size:36px;line-height:51px;margin-bottom:56px}}@media screen and (max-width:767px){._2aLN2 h1{font-size:24px;line-height:30px;margin-bottom:20px;text-align:left;padding-right:15px;padding-left:15px}h4._1DfqK{font-size:20px}._2pB9k{margin-bottom:0}p._1DfqK{font-size:14px;line-height:1.7;font-weight:400}}", ""]);

// exports
exports.locals = {
	"seperator": "_3u4su",
	"boxseperator": "q7wA1",
	"mainhedding": "_2aLN2",
	"common": "_1DfqK",
	"Paymentsection": "_2pB9k"
};
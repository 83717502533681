import cx from "classnames";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
// External Component
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, FormGroup, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import Collapsible from "react-collapsible";
import { FaChevronDown, FaInfoCircle } from "react-icons/fa";
import { FormattedMessage, injectIntl } from "react-intl";
// Redux
import { connect } from "react-redux";
import { serviceFee } from "../../../config";
// Locale
import messages from "../../../locale/messages";
// Component
import CurrencyConverter from "../../CurrencyConverter";
import s from "./Calendar.css";
//Images
import Faq from "./question.svg";

class BillDetails extends Component {
  static propTypes = {
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    currency: PropTypes.string.isRequired,
    monthlyDiscount: PropTypes.number,
    weeklyDiscount: PropTypes.number,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    formatMessage: PropTypes.any,
    specialPricing: PropTypes.array,
  };

  static defaultProps = {
    basePrice: 0,
    cleaningPrice: 0,
    monthlyDiscount: 0,
    weeklyDiscount: 0,
    startDate: null,
    endDate: null,
    specialPricing: [],
  };
  roundNumber = (num) => {
    return (Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100).toFixed(2);
  };

  subtractPercentage(total, per) {
    return total - (per / 100) * total;
  }
  addPercentage(total, per) {
    return total + (per / 100) * total;
  }
  reversePercentage(finalNum, per) {
    return (finalNum * 100) / (per + 100);
  }
  getPercentage(total, percent) {
    return (percent / 100) * total;
  }

  renderMonthlyPayments = (checkIn, checkOut, isAverage) => {
    const { taxRate, cleaningPrice } = this.props;
    const { locale } = this.props;
    moment.locale(locale);
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    const bookingPeriods = Math.floor(bookingDays / 30);
    const bookingPeriodPrice = 30 * isAverage.toFixed(2);
    // const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";
    // const cleaningPriceWithTax = cleaningPrice + (cleaningPrice / 100) * taxRate;

    const restDays = bookingDays % 30;
    const restPrice = restDays * isAverage.toFixed(2) + cleaningPrice;
    // const restPrice = restPriceWithoutTax + (restPriceWithoutTax / 100) * taxRate;

    const popoverRestPrice = (
      <Popover id="popover-trigger-hover-focus" title="Restbetrag">
        Der Restbetrag beinhaltet die durch die Monatsgebühr noch nicht abgedeckten Buchungstage sowie die Übergabepauschale.
        <br />
        Restbetrag Miete: <b>{(restDays * isAverage).toFixed(2)}€</b>
        <br />
        Übergabepauschale: <b>{cleaningPrice.toFixed(2)}€</b>
      </Popover>
    );

    let bookingPeriodsContent = [];
    for (let i = 1; i <= bookingPeriods; i++) {
      if (i < bookingPeriods) {
        bookingPeriodsContent.push(
          <div key={i} className={cx(s.tableText, s.bookingPeriods)}>
            <span className={cx(s.noBorder)}>
              {i + 1}. Monatsrate (
              {moment(checkIn)
                .add(30 * (i - 1) + 1, "days")
                .format("DD.MM.YYYY")}
              ):
            </span>
            <span className={cx(s.noBorder, s.textRight)}>
              <b>{bookingPeriodPrice.toFixed(2)}€</b>
            </span>
          </div>
        );
      }
    }

    //rest
    bookingPeriodsContent.push(
      <div className={cx(s.tableText, s.bookingPeriods)}>
        <span className={cx(s.noBorder)}>
          Restbetrag (
          {moment(checkIn)
            .add(30 * (bookingPeriods - 1), "days")
            .format("DD.MM.YYYY")}
          ):
          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRestPrice}>
            <FaInfoCircle />
          </OverlayTrigger>
        </span>
        <span className={cx(s.noBorder, s.textRight)}>
          <b>{restPrice.toFixed(2)}€</b>
        </span>
      </div>
    );
    const openLink = (
      <span className={s.bookingPeriodsLink}>
        Zahlungsdetails <FaChevronDown className={s.collapseIcon} />{" "}
      </span>
    );
    return (
      <div className={s.bookingPeriod}>
        <Collapsible trigger={openLink}>
          <div className={cx(s.tableText, s.bookingPeriods)}>
            <span className={cx(s.noBorder)}>1. Monatsrate (bei Buchung): </span>
            <span className={cx(s.noBorder, s.textRight)}>
              <b>{bookingPeriodPrice.toFixed(2)}€</b>
            </span>
          </div>
          {bookingPeriodsContent}
        </Collapsible>
      </div>
    );
  };

  render() {
    const { basePrice, cleaningPrice, currency, monthlyDiscount, weeklyDiscount, endDate, isMonthly } = this.props;
    const { serviceFees, base, rates, specialPricing, taxRate, checkIn, checkOut } = this.props;
    let { startDate, personCapacity, qmPrice, nkPrice } = this.props;
    const { formatMessage } = this.props.intl;
    let serviceFeeCurrency;
    let currentDay,
      bookingSpecialPricing = [],
      isSpecialPriceAssigned = false;
    let priceForDays = 0,
      isAverage = 0;
    let isDayTotal = 0;
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;
    let momentStartDate,
      momentEndDate,
      dayDifference,
      discount,
      discountType,
      total,
      taxRateFee,
      totalWithoutServiceFee = 0,
      discountLessBasePrice = 0;
    if (startDate != null && endDate != null) {
      startDate = moment(startDate).format("YYYY-MM-DD");
      momentStartDate = moment(startDate);
      momentEndDate = moment(endDate);
      dayDifference = momentEndDate.diff(momentStartDate, "days") + 1;

      if (dayDifference > 0) {
        let stayedNights = [];
        // Find stayed nights
        for (let i = 0; i < dayDifference; i++) {
          let currentDate = moment(startDate).add(i, "day");

          stayedNights.push(currentDate.format("YYYY-MM-DD"));
        }

        if (stayedNights && stayedNights.length > 0) {
          stayedNights.map((item, key) => {
            let isSpecialPricing;
            if (item) {
              let pricingRow, currentPrice;
              currentDay = moment(item).format("dddd").toLowerCase();
              isSpecialPricing = specialPricing.find(function (o) {
                return moment(item, "YYYY-MM-DD").format("YYYY-MM-DD") == moment(o.blockedDates, "DD.MM.YYYY").format("YYYY-MM-DD");
              });
              //@TODO disable specialpricing to avoid errors
              isSpecialPricing = false;
              if (isSpecialPricing && isSpecialPricing.isSpecialPrice) {
                isSpecialPriceAssigned = true;
                currentPrice = Number(isSpecialPricing.isSpecialPrice);
              } else {
                currentPrice = Number(basePrice);
              }
              // Price object
              pricingRow = {
                blockedDates: item,
                isSpecialPrice: currentPrice,
              };
              bookingSpecialPricing.push(pricingRow);
            }
          });
        }
      }

      if (isSpecialPriceAssigned) {
        bookingSpecialPricing.map((item, index) => {
          priceForDays = priceForDays + Number(item.isSpecialPrice);
        });
      } else {
        bookingSpecialPricing.map((item, index) => {
          priceForDays = priceForDays + Number(item.isSpecialPrice);
        });
        // priceForDays = Number(basePrice) * Number(dayDifference);
      }
      //priceForDays = Number(basePrice) * Number(dayDifference);
      discount = 0;
      discountType = null;
      total = 0;
    }

    isAverage = Number(priceForDays) / Number(dayDifference);
    isDayTotal = isAverage.toFixed(2) * dayDifference;
    priceForDays = isDayTotal;

    if (dayDifference >= 7) {
      if (monthlyDiscount > 0 && dayDifference >= 28) {
        discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
        discountType = monthlyDiscount + "% " + formatMessage(messages.monthlyDiscount);
      } else {
        discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
        discountType = weeklyDiscount + "% " + formatMessage(messages.weeklyDiscount);
      }
    }
    discountLessBasePrice = isDayTotal - discount;
    // taxRateFee = taxRate && taxRate > 0 ? ((discountLessBasePrice + cleaningPrice) * (Number(taxRate) / 100)) : 0;
    // totalWithoutServiceFee = (isDayTotal + cleaningPrice + taxRateFee) - discount;
    const totalAmount = isDayTotal + cleaningPrice - discount;
    const totalAmountNoServiceFee = this.reversePercentage(totalAmount, serviceFee);

    const hostServiceFeeNoTax = this.getPercentage(totalAmountNoServiceFee, serviceFee);
    const hostServiceFee = this.addPercentage(hostServiceFeeNoTax, taxRate);

    // total = (priceForDays + serviceFee + cleaningPrice + taxRateFee) - discount;

    total = priceForDays + cleaningPrice - discount;

    function LinkWithTooltip({ id, children, href, tooltip }) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip className={s.tooltip} id={id}>
              {tooltip}
            </Tooltip>
          }
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {children}
        </OverlayTrigger>
      );
    }

    return (
      <FormGroup>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {isMonthly && bookingDays > 60 && this.renderMonthlyPayments(checkIn, checkOut, isAverage)}
            <table className={cx("table")}>
              <tbody>
                {(!isMonthly || bookingDays < 60) && (
                  <tr className={cx(s.positionR)}>
                    <td className={cx(s.noBorder)}>
                      {isSpecialPriceAssigned && (
                        <div className={s.specialPriceIcon}>
                          <span>
                            <img src={Faq} className={s.faqImage} />
                          </span>
                          <div className={cx(s.tltip, s.relativeSection)}>
                            <FormattedMessage {...messages.averagePricePerNight} />
                          </div>
                        </div>
                      )}
                      {/* {isSpecialPriceAssigned && !isMonthly && (
                        <div className={cx(s.specialPriceText, s.paddingLeft)}>
                          <CurrencyConverter
                            //amount={basePrice}
                            amount={isAverage}
                            from={currency}
                          />
                          {" x " + dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                        </div>
                      )} */}
                      {(!isMonthly || bookingDays < 60) && (
                        <div className={cx(s.specialPriceText)}>
                          {basePrice.toFixed(2)}€
                          {/* <CurrencyConverter
                                                    //amount={basePrice}
                                                    amount={isAverage}
                                                    from={currency}
                                                /> */}
                          {" x " + dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                        </div>
                      )}
                    </td>
                    <td className={cx(s.noBorder, "text-right")}>
                      <b>
                        <CurrencyConverter amount={isDayTotal} from={currency} />
                      </b>
                    </td>
                  </tr>
                )}
                {discount > 0 && (
                  <tr>
                    <td>{discountType}</td>
                    <td className={cx("text-right", s.discountText)}>
                      - <CurrencyConverter amount={discount} from={currency} />
                    </td>
                  </tr>
                )}

                {isMonthly && bookingDays > 60 && (
                  <tr>
                    <td>Mietkosten</td>
                    <td className={cx("text-right")}>
                      <span>
                        <b>
                          <CurrencyConverter amount={isDayTotal + cleaningPrice} from={currency} />
                        </b>
                      </span>
                    </td>
                  </tr>
                )}
                {cleaningPrice > 0 && (!isMonthly || bookingDays < 60) && (
                  <tr>
                    <td>
                      {isMonthly && bookingDays > 60 && <span>Inkl. </span>}
                      <FormattedMessage {...messages.cleaningFee} />
                    </td>
                    <td className={cx("text-right")}>
                      {" "}
                      <b>
                        <CurrencyConverter amount={cleaningPrice} from={currency} />{" "}
                      </b>
                    </td>
                  </tr>
                )}
                {taxRate > 0 && (
                  <tr>
                    <td>
                      {isMonthly && bookingDays > 60 && <span>zzgl. </span>}MwSt. ({taxRate}%):
                    </td>
                    <td className={cx("text-right")}>
                      {" "}
                      <b>
                        <CurrencyConverter amount={((isDayTotal + cleaningPrice) / 100) * taxRate} from={currency} />{" "}
                      </b>
                    </td>
                  </tr>
                )}

                {/* {
                                    taxRate > 0 && <tr>
                                    <td><FormattedMessage {...messages.taxRate} /></td>     
                                        <td className={cx('text-right')}>
                                            <CurrencyConverter
                                                amount={taxRateFee}
                                                from={currency}
                                            />
                                        </td>
                                    </tr>
                                } */}
                {serviceFee > 0 && (
                  <tr>
                    <td>
                      Inkl. <FormattedMessage {...messages.serviceFee} />
                    </td>
                    <td className={cx("text-right")}>
                      <CurrencyConverter amount={hostServiceFeeNoTax} from={currency} />
                    </td>
                  </tr>
                )}

                <tr>
                  <td>
                    <FormattedMessage {...messages.total} />
                  </td>
                  <td className={cx("text-right")}>
                    <b>
                      <CurrencyConverter amount={total + (total / 100) * taxRate} from={currency} />
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

const mapState = (state) => ({
  specialPricing: state.viewListing.specialPricing,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BillDetails)));

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox2.css";
import { Grid, Row, Col, Button } from "react-bootstrap";
import Link from "../../Link/Link";
import ArrowDown from "./arrow_down.svg";
class ContentBox2 extends React.Component {
  static propTypes = {
    items: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { items } = this.props;

    return (
      <div className={cx(s.content)}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className={s.headLineBlue}>EINFACH. SICHER. SCHNELL.</h4>
            <h2 className={s.headLine}>So funktioniert’s</h2>
            <h5 className={s.headLineSub}>Bieten Sie Ihre Fläche in unter 2 Minuten an.</h5>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            {items.map((item, index) => {
              return (
                <Row className={s.box} key={index}>
                  <Col xs={3} sm={3} md={2} lg={2}>
                    <div className={s.number}>{index + 1}</div>
                  </Col>
                  <Col xs={9} sm={9} md={10} lg={10} className={s.item}>
                    <div className={s.headline}>{item.headline} </div>
                    <span className={s.text}>{item.text}</span>
                  </Col>
                </Row>
              );
            })}
            <div className={s.buttonWrapper}>
              <Link tp="/become-a-host?mode=new">
                <Button className={cx(s.btn, s.btnPrimary, s.button)}>Jetzt Inserat erstellen</Button>
              </Link>
              <div className={s.buttonText}>kostenlos & unverbindlich</div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s)(ContentBox2);

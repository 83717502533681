// General
import React, { Component } from "react";
import PropTypes from "prop-types";

//Redux Form
import { Field, reduxForm } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Internal Component
import IncrementButton from "../IncrementButton";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, FormGroup, Col, FormControl, InputGroup, Popover, OverlayTrigger, ControlLabel } from "react-bootstrap";
import s from "./ListPlaceStep1.css";

import IconRamp from './icon_ramp.png';
import IconLoadingBrige from './icon_loadingbridge.png';
import IconGate from './icon_gate.png';
import IconDoor from './icon_door.png';

//Fontawesome
import  { FaInfoCircle } from 'react-icons/fa';

// Component

import update from "./update";

class Page4 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      powerConnection: "",
      lighting: "",
      buildingClimate: "",
      truckDocks: 0,
      ramps: 0,
      gates: 0,
      doors: 0,
    };
  }

  componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({});
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({});
    }
  }

  renderIncrementButton = (field) => <IncrementButton {...field} />;

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  render() {
    const { handleSubmit, submitting, pristine, previousPage, nextPage } = this.props;
    const { bathrooms, bathroomType } = this.state;
    const { isExistingList } = this.props;
    const { formatMessage } = this.props.intl;
    let path = isExistingList ? "Standort" : "Lage";

    let powerConnection = [
      {
        value: "Standard",
        label: "Standard",
      },
      {
        value: "Produktion",
        label: "Produktion",
      },
      {
        value: "Kein Stromanschluss",
        label: "Kein Stromanschluss",
      },
    ];

    let lighting = [
      {
        value: "Einfach Beleuchtet",
        label: "Einfach Beleuchtet",
      },
      {
        value: "Ausreichend für Lager",
        label: "Ausreichend für Lager",
      },
      {
        value: "Ausreichend für Produktion",
        label: "Ausreichend für Produktion",
      },
      {
        value: "Besonders hohe Beleuchtung",
        label: "Besonders hohe Beleuchtung",
      },
      {
        value: "Keine Beleuchtung",
        label: "Keine Beleuchtung",
      },
    ];

    let buildingClimate = [
      {
        value: "beheizt",
        label: "beheizt",
      },
      {
        value: "unbeheizt",
        label: "unbeheizt",
      },
      {
        value: "gekühlt",
        label: "gekühlt",
      },
      {
        value: "klimatisiert",
        label: "klimatisiert",
      },
      {
        value: "belüftet",
        label: "belüftet",
      },
    ];

    const popoverPowerConnection = (
      <Popover id="popover-trigger-hover-focus" title="Stromanschluss">
        <p>
          <strong>Standard</strong>
          <br />
          Für Lager ausreichende Versorgung mit 230V Steckdosen.
        </p>

        <p>
          <strong>Produktion</strong>
          <br />
          Lagerfläche mit 230V Steckdosen und mindestens einem 16A Anschluss.
        </p>

        <p>Hinweis: Genaue Angaben können Sie in Ihre Flächenbeschreibung hinzufügen.</p>
      </Popover>
    );
    const popoverLighting = (
      <Popover id="popover-trigger-hover-focus" title="Beleuchtung">
        <p>
          <strong>Einfach Beleuchtet</strong>
          <br />
          Ausreichende Beleuchtung für einfache Lagerarbeiten (unter 150 Lux)
        </p>
        <p>
          <strong>Ausreichend für Lager</strong>
          <br />
          Standardanforderungen für Lagerflächen (mindestens 150 Lux)
        </p>
        <p>
          <strong>Ausreichend für Produktion </strong>
          <br />
          Erfüllt mindestens die Anforderungen für einfache Produktionsarbeiten (mindestens 300 Lux)
        </p>
        <p>
          <strong>Besonders hohe Beleuchtung </strong>
          <br />
          Geeignet für Produktion, Lager und Büroarbeiten (über 500 Lux)
        </p>
      </Popover>
    );
    const popoverTruckDocks = (
      <Popover id="popover-trigger-hover-focus" title="Ladebrücken">
        <img className={s.popoverImage} src={IconLoadingBrige} />
      </Popover>
    );
    const popoverRamps = (
      <Popover id="popover-trigger-hover-focus" title="Rampen">
        <img className={s.popoverImage} src={IconRamp} />
      </Popover>
    );
    const popoverGates = (
      <Popover id="popover-trigger-hover-focus" title="Tore">
         <img className={s.popoverImage} src={IconGate} />
      </Popover>
    );
    const popoverDoors = (
      <Popover id="popover-trigger-hover-focus" title="Türen">
         <img className={s.popoverImage} src={IconDoor} />
      </Popover>
    );

    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 640px)").matches : undefined;
    const overlayPlacement = smallDevice ? "bottom" : "right";

    return (
      <Grid fluid>
        <Row className={cx(s.landingContainer, "arrowPosition")}>
          <Col xs={12} sm={7} md={7} lg={7} className={s.landingContent}>
            <div>
              <h3 className={s.landingContentTitle}>
                <FormattedMessage {...messages.howManyBathrooms} />
              </h3>
              <form onSubmit={handleSubmit}>
                <div className={s.landingMainContent}>
                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.powerConnection} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup} style={{ maxWidth: "348px" }}>
                   
                      <Field name="powerConnection" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                        )
                        {powerConnection.map((item, key) => {
                          return (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Field>
                      <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverPowerConnection}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.lighting} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup} style={{ maxWidth: "348px" }}>
   
                      <Field name="lighting" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                        )
                        {lighting.map((item, key) => {
                          return (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Field>
                      <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverLighting}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className={cx(s.formGroup)}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.buildingClimate} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup}>
                      <Field name="buildingClimate" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect)}>
                        )
                        {buildingClimate.map((item, key) => {
                          return (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Field>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.truckDocks} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup} >
                  
                      <Field
                        name="truckDocks"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={formatMessage(messages.truckDock)}
                        labelPlural={formatMessage(messages.truckDocks)}
                        maxValue={100}
                        minValue={0}
                        incrementBy={1}
                        isFeminin={true}
                      />
                            
                            <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverTruckDocks}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>



                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.ramps} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup}>
                     
                      <Field
                        name="ramps"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={formatMessage(messages.ramp)}
                        labelPlural={formatMessage(messages.ramps)}
                        maxValue={100}
                        minValue={0}
                        incrementBy={1}
                        isFeminin={true}
                      />
                       <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverRamps}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>


                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.gates} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup}>
                      <Field
                        name="gates"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={formatMessage(messages.gate)}
                        labelPlural={formatMessage(messages.gates)}
                        maxValue={100}
                        minValue={0}
                        incrementBy={1}
                      />
                          <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverGates}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>


                  <FormGroup className={s.formGroup}>
                    <ControlLabel className={s.landingLabel}>
                      <FormattedMessage {...messages.doors} />
                    </ControlLabel>
                    <InputGroup className={s.infoGroup}>
                   
                      <Field
                        name="doors"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={formatMessage(messages.door)}
                        labelPlural={formatMessage(messages.doors)}
                        maxValue={100}
                        minValue={0}
                        incrementBy={1}
                        isFeminin={true}
                      />
                          <InputGroup.Addon className={s.infoGroupAddon}>
                          <OverlayTrigger trigger={["hover", "focus"]} placement={overlayPlacement} overlay={popoverDoors}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </InputGroup.Addon>
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className={s.nextPosition}>
                  <div className={s.nextBackButton}>
                    <hr className={s.horizontalLineThrough} />

                    <FormGroup className={s.formGroup}>
                      <Col xs={12} sm={12} md={12} lg={12} className={s.noPadding}>
                        <Button className={cx(s.button, s.btnPrimaryBorder, s.btnlarge, s.pullLeft)} onClick={() => previousPage("Technische Angaben")}>
                          <FormattedMessage {...messages.back} />
                        </Button>
                        <Button className={cx(s.button, s.btnPrimary, s.btnlarge, s.pullRight)} onClick={() => nextPage(path)}>
                          <FormattedMessage {...messages.next} />
                        </Button>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Page4 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update,
})(Page4);

const mapState = (state) => ({
  isExistingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Page4)));

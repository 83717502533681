import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Redux
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";

import CurrencyConverter from "../CurrencyConverter";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// External Component
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { Row, Col } from "react-bootstrap";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "!isomorphic-style-loader!css-loader!./DayDragCalendar.css";

import SaveCalendar from "./SaveCalendar";
class DayDragCalendar extends Component {
  static propTypes = {
    change: PropTypes.func,
    formName: PropTypes.string,
    disabledDates: PropTypes.array,
    blockedDates: PropTypes.array,
  };

  static defaultProps = {
    disabledDates: [],
    blockedDates: [],
    listId: null,
    sources: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      from: undefined,
      to: undefined,
      dateRange: [],
      //availableDates: [],
      chooseRangeDate: [],
      isPrice: [],
      sources: [],
      init: false
    };
    this.isDaySelected = this.isDaySelected.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.resetCalendar = this.resetCalendar.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.resetDatePickerChange = this.resetDatePickerChange.bind(this);
  }

  componentWillMount() {
    const { blockedDates, sources, availableDatesPrices } = this.props;
    if (blockedDates != undefined) {
      this.setState({ selectedDays: blockedDates });
    }

    let sourcesValue = [];
    let sourceObject = {};

    availableDatesPrices &&
      availableDatesPrices.map((item, key) => {
        sourceObject = {};
        sourceObject["isSpecialPrice"] = item.isSpecialPrice;
        sourceObject["blockedDates"] = item.date;
        sourcesValue.push(sourceObject);
      });
    this.setState({ sources: sourcesValue });
  }

  componentWillReceiveProps(nextProps) {
    const { blockedDates, sources, availableDatesPrices } = nextProps;
    if (blockedDates != undefined) {
      this.setState({ selectedDays: blockedDates });
    }
    let sourcesValue = [];
    let sourceObject = {};

    availableDatesPrices &&
      availableDatesPrices.map((item, key) => {
        sourceObject = {};
        sourceObject["isSpecialPrice"] = item.isSpecialPrice;
        sourceObject["blockedDates"] = item.date;
        sourcesValue.push(sourceObject);
      });
    this.setState({ sources: sourcesValue });
  }

  renderDay(day) {
    const { currency, baseCurrency, isAdminCurrency } = this.props;
    const { dateRange, sources } = this.state;
    const date = day.getDate();
    let dateRangeValue = moment(day).format("YYYY-MM-DD");

    return (
      <div className={s.responsiveDisplay}>
        <span className={"dateFontWeight"}>{date}</span>
        <div>
          {sources &&
            sources.map((item, key) => {
              let dateValue = moment(item.blockedDates).format("YYYY-MM-DD");
              if (dateRangeValue == dateValue) {
                return (
                  <div className={"priceAlignment"}>
                    <CurrencyConverter amount={item.isSpecialPrice} from={currency} />
                    {/* {item.isSpecialPrice} */}
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  }

  isDaySelected(day) {
    const { selectedDays } = this.state;

    if (selectedDays.length > 0) {
      return selectedDays.some((selectedDay) => DateUtils.isSameDay(selectedDay, day));
    }
  }

  async handleDayClick(day, { start, end, selected, disabled }) {
    const { blockedDates, change, formName } = this.props;
    let selectedDays = blockedDates.slice();
    let startDate, endDate;
    let dateRange = [],
      rangeStart,
      rangeEnd;

    if (disabled) {
      return;
    }

    const range = DateUtils.addDayToRange(day, this.state);
    startDate = range.from;
    endDate = range.to;

    if (startDate && !endDate) {
      rangeStart = new Date(startDate);
      dateRange.push(rangeStart);
    } else if (startDate && endDate) {
      rangeStart = new Date(startDate);
      rangeEnd = new Date(endDate);

      if (!DateUtils.isSameDay(rangeStart, rangeEnd)) {
        dateRange.push(rangeStart);

        rangeStart = new Date(+rangeStart);

        while (rangeStart < endDate) {
          dateRange.push(rangeStart);
          var newDate = rangeStart.setDate(rangeStart.getDate() + 1);
          rangeStart = new Date(newDate);
        }
      } else {
        startDate = null;
        endDate = null;
        dateRange, (selectedDays = []);
      }
    }
    this.setState({ selectedDays, dateRange, from: startDate, to: endDate });

    change("ListPlaceStep3", "startDate", startDate);
    change("ListPlaceStep3", "endDate", endDate);
  }

  resetCalendar() {
    const { change } = this.props;
    // this.setState({ dateRange: [], from: null, to: null, startDate: null, endDate: null });
    this.setState({ dateRange: [], from: null, to: null, startDate: null, endDate: null });
    change("ListPlaceStep3", "startDate", null);
    change("ListPlaceStep3", "endDate", null);
  }

  resetDatePickerChange() {
    const { change } = this.props;
    this.setState({ dateRange: [], from: null, to: null });
  }

  isOutsideRange = (day) => {
    day.isAfter(this.props.maxDaysNoticeEnd) || day.isBefore(this.props.maxDaysNoticeStart);
  };

  render() {
    const { selectedDays, from, to, dateRange, init } = this.state;
    const { disabledDates, formName, listId, availableDates, maxDaysNotice, maxDaysNoticeStart, maxDaysNoticeEnd } = this.props;
    const { availableDatesPrices } = this.props;
    const { sources } = this.state;
    const { minNight, maxNight, houseRules, checkInEnd, checkInStart } = this.props;
    const { cancellationPolicy, bookingNoticeTime } = this.props;
    const { cleaningPrice, basePrice, currency } = this.props;
    const { isStartDate, isEndDate } = this.props;
    const { formatMessage, locale } = this.props.intl;

    //set locale
    moment.locale(locale);

    let dateObj = new Date();

    const modifiers = {
      start: from,
      end: to,
      selected: selectedDays,
      selecting: dateRange,
      available: availableDates,
    };
    

    let inputProps = {};
    let rangeObject = maxDaysNotice ==="custom"?  {
      after: new Date(maxDaysNoticeEnd),
      before: new Date(maxDaysNoticeStart),
    } : {}
    if(!init){
      let startMonth = maxDaysNotice ==="custom"? new Date(maxDaysNoticeStart) : new Date(moment())
      inputProps = {month: startMonth}
      this.setState({init: true})
    }
    return (
      <Row>
        <Col lg={8} md={10} sm={10} xs={12} className={"saveCalender"}>
          <DayPicker
          disabledDays={[
            DateUtils.isPastDay, ...disabledDates,
            rangeObject,
          ]}
            selectedDays={[this.isDaySelected, from, { from, to }]}
            onDayClick={this.handleDayClick}
            modifiers={modifiers}
            fromMonth={dateObj}
            renderDay={this.renderDay}
            localeUtils={MomentLocaleUtils}
            {...inputProps}
            locale={locale}
            todayButton={formatMessage(messages.today)}
            className={"BecomeCalendar"}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SaveCalendar
            selectedDays={dateRange}
            start={from}
            end={to}
            formName={formName}
            resetCalendar={this.resetCalendar}
            resetDatePickerChange={this.resetDatePickerChange}
            listId={listId}
            minNight={minNight}
            maxNight={maxNight}
            houseRules={houseRules}
            checkInEnd={checkInEnd}
            checkInStart={checkInStart}
            cancellationPolicy={cancellationPolicy}
            maxDaysNotice={maxDaysNotice}
            bookingNoticeTime={bookingNoticeTime}
            cleaningPrice={cleaningPrice}
            basePrice={basePrice}
            currency={currency}
            isStartDate={isStartDate}
            isEndDate={isEndDate}
          />
        </Col>
      </Row>
    );
  }
}

const selector = formValueSelector("ListPlaceStep3");

const mapState = (state) => ({
  isStartDate: selector(state, "startDate"),
  isEndDate: selector(state, "endDate"),
  isEndDate: selector(state, "endDate"),
  maxDaysNotice: selector(state, "maxDaysNotice"),
  maxDaysNoticeStart: selector(state, "maxDaysNoticeStart"),
  maxDaysNoticeEnd: selector(state, "maxDaysNoticeEnd"),
});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DayDragCalendar)));

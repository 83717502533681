import React from "react";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ReviewBox.css";
import Carousel from "react-multi-carousel";
import s2 from "!isomorphic-style-loader!css-loader!react-multi-carousel/lib/styles.css";
import { Grid, Row, Col } from "react-bootstrap";
import QuoteImage from "./quote.svg";
import PhotoBirk from "./photo_birk.jpg";
import PhotoPohl from "./photo_pohl.jpg";
class ReviewBox extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
    });
  }
  render() {
    const isBrowser = typeof window !== "undefined";
    const smallDevice = isBrowser ? window.matchMedia("(max-width: 1500px)").matches : undefined;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 2,
        partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
      },
      tablet: {
        breakpoint: { max: 1500, min: 464 },
        items: 1,
        partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
      },
    };
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Carousel
              // centerMode={true}
              partialVisible={false}
              swipeable={smallDevice ? true : false}
              draggable={smallDevice ? true : false}
              showDots={smallDevice ? true : false}
              responsive={responsive}
              // ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={smallDevice ? true : false}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              arrows={false}
              transitionDuration={500}
              // containerClass="carousel-container"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              dotListClass="dots"
              // itemClass="carousel-item-padding-40-px"
            >
              <div className={cx(s.table)}>
                <div>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <div className={cx(s.tableCell)}>
                        <img src={PhotoBirk} className={s.image}></img>
                      </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12}>
                      <div className={cx(s.tableCell, s.contentRight)}>
                        <img className={s.logo} src={QuoteImage} />
                        <p className={s.caption}>Expozed1 ermöglicht die kurzzeitige und flexible Buchung von Flächen in ganz Deutschland. Unser Ziel ist es verfügbare Flächen sichtbar zu machen und Unternehmen die Möglichkeit zu bieten Just-in-time auf die täglichen Herausforderungen reagieren zu können.</p>
                        <div className={s.quoteName}>Timo Birk </div>
                        <div className={s.quoteCompany}>Gründer Expozed1</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={cx(s.table)}>
                <div>
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <div className={cx(s.tableCell)}>
                        <img src={PhotoPohl} className={s.image}></img>
                      </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} xs={12}>
                      <div className={cx(s.tableCell, s.contentRight)}>
                        <img className={s.logo} src={QuoteImage} />
                        <p className={s.caption}>Die Welt verändert sich und wir benötigen zunehmend Flexibilität in unseren Unternehmensprozessen. Durch die kurzfristige Vermietung der Gewerbeflächen schaffen wir Transparenz und Agilität für Unternehmen. Temporärer Leerstand kann genutzt und die Rendite Ihres Objekts deutlich gesteigert werden.</p>
                        <div className={s.quoteName}>Pascal Pohl </div>
                        <div className={s.quoteCompany}>Gründer Expozed1</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
             
            </Carousel>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s, s2)(ReviewBox);

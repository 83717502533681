import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from 'oy-vey';
import Layout from '../layouts/Layout';
import Header from '../modules/Header';
import Footer from '../modules/Footer';
import EmptySpace from '../modules/EmptySpace';
import { url, sitename } from '../../../config';
import CurrencyView from '../modules/CurrencyView';

class PayoutSuccess extends Component {
	static propTypes = {
		content: PropTypes.shape({
			kycType: PropTypes.string.isRequired,
			kycError: PropTypes.string.isRequired
		}).isRequired
	};

	render() {
		const textStyle = {
			color: '#404040',
			backgroundColor: '#F7F7F7',
			fontFamily: 'Arial',
			fontSize: '16px',
			padding: '35px',
		};

		const linkText = {
			color: '#0667B3',
			fontSize: '16px',
			textDecoration: 'none',
			cursor: 'pointer',
		}

		const  { content: { name, logo, amount, currency, confirmationCode, amount_fee } } = this.props;

		return (
			<Layout>
				<Header color="#0667B3" backgroundColor="#F7F7F7" logo={logo} />
				<div>
					<Table width="100%" >
						<TBody>
							<TR>
								<TD style={textStyle}>
									<EmptySpace height={20} />
									<div>
										Hallo {name},
					        </div>
									<EmptySpace height={20} />
									<div>
										Es freut uns Ihnen mitteilen zu dürfen, 
										dass wir soeben eine Auszahlung für die <a style={linkText} href={url + "/users/bookings/receipt/" + confirmationCode}>Buchung {confirmationCode}</a> in der Höhe von <CurrencyView amount={amount} currency={currency} /> vorgenommen haben.
										
										<br />
										Der Betrag wird in Kürze auf Ihrem Konto gutgeschrieben.
										<br />
										<br />
										Wir stellen Ihnen für unseren Service eine Gebühr von <CurrencyView amount={amount_fee} currency={currency} /> in Rechnung. Das Dokument dazu finden Sie im Anhang.
										Der Betrag wird automatisch mit Ihrem Auszahlungsbetrag verrechnet.
									</div>
									<EmptySpace height={20} />
									<div>
										Vielen Dank, <br />
										Ihr {sitename} Team
					        </div>
								</TD>
							</TR>
						</TBody>
					</Table>
					<EmptySpace height={40} />
				</div>
				<Footer />
				<EmptySpace height={20} />
			</Layout>
		);
	}
}

export default PayoutSuccess;

// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

// Redux Form
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { graphql, gql, compose } from "react-apollo";
import submitLight from "./submitLight";
import validateLight from "./validateLight";
import s2 from '!isomorphic-style-loader!css-loader!react-phone-input-2/lib/style.css';

// Locale
import messages from "../../locale/messages";

// Redux
import { connect } from "react-redux";


// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./EditProfileForm.css";
import { Button, Form, Row, Col, ControlLabel, FormControl, Panel, InputGroup, Alert } from "react-bootstrap";


class EditProfileFormLight extends Component {
  static propTypes = {
    loadAccount: PropTypes.func,
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { change, initialValues } = this.props;
    let loggedinEmail;
    if (initialValues && initialValues.email) {
      loggedinEmail = initialValues.email;
    }
    change("loggedinEmail", loggedinEmail);
  }

  componentWillReceiveProps() {
    const { change, initialValues, completeProfile } = this.props;
    let loggedinEmail;
    if (initialValues && initialValues.email) {
      loggedinEmail = initialValues.email.replace(/['"]+/g, '');
    }

    change("loggedinEmail", loggedinEmail);
  
   
  }

  componentWillMount() {
  
 
  }


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, isDisabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={isDisabled} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlEmail = ({ input, label, type, meta: { touched, error }, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} disabled={disabled} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const {error, handleSubmit, submitting, dispatch, base, availableCurrencies, initialValues } = this.props;
    const { formatMessage } = this.props.intl;
    const { siteSettingStatus } = this.props;

    const title = <h3>{formatMessage(messages.RequiredDetails)}</h3>;
    
    return (
      <div className={"inputFocusColor"}>
        {error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        <Panel className={s.panelHeader} header={title}>
          {/* <div className={s.infoText}>
          Legen Sie hier Ihr vollständiges Profil an. Vertrauliche Daten wie beispielsweise Ihr vollständiger Name, die exakte Adresse oder Telefonnummer sind auf Ihrem öffentlichen Profil nicht sichtbar und werden erst nach Abschluss einer Buchung geteilt.
          </div> */}
          <Form onSubmit={handleSubmit(submitLight)}>
            <Row className={s.formGroup}>
              <div className={s.subHeadline}>Ansprechpartner</div>
              <Col componentClass={ControlLabel} xs={12} sm={3} md={3} lg={3} className={s.textAlign}>
                <label className={s.labelText}>{formatMessage(messages.companyName)}</label>
              </Col>
              <Col componentClass={ControlLabel} xs={12} sm={9} md={9} lg={9}>
                <Field
                  name="companyName"
                  type="text"
                  component={this.renderFormControl}
                  label={formatMessage(messages.companyName)}
                  className={cx(s.formControlInput, s.commonBorder)}
                />
              </Col>
              <Col componentClass={ControlLabel} xs={12} sm={3} md={3} lg={3} className={s.textAlign}>
                <label className={s.labelText}>{formatMessage(messages.firstName)}</label>
              </Col>
              <Col componentClass={ControlLabel} xs={12} sm={9} md={9} lg={9}>
                <Field
                  name="firstName"
                  type="text"
                  component={this.renderFormControl}
                  label={formatMessage(messages.firstName)}
                  className={cx(s.formControlInput, s.commonBorder)}
                />
              </Col>
              <Col componentClass={ControlLabel} xs={12} sm={3} md={3} lg={3} className={s.textAlign}>
                <label className={s.labelText}>{formatMessage(messages.lastName)}</label>
              </Col>
              <Col componentClass={ControlLabel} xs={12} sm={9} md={9} lg={9}>
                <Field
                  name="lastName"
                  type="text"
                  component={this.renderFormControl}
                  label={formatMessage(messages.lastName)}
                  className={cx(s.formControlInput, s.commonBorder)}
                />
              </Col>
          
            
            </Row>
            <Row className={s.formGroup}>
              <Col componentClass={ControlLabel} xs={12} sm={3} md={3} lg={3} className={s.textAlign}>
                <label className={s.labelText}>{formatMessage(messages.email)}</label>
              </Col>
              <Col componentClass={ControlLabel} xs={12} sm={9} md={9} lg={9}>
                <Field
                  name="email"
                  type="text"
                  component={this.renderFormControlEmail}
                  label={formatMessage(messages.email)}
                  className={cx(s.formControlInput, s.commonBorder)}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row className={s.formGroup}>
              <Col xs={12} sm={12} md={12} lg={12} className={s.spaceTop3}>
              
                <Button bsSize="small" className={cx(s.button, s.btnPrimary, s.btnlarge)} type="submit" disabled={submitting}>
                  {formatMessage(messages.save)}
                </Button>
              </Col>
            </Row>
          </Form>
        </Panel>
      </div>
    );
  }
}
EditProfileFormLight = reduxForm({
  form: "EditProfileFormLight", // a unique name for this form
  validateLight,
})(EditProfileFormLight);
const selector = formValueSelector("EditProfileFormLight");

const mapState = (state) => ({
  initialValues: state.account.data,
  base: state.currency.base,
  siteSettingStatus: state.siteSettings.data,
});
const mapDispatch = {
  change,
};

export default compose(
  injectIntl,
  withStyles(s,s2),
  connect(mapState, mapDispatch),
)(EditProfileFormLight);

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2qn_P{margin:0 auto;padding:0 0 40px;max-width:1080px;background-color:#fff;margin-top:40px;text-align:center}._2bBVu img{width:200px}h2{font-size:18px}._2q5ll{width:100%;display:block;margin:0 auto}", ""]);

// exports
exports.locals = {
	"container": "_2qn_P",
	"icon": "_2bBVu",
	"landingContainer": "_2q5ll"
};
// Redux Form
import { reset } from 'redux-form';

import {addPayin} from '../../../actions/Payin/addPayinAction';

async function submit(values, dispatch) {
	let paymentType = values.methodId;
	let accountNumber = paymentType == 2 ? values.accountNumber : null;
	let owner = {
		name: values.name.trim(),
		address1: values.address1.trim(),
		address2: values.address2 ? values.address2.trim() : "",
		city: values.city.trim(),
		country: values.country.trim(),
		postalCode: values.zipcode.trim(),
		region: values.region.trim(),
	};

  let cleanedIban = values.iban.replace(/ /g,'').trim();
  let cleanedBic = values.bic.replace(/ /g,'').toUpperCase();

  const isBooking = false;
  const bookingId = null;
  dispatch(addPayin(
  	values.methodId, 
	values.currency,
	cleanedIban,
	cleanedBic,
	cleanedIban.substr(cleanedIban.length - 4),
	owner,
	isBooking,
	bookingId
	  )
  );
  //dispatch(reset('PayinForm'));
}

export default submit;
// Fetch request
import fetch from "../../core/fetch/fetch.server";
import history from "../../core/history";
// Redux
import { setRuntimeVariable } from "../../actions/runtime";

import { loadAccount } from "../../actions/account";


// Toaster,,,,
import { toastr } from "react-redux-toastr";

async function submitLight(values, dispatch, props) {
  const { closeModal } = props;
  const query = `
  query (
    $companyName:String,
    $firstName:String,
    $lastName:String,
    $loggedinEmail:String,
   
  ) {
      userEditProfileLight (
        companyName:$companyName,
        firstName:$firstName,
        lastName:$lastName,
        loggedinEmail:$loggedinEmail,
        
      ) {
        status
      }
    }
    `;
  const { year, month, day } = values;
  let companyNameValue = values.companyName ? values.companyName.trim() : values.companyName;
  let firstNameValue = values.firstName ? values.firstName.trim() : values.firstName;
  let lastNameValue = values.lastName ? values.lastName.trim() : values.lastName;
 
  let loggedinEmailValue = values.loggedinEmail ? values.loggedinEmail.trim().replace(/['"]+/g, '') : values.loggedinEmail.replace(/['"]+/g, '');
 
  const userData = {
    companyName: companyNameValue,
    firstName: firstNameValue,
    lastName: lastNameValue,
   
    email: loggedinEmailValue,
    
  };
  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables: userData,
    }),
    credentials: "include",
  });

  const { data } = await resp.json();

  //TODO Check status
  if (data.userEditProfileLight.status == "success") {
    await dispatch(loadAccount());
    toastr.success("", "Ihre Änderungen wurden erfolgreich aktualisiert!");
    if (closeModal) {
      closeModal();
    } else {
      //   //redirect to dashboard

      history.push("/dashboard");
    }
  } else if (data.userEditProfileLight.status == "email") {
    //throw new SubmissionError({ _error: messages.emailAlreadyExists });
    toastr.error("Update Profile Failed", "Email already exist, please try another email address!");
  } else if (data.userEditProfileLight.status == "notLoggedIn") {
    dispatch(
      setRuntimeVariable({
        name: "isAuthenticated",
        value: false,
      })
    );
    toastr.error("Update Profile Failed", "You are not logged in, please login and try again!");
    //throw new SubmissionError({ _error: messages.notLoggedIn });
  } else {
    // throw new SubmissionError({ _error: messages.somethingWentWrong });
    toastr.error("Update Profile Failed", "Sorry, something went wrong! Reload this page and try again!");
  }
}

export default submitLight;

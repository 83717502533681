import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Calendar.css";
import { Button, FormGroup } from "react-bootstrap";
import cx from "classnames";
// Component
import Loader from "../../Loader";
import history from "../../../core/history";

// Locale
import messages from "../../../locale/messages";
class BookingButton extends Component {
  static propTypes = {
    availability: PropTypes.bool.isRequired,
    isDateChosen: PropTypes.bool.isRequired,
    basePrice: PropTypes.number.isRequired,
    isHost: PropTypes.bool.isRequired,
    bookingProcess: PropTypes.any.isRequired,
    listId: PropTypes.number.isRequired,
    guests: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    bookingType: PropTypes.string.isRequired,
    bookingLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    maximumStay: PropTypes.bool,
    userBanStatus: PropTypes.number,
  };
  static defaultProps = {
    availability: true,
    isDateChosen: false,
    bookingLoading: false,
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleClick() {
    const { bookingProcess, listId, guests, startDate, endDate, taxRate, bookingType } = this.props;
    bookingProcess(listId, guests, startDate, endDate, null, taxRate, bookingType, null,null);
  }
  handleClickLongTerm = () => {
    const { bookingProcess, listId, guests, startDate, endDate, taxRate, bookingType, selectedLongTerm } = this.props;
    // history.push("/book-longterm/" + listId + "/" + selectedLongTerm);
    bookingProcess(listId, guests, startDate, endDate, null, taxRate, bookingType, null,selectedLongTerm);
  };

  handleChange() {
    history.push("/search");
  }
  render() {
    const { basePrice, userBanStatus, isDateChosen, availability, isHost, bookingType, bookingLoading, taxRate } = this.props;
    const { formatMessage } = this.props.intl;
    const { maximumStay, minimumStay, isLongTermBooking, selectedLongTerm } = this.props;
    let disabled, buttonLabel;
    if (!isDateChosen || basePrice < 0 || isHost || maximumStay || userBanStatus || minimumStay) {
      disabled = true;
    } else {
      disabled = false;
    }
    if (bookingType === "instant" && !isLongTermBooking) {
      buttonLabel = messages.book;
    } else {
      buttonLabel = messages.requestToBook;
    }


    // @TODO Temporary disable booking
    // if((typeof window !== "undefined" && window.location.hostname !== "dev.expozed1.de") && (typeof window !== "undefined" && window.location.hostname !== "local.expozed1.de")) {
    //     disabled = true;
    // }

    if (!availability && isDateChosen) {
      return (
        <div>
          <FormGroup className={s.formGroup}>
            <Button className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnPrimaryBorder)} onClick={this.handleChange}>
              <FormattedMessage {...messages.viewOtherListings} />
            </Button>
          </FormGroup>
        </div>
      );
    } else if (isLongTermBooking) {
      return (
        <FormGroup className={s.formGroup}>
          <Button
            className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnPrimary)}
            onClick={this.handleClickLongTerm}
            disabled={!selectedLongTerm}
          >{formatMessage(buttonLabel)}</Button>
        </FormGroup>
      );
    } else {
      return (
        <FormGroup className={s.formGroup}>
          <Loader
            type={"button"}
            className={cx(s.btn, s.btnBlock, s.btnlarge, s.btnPrimary)}
            handleClick={this.handleClick}
            disabled={disabled}
            show={bookingLoading}
            label={formatMessage(buttonLabel)}
          />
        </FormGroup>
      );
    }
  }
}
export default injectIntl(withStyles(s)(BookingButton));

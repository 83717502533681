import React from 'react';
import PropTypes from 'prop-types';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AdminNavigation.css';
import {
  Nav
} from 'react-bootstrap';

// Internal Components
import NavLink from '../../NavLink';
import Logout from '../../Logout';

class AdminNavigation extends React.Component {

  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    return (
      <Nav pullRight className={s.siteAdminNav}>
        <NavLink to="/disable-ga" >
          Opt-out GA
        </NavLink>
        <NavLink to="/" >
          Go to Main Site
        </NavLink>
        <Logout className={s.logout} />
      </Nav>
    );
  }

}

export default withStyles(s)(AdminNavigation);

import withStyles from "isomorphic-style-loader/lib/withStyles";
import React, { Component } from "react";
import { FaLock } from "react-icons/fa";
import { FormattedMessage, injectIntl } from "react-intl";
// Locale
import messages from "../../locale/messages";
import S from "./TrustInfo.css";

class TrustInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className={S.trustIconWrapper}>
        <FaLock className={S.iconStyle} />
        <FormattedMessage {...messages.trustInfo} />
      </div>
    );
  }
}
export default injectIntl(withStyles(S)(TrustInfo));

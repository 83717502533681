import messages from '../../locale/messages';
import PopulateData from "../../helpers/populateData";
const validate = values => {

  const errors = {}
  if (!values.firstName) {
    errors.firstName = messages.required;
  } else if (values.firstName && values.firstName.trim() == "") {
    errors.firstName = messages.required;
  }

  if (!values.lastName) {
    errors.lastName = messages.required;
  } else if (values.lastName && values.lastName.trim() == "") {
    errors.lastName = messages.required;
  }

  if (!values.email) {
    errors.email = messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = messages.emailInvalid;
  }

  if (!values.gender) {
    errors.gender = messages.required;
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = messages.required;
  } else if (values.phoneNumber && values.phoneNumber.trim() == "") {
    errors.phoneNumber = messages.required;
  } else if (values.phoneNumber.length < 6) {
    errors.phoneNumber = messages.phoneNumberInvalid;
  }


  if (!values.preferredLanguage) {
    errors.preferredLanguage = messages.required;
  }

  if (!values.preferredCurrency) {
    errors.preferredCurrency = messages.required;
  }

  // if (!values.location) {
  //   errors.location = messages.required;
  // } else if (values.location && values.location.trim() == "") {
  //   errors.location = messages.required;
  // }

  // if (!values.info) {
  //   errors.info = messages.required;
  // } else if (values.info && values.info.trim() == "") {
  //   errors.info = messages.required;
  // }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = messages.required;
  }

  if (!values.companyName) {
    errors.companyName = messages.required;
  }
  if (!values.nationality) {
    errors.nationality = messages.required;
  }

  if (!values.region) {
    errors.region = messages.required;
  }

  if (!values.companyCountry) {
    errors.companyCountry = messages.required;
  }
  if (!values.address1) {
    errors.address1 = messages.required;
  }
  if (!values.city) {
    errors.city = messages.required;
  }
  if (!values.zip) {
    errors.zip = messages.required;
  }
  if (!values.day || !values.month || !values.year) {
    errors.day = messages.required;
  }
  if (new Date().getFullYear() - values.year < 18) {
    errors.day = messages.mustBe18OrOld;
  }

  if (values.year && values.month && values.day) {
    if (!PopulateData.isValidDate(values.year, values.month, values.day)) {
      errors.day = "Invalid date of birth, please fill the valid data";
    }
  }
  if (!values.taxId) {
    errors.taxId = messages.required;
  }
  if (!values.companyRegistrationNumber) {
    errors.companyRegistrationNumber = messages.required;
  }
  if(values.companyRegistrationNumber && !values.companyRegistrationNumber.replace(/\s/g,'').toUpperCase().match(/^(HRB|HRA)\d+$/g)) {
    errors.companyRegistrationNumber = messages.companyRegistrationNumberInvalid;
  }
  if(values.taxId && !values.taxId.toUpperCase().replace(/\s/g,'').match(/^(DE)?[0-9]{9}/g)) {
    errors.taxId = messages.taxIdInvalid;
  }
  return errors
}

export default validate

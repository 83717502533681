exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._2nzwl{max-width:1080px;width:100%;display:block;margin:0 auto}._1Zh6s{font-size:44px;font-weight:400;word-break:break-all}h1:first-child{margin-top:0}._1D4Kc,p{font-size:14px}._1D4Kc{color:#404040}._1Uybw,._3jzcR{font-size:16px}._1Uybw{border-radius:0;width:100%;display:block;color:#404040;padding-bottom:120px;border-bottom:1px solid #dce0e0}.ikKBq{display:block;padding:14px 15px;position:relative;text-align:center;font-size:14px;color:#707070;cursor:pointer;border-bottom:5px solid transparent}._1RYMN .ikKBq,.ikKBq:hover{text-decoration:none;color:#404040}._1RYMN .ikKBq{border-color:#aaa}.mmMsB{margin:0}._2voiF{margin-top:100px;padding-bottom:50px;position:relative}._3CXsK{margin-left:0;margin-right:0}._2Rrx_{text-align:center;height:24px}.PS8F5{position:absolute;right:-30px;top:0}.yNMjK{background-color:#3fb34f}.NMg66{background-color:#ffb400}._3e5P4{background-color:#0667b3}._1ha3d{position:relative;opacity:1;padding:6px 10px;left:-50%;top:-60px;background-color:#fff}._3JmWw{height:12px;width:12px;border-radius:50%;position:absolute;top:6px;left:-6px;background:#fff}._1BNdB{position:relative;left:-50%;top:3px;text-align:center}._28lB-{margin-top:30px}.HgvUC{margin:102px 0 0}.lQRo2{padding-bottom:25px}@media (max-width:1023px) and (min-width:768px){._1Uybw{padding-bottom:195px}}@media screen and (max-width:767px){._1GXtO{display:none}._1Uybw,.lQRo2{padding-bottom:0}}.s53Z2{margin-bottom:12px}", ""]);

// exports
exports.locals = {
	"landingContainer": "_2nzwl",
	"landingTitle": "_1Zh6s",
	"subText": "_1D4Kc",
	"textGray": "_3jzcR",
	"panelHeader": "_1Uybw",
	"tabItem": "ikKBq",
	"active": "_1RYMN",
	"noMargin": "mmMsB",
	"lineGraph": "_2voiF",
	"graphContainer": "_3CXsK",
	"timeLine": "_2Rrx_",
	"timeLinePoint": "PS8F5",
	"refund": "yNMjK",
	"semiRefund": "NMg66",
	"nonRefund": "_3e5P4",
	"toolTop": "_1ha3d",
	"toolMarker": "_3JmWw",
	"toolLable": "_1BNdB",
	"toolText": "_28lB-",
	"FlexibleTop": "HgvUC",
	"strictPadding": "lQRo2",
	"hidesm": "_1GXtO",
	"space2": "s53Z2"
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../ViewMessage.css';

class Status extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired
    };

    static defaultProps = {
        type: null,
        createdAt: null
    };

    label(status) {
        switch (status) {
            //TODO Translateable
            case 'inquiry':
                return 'Anfrage';
            case 'preApproved':
                return 'Vorgeprüft';
            case 'monthly_payed':
                return 'Monatliche Zahlung ausgeführt';
            case 'declined':
                return 'Abgelehnt';
            case 'approved':
                return 'Genehmigt';
            case 'pending':
                return 'Ausstehend';
            case 'cancelledByHost':
                return 'Vom Vermieter storniert';
            case 'cancelledByGuest':
                return 'Vom Mieter storniert';
            case 'intantBooking':
                return 'Buchung bestätigt';
            case 'confirmed':
                return 'Buchung bestätigt';
            case 'expired':
                return 'Abgelaufen';
            case 'requestToBook':
                return 'Buchungsanfrage';
            case 'completed':
                return 'Abgeschlossen';
            case 'notpayed' || 'waiting':
                return 'Zahlung austehend';
        }
    }

    render() {
        const { type, createdAt } = this.props;
        let date = createdAt != null ? moment(createdAt).format('DD.MM.YYYY') : '';
        return (
            <div className={cx(s.inlineStatus, s.space5)}>
                <div className={cx(s.horizontalText)}>
                    <span className={s.textWrapper}>
                        <span>{this.label(type)}</span>
                        <span> {date}</span>
                    </span>
                </div>
            </div>
        );
    }
}

export default withStyles(s)(Status);


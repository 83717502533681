import React from "react";
import s2 from "!isomorphic-style-loader!css-loader!antd/dist/antd.css";
import s3 from "!isomorphic-style-loader!css-loader!sass-loader!react-dates/lib/css/_datepicker.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SingleDatePicker } from "react-dates";
import { Grid, Row, Col, Button } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./FunnelMieter.css";
import ListingSize1Image from "./listing_size_1.svg";
import ListingSize2Image from "./listing_size_2.svg";
import ListingSize3Image from "./listing_size_3.svg";
import ListingSize4Image from "./listing_size_4.svg";
import ListingType1Image from "./listing_type_1.svg";
import ListingType2Image from "./listing_type_2.svg";
import ListingType3Image from "./listing_type_3.svg";
import { Slider, Checkbox } from "antd"; // Translation
// Translation
import history from "../../core/history";

import moment from "moment";
import { Event } from "../../components/Tracking/GA";

class FunnelMieter extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      actualStep: 1,
      selectedState: "Baden-Württemberg",
      selectedListingType: null,
      selectedListingSize: null,
      selectedStartDate: null,
      selectedDuration: 1,
      focusedInput: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      checked: null,
      submitted: false
    };
  }

  nextStep = () => {
    this.setState({ actualStep: this.state.actualStep + 1 });
    Event("funnel-mieter", "step", this.state.actualStep + 1);
  };
  previousStep = () => {
    this.setState({ actualStep: this.state.actualStep - 1 });
  };
  goHome = () => {
    history.push("/");
  };
  setSelectedState = (state) => {
    this.setState({ selectedState: state });
  };
  setSelectedListingType = (type) => {
    this.setState({ selectedListingType: type });
    this.nextStep();
  };
  setSelectedListingSize = (size) => {
    this.setState({ selectedListingSize: size });
    this.nextStep();
  };
  setSelectedStartDate = (date) => {
    this.setState({ selectedStartDate: date });
    this.nextStep();
  };
  setSelectedDuration = (duration) => {
    this.setState({ selectedDuration: duration });
    this.nextStep();
  };

  onSliderChange = (value) => {
    this.setState({
      selectedDuration: value,
    });
  };
  onCheckboxChange = (e) => {
    this.setState({checked: e.target.checked})
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    let tempState = this.state;
    tempState[name] = value;
    // this.setState(newValue, () => {
    //   this.validateForm();
    // });
    this.setState(tempState);
  };

  getCookie = (name) => {
    let re = new RegExp(name + "=([^;]+)");
    let value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };

  sendFormToHubspot = () => {
    const { firstname, lastname, phone, email, city, selectedState, selectedListingType, selectedListingSize, selectedDuration, selectedStartDate } = this.state;
    // Replace portalId and formId with information from your FORM
    let portalId = "8224909";
    let formId = "7c70c03c-ea7c-4281-b38c-4aeb358780af";
    let url = "https://api.hsforms.com/submissions/v3/integration/submit/" + portalId + "/" + formId;
    // Build request JSON:
    const marks = {
      1: "1 Monat",
      2: "3 Monate",
      3: "3 Monate",
      4: "4 Monate",
      5: "5 Monate",
      6: "6 Monate",
      7: "7 Monate",
      8: "8 Monate",
      9: "9 Monate",
      10: "10 Monate",
      11: "11 Monate",
      12: "12 Monate",
      24: "1-3 Jahre",
      48: "Über 3 Jahre",
    };

    const listingSizeMapping =   {
      "250_500": "250qm - 500qm",
      "500_1500": "500qm - 1.500qm",
      "1500_5000": "500qm - 1.500qm",
      "5000+": "> 5.000qm",
    }
   
    let hubspotData = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "firstname",
          value: firstname,
        },
        {
          name: "lastname",
          value: lastname,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "city",
          value: city,
        },
        {
          name: "state",
          value: selectedState,
        },
        {
          name: "flachentyp",
          value: selectedListingType,
        },
        {
          name: "flachengro_e",
          value: listingSizeMapping[selectedListingSize],
        },
        {
          name: "mietdauer",
          value: marks[selectedDuration],
        },
        {
          name: "startdatum",
          value: moment(selectedStartDate).format("DD.MM.YYYY"),
        },
      ],
      context: {
        hutk: this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow Expozed1 to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999, // replace with your subscription ID
              text: "I agree to receive marketing communications from Expozed1.",
            },
          ],
        },
      },
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(hubspotData),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // toastr.success("Anfrage", "Wir haben Ihre Anfrage erhalten und melden uns schnellst möglich bei Ihnen.");

          this.setState({ submitted: true });
        } else {
          // toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        // toastr.error("Anfrage", "Es gab einen Fehler bei der Übermittlung Ihrer Daten. Bitte wenden Sie sich an den Support.");
      });
  };

  sendForm = () => {
    const { firstname, lastname, phone, email, city, selectedState, selectedListingType, selectedListingSize, selectedDuration, selectedStartDate } = this.state;


    this.sendFormToHubspot();
    const listingTypeMapping = {
      "Lager oder Logistik": "129",
      "Produktion": "130",
      "Außenfläche": "131",
    }
    const listingSizeMapping = {
      "250_500": "251",
      "500_1500": "501",
      "1500_5000": "1501",
      "5000+": "5001",
    }
 
    let searchQuery = ""
    if(city) {
      searchQuery += `&address=${city}`;
    }
    if(selectedListingSize) {
      searchQuery += `&guests=${listingSizeMapping[selectedListingSize]}`;
    }
    if(selectedListingType) {
      searchQuery += `&roomType=${listingTypeMapping[selectedListingType]}`;
    }
    if(selectedStartDate) {
      searchQuery += `&startdate=${moment(selectedStartDate).format("YYYY-MM-DD")}&enddate=${moment(selectedStartDate).add(selectedDuration, 'weeks').format("YYYY-MM-DD")}`;
    }
    // if

    searchQuery += `&chosen=1`;
    // history.push("/search?"+ searchQuery);
    this.setState({submitted: true})
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput: focusedInput.focused }, () => {});
  };
  onDateChange = (date) => {
    this.setState({ selectedStartDate: date }, () => {});
  }

  returnYears = () => {
    let years = [];
    for (let i = moment().year() - 100; i <= moment().year(); i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    </div>
  );

  renderStep = (stepNumber) => {
    const { firstname, lastname, email, phone, checked,submitted } = this.state;

    if (stepNumber === 1) {
      return (
        <div>
          <h3 className={s.headline}>Welche Art von Fläche suchen Sie?</h3>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingType("Lager oder Logistik")}>
                <img className={s.itemImage} src={ListingType1Image} />
              </div>
              <div className={s.clickLabel}>Lager oder Logistik</div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingType("Produktion")}>
                <img className={s.itemImage} src={ListingType2Image} />
              </div>
              <div className={s.clickLabel}>Produktion</div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingType("Außenfläche")}>
                <img className={s.itemImage} src={ListingType3Image} />
              </div>
              <div className={s.clickLabel}>Außenfläche</div>
            </Col>
          </Row>
        </div>
      );
    }
    if (stepNumber === 2) {
      const marks = {
        1: "1 Monat",
        2: "3 Monate",
        3: "3 Monate",
        4: "4 Monate",
        5: "5 Monate",
        6: "6 Monate",
        7: "7 Monate",
        8: "8 Monate",
        9: "9 Monate",
        10: "10 Monate",
        11: "11 Monate",
        12: "12 Monate",
        24: "1-3 Jahre",
        48: "Über 3 Jahre",
      };
      const marksUnvisible = {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        24: "",
        48: "",
      };

      return (
        <div>
          <h3 className={s.headline}>Zu wann und wie lange benötigen Sie die Fläche?</h3>
          <div className={cx(s.formWrapper, "lead-funnel")}>
            <span className={s.label}>Startdatum</span>

            <SingleDatePicker
              id="date_input"
              name="start_date"
              date={this.state.selectedStartDate}
              onDateChange={this.onDateChange}
              numberOfMonths={1}
              placeholder={""}
              hideKeyboardShortcutsPanel
              onFocusChange={(focusedInput) => this.onFocusChange(focusedInput)}
              daySize={48}
              focused={this.state.focusedInput}
              keepOpenOnDateSelect={false}
              renderMonthElement={this.renderMonthElement}
              className={s.dateInput}
              showDefaultInputIcon
              inputIconPosition="after"
            />
            <span className={s.label}>Dauer</span>
            <span className={cx(s.label, s.floatRight)}>{marks[this.state.selectedDuration]}</span>

            <Slider
              min={0}
              max={48}
              marks={marksUnvisible}
              step={null}
              defaultValue={4}
              onChange={this.onSliderChange}
              value={this.state.selectedDuration}
              tooltipVisible={false}
              trackStyle={{ backgroundColor: "#2081CD" }}
            />
          </div>
          <Button disabled={!this.state.selectedStartDate} onClick={this.nextStep} className={s.nextButton}>
            Weiter
          </Button>
        </div>
      );
    }

  
    if (stepNumber === 3) {
      return (
        <div>
          <h3 className={s.headline}>Wie groß soll die Fläche sein?</h3>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingSize("250_500")}>
                <div className={s.itemImafxdfgdvgeWrapper}>
                  <img className={s.itemImage} src={ListingSize1Image} />
                </div>
              </div>
              <div className={s.clickLabel}>250qm - 500qm</div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingSize("500_1500")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={ListingSize2Image} />
                </div>
              </div>
              <div className={s.clickLabel}>500qm - 1.500qm</div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingSize("1500_5000")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={ListingSize3Image} />
                </div>
              </div>
              <div className={s.clickLabel}>1.500qm - 5.000qm</div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className={s.clickItem} onClick={() => this.setSelectedListingSize("5000+")}>
                <div className={s.itemImageWrapper}>
                  <img className={s.itemImage} src={ListingSize4Image} />
                </div>
              </div>
              <div className={s.clickLabel}>{">"} 5.000qm</div>
            </Col>
          </Row>
        </div>
      );
    }
    if (stepNumber === 4) {
      return (
        <div>
          <h3 className={s.headline}>Wo soll Ihre neue Fläche sein?</h3>
          <div className={s.formWrapper}>
            <div className={s.formItem}>
              <span className={s.label}>Bundesland</span>
              <select
                className={cx(s.formControlInput, s.selectField)}
                value={this.state.selectedState}
                onChange={(e) => this.setSelectedState(e.target.value)}
              >
                {/* <option disabled selected hidden value={null}>Mietlaufzeit wählen</option> */}
                <option value="Baden-Wuerttemberg">Baden-Württemberg</option>
                <option value="Bayern">Bayern</option>
                <option value="Berlin">Berlin</option>
                <option value="Brandenburg">Brandenburg</option>
                <option value="Bremen">Bremen</option>
                <option value="Hamburg">Hamburg</option>
                <option value="Hessen">Hessen</option>
                <option value="Mecklenburg-Vorpommern">Mecklenburg-Vorpommern</option>
                <option value="Niedersachsen">Niedersachsen</option>
                <option value="Nordrhein-Westfalen">Nordrhein-Westfalen</option>
                <option value="Rheinland-Pfalz">Rheinland-Pfalz</option>
                <option value="Saarland">Saarland</option>
                <option value="Sachsen">Sachsen</option>
                <option value="Sachsen-Anhalt">Sachsen-Anhalt</option>
                <option value="Schleswig-Holstein">Schleswig-Holstein</option>
                <option value="Thüringen">Thüringen</option>
              </select>
            </div>
            <div className={s.formItem}>
              <span className={s.label}>Stadt (optional)</span>

              <input
                name="city"
                type="text"
                value={this.state.city}
                label={"City"}
                className={cx(s.formControlInput, s.commonBorder)}
                onChange={(e) => this.handleInputChange(e)}
              />
            </div>
          </div>
          <Button onClick={this.nextStep} className={s.nextButton}>
            Weiter
          </Button>
        </div>
      );
    }

    if (stepNumber === 5) {
      return (
        <div>
          <h3 className={s.headline}>Jetzt passendes Angebot auf unserer Plattform finden</h3>
          <h5 className={s.subHeadline}>
          Wer soll die passenden Objekte erhalten? 
          </h5>

          <div className={s.formItem}>
            <span className={s.label}>Vorname</span>

            <input
              name="firstname"
              type="text"
              value={this.state.firstname}
              label={"Vorname"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={s.formItem}>
            <span className={s.label}>Nachname</span>

            <input
              name="lastname"
              type="text"
              value={this.state.lastname}
              label={"Nachname"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={s.formItem}>
            <span className={s.label}>E-Mail-Adresse</span>

            <input
              name="email"
              type="text"
              value={this.state.email}
              label={"E-Mail-Adresse"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={s.formItem}>
            <span className={s.label}>Telefonnummer</span>

            <input
              name="phone"
              type="text"
              value={this.state.phone}
              label={"Telefonnummer"}
              className={cx(s.formControlInput, s.commonBorder)}
              onChange={(e) => this.handleInputChange(e)}
            />
          </div>
          <div className={cx(s.formItem,s.checkBox)}>
          <Checkbox onChange={this.onCheckboxChange}>Ich erkläre mich mit der Verarbeitung der gemachten Angaben zur Kontaktaufnahme durch die Expozed GmbH einverstanden.</Checkbox>
          </div>

          <Button disabled={!checked || !firstname || !lastname || !email || !phone} onClick={this.sendForm} className={s.nextButton}>
          Weiter zur Objektauswahl
          </Button>
          <div className={s.subButton}>Kostenlos & unverbindlich</div>
        </div>
      );
    }
  };

  render() {
    const { siteName } = this.props;
    const { actualStep, submitted } = this.state;

    moment.locale("de");
    if(submitted) {
      return ( 

<div className={s.container}>
<Grid fluid>
  <Row className={cx(s.space6, s.spaceTop6)}>
    <Col xs={12} sm={12} md={12} lg={12}>
      <div className={s.stepper}>
      
      </div>
    </Col>
  </Row>
  <Row className={cx(s.space6, s.spaceTop6)}>
    <Col xs={12} sm={12} md={12} lg={12}>
    <h3 className={s.headline}>Vielen Dank für Ihre Anfrage, wir melden uns umgehend bei Ihnen.
    </h3>
      {actualStep > 1 && (
        <Button onClick={() => this.goHome()} className={s.prevButton}>
          zur Startseite
        </Button>
      )}
    </Col>
  </Row>
</Grid>
</div>)
    }
    return (
      <div className={s.container}>
        <Grid fluid>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={s.stepper}>
                <div className={cx(s.item, actualStep === 1 ? s.activeItem : "")}>1</div>
                <div className={cx(s.item, actualStep === 2 ? s.activeItem : "")}>2</div>
                <div className={cx(s.item, actualStep === 3 ? s.activeItem : "")}>3</div>
                <div className={cx(s.item, actualStep === 4 || actualStep === 5 ? s.activeItem : "")}>4</div>
              </div>
            </Col>
          </Row>
          <Row className={cx(s.space6, s.spaceTop6)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              {this.renderStep(actualStep)}
              {actualStep > 1 && (
                <Button onClick={this.previousStep} className={s.prevButton}>
                  zurück
                </Button>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {};

export default withStyles(s, s2, s3)(connect(mapState, mapDispatch)(FunnelMieter));

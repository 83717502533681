import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ContentBox4.css";
import CheckImage from "./check.svg";
import { Grid, Row, Col } from "react-bootstrap";
class ContentBox4 extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderContent = () => {};
  onChange = (isVisible) => {
    this.setState({ isVisible });
  };

  // {
  //   headline: "Ihre perfekte Lagerfläche in unter 2 Minuten",
  //   subHeadline: "DIE PLATTFORM",
  //   checkItems: [
  //     {
  //     headline: "Sofort verfügbar",
  //     text: "Deutschlandweit. Starten Sie sofort durch."
  //     },
  //     {
  //     headline: "Individuelle Laufzeit",
  //     text: "Sie entscheiden über die Laufzeit. Flexibel und einfach."
  //     },
  //     {
  //     headline: "Einfach und unkompliziert",
  //     text: "Digitaler Mietprozess. Smarte Zahlungsabwicklung."
  //     },
  //     {
  //     headline: "Fixer Mietpreis.",
  //     text: "Profitieren Sie von unserem transparenten  und fairen Pricing."
  //     },
  //   ],
  //   text: "Lager- und Produktionsflächen in ganz Deutschland mieten. Wir bringen Mieter und Eigentümer zusammen.",
  //   image: "Stafan Keckeisen, Geschäftsführer Intact Batterien",
  //   image: keckeisenImage
  // }

  render() {
    const { data: {checkItems, text, image, headline, subHeadline }} = this.props;

    return (
      <Grid className={s.content} fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={s.textWrapper}>
            <h4 className={s.subHeadline}>{subHeadline}</h4>
            <h2 className={s.headline}>{headline}</h2>

            <div className={s.text}>{text}</div>
            <img src={image} className={s.image} />
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={s.itemsWrapper}>
            {checkItems.map((item, index) => {
              return (
                <div className={s.itemWrapper} key={index}>
                  <div className={s.itemImageWrapper}>
                  <img src={CheckImage} className={s.checkImage} /></div>
                  <div className={s.checkContent}>
                    <h5 className={s.itemHeadline}>{item.headline}</h5>
                    <span className={s.itemText}>{item.text}</span>
                  </div>
                </div>
              );
            })}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withStyles(s)(ContentBox4);

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1080px;--max-container-width:100%;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--border-color:#dce0e0;--background-color:#fff;--btn-color:#404040;--btn-primary-bg:#0667b3;--btn-primary-color:#fff;--btn-primary-hover-bg:#58595b;--btn-secondary-bg:#0667b2;--btn-secondary-color:#fff;--btn-secondary-hover-bg:#0667b2}._3KNtx{padding-left:20px;padding-right:20px}._1BFU_{margin:0 auto;padding-top:30px;max-width:1080px;max-width:var(--max-content-width)}._3XveD{background-color:#fff;box-shadow:0 1px 15px 3px #eeebeb;border-radius:4px;margin:0 15px 60px}@media screen and (max-width:1199px){._3XveD{background-color:#fff;box-shadow:0 1px 15px 3px #eeebeb;border-radius:4px;margin:30px 15px 60px}}", ""]);

// exports
exports.locals = {
	"root": "_3KNtx",
	"container": "_1BFU_",
	"mainSection": "_3XveD"
};
import React, { Children } from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import deepForceUpdate from "react-deep-force-update";
// Helpers
import { environment, bugsnagKey } from "../config";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
var BugsnagPluginExpress = require('@bugsnag/plugin-express')

import history from "../core/history";
let ErrorBoundary = null;


if(environment === "production" || environment === "staging" || environment === "development") {
  Bugsnag.start({
    apiKey: bugsnagKey,
    plugins: [new BugsnagPluginReact(),BugsnagPluginExpress],
  })
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}
const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.any.isRequired,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store: PropTypes.shape({
    subscribe: PropTypes.any.isRequired,
    dispatch: PropTypes.any.isRequired,
    getState: PropTypes.any.isRequired,
  }).isRequired,
  // Apollo Client
  client: PropTypes.object.isRequired,
};

class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      load: false,
      gaUserSet: false
    };
  }

  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  // componentWillMount() {
  // if (typeof window === 'undefined') {
  //   return;
  // }

  // const googleMaps = (window.google) && (window.google.maps);
  // if (!googleMaps) {
  //   const getGoogleMapsAPIUrl = key => `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`
  //   const initGoogleMapsAPI = () => loadScriptAsync(getGoogleMapsAPIUrl(googleMapAPI));
  //   return;
  // }
  // }

  componentDidMount() {
    const store = this.props.context && this.props.context.store;

    if (store) {
      this.unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const newIntl = state.intl;
        if (this.intl !== newIntl) {
          this.intl = newIntl;

          console.log("process.env.__DEV__",process.env.__DEV__)
          if (process.env.__DEV__) {
            // eslint-disable-next-line no-console
            //console.log('Intl changed — Force rendering');
          }
          deepForceUpdate(this);
        }
      });
    }
    if (environment === "production") {
      //Facebook tracking pixel
      const ReactPixel = require("react-facebook-pixel");
      ReactPixel.default.init("640872573469665");
    }

    this.setState({
      load: true,
    });

    //hide hubspot on some routes
    if (history.location) {
      const hideHubSpot = history.location.pathname.includes("/booking/") || history.location.pathname.includes("become-a-host");
      if (window.HubSpotConversations) {
        const hubspotLoaded = window.HubSpotConversations.widget.status().loaded;
        if (hideHubSpot && hubspotLoaded) {
          window.HubSpotConversations.widget.remove();
          this.setState({
            hubspotRemoved: true,
          });
        } else if (!hubspotLoaded) {
          window.HubSpotConversations.widget.load();
          this.setState({
            hubspotRemoved: false,
          });
        }
      }
    
    }
   
    console.log("store",store.getState())
    if(window && window.ga && store && !this.state.gaUserSet) {
      const user = store.getState().user;
      if(user && user.email) {
        window.ga('set', 'userId', user.email);
        this.setState({gaUserSet: true})
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }
  componentWillUpdate() {
   
    //hide hubspot on some routes
    if (history.location) {
      const hideHubSpot = history.location.pathname.includes("/booking/") || history.location.pathname.includes("become-a-host");
      if (window.HubSpotConversations) {
        const hubspotLoaded = window.HubSpotConversations.widget.status().loaded;
        if (hideHubSpot && hubspotLoaded) {
          window.HubSpotConversations.widget.remove();
          this.setState({
            hubspotRemoved: true,
          });
        } else if (!hubspotLoaded) {
          window.HubSpotConversations.widget.load();
          this.setState({
            hubspotRemoved: false,
          });
        }
      }
    
    }
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    const store = this.props.context && this.props.context.store;
    const state = store && store.getState();
    this.intl = (state && state.intl) || {};
    const { initialNow, locale, messages } = this.intl;
    const localeMessages = (messages && messages[locale]) || {};
    const { load } = this.state;
   
    if (ErrorBoundary) {
      return (
        <ErrorBoundary>
          <IntlProvider initialNow={initialNow} locale={locale} messages={localeMessages} defaultLocale="de-DE">
            {Children.only(this.props.children)}
          </IntlProvider>
        </ErrorBoundary>
      );
    } else {
      return (
        <IntlProvider initialNow={initialNow} locale={locale} messages={localeMessages} defaultLocale="de-DE">
          {Children.only(this.props.children)}
        </IntlProvider>
      );
    }
  }
}

export default App;

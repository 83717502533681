exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2rj9R{max-width:1070px;margin:0 auto}", ""]);

// exports
exports.locals = {
	"completeContainer": "_2rj9R"
};
import React from 'react';
import Layout from '../../components/Layout';
import Book from './Book';
import NotFound from '../notFound/NotFound';
import { getPayins } from '../../actions/Payin/getPayins';

const title = 'Buchungen';

export default {

  path: '/book/:hostingId',

  async action({ store, params, query }) {

    // From Redux Store
    let isAuthenticated = store.getState().runtime.isAuthenticated;
    let bookingData = store.getState().book.data;
    let hostingId = params.hostingId;
    let currentAccountId = query && query.account;
    let profileComplete =  (store.getState().account && store.getState().account.data) ? store.getState().account.data.profileComplete : true;

 
    // Check authentication
    if (!isAuthenticated) {
      return { redirect: '/login?refer=/spaces/' + hostingId };
    }

    // Check listId is provided
    if (!hostingId) {
      return {
        title,
        component: <Layout><NotFound title={title} /></Layout>,
        status: 404
      };
    }

    // // Check redux store for booking data
    // if (!bookingData) {
    //   return { redirect: '/spaces/' + hostingId };
    // }

    await store.dispatch(getPayins(currentAccountId));

    return {
      title,
      component: <Layout><Book title={title} /></Layout>,
    };
  },

};
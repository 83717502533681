// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Internal Helpers
import submit from "./submit";
import update from "./update";
import updateStep2 from "./updateStep2";
import updateStep3 from "./updateStep3";

// Translation
import { injectIntl } from "react-intl";

// Step #3
import Calendar from "./Calendar";
import MaxDaysNotice from "./MaxDaysNotice";
// Tab Bar
import TabBarStep from "./TabBarStep";
import SaveButton from '../Header/List/SaveButton';
import history from "../../core/history";

import s from './ListPlaceStep1.css';

class EditCalendar extends Component {
  static propTypes = {
    listData: PropTypes.object,
    existingList: PropTypes.bool,
    listingSteps: PropTypes.object,
    listId: PropTypes.number,
    formBaseURI: PropTypes.string,
    mode: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      page: "index",
      step1: null,
      step2: null,
      step3: null,
      step4: null,
      formValues: {},
    };
  }

  componentWillMount() {
    const { existingList, listingSteps } = this.props;
    if (existingList && listingSteps != undefined) {
      this.setState({
        step1: listingSteps.step1,
        step2: listingSteps.step2,
        step3: listingSteps.step3,
        step4: listingSteps.step4,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { existingList, listingSteps } = nextProps;
    if (existingList && listingSteps != undefined) {
      this.setState({
        step1: listingSteps.step1,
        step2: listingSteps.step2,
        step3: listingSteps.step3,
        step4: listingSteps.step4,
      });
    } else {
      this.setState({
        step1: null,
        step2: null,
        step3: null,
        step4: null,
      });
    }
  }

 

  render() {
    const { page} = this.state;
    const { formPage, mode, listId, baseCurrency } = this.props;
    return (
      <div className={"inputFocusColor", "editCalendar"}>
        <div className={s.saveButton}>
          <SaveButton step={3} listId={listId} isActiveListing={true} className={s.exitText} />
        </div>
        <MaxDaysNotice listId={listId} isActiveListing={true} />
        <Calendar listId={listId} isActiveListing={true} baseCurrency={baseCurrency} />
      </div>
    );
  }
}

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingSteps: state.location.listingSteps,
});

const mapDispatch = {};

export default injectIntl(connect(mapState, mapDispatch)(EditCalendar));

import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import AddPayinContainer from './AddPayinContainer';

const title = 'Zahlungsmethode hinzufügen';

export default {

  path: '/user/addpayin',

  action({ store }) {

    // From Redux Store
    let isAuthenticated = store.getState().runtime.isAuthenticated;

    let profileComplete = store.getState().account.data ? store.getState().account.data.profileComplete : null;


    if (!isAuthenticated) {
      return { redirect: '/login' };
    }

    let accountData = store.getState().account.data;

    return {
      title,
      component: <UserLayout><AddPayinContainer title={title} initialData={accountData} /></UserLayout>,
    };
  },

};

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._2UNx1{width:100%;height:100vh}._3lmmv{width:100%;height:auto}.C3qJ2{position:absolute;width:100%;height:100vh;top:0;background-size:cover;object-fit:cover;background-repeat:no-repeat;background-position:top;transition:all .1s ease;box-shadow:inset 0 0 10px rgba(0,0,0,.5)}._2cDfJ{position:relative;height:100%;margin:0 auto;padding-bottom:24px;max-width:1080px;padding:0 15px}._1PSBV{color:#707070;position:absolute;top:16%;background:#fff;padding:30px;border-radius:5px;width:100%;max-width:450px;box-shadow:1px 5px 10px rgba(0,0,0,.2)}._1PSBV h1 span{margin:0 0 10px;color:#0667b3}._1PSBV h1{margin:0;font-weight:600;font-size:26px;color:#404040}@media screen and (max-width:767px){._2cDfJ{padding:0 10px}._1PSBV{max-width:calc(100% - 20px)}}@media screen and (max-width:767px) and (max-height:720px){._2UNx1,.C3qJ2{min-height:720px}}", ""]);

// exports
exports.locals = {
	"layout4Container": "_2UNx1",
	"bannerLayoutContainer": "_3lmmv",
	"bannerBackgroundImage": "C3qJ2",
	"searchFormContainer": "_2cDfJ",
	"searchFormWrap": "_1PSBV"
};
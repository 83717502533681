import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";

// Redux
import { connect } from "react-redux";
import { serviceFee } from "../../../config";

// Redux Form
import { Field, reduxForm, formValueSelector, reset } from "redux-form";

import { Row, FormGroup, Col, FormControl, Button } from "react-bootstrap";


import { toastr } from "react-redux-toastr";

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Payment.css";

// Helpers
import validate from "./validate";
import fetch from "../../../core/fetch";

// Component
import HouseRules from "./HouseRules";
import InsuranceInfo from "./InsuranceInfo";
import RequestInfo from "./RequestInfo";
import Link from "../../Link";

import { makePayment } from "../../../actions/booking/makePayment";

// Locale
import messages from "../../../locale/messages";

const createOptions = () => {
  return {
    style: {
      base: {
        color: "#404040",
        fontWeight: 400,
        //fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
        fontFamily: "inherit",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        ":focus": {
          color: "#404040",
        },

        "::placeholder": {
          color: "#aaa",
        },

        ":focus::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        color: "#303238",
        ":focus": {
          color: "#404040",
        },
        "::placeholder": {
          color: "#aaa",
        },
      },
    },
  };
};

const limitSepa = 5000;
class PrePaymentForm extends Component {
  static propTypes = {
    houseRules: PropTypes.arrayOf(
      PropTypes.shape({
        listsettings: PropTypes.shape({
          itemName: PropTypes.string.isRequired,
        }),
      })
    ),
    hostDisplayName: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    initialValues: PropTypes.shape({
      listId: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      checkIn: PropTypes.object.isRequired,
      checkOut: PropTypes.object.isRequired,
      guests: PropTypes.number.isRequired,
      basePrice: PropTypes.number.isRequired,
      qmPrice: PropTypes.number.isRequired,
      nkPrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      weeklyDiscount: PropTypes.number,
      monthlyDiscount: PropTypes.number,
      paymentType: PropTypes.number,
    }).isRequired,
    paymentCurrencyList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        symbol: PropTypes.string.isRequired,
        isEnable: PropTypes.bool.isRequired,
        isPayment: PropTypes.bool.isRequired,
      })
    ),
    paymentLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    paymentCurrencyList: [],
    paymentLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmitRequest = this.handleSubmitRequest.bind(this);

  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl disabled={disabled} componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup>
        <FormControl {...input} className={className} componentClass="textarea" placeholder={label}>
          {children}
        </FormControl>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  };

  renderGuests(personCapacity) {
    const { formatMessage } = this.props.intl;

    let rows = [];
    for (let i = 1; i <= personCapacity; i++) {
      rows.push(
        <option key={i} value={i}>
          {i} {i > 1 ? formatMessage(messages.guests) : formatMessage(messages.guest)}
        </option>
      );
    }
    return rows;
  }

  renderFormControl = ({ input, label, type, placeholder, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={placeholder} type={type} className={className} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  };

  handleClick = () => {
    const { dispatch } = this.props;
    dispatch(reset("BookingForm"));
  };

  componentDidMount() {
    const { mandateReturned, change, payinData, monthlyPay, checkOut, checkIn, total, isAverage, taxRate } = this.props;
    let latestPayin = null;

    const bookingPeriodPriceWithOutTax = 30 * isAverage.toFixed(2);
    const bookingPeriodPrice = bookingPeriodPriceWithOutTax + (bookingPeriodPriceWithOutTax / 100) * taxRate;
    const bookingDays = moment(checkOut).diff(moment(checkIn), "days") + 1;

    if (!monthlyPay || bookingDays <= 60 || bookingPeriodPrice > limitSepa) {
      change("paymentType", "full");
    } 

    //Remove temp booking from localstorage
    localStorage.removeItem("booking");
  }

  handleSubmit = (values, dispatch) => {
  };
  subtractPercentage(total, per) {
    return total - (per / 100) * total;
  }
  addPercentage(total, per) {
    return total + (per / 100) * total;
  }
  reversePercentage(finalNum, per) {
    return (finalNum * 100) / (per + 100);
  }
  getPercentage(total, percent) {
    return (percent / 100) * total;
  }

  async handleSubmitRequest (values, dispatch)  {
    const { taxRate } = this.props;
    // const { selectedPaymentMethodId } = this.state;
    //This is for reservation
    const selectedPaymentMethodId = 99;


    let paymentCurrency = values.paymentCurrency;

    let query = `query checkReservation ($checkIn: String,$checkOut: String,$listId: Int ){
      checkReservation(checkIn: $checkIn, checkOut:$checkOut, listId:$listId ){
        id
        listId
        hostId
        guestId
        checkIn
        checkOut
        status
      }
    }`;

    const params = {
      listId: values.listId,
      checkIn: values.checkIn,
      checkOut: values.checkOut,
    };

    const resp = await fetch("/graphql", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: params,
      }),
      credentials: "include",
    });

    const { data } = await resp.json();

    if (data && data.checkReservation && data.checkReservation.status == "200") {
      let msg = "",
        paymentMethodId,
        createPaymentMethod;
      let total = values.total;

      const totalAmountNoServiceFee = this.reversePercentage(total, serviceFee);

      const hostServiceFeeNoTax = this.getPercentage(totalAmountNoServiceFee, serviceFee);
      const hostServiceFee = this.addPercentage(hostServiceFeeNoTax, taxRate);

      if(taxRate > 0 ) {
        total = total + (total / 100 * taxRate);

      }

      const { status, paymentIntentSecret, reservationId } = await dispatch(
        makePayment(
          values.listId,
          values.listTitle,
          values.hostId,
          values.guestId,
          values.checkIn,
          values.checkOut,
          values.guests,
          values.message || "",
          values.basePrice,
          values.cleaningPrice,
          values.currency,
          values.discount,
          values.discountType,
          values.guestServiceFee,
          hostServiceFeeNoTax.toFixed(2),
          total,
          "reservation",
          paymentCurrency,
          //no paymenttype because request 99
          99,
          values.guestEmail,
          values.bookingSpecialPricing,
          values.isSpecialPriceAssigned,
          values.isSpecialPriceAverage,
          values.dayDifference,
          paymentMethodId,
          selectedPaymentMethodId,
          taxRate,
          values.checkInStart,
          values.checkInEnd,
          values.hostServiceFeeType,
          values.hostServiceFeeValue,
          null,
          false,
          null,
          null
        )
      );
    } else {
      toastr.error("Oops!", "Those dates are not available.");
    }
  }

  requestBooking = () => {};

  render() {
    
    const { hostDisplayName, houseRules, paymentLoading, valid } = this.props;
    const { handleSubmit, submitting, error,  bookingType } = this.props;
    const { listId, guestPicture, nextPage, isInsurable } = this.props;
    let { payinData } = this.props;
    const { formatMessage } = this.props.intl;
    const submitFunction = bookingType === "instant" ? this.handleSubmit : this.handleSubmitRequest;
    return (
      <div className={cx(s.bookItPanel, s.spaceTop2, s.aboutNoMargin)}>
        <form onSubmit={handleSubmit(this.handleSubmitRequest)}>
          <Row>
            <Col md={12} className={cx(s.textLeft)}>
              <div className={s.h3}>{bookingType === "request" ? "Infos zu Ihrer Buchungsanfrage" : <FormattedMessage {...messages.aboutYourTrip} />}</div>
              <div className={s.aboutPaymentDesc}>
                <FormattedMessage {...messages.aboutDescPayment} />
              </div>
              {/* <div className={cx(s.bookItDetails, s.spaceTop2, s.space4)}>
                <span>
                  <FormattedMessage {...messages.whoComing} />
                </span>
                <Row className={s.spaceTop2}>
                  <Col md={12} lg={5}>
                    <Field name="guests" component={this.renderFormControlSelect} className={s.formControlSelect}>
                      {this.renderGuests(allowedPersonCapacity)}
                    </Field>
                  </Col>
                </Row>
              </div> */}
              <div className={s.displayTable}>
                <div className={s.displayTableRow}>
                  <div className={cx(s.displayTableCell, s.avatarSection, s.vtrTop)}>
                    <img src={guestPicture ? "/images/avatar/medium_" + guestPicture : "/assets/avatar_default.svg"} className={s.avatarImage} />
                  </div>
                  <div className={cx(s.displayTableCell, s.messageSection, s.vtrTop)}>
                    <div>
                      <span>
                        <FormattedMessage {...messages.sayHello} />:
                      </span>
                    </div>
                    <div>
                      <Field className={s.textArea} name="message" component={this.renderFormControlTextArea} label={formatMessage(messages.descriptionInfo)} />
                    </div>
                
                  </div>
                </div>
              </div>

              {bookingType === "request" ? ( <div className={s.space4}><RequestInfo /></div>): (<div>
              <div className={s.space4}>
                <InsuranceInfo isInsurable={isInsurable} hostDisplayName={hostDisplayName} />
              </div>
              {houseRules.length > 0 && (
                <div className={s.space4}>
                  <HouseRules hostDisplayName={hostDisplayName} houseRules={houseRules} />
                </div>
              )}
              </div>) }
      
            </Col>
            <Col className={cx(s.textLeft)}>
              {/* <Field
                name="paymentType"
                type="text"
                className={cx(s.formControlSelect, s.fullWithSelect)}
                component={this.renderFormControlSelect}
                onChange={(e) => this.handleSelectPaymentMethod(e)}
              >
               
                {payinOptions}
              </Field> */}

              <Row className={s.space4}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={s.nextBtn}>
                    {bookingType === "request" ? (
                      <Button className={cx(s.button, s.btnPrimary)} type="submit" disabled={submitting || error || !valid}>
                        Jetzt Buchungsanfrage stellen
                      </Button>
                    ) : (
                      <Button className={cx(s.button, s.btnPrimary)} onClick={nextPage} disabled={submitting || error || !valid}>
                        weiter
                      </Button>
                    )}
                  </div>
                  {!paymentLoading && (
                    <div className={s.spaceTop1}>
                      <Link to={"/spaces/" + listId} className={cx(s.cancelLinkText)} onClick={this.handleClick}>
                        <FormattedMessage {...messages.cancel} />
                      </Link>
                    </div>
                  )}
                  {paymentLoading && (
                    <div className={s.spaceTop1}>
                      <a href="javascript:void(0)" className={cx(s.cancelLinkText)}>
                        <FormattedMessage {...messages.cancel} />
                      </a>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

PrePaymentForm = reduxForm({
  form: "PaymentForm", // a unique name for this form
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PrePaymentForm);

// Decorate with connect to read form values
const selector = formValueSelector("PaymentForm"); // <-- same as form name

const mapState = (state) => ({
  loading: state.payin.getPayinLoading,
  payinData: state.payin.data,
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PrePaymentForm)));
